import React from 'react';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';
import visaImg from '../../../../../assets/images/visa.svg';
import mastercardImg from '../../../../../assets/images/mastercard.svg';
import amexImg from '../../../../../assets/images/amex.svg';
import discoverImg from '../../../../../assets/images/discover.svg';
import { TiLockClosedOutline } from 'react-icons/ti';

@inject('store', 'global', 'invoice')
@observer
class PreviewEmail extends React.Component {
  getPriceFormatted(value) {
    return `$${this.props.global.numberWithCommas(parseFloat(value).toFixed(2))}`;
  }

  render() {
    const { paypointData, defaultSettings, pay, invoiceDetails, advancedOptions } = this.props.invoice;

    const { brandColor, contactUsEmail, contactUsPhone, contactUsText } = defaultSettings;
    const dueDate = dayjs(invoiceDetails.dueDate).format('MMM D, YYYY');

    const { paylinkHeader, paylinkDescription, paymentMethods } = advancedOptions;
    const { visa, mastercard, discover, amex } = paymentMethods;

    const logo = paypointData.logo || this.props.invoice.getInvoiceLogo;
    
    return (
      <div id="preview-email" className="preview-card">
        <div className="logo">
          {logo &&
          <img src={logo} alt="logo" />
          }
        </div>
        <div className="bar">
          <div className="bar-left" style={{ backgroundColor: brandColor }}></div>
          <div className="bar-right"></div>
        </div>

        <div className="header-paypoint">
          <h5 className="name">{paylinkHeader}</h5>
          <span className="subtitle">{paylinkDescription}</span>
        </div>

        <h2 className="price">{this.getPriceFormatted(pay.subtotal)}</h2>

        <div className="invoice-data center">
          <div className="group-data middle">
            <span className="group-header">Invoice #</span>
            <span className="group-value">{invoiceDetails.invoiceNumber}</span>
          </div>

          <div className="group-data middle">
            <span className="group-header">Due Date</span>
            <span className="group-value">{dueDate}</span>
          </div>
        </div>

        <div className="btn-pay btn-group full-w btn-pay">
          <button type="button" className="btn btn-success btn-lg" style={{ backgroundColor: brandColor }}>
            <TiLockClosedOutline />
            Pay {this.getPriceFormatted(pay.subtotal)}
          </button>
        </div>

        <p className="contact-us small-grey-m0 text-center full-w">
          <b>{contactUsText}</b>
          <br />
          {contactUsEmail} | {contactUsPhone}
        </p>

        <div className="card-brands text-center bottom-grey mt-3">
          <div>
            {visa && <img alt="" src={visaImg}/>}
            {mastercard && <img alt="" className="mastercard" src={mastercardImg}/>}
            {discover && <img alt="" className="discover" src={discoverImg} />}
            {amex && <img alt="" className="amex" src={amexImg} />}
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewEmail;
