import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { CustomerLinks } from '../../components/CustomerLinks';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '../../components/Layout';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Accordion , Card, Modal, Button} from 'react-bootstrap';
import { PaymentMethodCCForm } from '../../components/PaymentMethodCCForm';
import { PaymentMethodECheck } from '../../components/PaymentMethodECheck';
import { PaymentMethodsAccepted } from '../../components/PaymentMethodsAccepted';
import {BiChevronDown} from "@react-icons/all-files/bi/BiChevronDown";
import {BiCreditCard} from '@react-icons/all-files/bi/BiCreditCard';
import {BiDollarCircle} from '@react-icons/all-files/bi/BiDollarCircle';
import {BiTrash} from '@react-icons/all-files/bi/BiTrash';
import {AiOutlinePlus} from '@react-icons/all-files/ai/AiOutlinePlus';

@inject('customer', 'global', 'vTerminal')
@observer
class PayMethods extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customerId: null,
            customer: null,
            deleteModalIsOpen: false,
            addModalIsOpen: false,
        };
        this.deletePaymentMethod = this.deletePaymentMethod.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this); 
        this.deletePaymentMethodAction = this.deletePaymentMethodAction.bind(this); 
        this.openAddModal = this.openAddModal.bind(this); 
        this.closeAddModal = this.closeAddModal.bind(this); 
        this.setPaymentMethod = this.setPaymentMethod.bind(this); 
        this.addPaymentMethod = this.addPaymentMethod.bind(this); 
        this.validatePaymentMethodFields = this.validatePaymentMethodFields.bind(this); 
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.getCustomerFromApi(id)
    }

    getCustomerFromApi(id){
        let command = this.props.match.params.command;
        this.props.global.setLoading(true);
        this.props.customer.getCustomerFromApi(id).then(res => {
            this.setState({ customer: res.data });
            /* This is to load the permissions that the customer has in the entrypoint. */
            this.props.vTerminal.setEntryPoint(res.data.PaypointEntryname);
            this.props.vTerminal.getPaymentPageFromApi().then(res => {
                this.setState({ customerId: id });
                /* This is to raise the modal window if it is automatic. */
                if(command === "add"){
                    this.props.vTerminal.clearACHForm();
                    this.props.vTerminal.clearCCForm();
                    this.props.vTerminal.setPaymentMethod('ach');
                    this.setState({ addModalIsOpen: true });
                }
                this.props.global.setLoading(false);
            })
            .catch(error => {
                this.props.global.setLoading(false);
            });
        })
        .catch(error => {
            this.props.global.setLoading(false);
        });        
    }

    validatePaymentMethodFields(){
        var paymentPage = this.props.vTerminal.paymentPage;
        var validators = this.props.global.validators;
        // validating ach form
        if(this.props.vTerminal.getPaymentMethod === "ach"){

            this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountHolderName) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountHolderName))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountType) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountType))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achRouting) || !this.props.global.isValidRouting(paymentPage.paymentMethods.achRouting) || validators.isMaxLength(9, paymentPage.paymentMethods.achRouting) || validators.stringValidator('routing', paymentPage.paymentMethods.achRouting))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccount) ||  validators.stringValidator('numbers', paymentPage.paymentMethods.achAccount) || validators.isMinLength(4, paymentPage.paymentMethods.achAccount) || validators.isMaxLength(17, paymentPage.paymentMethods.achAccount))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
            }
        }else if(this.props.vTerminal.getPaymentMethod === "card"){
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.cardNumber) || validators.isMaxLength(16, paymentPage.paymentMethods.cardNumber) || validators.stringValidator('card', paymentPage.paymentMethods.cardNumber) || !this.props.global.luhnCheck(paymentPage.paymentMethods.cardNumber))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardExpirationDate) || validators.isMaxLength(4, paymentPage.paymentMethods.cardExpirationDate) || validators.stringValidator('exp', paymentPage.paymentMethods.cardExpirationDate))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
            }
            else{
                let expDateYear = paymentPage.paymentMethods.cardExpirationDate.substr(-2);
                let expDateMonth = paymentPage.paymentMethods.cardExpirationDate.substr(0,2);
                let currentYear =new Date().getFullYear().toString().substr(-2);
                let currentMonth =new Date().getMonth().toString();

                if(parseInt(expDateYear) < parseInt(currentYear)){
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
                }


                if((parseInt(expDateYear) === parseInt(currentYear)) && (parseInt(expDateMonth) < parseInt(currentMonth)+1)){
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                }
            }

            let ccType = this.props.global.creditCardType(paymentPage.paymentMethods.cardNumber);
            if(validators.isEmpty(paymentPage.paymentMethods.cardCvv) || (ccType === "american-express" ? validators.stringValidator('cvvamex', paymentPage.paymentMethods.cardCvv) : validators.stringValidator('cvv', paymentPage.paymentMethods.cardCvv)))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',true);
            }
            else{

                this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardZipcode) || validators.isMaxLength(7, paymentPage.paymentMethods.cardZipcode) || validators.stringValidator('zipcode', paymentPage.paymentMethods.cardZipcode))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            }
        }
    }

    setPaymentMethod(method){
        this.props.vTerminal.setPaymentMethod(method);
    }

    deletePaymentMethod(id){
        this.setState({ paymentMethodToDelete: id });
        this.openDeleteModal();
    }

    openDeleteModal(){
        this.setState({ deleteModalIsOpen: true });
    }
    
    closeDeleteModal(){
        this.setState({ deleteModalIsOpen: false });
    }

    openAddModal(){
        this.props.vTerminal.clearACHForm();
        this.props.vTerminal.clearCCForm();
        this.props.vTerminal.setPaymentMethod('ach');
        this.setState({ addModalIsOpen: true });
    }
    
    closeAddModal(){
        this.setState({ addModalIsOpen: false });
    }

    addPaymentMethod(){
        this.validatePaymentMethodFields();
        if(!this.props.vTerminal.hasPaymentPageErrors()){
            this.props.global.setLoading(true);
            this.props.vTerminal.addPaymentMethod(this.state.customerId).then(result => {
                this.closeAddModal();
                this.props.global.setLoading(false);
                this.getCustomerFromApi(this.state.customerId);
                toast.success("Payment Method added successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    deletePaymentMethodAction(){
        let paymentMethodToDelete = this.state.paymentMethodToDelete;
        
        if(paymentMethodToDelete){
            this.props.global.setLoading(true);
            this.props.vTerminal.deletePaymentMethod(paymentMethodToDelete)
            .then(response => {
                this.props.global.setLoading(false);
                if(response.data.responseData.resultCode && response.data.responseData.resultCode !== 1){
                    let message = response.data.responseData.resultText ?  response.data.responseData.resultText : 'Sorry, we have a problem deleting the payment method.';
                    toast.error(message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container'
                    });
    
                }else{
                    this.getCustomerFromApi(this.state.customerId);
                    this.setState({ deleteModalIsOpen: false });
                    toast.success("Data deleted successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                }
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }


    render() {
        let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;
        return (
            <Layout {...this.props}>
            <div>
                <Modal style={{textAlign: "center"}} show={this.state.deleteModalIsOpen} onHide={this.closeDeleteModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <BiTrash className="icon-modal"/>
                        <h5>Delete</h5>
                        <p className="small">Are you sure you want to delete this payment method?</p>
                        <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeDeleteModal()}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="btn" variant="danger" onClick={(e) => this.deletePaymentMethodAction()}>
                            Delete
                        </Button>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.addModalIsOpen} onHide={this.closeAddModal}  size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>                        
                        <h6 className="mb-3" data-qaid="paymentMethodCustomerModal">Payment Method</h6>
                        {
                            (!credentialsPermissions.ach.onetime && !credentialsPermissions.ach.recurring && !credentialsPermissions.card.onetime && !credentialsPermissions.card.recurring) && (
                                <div style={{margin: '50px 0 50px 0'}}>
                                    <div className="alert alert-warning small">There are no services activated for this Paypoint. Contact your administrator to enable it.</div>
                                </div>
                            )                        
                        }
                        <Accordion defaultActiveKey="1" className="mb-4">
                        { (credentialsPermissions.ach.onetime || credentialsPermissions.ach.recurring) && 
                            (<Card className="card card-in mb-3 card-hover">
                                <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="1" onClick={(e) => this.setPaymentMethod('ach')}>
                                    <span className="badge bg-dark card-info-badge">Most popular method</span>
                                    <div className="row">
                                        <div className="col-2 text-center">
                                            <BiDollarCircle className="grey-icon" style={{fontSize: '36px'}} />
                                        </div>
                                        <div className="col-10">
                                            Bank account / E-Check
                                            <p className="small small-grey-m0">
                                            Pay from your Bank Account
                                            </p>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <PaymentMethodECheck history={this.props.history} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>)
                        }
                        { (credentialsPermissions.card.onetime || credentialsPermissions.card.recurring)  &&                         
                            (<Card className="card card-in mb-3 card-hover">
                                <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod('card')}>
                                    <div className="row">
                                        <div className="col-2 text-center">
                                            <BiCreditCard className="grey-icon" style={{fontSize: '40px'}} />
                                        </div>
                                        <div className="col-10">
                                            Credit or Debit Card
                                            <p className="small small-grey-m0">
                                                                Use your Credit or Debit Card
                                            </p>
                                            <div className="card-brands accordion-right-corner" style={{position: 'absolute'}}>
                                                <PaymentMethodsAccepted/>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <PaymentMethodCCForm history={this.props.history} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>)
                        }
                        </Accordion>
                        {
                            (!credentialsPermissions.ach.onetime && !credentialsPermissions.ach.recurring && !credentialsPermissions.card.onetime && !credentialsPermissions.card.recurring) ?
                            (
                                <div className="col">
                                    <button className="btn full-w btn-light" type="button" onClick={() => this.closeAddModal() }>Close</button>
                                </div>
                            ):
                                <>
                                <div className="row">
                                    <div className="col">
                                        <button className="btn full-w btn-light" type="button" onClick={() => this.closeAddModal() } data-qaid="cancelButtonPayMethodmodal">Cancel</button>
                                    </div>
                                    <div className="col">
                                        <button className="btn full-w btn-primary" type="button" onClick={() => this.addPaymentMethod()}>+ Add a Payment Method</button>   
                                    </div>
                                </div>
                                </>
                        }
                        
                    </Modal.Body>
                </Modal>
                <TopBar>
                    <MainTopBarMenu />
                    <div className="top-bar-sub">
                        <CustomerLinks gobackLink={'/'+this.props.global.getURLEntry()+'/customer/'+this.state.customerId} gobackText="Customer Overview" history={this.props.history} goback={true} selected="paymethods" id={this.state.customerId} />
                    </div>
                </TopBar>
                <div className="mt-body4">
                    <div className="row mb-3">
                        <div className="col-11">
                            <div className="datatable-actions float-end">
                            <DropdownButton
                            menuAlign="right"
                            title={<div>+ Actions</div>}
                            size="sm"
                            variant="default"
                            >
                                <Dropdown.Item onClick={(e) => this.openAddModal() } eventKey="1">Add a Payment Method</Dropdown.Item>
                            </DropdownButton>
                            </div>

                            <h5>Customer - {this.state.customer ? this.props.global.capitalizeFirstLetterOfEachWord(this.state.customer.Firstname) : ''} {this.state.customer ? this.props.global.capitalizeFirstLetterOfEachWord(this.state.customer.Lastname): ''}</h5>
                            <p className="small-grey">{this.state.customer ? this.state.customer.Email: ''}</p>
                        </div>
                    </div>
                    <div className="mb-3" data-qaid="paymentMethodsPage"><b>Payment Methods</b></div>
                    <div className="row mb-4">
                        <div className="col-11">
                            {
                                (this.state.customer && this.state.customer.StoredMethods) &&
                                 this.state.customer.StoredMethods.map((record, i) => (
                                    <div key={i} className="card-v2 card-payment-method small" >
                                       
                                        <div className="datatable-actions" style={{float: "right"}}>
                                        <DropdownButton
                                            menuAlign="right"
                                            title={<BiChevronDown/>}
                                            id="dropdown-menu-align-right"
                                            size="sm"
                                            variant="default"
                                        >
                                        <Dropdown.Item style={{color:"rgb(192, 0, 0)"}} onClick={(e)=>this.deletePaymentMethod(record.IdPmethod)}>Delete</Dropdown.Item>
                                        </DropdownButton>
                                        </div>

                                        <div className="right-panel-card mb-3">
                                            { record.Descriptor ? this.props.global.getPaymethodImg(record.Descriptor) : '-'}
                                        </div>

                                        <div className="row">
                                            <div className="col-7">
                                                { record.Method === 'card' ?
                                                <label className="grey">Card Number </label>
                                                :
                                                <label className="grey">Account Number</label>
                                                }
                                                <br/>
                                                •••• •••• {record.MaskedAccount ?  this.props.global.maskedCardNumber(record.MaskedAccount, 'v5') : '-'}
                                            </div>
                                            { record.Method === 'card' ?
                                            <div className="col-5 text-center">
                                                <label className="grey">Expires on</label>
                                                <h6 style={{fontWeight: 500}}>
                                                    {record.ExpDate ?  this.props.global.expDateFormat(record.ExpDate) : '-'}
                                                </h6>
                                            </div>
                                            :
                                            <div className="col-5 text-center">
                                                <label className="grey">Account Type</label>
                                                <h6 style={{fontWeight: 500}}>
                                                    {record.Descriptor ?  record.Descriptor: '-'}
                                                </h6>
                                            </div>
                                            }
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                { record.Method === 'card' ?
                                                <label className="grey">Cardholder Name </label>
                                                :
                                                <label className="grey">Account Holder Name </label>
                                                }
                                                <br/>
                                                {record.HolderName ?  record.HolderName : '-' }
                                               
                                            </div>
                                        </div>
                                        <hr className="hr-m1" />
                                        {/*<div className="blue-text">0 active scheduled payments</div>*/}
                                    </div>
                                 ))
                            }
                            <div className="card-payment-method text-center card-payment-method-add">
                                <AiOutlinePlus style={{fontSize: '60px', color: "#cccccc", marginBottom: "15px"}}/>
                                <br/>
                                <button onClick={() => this.openAddModal()} className="btn btn-primary">Add a Payment Method</button>
                            </div>
                            </div>
                    </div>
                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { PayMethods };