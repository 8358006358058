import { action } from 'mobx'

export default function TemplateOwnerFunction({
	ownershipData,
	setOwnershipData,
	insertValues,
	handleChange
}) {
	const fieldDataOwner = (data, templateCreator, newTemplate) => {
		let newOwnershipData = {}
		let structure = {
			visible: true,
			subHeader: '',
			subFooter: '',
			multipleContacts: true,
			multipleOwners: true
		}
		for (let element in structure) {
			newOwnershipData[element] = insertValues(
				data[templateCreator],
				'ownershipData',
				element,
				structure[element],
				'oneElement'
			)
		}

		structure = {
			ownername: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			ownertitle: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			ownerpercent: {
				posRow: 0,
				posCol: 0,
				value: 25,
				visible: true,
				readOnly: false,
				required: false
			},
			ownerssn: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			ownerdob: {
				posRow: 0,
				posCol: 0,
				value: !newTemplate ? 'date' : 'null',
				visible: true,
				readOnly: false,
				required: false
			},
			ownerphone1: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			ownerphone2: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			owneremail: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			ownerdriver: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			odriverstate: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			oaddress: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			ocity: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			ostate: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			ozip: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			ocountry: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			contactName: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			contactTitle: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			contactEmail: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			},
			contactPhone: {
				posRow: 0,
				posCol: 0,
				value: '',
				visible: true,
				readOnly: false,
				required: false
			}
		}
		for (let element in structure) {
			newOwnershipData[element] = insertValues(
				data[templateCreator],
				'ownershipData',
				element,
				structure[element]
			)
		}
		setOwnershipData(newOwnershipData)
	}
	const handleChangeOwner = action(
		(property, field, value, onlyField = false) => {
			handleChange(
				ownershipData,
				setOwnershipData,
				property,
				field,
				value,
				onlyField
			)
		}
	)

	return { handleChangeOwner, fieldDataOwner }
}
