import React from 'react';
import { inject, observer } from 'mobx-react';
import NumberFormat from 'react-number-format';
import 'rc-slider/assets/index.css';
import TooltipSlider from './TooltipSlider.js'
import Switch from "react-switch";

@inject('reports', 'filter')
@observer
class ComponentRangeAmount extends React.Component {
    constructor(props) {
        super(props);
        this.state = { menuAdvanced: {}, inlineRadio: {}, filterType: 'eq', placeholder: '' };
        this.handleChangeMenuAdvanced = this.handleChangeMenuAdvanced.bind(this);
        this.handleRadioOption = this.handleRadioOption.bind(this);
        this.handleChangePlaceholder = this.handleChangePlaceholder.bind(this);
    }

    handleChangeMenuAdvanced = () => {
        const { id } = this.props
        let tempMenuAdvanced = this.state.menuAdvanced, tempInlineRadio = {}
        tempMenuAdvanced[id] = !tempMenuAdvanced[id]
        this.setState({menuAdvanced: tempMenuAdvanced, inlineRadio: tempInlineRadio})
    }

    handleRadioOption = (event, type) => {
        const { filterParameter } = this.props
        this.props.filter.clearFilterAmount(filterParameter, type)
        this.handleChangePlaceholder(type)
        const {id, checked} = event.target
        let tempInlineRadio = {}
        tempInlineRadio[id] = checked
        this.setState({inlineRadio: tempInlineRadio, filterType: type})
    }

    handleChangePlaceholder = (type) => {
        let placeholder = this.state.placeholder
        switch (type) {
            case 'gt':
            case 'ge':
                placeholder = '$ min.'
                break;
            case 'lt':
            case 'le':
                placeholder = '$ max.'
                break;        
            case 'eq':
                placeholder = '$ equal.'
                break;        
            default:
                placeholder = ''
                break;
        }
        this.setState({placeholder: placeholder})
    }

    render() {
        const { id, title, minRange, maxRange, minParameter, maxParameter, filterParameter } = this.props
        return (<>
                <span>
                    <small>{title}</small>
                    <Switch
                        checked={this.state.menuAdvanced[id] || false}
                        onChange={this.handleChangeMenuAdvanced}
                        onColor="#10A0E3"
                        onHandleColor="#ffffff"
                        handleDiameter={20}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={38}
                        className="react-switch-slider-menu"
                    />
                    <small className='text-uppercase float-end' style={{marginRight: '0.5rem', marginTop: '2px'}}>Advanced</small>
                </span>
                {this.state.menuAdvanced[id] &&
                    <div className="row form-group mt-3 mb-4" style={{fontSize: '20px', marginLeft: '0'}}>
                        <div className="col-auto mr-2 icheck-primary">
                            <input type="radio" name={`inlineRadioLessThan${id}`} id={`inlineRadioLessThan${id}`} value="0" checked={this.state.inlineRadio[`inlineRadioLessThan${id}`] ? true : false} onChange={(e) => this.handleRadioOption(e, 'lt')} />
                            <label htmlFor={`inlineRadioLessThan${id}`}>Less than</label>
                        </div>
                        <div className="col-auto mr-2 icheck-primary">
                            <input type="radio" name={`inlineRadioLessThanEqual${id}`} id={`inlineRadioLessThanEqual${id}`} value="1" checked={this.state.inlineRadio[`inlineRadioLessThanEqual${id}`] ? true : false} onChange={(e) => this.handleRadioOption(e, 'le')} />
                            <label htmlFor={`inlineRadioLessThanEqual${id}`}>Less than and equal to</label>
                        </div>
                        <div className="col-auto mr-2 icheck-primary">
                            <input type="radio" name={`inlineRadioEqual${id}`} id={`inlineRadioEqual${id}`} value="2" checked={this.state.inlineRadio[`inlineRadioEqual${id}`] ? true : false} onChange={(e) => this.handleRadioOption(e, 'eq')} />
                            <label htmlFor={`inlineRadioEqual${id}`}>Equal to</label>
                        </div>
                        <div className="col-auto mr-2 icheck-primary">
                            <input type="radio" name={`inlineRadioGreaterThan${id}`} id={`inlineRadioGreaterThan${id}`} value="3" checked={this.state.inlineRadio[`inlineRadioGreaterThan${id}`] ? true : false} onChange={(e) => this.handleRadioOption(e, 'gt')} />
                            <label htmlFor={`inlineRadioGreaterThan${id}`}>Greater than</label>
                        </div>
                        <div className="col-auto mr-2 icheck-primary">
                            <input type="radio" name={`inlineRadioGreaterThanEqual${id}`} id={`inlineRadioGreaterThanEqual${id}`} value="4" checked={this.state.inlineRadio[`inlineRadioGreaterThanEqual${id}`] ? true : false} onChange={(e) => this.handleRadioOption(e, 'ge')} />
                            <label htmlFor={`inlineRadioGreaterThanEqual${id}`}>Greater than and equal to</label>
                        </div>
                    </div>
                }
                {Object.keys(this.state.inlineRadio).length > 0 ? 
                <>
                    <div className='mt-5 mb-3'>
                        <div className="form-group">
                            <TooltipSlider
                                min={minRange}
                                max={maxRange}
                                value={this.props.filter.rangeNumber[minParameter]}
                                allowCross={false}
                                reverse={this.state.inlineRadio[`inlineRadioGreaterThan${id}`] || this.state.inlineRadio[`inlineRadioGreaterThanEqual${id}`] ? true : false}
                                draggableTrack
                                trackStyle={ this.props.filter.rangeTrackStyle }
                                handleStyle={ (this.state.inlineRadio[`inlineRadioLessThanEqual${id}`] || this.state.inlineRadio[`inlineRadioGreaterThanEqual${id}`] || this.state.inlineRadio[`inlineRadioEqual${id}`]) ? this.props.filter.rangeHandleStyleBlue : this.props.filter.rangeHandleStyle }
                                railStyle={ this.state.inlineRadio[`inlineRadioEqual${id}`] ? this.props.filter.rangeTrackStyle : this.props.filter.rangeRailStyle }
                                onChange={(e) => this.props.filter.filterByRangeNumberWithSlider(minParameter, `${filterParameter}(${this.state.filterType})`, e)}
                                tipFormatter={(value) => `${(this.state.inlineRadio[`inlineRadioGreaterThan${id}`] || this.state.inlineRadio[`inlineRadioGreaterThanEqual${id}`]) ? '-' : ''}$${value?.toFixed(2)}`}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    decimalSeparator="."
                                    displayType="input"
                                    type="text"
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix="$"
                                    value={this.props.filter.rangeNumber[minParameter] ? this.props.filter.rangeNumber[minParameter] : ''}
                                    placeholder={this.state.placeholder}
                                    className="form-control"
                                    onValueChange={(values) => this.props.filter.filterByRangeNumber(minParameter, `${filterParameter}(${this.state.filterType})`, values)}
                                    onKeyDown={(e) => this.props.filter.handleKeyDownByRange(minParameter, `${filterParameter}(${this.state.filterType})`, e)}
                                />
                                <label>{this.state.placeholder}</label>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='mt-5 mb-3'>
                        <div className="form-group">
                            <TooltipSlider
                                range 
                                min={minRange}
                                max={maxRange}
                                value={[this.props.filter.rangeNumber[minParameter] ? this.props.filter.rangeNumber[minParameter] : 0, this.props.filter.rangeNumber[maxParameter] ? this.props.filter.rangeNumber[maxParameter] : 750]}
                                allowCross={false}
                                draggableTrack
                                trackStyle={ this.props.filter.rangeTrackStyle }
                                handleStyle={ this.props.filter.rangeHandleStyle }
                                railStyle={ this.props.filter.rangeRailStyle }
                                onChange={(e) => {
                                    this.props.filter.filterByRangeNumberWithSlider(minParameter, `${filterParameter}(ge)`, e[0]); 
                                    this.props.filter.filterByRangeNumberWithSlider(maxParameter, `${filterParameter}(le)`, e[1]);
                                    (e[1] === 1000 && this.props.filter.filterByGreaterValue(`${minParameter}|${maxParameter}`, filterParameter, e[1]));
                                }}
                                tipFormatter={(value) => `$${value?.toFixed(2)}`}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    decimalSeparator="."
                                    displayType="input"
                                    type="text"
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix="$"
                                    value={this.props.filter.rangeNumber[minParameter] ? this.props.filter.rangeNumber[minParameter] : ''}
                                    isAllowed={(values) => {
                                        if (values.value === '' || values.value === null || !this.props.filter.rangeNumber[maxParameter]) {
                                            return true;
                                        }
                                        return values.floatValue < Number(this.props.filter.rangeNumber[maxParameter]);                                        
                                    }}
                                    placeholder={"$ min."}
                                    className="form-control"
                                    onValueChange={(values) => this.props.filter.filterByRangeNumber(minParameter, `${filterParameter}(ge)`, values)}
                                    onKeyDown={(e) => this.props.filter.handleKeyDownByRange(minParameter, `${filterParameter}(ge)`, e)}
                                />
                                <label>{"$ min."}</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    decimalSeparator="."
                                    displayType="input"
                                    type="text"
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix="$"
                                    value={this.props.filter.rangeNumber[maxParameter] ? this.props.filter.rangeNumber[maxParameter] : ''}
                                    placeholder={"$ max."}
                                    className="form-control"
                                    onValueChange={(values) => this.props.filter.filterByRangeNumber(maxParameter, `${filterParameter}(le)`, values)}
                                    onKeyDown={(e) => this.props.filter.handleKeyDownByRange(maxParameter, `${filterParameter}(le)`, e)}
                                />
                                <label>{"$ max."}</label>
                            </div>
                        </div>
                    </div>
                </>
                }
            </>);
    }
}

export { ComponentRangeAmount };