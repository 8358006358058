import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { OrganizationOverviewLinks } from '../../components/OrganizationOverviewLinks';
import {IMaskInput} from 'react-imask';

import {BiDollar} from '@react-icons/all-files/bi/BiDollar';
import {BiDollarCircle} from '@react-icons/all-files/bi/BiDollarCircle';
import {BiEdit} from '@react-icons/all-files/bi/BiEdit';
import {BiGridSmall} from '@react-icons/all-files/bi/BiGridSmall';
import {BiImageAdd} from '@react-icons/all-files/bi/BiImageAdd';
import {BiInfoCircle} from '@react-icons/all-files/bi/BiInfoCircle';
import {BiRepost} from '@react-icons/all-files/bi/BiRepost';
import {BiStore} from '@react-icons/all-files/bi/BiStore';
import { LocationSearchInput } from '../../components/LocationSearchInput';
import { Brand } from '../../components/Brand';


@inject('entry', 'global')
@observer
class OrgDetails extends React.Component {

    legalName = () => {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return encryptStorage.getItem(this.props.global.getURLEntry()+"_pEntry").legalName;
    }

    constructor(props) {
        super(props);
        this.state = {
            idOrg:null,
            organization : null,
            editOrgInfoModalIsOpen: false,
            
            orgName: '',
            orgId: '',
            orgWebsite: '',
            orgAddress: '',
            orgAddress2: '',
            orgCity: '',
            orgState: '',
            orgZip: '',
            orgCountry: '',

            orgACHAccount:'',
            orgACHRouting:'',

            orgBillingAddress: '',
            orgBillingAddress2: '',
            orgBillingAddressValue: '',
            orgBillingCity: '',
            orgBillingState: '',
            orgBillingZip: '',
            orgBillingCountry: '',

            orgErrors :{},
            fileError: false,
            disableAutoReceipt: false,
            autoCapture: false,
            general: [],
            forPayOuts: []
        };
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.showError = this.showError.bind(this);
        this.closeOrgInfoModal = this.closeOrgInfoModal.bind(this);
        this.openOrgInfoModal = this.openOrgInfoModal.bind(this);
        this.validateOrgFields = this.validateOrgFields.bind(this);
        this.saveOrg = this.saveOrg.bind(this);
        this.handleGoogleAutocompleteAddress = this.handleGoogleAutocompleteAddress.bind(this);
        this.handleGoogleAutocompleteBillingAddress = this.handleGoogleAutocompleteBillingAddress.bind(this);
        this.getOrganization = this.getOrganization.bind(this);
        this.handleFileRead = this.handleFileRead.bind(this);
        this.selectFileLogo = this.selectFileLogo.bind(this);
        this.updateLogo = this.updateLogo.bind(this);
        this.inputFileLogo = React.createRef();
    }
    handleCheckChange(event) {
        let thisObj = this;
        let values = {forPayOuts:null, general: null};
        const { id, checked } = event.target;
        let idOrg = this.props.match.params.id;

        this.props.global.setLoading(true);
        this.setState({ [id]: checked }, () => {
            if(id === 'disableAutoReceipt'){
                let general = thisObj.state.general;
                general.forEach(function (item) {
                    if(item.key === "disableAutoReceipt"){
                        item.readOnly = false;
                        item.value = checked.toString();
                    }
                });
                values.general = general;
            }
            if(id === 'autoCapture'){
                let forPayOuts = thisObj.state.forPayOuts;
                if(!forPayOuts.length > 0 ){
                    forPayOuts = [{readOnly: false, key: "autoCapture", value: checked.toString()}];
                }
                else{
                    forPayOuts.forEach(function (item) {
                        if(item.key === "autoCapture"){
                            item.readOnly = false;
                            item.value = checked.toString();
                        }
                    });
                }
                values.forPayOuts = forPayOuts;
            }
            thisObj.props.entry.updateOrgSettings(idOrg, null, null, null, values.forPayOuts ? values.forPayOuts : null, values.general ? values.general : null).then(res => {
                toast.success("Settings Fields updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
                thisObj.props.global.setLoading(false);
            })
            .catch(error => {
                thisObj.showError(error);
                thisObj.props.global.setLoading(false);
            });
        });
    }

    async loadSettingsData(idOrg) {
        this.props.global.setLoading(true);
        const paypointSettings = await this.props.entry.getOrganizationSettings(idOrg);
    
        const { general, forPayOuts } = paypointSettings;
        let thisObj = this;
        if (general !== null) {
            general.forEach(function (item) {
                if(item.key === "disableAutoReceipt"){
                    thisObj.setState({
                        disableAutoReceipt: item.value === "true",
                        general: general,
                    }, () => {
                        thisObj.props.global.setLoading(false);
                    })
                }
            });
        }

        if (forPayOuts !== null) {
            forPayOuts.forEach(function (item) {
                if(item.key === "autoCapture"){
                    thisObj.setState({
                        autoCapture: item.value === "true",
                        forPayOuts: forPayOuts,
                    }, () => {
                        thisObj.props.global.setLoading(false);
                    })
                }
            });
        }
      }

    handleTextChange(e){
        this.setState({ [e.target.id]: e.target.value });
    }

    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }
    
    componentDidMount() {
        this.props.global.setLoading(true);
        let idOrg = this.props.match.params.id;
        this.getOrganization(idOrg);
        this.loadSettingsData(idOrg);
        this.setState({idOrg:idOrg});
    }

    getOrganization(idOrg){
        this.props.entry.getOrganization(idOrg).then(res => {
            
            this.setState({
                organization: res,
                orgName: res.OrgName,
                orgId: res.OrgId,
                orgWebsite: res.OrgWebsite,
                orgAddress: res.OrgAddress,
                orgCity: res.OrgCity,
                orgState: res.OrgState,
                orgZip: res.OrgZip,
                orgCountry: res.OrgCountry,

                orgACHAccountMasked: res.BillingInfo.achAccount ? res.BillingInfo.achAccount: '',
                orgACHRoutingMasked: res.BillingInfo.achRouting ? res.BillingInfo.achRouting : '',


                orgBillingAddress:  res.BillingInfo.billingAddress,
                orgBillingCity: res.BillingInfo.billingCity,
                orgBillingState: res.BillingInfo.billingState,
                orgBillingZip: res.BillingInfo.billingZip,
                orgBillingCountry: res.BillingInfo.billingCountry,
            });
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.showError(error);
            this.props.global.setLoading(false);
        });
    }

    handleGoogleAutocompleteAddress(orgAddress, orgCity, orgState, orgZip, orgCountry){
        this.setState({
            orgAddress : orgAddress ?  orgAddress : '',
            orgCity : orgCity ?  orgCity : '',
            orgState : orgState ?  orgState : '',
            orgZip : orgZip ?  orgZip : '',
            orgCountry : orgCountry ?  orgCountry : ''
         });
    }


    handleGoogleAutocompleteBillingAddress(billingAddress, billingCity, billingState, billingZip, billingCountry){
        this.setState({
            orgBillingAddress : billingAddress ?  billingAddress : '',
            orgBillingCity : billingCity ?  billingCity : '',
            orgBillingState : billingState ?  billingState : '',
            orgBillingZip : billingZip ?  billingZip : '',
            orgBillingCountry : billingCountry ?  billingCountry : ''
         });
    }


    closeOrgInfoModal(){
        this.setState({editUserInfoModalIsOpen:false})
    }

    openOrgInfoModal(e){
        if(e){
            e.preventDefault();
        }
        this.setState({editUserInfoModalIsOpen:true})
    }

    showError(error){
        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
        toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
        });
    }

    handleFileRead(e){
        let file = e.target.files[0];
        if(file){
            let fileExtension = this.props.global.getFileExtension(file.type);

            if( (fileExtension === "jpeg" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "gif") && file.size <= 2000000){
                this.props.global.readFileBase64(file).then(base64 => {
                    let base64string = base64.split(',');
                    if(base64string[0] &&  base64string[1]){
                        this.props.entry.setLogo(base64string[1],fileExtension);
                    }
                    this.props.entry.setPreviewLogo(base64);
                    this.updateLogo();
                    this.setState({fileError: false});
                })
                .catch(error => {
                    console.log(error);
                });
            }
            else{
                this.setState({fileError: true});
            }
            
        }
        
    }

    updateLogo(){
        let org ={
            orgName: this.state.orgName,
            orgLogo: this.props.entry.logo,
            orgId: this.state.organization.idOrg
        };

        this.props.entry.updateOrgLogo(org)
        .then(result => {
            this.getOrganization(this.state.organization.idOrg);
            toast.success("Profile updated successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container'
            });
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    saveOrg(){
        let errors = this.validateOrgFields();
        this.setState({ orgErrors: errors }, function(){
            
            if(Object.keys(errors).length === 0){
                let billingInfo = {
                    billingAddress: this.state.orgBillingAddress,
                    billingCity: this.state.orgBillingCity,
                    billingState: this.state.orgBillingState,
                    billingZip: this.state.orgBillingZip,
                    billingCountry: this.state.orgBillingCountry,
                };
                if(this.state.orgACHRouting && this.state.orgACHRouting !== ''){
                    billingInfo.achRouting = this.state.orgACHRouting;
                    billingInfo.achAccount = this.state.orgACHAccount;
                }

                let org ={
                    orgId: this.state.orgId,
                    orgName: this.state.orgName,
                    orgWebsite: this.state.orgWebsite,
                    orgAddress: this.state.orgAddress,
                    orgCity: this.state.orgCity,
                    orgState: this.state.orgState,
                    orgZip: this.state.orgZip,
                    orgCountry: this.state.orgCountry,
                    billingInfo: billingInfo
                };
                this.props.global.setLoading(true);   
                this.props.entry.updateOrgInfo(org, this.state.organization.idOrg)
                .then(result => {
                    this.closeOrgInfoModal();
                    this.props.global.setLoading(false);
                    toast.success("Profile updated successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                    this.getOrganization(this.state.organization.idOrg);
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
        });
    }

    selectFileLogo(){
        this.inputFileLogo.current.click();
    }

    
    validateOrgFields(){
        let validators = this.props.global.validators;
        let errors = {};
        if(validators.isEmpty(this.state.orgName) || validators.isMaxLength(250, this.state.orgName))
        {
            errors['orgName']= true;
        }

        if(validators.isEmpty(this.state.orgWebsite) || validators.isMaxLength(250, this.state.orgWebsite) || validators.stringValidator('url', this.state.orgWebsite))
        {
            errors['orgWebsite']= true;
        }

        if (validators.isEmpty(this.state.orgAddress) || validators.isMaxLength(250, this.state.orgAddress)  || this.state.orgZip === null || this.state.orgZip === '' ||  validators.stringValidator('numbers', this.state.orgZip)) {
            errors['orgAddress'] = true;
            
        }

        if (!validators.isEmpty(this.state.orgACHAccount) || !validators.isEmpty(this.state.orgACHRouting)){
            if (validators.isEmpty(this.state.orgACHAccount) || validators.isMaxLength(250, this.state.orgACHAccount) || validators.stringValidator('numbers', this.state.orgACHAccount) || validators.isMinLength(4, this.state.orgACHAccount) || validators.isMaxLength(17, this.state.orgACHAccount)) {
                errors['orgACHAccount'] = true;
            }
            
            if(validators.isEmpty(this.state.orgACHRouting) || validators.isMaxLength(9, this.state.orgACHRouting) || validators.stringValidator('routing', this.state.orgACHRouting)) {
                errors['orgACHRouting'] = true;
            }
        }

        if (validators.isEmpty(this.state.orgBillingAddress)) {
            errors['orgBillingAddress'] = true;
        }
        

        return errors;
    }

   
    render() {
        
        return (
            <Layout {...this.props}>
            <div>

               
                <Modal show={this.state.editUserInfoModalIsOpen} onHide={this.closeOrgInfoModal}  size="md" centered >
                <Modal.Body className="popover-body">
                        <div className="row mb-3">
                            <div className="col"><h6>Edit Org. Information</h6></div>
                        </div>
                        
                        <div className="row mb-4">
                                <div className="col-12 mb-3">
                                    <div className="form-floating">
                                        <input value={this.state.orgId} id="orgId" onChange={(e) => this.handleTextChange(e)} className={this.state.orgErrors.orgId ? "form-control input-error" : "form-control"} placeholder="Organization Identifier" />
                                        <label htmlFor="orgName">Organization Identifier(ID)</label>
                                    </div>
                                </div>
                            <div className="col-12 mb-3">
                            <div className="form-floating">
                                <input value={this.state.orgName} id="orgName" onChange={(e) => this.handleTextChange(e)} className={this.state.orgErrors.orgName ? "form-control input-error" : "form-control"} placeholder="Organization Name" />
                                <label htmlFor="orgName">Organization Name</label>
                            </div>
                            </div>
 

                            <div className="col-12 mb-3">
                            <div className="form-floating">
                                <input value={this.state.orgWebsite} id="orgWebsite" onChange={(e) => this.handleTextChange(e)} className={this.state.orgErrors.orgWebsite ? "form-control input-error" : "form-control"} placeholder="Website" />
                                <label htmlFor="orgWebsite">Website</label>
                            </div>
                            </div>
                            
                            <div className="col-12 mb-3">
                            <div  className={this.state.orgErrors.orgAddress ? "input-error-cont" : ""}>
                                <LocationSearchInput 
                                    placeholder="Address" 
                                    onChange={this.handleGoogleAutocompleteAddress} 
                                    handleTextChange={this.handleTextChangeMask} 
                                    address={this.state.orgAddress}
                                    address2={this.state.orgAddress2}
                                    city={this.state.orgCity}
                                    state={this.state.orgState}
                                    zipcode={this.state.orgZip}
                                    country={this.state.orgCountry}
                                    withCountry="true"
                                    handleTextChangeKeys={['orgAddress','orgAddress2', 'orgCity', 'orgState', 'orgZip', 'orgCountry']}
                                />
                            </div>
                            </div>

                            <b className="mb-3 mt-2">Billing Info</b>

                            <div className="col-6 mb-3">
                                <div className="form-floating">
                                    <IMaskInput
                                        mask={this.props.global.maskValidator('numbers')}
                                        name="orgACHAccount"
                                        value={this.state.orgACHAccount}
                                        unmask={true}                                        
                                        onAccept={
                                            (value, mask) => this.handleTextChangeMask('orgACHAccount', value)
                                        }
                                        placeholder={"ACH Account " + this.props.global.maskedCardNumber(this.state.orgACHAccountMasked, "v5")}
                                        className={this.state.orgErrors.orgACHAccount ? "form-control input-error" : "form-control"}
                                        id="orgACHAccount"
                                    />
                                    <label htmlFor="orgACHAccount">{"ACH Account " + this.props.global.maskedCardNumber(this.state.orgACHAccountMasked, "v5")}</label>
                                </div>
                            </div>
                            <div className="col-6 mb-3">
                                <div className="form-floating">
                                   <IMaskInput
                                        mask={this.props.global.maskValidator('routing')}
                                        name="orgACHRouting"
                                        value={this.state.orgACHRouting}
                                        unmask={true}                                        
                                        onAccept={
                                            (value, mask) => this.handleTextChangeMask('orgACHRouting', value)
                                        }
                                        placeholder={"ACH Routing " + this.props.global.maskedCardNumber(this.state.orgACHRoutingMasked, "v5")}
                                        className={this.state.orgErrors.orgACHRouting ? "form-control input-error" : "form-control"}
                                        id="orgACHRouting"
                                    />
                                    <label htmlFor="achRouting">{"ACH Routing " + this.props.global.maskedCardNumber(this.state.orgACHRoutingMasked, "v5")}</label>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className={this.state.orgErrors.orgBillingAddress ? "input-error-cont" : ""}>
                                    <LocationSearchInput 
                                        placeholder="Billing Address" 
                                        onChange={this.handleGoogleAutocompleteBillingAddress} 
                                        handleTextChange={this.handleTextChangeMask} 
                                        address={this.state.orgBillingAddress}
                                        address2={this.state.orgBillingAddress2}
                                        city={this.state.orgBillingCity}
                                        state={this.state.orgBillingState}
                                        zipcode={this.state.orgBillingZip}
                                        country={this.state.orgBillingCountry}
                                        withCountry="true"
                                        handleTextChangeKeys={['orgBillingAddress','orgBillingAddress2', 'orgBillingCity', 'orgBillingState', 'orgBillingZip', 'orgBillingCountry']}
                                    />
                                </div>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col">
                                <button className="btn full-w btn-light" type="button" onClick={() => this.closeOrgInfoModal() }>Cancel</button>
                            </div>
                            <div className="col">
                            <button className="btn full-w btn-primary" type="button" onClick={() => this.saveOrg()}>Save</button>   
                            </div>
                        </div>  
                </Modal.Body>
                </Modal>

                

                <div className="mt-body4">
                <div>
                <TopBar>
                    <MainTopBarMenu />
                    <div className="top-bar-sub">
                        <OrganizationOverviewLinks goback={true} gobackLink={'/'+this.props.global.getURLEntry() + '/report/organizations'} gobackText="Org. Report" idOrg={this.state.idOrg} selected="overview"/>
                    </div>
                </TopBar>
                    <div className="row mb-4">
                        <div className="col-12">
                            <h5 className="fl-capitalize">Organization - {this.state.organization ? this.state.organization.OrgName:'-'}</h5>
                            <p className="small-grey">View and edit organization information, services enabled, and contacts</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 mb-5 full-1260 order-1260-2">
                            <h6 className="sub-header-line mb-1  mb-4" data-qaid="organizationDetails">
                                Details   
                                <a href="/" className="float-end" style={{fontSize: "18px"}} onClick={(e) => this.openOrgInfoModal(e)}><BiEdit/></a>
                            </h6>
                            
                            <div className="row small mb-3 dark-grey">
                                <div className="col-5"><b>Profile</b></div>
                                <div className="col-7 fl-capitalize">
                                    <div className="profile-image mb-2" onClick={(e)=>this.selectFileLogo()}>
                                        {this.props.entry?.previewLogo ?
                                                <Brand classBrand={'full-w'} imageBrand={this.props.entry.previewLogo} />
                                            :
                                                <>
                                                    {this.state.organization?.OrgLogo ?
                                                        <Brand classBrand={'full-w'} imageBrand={this.state.organization.OrgLogo} />
                                                    :
                                                        <BiImageAdd/>
                                                    }                                                
                                                </>
                                        }
                                        <input className="hide" ref={this.inputFileLogo} type="file" onChange={(e) => this.handleFileRead(e)} />
                                    </div>
                                    {this.state.fileError && 
                                        <div className="small-small text-danger">File size or extension not allowed</div>
                                    }
                                </div>
                            </div>
                                    <div className="row small mb-2 dark-grey">
                                        <div className="col-5"><b>ID</b></div>
                                        <div className="col-7 fl-capitalize">{this.state.organization ? this.state.organization.OrgId : '-'}</div>
                                    </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>Organization</b></div>
                                <div className="col-7 fl-capitalize">{this.state.organization ? this.state.organization.OrgName:'-'}</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>Website</b></div>
                                <div className="col-7"><a rel="noreferrer" target="_blank" href={this.state.organization && this.state.organization.OrgWebsite? this.state.organization.OrgWebsite:'-'}>{this.state.organization ? this.state.organization.OrgWebsite:'-'}</a></div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>Address</b></div>
                                <div className="col-7">{this.state.organization && this.state.organization.OrgAddress ? this.state.organization.OrgAddress:'-'}</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>City</b></div>
                                <div className="col-7">{this.state.organization && this.state.organization.OrgCity? this.state.organization.OrgCity:'-'}</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>State</b></div>
                                <div className="col-7">{this.state.organization && this.state.organization.OrgState ? this.state.organization.OrgState:'-'}</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>Zip</b></div>
                                <div className="col-7">{this.state.organization && this.state.organization.OrgZip ? this.state.organization.OrgZip:'-'}</div>
                            </div>
                            <div className="row small mb-5 dark-grey">
                                <div className="col-5"><b>Country</b></div>
                                <div className="col-7">{this.state.organization && this.state.organization.OrgCountry ? this.state.organization.OrgCountry:'-'}</div>
                            </div>

                            <h6 className="sub-header-line mb-1  mb-4">
                                Billing Information   
                            </h6>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>Billing Address</b></div>
                                <div className="col-7">{this.state.organization && this.state.organization.BillingInfo && this.state.organization.BillingInfo.billingAddress ? this.state.organization.BillingInfo.billingAddress :'-'}</div>
                            </div>
                            { (this.state.organization && this.state.organization.BillingInfo && this.state.organization.BillingInfo.billingAddress) &&
                            <div className="row small mb-5 dark-grey">
                                <div className="col-5"></div>
                                <div className="col-7">{this.state.organization.BillingInfo.billingCity + " " + this.state.organization.BillingInfo.billingState + ", " + this.state.organization.BillingInfo.billingZip + ". " + this.state.organization.BillingInfo.billingCountry }</div>
                            </div>
                            }
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>ACH Account</b></div>
                                <div className="col-7">{this.state.organization && this.state.organization.BillingInfo && this.state.organization.BillingInfo.achAccount ? this.props.global.maskedCardNumber(this.state.organization.BillingInfo.achAccount, "v5") :'-'}</div>
                            </div>
                            <div className="row small mb-5 dark-grey">
                                <div className="col-5"><b>ACH Routing</b></div>
                                <div className="col-7">{this.state.organization && this.state.organization.BillingInfo && this.state.organization.BillingInfo.achRouting ? this.props.global.maskedCardNumber(this.state.organization.BillingInfo.achRouting, "v5") :'-'}</div>
                            </div>
                            <h6 className="sub-header-line mb-1  mb-4">
                                General Settings  
                            </h6>                           
                            <div className="mb-3 d-flex flex-column ">
                                <div className="icheck-primary">
                                    <input type="checkbox" name="autoCapture" id="autoCapture" value={this.state.autoCapture} checked={this.state.autoCapture} onChange={(e) => this.handleCheckChange(e)}/>
                                    <label htmlFor="autoCapture">Auto-capture</label>
                                </div>
                                <div className="icheck-primary">
                                    <input type="checkbox" name="disableAutoReceipt" id="disableAutoReceipt" value={this.state.disableAutoReceipt} checked={this.state.disableAutoReceipt} onChange={(e) => this.handleCheckChange(e)}/>
                                    <label htmlFor="disableAutoReceipt">Receipts from Autopay</label>
                                </div>
                            </div>
                            <h6 className="sub-header-line mb-1  mb-4">
                                Services  
                            </h6>
                           
                            <div className="small mb-3 dark-grey">
                                { (this.state.organization && this.state.organization.Services && this.state.organization.Services.length) ?
                                    
                                    this.state.organization.Services.map((record, i) => (
                                        <span key={i} style={{marginRight: "30px"}}>
                                        <BiInfoCircle className="green-text" style={{fontSize: '20px'}}/> {record.name}
                                        </span>
                                    ))
                                    :
                                    "-"
                                }
                            </div>


                        </div>
                        <div className="col-lg-8 offset-lg-1 dashboard-items full-1260 order-1260-1 offset-1260-0">
                            <div className="row mb-3 overview-stats">
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle orange">
                                        <BiGridSmall/>
                                    </div>
                                    <label>Sub-Organizations</label><br/>
                                    <b>{ this.state.organization && this.state.organization.summary ? this.state.organization.summary.childOrgs : "0" }</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle blue">
                                        <BiRepost/>
                                    </div>
                                    <label>Active Autopays</label><br/>
                                    <b>{ this.state.organization && this.state.organization.summary ? this.state.organization.summary.countSubs : "0" }</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle blue">
                                    <BiDollar/>
                                    </div>
                                    <label>Autopay Volume</label><br/>
                                    <b>${this.state.organization && this.state.organization.summary ? this.props.global.numberWithCommas(this.state.organization.summary.amountSubs.toFixed(2)) : "0.00"}</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle red">
                                        <BiStore/>
                                    </div>
                                    <label>Paypoints</label><br/>
                                    <b>{ this.state.organization && this.state.organization.summary ? this.state.organization.summary.childPaypoints : "0" }</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle green">
                                        <BiDollarCircle/>
                                    </div>
                                    <label>Transactions</label><br/>
                                    <b>{ this.state.organization && this.state.organization.summary ? this.state.organization.summary.countTx : "0" }</b>
                                </div>
                                
                               
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle green">
                                        <BiDollar/>
                                    </div>
                                    <label>Gross Volume</label><br/>
                                    <b>${this.state.organization && this.state.organization.summary ? this.props.global.numberWithCommas(this.state.organization.summary.amountTx.toFixed(2)) : "0.00"}</b>
                                </div>

                               
                            </div>

                            <h6 className="sub-header-line mb-1">
                                Contacts
                            </h6>

                            {(this.state.organization && this.state.organization.Contacts  && this.state.organization.Contacts ) ?
                            <div className="report-container height-1260-auto">
                            <table className="table table-hover table-striped mb-5">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-center">Email</th>
                                        <th className="text-center">Phone</th>
                                        <th className="text-right">Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    this.state.organization.Contacts.map((record, i) => (
                                    <tr key={i}>
                                        <td>{record.ContactName}</td>
                                        <td className="text-center">{record.ContactEmail}</td>
                                        <td className="text-center">{record.ContactPhone}</td>
                                        <td className="text-right">{record.ContactTitle}</td>
                                    </tr>
                                      ))
                                    }
                                </tbody>
                            </table>
                            </div>
                            :  <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Records not found</div>
                            }

                            

                        </div>
                    </div>

                </div>
                </div>

                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { OrgDetails };