import React from 'react';
import { Container } from 'react-bootstrap';
import PartialViewHeader from './PartialViewHeader';
import PartialViewQuickFilter from './PartialViewQuickFilter';
import PartialViewTable from './PartialViewTable';

function PartialViewBatchDetailReport() {
	return (
		<>
			<Container
				fluid
				className='slide-view-container'>
				<PartialViewHeader />
				<PartialViewQuickFilter />
				<PartialViewTable />
			</Container>
		</>
	);
}

export default PartialViewBatchDetailReport;
