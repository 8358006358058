import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('reports', 'filter')
@observer
class RenderOrganization extends React.Component {

    render() {
        return (<>                                  
                <div className="col-sm-6 col-xs-12 mb-4">
                    <div className="form-floating">
                        <input name="orgName" id="orgName" className={"form-control"} placeholder="Organization" onChange={(e) => this.props.filter.handleCaptureTextFilter('orgName(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('orgName(ct)', e)} value={this.props.filter.filterText.orgName ? this.props.filter.filterText.orgName : ''} />
                        <label htmlFor="orgName">Organization</label>
                    </div>
                </div>
                <div className="col-sm-6 col-xs-12 mb-4">
                    <div className="form-floating">
                        <input name="paypointLegal" id="paypointLegal" className={"form-control"} placeholder="Paypoint" onChange={(e) => this.props.filter.handleCaptureTextFilter('paypointLegal(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('paypointLegal(ct)', e)} value={this.props.filter.filterText.paypointLegal ? this.props.filter.filterText.paypointLegal : ''} data-qaid="inputPaypointFilter" />
                        <label htmlFor="paypointLegal">Paypoint</label>
                    </div>
                </div>
                <div className="col-sm-6 col-xs-12 mb-4">
                    <div className="form-floating">
                        <input name="paypointDba" id="paypointDba" className={"form-control"} placeholder="DBA Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('paypointDba(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('paypointDba(ct)', e)} value={this.props.filter.filterText.paypointDba ? this.props.filter.filterText.paypointDba : ''}/>
                        <label htmlFor="paypointDba">DBA Name</label>
                    </div>
                </div>
                <div className="col-sm-6 col-xs-12 mb-4">
                    <div className="form-floating">
                        <input name="externalPaypointID" id="externalPaypointID" className={"form-control"} placeholder="External Paypoint ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('externalPaypointID(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('externalPaypointID(ct)', e)} value={this.props.filter.filterText.externalPaypointID ? this.props.filter.filterText.externalPaypointID : ''}/>
                        <label htmlFor="externalPaypointID">External Paypoint ID</label>
                    </div>
                </div>
            </>);
    }
}

export { RenderOrganization };