import { action } from 'mobx'

export default function TiersFunction({ service, setService }) {
	const handleAddBankTier = action((serviceType, serviceName, indexService, id, field) => {
		const newField =
			field === 'BankData'
				? {
						id: 0,
						bankName: '',
						typeAccount: '',
						routingAccount: '',
						accountNumber: '',
						bankAccountHolderName: '',
						bankAccountHolderType: '',
						bankAccountFunction: 3
				  }
				: {
						highPayRange: 0,
						lowPayRange: 0,
						mode: 2,
						payorPrice: {
							absorbDifference: false,
							greaterValueAllowed: false,
							allowOverride: false,
							priceFloat: 0,
							priceFix: 0,
							priceMinimum: 0,
							priceMaximum: 0
						}
				  }

		const newData = service[serviceType][serviceName].map((data, index) => {
			if (index === indexService) {
				const newDataGateway = data.Gateways.map((dataGateway) => {
					if (dataGateway.Id === id) {
						if (dataGateway[field]) {
							return {
								...dataGateway,
								[field]: [...dataGateway[field], newField]
							}
						} else {
							return {
								...dataGateway,
								[field]: [newField]
							}
						}
					}
					return dataGateway
				})
				return {
					...data,
					Gateways: newDataGateway
				}
			}
			return data
		})
		setService({ ...service, [serviceType]: { ...service[serviceType], [serviceName]: newData } })
	})
	const handleRemoveBankTier = action((serviceType, serviceName, indexService, id, fieldata, indexBank) => {
		const newDataService = service[serviceType][serviceName].filter((data, index) => index === indexService)
		if (newDataService.length === 0) return

		const newDataGateway = newDataService[indexService].Gateways.filter((dataGateway) => dataGateway.Id === id)
		if (newDataGateway.length === 0) return

		const newDataBankTier = newDataGateway[0][fieldata].filter((data, index) => index !== indexBank)
		const newData = service[serviceType][serviceName].map((data, index) => {
			if (index === indexService) {
				const newDataGateway = data.Gateways.map((dataGateway) => {
					if (dataGateway.Id === id) {
						return {
							...dataGateway,
							[fieldata]: newDataBankTier
						}
					}
					return dataGateway
				})
				return {
					...data,
					Gateways: newDataGateway
				}
			}
			return data
		})
		setService({ ...service, [serviceType]: { ...service[serviceType], [serviceName]: newData } })
	})
	return { handleAddBankTier, handleRemoveBankTier }
}
