import React, { useState } from 'react'
import { observable, action } from 'mobx'
import GlobalStore from '../GlobalStore'
import Record from './recordFunction/Record'
import TemplateDetailsFunction from './recordFunction/TemplateDetailsFunction'
import TemplateBusinessFunction from './recordFunction/TemplateBusinessFunction'
import TemplateBankingFunction from './recordFunction/TemplateBankingFunction'
import TemplateOwnerFunction from './recordFunction/TemplateOwnerFunction'
import TemplateProcessingFunction from './recordFunction/TemplateProcessingFunction'
import TemplateServiceFunction from './recordFunction/TemplateServiceFunction'
import templatesContext from '../../views/Boarding/Template/context/templates_context'
import { validName } from '../../components/inputStyles/validateFunction'
import { toast, ToastContainer, Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TemplateUnderwritingFunction from './recordFunction/TemplateUnderwritingFunction'

export default function ManageTemplate({ newTemplate, children, setLoading }) {
	const [isLoading, setIsLoading] = useState(true)
	const [rootTemplate, setRootTemplate] = useState({ rootTemplate: null, isRoot: false })
	const [orgName, setOrgName] = useState('')
	const [orgTree, setOrgTree] = useState({})
	const [pdfTemplates, setPdfTemplates] = useState([])
	const [resumable, setResumable] = useState([])
	const [recipientEmailNotification, setRecipientEmailNotification] = useState([])
	const [tiersTable, settiersTable] = useState([])

	const [detailsData, setDetailsData] = useState({})
	const [businessData, setBusinessData] = useState({})
	const [processingData, setProcessingData] = useState({})
	const [ownershipData, setOwnershipData] = useState({})
	const [servicesData, setServicesData] = useState({})
	const [bankingData, setBankingData] = useState({})
	const [underWritingData, setUnderWritingData] = useState({})

	const LoadingState = isLoading
	const RootTemplate = observable(rootTemplate)
	const OrgTree = observable(orgTree)
	const DetailsTemplate = observable(detailsData)
	const BusinessTemplate = observable(businessData)
	const UnderwritingTemplate = observable(underWritingData)
	const ProcessingTemplate = observable(processingData)
	const OwnershipTemplate = observable(ownershipData)
	const ServicesTemplate = observable(servicesData)
	const BankingTemplate = observable(bankingData)
	const PDFTemplates = observable(pdfTemplates)
	const Resumable = observable(resumable)
	const RecipientEmailNotification = observable(recipientEmailNotification)
	const TiersTable = observable(tiersTable)

	const messageFileds = 'You must fill in the required fields'
	const messageValid = (
		<>
			This field is required only if the <strong>visible property is not active</strong>, or <strong>read-only is active</strong> and <strong>the field remains empty</strong>, since the information in this field is mandatory and when <strong>deactivating the visible property</strong> or <strong>activating the read-only property</strong>, the user will not be able to enter the information on the boarding application.
		</>
	)

	const loadData = action(async () => {
		setLoading(true)
		const templateCreator = newTemplate ? 'rootTemplate' : 'baseTemplate'
		let res
		if (newTemplate) {
			res = await loadRootNewTemplate()
		} else {
			res = await loadBaseTemplate(newTemplate)
			if (!res) return
			setOrgName(res?.orgName ? res?.orgName : '')
		}
		fieldDataDetails(res, templateCreator, newTemplate)
		fieldDataBusiness(res, templateCreator, newTemplate)
		fieldDataUnderwriting(res, templateCreator, newTemplate)
		fieldDataOwner(res, templateCreator, newTemplate)
		fieldDataProcessing(res, templateCreator, newTemplate)
		fieldDataService(res, templateCreator, newTemplate)
		fieldDataBanking(res, templateCreator, newTemplate)
		setPdfTemplates(res?.templates ? res?.templates : [])
		setResumable(res?.resumable !== null ? res?.resumable : true)
		setRecipientEmailNotification(res?.recipientEmailNotification !== null ? res?.recipientEmailNotification : true)
		settiersTable(res?.tiersName ? res?.tiersName : [])

		setIsLoading(false)
		setLoading(false)
	})
	const insertValues = (object, parent, element, objetValue, type = '') => {
		let newFileds = {}
		if (type === 'oneElement') {
			newFileds = !object || object === null || object === undefined ? objetValue : !object[parent] || object[parent] === null || object[parent] === undefined ? objetValue : object[parent][element] === null || object[parent][element] === undefined ? objetValue : validFields(object[parent][element], objetValue)
		} else {
			for (let addValue in objetValue) {
				if (type === '') {
					newFileds[addValue] = !object || object === null || object === undefined ? objetValue[addValue] : !object[parent] || object[parent] === null || object[parent] === undefined ? objetValue[addValue] : !object[parent][element] || object[parent][element] === null || object[parent][element] === undefined ? objetValue[addValue] : object[parent][element][addValue] === undefined ? objetValue[addValue] : validFields(object[parent][element][addValue], objetValue[addValue])
				} else if (type === 'array') {
					newFileds[addValue] =
						!object || object === null || object === undefined
							? objetValue[addValue]
							: !object[parent] || object[parent] === null || object[parent] === undefined || object[parent].length <= 0
							? objetValue[addValue]
							: !object[parent][0][element] || object[parent][0][element] === null || object[parent][0][element] === undefined
							? objetValue[addValue]
							: object[parent][0][element][addValue] === undefined
							? objetValue[addValue]
							: validFields(object[parent][0][element][addValue], objetValue[addValue])
				}
			}
		}
		return newFileds
	}
	const validFields = (value, objetValue) => {
		switch (objetValue) {
			case 'number':
			case 0:
				const regex = /^[0-9]+([.,][0-9]+)?$/
				const number = parseInt(value)
				return !regex.test(value) || isNaN(number) ? '0' : value
			case 'string':
			case '':
				return value
			case true:
			case 'true':
			case false:
			case 'false':
			case 'boolean':
				return value === 'true' || value === 'false' || value === true || value === false ? value : false
			case 'date':
				try {
					const newDate = new Date(value)
					return isNaN(newDate) ? null : value
				} catch (error) {
					return null
				}
			case 'null':
				return null
			default:
				return value
		}
	}
	const handleChange = action((objet, setObjet, property, field, value, onlyField = false) => {
		if (!onlyField) {
			let valuesChecked = ''
			if (field === 'visible' && !value) {
				valuesChecked = { readOnly: false, required: false, visible: false }
			} else if (field === 'readOnly' && value) {
				valuesChecked = { readOnly: value, required: false, visible: true }
			} else if (field === 'required' && value) {
				valuesChecked = { readOnly: false, required: value, visible: true }
			}
			if (!valuesChecked) {
				setObjet({ ...objet, [property]: { ...objet[property], [field]: value } })
			} else {
				setObjet({ ...objet, [property]: { ...objet[property], readOnly: valuesChecked.readOnly, required: valuesChecked.required, visible: valuesChecked.visible } })
			}
		} else {
			setObjet({ ...objet, [field]: value })
		}
	})
	const validData = action((setKey) => {
		const validateToggle = (data) => {
			let valid = false
			for (var element in data) {
				if (JSON.stringify(data[element]).includes('{')) {
					const newArray = Object.keys(data[element])
					const visibleFind = newArray.find((field) => field === 'visible')
					const readOnlyFind = newArray.find((field) => field === 'readOnly')
					const requiredFind = newArray.find((field) => field === 'required')

					if (visibleFind === 'visible' && readOnlyFind === 'readOnly' && requiredFind === 'required') {
						if (data[element].visible && data[element].readOnly && data[element].required) return true
					} else {
						valid = validateToggle(data[element])
						if (valid) return true
					}

					for (var SubElement in data[element]) {
						if (JSON.stringify(data[element][SubElement]).includes('{')) {
							valid = validateToggle(data[element][SubElement])
							if (valid) return true
						}
					}
				}
			}
			return false
		}
		let valid = false,
			validToggle = false
		if (!newTemplate) {
			if (detailsData.templateName.trim().length === 0 || detailsData.templateDescription.trim().length === 0) valid = true
			if (valid) {
				setKey('main')
				toastMessage(messageFileds, false)
				return false
			}
		}
		if (
			(businessData.dbaname.value.trim().length === 0 && (!businessData.dbaname.visible || businessData.dbaname.readOnly)) ||
			(businessData.legalname.value.trim().length === 0 && (!businessData.legalname.visible || businessData.legalname.readOnly)) ||
			(businessData.website.value.trim().length === 0 && (!businessData.website.visible || businessData.website.readOnly)) ||
			(businessData.baddress.value.trim().length === 0 && (!businessData.baddress.visible || businessData.baddress.readOnly)) ||
			(businessData.bcity.value.trim().length === 0 && (!businessData.bcity.visible || businessData.bcity.readOnly)) ||
			(businessData.bstate.value.trim().length === 0 && (!businessData.bstate.visible || businessData.bstate.readOnly)) ||
			(businessData.bzip.value.trim().length === 0 && (!businessData.bzip.visible || businessData.bzip.readOnly)) ||
			(businessData.bcountry.value.trim().length === 0 && (!businessData.bcountry.visible || businessData.bcountry.readOnly)) ||
			(businessData.maddress.value.trim().length === 0 && (!businessData.maddress.visible || businessData.maddress.readOnly)) ||
			(businessData.mcity.value.trim().length === 0 && (!businessData.mcity.visible || businessData.mcity.readOnly)) ||
			(businessData.mstate.value.trim().length === 0 && (!businessData.mstate.visible || businessData.mstate.readOnly)) ||
			(businessData.mzip.value.trim().length === 0 && (!businessData.mzip.visible || businessData.mzip.readOnly)) ||
			(businessData.mcountry.value.trim().length === 0 && (!businessData.mcountry.visible || businessData.mcountry.readOnly))
		)
			valid = true
		validToggle = validateToggle(businessData)
		if (valid || validToggle) {
			setKey('business')
			toastMessage(messageFileds, false)
			return false
		}

		if (!/non-profit org|government/.test(businessData.btype.value.toLowerCase())) {
			if ((!GlobalStore.emailValidation(ownershipData.owneremail.value) && ownershipData.owneremail.value !== '') || (!GlobalStore.emailValidation(ownershipData.contactEmail.value) && ownershipData.contactEmail.value !== '')) valid = true
			if (
				(ownershipData.ownername.value.trim().length === 0 && (!ownershipData.ownername.visible || ownershipData.ownername.readOnly)) ||
				(!validName.test(ownershipData.ownername.value) && (!ownershipData.ownername.visible || ownershipData.ownername.readOnly)) ||
				(ownershipData.oaddress.value.trim().length === 0 && (!ownershipData.oaddress.visible || ownershipData.oaddress.readOnly)) ||
				(ownershipData.ocity.value.trim().length === 0 && (!ownershipData.ocity.visible || ownershipData.ocity.readOnly)) ||
				(ownershipData.ownerphone1.value.trim().length === 0 && (!ownershipData.ownerphone1.visible || ownershipData.ownerphone1.readOnly)) ||
				(ownershipData.owneremail.value.trim().length === 0 && (!ownershipData.owneremail.visible || ownershipData.owneremail.readOnly)) ||
				(ownershipData.contactName.value.trim().length === 0 && (!ownershipData.contactName.visible || ownershipData.contactName.readOnly)) ||
				(ownershipData.contactEmail.value.trim().length === 0 && (!ownershipData.contactEmail.visible || ownershipData.contactEmail.readOnly)) ||
				(ownershipData.contactPhone.value.trim().length === 0 && (!ownershipData.contactPhone.visible || ownershipData.contactPhone.readOnly))
			)
				valid = true
			validToggle = validateToggle(ownershipData)
		}
		if (valid || validToggle) {
			setKey('owners')
			toastMessage(messageFileds, false)
			return false
		}
		if(!newTemplate && underWritingData && (!underWritingData?.method || (underWritingData?.method === 'automatic' && !underWritingData?.policyId))) {
			setKey('main')
			toastMessage(messageFileds, false)
			return false
		}
		else{
			valid = false
		}
		if (processingData && processingData.binperson !== undefined && processingData.binphone !== undefined && processingData.binweb !== undefined) {
			if (
				(!processingData.binperson.visible && !processingData.binphone.visible && !processingData.binweb.visible) ||
				(processingData.binperson.visible && processingData.binphone.visible && processingData.binweb.visible && parseFloat(processingData.binperson.value !== '' ? processingData.binperson.value : 0) > 0 && parseFloat(processingData.binphone.value !== '' ? processingData.binphone.value : 0) > 0 && parseFloat(processingData.binweb.value !== '' ? processingData.binweb.value : 0) > 0) ||
				(processingData.binperson.readOnly && processingData.binphone.readOnly && processingData.binweb.readOnly) ||
				(processingData.binperson.readOnly && !processingData.binphone.visible && !processingData.binweb.visible) ||
				(!processingData.binperson.visible && processingData.binphone.readOnly && !processingData.binweb.visible) ||
				(!processingData.binperson.visible && !processingData.binphone.visible && processingData.binweb.readOnly) ||
				(processingData.binperson.readOnly && processingData.binphone.readOnly && !processingData.binweb.visible) ||
				(processingData.binperson.readOnly && !processingData.binphone.visible && processingData.binweb.readOnly) ||
				(!processingData.binperson.visible && processingData.binphone.readOnly && processingData.binweb.readOnly)
			) {
				if (parseFloat(processingData.binperson.value !== '' ? processingData.binperson.value : 0) + parseFloat(processingData.binphone.value !== '' ? processingData.binphone.value : 0) + parseFloat(processingData.binweb.value !== '' ? processingData.binweb.value : 0) !== 100) valid = true
			} else if (processingData.binperson.visible || processingData.binphone.visible || processingData.binweb.visible) {
				if (parseFloat(processingData.binperson.value !== '' ? processingData.binperson.value : 0) + parseFloat(processingData.binphone.value !== '' ? processingData.binphone.value : 0) + parseFloat(processingData.binweb.value !== '' ? processingData.binweb.value : 0) > 100) valid = true
			}
		} else {
			valid = true
		}
		if (
			(processingData.mcc.value.trim().length === 0 && (!processingData.mcc.visible || processingData.mcc.readOnly)) ||
			(processingData.bsummary.value.trim().length === 0 && (!processingData.bsummary.visible || processingData.bsummary.readOnly)) ||
			(processingData.avgmonthly.value.trim().length <= 0 && (!processingData.avgmonthly.visible || processingData.avgmonthly.readOnly)) ||
			(processingData.ticketamt.value.trim().length <= 0 && (!processingData.ticketamt.visible || processingData.ticketamt.readOnly)) ||
			(processingData.highticketamt.value.trim().length <= 0 && (!processingData.highticketamt.visible || processingData.highticketamt.readOnly))
		)
			valid = true
		validToggle = validateToggle(processingData)
		if (valid || validToggle) {
			setKey('processing')
			toastMessage(messageFileds, false)
			return false
		}

		/* 	const newServie = {
			card: { pricingType: servicesData.card.pricingType, discountFrequency: servicesData.card.discountFrequency, fundingRollup: servicesData.card.fundingRollup },
			ach: { pricingType: servicesData.ach.pricingType, discountFrequency: servicesData.ach.discountFrequency, fundingRollup: servicesData.ach.fundingRollup }
		}
		validToggle = validateToggle(newServie)
		if (validToggle) {
			setKey('services')
			toastMessage(messageFileds, false)
			return false
		} */

		if (
			(BankingTemplate.depositBank.bankName.value.trim().length === 0 && (!BankingTemplate.depositBank.bankName.visible || BankingTemplate.depositBank.bankName.readOnly)) ||
			(BankingTemplate.depositBank.routingNumber.value.trim().length === 0 && (!BankingTemplate.depositBank.routingNumber.visible || BankingTemplate.depositBank.routingNumber.readOnly)) ||
			(BankingTemplate.depositBank.accountNumber.value.trim().length === 0 && (!BankingTemplate.depositBank.accountNumber.visible || BankingTemplate.depositBank.accountNumber.readOnly)) ||
			(BankingTemplate.depositBank.accountType.value.trim().length === 0 && (!BankingTemplate.depositBank.accountType.visible || BankingTemplate.depositBank.accountType.readOnly)) ||
			(BankingTemplate.withdrawalBank.bankName.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.bankName.visible || BankingTemplate.withdrawalBank.bankName.readOnly)) ||
			(BankingTemplate.withdrawalBank.routingNumber.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.routingNumber.visible || BankingTemplate.withdrawalBank.routingNumber.readOnly)) ||
			(BankingTemplate.withdrawalBank.accountNumber.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.accountNumber.visible || BankingTemplate.withdrawalBank.accountNumber.readOnly)) ||
			(BankingTemplate.withdrawalBank.accountType.value.trim().length === 0 && (!BankingTemplate.withdrawalBank.accountType.visible || BankingTemplate.withdrawalBank.accountType.readOnly))
		)
			valid = true
		validToggle = validateToggle(bankingData)
		if (bankingData.termsAndConditions.visible) {
			bankingData.termsAndConditions.tcLinks.forEach((links) => {
				if (links.label === null || links.label.trim().length === 0 || links.value === null || links.value.trim().length === 0) {
					valid = true
					return false
				}
			})
		}
		if (valid || validToggle) {
			setKey('documents')
			toastMessage(messageFileds, false)
			return false
		}
		return true
	})
	const validNonProfit = () => {
		if (/non-profit org|government/.test(businessData.btype.value.toLowerCase())) {
			return {
				...ownershipData,
				ownername: { ...ownershipData.ownername, required: false },
				ownertitle: { ...ownershipData.ownertitle, required: false },
				ownerpercent: { ...ownershipData.ownerpercent, required: false, value: ownershipData.ownerpercent.value.length <= 0 ? '0' : ownershipData.ownerpercent.value },
				ownerssn: { ...ownershipData.ownerssn, required: false },
				ownerdob: { ...ownershipData.ownerdob, required: false },
				ownerphone1: { ...ownershipData.ownerphone1, required: false },
				ownerphone2: { ...ownershipData.ownerphone2, required: false },
				owneremail: { ...ownershipData.owneremail, required: false },
				ownerdriver: { ...ownershipData.ownerdriver, required: false },
				odriverstate: { ...ownershipData.odriverstate, required: false },
				oaddress: { ...ownershipData.oaddress, required: false },
				ocity: { ...ownershipData.ocity, required: false },
				ostate: { ...ownershipData.ostate, required: false },
				ozip: { ...ownershipData.ozip, required: false },
				ocountry: { ...ownershipData.ocountry, required: false }
			}
		} else {
			return ownershipData
		}
	}
	const CreateData = action(async (data) => {
		const newTemplateData = {
			...detailsData,
			templateName: data.templateName,
			templateDescription: data.templateDescription,
			templateCode: data.templateCode,
			resumable: data.resumable,
			recipientEmailNotification: data.recipientEmailNotification,
			orgId: parseInt(data.orgId, 10),
			templateContent: { ...detailsData.templateContent, underWritingData: {
				method: data.method,
				policyId: data.policyId
			}, businessData: businessData, processingData: processingData, servicesData: servicesData, ownershipData: validNonProfit(), documentsData: bankingData }
		}
		await createTemplate(newTemplateData.orgId, newTemplateData)
	})
	const UpdateData = action(async (setKey) => {
		const res = validData(setKey)
		if (!res) return
		const newTemplateData = { ...detailsData, templateContent: { ...detailsData.templateContent, businessData: businessData, underWritingData: underWritingData, processingData: processingData, servicesData: servicesData, ownershipData: validNonProfit(), documentsData: bankingData } }
		await updateTemplate(newTemplateData)
	})
	const toastMessage = action((message, success = true) => {
		if (success) {
			toast.success(message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-success-container'
			})
		} else {
			toast.error(message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		}
	})

	const { loadRootNewTemplate, loadBaseTemplate, createTemplate, updateTemplate } = Record({ setLoading, rootTemplate, setRootTemplate, setOrgTree })
	const { handleChangeDetails, fieldDataDetails } = TemplateDetailsFunction({ detailsData, setDetailsData, handleChange, insertValues })
	const { handleChangeBusiness, fieldDataBusiness } = TemplateBusinessFunction({ businessData, setBusinessData, insertValues, handleChange })
	const { handleChangeUnderwriting, fieldDataUnderwriting } = TemplateUnderwritingFunction({ underWritingData, setUnderWritingData, handleChange })
	const { handleChangeBanking, handleChangeBankLink, fieldDataBanking, addLink, deleteLink } = TemplateBankingFunction({ bankingData, setBankingData, insertValues })
	const { handleChangeOwner, fieldDataOwner } = TemplateOwnerFunction({ ownershipData, setOwnershipData, insertValues, handleChange })
	const { handleChangeProcessing, fieldDataProcessing } = TemplateProcessingFunction({ processingData, setProcessingData, insertValues, handleChange })
	const { handleChangeService, handleChangeFees, handleChangePricingType, fieldDataService } = TemplateServiceFunction({ servicesData, setServicesData, insertValues })

	return (
		<templatesContext.Provider
			value={{
				LoadingState,
				isLoading,
				loadData,
				CreateData,
				UpdateData,
				RootTemplate,
				orgName,
				OrgTree,
				DetailsTemplate,
				handleChangeDetails,
				BusinessTemplate,
				handleChangeBusiness,
				UnderwritingTemplate,
				handleChangeUnderwriting,
				fieldDataUnderwriting,
				BankingTemplate,
				underWritingData,
				setUnderWritingData,
				handleChangeBanking,
				handleChangeBankLink,
				addLink,
				deleteLink,
				OwnershipTemplate,
				handleChangeOwner,
				ProcessingTemplate,
				handleChangeProcessing,
				ServicesTemplate,
				handleChangeService,
				handleChangeFees,
				handleChangePricingType,
				PDFTemplates,
				TiersTable,
				messageValid,
				validData,
				toastMessage,
				Resumable,
				RecipientEmailNotification
			}}>
			<>
				{children}
				<ToastContainer transition={Bounce} />
			</>
		</templatesContext.Provider>
	)
}
