import React from 'react'
import styles from '../../assets/css/styleCommandCenter.module.css'
import { BiCheck } from 'react-icons/bi'

export default function ButtonSaveCancel({ inputDisabled, funtionCancel = () => null, funtionSave = () => null, captionSave = 'SAVE CHANGES' }) {
	return (
		<>
			{!inputDisabled && (
				<div
					className="d-flex align-items-center justify-content-end flex-wrap flex-row w-100 gap-3"
					style={{ marginTop: '20px', paddingRight: '10px' }}>
					{funtionCancel && (
						<button
							onClick={() => funtionCancel()}
							className={styles['btn'] + ' ' + styles['btn-red-letter'] + ' ' + styles['no-border']}>
							CANCEL
						</button>
					)}
					{funtionSave && (
						<button
							onClick={() => funtionSave()}
							className={styles['btn'] + ' ' + styles['btn-blue']}>
							<BiCheck className={styles['icon24']} />
							{captionSave}
						</button>
					)}
				</div>
			)}
		</>
	)
}
