import React from 'react';
import { inject, observer } from 'mobx-react';
import { Accordion, Card } from 'react-bootstrap';
import { BarChartEntry } from './BarChartEntry';

import {BiChevronDown} from '@react-icons/all-files/bi/BiChevronDown';

@inject('reports', 'global', 'entry')
@observer
class PaypointDataChartColumn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            statistics: null,
            idEntry: null
        };
        this.getPaypointStatistics = this.getPaypointStatistics.bind(this);
    }

    componentDidMount() {
        if(this.props.routeParams.id){
            let idEntry =  this.props.routeParams.id;
            this.setState({ idEntry: idEntry }, function(){
                this.getPaypointStatistics(idEntry);
            });
        }
       
    }

    getPaypointStatistics(idEntry){
        this.props.entry.getEntryStatistics(idEntry).then(res => {
            
        })
        .catch(error => {
            
        });
    }

   

    render() {
        let entryDataChart = this.props.entry.entryDataChart;
       
        let payPoint = this.props.entryPoint && this.props.entryPoint.Paypoint ? this.props.entryPoint.Paypoint : null;
        console.log(payPoint);
        return (
           
            <>
                {<div className="mb-5">
                    <small className="grey">Total Payments this month</small>
                    <h2 style={{fontWeight: 400}}>${this.props.global.numberWithCommas(entryDataChart.lastMonthTotal.toFixed(2))}</h2>
                    <small>Payment History</small>
                    <BarChartEntry/>
                </div>}

                <h6 className="sub-header-line mb-1 dark-grey mb-4">
                    Account Information   
                </h6>
                
                <Accordion defaultActiveKey="0">
                <Card className="card-no-border mb-3">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2">
                        <BiChevronDown/> Business Address 
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Address</div>
                            <div className="col-6">{ payPoint && payPoint.Address1 ? payPoint.Address1 : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Address 2</div>
                            <div className="col-6">{ payPoint && payPoint.Address2 ? payPoint.Address2 : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">City</div>
                            <div className="col-6">{ payPoint && payPoint.City ? payPoint.City : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">State</div>
                            <div className="col-6">{ payPoint && payPoint.State ? payPoint.State : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Zip</div>
                            <div className="col-6">{ payPoint && payPoint.Zip ? payPoint.Zip : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Country</div>
                            <div className="col-6">{ payPoint && payPoint.Country ? payPoint.Country : '-'}</div>
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

               

                <Accordion defaultActiveKey="0">
                <Card className="card-no-border mb-3">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2">
                    <BiChevronDown/> Organization
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Address</div>
                            <div className="col-6">{ payPoint && payPoint.ParentOrg && payPoint.ParentOrg.OrgName ?  payPoint.ParentOrg.OrgName : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Address</div>
                            <div className="col-6">{ payPoint && payPoint.ParentOrg && payPoint.ParentOrg.OrgAddress ?  payPoint.ParentOrg.OrgAddress : '-'}</div>
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

                {(payPoint && payPoint.BankData && payPoint.BankData.length) &&
                <Accordion defaultActiveKey="0">
                <Card className="card-no-border mb-3">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2">
                    <BiChevronDown/> Banking Information
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        {payPoint.BankData.map((record, i) => (
                        <>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Bank Name</div>
                            <div className="col-6">{record.bankName}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Routing Number</div>
                            <div className="col-6">{record.routingAccount}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Account Number</div>
                            <div className="col-6">{record.accountNumber}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Account Type</div>
                            <div className="col-6">{record.typeAccount}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey mb-3">Account Function</div>
                            <div className="col-6">{this.props.global.getBankAccountFunction(record.bankAccountFunction)}</div>
                        </div>
                        </>
                        ))}
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
                }


                <div className="mb-2">Contact</div>
                
                {
                    (this.props.entryPoint && this.props.entryPoint.Paypoint && this.props.entryPoint.Paypoint.Contacts)&&
                        this.props.entryPoint.Paypoint.Contacts.map((record, i) => (
                        <div key={i} className="mb-3">
                        <div className="row small mb-2">
                            <div className="col-6 grey">Contact Name</div>
                            <div className="col-6">{record.ContactName ? record.ContactName: '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Email</div>
                            <div className="col-6">{record.ContactEmail ? record.ContactEmail: '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Phone</div>
                            <div className="col-6">{record.ContactPhone ? record.ContactPhone: '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-6 grey">Title</div>
                            <div className="col-6">{record.ContactTitle ? record.ContactTitle: '-'}</div>
                        </div>
                        </div>
                        ))
                    
                    
                }

            </>
        )
    }
}

export { PaypointDataChartColumn };