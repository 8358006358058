import React, { useState } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import FrameGroup from '../../../components/commandCenter/FrameGroup'
import ButtonSaveCancel from '../../../components/commandCenter/ButtonSaveCancel'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import BillingDetails from '../../../components/commandCenter/BillingFees/BillingDetails'
import { Element } from 'react-scroll'

export default function BillingServiceCard({ servicesType, type, titleCard, element, iconElement }) {
	const [inputDisabled, setInputDisabled] = useState(true)
	const [inputValues, SetInputValues] = useState({ billingAccount: '0012545875413488', pricingType: 'Pass-Through', perAuthorizationMD: '10.00', perAuthorizationPorcent: '3', billingFrequency: 'Daily', transactionFee: '0.00', ACHBatchFree: '0.00', monthlyPlatformFee: '0.00', minProcessingFee: '0.00', retrievalFee: '0.00', changeBackFee: '0.00', earlyTerminationFee: '0.00', AVSCardFee: '0.00', annualFee: '0.00', DDARejectFee: '0.00', monthlyPCIFee: '0.00' })

	return (
		<Element
			name={element}
			className="d-flex justify-content-center w-100">
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={iconElement}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label={titleCard}
					editClick={() => setInputDisabled(false)}
					useBtn={inputDisabled}
					borderButton={true}
					showState={true}
					statusState={true}
				/>

				<BillingDetails
					inputDisabled={inputDisabled}
					inputValues={inputValues}
					SetInputValues={SetInputValues}
				/>

				<ButtonSaveCancel
					inputDisabled={inputDisabled}
					funtionCancel={() => setInputDisabled(true)}
				/>
			</div>
		</Element>
	)
}
