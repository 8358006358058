import { PayabliCookieManager, PayabliStorageManager} from './localStorageManager';
import md5 from 'crypto-js/md5';
import { SecurityManager } from './securityManager';
import GlobalStore from './../store/GlobalStore';

export class IddleManager {

    static startTimer = (callBackFunction) => {
        let iddleModalDisplayed = false;
        document.onmousemove = function(){
            if(iddleModalDisplayed !== true){
                PayabliCookieManager.createCookie(`${PayabliStorageManager.getEntryName()}_payabliLastTimeCookie_${process.env.REACT_APP_ENVIRONMENT}`, new Date(), 1, true);
            }
        }

        
        setInterval(function(){
            //logout if we dont have a token 
            let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
            let accessToken = encryptStorage.getItem("pToken");
            if(Object.keys(accessToken).length === 0){
                SecurityManager.logout(GlobalStore.getURLEntry());
            }

            let mouseMoveLastTime = PayabliCookieManager.readCookie(`${PayabliStorageManager.getEntryName()}_payabliLastTimeCookie_${process.env.REACT_APP_ENVIRONMENT}`) ? PayabliCookieManager.readCookie(`${PayabliStorageManager.getEntryName()}_payabliLastTimeCookie_${process.env.REACT_APP_ENVIRONMENT}`) : new Date();
            let timeout = process.env.REACT_APP_IDLE_TIMEOUT;
            if(mouseMoveLastTime && timeout){
                mouseMoveLastTime = new Date(Date.parse(mouseMoveLastTime));
                if(timeout){
                    let diffMinutes = parseInt(Math.abs((new Date().getTime() - mouseMoveLastTime.getTime()) / 1000) / 60);
                    if(diffMinutes >= (timeout * 2)){
                        //logout
                        SecurityManager.logout(GlobalStore.getURLEntry());
                    }
                    else if(diffMinutes >= timeout)
                    {
                        // showing iddle modal
                        try{
                            let countDown = timeout - (diffMinutes - timeout);
                            callBackFunction(true, countDown);
                            iddleModalDisplayed = true;
                        }catch(e){}
                    }
                }
            }
            

        }, process.env.REACT_APP_MOUSEMOVE_PROPAGATION_INTERVAL * 1000);

    }

    static goToLogout(pEntry){
        window.location = `/${pEntry}/logout`;
    }
}


