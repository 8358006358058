import React from 'react'
import { BiX } from 'react-icons/bi'
import { Modal } from 'react-bootstrap'

export default function ModalFileLimit({ show, close, title, textLine1 ='', textLine2 = '' }) {
	return (
		<Modal
			style={{ textAlign: 'center' }}
			show={show}
			onHide={() => close()}
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Body style={{ padding: '60px 40px' }}>
				<BiX
					className="icon-modal"
					style={{ cursor: 'pointer', position: 'absolute', top: '15px', right: '15px', fontSize: '35px' }}
					onClick={() => close()}
				/>
				<h5>{title}</h5>
				<p style={{ textAlign: 'justify' }}>{textLine1}</p>

				<p style={{ textAlign: 'justify' }}>{textLine2}</p>
			</Modal.Body>
		</Modal>
	)
}
