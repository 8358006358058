import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiXCircle, BiCheckCircle, BiPaperPlane, BiDollarCircle, BiLoader, BiLogOutCircle, BiLogInCircle } from 'react-icons/bi';
import DatePicker from "react-datepicker";
import { RenderSource } from '../../components/filters/RenderSource';
import { RenderTotalAmount } from '../../components/filters/RenderTotalAmount';
import { RenderPaymentInformationMo } from '../../components/filters/RenderPaymentInformationMo';
import { RenderVendorInformation } from '../../components/filters/RenderVendorInformation';
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'global', 'filter')
@observer
class ViewPartialBills extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="billNumber" id="billNumber" className={"form-control"} placeholder="Bill #" onChange={(e) => this.props.filter.handleCaptureTextFilter('billNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('billNumber(ct)', e)} value={this.props.filter.filterText.billNumber ? this.props.filter.filterText.billNumber : ''} />
                            <label htmlFor="billNumber">Bill #</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="lotNumber" id="lotNumber" className={"form-control"} placeholder="Lot #" onChange={(e) => this.props.filter.handleCaptureTextFilter('lotNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('lotNumber(ct)', e)} value={this.props.filter.filterText.lotNumber ? this.props.filter.filterText.lotNumber : ''} />
                            <label htmlFor="lotNumber">Lot #</label>
                        </div>
                    </div>
                </div>
                <div>
                    <p><small>Bill Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[-99]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleFilterBillsStatus('status(in)', -99, !(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[-99]))}><BiXCircle /> Deleted/Cancelled</span>
                        <span className={(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[1]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleFilterBillsStatus('status(in)', 1, !(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[1]))}><BiCheckCircle /> Active</span>
                        <span className={(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[2]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleFilterBillsStatus('status(in)', 2, !(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[2]))}><BiPaperPlane /> Sent to Approval</span>
                        <span className={(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[5]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleFilterBillsStatus('status(in)', 5, !(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[5]))}><BiLoader /> Pending Approval</span>
                        <span className={(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[11]) ? "badge bg-light" : "badge text-light"} onClick={() => this.props.filter.handleFilterBillsStatus('status(in)', 11, !(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[11]))}><BiLogOutCircle /> Not Approved</span>
                        <span className={(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[20]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleFilterBillsStatus('status(in)', 20, !(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[20]))}><BiLogInCircle /> Approved</span>
                        <span className={(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[50]) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleFilterBillsStatus('status(in)', 50, !(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[50]))}>{this.props.global.getGlobalImg((Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[50]) ? 'intransitsecondarydark' : 'intransitsecondary', '12px')} Payment In Transit</span>
                        <span className={(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[100]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleFilterBillsStatus('status(in)', 100, !(Object.keys(this.props.filter.filterBillsStatus).length > 0 && this.props.filter.filterBillsStatus[100]))}><BiDollarCircle /> Paid</span>
                    </div>
                </div>
                <div>
                    <RenderTotalAmount />
                </div>
                <ContainerShowMoreLess>
                    <hr className='mt-1 mb-3'></hr>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12 mb-4">                                
                            <p><small>Created On</small></p>
                            <DatePicker
                                customInput={
                                    <div className="form-floating">
                                        <input 
                                            id="createdOnBillsCalendar" 
                                            name="createdOnBillsCalendar" 
                                            readOnly 
                                            autoComplete="off" 
                                            className={"form-control input-calendar"} 
                                            value={(this.props.filter.stateDate.startDateCreatedOnBills ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateCreatedOnBills) + ' → ' : '') + (this.props.filter.stateDate.endDateCreatedOnBills ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateCreatedOnBills) : '')} 
                                            placeholder="Start Date to End Date" 
                                        />
                                        <label htmlFor="createdOnBillsCalendar">Start Date to End Date</label>
                                    </div>
                                }
                                selected={ this.props.filter.stateDate.startDateCreatedOnBills ? this.props.filter.stateDate.startDateCreatedOnBills : new Date() }
                                onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateCreatedOnBills', 'endDateCreatedOnBills', 'createdAt')}
                                startDate={this.props.filter.stateDate.startDateCreatedOnBills}
                                endDate={this.props.filter.stateDate.endDateCreatedOnBills}
                                selectsRange={true}
                                monthsShown={2}
                                shouldCloseOnSelect={this.props.filter.stateDate.endDateCreatedOnBills !== null ? false : true}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <p><small>Last Updated On</small></p>
                            <DatePicker
                                customInput={
                                    <div className="form-floating">
                                        <input 
                                            id="lastUpdatedOnBillsCalendar" 
                                            name="lastUpdatedOnBillsCalendar" 
                                            readOnly 
                                            autoComplete="off" 
                                            className={"form-control input-calendar"} 
                                            value={(this.props.filter.stateDate.startDateLastUpdatedOnBills ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateLastUpdatedOnBills) + ' → ' : '') + (this.props.filter.stateDate.endDateLastUpdatedOnBills ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateLastUpdatedOnBills) : '')} 
                                            placeholder="Start Date to End Date" 
                                        />
                                        <label htmlFor="lastUpdatedOnBillsCalendar">Start Date to End Date</label>
                                    </div>
                                }
                                selected={ this.props.filter.stateDate.startDateLastUpdatedOnBills ? this.props.filter.stateDate.startDateLastUpdatedOnBills : new Date()}
                                onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateLastUpdatedOnBills', 'endDateLastUpdatedOnBills', 'updatedOn')}
                                startDate={this.props.filter.stateDate.startDateLastUpdatedOnBills}
                                endDate={this.props.filter.stateDate.endDateLastUpdatedOnBills}
                                selectsRange={true}
                                monthsShown={2}
                                shouldCloseOnSelect={this.props.filter.stateDate.endDateLastUpdatedOnBills !== null ? false : true}
                                popperModifiers={{
                                    preventOverflow: {
                                        enabled: true,
                                    },
                                    }}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <p><small>Due On</small></p>
                            <DatePicker
                                customInput={
                                    <div className="form-floating">
                                        <input 
                                            id="dueOnBillsCalendar" 
                                            name="dueOnBillsCalendar" 
                                            readOnly 
                                            autoComplete="off" 
                                            className={"form-control input-calendar"} 
                                            value={(this.props.filter.stateDate.startDateDueOnBillsCalendar ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateDueOnBillsCalendar) + ' → ' : '') + (this.props.filter.stateDate.endDateDueOnBillsCalendar ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateDueOnBillsCalendar) : '')} 
                                            placeholder="Start Date to End Date" 
                                        />
                                        <label htmlFor="dueOnBillsCalendar">Start Date to End Date</label>
                                    </div>
                                }
                                selected={ this.props.filter.stateDate.startDateDueOnBillsCalendar ? this.props.filter.stateDate.startDateDueOnBillsCalendar : new Date() }
                                onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateDueOnBillsCalendar', 'endDateDueOnBillsCalendar', 'billDueDate')}
                                startDate={this.props.filter.stateDate.startDateDueOnBillsCalendar}
                                endDate={this.props.filter.stateDate.endDateDueOnBillsCalendar}
                                selectsRange={true}
                                monthsShown={2}
                                shouldCloseOnSelect={this.props.filter.stateDate.endDateDueOnBillsCalendar !== null ? false : true}
                            />
                        </div>                            
                    </div>
                </ContainerShowMoreLess>
                <div>
                    <RenderPaymentInformationMo />
                </div>
                <div>
                    <p><small>Payment Status</small></p>
                    <div className='section-status mb-3'>
                    <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('paymentStatus(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiXCircle /> Cancelled</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleStatus('paymentStatus(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))}>{this.props.global.getGlobalImg((Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? 'intransitsecondary' : 'intransitsecondarydark', '12px')} Captured</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('paymentStatus(in)', 2, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]))}><BiLoader /> Processing</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleStatus('paymentStatus(in)', 3, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]))}><BiLogInCircle /> Processed</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('paymentStatus(in)', 5, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]))}><BiDollarCircle /> Paid</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[11]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('paymentStatus(in)', 11, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[11]))}><BiLogInCircle /> Authorized</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Bill Date</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="billDateCalendar" 
                                        name="billDateCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateBillDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateBillDate) + ' → ' : '') + (this.props.filter.stateDate.endDateBillDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateBillDate) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="billDateCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateBillDate ? this.props.filter.stateDate.startDateBillDate : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateBillDate', 'endDateBillDate', 'billDate')}
                            startDate={this.props.filter.stateDate.startDateBillDate}
                            endDate={this.props.filter.stateDate.endDateBillDate}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateBillDate !== null ? false : true}
                        />
                    </div>
                </div>
                <RenderVendorInformation />
                <div>
                    <h5 className='title'>Transaction Information</h5>  
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="paymentId" id="paymentId" className={"form-control"} placeholder="Transaction ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('paymentId(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('paymentId(ct)', e)} value={this.props.filter.filterText.paymentId ? this.props.filter.filterText.paymentId : ''} />
                                <label htmlFor="paymentId">Transaction ID</label>
                            </div>
                        </div>
                    </div>  
                    <RenderSource />
                </div>
                <div>
                    <h5 className='title'>Custom Fields</h5>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="vendorCustomField1" id="vendorCustomField1" className={"form-control"} placeholder="Test Customer Field 1" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorCustomField1(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorCustomField1(ct)', e)} value={this.props.filter.filterText.vendorCustomField1 ? this.props.filter.filterText.vendorCustomField1 : ''} />
                                <label htmlFor="vendorCustomField1">Test Customer Field 1</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="vendorCustomField2" id="vendorCustomField2" className={"form-control"} placeholder="Test Customer Field 2" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorCustomField2(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorCustomField2(ct)', e)} value={this.props.filter.filterText.vendorCustomField2 ? this.props.filter.filterText.vendorCustomField2 : ''} />
                                <label htmlFor="vendorCustomField2">Test Customer Field 2</label>
                            </div>
                        </div>
                    </div>
                </div>                
        </>);
    }
}

export { ViewPartialBills };