import { makeObservable, action, observable } from 'mobx'
import axios from 'axios'
import { PayabliStorageManager } from '../api/localStorageManager'
import * as Sentry from "@sentry/react";

class UserStore {
	constructor() {
		makeObservable(this)
	}

	@action
	deleteUser(userId) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.delete(process.env.REACT_APP_URL_API + 'User/' + userId, {
				headers: { requestToken: encryptStorage.getItem('pToken') }
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	updateUserCredentials(user) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(
				process.env.REACT_APP_URL_API + 'User/' + user.id,
				{
					email: user.email,
					pwd: user.pwd
				},
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				Sentry.captureException(error);
				throw error
			})
	}

	updatePermissions(user, permissions, userPermissions) {
		let access = []

		if (permissions) {
			permissions.forEach(function (item) {
				if (userPermissions.indexOf(item.permission) !== -1) {
					access.push({
						roleLabel: item.permission,
						roleValue: item.checked ? true : false
					})
				}

				if (item.childs) {
					item.childs.forEach(function (item2) {
						if (userPermissions.indexOf(item2.permission) !== -1) {
							access.push({
								roleLabel: item2.permission,
								roleValue: item2.checked ? true : false
							})
						}
						if (item2.childs) {
							item2.childs.forEach(function (item3) {
								if (
									userPermissions.indexOf(
										item3.permission
									) !== -1
								) {
									access.push({
										roleLabel: item3.permission,
										roleValue: item3.checked ? true : false
									})
								}
							})
						}
					})
				}
			})
		}

		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(
				process.env.REACT_APP_URL_API + 'User/' + user.id,
				{
					access: access
				},
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	updateUserInfo(user) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(
				process.env.REACT_APP_URL_API + 'User/' + user.id,
				{
					name: user.name,
					email: user.email,
					phone: user.phone,
					usrStatus: parseInt(user.usrStatus),
					timeZone: user.timeZone,
					additionalData: user.additionalData
				},
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	updateUserMFA(id, enableMFA, enableMFAType) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(
				process.env.REACT_APP_URL_API + 'User/mfa/' + id,
				{
					mfa: enableMFA,
					mfaMode: enableMFAType !== '' ? enableMFAType : '0'
				},
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	newUserInfo(user) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.post(
				process.env.REACT_APP_URL_API + 'User',
				{
					name: user.name,
					email: user.email,
					phone: user.phone,
					usrStatus: parseInt(user.usrStatus),
					pwd: user.pwd,
					scope: user.scope,
					access: user.access,
					timeZone: user.timeZone,
					mfaData: {
						mfa: user.mfa ? user.mfa : false,
						mfaMode: user.mfaMode ? user.mfaMode : 0
					}
				},
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	sendEmailToResetPassword(user) {
		return axios
			.post(
				process.env.REACT_APP_URL_API + 'User/authreset',
				{
					email: user.email,
					entry: user.entry,
					entryType: 0
				},
				{
					headers: { requestToken: process.env.REACT_APP_TOKEN }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	resetPassword(user, token) {
		return axios
			.put(
				process.env.REACT_APP_URL_API + 'User/authpsw',
				{
					psw: user.psw
				},
				{
					headers: { requestToken: token }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	getUserFromApi(id, pEntry) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let entry =
			encryptStorage &&
			encryptStorage.getItem(
				`${PayabliStorageManager.getEntryName()}_pEntry`
			) &&
			encryptStorage.getItem(
				`${PayabliStorageManager.getEntryName()}_pEntry`
			).pEntry
				? encryptStorage.getItem(
						`${PayabliStorageManager.getEntryName()}_pEntry`
				  ).pEntry
				: null
		return axios
			.get(
				process.env.REACT_APP_URL_API +
					'User/' +
					id +
					'?entry=' +
					(pEntry ? pEntry : entry) +
					'&level=0',
				{
					headers: {
						requestToken: encryptStorage.getItem('pToken')
					}
				}
			)
			.then((res) => {
				return res.data
			})
			.catch((error) => {
				throw error
			})
	}

	getPermissionsFromApi() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(process.env.REACT_APP_URL_API + 'Tools/values/userRoles', {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((res) => {
				return res.data
			})
			.catch((error) => {
				throw error
			})
	}

	getReportsFromApi() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(
				process.env.REACT_APP_URL_API +
					'Tools/values/notificationReports',
				{
					headers: {
						requestToken: encryptStorage.getItem('pToken')
					}
				}
			)
			.then((res) => {
				return res.data
			})
			.catch((error) => {
				throw error
			})
	}

	getNotificationsFromApi() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(
				process.env.REACT_APP_URL_API +
					'Tools/values/notificationEvents',
				{
					headers: {
						requestToken: encryptStorage.getItem('pToken')
					}
				}
			)
			.then((res) => {
				return res.data
			})
			.catch((error) => {
				throw error
			})
	}

	validateToken(token) {
		return axios
			.post(
				process.env.REACT_APP_URL_API + 'Tools/validate',
				{},
				{
					headers: { requestToken: token }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				Sentry.captureException(error);
				throw error
			})
	}

	newNotification(notification) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.post(
				process.env.REACT_APP_URL_API + 'Notification',
				notification,
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	bulkNewNotification(notifications) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.post(
				process.env.REACT_APP_URL_API + 'Notification/addbulk',
				notifications,
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	deleteNotification(notificationId) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.delete(
				process.env.REACT_APP_URL_API +
					'Notification/' +
					notificationId,
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	bulkDeleteNotification(notificationsIds) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.delete(process.env.REACT_APP_URL_API + 'Notification/removebulk', {
				headers: { requestToken: encryptStorage.getItem('pToken') },
				data: notificationsIds
			})
			.then((res) => {
				return res
			})
			.catch((error) => {
				throw error
			})
	}

	updateNotification(notification){
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(
				process.env.REACT_APP_URL_API + 'Notification/' + notification.notificationId,
				notification,
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				Sentry.captureException(error);
				throw error
			})
	}



	getUsers(entryPoint, filter) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(
				process.env.REACT_APP_URL_API +
					'Query/users/point/' +
					entryPoint +
					(filter ? filter : ''),
				{
					headers: {
						requestToken: encryptStorage.getItem('pToken')
					}
				}
			)
			.then((res) => {
				return res.data.Records
			})
			.catch((error) => {
				throw error
			})
	}

	getUsersByStatus(entryPoint, status) {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.get(
				process.env.REACT_APP_URL_API +
					'Query/users/point/' +
					entryPoint,
				{
					headers: {
						requestToken: encryptStorage.getItem('pToken')
					},
					params: {
						'status(eq)': status
					}
				}
			)
			.then((res) => {
				return res.data.Records
			})
			.catch((error) => {
				throw error
			})
	}

	hasAccess(path, permissionsArray) {
		switch (path) {
			case '/:entryUrl/report/bills/:id?':
			case '/:entryUrl/report/bills/bill/:billNumber?':
			case '/:entryUrl/report/bills/vendor/:vendorNumber?':
			case '/:entryUrl/bills/new':
			case '/:entryUrl/bills/edit/:idBill':
			case '/:entryUrl/bills/new/:vendorNumber/:entry':
			case '/:entryUrl/bills/view/:idBill':
				return permissionsArray.indexOf('Bills') !== -1 ? true : false
			case '/:entryUrl/report/approvals':
				return permissionsArray.indexOf('Approvals') !== -1
					? true
					: false
			case '/:entryUrl/report/vendors':
			case '/:entryUrl/vendor/:id':
				return permissionsArray.indexOf('Vendors') !== -1 ? true : false
			case '/:entryUrl/chargeback/:id/:command?':
				return permissionsArray.indexOf('Chargeback') !== -1
					? true
					: false
			case '/:entryUrl/report/transactions/out/:id?':
				return permissionsArray.indexOf("Transaction") !== -1
				? true
				: false;
			case '/:entryUrl/report/transaction/out/:billNumber':
				return permissionsArray.indexOf('MoneyOut Actions') !== -1
					? true
					: false
			case '/:entryUrl/report/batches/out':
			case '/:entryUrl/report/batches/transactions/out/:idBatch':
				return permissionsArray.indexOf('Transaction') !== -1
					? true
					: false
			case '/:entryUrl/report/transactions/:id/:gobackData?':
			case '/:entryUrl/report/transactions':
			case '/:entryUrl/report/transactions/id/:paymentTransId':
			case '/:entryUrl/report/transactions/idtransrefund/:idtransRefund':
				return permissionsArray.indexOf('Transaction') !== -1
					? true
					: false
			case '/:entryUrl/report/transactions/paypoint/:entry/:idPaypoint':
				return permissionsArray.indexOf('Transaction') !== -1
					? true
					: false
			case '/:entryUrl/report/transactions/org/:idOrg':
				return permissionsArray.indexOf('Transaction') !== -1
					? true
					: false
			case '/:entryUrl/report/batches':
			case '/:entryUrl/report/batches/:idBatch':
				return permissionsArray.indexOf('Batch') !== -1 ? true : false
			case '/:entryUrl/report/autopays/paypoint/:entry/:idPaypoint':
				return permissionsArray.indexOf('Subscriptions') !== -1
					? true
					: false
			case '/:entryUrl/report/autopays/org/:idOrg':
				return permissionsArray.indexOf('Subscriptions') !== -1
					? true
					: false
			case '/:entryUrl/report/autopays':
			case '/:entryUrl/report/autopays/:id?/:command?':
				return permissionsArray.indexOf('Subscriptions') !== -1
					? true
					: false
			case '/:entryUrl/report/settlements/:batchNumber?':
				return permissionsArray.indexOf('Settlement') !== -1
					? true
					: false
			case '/:entryUrl/report/chargebacks/:paymentTransId?':
				return permissionsArray.indexOf('Chargeback') !== -1
					? true
					: false
			case '/:entryUrl/report/users':
			case '/:entryUrl/report/usersorg/:idOrg':
			case '/:entryUrl/users/new/:idOrg?':
				return permissionsArray.indexOf('Manage Users') !== -1
					? true
					: false
			case '/:entryUrl/report/organizations/:idOrg?':
				return permissionsArray.indexOf('Organizations') !== -1
					? true
					: false
			case '/:entryUrl/report/paypoints/:idOrg':
			case '/:entryUrl/report/paypoints':
				return permissionsArray.indexOf('Paypoints') !== -1
					? true
					: false
			case '/:entryUrl/paypoints/overview/:entry/:id':
				return permissionsArray.indexOf('Paypoints') !== -1
					? true
					: false
			case '/:entryUrl/report/residuals':
				return permissionsArray.indexOf('Residuals') !== -1
					? true
					: false
			case '/:entryUrl/report/summaryresiduals':
				return permissionsArray.indexOf('Residuals') !== -1
					? true
					: false
			case '/:entryUrl/report/residuals/paypoint/:entry/:idPaypoint':
				return permissionsArray.indexOf('Residuals') !== -1
					? true
					: false
			case '/:entryUrl/report/residuals/org/:idOrg?':
				return permissionsArray.indexOf('Residuals') !== -1
					? true
					: false
			case '/:entryUrl/boarding/applications':
				return permissionsArray.indexOf('Applications') !== -1
					? true
					: false
			case '/:entryUrl/boarding/applicationedit/:id':
				return permissionsArray.indexOf('Applications') !== -1
					? true
					: false
			case '/:entryUrl/boarding/viewapplication/:id':
				return permissionsArray.indexOf('Applications') !== -1
					? true
					: false
			case '/:entryUrl/boarding/templates':
				return permissionsArray.indexOf('Templates') !== -1
					? true
					: false
			case '/:entryUrl/boarding/links':
				return permissionsArray.indexOf('Links') !== -1 ? true : false
			case '/:entryUrl/boarding/newtemplate':
				return permissionsArray.indexOf('Templates') !== -1
					? true
					: false
			case '/:entryUrl/boarding/edittemplate/:id':
				return permissionsArray.indexOf('Templates') !== -1
					? true
					: false
			case '/:entryUrl/boarding/newapptpl/:id/:idOrg?':
				return permissionsArray.indexOf('Applications') !== -1
					? true
					: false
			case '/:entryUrl/boarding/app/:id':
				return permissionsArray.indexOf('Applications') !== -1
					? true
					: false
			case '/:entryUrl/report/application/paypoint/:entry/:idPaypoint':
				return permissionsArray.indexOf('Applications') !== -1
					? true
					: false
			case '/:entryUrl/management/paypoints/:oid?/:id?':
				return permissionsArray.indexOf('Paypoints') !== -1
					? true
					: false
			case '/:entryUrl/boarding/externalapp/load/:id':
				return true
			case '/:entryUrl/boarding/externalapp/new/:id':
				return true
			case '/:entryUrl/report/customers/:command?':
				return permissionsArray.indexOf('Customers') !== -1
					? true
					: false
			case '/:entryUrl/report/customers/org/:idOrg':
				return permissionsArray.indexOf('Customers') !== -1
					? true
					: false
			case '/:entryUrl/customer/paymethods/:id/:command?':
				return permissionsArray.indexOf('Autopay') !== -1 ||
					permissionsArray.indexOf('Accept Payments') !== -1
					? true
					: false
			case '/:entryUrl/customer/:id':
				return permissionsArray.indexOf('Customers') !== -1
					? true
					: false
			case '/:entryUrl/report/invoices':
				return permissionsArray.indexOf('Invoices') !== -1
					? true
					: false
			case '/:entryUrl/invoices/:id?':
				return permissionsArray.indexOf('Invoices') !== -1
					? true
					: false
			case '/:entryUrl/report/catalog':
				return permissionsArray.indexOf('Virtual Terminal') !== -1
					? true
					: false
			case '/:entryUrl/vterminal/:entry/:idPaypoint':
				return permissionsArray.indexOf('Virtual Terminal') !== -1
					? true
					: false
			case '/:entryUrl/login':
				return true
			case '/:entryUrl/forgotpassword':
				return true
			case '/:entryUrl/resetpassword/:token':
				return true
			case '/:entryUrl/riskcontrol/reports':
				return true
			case '/:entryUrl/dashboard':
				return permissionsArray.indexOf('Dashboard') !== -1
					? true
					: false
			case '/:entryUrl/profile/:id/:entryName?':
				return permissionsArray.indexOf('Profile') !== -1 ? true : false
			case '/:entryUrl/developers':
			case '/:entryUrl/developers/apitokens':
			case '/:entryUrl/developers/creator':
				return permissionsArray.indexOf('Developers') !== -1
					? true
					: false
			case '/:entryUrl/developers/hellosigntemplate':
				return permissionsArray.indexOf('Developers') !== -1
					? true
					: false
			case '/:entryUrl/developers/sftpaccounts':
			case '/:entryUrl/developers/reports':
				return permissionsArray.indexOf('Xchange') !== -1 ? true : false
			case '/:entryUrl/fields':
				return permissionsArray.indexOf('Notifications') !== -1
					? true
					: false
			case '/:entryUrl/notifications':
				return permissionsArray.indexOf('Organizations') !== -1
					? true
					: false
			case '/:entryUrl/orgdetails':
				return permissionsArray.indexOf('Organizations') !== -1
					? true
					: false
			case '/:entryUrl/orgdetails/:id':
				return permissionsArray.indexOf('Organizations') !== -1
					? true
					: false
			default:
				return false
		}
	}
}

const userStore = new UserStore()
export default userStore
