import React from 'react';
import { inject } from 'mobx-react';
import {  Modal } from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumberFormat from 'react-number-format';
import SelectSearch from 'react-select-search';
import '../assets/css/react-select-search.css';
import Fuse from 'fuse.js';

export default function fuzzySearch(options) {
    const fuse = new Fuse(options, {
        keys: ['name', 'groupName', 'items.name'],
        threshold: 0.3,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }

        let result = fuse.search(value);
        let resultArray = []
        result.forEach(function (item) {
            resultArray.push(item.item);
        });
        return resultArray;
    };
}

@inject('global', 'reports')
class EditCatalog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            itemProductName: '',
            itemDescription: '',
            itemCost: '',
            itemProductCode: '',
            lineItemsPaypoint: "",
            lineItemNameError: false,
            lineItemCostError: false,
            lineItemsPaypointError: false,
            partnerPaypoints:  [],
        };

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextChangeSelectSearch = this.handleTextChangeSelectSearch.bind(this);
        this.handleItemCost = this.handleItemCost.bind(this);
        this.onlyPositiveNumbers = this.onlyPositiveNumbers.bind(this);
        this.actionLineItem = this.actionLineItem.bind(this);
    }

    getPartnerPaypoints(selected){
        this.props.reports.getPartnerPaypoints().then(res => {
            let dataArray = [];
            if(res.Records){
                res.Records.forEach(function (item) {
                    if(item.EntryPoints && item.EntryPoints[0] && item.EntryPoints[0].EntryName){
                        dataArray.push({
                            value: item.EntryPoints[0].EntryName,
                            name: item.LegalName,
                        });
                    }
                });
            }
            this.setState({ partnerPaypoints: dataArray}, function(){
                if(selected){
                    this.setState({lineItemsPaypoint : selected});
                }
            });
        })
        .catch(error => {
            
        });
    }

    closeModal(){
        this.setState({ modalIsOpen: false, lineItemsPaypoint : '', partnerPaypoints:  [] });
    }

    openModal(e){
        if(e){
            e.preventDefault();
        }  
        
        this.getPartnerPaypoints(this.props.lineItemRecord && this.props.lineItemRecord.PaypointEntryname ? this.props.lineItemRecord.PaypointEntryname : null);
        
        this.setState({ 
            itemProductName: this.props.lineItemRecord && this.props.lineItemRecord.itemProductName ? this.props.lineItemRecord.itemProductName : '',
            itemDescription: this.props.lineItemRecord && this.props.lineItemRecord.itemDescription ? this.props.lineItemRecord.itemDescription : '',
            itemCost: this.props.lineItemRecord && this.props.lineItemRecord.itemCost ? this.props.lineItemRecord.itemCost : '',
            itemProductCode: this.props.lineItemRecord && this.props.lineItemRecord.itemProductCode ? this.props.lineItemRecord.itemProductCode : '',
            modalIsOpen: true
        });
    }

    handleTextChange(state, e){
        this.setState({ [state]: e.target.value });
    }

    handleTextChangeSelectSearch(value, name){
        this.setState({ [name]: value });
    }

    handleItemCost(state, e) {
        this.setState({ [state]: e.value });
    }

    onlyPositiveNumbers(event) {
        if (event.charCode === 8 || event.charCode === 0 || (event.charCode >= 48 && event.charCode <= 57)) {
          return;
        } else event.preventDefault();
    }

    actionLineItem(action) {
        let validators = this.props.global.validators, lineItemNameError = false, lineItemCostError = false, lineItemsPaypointError = false;
        
        lineItemNameError = validators.isEmpty(this.state.itemProductName) ? true : false;
    
        lineItemCostError = validators.isEmpty(this.state.itemCost) ? true : false;
        
        lineItemsPaypointError = validators.isEmpty(this.state.lineItemsPaypoint) ? true : false;
    
        this.setState(
          {
            lineItemNameError: lineItemNameError,
            lineItemCostError: lineItemCostError,
            lineItemsPaypointError: lineItemsPaypointError,
          },          
          function () {
            if (!this.state.lineItemNameError && !this.state.lineItemCostError && !this.state.lineItemsPaypointError) {
                if (action === 'add') {
                    let objectLineItem = {
                        itemProductName: this.state.itemProductName,
                        itemDescription: this.state.itemDescription,
                        itemCost: this.state.itemCost,
                        itemProductCode: this.state.itemProductCode,
                    };
                    this.props.global.setLoading(true);
                    this.props.reports
                    .addLineItem(objectLineItem, this.state.lineItemsPaypoint)
                    .then((res) => {
                        if(this.props.functionCallback){
                            this.props.functionCallback();
                        }

                        this.props.global.setLoading(false);
                        toast.success("Products or Services created successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });
                    })
                    .catch((error) => {
                        this.props.global.setLoading(false);
                        let errorMessage =
                        error.response && error.response.data.responseText
                            ? error.response.data.responseText
                            : 'Something is Wrong creating the Products or Services!';
                        toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                        });
                    });
                    this.closeModal();
                } else {
                    let objectLineItem = {
                        itemProductName: this.state.itemProductName,
                        itemDescription: this.state.itemDescription,
                        itemCost: this.state.itemCost,
                        itemProductCode: this.state.itemProductCode,
                    };
                    let lineItemId = this.props.lineItemRecord && this.props.lineItemRecord.Id;
                    this.props.global.setLoading(true);
                    this.props.reports
                    .updateLineItem(lineItemId, objectLineItem)
                    .then((res) => {
                        if(this.props.functionCallback){
                            this.props.functionCallback();
                        }

                        this.props.global.setLoading(false);
                        toast.success("Products or Services updated successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });
                    })
                    .catch((error) => {
                        this.props.global.setLoading(false);
                        let errorMessage =
                        error.response && error.response.data.responseText
                            ? error.response.data.responseText
                            : 'Something is Wrong updating the Products or Services!';
                        toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                        });
                    });
                    this.closeModal();
                }                
            }
          }
        );
      }

    render() {
        return (
            <>
              <Modal show={this.state.modalIsOpen} onHide={() => this.closeModal()}  size="md"  aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Body className="popover-body" id="editCatalogModal">
              <div className="row mb-2">
                <div className="col">
                    <h6 data-qaid="prodCatalogModalMoneyIn">{this.props.title ? this.props.title : "Edit product or service"}</h6>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <div className={this.state.lineItemsPaypointError ? "form-floating mb-3 input-error" : "form-floating mb-3"}>
                            <SelectSearch
                                id="lineItemsPaypoint"
                                options={this.state.partnerPaypoints}
                                autoComplete={"selectSearch"}
                                search
                                filterOptions={fuzzySearch}
                                emptyMessage="Not found"
                                placeholder="Find and select a Paypoint (Merchant)"
                                value={this.state.lineItemsPaypoint}
                                onChange={(e) =>
                                    this.handleTextChangeSelectSearch(e,"lineItemsPaypoint")
                                }
                                disabled={this.props.action === "edit"}
                            />
                            <label htmlFor="lineItemsPaypoint" className="forceFloatLabel">Paypoint</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <input
                            type="text"
                            value={this.state.itemProductName}
                            className={this.state.lineItemNameError ? 'form-control mb-3 input-error' : 'form-control mb-3'}
                            placeholder="Product or Service Name"
                            maxLength={250}
                            onChange={(e) => this.handleTextChange('itemProductName', e)}
                        />
                    </div>
                    <div className="col-sm-6">
                        <NumberFormat
                            thousandsGroupStyle="thousand"
                            prefix="$"
                            decimalSeparator="."
                            displayType="input"
                            type="text"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.state.itemCost}
                            className={this.state.lineItemCostError ? 'form-control mb-3 input-error' : 'form-control mb-3'}
                            placeholder="$0.00"
                            onValueChange={(e) => this.handleItemCost('itemCost', e)}
                            readOnly={false}
                        />
                    </div>
                    <div className="col-sm-6">
                        <input
                            value={this.state.itemProductCode}
                            onKeyPress={this.onlyPositiveNumbers}
                            onChange={(e) => this.handleTextChange('itemProductCode', e)}
                            type="text"
                            className={'form-control mb-3'}
                            placeholder="Item Code (optional)"
                            maxLength={8}
                        />
                    </div>
                </div>                

                <div>
                    <textarea
                        rows={2}
                        value={this.state.itemDescription}
                        onChange={(e) => this.handleTextChange('itemDescription', e)}
                        type="text"
                        className="form-control mb-1"
                        placeholder="Description (optional)"
                    />
                </div>             
                <div className="row mt-4">
                    <div className="col">
                    <button className="btn full-w btn-light" type="button" onClick={() => this.closeModal()} data-qaid="cancelProductCatalogMoneyIn">
                        Cancel
                    </button>
                    </div>
                    {this.props.action === 'add' && (
                    <div className="col">
                        <button className="btn full-w btn-primary" type="button" onClick={() => this.actionLineItem('add')}>
                        Add
                        </button>
                    </div>
                    )}
                    {this.props.action === 'edit' && (
                    <div className="col">
                        <button className="btn full-w btn-primary" type="button" onClick={() => this.actionLineItem('edit')}>
                        Save changes
                        </button>
                    </div>
                    )}
                </div>
              </Modal.Body>
              </Modal>   
              <a href="/" className={this.props.className ? this.props.className : "dropdown-item data-qaid-editItemCatalogMoneyIn"} onClick={(e) => this.openModal(e)} >{this.props.label ? this.props.label : "Edit Catalog"}</a>             
            </>
        )
    }
}

export { EditCatalog };