import React, { useContext } from 'react'
import GlobalStore from '../../../../../../store/GlobalStore'
import GroupRow from '../../../../../../components/templates/GroupRow'
import CardPricingType from './CardPricingType'
import AchPricingType from './AchPricingType'
import MgmPricingType from './MgmPricingType'
import templatesContext from '../../../context/templates_context'

export default function PricingType({ type }) {
	const { RootTemplate, LoadingState, ServicesTemplate, handleChangeService } = useContext(templatesContext)
	return (
		<>
			{(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData[type][type === 'card' ? 'cardAcceptance' : type === 'ach' ? 'achAcceptance' : 'mgmAcceptance'].visible) && (
				<>
					<div className="mt-4">
						<strong>Pricing</strong>
						<div className="row">
							<div className="col-md-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate[type]}
									parentField={RootTemplate.rootTemplate.servicesData[type]}
									handleChange={(property, field, values) => handleChangeService(type, '', property, field, values)}
									field="pricingType"
									inputType={{
										title: 'Pricing Type',
										type: 'select',
										mask: GlobalStore.getOptionsType(type === 'card' ? 'card-pricing' : type === 'ach' ? 'ach-pricing' : 'mgm-pricing')
									}}
									checkOptions={{ state: { visible: false, readOnly: false, required: false } }}
								/>
							</div>
						</div>
					</div>
				</>
			)}
			{!LoadingState && type === 'card' ? <CardPricingType /> : type === 'ach' ? !LoadingState && <AchPricingType /> : !LoadingState && <MgmPricingType />}
		</>
	)
}
