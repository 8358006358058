import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { DevelopersLinks } from '../../components/DevelopersLinks';
import { EditSftpAccounts } from '../../components/EditSftpAccounts';
import { TopBar } from '../../components/TopBar';
import { ReportPaginator } from '../../components/ReportPaginator';
import { Dropdown, DropdownButton, OverlayTrigger, Tooltip, Modal, Button } from 'react-bootstrap';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { MainBar } from '../../components/MainBar';
import { BiTrash, BiDotsVerticalRounded } from 'react-icons/bi';
import { CgUnavailable } from 'react-icons/cg';

@inject('global', 'reports')
@observer
class SftpAccounts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            flagItemAllColumn: true,
            deleteModalIsOpen: false,
            sftpAccountToDelete: null
        }
        this.getReportNameComponent = this.getReportNameComponent.bind(this);
        this.buttonAddComponent = this.buttonAddComponent.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.renderTdFrequency = this.renderTdFrequency.bind(this);
        this.deleteSftpAccount = this.deleteSftpAccount.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteSftpAccountAction = this.deleteSftpAccountAction.bind(this);
        this.getModeText = this.getModeText.bind(this);
    }

    componentDidMount() {
        this.props.reports.setFrom(0);
        this.props.reports.setHeaders(
            {
                parentOrgName   : { label:'Organization', class: '', display: false },
                legalName       : { label:'Paypoint', class: '', display: true },
                userId          : { label:'Username', class: '', display: true },
                server          : { label:'Server URL', class: '', display: true },  
                frequency       : { label:'Frequency', class: '', display: true },
                schedule        : { label:'Schedule (UTC)', class: '', display: true },
                mode            : { label:'Mode', class: '', display: true },
            }
        )        
        this.props.reports.setRecords([]);
        this.getReportFromApi();
    }

    getReportNameComponent(){
        return  <div className="mr-3 inline">
                    <h6 style={{display: "inline-block"}}>SFTP Accounts</h6>
                </div>
    }

    buttonAddComponent(withoutStyle=false){
        return <EditSftpAccounts 
                    action={"add"} 
                    functionCallback={this.getReportFromApi}
                    className={!withoutStyle ? "btn btn-default btn-bordered-success ml-2" : "button-add-a dropdown-item"}  
                    title={"Add new SFTP Account"}
                    withoutStyle={withoutStyle}
                    style={withoutStyle ? {marginLeft: '1rem !important'}: {}} 
                />;
    }
    
    getReportFromApi(){
        this.props.global.setLoading(true);
        this.props.reports.getReportxChangeFromApi('xChangeAccount').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = this.props.global.getTextParseMsgError(error);
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }
     
    clearFilters(){
        this.props.reports.clearFilters();
        this.getReportFromApi();
    }
 
    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
 
    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }
 
    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    renderTdFrequency(record){
        if (Array.isArray(record.schedule) && record.schedule[0].frequency) {
            let frequency = record.schedule[0].frequency.toLowerCase();
            if (frequency !== 'weekly') {
                return this.props.global.getXchangeFrequency(frequency);
            } else {
                let days = [], object = record.schedule[0];
                for (let key in object){
                    if (key === 'Monday' && object[key] === true) {
                        days.push(key);
                    } else if (key === 'Tuesday' && object[key] === true) {
                        days.push(key);
                    } else if (key === 'Wednesday' && object[key] === true) {
                        days.push(key);
                    } else if (key === 'Thursday' && object[key] === true) {
                        days.push(key);
                    } else if (key === 'Friday' && object[key] === true) {
                        days.push(key);
                    } else if (key === 'Saturday' && object[key] === true) {
                        days.push(key);
                    } else if (key === 'Sunday' && object[key] === true) {
                        days.push(key);
                    }
                }
                return (
                    <OverlayTrigger
                        placement={'bottom'}
                        overlay={<Tooltip>{days.length > 0 ? `Weekly; on ${days.join(', ')}` : 'Weekly'}</Tooltip>}
                    >
                        {this.props.global.getXchangeFrequency(frequency)}
                    </OverlayTrigger>
                );
            }
        } else {
            return <span className="badge bg-light"><CgUnavailable /> N/A</span>;
        }
    }

    deleteSftpAccount(id){
        this.setState({ sftpAccountToDelete: id });
        this.openDeleteModal();
    }

    openDeleteModal(){
        this.setState({ deleteModalIsOpen: true });
    }

    closeDeleteModal(){
        this.setState({ deleteModalIsOpen: false });
    }

    deleteSftpAccountAction(){
        let sftpAccountToDelete = this.state.sftpAccountToDelete;        
        if(sftpAccountToDelete){
            this.props.global.setLoading(true);
            this.props.reports.deleteSftpAccount(sftpAccountToDelete)
            .then(response => {
                this.closeDeleteModal();
                this.getReportFromApi();
                this.setState({ deleteModalIsOpen: false });
                toast.success("Data deleted successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = this.props.global.getTextParseMsgError(error);
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    getModeText(modeNumber){
        switch (modeNumber) {
            case 0:
                return 'Download';
            case 1:
                return 'Upload';
            case 2:
                return 'Both';
            default:
                return '-';
        }
    }

    render() {
        return (
            <Layout {...this.props}>                
                <div>
                    <TopBar>
                        <MainTopBarMenu/>
                        <div className="top-bar-sub">
                            <DevelopersLinks selected="sftpaccounts"/>
                        </div>
                    </TopBar>
                    <ParentFilterPanel report={'xChangeAccount'} />
                    <div className="mt-body4">
                        <MainBar
                            reportNameComponent={this.getReportNameComponent}
                            dataQAName="CatalogReports"
                            showHideFilters={this.showHideFilters}
                            selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                            handleShowColumn={(e) => this.handleShowColumn(e)}
                            totalRecords={this.props.reports.totalRecords}
                            getHeaders={this.props.reports.getHeaders}
                            headers={this.props.reports.headers}
                            refreshView={this.clearFilters}
                            skipTotal={true}
                            buttonAddComponent={this.buttonAddComponent}
                            flagItemAllColumn={this.state.flagItemAllColumn}
                        />
                        <div className="report-container">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        { this.props.reports.getHeaders.map((record, i) =>
                                            ( record[1].display && <th key={i} scope="col" className={record[1].class}>{record[1].label}</th> )
                                        )}
                                        <th className='text-center sticky-row' scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { this.props.reports.records.map((record, i) => (
                                        <tr key={i}>
                                            { (this.props.reports.headers.parentOrgName && this.props.reports.headers.parentOrgName.display) &&
                                                <td>{record.parentOrgName ? record.parentOrgName : '-'}</td>
                                            }
                                            { (this.props.reports.headers.legalName && this.props.reports.headers.legalName.display) &&
                                                <td>{record.legalName ? record.legalName : '-'}</td>
                                            }
                                            { (this.props.reports.headers.userId && this.props.reports.headers.userId.display) &&
                                                <td>{record.userId ? record.userId : '-'}</td>
                                            }
                                            { (this.props.reports.headers.server && this.props.reports.headers.server.display) &&
                                                <td>{record.server ? record.server : '-'}</td>
                                            }
                                            { (this.props.reports.headers.frequency && this.props.reports.headers.frequency.display) &&
                                                <td>{this.renderTdFrequency(record)}</td>
                                            }
                                            { (this.props.reports.headers.schedule && this.props.reports.headers.schedule.display) &&
                                                <td>{Array.isArray(record.schedule) && record.schedule[0].timeOfDay ? this.props.global.getXchangeFrequencyScheduleTime(record.schedule[0].timeOfDay) : <span className="badge bg-light"><CgUnavailable /> N/A</span>}</td>
                                            }
                                            { (this.props.reports.headers.mode && this.props.reports.headers.mode.display) &&
                                                <td>{this.getModeText(record.mode)}</td>
                                            }
                                            <td className='text-center sticky-row'>
                                                <DropdownButton
                                                    menuAlign="right"
                                                    title={<BiDotsVerticalRounded/>}
                                                    id="actionsMenuButton"
                                                    size="sm"
                                                    variant="default"
                                                >
                                                    <EditSftpAccounts action={"edit"} id={record.idApiAccount ? record.idApiAccount : null} idSftpConfig={record.idSftpConfig ? record.idSftpConfig : null} withoutStyle={true} functionCallback={this.getReportFromApi} className={"button-add-a dropdown-item"} title={"Edit SFTP Account"} />
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item style={{color: '#c00000'}} onClick={(e) => this.deleteSftpAccount(record.idSftpConfig)}>Delete</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {this.props.reports.records.length < 1 && 
                                <RecordsNotFound message="No SFTP Accounts yet" description={<span>Sorry. We don't have any record to show.</span>}/>
                            }
                        </div>
                        <ReportPaginator report="xChangeAccount" option={'xchange'} />
                    </div>
                    <ToastContainer transition={Bounce} />
                    <Modal style={{textAlign: "center"}} show={this.state.deleteModalIsOpen} onHide={this.closeDeleteModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            <BiTrash className="icon-modal"/>
                            <h5>Delete</h5>
                            {
                                <>
                                    <p className="small">Are you sure you want to delete this account?</p>
                                    <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeDeleteModal()}>
                                        Cancel
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button className="btn" variant="danger" onClick={(e) => this.deleteSftpAccountAction()}>
                                        Delete
                                    </Button>
                                </>
                            }                   
                        </Modal.Body>
                    </Modal>
                </div>
            </Layout>
        )
    }
}

export { SftpAccounts };