import React from 'react';
import { inject, observer } from 'mobx-react';
import { Bar } from 'react-chartjs-2';

@inject('global', 'entry')
@observer
class BarChartEntry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: ""
        };
      
    }

    render() {
        let entryDataChart = this.props.entry.entryDataChart;
        let reactObj = this;
        const data = {
            labels: entryDataChart['labels'],
            datasets: [
              {
                label: '$',
                data: entryDataChart['values'],
                backgroundColor:  '#008bca',
               
              },
            ],
          };

          const options = {
            plugins: {
              legend: {
                  display: false
              },
              tooltip: {
                mode: 'index',
                intersect: false,
                backgroundColor: '#ffffff',
                position: 'average',
                titleColor: '#5A5F7D',
                titleFontSize: 13,
                titleSpacing: 15,
                bodyColor: '#333333',
                borderColor: '#F1F2F6',
                borderWidth: 2,
                padding: 10,
                z: 999999,
                custom: function(tooltip) {
                  if (!tooltip) return;
                  tooltip.displayColors = false;
                },
                callbacks: {
                  title() {
                      //return ``;
                  },
                  label(t) {
                    return `${t.label}: $${reactObj.props.global.numberWithCommas(parseFloat(t.raw).toFixed(2))}`;
                  },
                  labelColor(tooltipItem, chart) {
                    return {
                      backgroundColor: "transparent",
                      borderColor: 'transparent',
                    };
                  },
                }
              }
            },
            responsive: true,
            scales: {
                x: {
                    //display: false,
                    grid: {
                      display: false
                    },
                    ticks: {
                      display: true,
                    }
                  },
                  y: {
                    display: false
                  }
            }
          };

        return (
             <>
                <Bar data={data} options={options} />
            </>
        )
    }
}

export { BarChartEntry };