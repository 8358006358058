import React from 'react'
import { inject, observer } from 'mobx-react'
import { Tab, Tabs, Row, Button, Table, InputGroup, Modal, Card, Tooltip, OverlayTrigger } from 'react-bootstrap'
import axios from 'axios'
import { InputSimpleLink } from '../../components/InputSimpleLink'
import { ReadOnlySimpleLink } from '../../components/ReadOnlySimpleLink'
import { InputSelectLink } from '../../components/InputSelectLink'
import { InputCalendarLink } from '../../components/InputCalendarLink'
import { CheckLink } from '../../components/CheckLink'
import { ReadOnlyCheckLink } from '../../components/ReadOnlyCheckLink'
import { ToastContainer, toast, Bounce } from 'react-toastify'
import { InputSelectSearchable } from '../../components/InputSelectSearchable'
import 'react-toastify/dist/ReactToastify.css'
import { Brand } from '../../components/Brand'
import { BiTrash } from '@react-icons/all-files/bi/BiTrash'
import { BiChevronRight } from '@react-icons/all-files/bi/BiChevronRight'
import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft'
import { BiDetail } from '@react-icons/all-files/bi/BiDetail'
import { BiUserCheck } from '@react-icons/all-files/bi/BiUserCheck'
import { BiCog } from '@react-icons/all-files/bi/BiCog'
import { BiX } from '@react-icons/all-files/bi/BiX'
import { RiBankLine } from '@react-icons/all-files/ri/RiBankLine'
import { BiDollarCircle } from '@react-icons/all-files/bi/BiDollarCircle'
import { BiPlus } from '@react-icons/all-files/bi/BiPlus'
import { PayabliStorageManager } from '../../api/localStorageManager'
import { BiCheckCircle } from '@react-icons/all-files/bi/BiCheckCircle'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle'
import { LocationSearchInput } from '../../components/LocationSearchInput'
import visa from '../../assets/images/visa.svg'
import mastercard from '../../assets/images/mastercard.svg'
import amex from '../../assets/images/amex.png'
import discover from '../../assets/images/discover.svg'
import achIcon from '../../assets/images/ach.svg'
import PhoneInput from '../../components/PhoneInput'

@inject('boarding', 'store', 'global')
@observer
class EditApplicationLink extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			logo: null,
			email: null,
			referenceId: null,
			error: null,
			baseTemplate: null,
			baseData: null,
			appId: 0,
			filesSize: 0,
			fileExceededSize: false,
			lastFileSize: 0,
			currentSection: 'business',
			offOwn: true,
			offPrc: true,
			offSrv: true,
			offDoc: true,
			complete: false,
			bsameaddress: false,
			endLinkModalIsOpen: false,
			additionalOwners: [],
			lastowner: 0,
			additionalContacts: [],
			lastcontact: 0,
			additionalFiles: [],
			lastfile: 0,
			currentPos: 0,
			activePos: [],
			login: true,
			cancelTplModalIsOpen: false,
			saveTplModalIsOpen: false,
			inside: false,
			saveLinkModalIsOpen: false,
			isRoot: false,
			termsAccepted: [],
			checkAccepted: [],
			signer: {
				name: '',
				ssn: '',
				dob: '',
				phone: '',
				email: '',
				address: '',
				address1: '',
				state: '',
				country: '',
				city: '',
				zip: '',
				acceptance: false
			},
			signers: [],
			validTabs: []
		}
		this.setTab = this.setTab.bind(this)
		this.bSameaddresshandleChange = this.bSameaddresshandleChange.bind(this)
		this.addOwner = this.addOwner.bind(this)
		this.removeOwner = this.removeOwner.bind(this)
		this.addOwnerContacts = this.addOwnerContacts.bind(this)
		this.onFileChange = this.onFileChange.bind(this)
		this.addDoc = this.addDoc.bind(this)
		this.removeDoc = this.removeDoc.bind(this)
		this.saveToComplete = this.saveToComplete.bind(this)
		this.submitComplete = this.submitComplete.bind(this)
		this.closeEndLinkModal = this.closeEndLinkModal.bind(this)
		this.getApplicationFromApi = this.getApplicationFromApi.bind(this)
		this.handleAcceptClick = this.handleAcceptClick.bind(this)
		this.handleAcceptCheckClick = this.handleAcceptCheckClick.bind(this)
		this.handleTextChangeMask = this.handleTextChangeMask.bind(this)
		this.handleGoogleAutocompleteBusinessAddress = this.handleGoogleAutocompleteBusinessAddress.bind(this)
		this.handleGoogleAutocompleteBusinessMAddress = this.handleGoogleAutocompleteBusinessMAddress.bind(this)
		this.handleGoogleAutocompleteOwnerAddress = this.handleGoogleAutocompleteOwnerAddress.bind(this)
		this.handleGoogleAutocompleteSignerAddress = this.handleGoogleAutocompleteSignerAddress.bind(this)
		this.setOwnerDataDOB = this.setOwnerDataDOB.bind(this)
	}

	setOwnerDataDOB(k, data, field) {
		let baseData = this.state.baseData
		if (baseData.ownerData && baseData.ownerData[k]) {
			baseData.ownerData[k][field] = data
		}

		this.setState({
			baseData: baseData
		})
	}

	setSignerDataDOB(k, data, field) {
		let signer = this.self.state.signer
		if (signer) {
			signer[field] = data
		}

		this.self.setState({
			signer: signer
		})
	}

	setBusinessDataDate(k, data, field) {
		let baseData = this.self.state?.baseData
		if (baseData && baseData[field]) {
			baseData[field] = data
		}
		this.self.setState({
			baseData: baseData
		})
	}

	handleGoogleAutocompleteSignerAddress(signer_address, address2, signer_city, signer_state, signer_zip, signer_country) {
		this.props.boarding.setTemplateData('signer_address', signer_address)
		this.props.boarding.setTemplateData('signer_address2', address2)
		this.props.boarding.setTemplateData('signer_city', signer_city)
		this.props.boarding.setTemplateData('signer_state', signer_state)
		this.props.boarding.setTemplateData('signer_zip', signer_zip)
		this.props.boarding.setTemplateData('signer_country', signer_country)
		let signer = this.state.signer
		signer.country = signer_country ? signer_country : ''
		signer.city = signer_city ? signer_city : ''
		signer.state = signer_state ? signer_state : ''
		signer.address = signer_address ? signer_address : ''
		signer.address1 = address2 ? address2 : ''
		signer.zip = signer_zip ? signer_zip : ''
		this.setState({
			signer: signer
		})
	}

	handleGoogleAutocompleteOwnerAddress(oaddress, address2, ocity, ostate, ozip, ocountry, k) {
		this.props.boarding.setTemplateData('oaddress_' + k, oaddress)
		this.props.boarding.setTemplateData('ocity_' + k, ocity)
		this.props.boarding.setTemplateData('ostate_' + k, ostate)
		this.props.boarding.setTemplateData('ozip_' + k, ozip)
		this.props.boarding.setTemplateData('ocountry_' + k, ocountry)
		let values = {}
		values['ocountry_' + k] = ocountry ? ocountry : ''
		values['ocity_' + k] = ocity ? ocity : ''
		values['ostate_' + k] = ostate ? ostate : ''
		values['ozip_' + k] = ozip ? ozip : ''
		values['oaddress_' + k] = oaddress ? oaddress : ''
		this.setState(values)
	}

	handleGoogleAutocompleteBusinessAddress(baddress, baddress1, bcity, bstate, bzip, bcountry) {
		this.props.boarding.setTemplateData('baddress', baddress)
		this.props.boarding.setTemplateData('baddress1', baddress1)
		this.props.boarding.setTemplateData('bcity', bcity)
		this.props.boarding.setTemplateData('bstate', bstate)
		this.props.boarding.setTemplateData('bzip', bzip)
		this.props.boarding.setTemplateData('bcountry', bcountry)
		this.setState({
			baddress: baddress ? baddress : '',
			baddress1: baddress1 ? baddress1 : '',
			bcity: bcity ? bcity : '',
			bstate: bstate ? bstate : '',
			bzip: bzip ? bzip : '',
			bcountry: bcountry ? bcountry : ''
		})
	}

	handleGoogleAutocompleteBusinessMAddress(maddress, maddress1, mcity, mstate, mzip, mcountry) {
		this.props.boarding.setTemplateData('maddress', maddress)
		this.props.boarding.setTemplateData('maddress1', maddress1)
		this.props.boarding.setTemplateData('mcity', mcity)
		this.props.boarding.setTemplateData('mstate', mstate)
		this.props.boarding.setTemplateData('mzip', mzip)
		this.props.boarding.setTemplateData('mcountry', mcountry)
		this.setState({
			maddress: maddress ? maddress : '',
			maddress1: maddress1 ? maddress1 : '',
			mcity: mcity ? mcity : '',
			mstate: mstate ? mstate : '',
			mzip: mzip ? mzip : '',
			mcountry: mcountry ? mcountry : ''
		})
	}

	handleTextChangeMask(e, value) {
		this.props.boarding.setTemplateData(e, value)
		this.setState({ [e]: value })
	}

	handleAcceptClick = (e) => {
		e.preventDefault()
		let index = e.target.getAttribute('term')
		if (this.state.termsAccepted.length > 0) {
			let accept = this.state.termsAccepted
			accept[index] = true
			this.setState({ termsAccepted: accept })
		} else {
			let accept = Array(this.state.baseTemplate.banking.termsConditions.length).fill(false)
			accept[index] = true
			this.setState({ termsAccepted: accept })
		}
		let href = e.target.getAttribute('href')
		if (href === null) {
			window.open('/404', '_blank')
		} else {
			window.open(href, '_blank')
		}
	}

	handleAcceptCheckClick = (e) => {
		let accept = this.state.termsAccepted
		let allchecked = (arr) => arr.every((v) => v === true)
		let signer = this.state.signer
		let signer_acceptance = false
		if (e.target.checked) {
			if (accept.length === 0 || !allchecked(accept)) {
				e.preventDefault()
				toast.error('You must click and review link(s) above before agreeing.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			} else {
				signer.acceptance = true
				signer_acceptance = true
				this.setState({ signer: signer })
			}
		} else {
			signer.acceptance = false
			this.setState({ signer: signer })
		}
		this.props.boarding.setTemplateData('signer_acceptance', signer_acceptance)
	}

	navigateAllTabs() {
		let objThis = this
		setTimeout(function () {
			objThis.gotoNextTab(0)
			objThis.gotoNextTab(1)
			objThis.gotoNextTab(2)

			let validTabs = objThis.state.validTabs
			if (objThis.props.boarding.errors()) {
				validTabs[3] = false
			} else {
				objThis.gotoNextTab(3)
			}
			objThis.setState({ validTabs: validTabs })
			objThis.gotoNextTab(4)
			objThis.gotoNextTab(0)

			objThis.props.global.setLoading(false)
		}, 1000)
	}

	onFileChange(event) {
		const div = document.getElementById(event.target.name)
		const currentFileSize = this.state.filesSize + event.target.files[0].size - this.state.lastFileSize
		if (currentFileSize > 31457280) {
			event.target.value = null
			this.setState({ fileExceededSize: true })
		} else {
			if (event.target.files.length > 0) {
				div.classList.remove('input-error')
				this.props.boarding.setErrorData(event.target.name, false)
				this.props.boarding.setTemplateData(event.target.name, event.target.files[0])
			} else {
				div.classList.add('input-error')
				this.props.boarding.setErrorData(event.target.name, true)
				this.props.boarding.setTemplateData(event.target.name, null)
			}
		}
	}

	bSameaddresshandleChange(checked) {
		this.setState({ bsameaddress: checked.target.checked }, function () {
			let template = this.state.baseTemplate
			if (this.state.bsameaddress) {
				if (this.props.boarding.template_data.baddress) {
					template.business.address.maddress.value = this.props.boarding.template_data.baddress.value
					this.props.boarding.setTemplateData('maddress', this.props.boarding.template_data.baddress.value)
					template.business.address.maddress.ro = true
					this.props.boarding.setErrorData('maddress', false)
				}
				if (this.props.boarding.template_data.baddress1) {
					template.business.address.maddress1.value = this.props.boarding.template_data.baddress1.value
					this.props.boarding.setTemplateData('maddress1', this.props.boarding.template_data.baddress1.value)
					template.business.address.maddress1.ro = true
					this.props.boarding.setErrorData('maddress1', false)
				}
				if (this.props.boarding.template_data.bcity) {
					template.business.address.mcity.value = this.props.boarding.template_data.bcity.value
					this.props.boarding.setTemplateData('mcity', this.props.boarding.template_data.bcity.value)
					template.business.address.mcity.ro = true
					this.props.boarding.setErrorData('mcity', false)
				}
				if (this.props.boarding.template_data.bstate) {
					template.business.address.mstate.value = this.props.boarding.template_data.bstate.value
					this.props.boarding.setTemplateData('mstate', this.props.boarding.template_data.bstate.value)
					template.business.address.mstate.ro = true
					this.props.boarding.setErrorData('mstate', false)
				}
				if (this.props.boarding.template_data.bzip) {
					template.business.address.mzip.value = this.props.boarding.template_data.bzip.value
					this.props.boarding.setTemplateData('mzip', this.props.boarding.template_data.bzip.value)
					template.business.address.mzip.ro = true
					this.props.boarding.setErrorData('mzip', false)
				}
				if (this.props.boarding.template_data.bcountry) {
					template.business.address.mcountry.value = this.props.boarding.template_data.bcountry.value
					this.props.boarding.setTemplateData('mcountry', this.props.boarding.template_data.bcountry.value)
					template.business.address.mcountry.ro = true
					this.props.boarding.setErrorData('mcountry', false)
				}
			} else {
				if (this.props.boarding.template_data.maddress) {
					template.business.address.maddress.ro = false
				}
				if (this.props.boarding.template_data.maddress1) {
					template.business.address.maddress1.ro = false
				}
				if (this.props.boarding.template_data.mcity) {
					template.business.address.mcity.ro = false
				}
				if (this.props.boarding.template_data.mstate) {
					template.business.address.mstate.ro = false
				}
				if (this.props.boarding.template_data.mzip) {
					template.business.address.mzip.ro = false
				}
				if (this.props.boarding.template_data.mcountry) {
					template.business.address.mcountry.ro = false
				}
			}
			this.setState({ baseTemplate: template })
		})
	}

	getProgressBarColor() {
		let color = '#dc3545'
		let progressBarPercent = this.getProgressBarPercent()

		if (progressBarPercent <= 35) {
			color = '#dc3545'
		} else if (progressBarPercent > 35 && progressBarPercent < 70) {
			color = '#ffc107'
		} else if (progressBarPercent >= 70) {
			color = '#88c952'
		}
		return color
	}

	getProgressBarPercent() {
		let validTabs = this.state.validTabs
		let percent = 0
		validTabs.forEach((element) => {
			if (element === true) {
				percent = percent + 20
			}
		})
		return percent
	}

	gotoNextTabAndSubmit(_n) {
		let validTabs = this.state.validTabs
		for (let x = 0; x < 5; x++) {
			if (this.props.boarding.isValidTab(x, this.state.additionalOwners, this.state.additionalContacts)) {
				validTabs[x] = true
			} else {
				validTabs[x] = false
			}
			this.props.boarding.setNextClicked(true)
		}

		this.gotoNextTab(_n)
		this.submitComplete()
		this.setState({ validTabs: validTabs })
	}

	componentDidMount() {
		var inside = this.state.inside

		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		if (encryptStorage.getItem('pToken')) {
			inside = true
		}
		this.setState({ appId: this.props.match.params.id, inside: inside })
		this.props.boarding.clearTemplateData()
		this.props.boarding.clearErrorData()
	}

	gotoNextTab(_n) {
		let validTabs = this.state.validTabs
		if (this.props.boarding.isValidTab(this.state.currentPos, this.state.additionalOwners, this.state.additionalContacts)) {
			validTabs[this.state.currentPos] = true
		} else {
			validTabs[this.state.currentPos] = false
		}
		this.setState({ validTabs: validTabs })

		if (_n > 5) return

		let actv = this.state.activePos
		if (actv.indexOf(_n) < 0) {
			_n = _n + 1
			this.gotoNextTab(_n)
			return
		}

		if (_n === 5) _n = 4

		this.setState({ currentPos: _n })
		this.setCurrentSection(_n)
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	}

	gotoPrevTab(_n) {
		let validTabs = this.state.validTabs
		if (this.props.boarding.isValidTab(this.state.currentPos, this.state.additionalOwners, this.state.additionalContacts)) {
			validTabs[this.state.currentPos] = true
		} else {
			validTabs[this.state.currentPos] = false
		}
		this.setState({ validTabs: validTabs })

		if (_n < 0) return

		let actv = this.state.activePos
		if (actv.indexOf(_n) < 0) {
			_n = _n - 1
			this.gotoPrevTab(_n)
			return
		}

		if (_n === 5) _n = 4

		this.setState({ currentPos: _n })
		this.setCurrentSection(_n)
	}

	setCurrentSection(_n) {
		switch (_n) {
			case 0:
				this.setState({ currentSection: 'business' })
				break
			case 1:
				this.setState({ currentSection: 'owners' })
				break
			case 2:
				this.setState({ currentSection: 'processing' })
				break
			case 3:
				this.setState({ currentSection: 'services' })
				break
			case 4:
			default:
				this.setState({ currentSection: 'banking' })
				break
		}
	}

	setTab(event) {
		let cp = 0
		if (event === 'business') {
			cp = 0
		}
		if (event === 'owners') {
			cp = 1
		}
		if (event === 'processing') {
			cp = 2
		}
		if (event === 'services') {
			cp = 3
		}
		if (event === 'banking') {
			cp = 4
		}

		let validTabs = this.state.validTabs
		if (this.props.boarding.isValidTab(this.state.currentPos, this.state.additionalOwners, this.state.additionalContacts)) {
			validTabs[this.state.currentPos] = true
		} else {
			validTabs[this.state.currentPos] = false
		}
		this.setState({ validTabs: validTabs })

		this.setState({ currentSection: event, currentPos: cp })
	}

	handleTextChange(event) {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleKeyDown(e) {
		if (e.key === 'Enter') {
			this.getApplicationFromApi()
		}
	}

	getApplicationFromApi() {
		this.props.boarding.clearTemplateData()
		this.props.boarding.clearErrorData()
		this.setState({ error: '' })
		this.props.global.setLoading(true)
		return axios
			.post(
				process.env.REACT_APP_URL_API + 'Boarding/read/' + this.state.appId,
				{
					email: this.state.email,
					referenceId: this.state.referenceId
				},
				{
					headers: { requestToken: process.env.REACT_APP_TOKEN }
				}
			)
			.then((tdata) => {
				if (!tdata.data.builderData || tdata.data.builderData === null) {
					let errorMessage = 'Missing Template Data!'
					this.setState({ error: errorMessage })
					return
				}
				let signer = tdata.data.signer
				signer.acceptance = false
				this.setState({ baseTemplate: tdata.data.builderData, baseData: tdata.data, isLoadingBase: false, update: true, signer: signer, appId: tdata.data.idApplication, orgId: tdata.data.orgId, login: false }, () => {
					if (this.state.baseTemplate) {
						if (this.state.baseTemplate.attributes && this.state.baseTemplate.attributes.minimumDocuments) {
							let x = parseInt(this.state.baseTemplate.attributes.minimumDocuments, 10)
							this.setState({ lastfile: x })
						}
						if (this.state.baseData.contactData !== null && this.state.baseData.contactData.length > 1) {
							var cts = []
							for (var cc = 1; cc < this.state.baseData.contactData.length; cc++) {
								cts.push(cc)
							}
							this.setState({ additionalContacts: cts, lastcontact: this.state.baseData.contactData.length })
						}
						if (this.state.baseData.ownerData !== null && this.state.baseData.ownerData.length > 1) {
							var ots = []
							for (var oc = 1; oc < this.state.baseData.ownerData.length; oc++) {
								ots.push(oc)
							}
							this.setState({ additionalOwners: ots, lastowner: this.state.baseData.ownerData.length })
						}
						//bsameaddress checkbox
						if (
							this.state.baseTemplate.business !== null &&
							this.state.baseTemplate.business.address !== null &&
							(this.state.baseTemplate.business.address.baddress !== null || this.state.baseTemplate.business.address.baddress1 !== null || this.state.baseTemplate.business.address.bcity !== null || this.state.baseTemplate.business.address.bstate !== null || this.state.baseTemplate.business.address.bzip !== null || this.state.baseTemplate.business.address.bcountry !== null) &&
							(this.state.baseTemplate.business.address.maddress !== null || this.state.baseTemplate.business.address.maddress1 !== null || this.state.baseTemplate.business.address.mcity !== null || this.state.baseTemplate.business.address.mstate !== null || this.state.baseTemplate.business.address.mzip !== null || this.state.baseTemplate.business.address.mcountry !== null)
						) {
							this.setState({ bsameaddress: true })
						}
						//init positions
						var atabs = []
						var cs = ''
						if (this.state.baseTemplate.business !== null) {
							atabs.push(0)
							cs = 'business'
						}
						if (this.state.baseTemplate.owners !== null) {
							atabs.push(1)
							if (cs === '') cs = 'owners'
						}
						if (this.state.baseTemplate.processing !== null) {
							atabs.push(2)
							if (cs === '') cs = 'processing'
						}
						if (this.state.baseTemplate.services !== null) {
							atabs.push(3)
							if (cs === '') cs = 'services'
						}
						if (this.state.baseTemplate.banking !== null) {
							atabs.push(4)
							if (cs === '') cs = 'banking'
						}
						atabs.push(5)
						this.setState({ currentSection: cs, activePos: atabs }, function () {
							this.navigateAllTabs()
						})
					}
				})

				axios
					.get(process.env.REACT_APP_URL_API + 'Organization/basicById/' + tdata.data.orgId, {
						headers: {
							requestToken: process.env.REACT_APP_TOKEN
						}
					})
					.then((orgData) => {
						this.setState({ logo: orgData.data.responseData && orgData.data.responseData.orgLogo ? orgData.data.responseData.orgLogo : null })
						this.props.global.setLoading(false)
					})
					.catch((error) => {
						this.props.global.setLoading(false)
					})
			})
			.catch((error) => {
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				this.setState({ error: errorMessage })
				this.props.global.setLoading(false)
			})
	}

	saveToComplete() {
		let percentage = 0
		for (var j = 0; j <= this.state.additionalOwners.length; j++) {
			if (this.props.boarding.template_data['ownerpercent_' + j]) percentage += parseInt(this.props.boarding.template_data['ownerpercent_' + j].value)
		}
		let tmp_data = this.props.boarding.template_data
		let percentage_proc = 0
		if (tmp_data['binperson']) percentage_proc += parseInt(tmp_data['binperson'].value)
		if (tmp_data['binphone']) percentage_proc += parseInt(tmp_data['binphone'].value)
		if (tmp_data['binweb']) percentage_proc += parseInt(tmp_data['binweb'].value)
		if (percentage_proc > 100) {
			toast.error('Combined total payments percent must be equal to or less than 100%!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (percentage > 100) {
			toast.error('Total ownership must be equal to or less than 100%!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else {
			this.props.global.setLoading(true)
			this.props.boarding
				.saveApplication(this.state.baseData.orgId, this.state.linkId, this.state.additionalOwners, this.state.additionalContacts, this.state.update, this.state.baseData.idApplication)
				.then((res) => {
					var addc = this.state.additionalContacts
					var naddc = []
					if (addc.length > 0) {
						for (var ci = 0; ci < addc.length; ci++) {
							let cx = addc[ci]
							if (cx !== 0) {
								naddc.push(cx)
							}
						}
					}
					var addo = this.state.additionalOwners
					var naddo = []
					if (addo.length > 0) {
						for (var oi = 0; oi < addo.length; oi++) {
							let ox = addo[oi]
							if (ox !== 0) {
								naddo.push(ox)
							}
						}
					}
					this.setState({ appId: res.data.responseData, additionalContacts: naddc, additionalOwners: naddo })
					this.props.global.setLoading(false)
					toast.success('Application saved successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-success-container'
					})
				})
				.catch((err) => {
					var addc = this.state.additionalContacts
					var naddc = []
					if (addc.length > 0) {
						for (var ci = 0; ci < addc.length; ci++) {
							let cx = addc[ci]
							if (cx !== 0) {
								naddc.push(cx)
							}
						}
					}
					var addo = this.state.additionalOwners
					var naddo = []
					if (addo.length > 0) {
						for (var oi = 0; oi < addo.length; oi++) {
							let ox = addo[oi]
							if (ox !== 0) {
								naddo.push(ox)
							}
						}
					}
					this.setState({ additionalContacts: naddc, additionalOwners: naddo })
					this.props.global.setLoading(false)
					toast.error('Oops! something went wrong, please review and try again.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				})
		}
	}

	submitComplete() {
		let percentage = 0
		let checkAccepted = typeof this.state.termsAccepted === 'boolean' ? [this.state.termsAccepted] : this.state.termsAccepted
		let allchecked = (arr) => arr.every((v) => v === true)
		let allValueSigner = (signers) => signers.every((s) => s && s.value !== '')
		const { signer_acceptance, signer_address, signer_city, signer_country, signer_email, signer_name, signer_phone, signer_ssn, signer_state, signer_zip } = Object(this.props.boarding.template_data)
		for (var j = 0; j <= this.state.additionalOwners.length; j++) {
			if (this.props.boarding.template_data['ownerpercent_' + j]) percentage += parseInt(this.props.boarding.template_data['ownerpercent_' + j].value)
		}
		let tmp_data = this.props.boarding.template_data
		let percentage_proc = 0
		if (tmp_data['binperson']) percentage_proc += parseInt(tmp_data['binperson'].value)
		if (tmp_data['binphone']) percentage_proc += parseInt(tmp_data['binphone'].value)
		if (tmp_data['binweb']) percentage_proc += parseInt(tmp_data['binweb'].value)
		if (!allValueSigner([signer_address, signer_city, signer_country, signer_email, signer_name, signer_phone, signer_ssn, signer_state, signer_zip])) {
			toast.error('The signer informations is incomplete.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (this.state.baseTemplate.banking?.termsConditions && this.state.baseTemplate.banking?.termsConditions?.length !== 0 && ((signer_acceptance && signer_acceptance.value === false) || !signer_acceptance)) {
			toast.error('The signer has not accepted the terms.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (percentage_proc > 100) {
			toast.error('Combined total payments percent must be equal to or less than 100%!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (percentage > 100) {
			toast.error('Total ownership must be equal to or less than 100%!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (this.props.boarding.errors()) {
			this.setState({ complete: false })
			toast.error('Please review the information required or with errors and try again.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else if (checkAccepted.length !== 0 && !allchecked(checkAccepted)) {
			toast.error('You must click and review link(s) above before agreeing.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		} else {
			//submit app
			this.props.global.setLoading(true)
			this.props.boarding
				.saveApplication(this.state.baseData.orgId, this.state.linkId, this.state.additionalOwners, this.state.additionalContacts, true, this.state.baseData.idApplication)
				.then((res) => {
					var addc = this.state.additionalContacts
					var naddc = []
					if (addc.length > 0) {
						for (var ci = 0; ci < addc.length; ci++) {
							let cx = addc[ci]
							if (cx !== 0) {
								naddc.push(cx)
							}
						}
					}
					var addo = this.state.additionalOwners
					var naddo = []
					if (addo.length > 0) {
						for (var oi = 0; oi < addo.length; oi++) {
							let ox = addo[oi]
							if (ox !== 0) {
								naddo.push(ox)
							}
						}
					}
					toast.success('Application saved successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-success-container'
					})
					this.setState({ appId: res.data.responseData, additionalContacts: naddc, additionalOwners: naddo })
					this.props.boarding
						.updateApplicationStatus(res.data.responseData, 1)
						.then((res) => {
							this.props.global.setLoading(false)
							this.setState({ login: true, email: null, referenceId: null })
						})
						.catch(() => {
							this.props.global.setLoading(false)
							this.setState({ login: true, email: null, referenceId: null })
						})
				})
				.catch((err) => {
					var addc = this.state.additionalContacts
					var naddc = []
					if (addc.length > 0) {
						for (var ci = 0; ci < addc.length; ci++) {
							let cx = addc[ci]
							if (cx !== 0) {
								naddc.push(cx)
							}
						}
					}
					var addo = this.state.additionalOwners
					var naddo = []
					if (addo.length > 0) {
						for (var oi = 0; oi < addo.length; oi++) {
							let ox = addo[oi]
							if (ox !== 0) {
								naddo.push(ox)
							}
						}
					}
					this.setState({ additionalContacts: naddc, additionalOwners: naddo })
					this.props.global.setLoading(false)
					toast.error('Oops! something went wrong, please review and try again.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				})
		}
	}

	closeEndLinkModal() {
		if (this.state.endLinkModalIsOpen) {
			window.location.reload()
		}
	}

	closeSaveLinkModal() {
		this.setState({ saveLinkModalIsOpen: false })
		window.location.reload()
	}
	onCopyLegalName(e) {
		this.setState({
			taxfillname: this.props.boarding.template_data.legalname.value
		})
	}
	closeSaveLinkModalContinue() {
		axios
			.put(process.env.REACT_APP_URL_API + 'Boarding/applink/' + this.state.appId + '/' + this.state.email, null, {
				headers: {
					requestToken: process.env.REACT_APP_TOKEN
				}
			})
			.then((applink_data) => {
				const applink_hex = applink_data.data.responseData.appLink.split('/').slice(-1)[0]
				this.setState({ saveLinkModalIsOpen: false, notclear: true, appId: applink_hex }, function () {
					this.getApplicationFromApi()
				})
			})
	}

	addOwnerContacts() {}

	removeOwner(k) {
		if (this.state.additionalOwners.length === 0) return
		let f = this.state.additionalOwners
		let idx = -1
		let g = this.state.lastowner - 1
		for (var i = 0; i < this.state.additionalOwners.length; i++) {
			if (this.state.additionalOwners[i] === k) {
				idx = i
			}
		}
		if (idx >= 0) {
			f.splice(idx, 1)
			this.setState({ additionalOwners: f, lastowner: g })

			let basedata = this.state.baseData
			basedata.ownerData.splice(idx + 1, 1)
		}
	}

	removeDoc(k) {
		if (this.state.additionalFiles.length === 0) return
		let f = this.state.additionalFiles
		let idx = -1
		for (var i = 0; i < this.state.additionalFiles.length; i++) {
			if (this.state.additionalFiles[i] === k) {
				idx = i
			}
		}
		if (idx >= 0) {
			this.props.boarding.setErrorData('doc_' + this.state.additionalFiles[idx], false)
			this.props.boarding.setTemplateData('doc_' + this.state.additionalFiles[idx], null)
			f.splice(idx, 1)
			this.setState({ additionalFiles: f })
		}
	}

	addOwner() {
		if (!this.state.baseTemplate) return
		let f = this.state.additionalOwners
		let g = this.state.lastowner
		let baseData = this.state.baseData
		let newOwner = { ownerdob: null }
		baseData.ownerData.push(newOwner)

		if (g === 0) {
			g = 1
		}

		if (f[f.length - 1] === g) {
			g = g + 1
		}

		f.push(g)
		this.setState({ additionalOwners: f, lastowner: g })
	}

	addContact() {
		if (!this.state.baseTemplate) return
		let f = this.state.additionalContacts
		let g = this.state.lastcontact + 1
		f.push(g)
		this.setState({ additionalContacts: f, lastcontact: g })
	}

	addDoc() {
		if (!this.state.baseTemplate) return
		let f = this.state.additionalFiles
		let g = this.state.lastfile + 1
		f.push(g)
		this.setState({ additionalFiles: f, lastfile: g })
	}

	removeContact(k) {
		if (this.state.additionalContacts.length === 0) return
		let f = this.state.additionalContacts
		let idx = -1
		for (var i = 0; i < this.state.additionalContacts.length; i++) {
			if (this.state.additionalContacts[i] === k) {
				idx = i
			}
		}
		if (idx >= 0) {
			f.splice(idx, 1)
			this.setState({ additionalContacts: f })
		}
	}

	getPercentValue(v) {
		if (this.props.boarding.template_data['ownerpercent_' + v]) return this.props.boarding.template_data['ownerpercent_' + v].value
		if (this.state.baseData.ownerData !== null && this.state.baseData.ownerData[v] !== null) {
			if (this.state.baseData.ownerData[v] && this.state.baseData.ownerData[v].ownerpercent !== null) return this.state.baseData.ownerData[v].ownerpercent
			return '25'
		} else {
			if (this.state.baseTemplate.owners.own_list.ownerpercent.value !== null && this.state.baseTemplate.owners.own_list.ownerpercent.value.length > 0) {
				return this.state.baseTemplate.owners.own_list.ownerpercent.value
			}
			return '25'
		}
	}

	onCopyAddress2Business(e, k) {
		this.setState({
			['oaddress_' + k]: this.props.boarding.template_data['baddress'].value,
			['ocity_' + k]: this.props.boarding.template_data['bcity'].value,
			['ozip_' + k]: this.props.boarding.template_data['bzip'].value,
			['ostate_' + k]: this.props.boarding.template_data['bstate'].value,
			['ocountry_' + k]: this.props.boarding.template_data['bcountry'].value
		})
	}

	getOwnerBlock(k) {
		if (!this.state.baseTemplate.owners || this.state.baseTemplate.owners === null) return
		var rows = []
		let required_fields = []
		let readonly_fields = []
		let invisibles_fields = ['address2']

		if (this.state.baseTemplate.owners.own_list.ownername && this.state.baseTemplate.owners.own_list.ownername !== null) {
			if (this.state.baseTemplate.owners.own_list.ownername.ro === false) {
				rows.push(
					<InputSimpleLink
						iKey={k}
						iField={'ownername'}
						iChangeCallback={this.setOwnerDataDOB}
						iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.ownername.rq) ? true : false}
						iTitle="Owner Name"
						iName={'ownername_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownername : this.state.baseTemplate.owners.own_list.ownername.value}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Owner Name"
						iName={'ownername_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownername : this.state.baseTemplate.owners.own_list.ownername.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.own_list.ownertitle && this.state.baseTemplate.owners.own_list.ownertitle !== null) {
			if (this.state.baseTemplate.owners.own_list.ownertitle.ro === false) {
				rows.push(
					<InputSimpleLink
						iKey={k}
						iField={'ownertitle'}
						iChangeCallback={this.setOwnerDataDOB}
						iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.ownertitle.rq) ? true : false}
						iTooltip="Business Title / Role of the Owner"
						iTitle="Owner Title"
						iName={'ownertitle_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownertitle : this.state.baseTemplate.owners.own_list.ownertitle.value}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="Business Title / Role of the Owner"
						iTitle="Owner Title"
						iName={'ownertitle_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownertitle : this.state.baseTemplate.owners.own_list.ownertitle.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.own_list.ownerpercent && this.state.baseTemplate.owners.own_list.ownerpercent !== null) {
			if (this.state.baseTemplate.owners.own_list.ownerpercent.ro === false) {
				rows.push(
					<InputSimpleLink
						iKey={k}
						iField={'ownerpercent'}
						iChangeCallback={this.setOwnerDataDOB}
						iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.ownerpercent.rq) ? true : false}
						iMask="percentage"
						iTitle="Ownership %"
						iName={'ownerpercent_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerpercent : this.state.baseTemplate.owners.own_list.ownerpercent.value}
						iValidator={this.props.global.getValidator().float}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Ownership %"
						iName={'ownerpercent_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerpercent : this.state.baseTemplate.owners.own_list.ownerpercent.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.own_list.ownerssn && this.state.baseTemplate.owners.own_list.ownerssn !== null) {
			if (this.state.baseTemplate.owners.own_list.ownerssn.ro === false) {
				rows.push(
					<InputSimpleLink
						iKey={k}
						iField={'ownerssn'}
						iChangeCallback={this.setOwnerDataDOB}
						iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.ownerssn.rq) ? true : false}
						iHide={true}
						iTooltip="Business Title / Role of the Owner"
						iMask="ssn"
						iTitle="Owner SSN"
						iName={'ownerssn_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerssn : this.state.baseTemplate.owners.own_list.ownerssn.value}
						iValidator={this.props.global.getValidator().ssn}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iHide={true}
						iTooltip="Business Title / Role of the Owner"
						iMask="ssn"
						iTitle="Owner SSN"
						iName={'ownerssn_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerssn : this.state.baseTemplate.owners.own_list.ownerssn.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.own_list.ownerdob && this.state.baseTemplate.owners.own_list.ownerdob !== null) {
			if (this.state.baseTemplate.owners.own_list.ownerdob.ro === false) {
				rows.push(
					<InputCalendarLink
						iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.ownerdob.rq) ? true : false}
						iMax={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())}
						iTitle="Date of Birth"
						iName={'ownerdob_' + k}
						iKey={k}
						iField={'ownerdob'}
						iChangeCallback={this.setOwnerDataDOB}
						iType="dob"
						iValue={
							this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] && this.state.baseData.ownerData[k] !== null && this.state.baseData.ownerData[k].ownerdob && typeof this.state.baseData.ownerData[k].ownerdob === 'object'
								? this.state.baseData.ownerData[k].ownerdob
								: typeof this.state.baseData.ownerData[k].ownerdob !== 'object'
								? this.props.global.getDateFromValue(this.state.baseData.ownerData[k].ownerdob)
								: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())
						}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Date of Birth"
						iName={'ownerdob_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdob : this.state.baseTemplate.owners.own_list.ownerdob.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.own_list.ownerphone1 && this.state.baseTemplate.owners.own_list.ownerphone1 !== null) {
			if (this.state.baseTemplate.owners.own_list.ownerphone1.ro === false) {
				rows.push(
					<PhoneInput
						iKey={k}
						iField={'ownerphone1'}
						iChangeCallback={this.setOwnerDataDOB}
						iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.ownerphone1.rq) ? true : false}
						iTooltip="Direct Phone Number of the owner"
						iMask="phone"
						iTitle="Phone Number"
						iName={'ownerphone1_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone1 : this.state.baseTemplate.owners.own_list.ownerphone1.value}
						iValidator={this.props.global.getValidator().phone}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="Direct Phone Number of the owner"
						iMask="phone"
						iTitle="Phone Number"
						iName={'ownerphone1_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone1 : this.state.baseTemplate.owners.own_list.ownerphone1.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.own_list.ownerphone2 && this.state.baseTemplate.owners.own_list.ownerphone2 !== null) {
			if (this.state.baseTemplate.owners.own_list.ownerphone2.ro === false) {
				rows.push(
					<PhoneInput
						iKey={k}
						iField={'ownerphone2'}
						iChangeCallback={this.setOwnerDataDOB}
						iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.ownerphone2.rq) ? true : false}
						iMask="phone"
						iTitle="Additional Phone Number"
						iName={'ownerphone2_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone2 : this.state.baseTemplate.owners.own_list.ownerphone2.value}
						iValidator={this.props.global.getValidator().phone}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iMask="phone"
						iTitle="Additional Phone Number"
						iName={'ownerphone2_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone2 : this.state.baseTemplate.owners.own_list.ownerphone2.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.own_list.owneremail && this.state.baseTemplate.owners.own_list.owneremail !== null) {
			if (this.state.baseTemplate.owners.own_list.owneremail.ro === false) {
				rows.push(
					<InputSimpleLink
						iKey={k}
						iField={'owneremail'}
						iChangeCallback={this.setOwnerDataDOB}
						iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.owneremail.rq) ? true : false}
						iMask="email"
						iTooltip="Direct Email of the Owner"
						iTitle="Email Address"
						iName={'owneremail_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].owneremail : this.state.baseTemplate.owners.own_list.owneremail.value}
						iValidator={this.props.global.getValidator().email}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iMask="email"
						iTooltip="Direct Email of the Owner"
						iTitle="Email Address"
						iName={'owneremail_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].owneremail : this.state.baseTemplate.owners.own_list.owneremail.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.own_list.ownerdriver && this.state.baseTemplate.owners.own_list.ownerdriver !== null) {
			if (this.state.baseTemplate.owners.own_list.ownerdriver.ro === false) {
				rows.push(
					<InputSimpleLink
						iKey={k}
						iField={'ownerdriver'}
						iChangeCallback={this.setOwnerDataDOB}
						iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.ownerdriver.rq) ? true : false}
						iTitle="Driver's License #"
						iName={'ownerdriver_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdriver : this.state.baseTemplate.owners.own_list.ownerdriver.value}
						iValidator={this.props.global.getValidator().alphanumeric}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Driver's License #"
						iName={'ownerdriver_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdriver : this.state.baseTemplate.owners.own_list.ownerdriver.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.own_list.odriverstate && this.state.baseTemplate.owners.own_list.odriverstate !== null) {
			if (this.state.baseTemplate.owners.own_list.odriverstate.ro === false) {
				rows.push(
					<InputSelectLink
						iKey={k}
						iField={'odriverstate'}
						iChangeCallback={this.setOwnerDataDOB}
						iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.odriverstate.rq) ? true : false}
						iType="us_states"
						iTitle="Driver's State License"
						iName={'odriverstate_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].odriverstate : this.state.baseTemplate.owners.own_list.odriverstate.value}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Driver's State License"
						iName={'odriverstate_' + k}
						iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].odriverstate : this.state.baseTemplate.owners.own_list.odriverstate.value}
					/>
				)
			}
		}

		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.oaddress && this.state.baseTemplate.owners.own_list.oaddress !== null) {
			if (this.state.baseTemplate.owners.own_list.oaddress.ro === true) {
				readonly_fields.push('address')
				// rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.oaddress.rq) ? true : false} iLink="true" iLinkClick={(e) => this.onCopyAddress2Business(e, k)} iLinkText="Copy address from business details" iTooltip="Residential Street Address of Owner. Federal Law requires us to request this information" iTitle="Owner Address" iName={"oaddress_" + k} iValue={(this.state.owner_exists && this.state.owner_exists[k]['oaddress_']) || this.state.baseTemplate.owners.own_list.oaddress.value || this.state["oaddress_" + k]} iValidator={this.props.global.getValidator().text} />);
			} else if ([true, null].includes(this.state.baseTemplate.owners.own_list.oaddress.rq) ? true : false) {
				required_fields.push('address')
			} else {
				invisibles_fields.push('address')
				// rows.push(<ReadOnlySimpleLink iTooltip="Residential Street Address of Owner. Federal Law requires us to request this information" iTitle="Owner Address" iName={"oaddress_" + k} iValue={this.state.baseTemplate.owners.own_list.oaddress.value} />);
			}
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ocity && this.state.baseTemplate.owners.own_list.ocity !== null) {
			if (this.state.baseTemplate.owners.own_list.ocity.ro === true) {
				readonly_fields.push('city')
				// rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.owners.own_list.oaddress.rq) ? true : false} iLink="true" iLinkClick={(e) => this.onCopyAddress2Business(e, k)} iLinkText="Copy address from business details" iTooltip="Residential Street Address of Owner. Federal Law requires us to request this information" iTitle="Owner Address" iName={"oaddress_" + k} iValue={(this.state.owner_exists && this.state.owner_exists[k]['oaddress_']) || this.state.baseTemplate.owners.own_list.oaddress.value || this.state["oaddress_" + k]} iValidator={this.props.global.getValidator().text} />);
			} else if ([true, null].includes(this.state.baseTemplate.owners.own_list.ocity.rq) ? true : false) {
				required_fields.push('city')
			} else {
				invisibles_fields.push('city')
			}
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ostate && this.state.baseTemplate.owners.own_list.ostate !== null) {
			if (this.state.baseTemplate.owners.own_list.ostate.ro === true) {
				readonly_fields.push('state')
			} else if ([true, null].includes(this.state.baseTemplate.owners.own_list.ostate.rq) ? true : false) {
				required_fields.push('state')
			} else {
				invisibles_fields.push('state')
			}
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ozip && this.state.baseTemplate.owners.own_list.ozip !== null) {
			if (this.state.baseTemplate.owners.own_list.ozip.ro === true) {
				readonly_fields.push('zipcode')
			} else if ([true, null].includes(this.state.baseTemplate.owners.own_list.ozip.rq) ? true : false) {
				required_fields.push('zipcode')
			} else {
				invisibles_fields.push('zipcode')
			}
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ocountry && this.state.baseTemplate.owners.own_list.ocountry !== null) {
			if (this.state.baseTemplate.owners.own_list.ocountry.ro === true) {
				readonly_fields.push('country')
			} else if ([true, null].includes(this.state.baseTemplate.owners.own_list.ocountry.rq) ? true : false) {
				required_fields.push('country')
			} else {
				invisibles_fields.push('country')
			}
		}
		rows.push(
			<LocationSearchInput
				placeholder="Address"
				customSize="col-md-6 mb-3"
				withCountry="true"
				withoutRow="true"
				onChange={this.handleGoogleAutocompleteOwnerAddress}
				handleTextChange={this.handleTextChangeMask}
				address={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].oaddress : this.state.baseTemplate.owners.own_list.oaddress.value}
				city={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ocity : this.state.baseTemplate.owners.own_list.ocity.value}
				state={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ostate : this.state.baseTemplate.owners.own_list.ostate.value}
				zipcode={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ozip : this.state.baseTemplate.owners.own_list.ozip.value}
				country={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ocountry : this.state.baseTemplate.owners.own_list.ocountry.value}
				oindex={k}
				iRequired={required_fields}
				iReadonly={readonly_fields}
				iInvisible={invisibles_fields}
				iKey={k}
				iChangeCallback={this.setOwnerDataDOB}
				iTooltip="Residential Street Address of Owner. Federal Law requires us to request this information"
				// iLink="true"
				// iLinkClick={(e) => this.onCopyAddress2Business(e, k)}
				// iLinkText="Copy address from business details"
				handleTextChangeKeys={['oaddress_' + k, 'address2', 'ocity_' + k, 'ostate_' + k, 'ozip_' + k, 'ocountry_' + k]}
				handleTextChangeKeysBaseData={['oaddress', 'address2', 'ocity', 'ostate', 'ozip', 'ocountry']}
			/>
		)

		return <>{rows}</>
	}

	getContactBlock(k) {
		if (!this.state.baseTemplate.owners) return
		if (this.state.baseTemplate.owners.contact_list === null) return
		var rows = []

		if (this.state.baseTemplate.owners.contact_list.contactName && this.state.baseTemplate.owners.contact_list.contactName !== null) {
			if (this.state.baseTemplate.owners.contact_list.contactName.ro === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.owners.contact_list.contactName.rq) ? true : false}
						iTitle="Contact Name"
						iName={'contactName_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactName : this.state.baseTemplate.owners.contact_list.contactName.value}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Contact Name"
						iName={'contactName_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactName : this.state.baseTemplate.owners.contact_list.contactName.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.contact_list.contactTitle && this.state.baseTemplate.owners.contact_list.contactTitle !== null) {
			if (this.state.baseTemplate.owners.contact_list.contactTitle.ro === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.owners.contact_list.contactTitle.rq) ? true : false}
						iTitle="Contact Title"
						iName={'contactTitle_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactTitle : this.state.baseTemplate.owners.contact_list.contactTitle.value}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Contact Title"
						iName={'contactTitle_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactTitle : this.state.baseTemplate.owners.contact_list.contactTitle.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.contact_list.contactEmail && this.state.baseTemplate.owners.contact_list.contactEmail !== null) {
			if (this.state.baseTemplate.owners.contact_list.contactEmail.ro === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.owners.contact_list.contactEmail.rq) ? true : false}
						iMask="email"
						iTitle="Contact Email"
						iName={'contactEmail_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactEmail : this.state.baseTemplate.owners.contact_list.contactEmail.value}
						iValidator={this.props.global.getValidator().email}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iMask="email"
						iTitle="Contact Email"
						iName={'contactEmail_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactEmail : this.state.baseTemplate.owners.contact_list.contactEmail.value}
					/>
				)
			}
		}
		if (this.state.baseTemplate.owners.contact_list.contactPhone && this.state.baseTemplate.owners.contact_list.contactPhone !== null) {
			if (this.state.baseTemplate.owners.contact_list.contactPhone.ro === false) {
				rows.push(
					<PhoneInput
						iRequired={[true, null].includes(this.state.baseTemplate.owners.contact_list.contactPhone.rq) ? true : false}
						iMask="phone"
						iTitle="Contact Phone"
						iName={'contactPhone_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactPhone : this.state.baseTemplate.owners.contact_list.contactPhone.value}
						iValidator={this.props.global.getValidator().phone}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iMask="phone"
						iTitle="Contact Phone"
						iName={'contactPhone_' + k}
						iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactPhone : this.state.baseTemplate.owners.contact_list.contactPhone.value}
					/>
				)
			}
		}

		return <Row className="mt-4">{rows}</Row>
	}

	getDocumentBlock() {
		var rows = []
		var arows = []
		var files = []
		let zipfile = ''
		if (this.state.baseData.documentsRef && this.state.baseData.documentsRef !== null) {
			if (this.state.baseData.documentsRef.zipfile !== null) zipfile = <b>{'Documents stored in: ' + this.state.baseData.documentsRef.zipfile}</b>
			if (this.state.baseData.documentsRef.filelist !== null && this.state.baseData.documentsRef.filelist.length > 0) {
				for (var j = 0; j < this.state.baseData.documentsRef.filelist.length; j++) {
					files.push(
						<>
							<span className="text-success">{this.state.baseData.documentsRef.filelist[j].originalName}</span>
							<br />
						</>
					)
				}
			}
		}
		let x = parseInt(this.state.baseTemplate.attributes.minimumDocuments, 10)
		if (files.length < x) {
			x = x - files.length
			for (var i = 0; i < x; i++) {
				rows.push(
					<div
						className="col-md-6 mt-2"
						key={i}>
						<input
							type="file"
							className="form-control input-error"
							id={'doc_' + i}
							name={'doc_' + i}
							onChange={this.onFileChange}
							accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain"
						/>
					</div>
				)
				if (!this.props.boarding.existsTemplateData('doc_' + i)) {
					this.props.boarding.setErrorData('doc_' + i, true)
					this.props.boarding.setTemplateData('doc_' + i, null)
				}
			}
		} else {
			x = 0
		}
		if (this.state.additionalFiles.length > 0) {
			for (let j = 0; j < this.state.additionalFiles.length; j++) {
				let f = this.state.additionalFiles[j]
				rows.push(
					<div
						className="col-md-6 mt-2"
						key={f}>
						<InputGroup>
							<input
								type="file"
								className="form-control input-error"
								id={'doc_' + f}
								name={'doc_' + f}
								onChange={this.onFileChange}
								accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain"
							/>
							<Button
								variant="danger"
								onClick={(e) => this.removeDoc(f)}>
								<BiTrash fontSize="1.5em" />
							</Button>
						</InputGroup>
					</div>
				)
				if (!this.props.boarding.existsTemplateData('doc_' + f)) {
					this.props.boarding.setErrorData('doc_' + f, true)
					this.props.boarding.setTemplateData('doc_' + f, null)
				}
			}
		}

		return (
			<>
				<Row className="mt-2">
					<div className="col-3">
						<p>{zipfile}</p>
					</div>
					<div className="col-9">{files}</div>
				</Row>
				<Row className="mt-2">
					{x > 0 && (
						<p className="small">
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip>To help our Underwriting Team get your merchant account ready the more information the better. Some recommended docs are: Current merchant services statements, Drivers License, Business license, Voided Check</Tooltip>}>
								<BiInfoCircle className="info-icon" />
							</OverlayTrigger>
							Please upload supporting documents below; at least 1 file is expected. Upload max is 30MB of any combination of .pdf .jpg or .png files.
						</p>
					)}
					{rows}
					{arows}
					<div
						className="col-md-6 mt-2"
						id="addFiles">
						<button
							className="btn bordered mt-1"
							style={{ margin: '0' }}
							onClick={(e) => this.addDoc()}>
							<BiPlus /> Add more documents
						</button>
					</div>
				</Row>
			</>
		)
	}

	getBusinessBlock() {
		var rows = []
		if (this.state.baseTemplate.business.details && this.state.baseTemplate.business.details !== null) {
			if (this.state.baseTemplate.business.details.dbaname && this.state.baseTemplate.business.details.dbaname !== null) {
				if (this.state.baseTemplate.business.details.dbaname.ro === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.dbaname.rq) ? true : false}
							iTooltip='This is your fictitious "doing business as" name that you may have registered to distinguish from your legal name'
							iTitle="Business DBA Name"
							iName="dbaname"
							iValue={this.state.baseData.dbaName}
							iValidator={this.props.global.getValidator().text}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTooltip='This is your fictitious "doing business as" name that you may have registered to distinguish from your legal name'
							iTitle="Business DBA Name"
							iName="dbaname"
							iValue={this.state.baseData.dbaName}
						/>
					)
				}
			}
			if (this.state.baseTemplate.business.details.legalname && this.state.baseTemplate.business.details.legalname !== null) {
				if (this.state.baseTemplate.business.details.legalname.ro === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.legalname.rq) ? true : false}
							iTitle="Business Legal Name"
							iName="legalname"
							iValue={this.state.baseData.legalName}
							iValidator={this.props.global.getValidator().text}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTitle="Business Legal Name"
							iName="legalname"
							iValue={this.state.baseData.legalName}
						/>
					)
				}
			}
			if (this.state.baseTemplate.business.details.btype && this.state.baseTemplate.business.details.btype !== null) {
				if (this.state.baseTemplate.business.details.btype.ro === false) {
					rows.push(
						<InputSelectLink
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.btype.rq) ? true : false}
							iType="btype"
							iTitle="Business Type"
							iName="btype"
							iValue={this.state.baseData.ownType}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTitle="Business Type"
							iName="btype"
							iValue={this.state.baseData.ownType}
						/>
					)
				}
			}
			if (this.state.baseTemplate.business.details.ein && this.state.baseTemplate.business.details.ein !== null) {
				if (this.state.baseTemplate.business.details.ein.ro === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.ein.rq) ? true : false}
							iTooltip="Tax ID Number or Social Security Number for Sole Proprietor"
							iMask="ein"
							iTitle="Business EIN"
							iName="ein"
							iValue={this.state.baseData.ein}
							iValidator={this.props.global.getValidator().ein}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTooltip="Tax ID Number or Social Security Number for Sole Proprietor"
							iMask="ein"
							iTitle="Business EIN"
							iName="ein"
							iValue={this.state.baseData.ein}
						/>
					)
				}
			}
			if (this.state.baseTemplate.business.details.taxfillname && this.state.baseTemplate.business.details.taxfillname !== null) {
				if (this.state.baseTemplate.business.details.taxfillname.ro === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.taxfillname.rq) ? true : false}
							iTooltip="The combination of your Tax ID Number and your Tax Filing Name must match your IRS Documents"
							iTitle="Tax Filing Name"
							iName="taxfillname"
							iLink="true"
							iLinkClick={(e) => this.onCopyLegalName(e)}
							iLinkText="Tax Fill Name same as Business Legal Name"
							iValue={this.state.taxfillname || this.state.baseData.taxfillname}
							iValidator={this.props.global.getValidator().text}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTooltip="The combination of your Tax ID Number and your Tax Filing Name must match your IRS Documents"
							iTitle="Tax Filing Name"
							iName="taxfillname"
							iValue={this.state.baseData.taxfillname}
						/>
					)
				}
			}
			if (this.state.baseTemplate.business.details.license && this.state.baseTemplate.business.details.license !== null) {
				if (this.state.baseTemplate.business.details.license.ro === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.license.rq) ? true : false}
							iTooltip="ID number for Government Issued Business License"
							iTitle="Business License"
							iName="license"
							iValue={this.state.baseData.license}
							iValidator={this.props.global.getValidator().alphanumeric}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTooltip="ID number for Government Issued Business License"
							iTitle="Business License"
							iName="license"
							iValue={this.state.baseData.license}
						/>
					)
				}
			}
			if (this.state.baseTemplate.business.details.licstate && this.state.baseTemplate.business.details.licstate !== null) {
				if (this.state.baseTemplate.business.details.licstate.ro === false) {
					rows.push(
						<InputSelectLink
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.licstate.rq) ? true : false}
							iTitle="License State"
							iName="licstate"
							iValue={this.state.baseData.licenseState}
							iType="us_states"
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTitle="License State"
							iName="licstate"
							iValue={this.state.baseData.licenseState}
						/>
					)
				}
			}
			if (this.state.baseTemplate.business.details.website && this.state.baseTemplate.business.details.website !== null) {
				if (this.state.baseTemplate.business.details.website.ro === false) {
					rows.push(
						<InputSimpleLink
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.website.rq) ? true : false}
							iTitle="Website"
							iName="website"
							iValue={this.state.baseData.websiteAddress}
							iValidator={this.props.global.getValidator().url}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTitle="Website"
							iName="website"
							iValue={this.state.baseData.websiteAddress}
						/>
					)
				}
			}
			if (this.state.baseTemplate.business.details.startdate && this.state.baseTemplate.business.details.startdate !== null) {
				if (this.state.baseTemplate.business.details.startdate.ro === false) {
					rows.push(
						<InputCalendarLink
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.startdate.rq) ? true : false}
							iTitle="Business Start Date"
							iName="startdate"
							self={this}
							iKey={0}
							iField={'bStartdate'}
							iChangeCallback={this.setBusinessDataDate}
							iValue={this.state.baseData.bStartdate !== null ? this.props.global.getDateFromValue(this.state.baseData.bStartdate) : new Date()}
							iMax={new Date()}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTitle="Business Start Date"
							iName="startdate"
							iValue={this.state.baseData.bStartdate}
						/>
					)
				}
			}
			if (this.state.baseTemplate.business.details.phonenumber && this.state.baseTemplate.business.details.phonenumber !== null) {
				if (this.state.baseTemplate.business.details.phonenumber.ro === false) {
					rows.push(
						<PhoneInput
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.phonenumber.rq) ? true : false}
							iTooltip="Direct Phone Number of the owner"
							iMask="phone"
							iTitle="Phone Number"
							iName="phonenumber"
							iValue={this.state.baseData.bPhone}
							iValidator={this.props.global.getValidator().phone}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iTooltip="Direct Phone Number of the owner"
							iMask="phone"
							iTitle="Phone Number"
							iName="phonenumber"
							iValue={this.state.baseData.bPhone}
						/>
					)
				}
			}
			if (this.state.baseTemplate.business.details.faxnumber && this.state.baseTemplate.business.details.faxnumber !== null) {
				if (this.state.baseTemplate.business.details.faxnumber.ro === false) {
					rows.push(
						<PhoneInput
							iRequired={[true, null].includes(this.state.baseTemplate.business.details.faxnumber.rq) ? true : false}
							iMask="phone"
							iTitle="Fax Number"
							iName="faxnumber"
							iValue={this.state.baseData.bFax}
							iValidator={this.props.global.getValidator().phone}
						/>
					)
				} else {
					rows.push(
						<ReadOnlySimpleLink
							iMask="phone"
							iTitle="Fax Number"
							iName="faxnumber"
							iValue={this.state.baseData.bFax}
						/>
					)
				}
			}
		}
		return <Row className="mt-4">{rows}</Row>
	}

	getBusinessAddressBlock() {
		var rows = []
		var mrows = []

		let required_fields = []
		let readonly_fields = []
		let invisibles_fields = []

		if (this.state.baseTemplate.business.address && this.state.baseTemplate.business.address !== null) {
			if (this.state.baseTemplate.business.address.baddress && this.state.baseTemplate.business.address.baddress !== null) {
				if (this.state.baseTemplate.business.address.baddress.ro === true) {
					readonly_fields.push('address')
				} else if ([true, null].includes(this.state.baseTemplate.business.address.baddress.rq) ? true : false) {
					required_fields.push('address')
				}
			} else {
				invisibles_fields.push('address')
			}
			if (this.state.baseTemplate.business.address.baddress1 && this.state.baseTemplate.business.address.baddress1 !== null) {
				if (this.state.baseTemplate.business.address.baddress1.ro === true) {
					readonly_fields.push('address2')
				} else if ([true, null].includes(this.state.baseTemplate.business.address.baddress1.rq) ? true : false) {
					required_fields.push('address2')
				}
			} else {
				invisibles_fields.push('address2')
			}
			if (this.state.baseTemplate.business.address.bcity && this.state.baseTemplate.business.address.bcity !== null) {
				if (this.state.baseTemplate.business.address.bcity.ro === true) {
					readonly_fields.push('city')
				} else if ([true, null].includes(this.state.baseTemplate.business.address.bcity.rq) ? true : false) {
					required_fields.push('city')
				}
			} else {
				invisibles_fields.push('city')
			}
			if (this.state.baseTemplate.business.address.bstate && this.state.baseTemplate.business.address.bstate !== null) {
				if (this.state.baseTemplate.business.address.bstate.ro === true) {
					readonly_fields.push('state')
				} else if ([true, null].includes(this.state.baseTemplate.business.address.bstate.rq) ? true : false) {
					required_fields.push('state')
				}
			} else {
				invisibles_fields.push('state')
			}
			if (this.state.baseTemplate.business.address.bzip && this.state.baseTemplate.business.address.bzip !== null) {
				if (this.state.baseTemplate.business.address.bzip.ro === true) {
					readonly_fields.push('zipcode')
				} else if ([true, null].includes(this.state.baseTemplate.business.address.bzip.rq) ? true : false) {
					required_fields.push('zipcode')
				}
			} else {
				invisibles_fields.push('zipcode')
			}
			if (this.state.baseTemplate.business.address.bcountry && this.state.baseTemplate.business.address.bcountry !== null) {
				if (this.state.baseTemplate.business.address.bcountry.ro === true) {
					readonly_fields.push('country')
				} else if ([true, null].includes(this.state.baseTemplate.business.address.bcountry.rq) ? true : false) {
					required_fields.push('country')
				}
			} else {
				invisibles_fields.push('country')
			}
			rows.push(
				<LocationSearchInput
					placeholder="Business Address"
					customSize="col-md-6 mb-3"
					withCountry="true"
					withoutRow="true"
					onChange={this.handleGoogleAutocompleteBusinessAddress}
					handleTextChange={this.handleTextChangeMask}
					address={this.state.baseData.bAddress1}
					address2={this.state.baseData.bAddress2}
					city={this.state.baseData.bCity}
					state={this.state.baseData.bState}
					zipcode={this.state.baseData.bZip}
					country={this.state.baseData.bCountry}
					iRequired={required_fields}
					iReadonly={readonly_fields}
					iInvisible={invisibles_fields}
					iTooltip="Where the business is located"
					handleTextChangeKeys={['baddress', 'baddress1', 'bcity', 'bstate', 'bzip', 'bcountry']}
				/>
			)

			if (
				(this.state.baseTemplate.business.address.baddress !== null || this.state.baseTemplate.business.address.baddress1 !== null || this.state.baseTemplate.business.address.bcity !== null || this.state.baseTemplate.business.address.bstate !== null || this.state.baseTemplate.business.address.bzip !== null || this.state.baseTemplate.business.address.bcountry !== null) &&
				(this.state.baseTemplate.business.address.maddress !== null || this.state.baseTemplate.business.address.maddress1 !== null || this.state.baseTemplate.business.address.mcity !== null || this.state.baseTemplate.business.address.mstate !== null || this.state.baseTemplate.business.address.mzip !== null || this.state.baseTemplate.business.address.mcountry !== null)
			) {
				mrows.push(
					<div className="col-12 text-left mb-3">
						<div className="icheck-primary">
							<input
								checked={this.state.bsameaddress}
								type="checkbox"
								name="b_sameaddress"
								id="b_sameaddress"
								onChange={this.bSameaddresshandleChange}
							/>
							<label htmlFor="b_sameaddress">Mailing Address same as Business Address</label>
						</div>
					</div>
				)
			}
			if (!this.state.bsameaddress) {
				let required_fields = []
				let readonly_fields = []
				let invisibles_fields = []
				if (this.state.baseTemplate.business.address.maddress && this.state.baseTemplate.business.address.maddress !== null) {
					if (this.state.baseTemplate.business.address.maddress.ro === true) {
						readonly_fields.push('address')
					} else if ([true, null].includes(this.state.baseTemplate.business.address.maddress.rq) ? true : false) {
						required_fields.push('address')
					}
				} else {
					invisibles_fields.push('address')
				}

				if (this.state.baseTemplate.business.address.maddress1 && this.state.baseTemplate.business.address.maddress1 !== null) {
					if (this.state.baseTemplate.business.address.maddress1.ro === true) {
						readonly_fields.push('address2')
					} else if ([true, null].includes(this.state.baseTemplate.business.address.maddress1.rq) ? true : false) {
						required_fields.push('address2')
					}
				} else {
					invisibles_fields.push('address2')
				}
				if (this.state.baseTemplate.business.address.mcity && this.state.baseTemplate.business.address.mcity !== null) {
					if (this.state.baseTemplate.business.address.mcity.ro === true) {
						readonly_fields.push('city')
					} else if ([true, null].includes(this.state.baseTemplate.business.address.mcity.rq) ? true : false) {
						required_fields.push('city')
					}
				} else {
					invisibles_fields.push('city')
				}
				if (this.state.baseTemplate.business.address.mstate && this.state.baseTemplate.business.address.mstate !== null) {
					if (this.state.baseTemplate.business.address.mstate.ro === true) {
						readonly_fields.push('state')
					} else if ([true, null].includes(this.state.baseTemplate.business.address.mstate.rq) ? true : false) {
						required_fields.push('state')
					}
				} else {
					invisibles_fields.push('state')
				}
				if (this.state.baseTemplate.business.address.mzip && this.state.baseTemplate.business.address.mzip !== null) {
					if (this.state.baseTemplate.business.address.mzip.ro === true) {
						readonly_fields.push('zipcode')
					} else if ([true, null].includes(this.state.baseTemplate.business.address.mzip.rq) ? true : false) {
						required_fields.push('zipcode')
					}
				} else {
					invisibles_fields.push('zipcode')
				}
				if (this.state.baseTemplate.business.address.mcountry && this.state.baseTemplate.business.address.mcountry !== null) {
					if (this.state.baseTemplate.business.address.mcountry.ro === true) {
						readonly_fields.push('country')
					} else if ([true, null].includes(this.state.baseTemplate.business.address.mcountry.rq) ? true : false) {
						required_fields.push('country')
					}
				} else {
					invisibles_fields.push('country')
				}
				mrows.push(
					<LocationSearchInput
						placeholder="Mailing Address"
						customSize="col-md-6 mb-3"
						withCountry="true"
						withoutRow="true"
						onChange={this.handleGoogleAutocompleteBusinessMAddress}
						handleTextChange={this.handleTextChangeMask}
						address={this.state.baseData.mAddress1}
						address2={this.state.baseData.mAddress2}
						city={this.state.baseData.mCity}
						state={this.state.baseData.mState}
						zipcode={this.state.baseData.mZip}
						country={this.state.baseData.mCountry}
						iRequired={required_fields}
						iReadonly={readonly_fields}
						iInvisible={invisibles_fields}
						handleTextChangeKeys={['maddress', 'maddress1', 'mcity', 'mstate', 'mzip', 'mcountry']}
					/>
				)
			}
		}
		return (
			<>
				<Row className="mt-2">{rows}</Row>
				<hr />
				<Row className="mt-2">{mrows}</Row>
			</>
		)
	}

	getProcessingBlock() {
		if (this.state.baseTemplate.processing === null) return
		var rows = []

		if (this.state.baseTemplate.processing.mcc && this.state.baseTemplate.processing.mcc !== null) {
			if (this.state.baseTemplate.processing.mcc.ro === false) {
				rows.push(
					<InputSelectSearchable
						iRequired={[true, null].includes(this.state.baseTemplate.processing.mcc.rq) ? true : false}
						iTitle="Industry (Merchant Category Code)"
						iName="mcc"
						iValue={this.state.baseData.mccid}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<InputSelectSearchable
						iReadonly={true}
						iTitle="Industry (Merchant Category Code)"
						iName="mcc"
						iValue={this.state.baseData.mccid}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.bsummary && this.state.baseTemplate.processing.bsummary !== null) {
			if (this.state.baseTemplate.processing.bsummary.ro === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.processing.bsummary.rq) ? true : false}
						iTooltip="Please provide some background on your Business"
						iTitle="Tell us about your Business"
						iName="bsummary"
						iValue={this.state.baseData.bSummary}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="Please provide some background on your Business"
						iTitle="Tell us about your Business"
						iName="bsummary"
						iValue={this.state.baseData.bSummary}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.binperson && this.state.baseTemplate.processing.binperson !== null) {
			if (this.state.baseTemplate.processing.binperson.ro === false) {
				rows.push(
					<InputSimpleLink
						iMask="percentage"
						iRequired={[true, null].includes(this.state.baseTemplate.processing.binperson.rq) ? true : false}
						iType="s100x10"
						iTitle="Percent of payments in Person"
						iName="binperson"
						iValue={this.state.baseData.binPerson || '0'}
						iValidator={this.props.global.getValidator().float}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Percent of payments in Person"
						iName="binperson"
						iValue={this.state.baseData.binPerson}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.binphone && this.state.baseTemplate.processing.binphone !== null) {
			if (this.state.baseTemplate.processing.binphone.ro === false) {
				rows.push(
					<InputSimpleLink
						iMask="percentage"
						iRequired={[true, null].includes(this.state.baseTemplate.processing.binphone.rq) ? true : false}
						iType="s100x10"
						iTitle="Percent of payments by Phone"
						iName="binphone"
						iValue={this.state.baseData.binPhone || '0'}
						iValidator={this.props.global.getValidator().float}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Percent of payments by Phone"
						iName="binphone"
						iValue={this.state.baseData.binPhone}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.binweb && this.state.baseTemplate.processing.binweb !== null) {
			if (this.state.baseTemplate.processing.binweb.ro === false) {
				rows.push(
					<InputSimpleLink
						iMask="percentage"
						iRequired={[true, null].includes(this.state.baseTemplate.processing.binweb.rq) ? true : false}
						iType="s100x10"
						iTitle="Percent of payments online"
						iName="binweb"
						iValue={this.state.baseData.binWeb || '0'}
						iValidator={this.props.global.getValidator().float}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Percent of payments online"
						iName="binweb"
						iValue={this.state.baseData.binWeb}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.avgmonthly && this.state.baseTemplate.processing.avgmonthly !== null) {
			if (this.state.baseTemplate.processing.avgmonthly.ro === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.processing.avgmonthly.rq) ? true : false}
						iTooltip="How much credit card volume do you anticipate"
						iMask="money"
						iTitle="Average Monthly Volume"
						iName="avgmonthly"
						iValue={this.state.baseData.averageMonthlyVolume}
						iValidator={this.props.global.getValidator().moneynonzero}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="How much credit card volume do you anticipate"
						iMask="money"
						iTitle="Average Monthly Volume"
						iName="avgmonthly"
						iValue={this.state.baseData.averageMonthlyVolume}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.ticketamt && this.state.baseTemplate.processing.ticketamt !== null) {
			if (this.state.baseTemplate.processing.ticketamt.ro === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.processing.ticketamt.rq) ? true : false}
						iTooltip="What is the average transaction size that you charge your customers"
						iMask="money"
						iTitle="Average Ticket Amount"
						iName="ticketamt"
						iValue={this.state.baseData.averageTicketAmount}
						iValidator={this.props.global.getValidator().moneynonzero}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="What is the average transaction size that you charge your customers"
						iMask="money"
						iTitle="Average Ticket Amount"
						iName="ticketamt"
						iValue={this.state.baseData.averageTicketAmount}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.highticketamt && this.state.baseTemplate.processing.highticketamt !== null) {
			if (this.state.baseTemplate.processing.highticketamt.ro === false) {
				rows.push(
					<InputSimpleLink
						iRequired={[true, null].includes(this.state.baseTemplate.processing.highticketamt.rq) ? true : false}
						iTooltip="What is the maximum transaction size you charge your customers?"
						iMask="money"
						iTitle="High Ticket Amount"
						iName="highticketamt"
						iValue={this.state.baseData.highTicketAmount}
						iValidator={this.props.global.getValidator().moneynonzero}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTooltip="What is the maximum transaction size you charge your customers?"
						iMask="money"
						iTitle="High Ticket Amount"
						iName="highticketamt"
						iValue={this.state.baseData.highTicketAmount}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.whenCharged && this.state.baseTemplate.processing.whenCharged !== null) {
			if (this.state.baseTemplate.processing.whenCharged.ro === false) {
				rows.push(
					<InputSelectLink
						iRequired={[true, null].includes(this.state.baseTemplate.processing.whenCharged.rq) ? true : false}
						iType="whencharged"
						iTitle="When is Payment Charged"
						iName="whenCharged"
						iValue={this.state.baseData.whencharged}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="When is Payment Charged"
						iName="whenCharged"
						iValue={this.state.baseData.whencharged}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.whenProvided && this.state.baseTemplate.processing.whenProvided !== null) {
			if (this.state.baseTemplate.processing.whenProvided.ro === false) {
				rows.push(
					<InputSelectLink
						iRequired={[true, null].includes(this.state.baseTemplate.processing.whenProvided.rq) ? true : false}
						iType="whenprovided"
						iTitle="Service Generally Provided In"
						iName="whenProvided"
						iValue={this.state.baseData.whenprovided}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Service Generally Provided In"
						iName="whenProvided"
						iValue={this.state.baseData.whenprovided}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.whenDelivered && this.state.baseTemplate.processing.whenDelivered !== null) {
			if (this.state.baseTemplate.processing.whenDelivered.ro === false) {
				rows.push(
					<InputSelectLink
						iRequired={[true, null].includes(this.state.baseTemplate.processing.whenDelivered.rq) ? true : false}
						iType="whendelivered"
						iTitle="Products/Services Delivered In"
						iName="whenDelivered"
						iValue={this.state.baseData.whendelivered}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Products/Services Delivered In"
						iName="whenDelivered"
						iValue={this.state.baseData.whendelivered}
					/>
				)
			}
		}
		if (this.state.baseTemplate.processing.whenRefunded && this.state.baseTemplate.processing.whenRefunded !== null) {
			if (this.state.baseTemplate.processing.whenRefunded.ro === false) {
				rows.push(
					<InputSelectLink
						iRequired={[true, null].includes(this.state.baseTemplate.processing.whenRefunded.rq) ? true : false}
						iType="whenrefunded"
						iTitle="Refund Policy"
						iName="whenRefunded"
						iValue={this.state.baseData.whenrefund}
						iValidator={this.props.global.getValidator().text}
					/>
				)
			} else {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Refund Policy"
						iName="whenRefunded"
						iValue={this.state.baseData.whenrefund}
					/>
				)
			}
		}
		return <Row className="mt-4">{rows}</Row>
	}

	getCardAcceptance() {
		if ((this.state.baseTemplate.services && this.state.baseTemplate.services.card === null) || (this.state.baseTemplate.services && this.state.baseTemplate.services.card.acceptance === null)) return
		var rows = []
		if (this.state.baseTemplate.services && this.state.baseTemplate.services.card.acceptance.visa && this.state.baseTemplate.services.card.acceptance.visa !== null) {
			rows.push(
				<ReadOnlyCheckLink
					iInvisible={true}
					iTitle="Visa"
					iName="card_visa"
					iValue={this.state.baseTemplate.services.card.acceptance.visa.value}
				/>
			)
		}
		if (this.state.baseTemplate.services && this.state.baseTemplate.services.card.acceptance.mastercard && this.state.baseTemplate.services.card.acceptance.mastercard !== null) {
			rows.push(
				<ReadOnlyCheckLink
					iInvisible={true}
					iTitle="Mastercard"
					iName="card_mastercard"
					iValue={this.state.baseTemplate.services.card.acceptance.mastercard.value}
				/>
			)
		}
		if (this.state.baseTemplate.services && this.state.baseTemplate.services.card.acceptance.discover && this.state.baseTemplate.services.card.acceptance.discover !== null) {
			rows.push(
				<ReadOnlyCheckLink
					iInvisible={true}
					iTitle="Discover"
					iName="card_discover"
					iValue={this.state.baseTemplate.services.card.acceptance.discover.value}
				/>
			)
		}
		if (this.state.baseTemplate.services && this.state.baseTemplate.services.card.acceptance.amex && this.state.baseTemplate.services.card.acceptance.amex !== null) {
			rows.push(
				<ReadOnlyCheckLink
					iInvisible={true}
					iTitle="Amex"
					iName="card_amex"
					iValue={this.state.baseTemplate.services.card.acceptance.amex.value}
				/>
			)
		}
		return (
			<Row className="mt-2 mb-2">
				<h6 className="mb-2">Card Acceptance</h6>
				<br />
				<label className="mb-2 small">Integrated per transaction fee for card acceptance.</label>
				<br />
				{rows}
			</Row>
		)
	}

	getCardPricing() {
		if (!this.state.baseTemplate?.services?.card?.acceptance || !this.state.baseTemplate?.services?.card?.price?.header) return
		const acceptance = Object.entries(this.state.baseTemplate.services.card.acceptance)
			.filter((r) => r[1] !== null)
			.map((e) => e[0].toLowerCase())
		let visibleRanges = ['low pay range', 'high pay range']
		let percentageColumns = this.state.baseTemplate.services.card.price.header.columns.map((e, index) => (e.value.startsWith('%') ? index : null)).filter((r) => r !== null)
		let notVisibleColumns = this.state.baseTemplate.services.card.price.header.columns.map((e, index) => (visibleRanges.includes(e.value) ? index : null)).filter((r) => r !== null)
		return (
			this.state.baseTemplate.services &&
			this.state.baseTemplate.services.card !== null &&
			this.state.baseTemplate.services.card.price !== null && (
				<>
					<h6 className=" mb-2">Pricing</h6>
					<Table className="table-striped">
						{this.state.baseTemplate.services.card.price.header !== null && (
							<thead>
								<tr>
									{this.state.baseTemplate.services.card.price.header.columns
										.filter((e) => !visibleRanges.includes(e.value))
										.map((colr, index) => (
											<th key={index}>{colr.value}</th>
										))}
								</tr>
							</thead>
						)}
						{this.state.baseTemplate.services.card.price.body !== null && (
							<tbody>
								{this.state.baseTemplate.services.card.price.body
									.filter((e) => acceptance.includes(e.columns[0].value.toLowerCase()))
									.map((rowr, index) => (
										<tr key={index}>
											{rowr.columns.map(
												(colr, ic) =>
													!notVisibleColumns.includes(ic) && (
														<td key={ic}>
															{(ic === 0 && (
																<div className="card-brands ">
																	<div>
																		<img
																			alt=""
																			style={colr.value.toLowerCase() === 'amex' || colr.value.toLowerCase() === 'mastercard' ? { height: '28px' } : null}
																			className={colr.value.toLowerCase() === 'masterCard' ? 'mastercard' : colr.value.toLowerCase() === 'discover' ? 'discover' : 'amex'}
																			src={colr.value.toLowerCase() === 'web' ? achIcon : colr.value.toLowerCase() === 'visa' ? visa : colr.value.toLowerCase() === 'mastercard' ? mastercard : colr.value.toLowerCase() === 'discover' ? discover : amex}
																		/>
																	</div>
																</div>
															)) ||
																(percentageColumns.includes(ic) ? parseFloat(colr.value || 0).toFixed(2) + '%' : '$' + parseFloat(colr.value || 0).toFixed(2))}
														</td>
													)
											)}
										</tr>
									))}
							</tbody>
						)}
					</Table>
				</>
			)
		)
	}

	getCardFee() {
		let visiblePriceColumns = 99
		if (this.state.baseTemplate?.services?.card?.price?.header) {
			visiblePriceColumns = this.state.baseTemplate.services.card.price.header.columns.length - 2
		}
		return (
			this.state.baseTemplate.services &&
			this.state.baseTemplate.services.card !== null &&
			this.state.baseTemplate.services.card.fees !== null && (
				<Table className="table-striped">
					{this.state.baseTemplate.services.card.fees.header !== null && (
						<thead>
							<tr>
								{this.state.baseTemplate.services.card.fees.header.columns.map((colr, index) => (
									<th key={index}>{colr.value}</th>
								))}
							</tr>
						</thead>
					)}
					{this.state.baseTemplate.services.card.fees.body !== null && (
						<tbody>
							{this.state.baseTemplate.services.card.fees.body.map((rowr, index) => (
								<tr key={index}>
									{rowr.columns.map((colr, ic) => (
										<td
											style={ic === 0 && visiblePriceColumns === 3 ? { width: '68%' } : ic === 0 && visiblePriceColumns === 5 ? { width: '77%' } : ic === 0 && visiblePriceColumns > 5 ? { width: '90%' } : null}
											key={ic}>
											{ic === 1 ? '$' + parseFloat(colr.value || 0).toFixed(2) : '' + colr.value || 0}
										</td>
									))}
								</tr>
							))}
						</tbody>
					)}
				</Table>
			)
		)
	}

	getAchAcceptance() {
		if ((this.state.baseTemplate.services && this.state.baseTemplate.services.ach === null) || (this.state.baseTemplate.services && this.state.baseTemplate.services.ach.acceptance === null)) return
		var rows = []
		if (this.state.baseTemplate.services && this.state.baseTemplate.services.ach.acceptance.web) {
			rows.push(
				<ReadOnlyCheckLink
					iInvisible={true}
					iTitle="Web"
					iName="ach_web"
					iValue={this.state.baseTemplate.services.ach.acceptance.web.value}
				/>
			)
		}
		return (
			<Row className="mt-2 mb-2">
				<h6 className="mb-2">ACH Acceptance</h6>
				<br />
				<label className="mb-2 small">Integrated per transaction fee for ACH debits and credits.</label>
				<br />
				{rows}
			</Row>
		)
	}
	getAchPricing() {
		if (!this.state.baseTemplate?.services?.ach?.acceptance || !this.state.baseTemplate?.services?.ach?.price?.header) return
		const acceptance = Object.entries(this.state.baseTemplate.services.ach.acceptance)
			.filter((r) => r[0] === 'web')
			.map((e) => e[0].toLowerCase())
		let visibleRanges = ['low pay range', 'high pay range']
		let percentageColumns = this.state.baseTemplate.services.ach.price.header.columns.map((e, index) => (e.value.startsWith('%') ? index : null)).filter((r) => r !== null)
		let notVisibleColumns = this.state.baseTemplate.services.ach.price.header.columns.map((e, index) => (visibleRanges.includes(e.value) ? index : null)).filter((r) => r !== null)

		return (
			this.state.baseTemplate.services &&
			this.state.baseTemplate.services.ach !== null &&
			this.state.baseTemplate.services.ach.price !== null && (
				<>
					<h6 className=" mb-2">Pricing</h6>
					<Table className="table-striped">
						{this.state.baseTemplate.services.ach.price.header !== null && (
							<thead>
								<tr>
									{this.state.baseTemplate.services.ach.price.header.columns
										.filter((e) => !visibleRanges.includes(e.value))
										.map((colr, index) => (
											<th key={index}>{colr.value}</th>
										))}
								</tr>
							</thead>
						)}
						{this.state.baseTemplate.services.ach.price.body !== null && (
							<tbody>
								{this.state.baseTemplate.services.ach.price.body
									.filter((e) => acceptance.includes(e.columns[0].value.toLowerCase()))
									.map((rowr, index) => (
										<tr key={index}>
											{rowr.columns.map(
												(colr, ic) =>
													!notVisibleColumns.includes(ic) && (
														<td key={ic}>
															{(ic === 0 && (
																<div className="card-brands ">
																	<div>
																		<img
																			alt=""
																			style={{ height: '28px', marginLeft: 0 }}
																			src={achIcon}
																		/>
																	</div>
																</div>
															)) ||
																(percentageColumns.includes(ic) ? parseFloat(colr.value || 0).toFixed(2) + '%' : '$' + parseFloat(colr.value || 0).toFixed(2))}
														</td>
													)
											)}
										</tr>
									))}
							</tbody>
						)}
					</Table>
				</>
			)
		)
	}

	getAchFee() {
		let visibleAchColumns = 99
		if (this.state.baseTemplate?.services?.ach?.price?.header) {
			visibleAchColumns = this.state.baseTemplate.services.ach.price.header.columns.length - 2
		}
		return (
			this.state.baseTemplate.services &&
			this.state.baseTemplate.services.ach !== null &&
			this.state.baseTemplate.services.ach.fees !== null && (
				<Table className="table-striped">
					{this.state.baseTemplate.services.ach.fees.header !== null && (
						<thead>
							<tr>
								{this.state.baseTemplate.services.ach.fees.header.columns.map((colr, index) => (
									<th key={index}>{colr.value}</th>
								))}
							</tr>
						</thead>
					)}
					{this.state.baseTemplate.services.ach.fees.body !== null && (
						<tbody>
							{this.state.baseTemplate.services.ach.fees.body.map((rowr, index) => (
								<tr key={index}>
									{rowr.columns.map((colr, ic) => (
										<td
											style={ic === 0 && visibleAchColumns === 3 ? { width: '62%' } : ic === 0 && visibleAchColumns === 5 ? { width: '77%' } : ic === 0 && visibleAchColumns > 5 ? { width: '90%' } : null}
											key={ic}>
											{ic === 1 ? '$' + parseFloat(colr.value || 0).toFixed(2) : '' + colr.value || 0}
										</td>
									))}
								</tr>
							))}
						</tbody>
					)}
				</Table>
			)
		)
	}

	getSignerBlock() {
		let signers = []
		if (this.props.boarding.template_data['ownername_0']) {
			signers.push({
				contactName: this.props.boarding.template_data['ownername_0'] ? this.props.boarding.template_data['ownername_0'].value : '',
				contactEmail: this.props.boarding.template_data['owneremail_0'] ? this.props.boarding.template_data['owneremail_0'].value : '',
				contactPhone: this.props.boarding.template_data['ownerphone1_0'] ? this.props.boarding.template_data['ownerphone1_0'].value : '',
				contactDOB: this.props.boarding.template_data['ownerdob_0'] ? this.props.boarding.template_data['ownerdob_0'].value : '',
				contactCountry: this.props.boarding.template_data['ocountry_0'] ? this.props.boarding.template_data['ocountry_0'].value : '',
				contactAddress: this.props.boarding.template_data['oaddress_0'] ? this.props.boarding.template_data['oaddress_0'].value : '',
				contactAddress2: this.props.boarding.template_data['oaddress2_0'] ? this.props.boarding.template_data['oaddress2_0'].value : '',
				contactCity: this.props.boarding.template_data['ocity_0'] ? this.props.boarding.template_data['ocity_0'].value : '',
				contactState: this.props.boarding.template_data['ostate_0'] ? this.props.boarding.template_data['ostate_0'].value : '',
				contactZip: this.props.boarding.template_data['ozip_0'] ? this.props.boarding.template_data['ozip_0'].value : '',
				contactSSN: this.props.boarding.template_data['ownerssn_0'] ? this.props.boarding.template_data['ownerssn_0'].value : ''
			})
		}

		this.state.additionalOwners.forEach((element) => {
			if (this.props.boarding.template_data['ownername_' + element]) {
				signers.push({
					contactName: this.props.boarding.template_data['ownername_' + element] ? this.props.boarding.template_data['ownername_' + element].value : '',
					contactEmail: this.props.boarding.template_data['owneremail_' + element] ? this.props.boarding.template_data['owneremail_' + element].value : '',
					contactPhone: this.props.boarding.template_data['ownerphone1_' + element] ? this.props.boarding.template_data['ownerphone1_' + element].value : '',
					contactDOB: this.props.boarding.template_data['ownerdob_' + element] ? this.props.boarding.template_data['ownerdob_' + element].value : '',
					contactCountry: this.props.boarding.template_data['ocountry_' + element] ? this.props.boarding.template_data['ocountry_' + element].value : '',
					contactAddress: this.props.boarding.template_data['oaddress_' + element] ? this.props.boarding.template_data['oaddress_' + element].value : '',
					contactAddress2: this.props.boarding.template_data['oaddress2_' + element] ? this.props.boarding.template_data['oaddress2_' + element].value : '',
					contactCity: this.props.boarding.template_data['ocity_' + element] ? this.props.boarding.template_data['ocity_' + element].value : '',
					contactState: this.props.boarding.template_data['ostate_' + element] ? this.props.boarding.template_data['ostate_' + element].value : '',
					contactZip: this.props.boarding.template_data['ozip_' + element] ? this.props.boarding.template_data['ozip_' + element].value : '',
					contactSSN: this.props.boarding.template_data['ownerssn_' + element] ? this.props.boarding.template_data['ownerssn_' + element].value : ''
				})
			}
		})

		if (this.props.boarding.template_data['contactName_0']) {
			signers.push({
				contactName: this.props.boarding.template_data['contactName_0'].value,
				contactEmail: this.props.boarding.template_data['contactEmail_0'].value,
				contactPhone: this.props.boarding.template_data['contactPhone_0'].value
			})
		}

		this.state.additionalContacts.forEach((element) => {
			if (this.props.boarding.template_data['contactName_' + element]) {
				signers.push({
					contactName: this.props.boarding.template_data['contactName_' + element].value,
					contactEmail: this.props.boarding.template_data['contactEmail_' + element].value,
					contactPhone: this.props.boarding.template_data['contactPhone_' + element].value
				})
			}
		})

		return (
			<>
				<h5 className="mt-5">Signer</h5>
				<p className="small mb-4 dark-grey">Please select which of the Owners or Contacts will be signing the application. You may also manually add a non-owner signer if desired.</p>
				<div className="row">
					<div className="col-12 mb-4">
						<div className="form-floating">
							<select
								onChange={(e) => this.selectContactAsSigner(e)}
								className="form-select form-control"
								id="selectContactAsSigner">
								<option value=""> Add Signer information manually... </option>
								{signers.map((record, i) => (
									<option
										key={i}
										value={JSON.stringify(record)}>
										{record.contactName}
									</option>
								))}
							</select>
							<label htmlFor="selectContactAsSigner">Owner or Contact as signer</label>
						</div>
					</div>
					<div className="col-sm-12">
						<InputSimpleLink
							iRequired={true}
							iTitle="Name"
							iName="signer_name"
							iValue={this.state.signer.name}
							iValidator={this.props.global.getValidator().text}
							full
						/>
					</div>
					<div className="col-sm-6">
						<InputSimpleLink
							iRequired={true}
							iHide={true}
							iMask="ssn"
							iTitle="Social Security Number"
							iName="signer_ssn"
							iValue={this.state.signer.ssn}
							iValidator={this.props.global.getValidator().ssn}
							full
						/>
					</div>

					<InputCalendarLink
						self={this}
						iKey={0}
						iField={'dob'}
						iChangeCallback={this.setSignerDataDOB}
						iRequired={true}
						iMax={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())}
						iTitle="Date of Birth"
						iName={'signer_dob'}
						iValue={this.state.signer && this.state.signer.dob ? this.props.global.getDateFromValue(this.state.signer.dob) : new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())}
						full
					/>

					<div className="col-sm-6">
						<PhoneInput
							iRequired={true}
							iMask="phone"
							iTitle="Phone"
							iName="signer_phone"
							iValue={this.state.signer.phone}
							iValidator={this.props.global.getValidator().phone}
							full
						/>
					</div>
					<div className="col-sm-6">
						<InputSimpleLink
							iRequired={true}
							iMask="email"
							iTitle="Email"
							iName="signer_email"
							iValue={this.state.signer.email}
							iValidator={this.props.global.getValidator().email}
							full
						/>
					</div>
					<LocationSearchInput
						placeholder="Signer Address"
						customSize="col-md-6 mb-3"
						withCountry="true"
						withoutRow="true"
						onChange={this.handleGoogleAutocompleteSignerAddress}
						handleTextChange={this.handleTextChangeMask}
						iRequired={['address', 'city', 'state', 'zipcode', 'country']}
						address={this.state.signer_address || this.state.signer.address}
						address2={this.state.signer_address2 || this.state.signer.address1}
						city={this.state.signer_city || this.state.signer.city}
						state={this.state.signer_state || this.state.signer.state}
						zipcode={this.state.signer_zip || this.state.signer.zip}
						country={this.state.signer_country || this.state.signer.country}
						handleTextChangeKeys={['signer_address', 'address2', 'signer_city', 'signer_state', 'signer_zip', 'signer_country']}
					/>
				</div>
			</>
		)
	}

	selectContactAsSigner(event) {
		let signer = this.state.signer
		if (event.target.value !== '') {
			let obj = JSON.parse(event.target.value)
			signer.name = obj.contactName
			signer.email = obj.contactEmail
			signer.phone = obj.contactPhone
			signer.ssn = obj.contactSSN ? obj.contactSSN : ''
			signer.dob = obj.contactDOB ? obj.contactDOB : ''
			signer.country = obj.contactCountry ? obj.contactCountry : ''
			signer.city = obj.contactCity ? obj.contactCity : ''
			signer.state = obj.contactState ? obj.contactState : ''
			signer.address = obj.contactAddress ? obj.contactAddress : ''
			signer.address1 = obj.contactAddress2 ? obj.contactAddress2 : ''
			signer.zip = obj.contactZip ? obj.contactZip : ''
		} else {
			signer.name = ''
			signer.email = ''
			signer.phone = ''
			signer.ssn = ''
			signer.dob = ''
			signer.country = ''
			signer.city = ''
			signer.state = ''
			signer.address = ''
			signer.address1 = ''
			signer.zip = ''
		}
		this.setState({
			signer: signer,
			signer_dob: signer.dob,
			signer_address: signer.address,
			signer_address2: signer.address1,
			signer_city: signer.city,
			signer_state: signer.state,
			signer_zip: signer.zip,
			signer_country: signer.country,
			signer_ssn: signer.ssn
		})
	}

	onCopyBankInfo(e) {
		this.setState({
			bankName: this.props.boarding.template_data.deposit_bankName.value,
			routingAccount: this.props.boarding.template_data.deposit_routingAccount.value,
			accountNumber: this.props.boarding.template_data.deposit_accountNumber.value,
			typeAccount: this.props.boarding.template_data.deposit_typeAccount.value
		})
	}

	closefileExceededSizel() {
		this.setState({ fileExceededSize: false })
	}

	render() {
		const infoTheme = {
			fontSize: '30px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}
		return (
			<>
				<Modal
					style={{ textAlign: 'center' }}
					show={this.state.saveLinkModalIsOpen}
					onHide={this.closeSaveLinkModal}
					aria-labelledby="contained-modal-title-vcenter"
					centered>
					<Modal.Body>
						<BiCheckCircle className="icon-modal" />
						<h5>Application Saved!</h5>
						{!this.state.inside && this.state.appemail !== null && (
							<>
								<p>
									Check <a href={'mailto:' + this.state.appemail}>{this.state.appemail}</a> for a link you may use to access and complete the application later.
								</p>
							</>
						)}
						{/* <Button className="btn mt-2" variant="outline-dark" onClick={(e) => this.closeSaveLinkModalContinue()}>
                            CONTINUE WITH APPLICATION
                        </Button> */}
						<Button
							className="btn mt-2"
							variant="outline-dark"
							onClick={(e) => this.closeSaveLinkModal()}>
							EXIT APPLICATION
						</Button>
						<p className="small text-danger">After exiting, you will be redirected to a blank application link.</p>
					</Modal.Body>
				</Modal>
				<Modal
					style={{ textAlign: 'center' }}
					show={this.state.fileExceededSize}
					onHide={this.closefileExceededSizel}
					aria-labelledby="contained-modal-title-vcenter"
					centered>
					<Modal.Body>
						<BiX className="icon-modal" />
						<h5>File limit exceeded!</h5>
						<p>It seems like you've exceeded the maximum file size allowed. Upload max is 30MB of any combination of .pdf .jpg or .png files. We apologize for any inconvenience.</p>

						<p>If you need to submit larger files, please email us.</p>
						<Button
							className="btn mt-2"
							variant="outline-dark"
							onClick={(e) => this.closefileExceededSizel()}>
							Ok
						</Button>
					</Modal.Body>
				</Modal>
				<div>
					{this.props.global.isLoading && (
						<div
							id="main-loading-layer"
							className="d-flex justify-content-center align-items-center">
							<div
								className="spinner-border"
								role="status"></div>
						</div>
					)}
					<>
						{this.state.login ? (
							<div className="mt-body3">
								<div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
									<div
										className="card-login mb-3"
										style={{ width: '35em' }}>
										<p className="grey mb-4">Sign in to get access to your application </p>
										<div className="form-floating mb-3">
											<input
												onKeyDown={(e) => this.handleKeyDown(e)}
												type="text"
												value={this.state.email}
												name="email"
												className={this.state.error ? 'form-control input-error' : 'form-control'}
												placeholder="Email"
												onChange={(e) => this.handleTextChange(e)}
											/>
											<label htmlFor="email">Email</label>
										</div>
										<div className="form-floating mb-3">
											<input
												onKeyDown={(e) => this.handleKeyDown(e)}
												type="text"
												value={this.state.referenceId}
												name="referenceId"
												className={this.state.error ? 'form-control input-error' : 'form-control'}
												placeholder="Reference Id"
												onChange={(e) => this.handleTextChange(e)}
											/>
											<label htmlFor="referenceId">Reference Id</label>
										</div>

										{this.state.error && <div className="text-danger mb-3 small">{this.state.error}</div>}

										<button
											className="full-w btn btn-lg btn-success mb-4"
											onClick={(e) => this.getApplicationFromApi()}>
											Validate
										</button>
									</div>
									<p className="small-small">
										Powered by{' '}
										<a
											href="/"
											className="small no-underline">
											Payabli
										</a>
									</p>
								</div>
							</div>
						) : (
							<div className="mt-body3">
								<div className="d-flex flex-column justify-content-center align-items-center">
									<div className="card application-form">
										<div id="main-branding">
											<Brand imageBrand={this.state.logo} />
										</div>
										<div className="card-body application-tabs">
											<div className="progress mb-2">
												<div
													className="progress-bar"
													role="progressbar"
													style={{ width: this.getProgressBarPercent() + '%', backgroundColor: this.getProgressBarColor() }}
													aria-valuenow="25"
													aria-valuemin="0"
													aria-valuemax="100">
													{this.getProgressBarPercent()}%
												</div>
											</div>
											{this.state.baseTemplate && (
												<Tabs
													activeKey={this.state.currentSection}
													onSelect={this.setTab}>
													{this.state.baseTemplate && this.state.baseTemplate.business !== null && (
														<Tab
															eventKey="business"
															title={
																<div className={this.state.validTabs.length <= 0 || this.state.validTabs[0] === false ? '' : 'green-text'}>
																	<BiDetail className="icon" />
																	<br />
																	<span>Business Details</span>
																</div>
															}>
															<h5>Business Details</h5>
															<p className="small mb-4 dark-grey">You have an awesome business, tell us a little bit about it! </p>
															{this.getBusinessBlock()}
															{this.getBusinessAddressBlock()}
														</Tab>
													)}
													{this.state.baseTemplate && this.state.baseTemplate.owners !== null && (
														<Tab
															eventKey="owners"
															title={
																<div className={this.state.validTabs.length <= 0 || this.state.validTabs[1] === false ? '' : 'green-text'}>
																	<BiUserCheck className="icon" />
																	<br />
																	<span>Ownership and Contacts</span>
																</div>
															}>
															<h5>Ownership and Contacts</h5>
															<p className="small mb-4 dark-grey">Federal Law requires us to collect some information on the owners, the business, and whoever will be signing the Merchant Agreement.</p>
															<Row className="mt-4">{this.getOwnerBlock(0)}</Row>
															{this.state.additionalOwners.length > 0 &&
																this.state.additionalOwners.map((k) => (
																	<Row
																		className="mt-2"
																		key={k}>
																		<div className="col-12 text-left">
																			<h5>Owner</h5>
																		</div>

																		{k === this.state.additionalOwners.length && (
																			<>
																				<div className="col-12 text-right">
																					<button
																						className="btn btn-danger mb-1"
																						onClick={(e) => this.removeOwner(k)}>
																						<BiTrash style={{ fontSize: '14px' }} />
																					</button>
																				</div>
																				<br />
																			</>
																		)}
																		{this.getOwnerBlock(k)}
																	</Row>
																))}
															{this.state.baseTemplate.attributes.multipleOwners !== false && (
																<div id="addOwner">
																	<button
																		className="btn full-w bordered no-margin mb-4"
																		onClick={(e) => this.addOwner()}>
																		Add additional Owner with 25% or more of Ownership
																	</button>
																</div>
															)}
															{this.getContactBlock(0)}
															{this.state.additionalContacts.length > 0 &&
																this.state.baseTemplate.owners.contact_list !== null &&
																this.state.additionalContacts.map((k) => (
																	<Row
																		className="mt-2"
																		key={k}>
																		<div className="col-12 text-right">
																			<button
																				className="btn btn-danger mb-1"
																				onClick={(e) => this.removeContact(k)}>
																				<BiTrash style={{ fontSize: '14px' }} />
																			</button>
																		</div>
																		{this.state.baseTemplate.owners.contact_list.contactName && this.state.baseTemplate.owners.contact_list.contactName.ro === false ? (
																			<InputSimpleLink
																				iRequired={[true, null].includes(this.state.baseTemplate.owners.contact_list.contactName.rq) ? true : false}
																				iTitle="Contact Name"
																				iName={'contactName_' + k}
																				iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactName : this.state.baseTemplate.owners.contact_list.contactName.value}
																				iValidator={this.props.global.getValidator().text}
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iTitle="Contact Name"
																				iName={'contactName_' + k}
																				iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactName : this.state.baseTemplate.owners.contact_list.contactName.value}
																			/>
																		)}
																		{this.state.baseTemplate.owners.contact_list.contactTitle && this.state.baseTemplate.owners.contact_list.contactTitle.ro === false ? (
																			<InputSimpleLink
																				iRequired={[true, null].includes(this.state.baseTemplate.owners.contact_list.contactTitle.rq) ? true : false}
																				iTitle="Contact Title"
																				iName={'contactTitle_' + k}
																				iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactTitle : this.state.baseTemplate.owners.contact_list.contactTitle.value}
																				iValidator={this.props.global.getValidator().text}
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iTitle="Contact Title"
																				iName={'contactTitle_' + k}
																				iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactTitle : this.state.baseTemplate.owners.contact_list.contactTitle.value}
																			/>
																		)}
																		{this.state.baseTemplate.owners.contact_list.contactEmail && this.state.baseTemplate.owners.contact_list.contactEmail.ro === false ? (
																			<InputSimpleLink
																				iRequired={[true, null].includes(this.state.baseTemplate.owners.contact_list.contactEmail.rq) ? true : false}
																				iTitle="Contact Email"
																				iName={'contactEmail_' + k}
																				iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactEmail : this.state.baseTemplate.owners.contact_list.contactEmail.value}
																				iValidator={this.props.global.getValidator().email}
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iTitle="Contact Email"
																				iName={'contactEmail_' + k}
																				iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactEmail : this.state.baseTemplate.owners.contact_list.contactEmail.value}
																			/>
																		)}
																		{this.state.baseTemplate.owners.contact_list.contactPhone && this.state.baseTemplate.owners.contact_list.contactPhone.ro === false ? (
																			<PhoneInput
																				iRequired={[true, null].includes(this.state.baseTemplate.owners.contact_list.contactPhone.rq) ? true : false}
																				iMask="phone"
																				iTitle="Contact Phone"
																				iName={'contactPhone_' + k}
																				iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactPhone : this.state.baseTemplate.owners.contact_list.contactPhone.value}
																				iValidator={this.props.global.getValidator().phone}
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iMask="phone"
																				iTitle="Contact Phone"
																				iName={'contactPhone_' + k}
																				iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactPhone : this.state.baseTemplate.owners.contact_list.contactPhone.value}
																			/>
																		)}
																	</Row>
																))}
															{this.state.baseTemplate.attributes.multipleContacts !== false && (
																<div id="addContact">
																	<button
																		className="btn full-w bordered no-margin mb-4"
																		onClick={(e) => this.addContact()}>
																		Add Additional Contact
																	</button>
																</div>
															)}
														</Tab>
													)}
													{this.state.baseTemplate && this.state.baseTemplate.processing !== null && (
														<Tab
															eventKey="processing"
															title={
																<div className={this.state.validTabs.length <= 0 || this.state.validTabs[2] === false ? '' : 'green-text'}>
																	<BiCog className="icon" />
																	<br />
																	<span>Processing Information</span>
																</div>
															}>
															<h5>Processing Information</h5>
															<p className="small mb-4 dark-grey">We are so proud to power your payment processing, share with us your needs.</p>
															{this.getProcessingBlock()}
														</Tab>
													)}

													{this.state.baseTemplate && this.state.baseTemplate.services !== null && (
														<Tab
															eventKey="services"
															title={
																<div className={this.state.validTabs[3] === false ? '' : 'green-text'}>
																	<BiDollarCircle className="icon" />
																	<br />
																	<span>Services and Pricing</span>
																</div>
															}>
															<h5>Services and Pricing</h5>
															<p className="small mb-4 dark-grey">Below you will find the rates associated with your Payment Processing Account.</p>
															{this.getCardAcceptance()}
															{this.state.baseTemplate.services && this.state.baseTemplate.services.card !== null && (
																<Row className="mt-0">
																	<CheckLink
																		iInvisible={true}
																		iTitle="Accept Card Processing Services"
																		iName="card"
																		iValue={true}
																	/>
																</Row>
															)}

															{this.getCardPricing()}
															{this.getCardFee()}
															<br />
															{this.getAchAcceptance()}
															{this.state.baseTemplate.services && this.state.baseTemplate.services.ach !== null && (
																<Row className="mt-0">
																	<CheckLink
																		iInvisible={true}
																		iTitle="Accept ACH Processing Services"
																		iName="ach"
																		iValue={true}
																	/>
																</Row>
															)}

															{this.getAchPricing()}
															{this.getAchFee()}
														</Tab>
													)}
													<Tab
														eventKey="banking"
														title={
															<div className={this.state.validTabs.length <= 0 || this.state.validTabs[4] === false ? '' : 'green-text'}>
																<RiBankLine className="icon" />
																<br />
																<span>Banking and Documents</span>
															</div>
														}>
														<h5>Banking and Documents</h5>
														<p className="small mb-4 dark-grey">We are almost done! Please provide us with some important supporting documents and where you want us to send your funds. </p>
														{this.state.baseTemplate.banking !== null && (this.state.baseTemplate.banking.depositAccount !== null || this.state.baseTemplate.banking.withdrawalAccount !== null) && (
															<Row className="mt-4">
																{this.state.baseTemplate.banking.depositAccount !== null && (
																	<div className="col-6">
																		<p className="mb-3">
																			<b>Deposit Account</b>
																		</p>
																		{this.state.baseTemplate.banking.depositAccount.bankName && this.state.baseTemplate.banking.depositAccount.bankName.ro === false ? (
																			<InputSimpleLink
																				iRequired={[true, null].includes(this.state.baseTemplate.banking.depositAccount.bankName.rq) ? true : false}
																				iLink="true"
																				iLinkInvisible="true"
																				iTooltip="Bank Account you'd like us to send funds to"
																				iTitle="Bank Name"
																				iName="deposit_bankName"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].bankName : this.state.baseTemplate.banking.depositAccount.bankName.value}
																				iValidator={this.props.global.getValidator().text}
																				full
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iTooltip="Bank Account you'd like us to send funds to"
																				iTitle="Bank Name"
																				iName="deposit_bankName"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].bankName : this.state.baseTemplate.banking.depositAccount.bankName.value}
																			/>
																		)}
																		{this.state.baseTemplate.banking.depositAccount.routingAccount && this.state.baseTemplate.banking.depositAccount.routingAccount.ro === false ? (
																			<InputSimpleLink
																				iRequired={[true, null].includes(this.state.baseTemplate.banking.depositAccount.routingAccount.rq) ? true : false}
																				iMask="routing"
																				iTitle="Routing Number"
																				iName="deposit_routingAccount"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].routingAccount : this.state.baseTemplate.banking.depositAccount.routingAccount.value}
																				iValidator={this.props.global.getValidator().routing}
																				full
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iMask="routing"
																				iTitle="Routing Number"
																				iName="deposit_routingAccount"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].routingAccount : this.state.baseTemplate.banking.depositAccount.routingAccount.value}
																			/>
																		)}
																		{this.state.baseTemplate.banking.depositAccount.accountNumber && this.state.baseTemplate.banking.depositAccount.accountNumber.ro === false ? (
																			<InputSimpleLink
																				iRequired={[true, null].includes(this.state.baseTemplate.banking.depositAccount.accountNumber.rq) ? true : false}
																				iMask="accountnumber"
																				iTitle="Account Number"
																				iName="deposit_accountNumber"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].accountNumber : this.state.baseTemplate.banking.depositAccount.accountNumber.value}
																				iValidator={this.props.global.getValidator().numbers}
																				full
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iMask="accountnumber"
																				iTitle="Account Number"
																				iName="deposit_accountNumber"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].accountNumber : this.state.baseTemplate.banking.depositAccount.accountNumber.value}
																			/>
																		)}
																		{this.state.baseTemplate.banking.depositAccount.typeAccount && this.state.baseTemplate.banking.depositAccount.typeAccount.ro === false ? (
																			<InputSelectLink
																				iRequired={[true, null].includes(this.state.baseTemplate.banking.depositAccount.typeAccount.rq) ? true : false}
																				iTitle="Account Type"
																				iName="deposit_typeAccount"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].typeAccount : this.state.baseTemplate.banking.depositAccount.typeAccount.value}
																				iValidator={this.props.global.getValidator().text}
																				iType="bank-type"
																				full
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iTitle="Account Type"
																				iName="deposit_typeAccount"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].typeAccount : this.state.baseTemplate.banking.depositAccount.typeAccount.value}
																			/>
																		)}
																	</div>
																)}

																{this.state.baseTemplate.banking.withdrawalAccount !== null && (
																	<div className="col-6">
																		<p className="mb-3">
																			<b>Withdrawal Account</b>
																		</p>
																		{this.state.baseTemplate.banking.withdrawalAccount.bankName && this.state.baseTemplate.banking.withdrawalAccount.bankName.ro === false ? (
																			<InputSimpleLink
																				iLink="true"
																				iRequired={[true, null].includes(this.state.baseTemplate.banking.withdrawalAccount.bankName.rq) ? true : false}
																				iLinkClick={(e) => this.onCopyBankInfo(e)}
																				iLinkText="Copy deposit account details"
																				iTooltip="Bank Account you'd like us to take our fees from"
																				iTitle="Bank Name"
																				iName="withdrawal_bankName"
																				iValue={this.state.bankName ? this.state.bankName : this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].bankName : this.state.baseTemplate.banking.withdrawalAccount.bankName.value}
																				iValidator={this.props.global.getValidator().text}
																				full
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iTooltip="Bank Account you'd like us to take our fees from"
																				iTitle="Bank Name"
																				iName="withdrawal_bankName"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].bankName : this.state.baseTemplate.banking.withdrawalAccount.bankName.value}
																			/>
																		)}
																		{this.state.baseTemplate.banking.withdrawalAccount.routingAccount && this.state.baseTemplate.banking.withdrawalAccount.routingAccount.ro === false ? (
																			<InputSimpleLink
																				iRequired={[true, null].includes(this.state.baseTemplate.banking.withdrawalAccount.routingAccount.rq) ? true : false}
																				iMask="routing"
																				iTitle="Routing Number"
																				iName="withdrawal_routingAccount"
																				iValue={this.state.routingAccount ? this.state.routingAccount : this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].routingAccount : this.state.baseTemplate.banking.withdrawalAccount.routingAccount.value}
																				iValidator={this.props.global.getValidator().routing}
																				full
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iMask="routing"
																				iTitle="Routing Number"
																				iName="withdrawal_routingAccount"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].routingAccount : this.state.baseTemplate.banking.withdrawalAccount.routingAccount.value}
																			/>
																		)}
																		{this.state.baseTemplate.banking.withdrawalAccount.accountNumber && this.state.baseTemplate.banking.withdrawalAccount.accountNumber.ro === false ? (
																			<InputSimpleLink
																				iRequired={[true, null].includes(this.state.baseTemplate.banking.withdrawalAccount.accountNumber.rq) ? true : false}
																				iMask="numbers"
																				iTitle="Account Number"
																				iName="withdrawal_accountNumber"
																				iValue={this.state.accountNumber ? this.state.accountNumber : this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].accountNumber : this.state.baseTemplate.banking.withdrawalAccount.accountNumber.value}
																				iValidator={this.props.global.getValidator().numbers}
																				full
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iMask="numbers"
																				iTitle="Account Number"
																				iName="withdrawal_accountNumber"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].accountNumber : this.state.baseTemplate.banking.withdrawalAccount.accountNumber.value}
																			/>
																		)}
																		{this.state.baseTemplate.banking.withdrawalAccount.typeAccount && this.state.baseTemplate.banking.withdrawalAccount.typeAccount.ro === false ? (
																			<InputSelectLink
																				iRequired={[true, null].includes(this.state.baseTemplate.banking.withdrawalAccount.typeAccount.rq) ? true : false}
																				iTitle="Account Type"
																				iName="withdrawal_typeAccount"
																				iValue={this.state.typeAccount ? this.state.typeAccount : this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].typeAccount : this.state.baseTemplate.banking.withdrawalAccount.typeAccount.value}
																				iValidator={this.props.global.getValidator().text}
																				iType="bank-type"
																				full
																			/>
																		) : (
																			<ReadOnlySimpleLink
																				iTitle="Account Type"
																				iName="withdrawal_typeAccount"
																				iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].typeAccount : this.state.baseTemplate.banking.withdrawalAccount.typeAccount.value}
																			/>
																		)}
																	</div>
																)}
															</Row>
														)}
														<br />
														{this.state.baseTemplate.attributes.minimumDocuments !== null && parseInt(this.state.baseTemplate.attributes.minimumDocuments, 10) > 0 && this.getDocumentBlock()}

														{this.getSignerBlock()}
														{this.state.baseTemplate.banking.termsConditions && this.state.baseTemplate.banking.termsConditions.length && (
															<>
																<h6 className="mt-2 text-center">Confirmation & Attestation</h6>
																<p className="small mb-4 dark-grey text-center">Please Review, and Print, or Save the documents provided in links below.</p>
															</>
														)}
														{this.state.baseTemplate.banking.termsConditions && this.state.baseTemplate.banking.termsConditions.length && (
															<>
																<Card className="card card-in mb-3 ">
																	<Card.Header>
																		<div className="row">
																			<div
																				className="col-4 col-sm-2 col-md-1"
																				style={infoTheme}>
																				{(this.state.signer.acceptance === true && <BsFillExclamationCircleFill color="limegreen" />) || <BsFillExclamationCircleFill color="red" />}
																			</div>
																			<div className="col-8 col-sm-10 col-md-11">
																				<p>
																					By clicking below I agree and hereby confirm to have Read, Agreed and Consented to the
																					{this.state.baseTemplate.banking.termsConditions.map((k, i) => (
																						<>
																							<span className="text-info"> {k['label']}</span>{' '}
																							<a
																								style={{ textDecoration: 'none' }}
																								rel="noreferrer"
																								term={i}
																								onClick={(e) => this.handleAcceptClick(e)}
																								target="_blank"
																								href={k['value']}>
																								(CLICK HERE)
																							</a>
																							{i === this.state.baseTemplate.banking.termsConditions.length - 2 ? ' and ' : ', '}
																						</>
																					))}
																					<span> provided. Please Review and Save these documents provided in links above.</span>
																				</p>
																			</div>
																		</div>
																	</Card.Header>
																	<Card.Body>
																		<Row>
																			<div
																				className="col-4 col-sm-2 col-md-1"
																				style={infoTheme}>
																				<input
																					style={{ backgroundColor: '#10A0E3', borderColor: '#10A0E3', minHeight: '22px', marginTop: '6px!important', marginBottom: '6px!important', paddingLeft: 0, width: '22px', height: '22px' }}
																					checked={this.state.signer.acceptance}
																					type="checkbox"
																					name="acceptance"
																					id="acceptance"
																					onClick={this.handleAcceptCheckClick}
																				/>
																			</div>
																			<div className="col-8 col-sm-10 col-md-11">
																				<label
																					style={{ fontSize: '12px' }}
																					htmlFor="acceptance">
																					I Agree to the above information & I attest that the information provided in this application is correct to the best of my knowledge as an authorized signer for this business.
																				</label>
																			</div>
																		</Row>
																	</Card.Body>
																</Card>
															</>
														)}
													</Tab>
												</Tabs>
											)}
										</div>
										<div className="card-footer">
											<Row>
												<div className="col-4 text-left">
													{this.state.currentPos > 0 && (
														<button
															className="btn"
															onClick={(e) => this.gotoPrevTab(this.state.currentPos - 1)}>
															<BiChevronLeft /> Previous
														</button>
													)}
												</div>
												<div className="col-4 text-center">
													<Button
														variant="btn btn-light"
														onClick={(e) => this.saveToComplete()}>
														Save to complete later
													</Button>
												</div>
												<div className="col-4 text-right">
													{this.state.currentPos < 4 ? (
														<Button
															variant="btn"
															onClick={(e) => this.gotoNextTab(this.state.currentPos + 1)}>
															Next <BiChevronRight />
														</Button>
													) : (
														<Button
															variant="btn"
															onClick={(e) => this.gotoNextTabAndSubmit(this.state.currentPos + 1)}>
															Submit <BiChevronRight />
														</Button>
													)}
												</div>
											</Row>
										</div>
										<ToastContainer transition={Bounce} />
									</div>
								</div>
							</div>
						)}
					</>
				</div>
			</>
		)
	}
}

export { EditApplicationLink }
