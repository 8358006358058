import './assets/css/bootstrap.min.css'
import './assets/css/icheck-bootstrap.min.css'
import './assets/css/custom.css'
import React, { Component } from 'react'
import { TransactionsReport } from './views/Reports/TransactionsReport'
import { BatchesReport } from './views/Reports/BatchesReport'
import { AutopayReport } from './views/Reports/AutopayReport'
import { CustomerReport } from './views/Reports/CustomerReport'
import { SettlementReport } from './views/Reports/SettlementReport'
import { BatchesTransactionsReport } from './views/Reports/BatchesTransactionsReport'
import { ChargebacksReport } from './views/Reports/ChargebacksReport'
import { ViewChargeback } from './views/Chargeback/ViewChargeback'
import { UserReport } from './views/Reports/UserReport'
import { OrgUserReport } from './views/Reports/OrgUserReport'
import { OrgCustomersReport } from './views/Reports/OrgCustomersReport'
import { OrganizationsReport } from './views/Reports/OrganizationsReport'
import { PaypointsReport } from './views/Reports/PaypointsReport'
import { PaypointOverview } from './views/Portfolio/PaypointOverview'
import { ResidualsReport } from './views/Reports/ResidualsReport'
import { SummaryResidualsReport } from './views/Reports/SummaryResidualsReport'
import { Applications } from './views/Boarding/Applications/Applications'
import { Templates } from './views/Boarding/Templates'
import { Template } from './views/Boarding/Template/Template'
import { ApplicationLink } from './views/Boarding/ApplicationLink'
import { EditApplication } from './views/Boarding/EditApplication'
import { EditApplicationLink } from './views/Boarding/EditApplicationLink'
import { ViewApplication } from './views/Boarding/ViewApplication'
import { CommandCenter } from './views/commandCenter/CommandCenter'
//import { EditPaypoint } from './views/Management/EditPaypoint'
import { NewAppFromTpl } from './views/Boarding/NewAppFromTpl'
import { Links } from './views/Boarding/Links'
import { ViewVirtualTerminal } from './views/VirtualTerminal/ViewVirtualTerminal'
import { Dashboard } from './views/Common/Dashboard'
import { Profile } from './views/User/Profile'
import { Developers } from './views/Common/Developers'
import { ApiKeys } from './views/Developers/ApiKeys'
import { ComponentCreator } from './views/Developers/ComponentCreator'
import { Reports } from './views/Developers/Reports'
import { SftpAccounts } from './views/Developers/SftpAccounts'
import { CustomFields } from './views/Common/CustomFields'
import { MyOrgDetails } from './views/Organization/MyOrgDetails'
import { OrgDetails } from './views/Organization/OrgDetails'
import { Login } from './views/Auth/Login'
import { ForgotPassword } from './views/Auth/ForgotPassword'
import { ResetPassword } from './views/Auth/ResetPassword'
import { Root } from './views/Common/Root'
import { PageNotFound } from './views/Common/PageNotFound'
import { Notifications } from './views/Common/Notifications'
import { Logout } from './views/Auth/Logout'
import { CatalogReport } from './views/Reports/CatalogReport'
import { ViewCustomer } from './views/Customer/ViewCustomer'
import { PayMethods } from './views/Customer/PayMethods'
import { Provider } from 'mobx-react'
import PaymentPageStore from './store/PaymentPageStore'
import ReportsStore from './store/ReportsStore'
import GlobalStore from './store/GlobalStore'
import CustomerStore from './store/CustomerStore'
import VirtualTerminalStore from './store/VirtualTerminalStore'
import UserStore from './store/UserStore'
import BoardingStore from './store/BoardingStore'
import ChargebackStore from './store/ChargebackStore'
import InvoiceStore from './store/InvoiceStore'
import EntryStore from './store/EntryStore'
import DeviceStore from './store/DeviceStore'
import ManagementStore from './store/ManagementStore'
import { PayabliStorageManager } from './api/localStorageManager'
import { TransactionsReportMoneyOut } from './views/Reports/TransactionsReportMoneyOut'
import VendorStore from './store/VendorStore'
import { VendorsReport } from './views/Reports/VendorsReport'
import BillStore from './store/BillStore'
import DevelopersStore from './store/DevelopersStore'
import FilterStore from './store/FilterStore'
import CustomTableStore from './store/CustomTableStore'
import { ViewVendor } from './views/Vendor/ViewVendor'
import BillBuilder from './views/Bills/builder/BillBuilder'
import { BillsReport } from './views/Reports/BillsReport'
import { ApprovalsReport } from './views/Reports/ApprovalsReport'
import { InvoiceReport } from './views/Invoice/report/InvoiceReport'
import InvoiceBuilder from './views/Invoice/builder/InvoiceBuilder'
import { BatchesReportMoneyOut } from './views/Reports/BatchesReportMoneyOut'
import { NewUser } from './views/User/NewUser'
import { Sso } from './views/Auth/Sso'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { OrgTransactionsReport } from './views/Reports/OrgTransactionsReport'
import { OrgAutopayReport } from './views/Reports/OrgAutopayReport'
import { OrgResidualsReport } from './views/Reports/OrgResidualsReport'
import { ApplicationReport } from './views/Reports/ApplicationReport'
import { AccessDenied } from './views/Common/AccessDenied'
/* import { FraudControls } from './views/RiskControl/FraudControls'
import { RiskMonitoring } from './views/RiskControl/RiskMonitoring'
import { NotificationCenter } from './views/RiskControl/NotificationCenter' */
import { ReportsRiskControl } from './views/RiskControl/ReportsRiskControl'
import './api/sessionExpiredInterceptor'
import HelloSignTemplate from './views/Developers/HelloSignTemplate/ListView/HelloSignTemplate'
import TiersDetails from './views/Boarding/Template/TiersDetails'
import StatisticStore from './store/StatisticStore'
import { MarketingPreferencesPage } from './views/MarketingPreferences/MarketingPreferencesPage'

class App extends Component {
	render() {
		if (window.location.href.endsWith('/login') || window.location.pathname === '/') {
			//PayabliStorageManager.clearStorage(PayabliStorageManager.getEntryName())
		} else if (window.location.href.endsWith('/sso')) {
			PayabliStorageManager.clearStorage(
				PayabliStorageManager.getEntryName()
			)
		} else {
			let encryptStorage =
				PayabliStorageManager.getEncryptedLocalStorage()
			if (encryptStorage && !encryptStorage.getItem('pUser')) {
				if (
					!window.location.href.includes('/sso') &&
					!window.location.href.includes('/boarding/externalapp/') &&
					!window.location.href.includes('/boarding/app/') &&
					!window.location.href.endsWith('/login') &&
					!window.location.href.endsWith('/logout') &&
					!window.location.href.includes('/resetpassword/') &&
					encryptStorage.getItem(
						`${PayabliStorageManager.getEntryName()}_pEntry`
					)
				) {
					window.location.replace(
						'/' +
						encryptStorage.getItem(
							`${PayabliStorageManager.getEntryName()}_pEntry`
						).pEntry +
						'/login'
					)
				}
			}
		}

		return (
			<Provider
				store={PaymentPageStore}
				reports={ReportsStore}
				global={GlobalStore}
				customer={CustomerStore}
				vTerminal={VirtualTerminalStore}
				user={UserStore}
				entry={EntryStore}
				boarding={BoardingStore}
				chargeback={ChargebackStore}
				management={ManagementStore}
				device={DeviceStore}
				invoice={InvoiceStore}
				vendor={VendorStore}
				bill={BillStore}
				filter={FilterStore}
				ctable={CustomTableStore}
				developers={DevelopersStore}
				statistic={StatisticStore}>
				<Router>
					<Switch>
						<Route
							exact
							path="/:entryUrl/users/new/:idOrg?"
							component={NewUser}
						/>
						<Route
							exact
							path="/:entryUrl/bills/new"
							component={BillBuilder}
						/>
						<Route
							exact
							path="/:entryUrl/bills/new/:vendorNumber/:entry"
							component={BillBuilder}
						/>
						<Route
							exact
							path="/:entryUrl/bills/edit/:idBill"
							component={BillBuilder}
						/>
						<Route
							exact
							path="/:entryUrl/bills/view/:idBill"
							component={BillBuilder}
						/>
						<Route
							exact
							path="/:entryUrl/report/bills/:id?"
							component={BillsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/bills/bill/:billNumber?"
							component={BillsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/bills/vendor/:vendorNumber?"
							component={BillsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/vendors"
							component={VendorsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/approvals"
							component={ApprovalsReport}
						/>
						<Route
							exact
							path="/:entryUrl/chargeback/:id/:command?"
							component={ViewChargeback}
						/>
						<Route
							exact
							path="/:entryUrl/report/transactions/out/:id?"
							component={TransactionsReportMoneyOut}
						/>
						<Route
							exact
							path="/:entryUrl/report/transaction/out/:billNumber"
							component={TransactionsReportMoneyOut}
						/>
						<Route
							exact
							path="/:entryUrl/report/batches/out"
							component={BatchesReportMoneyOut}
						/>
						<Route
							exact
							path="/:entryUrl/report/batches/transactions/out/:idBatch"
							component={TransactionsReportMoneyOut}
						/>
						<Route
							exact
							path="/:entryUrl/report/transactions/org/:idOrg"
							component={OrgTransactionsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/transactions"
							component={TransactionsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/transactions/idtransrefund/:idtransRefund"
							component={TransactionsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/transactions/:id/:gobackData?"
							component={TransactionsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/transactions/id/:paymentTransId"
							component={TransactionsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/transactions/paypoint/:entry/:idPaypoint"
							component={TransactionsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/batches"
							component={BatchesReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/autopays/paypoint/:entry/:idPaypoint"
							component={AutopayReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/autopays/org/:idOrg"
							component={OrgAutopayReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/autopays"
							component={AutopayReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/autopays/:id?/:command?"
							component={AutopayReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/settlements/:batchNumber?"
							component={SettlementReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/batches/:idBatch"
							component={BatchesTransactionsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/chargebacks/:paymentTransId?"
							component={ChargebacksReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/users"
							component={UserReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/usersorg/:idOrg"
							component={OrgUserReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/organizations/:idOrg?"
							component={OrganizationsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/paypoints"
							component={PaypointsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/paypoints/:idOrg"
							component={PaypointsReport}
						/>
						<Route
							exact
							path="/:entryUrl/paypoints/overview/:entry/:id"
							component={PaypointOverview}
						/>
						<Route
							exact
							path="/:entryUrl/report/residuals"
							component={ResidualsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/summaryresiduals"
							component={SummaryResidualsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/residuals/paypoint/:entry/:idPaypoint"
							component={ResidualsReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/residuals/org/:idOrg?"
							component={OrgResidualsReport}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/applications"
							component={Applications}
						/>
						<Route
							exact
							path="/:entryUrl/report/application/paypoint/:entry/:idPaypoint"
							component={ApplicationReport}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/applicationedit/:id"
							component={EditApplication}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/viewapplication/:id"
							component={ViewApplication}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/templates"
							component={Templates}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/links"
							component={Links}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/newtemplate"
							component={Template}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/edittemplate/:id"
							component={Template}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/newapptpl/:id/:idOrg?"
							component={NewAppFromTpl}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/app/:id"
							nav={false}
							component={ApplicationLink}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/externalapp/load/:id"
							nav={false}
							component={EditApplicationLink}
						/>
						<Route
							exact
							path="/:entryUrl/boarding/externalapp/new/:id"
							nav={false}
							component={ApplicationLink}
						/>
						<Route
							exact
							path="/:entryUrl/management/paypoints/:oid?/:id?"
							component={CommandCenter}
						/>
						{/* <Route exact path="/:entryUrl/management/paypoints/:oid?/:id?" component={EditPaypoint} /> */}
						<Route
							exact
							path="/:entryUrl/report/catalog"
							component={CatalogReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/customers/:command?"
							component={CustomerReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/customers/org/:idOrg"
							component={OrgCustomersReport}
						/>
						<Route
							exact
							path="/:entryUrl/customer/paymethods/:id/:command?"
							component={PayMethods}
						/>
						<Route
							exact
							path="/:entryUrl/customer/:id"
							component={ViewCustomer}
						/>
						<Route
							exact
							path="/:entryUrl/vendor/:id"
							component={ViewVendor}
						/>
						<Route
							exact
							path="/:entryUrl/report/invoices"
							component={InvoiceReport}
						/>
						<Route
							exact
							path="/:entryUrl/report/tiers"
							component={TiersDetails}
						/>
						<Route
							exact
							path="/:entryUrl/invoices/:id?"
							component={InvoiceBuilder}
						/>
						<Route
							exact
							path="/:entryUrl/vterminal/:entry/:idPaypoint"
							component={ViewVirtualTerminal}
						/>
						<Route
							exact
							path="/:entryUrl/login"
							component={Login}
						/>
						<Route
							exact
							path="/:entryUrl/sso"
							component={Sso}
						/>
						<Route
							exact
							path="/:entryUrl/forgotpassword"
							component={ForgotPassword}
						/>
						<Route
							exact
							path="/:entryUrl/resetpassword/:token"
							component={ResetPassword}
						/>
						<Route
							exact
							path="/:entryUrl/Logout"
							component={Logout}
						/>
						<Route
							exact
							path="/:entryUrl/dashboard"
							component={Dashboard}
						/>
						<Route
							exact
							path="/:entryUrl/profile/:id/:entryName?"
							component={Profile}
						/>
						<Route
							exact
							path="/:entryUrl/developers"
							component={Developers}
						/>
						<Route
							exact
							path="/:entryUrl/developers/apitokens"
							component={ApiKeys}
						/>
						<Route
							exact
							path="/:entryUrl/developers/creator"
							component={ComponentCreator}
						/>
						<Route
							exact
							path="/:entryUrl/developers/hellosigntemplate"
							component={HelloSignTemplate}
						/>
						<Route
							exact
							path="/:entryUrl/developers/reports"
							component={Reports}
						/>
						<Route
							exact
							path="/:entryUrl/developers/sftpaccounts"
							component={SftpAccounts}
						/>
						{/* <Route exact path="/:entryUrl/riskcontrol/fraudcontrols" component={FraudControls} /> 
						<Route exact path="/:entryUrl/riskcontrol/riskmonitoring" component={RiskMonitoring} /> 
						<Route exact path="/:entryUrl/riskcontrol/notificationcenter" component={NotificationCenter} />  */}
						<Route
							exact
							path="/:entryUrl/riskcontrol/reports"
							component={ReportsRiskControl}
						/>
						<Route
							exact
							path="/:entryUrl/fields"
							component={CustomFields}
						/>
						<Route
							exact
							path="/:entryUrl/notifications"
							component={Notifications}
						/>
						<Route
							exact
							path="/:entryUrl/orgdetails"
							component={MyOrgDetails}
						/>
						<Route
							exact
							path="/:entryUrl/orgdetails/:id"
							component={OrgDetails}
						/>
						<Route
							exact
							path="/"
							component={Root}
						/>
						<Route
							exact
							path="/login"
							component={Root}
						/>
						<Route
							exact
							path="/:entryUrl/404"
							component={PageNotFound}
						/>
						<Route
							exact
							path="/:entryUrl/accessdenied"
							component={AccessDenied}
						/>
						<Route
							exact
							path="/marketing-preferences/subscribe"
							component={MarketingPreferencesPage}
						/>
						<Route
							exact
							path="/*"
							component={PageNotFound}
						/>
					</Switch>
				</Router>
			</Provider>
		)
	}
}

export default App
