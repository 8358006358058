import React, { useContext, useRef, useState, useEffect } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import RowContainer from '../RowContainer'
import { RiAddFill, RiCloseLine } from 'react-icons/ri'
import { AiOutlineBank } from 'react-icons/ai'
import { BsTrash } from 'react-icons/bs'
import { OverlayTrigger, Tooltip, Alert, Button } from 'react-bootstrap'
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle'
import commandContext from '../../../views/commandCenter/context/commandCenter_context'

export default function BankAccount({ service, indexGateways, serviceName, inputDisabled, indexService, handleChange, handleAdd, handleRemove, setClassValidate, classValidate, showAlertFees, setShowAlertFees, addBankAccount, children }) {
	const { Banking } = useContext(commandContext)
	const [updateBank, setUpdateBank] = useState({ from: '', state: false })
	const refDeposit = useRef([]),
		//refChargeBacks = useRef([]),
		refWithdrawalsFees = useRef([])

	useEffect(() => {
		if (updateBank.state) handleClassValidate(updateBank.from)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateBank])
	const unCheckRefBank = (index) => {
		refDeposit.current[index].checked = false
		//refChargeBacks.current[index].checked = false
		refWithdrawalsFees.current[index].checked = false
	}
	const findClassValidate = (id) => {
		let type = ''
		classValidate.BankData.forEach((data) => {
			if (data.id === id) {
				type = data.type
				return
			}
		})
		return type
	}
	const findCheckClassValidate = (id, element) => {
		if (element === 'deposits') {
			const purpose = service.Gateways[indexGateways].BankData.find((data) => data.id === id && (data.bankAccountFunction === 0 || data.bankAccountFunction === 2))
			const purposeDuplicate = service.Gateways[indexGateways].BankData.find((data) => data.id !== id && (data.bankAccountFunction === 0 || data.bankAccountFunction === 2))
			if (purpose && purposeDuplicate) return true
		}
		if (element === 'withdrawalsFees') {
			const purpose = service.Gateways[indexGateways].BankData.find((data) => data.id === id && (data.bankAccountFunction === 1 || data.bankAccountFunction === 2))
			const purposeDuplicate = service.Gateways[indexGateways].BankData.find((data) => data.id !== id && (data.bankAccountFunction === 1 || data.bankAccountFunction === 2))
			if (purpose && purposeDuplicate) return true
		}
		return false
	}
	const findPurposeClassValidate = (id) => {
		let type = ''
		classValidate.purpose.forEach((data) => {
			if (data.id === id) {
				type = data.type
				return
			}
		})
		return type
	}
	const findBanking = (id, type) => {
		const bank = Banking.bankData.filter((data) => data.id === id)
		if (bank.length === 0) return
		if (type === 'deposits') return id !== 0 ? (bank[0].bankAccountFunction === 0 || bank[0].bankAccountFunction === 2 ? false : true) : true
		if (type === 'withdrawalsFees') return id !== 0 ? (bank[0].bankAccountFunction === 1 || bank[0].bankAccountFunction === 2 ? false : true) : true
	}
	const handleClassValidate = (type) => {
		const validateBankData = [],
			validateCheck = [],
			validatePurpose = []

		if (service.Gateways[indexGateways].BankData.length > 1) {
			const deposits = { state: false, id: '' },
				//chargeBacks = { state: false, id: '' },
				withdrawalsFees = { state: false, id: '' }
			for (let i = 0; i < service.Gateways[indexGateways].BankData.length; i++) {
				let count = 0
				if (type !== 'check') {
					for (let k = 0; k < service.Gateways[indexGateways].BankData.length; k++) {
						if (service.Gateways[indexGateways].BankData[i].id !== 0 && service.Gateways[indexGateways].BankData[k].id !== 0 && service.Gateways[indexGateways].BankData[i].id === service.Gateways[indexGateways].BankData[k].id) {
							count += 1
							if (count > 1) {
								validateBankData.push({ id: service.Gateways[indexGateways].BankData[k].id, type: 'duplicate' })
							}
						}
					}
				}

				if ((service.Gateways[indexGateways].BankData[i].bankAccountFunction === 0 && !deposits.state && !deposits.id) || (service.Gateways[indexGateways].BankData[i].bankAccountFunction === 2 && !deposits.state && !deposits.id)) {
					deposits.state = true
					deposits.id = service.Gateways[indexGateways].BankData[i].id
				} else if ((service.Gateways[indexGateways].BankData[i].bankAccountFunction === 0 && deposits.state) || (service.Gateways[indexGateways].BankData[i].bankAccountFunction === 2 && deposits.state)) {
					validateCheck.push({ id: i, type: 'deposits' })
				}
				/* if (service.Gateways[indexGateways].BankData[i].chargeBacks && !chargeBacks.state && !chargeBacks.id) {
						chargeBacks.state = true
						chargeBacks.id = service.Gateways[indexGateways].BankData[i].id
					} else if (service.Gateways[indexGateways].BankData[i].chargeBacks && chargeBacks.state) {
						validateCheck.push({ id: index, type: 'chargeBacks' })
					} */
				if ((service.Gateways[indexGateways].BankData[i].bankAccountFunction === 1 && !withdrawalsFees.state && !withdrawalsFees.id) || (service.Gateways[indexGateways].BankData[i].bankAccountFunction === 2 && !withdrawalsFees.state && !withdrawalsFees.id)) {
					withdrawalsFees.state = true
					withdrawalsFees.id = service.Gateways[indexGateways].BankData[i].id
				} else if ((service.Gateways[indexGateways].BankData[i].bankAccountFunction === 1 && withdrawalsFees.state) || (service.Gateways[indexGateways].BankData[i].bankAccountFunction === 2 && withdrawalsFees.state)) {
					validateCheck.push({ id: i, type: 'withdrawalsFees' })
				}
			}
		}

		for (let i = 0; i < service.Gateways[indexGateways].BankData.length; i++) {
			if (!(service.Gateways[indexGateways].BankData[i].bankAccountFunction === 0 || service.Gateways[indexGateways].BankData[i].bankAccountFunction === 2 ? true : false) && !(service.Gateways[indexGateways].BankData[i].bankAccountFunction === 1 || service.Gateways[indexGateways].BankData[i].bankAccountFunction === 2 ? true : false)) {
				validatePurpose.push({ id: i, type: 'is-invalid' })
			}

			if (type === 'delete') {
				refDeposit.current[i].checked = service.Gateways[indexGateways].BankData[i].bankAccountFunction === 0 || service.Gateways[indexGateways].BankData[i].bankAccountFunction === 2 ? true : false
				//refChargeBacks.current[index].checked = false
				refWithdrawalsFees.current[i].checked = service.Gateways[indexGateways].BankData[i].bankAccountFunction === 1 || service.Gateways[indexGateways].BankData[i].bankAccountFunction === 2 ? true : false
			}

			if (type !== 'check') {
				if (service.Gateways[indexGateways].BankData[i].id === 0) {
					validateBankData.push({ id: service.Gateways[indexGateways].BankData[i].id, type: 'empty' })
				}
			}
		}

		if (type !== 'check' && service.Gateways[indexGateways].BankData.length > 1) {
			setClassValidate({ ...classValidate, BankData: validateBankData, check: validateCheck, purpose: validatePurpose })
		} else if (type === 'check' && service.Gateways[indexGateways].BankData.length > 1) {
			setClassValidate({ ...classValidate, check: validateCheck, purpose: validatePurpose })
		} else if (type !== 'check' && service.Gateways[indexGateways].BankData.length === 0) {
			setClassValidate({ ...classValidate, BankData: validateBankData, purpose: validatePurpose })
		} else {
			setClassValidate({ ...classValidate, purpose: validatePurpose })
		}
		setUpdateBank({ from: '', state: false })
	}
	const createElement = (id) => {
		const elements = []
		Banking.bankData.map((data) => elements.push({ value: data.id, element: <div style={{ paddingBottom: '2px' }}>{data.bankName + ' - ' + data.accountNumber}</div>, text: data.bankName + ' - ' + data.accountNumber, select: data.id === id ? true : false }))
		return elements
	}
	const createElementBtn = () => {
		const Buttons = []
		Buttons.push({
			style: { width: '100%', borderBottom: '1px solid #b1b8be' },
			button: (
				<button
					onClick={() => addBankAccount(Banking.bankData.length + 1)}
					className={styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-blue']}
					style={{ padding: '8px 0.75rem', width: '100%' }}>
					<AiOutlineBank className={styles['icon20']} />
					New Bank Account
				</button>
			)
		})
		return Buttons
	}

	return (
		<div style={{ borderTop: '1px solid #D9D9D9', width: '100%', minHeight: '100px', padding: '20px 0 12px 15px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
			<label
				className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
				style={{ fontWeight: '600', lineHeight: '24px' }}>
				Processor configuration
			</label>
			{children}

			{inputDisabled && (
				<label
					className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' mb-2 ' + styles['color-blue']}
					style={{ fontWeight: '600', lineHeight: '24px' }}>
					BANKING
				</label>
			)}

			{!inputDisabled && service.Gateways[indexGateways].BankData === null && (
				<div className="row w-100">
					<div className="col-6 align-self-center">
						<label
							className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
							style={{ fontWeight: '600', lineHeight: '24px' }}>
							BANKING
						</label>
					</div>
					<div className="col-6 p-0">
						<div className="d-flex flex-row flex-wrap justify-content-end align-items-start p-0 gap-2 w-100">
							<button
								onClick={() => handleAdd(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'BankData')}
								className={styles['btn'] + ' ' + styles['color-blue'] + ' ' + styles['border-blue']}>
								<RiAddFill className={styles['icon20']} />
								ADD BANK ACCOUNT
							</button>
						</div>
					</div>
				</div>
			)}

			{service.Gateways[indexGateways].BankData &&
				service.Gateways[indexGateways].BankData.map((bankAccount, indexK) => (
					<div
						key={'bankAccount' + service.Gateways[indexGateways].Id + '-' + indexK}
						style={{ width: '100%' }}>
						<div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-0 gap-3 mb-2">
							{!inputDisabled && (
								<div className="row w-100">
									{indexK === 0 && (
										<div className="col-6 align-self-center">
											<label
												className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
												style={{ fontWeight: '600', lineHeight: '24px' }}>
												BANKING
											</label>
										</div>
									)}
									<div className={`col-6 p-0 ${indexK > 0 && 'w-100'}`}>
										<div className="d-flex flex-row flex-wrap justify-content-end align-items-start p-0 gap-2 w-100">
											{service.Gateways[indexGateways].BankData.length > 1 && (
												<button
													onClick={() => handleRemove(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'BankData', indexK) | setUpdateBank({ from: 'delete', state: true })}
													className={styles['btn'] + ' ' + styles['btn-red-letter'] + ' ' + styles['no-border']}>
													<BsTrash className={styles['icon20']} />
													DELETE
												</button>
											)}
											{indexK === service.Gateways[indexGateways].BankData.length - 1 && service.Gateways[indexGateways].BankData.length <= (Banking.bankData.length - 1 > 2 ? 2 : Banking.bankData.length - 1) && (
												<button
													onClick={() => handleAdd(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'BankData')}
													className={styles['btn'] + ' ' + styles['color-blue'] + ' ' + styles['border-blue']}>
													<RiAddFill className={styles['icon20']} />
													ADD BANK ACCOUNT
												</button>
											)}
										</div>
									</div>
								</div>
							)}

							<div className="d-flex flex-column w-100">
								<div className={styles['grid-container']}>
									<div className="d-flex flex-column align-items-start p-0">
										<RowContainer
											inputDisabled={inputDisabled}
											classNameInput={!inputDisabled && findClassValidate(bankAccount.id) ? 'is-invalid' : ''}
											TooltipValid={!inputDisabled && findClassValidate(bankAccount.id) === 'duplicate' ? 'Duplicate bank account' : ''}
											labelValue={'Bank Account #' + (indexK + 1).toString()}
											name={'bank[' + bankAccount.id + '][' + serviceName + ']'}
											value={bankAccount.id}
											setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'id', parseInt(values), false, false, 0, true, 'BankData', indexK, false, false, !inputDisabled) | setUpdateBank({ from: 'select', state: true }) | !inputDisabled && unCheckRefBank(indexK)}
											useElement={true}
											listElement={createElement(bankAccount.id)}
											listBtnElement={createElementBtn()}
											placeholder="Select a bank"
											maxHeightScroll="235px"
										/>
									</div>
									<div className="d-flex flex-column align-items-start p-0">
										<label
											className={styles['label-form'] + ' ' + styles['ft-s-13']}
											style={{ fontWeight: 600, lineHeight: '20px', letterSpacing: '0.0025em', width: '424px', minWidth: '20px' }}>
											Purpose
										</label>

										<div className="d-flex flex-row align-items-center justify-content-center gap-1 p-0">
											<div style={{ border: findPurposeClassValidate(indexK) === 'is-invalid' && '1px solid #dc3545', padding: findPurposeClassValidate(indexK) === 'is-invalid' && '5px 10px', borderRadius: '.25rem' }}>
												<div
													className={'d-flex flex-wrap flex-row align-items-center w-100 ' + styles['columns-sm']}
													style={{ padding: '0', gap: '25px' }}>
													<RowContainer
														inputType="Checkbox"
														refs={(e) => (e && !refDeposit.current.includes(e) ? (refDeposit.current = [...refDeposit.current, e]) : '')}
														value={bankAccount.bankAccountFunction === 0 || bankAccount.bankAccountFunction === 2 ? true : false}
														TooltipCaption={findCheckClassValidate(bankAccount.id, 'deposits') ? 'You cannot have more than one bank account for deposits' : ''}
														errorValid={findCheckClassValidate(bankAccount.id, 'deposits') ? true : false}
														inputDisabled={!inputDisabled ? findBanking(bankAccount.id, 'deposits') : true}
														name={'deposits' + service.Gateways[indexGateways].Id + serviceName + '[' + indexK + '-' + indexService + '-' + indexGateways + ']'}
														labelValue="Deposits"
														setValue={(checked) =>
															bankAccount.bankAccountFunction === 0 && checked === false
																? handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'bankAccountFunction', 3, false, false, 0, true, 'BankData', indexK) | setUpdateBank({ from: 'check', state: true })
																: handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'bankAccountFunction', bankAccount.bankAccountFunction === 1 && checked === true ? 2 : bankAccount.bankAccountFunction === 1 && checked === false ? 1 : bankAccount.bankAccountFunction === 2 && checked === false ? 1 : bankAccount.bankAccountFunction === 0 && checked === false ? 0 : 0, false, false, 0, true, 'BankData', indexK) |
																  setUpdateBank({ from: 'check', state: true })
														}
													/>
													<RowContainer
														inputType="Checkbox"
														refs={(e) => (e && !refWithdrawalsFees.current.includes(e) ? (refWithdrawalsFees.current = [...refWithdrawalsFees.current, e]) : '')}
														value={bankAccount.bankAccountFunction === 1 || bankAccount.bankAccountFunction === 2 ? true : false}
														TooltipCaption={findCheckClassValidate(bankAccount.id, 'withdrawalsFees') ? 'You cannot have more than one bank account for withdrawals/ Fees' : ''}
														errorValid={findCheckClassValidate(bankAccount.id, 'withdrawalsFees') ? true : false}
														inputDisabled={!inputDisabled ? findBanking(bankAccount.id, 'withdrawalsFees') : true}
														name={'withdrawalsFees' + service.Gateways[indexGateways].Id + serviceName + '[' + indexK + '-' + indexService + '-' + indexGateways + ']'}
														labelValue="Withdrawals/ Fees"
														setValue={(checked) =>
															bankAccount.bankAccountFunction === 1 && checked === false
																? handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'bankAccountFunction', 3, false, false, 0, true, 'BankData', indexK) | setUpdateBank({ from: 'check', state: true })
																: handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'bankAccountFunction', bankAccount.bankAccountFunction === 0 && checked === true ? 2 : bankAccount.bankAccountFunction === 0 && checked === false ? 0 : bankAccount.bankAccountFunction === 2 && checked === false ? 0 : bankAccount.bankAccountFunction === 1 && checked === false ? 1 : 1, false, false, 0, true, 'BankData', indexK) |
																  setUpdateBank({ from: 'check', state: true })
														}
													/>
												</div>
												{/* <div className="icheck-success">
												<input
													type="checkbox"
													onChange={(e) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'chargeBacks', e.target.checked, false, false, 0, true, 'BankData', indexK)}
													defaultChecked={bankAccount.chargeBacks}
													disabled={inputDisabled}
													id={'chargeBacks' + service.Gateways[indexGateways].Id + '[' + indexK + ']'}
												/>
												<label
													htmlFor={'chargeBacks' + service.Gateways[indexGateways].Id + '[' + indexK + ']'}
													className={styles['label-form'] + ' ' + styles['ft-s-12']}
													style={{ fontWeight: '500', lineHeight: '18px', textTransform: 'uppercase', color: '#606060' }}>
													Chargebacks/ ACH Returns
												</label>
											</div> */}
											</div>
											{findPurposeClassValidate(indexK) === 'is-invalid' && (
												<OverlayTrigger
													key={'right'}
													placement={'right'}
													overlay={<Tooltip>{'You must select a purpose for the bank account'}</Tooltip>}>
													<BiInfoCircle className={`${styles['icon15']} ${styles['color-error']}`} />
												</OverlayTrigger>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}

			{(showAlertFees || !Array.isArray(service.Gateways[indexGateways].BankData)) && (
				<Alert
					style={{ marginTop: '20px', width: '100%' }}
					show={!Array.isArray(service.Gateways[indexGateways].BankData) ? true : showAlertFees}
					variant="danger">
					<div className="d-flex flex-row justify-content-between align-items-center gap-1">
						<p className="m-0">At least one bank account must be assigned as withdrawal / fees purpose.</p>
						<Button
							onClick={() => setShowAlertFees(false)}
							variant="">
							<RiCloseLine className={styles['icon24'] + ' ' + styles['color-error']} />
						</Button>
					</div>
				</Alert>
			)}
		</div>
	)
}
