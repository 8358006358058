import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import { RenderPopoverTime } from '../../components/filters/RenderPopoverTime';

@inject('reports', 'filter')
@observer
class RenderTransactionDate extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Date</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="transactionDateCalendar" 
                                        name="transactionDateCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startTransactionDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startTransactionDate) + ' → ' : '') + (this.props.filter.stateDate.endTransactionDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endTransactionDate) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="transactionDateCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startTransactionDate ? this.props.filter.stateDate.startTransactionDate : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startTransactionDate', 'endTransactionDate', 'transactionDate')}
                            startDate={this.props.filter.stateDate.startTransactionDate}
                            endDate={this.props.filter.stateDate.endTransactionDate}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endTransactionDate !== null ? false : true}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Time</small></p>
                        { <RenderPopoverTime /> }
                    </div>
                </div>
            </>);
    }
}

export { RenderTransactionDate };