import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from "react-router-dom";
import { PayabliStorageManager } from '../api/localStorageManager'
import {BiChevronLeft} from '@react-icons/all-files/bi/BiChevronLeft';

@inject('reports','global')
@observer
class PaypointLinks extends React.Component {

     
    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        return (
            <div className="row">
            <div className="col-2">
                <div className="hide-sm">
                {this.props.goback && 
                    <a href={this.props.gobackLink} className="btn btn-light text-transform-normal"><BiChevronLeft/> { this.props.gobackText ? this.props.gobackText: "Go back" }</a>
                }
                </div>
            </div>
            <div className="col-8 text-center">
                {userPermissions.indexOf("Paypoints") !== -1 &&
                <Link to={'/'+this.props.global.getURLEntry()+'/paypoints/overview/'+this.props.entry+'/'+this.props.idPaypoint} className={this.props.selected === 'overview'? 'selected' : ''}> Overview</Link>
                }
                {userPermissions.indexOf("Transaction") !== -1 &&
                <Link to={'/'+this.props.global.getURLEntry()+'/report/transactions/paypoint/'+this.props.entry+'/'+this.props.idPaypoint} className={this.props.selected === 'transactions'? 'selected' : ''}>Transactions</Link>
                }
                {userPermissions.indexOf("Subscriptions") !== -1 &&
                <Link to={'/'+this.props.global.getURLEntry()+'/report/autopays/paypoint/'+this.props.entry+'/'+this.props.idPaypoint} className={this.props.selected === 'subscriptions'? 'selected' : ''}>Autopays</Link>
                }
                {userPermissions.indexOf("Residuals") !== -1 &&
                <Link to={'/'+this.props.global.getURLEntry()+'/report/residuals/paypoint/'+this.props.entry+'/'+this.props.idPaypoint} className={this.props.selected === 'residuals'? 'selected' : ''}>Residuals</Link>
                }
                {userPermissions.indexOf("Virtual Terminal") !== -1 &&
                <a href={'/'+this.props.global.getURLEntry()+"/vterminal/"+this.props.entry+'/'+this.props.idPaypoint} className={this.props.selected === 'virtualterminal'? 'selected' : ''} data-qaid="virtualTerminalLink">Virtual Terminal</a>
                }
                {userPermissions.indexOf("Applications") !== -1 &&
                    <a href={'/'+this.props.global.getURLEntry()+"/report/application/paypoint/"+this.props.entry+'/'+this.props.idPaypoint} className={this.props.selected === 'applications'? 'selected' : ''}>Application</a>
                }
            </div>
            <div className="col-2 text-right">
            </div>
        </div>
        )
    }
}

export { PaypointLinks };