import React, { useState, useContext } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import RowContainer from '../../../components/commandCenter/RowContainer'
import RadioButton from '../../../components/commandCenter/RadioButton'
import { RiCloseLine } from 'react-icons/ri'
import { Modal } from 'react-bootstrap'
import SwitchContainer from '../../../components/inputStyles/switchContainer'
import commandContext from '../context/commandCenter_context'

export default function AddTier({ tierProperty, closeModal }) {
	const { updateService, loadPaypointService } = useContext(commandContext)
	const [newTier, setNewTier] = useState({
		mode: 2,
		highPayRange: 0,
		lowPayRange: 0,
		absorbDifference: false,
		greaterValueAllowed: false,
		allowOverride: false,
		priceFloat: 0,
		priceFix: 0,
		priceMinimum: 0,
		priceMaximum: 0
	})

	const closeTiers = () => {
		setNewTier({
			mode: tierProperty.serviceType === 'check' || tierProperty.serviceType === 'cash' ? 0 : 2,
			highPayRange: 0,
			lowPayRange: 0,
			absorbDifference: false,
			greaterValueAllowed: false,
			priceFloat: 0,
			priceFix: 0,
			priceMinimum: 0,
			priceMaximum: 0
		})
		loadPaypointService()
		closeModal()
	}
	const addTiers = async () => {
		let valid = false
		if (parseInt(newTier.highPayRange) <= parseInt(newTier.lowPayRange)) {
			valid = true
		}
		if (parseInt(newTier.priceMaximum) < parseInt(newTier.priceMinimum)) {
			valid = true
		}
		if (valid) return

		const response = await updateService('addTiers', tierProperty.serviceType, tierProperty.type, tierProperty.indexService, tierProperty.indexGateways, 'Tier added successfully!', newTier)
		if (response) closeTiers()
	}

	return (
		<Modal
			show={tierProperty.add}
			onHide={closeTiers}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Header>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '10px 50px' }}>
					<Modal.Title
						id="contained-modal-title-vcenter"
						className={styles['label-form'] + ' ' + styles['ft-s-20'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Add New Payment Tier
					</Modal.Title>
					<RiCloseLine
						onClick={() => closeTiers()}
						className={styles['icon24']}
						style={{ cursor: 'pointer' }}
					/>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '12px', paddingInline: '30px' }}>
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Payment Tier
					</label>
					<div className="d-flex flex-column w-100">
						<div className={styles['grid-container']}>
							<div className="d-flex flex-column align-items-start p-0">
								<RadioButton
									recurringState={newTier.mode === 1 || newTier.mode === 2 ? true : false}
									oneTimeState={newTier.mode === 0 || newTier.mode === 2 ? true : false}
									inputDisabled={false}
									label="Pay mode Offered"
									changeStateRecurring={() => setNewTier({ ...newTier, mode: newTier.mode === 0 ? 2 : newTier.mode === 1 ? 1 : newTier.mode === 2 ? 0 : 1 })}
									changeStateOneTimeState={() => setNewTier({ ...newTier, mode: newTier.mode === 1 ? 2 : newTier.mode === 0 ? 0 : newTier.mode === 2 ? 1 : 0 })}
									onlyOneTimeState={tierProperty.serviceType === 'check' || tierProperty.serviceType === 'cash' ? true : false}
								/>

								<div
									className="d-flex flex-wrap flex-row align-items-center w-100"
									style={{ padding: '0 0 12px', gap: '8px' }}>
									<label
										className={styles['label-form'] + ' ' + styles['ft-s-13']}
										style={{ fontWeight: 600, lineHeight: '20px', letterSpacing: '0.0025em', width: '180px', minWidth: '20px' }}>
										Fees
									</label>
									<RowContainer
										inputType="Checkbox"
										divStyle={{ marginRight: '10px' }}
										value={newTier.absorbDifference}
										inputDisabled={false}
										name={'absorbDifference'}
										labelValue="Absorb Fee"
										setValue={(values) => setNewTier({ ...newTier, absorbDifference: values })}
									/>
									<RowContainer
										inputType="Checkbox"
										value={newTier.greaterValueAllowed}
										inputDisabled={false}
										name={'greaterValueAllowed'}
										labelValue="Difference Fee"
										setValue={(values) => setNewTier({ ...newTier, greaterValueAllowed: values })}
									/>
								</div>
								<div
									className="d-flex flex-wrap flex-row align-items-center w-100"
									style={{ padding: '0 0 12px', gap: '8px' }}>
									<div
										className={styles['label-form'] + ' ' + styles['ft-s-13']}
										style={{ lineHeight: '20px', width: '172px', minWidth: '20px' }}></div>
									<div className="d-flex flex-row flex-wrap align-items-center justify-content-center"></div>
									<SwitchContainer
										onChange={(values) => setNewTier({ ...newTier, allowOverride: values })}
										checked={newTier.allowOverride}
										onColor="#70AD47"
										onHandleColor="#ffffff"
										inputDisabled={false}
										handleDiameter={20}
										height={20}
										width={38}
										labelStyle={{ color: '#212529', padding: '0 30px 0 0' }}
										labelValue="Allow override"
										className="react-switch"
										classNameLabel={styles['label-form'] + ' ' + styles['ft-s-12']}
										TooltipCaption="When enabled, allows transactions to override the paypoint’s service fee settings."
									/>
								</div>
							</div>

							<div className="d-flex flex-column align-items-start p-0">
								<RowContainer
									inputType="number"
									labelValue="Min. Ticket Allowed"
									classNameInput={(newTier.highPayRange === null || !newTier.highPayRange ? 0 : parseInt(newTier.highPayRange)) <= (newTier.lowPayRange === null || !newTier.lowPayRange ? 0 : parseInt(newTier.lowPayRange)) ? 'is-invalid' : ''}
									name="lowPayRange"
									placeholder="$0.00"
									value={newTier.lowPayRange}
									setValue={(values) => setNewTier({ ...newTier, lowPayRange: values === null || !values ? 0 : values })}
									maxLength={18}
								/>

								<RowContainer
									inputType="number"
									labelValue="Max. Ticket Allowed"
									classNameInput={(newTier.highPayRange === null || !newTier.highPayRange ? 0 : parseInt(newTier.highPayRange)) <= (newTier.lowPayRange === null || !newTier.lowPayRange ? 0 : parseInt(newTier.lowPayRange)) ? 'is-invalid' : ''}
									name="highPayRange"
									placeholder="$0.00"
									value={newTier.highPayRange}
									setValue={(values) => setNewTier({ ...newTier, highPayRange: values === null || !values ? 0 : values })}
									maxLength={18}
								/>
							</div>
						</div>
					</div>

					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Pricing To Payor
					</label>
					<div className="d-flex flex-column w-100">
						<div className={styles['grid-container']}>
							<div className="d-flex flex-column align-items-start p-0">
								<RowContainer
									inputType="number"
									labelValue="% Fee Charged To Payor"
									name="priceFloat"
									placeholder="0.00%"
									prefix=""
									suffix="%"
									value={newTier.priceFloat}
									setValue={(values) => setNewTier({ ...newTier, priceFloat: values })}
									maxLength={18}
									maxValue={100}
								/>

								<RowContainer
									inputType="number"
									labelValue="$ Fee Charged To Payor"
									name="priceFix"
									placeholder="$0.00"
									value={newTier.priceFix}
									setValue={(values) => setNewTier({ ...newTier, priceFix: values })}
									maxLength={18}
								/>
							</div>
							<div className="d-flex flex-column align-items-start p-0">
								<RowContainer
									inputType="number"
									classNameInput={(newTier.priceMaximum === null || !newTier.priceMaximum ? 0 : parseInt(newTier.priceMaximum)) < (newTier.priceMinimum === null || !newTier.priceMinimum ? 0 : parseInt(newTier.priceMinimum)) ? 'is-invalid' : ''}
									labelValue="Min. Fee Charged To Payor"
									name="priceMinimum"
									placeholder="$0.00"
									value={newTier.priceMinimum}
									setValue={(values) => setNewTier({ ...newTier, priceMinimum: values === null || !values ? 0 : values })}
									maxLength={18}
								/>

								<RowContainer
									inputType="number"
									classNameInput={(newTier.priceMaximum === null || !newTier.priceMaximum ? 0 : parseInt(newTier.priceMaximum)) < (newTier.priceMinimum === null || !newTier.priceMinimum ? 0 : parseInt(newTier.priceMinimum)) ? 'is-invalid' : ''}
									labelValue="Max Fee Charged To Payor"
									name="priceMaximum"
									placeholder="$0.00"
									value={newTier.priceMaximum}
									setValue={(values) => setNewTier({ ...newTier, priceMaximum: values === null || !values ? 0 : values })}
									maxLength={18}
								/>
							</div>
						</div>
					</div>

					<div
						className="d-flex align-items-center justify-content-end flex-wrap flex-row w-100 gap-3"
						style={{ marginTop: '20px' }}>
						<button
							onClick={() => closeTiers()}
							className={styles['btn'] + ' ' + styles['btn-red-letter']}>
							CANCEL
						</button>
						<button
							onClick={() => addTiers()}
							className={styles['btn'] + ' ' + styles['btn-blue']}>
							ADD PAYMENT TIER
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
