import React, { useState, useContext } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { BiNotepad } from 'react-icons/bi'
import globalStore from '../../store/GlobalStore'
import applicationContext from '../../views/Boarding/Applications/context/applications_context'

export default function NotesEventsTooltip({ appId, notesEvents = [], showingNotesEvent, setNotesEvents, loadedAppId, SetLoadedAppId, openModal }) {
	const { loadMessage } = useContext(applicationContext)
	const [showTooltip, setShowTooltip] = useState(false)
	const [messageLoaded, setMessageLoaded] = useState(false)

	const openTooltip = async () => {
		setShowTooltip(true)
		showingNotesEvent(true)
		let newDate = new Date(loadedAppId.lastTimeloadedAppId)
		newDate.setSeconds(newDate.getSeconds() + 10)

		loadedAppId.id !== appId ? setMessageLoaded(false) : setMessageLoaded(true)

		if (loadedAppId.id !== appId || Date.now() > newDate) {
			const response = await loadMessage(appId)
			setNotesEvents(response)
			SetLoadedAppId(appId)
			setMessageLoaded(true)
		}
	}

	return (
		<OverlayTrigger
			placement="left-start"
			show={showTooltip && messageLoaded ? true : false}
			overlay={
				<Tooltip bsPrefix="notesEventTooltip">
					{
						<div
							onMouseEnter={() => openTooltip()}
							onMouseLeave={() => setShowTooltip(false) || showingNotesEvent(false) || setMessageLoaded(false)}>
							{notesEvents.length > 0 ? (
								notesEvents.slice(0, 3).map((note, i) => (
									<div
										key={'NoteList' + i}
										className="d-flex flex-column">
										<label
											style={{
												fontWeight: '600',
												fontSize: '12px'
											}}>{`${note.userName} on ${globalStore.stringDateFormatV3(note.createdAt)} - ${globalStore.stringTimeFormatV3(note.createdAt)}`}</label>
										{note.messageType === 1 ? (
											<label
												style={{
													fontSize: '12px',
													paddingBottom: '20px'
												}}>
												{note.content}
											</label>
										) : (
											<label
												style={{
													fontSize: '12px',
													paddingBottom: '20px'
												}}>{`Changed Status from ${globalStore.getAppStatusText(note.originalApplicationStatus, true)} to ${globalStore.getAppStatusText(note.currentApplicationStatus, true)} `}</label>
										)}
									</div>
								))
							) : (
								<label>There are no notes for this application</label>
							)}
							{notesEvents.length > 0 && (
								<label
									onClick={() => openModal()}
									className="text-primary w-100 text-end"
									style={{
										lineHeight: '15px',
										fontWeight: '400',
										cursor: 'pointer',
										fontSize: '12px',
										padding: '0 5px'
									}}>
									READ MORE
								</label>
							)}
						</div>
					}
				</Tooltip>
			}>
			<div className="btn-tooltip-notes" onMouseEnter={() => openTooltip()} onMouseLeave={() => setShowTooltip(false) || showingNotesEvent(false)}>
				<BiNotepad
					className="icon-modal"
					style={{ height: '14px', width: '14px', color: '#4A4A4A', margin: '0' }}
				/>
			</div>
		</OverlayTrigger>
	)
}
