import React from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function PartialBtnBadgeReport({
	classNameProps,
	isElementActive,
	isBadge,
	fnProps,
	icon,
	classCircle,
	textTitle,
	isInfoIcon,
	infoTextTooltip,
	textSubTitle,
	isNotAllowed,
	dataQaid
}) {
	let classBadge = isBadge ? 'd-flex bg-qfilter' : '';
	classBadge = classNameProps ? `${classBadge} ${classNameProps}` : classBadge;
	classBadge = isNotAllowed ? `${classBadge} bg-qfilter-disabled` : classBadge;
	classBadge = isElementActive ? `${classBadge} active` : classBadge;

	const circleClass = classCircle ? `circle ${classCircle}` : 'circle';

	return (
		<div
			className={classBadge}
			data-qaid={dataQaid || ''}
			onClick={fnProps}>
			<div className='d-flex align-items-center'>
				{icon && <div className={`${circleClass} d-none d-xl-flex`}>{icon}</div>}
				<div className='text-lines'>
					<span>
						<span style={isElementActive ? { fontWeight: 'bold' } : {}}>{textTitle}</span>
						{isInfoIcon && (
							<OverlayTrigger
								placement='bottom'
								overlay={<Tooltip>{infoTextTooltip}</Tooltip>}>
								<BiInfoCircle className='icon-info-text' />
							</OverlayTrigger>
						)}
					</span>
					<b>{textSubTitle}</b>
				</div>
			</div>
		</div>
	);
}

export default PartialBtnBadgeReport;
