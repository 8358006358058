import { action } from 'mobx'

export default function BusinessFunction({ business, setBusiness }) {
	const handleChangeBusiness = action((field, value) => {
		setBusiness({ ...business, [field]: value })
	})
	const sameAddressBusiness = action((state) => {
		if (state) {
			setBusiness({
				...business,
				mailAddress1: business.address1,
				mailAddress2: business.address2,
				mailCity: business.city,
				mailZip: business.zip,
				mailState: business.state,
				mailCountry: business.country,
				sameAddress: state
			})
		} else {
			setBusiness({
				...business,
				mailAddress1: '',
				mailAddress2: '',
				mailCity: '',
				mailZip: '',
				mailState: '',
				mailCountry: '',
				sameAddress: state
			})
		}
	})
	return { handleChangeBusiness, sameAddressBusiness }
}
