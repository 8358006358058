import React, { useEffect, useState } from 'react'
import styles from '../../assets/css/styleCommandCenter.module.css'
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle'
import SelectSearch from 'react-select-search'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import LoaderPlaceHolder from '../loaderPlaceHolder'
import Fuse from 'fuse.js'

export default function SelectBoxContainerTemplates({
	inputDisabled,
	labelValue,
	name,
	required = false,
	refs,
	value = '',
	classNameInput = '',
	inputStyle = {},
	setValue,
	optionList,
	placementValid = 'right',
	placement = 'right',
	TooltipValid = '',
	TooltipValidColorIcon = '',
	TooltipValidStyle = {},
	TooltipCaption = '',
	onBlur = () => null,
	useElement = false,
	listElement = [],
	listBtnElement = [],
	placeholder = '',
	maxHeightScroll = 'none',
	divClass = '',
	divStyle = {},
	labelStyle = {},
	labelClassName = '',
	selectSearch = false,
	isLoading = false,
	placeholderStyle = { height: '39px', width: '100%', borderRadius: '.25rem' }
}) {
	const [showSelect, setShowSelect] = useState(false)
	const [dataListElement, setDataListElement] = useState(listElement)

	useEffect(() => {
		if (dataListElement !== listElement && useElement) {
			setDataListElement(listElement)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listElement])

	const handleChange = (e) => {
		!selectSearch ? setValue(e.target.value) : setValue(e)
	}
	const selectOption = (value) => {
		setValue(value)
		setShowSelect(false)
		const newDataListElement = dataListElement.map((data) => {
			return {
				...data,
				select: data.value === value ? true : false
			}
		})
		setDataListElement(newDataListElement)
	}
	const findSelectOption = (id) => {
		const data = dataListElement.find(({ value }) => value === id)
		if (data) return data.text
		return placeholder
	}
	const fuzzySearch = (options) => {
		const fuse = new Fuse(options, {
			keys: ['name', 'groupName', 'items.name'],
			threshold: 0.3
		})

		return (value) => {
			if (!value.length) {
				return options
			}

			let result = fuse.search(value)
			let resultArray = []
			result.forEach(function (item) {
				resultArray.push(item.item)
			})
			return resultArray
		}
	}

	return (
		<div
			className={divClass}
			style={divStyle}>
			<label
				className={labelClassName}
				style={labelStyle}>
				{labelValue}
			</label>
			<SelectSearch
				//printOptions={'always' }
				ref={refs}
				options={optionList}
				//className="select-search"
				className={`select-search ${classNameInput}`}
				style={inputStyle}
				autoComplete={'selectSearch'}
				search
				filterOptions={fuzzySearch}
				emptyMessage="Not found"
				placeholder={placeholder}
				id={name || 'selectSearch'}
				value={value}
				onChange={(e) => handleChange(e)}
				disabled={inputDisabled ? inputDisabled : false}
			/>
		</div>
	)
}
