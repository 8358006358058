import React, { useState, useContext, useEffect } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import { Alert } from 'react-bootstrap'
import { BiBookContent, BiDotsVerticalRounded } from 'react-icons/bi'
import FrameGroup from '../../../components/commandCenter/FrameGroup'
import ButtonSaveCancel from '../../../components/commandCenter/ButtonSaveCancel'
import RowContainer from '../../../components/commandCenter/RowContainer'
import GlobalStore from '../../../store/GlobalStore'
import { Element } from 'react-scroll'
import commandContext from '../context/commandCenter_context'

export default function ProcessingInformation() {
	const { PayPoint, loadData, updatePayPoint, Processing, handleChangeProcessing, PaypointCurrency, handleChangeCurrency, toastMessage, newPypoint } = useContext(commandContext)
	const [inputDisabled, setInputDisabled] = useState(true)
	const [showAlert, setShowAlert] = useState(false)

	useEffect(() => {
		newPypoint && setInputDisabled(false)
	}, [newPypoint])
	const load = async () => {
		await loadData()
	}
	const validatePercents = (binPerson, binPhone, binWeb) => {
		setShowAlert(parseInt(binPerson) + parseInt(binPhone) + parseInt(binWeb) !== 100 ? true : false)
	}
	const updateProcessing = async () => {
		let valid = false
		if (Processing.mcc === null || Processing.mcc.toString().trim().length === 0) valid = true
		if (Processing.summary === null || Processing.summary.toString().trim().length === 0) valid = true
		if (Processing.averageMonthlyVolume === null || Processing.averageMonthlyVolume <= 0) valid = true
		if (Processing.averageTicketAmount === null || Processing.averageTicketAmount <= 0) valid = true
		if (Processing.highTicketAmount === null || Processing.highTicketAmount <= 0) valid = true
		if (parseInt(Processing.binPerson) + parseInt(Processing.binPhone) + parseInt(Processing.binWeb) !== 100) valid = true
		validatePercents(Processing.binPerson, Processing.binPhone, Processing.binWeb)
		if (valid || showAlert) {
			toastMessage('You must fill in the required fields', false)
			return
		}
		const res = updatePayPoint('Processing')
		if (res) setInputDisabled(true)
	}

	return (
		<Element
			name="Processing"
			className="d-flex justify-content-center w-100">
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={<BiBookContent className={styles['icon26'] + ' ' + styles['color-blue']} />}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label="Processing Information"
					editClick={() => setInputDisabled(false)}
					useBtn={PayPoint.idPaypoint <= 0 ? false : inputDisabled}
					btnDelete={false}
				/>

				<div className="d-flex flex-column w-100">
					<div className={styles['grid-container']}>
						<div className="d-flex flex-column align-items-start p-0">
							{!inputDisabled ? (
								<RowContainer
									selectSearch={true}
									inputDisabled={false}
									classNameInput={!inputDisabled && (Processing.mcc === null || Processing.mcc.trim().length === 0) ? 'input-error' : ''}
									labelValue="Merchant Category Code (MCC)"
									name="mcc"
									value={Processing.mcc}
									setValue={(values) => handleChangeProcessing('mcc', values)}
									optionList={GlobalStore.getMccOptions()}
								/>
							) : (
								<RowContainer
									inputDisabled={true}
									//isLoading={isLoading}
									labelValue="Merchant Category Code (MCC)"
									name="mcc"
									value={Processing.mcc}
									setValue={(values) => handleChangeProcessing('mcc', values)}
									optionList={
										<>
											{GlobalStore.getOptionsType('mcc').map((data) => (
												<option
													key={'optState' + data.value}
													value={data.value}>
													{data.text}
												</option>
											))}
										</>
									}
								/>
							)}
							<RowContainer
								inputType="number"
								prefix=""
								suffix="%"
								maxValue={100}
								decimalScale={0}
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								classNameInput={!inputDisabled && (parseInt(Processing.binPerson) + parseInt(Processing.binPhone) + parseInt(Processing.binWeb) !== 100 || showAlert) ? 'input-error' : ''}
								labelValue="% of Payments In Person"
								name="binPerson"
								placeholder="%0"
								value={Processing.binPerson}
								setValue={(values) => handleChangeProcessing('binPerson', values) | validatePercents(values, Processing.binPhone, Processing.binWeb)}
								mask=""
							/>
							<RowContainer
								inputType="number"
								prefix=""
								suffix="%"
								maxValue={100}
								decimalScale={0}
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								classNameInput={!inputDisabled && (parseInt(Processing.binPerson) + parseInt(Processing.binPhone) + parseInt(Processing.binWeb) !== 100 || showAlert) ? 'input-error' : ''}
								labelValue="% of Payments By Phone"
								name="binPhone"
								placeholder="%0"
								value={Processing.binPhone}
								setValue={(values) => handleChangeProcessing('binPhone', values) | validatePercents(Processing.binPerson, values, Processing.binWeb)}
								mask=""
							/>
							<RowContainer
								inputType="number"
								prefix=""
								suffix="%"
								maxValue={100}
								decimalScale={0}
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								classNameInput={!inputDisabled && (parseInt(Processing.binPerson) + parseInt(Processing.binPhone) + parseInt(Processing.binWeb) !== 100 || showAlert) ? 'input-error' : ''}
								labelValue="% of Payments Online"
								name="binWeb"
								placeholder="%0"
								value={Processing.binWeb}
								setValue={(values) => handleChangeProcessing('binWeb', values) | validatePercents(Processing.binPerson, Processing.binPhone, values)}
								mask=""
							/>
							<Alert
								style={{ width: '95%' }}
								show={!inputDisabled && (parseInt(Processing.binPerson) + parseInt(Processing.binPhone) + parseInt(Processing.binWeb) !== 100 || showAlert) ? true : false}
								variant="danger">
								<div className="d-flex flex-row justify-content-between align-items-center gap-1">
									<p className="m-0">Combined total payments percent must be equal to 100%!</p>
								</div>
							</Alert>
							<RowContainer
								inputType={'textarea'}
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								classNameInput={!inputDisabled && (Processing.summary === null || Processing.summary.trim().length === 0) ? 'is-invalid' : ''}
								placeholderStyle={{ minHeight: '88px', width: '226px', borderRadius: '.25rem' }}
								labelValue="Tell Us About Your Business"
								name="summary"
								placeholder="Value"
								value={Processing.summary}
								setValue={(values) => handleChangeProcessing('summary', values)}
							/>
						</div>

						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								classNameInput={!inputDisabled && (Processing.averageMonthlyVolume === null || Processing.averageMonthlyVolume <= 0) ? 'is-invalid' : ''}
								//isLoading={isLoading}
								labelValue="Average Monthly Volume"
								name="averageMonthlyVolume"
								placeholder="$0.00"
								value={Processing.averageMonthlyVolume}
								setValue={(values) => handleChangeProcessing('averageMonthlyVolume', values)}
								maxLength={18}
							/>
							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								classNameInput={!inputDisabled && (Processing.averageTicketAmount === null || Processing.averageTicketAmount <= 0) ? 'is-invalid' : ''}
								//isLoading={isLoading}
								labelValue="Average Ticket Amount"
								placeholder="$0.00"
								name="averageTicketAmount"
								value={Processing.averageTicketAmount}
								setValue={(values) => handleChangeProcessing('averageTicketAmount', values)}
								maxLength={18}
							/>
							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								classNameInput={!inputDisabled && (Processing.highTicketAmount === null || Processing.highTicketAmount <= 0) ? 'is-invalid' : ''}
								//isLoading={isLoading}
								labelValue="High Ticket Amount"
								name="highTicketAmount"
								placeholder="$0.00"
								value={Processing.highTicketAmount}
								setValue={(values) => handleChangeProcessing('highTicketAmount', values)}
								maxLength={18}
							/>
							<RowContainer
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="When Is Payment Charged"
								name="whencharged"
								value={Processing.whencharged}
								setValue={(values) => handleChangeProcessing('whencharged', values)}
								optionList={
									<>
										{GlobalStore.getOptionsType('whencharged').map((data) => (
											<option
												key={'whencharged' + data.value}
												value={data.value}>
												{data.text}
											</option>
										))}
									</>
								}
							/>
							<RowContainer
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="Products/Services Delivered In"
								name="ProductsServicesDeliveredIn"
								value={Processing.whendelivered}
								setValue={(values) => handleChangeProcessing('whendelivered', values)}
								optionList={
									<>
										{GlobalStore.getOptionsType('whendelivered').map((data) => (
											<option
												key={'whendelivered' + data.value}
												value={data.value}>
												{data.text}
											</option>
										))}
									</>
								}
							/>
							<RowContainer
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="When Generally Provided In"
								name="whenprovided"
								value={Processing.whenprovided}
								setValue={(values) => handleChangeProcessing('whenprovided', values)}
								optionList={
									<>
										{GlobalStore.getOptionsType('whenprovided').map((data) => (
											<option
												key={'whenprovided' + data.value}
												value={data.value}>
												{data.text}
											</option>
										))}
									</>
								}
							/>
							<RowContainer
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="Refund Policy"
								name="whenrefund"
								value={Processing.whenrefund}
								setValue={(values) => handleChangeProcessing('whenrefund', values)}
								optionList={
									<>
										{GlobalStore.getOptionsType('whenrefunded').map((data) => (
											<option
												key={'whenrefunded' + data.value}
												value={data.value}>
												{data.text}
											</option>
										))}
									</>
								}
							/>
						</div>
					</div>
				</div>

				<label
					className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
					style={{ fontWeight: '600', lineHeight: '24px' }}>
					Currency
				</label>
				<div className="d-flex flex-row justify-content-start flex-wrap align-items-center w-100 gap-2 p-0 pe-2 pb-3">
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-13']}
						style={{ fontWeight: '600', lineHeight: '40px', minWidth: '180px', letterSpacing: '0.0025em' }}>
						Actual Currency Set
					</label>
					{!inputDisabled ? (
						<div className="d-flex flex-row justify-content-start flex-nowrap align-items-center p-0 gap-5">
							<RowContainer
								inputType="Radio"
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="USD"
								name="actualCurrencySetUSD"
								value={PaypointCurrency.value === 'USD' ? true : false}
								setValue={() => handleChangeCurrency('USD')}
							/>
							<RowContainer
								inputType="Radio"
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="CAD"
								name="actualCurrencySetCAD"
								value={PaypointCurrency.value === 'CAD' ? true : false}
								setValue={() => handleChangeCurrency('CAD')}
							/>
						</div>
					) : (
						<RowContainer
							inputType="Label"
							//isLoading={isLoading}
							labelValue={PaypointCurrency.value === 'USD' ? 'United States Dollar (USD)' : 'Canadian Dollar (CAD)'}
							placeholderStyle={{ minHeight: '20px', width: '226px', marginRight: '20px', borderRadius: '.25rem' }}
						/>
					)}
				</div>

				<ButtonSaveCancel
					inputDisabled={inputDisabled}
					funtionCancel={PayPoint.idPaypoint <= 0 ? null : () => setInputDisabled(true) | load()}
					funtionSave={PayPoint.idPaypoint <= 0 ? null : () => updateProcessing()}
				/>
			</div>
		</Element>
	)
}
