import React, { useContext } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import SwitchContainer from '../../inputStyles/switchContainer'
import RowContainer from '../RowContainer'
import RadioButton from '../RadioButton'
import { RiAddFill } from 'react-icons/ri'
import { BsTrash } from 'react-icons/bs'
import commandContext from '../../../views/commandCenter/context/commandCenter_context'

export default function Tiers({ service, indexService, indexGateways, serviceType, inputDisabled, setShowModal, showModal, setTierProperty }) {
	const { handleChangeServices, updateService } = useContext(commandContext)

	const deleteTiers = async (index) => {
		await updateService('deleteTier', serviceType, service.ServiceGroup, indexService, indexGateways, 'Tier successfully removed!', '', index)
	}

	return (
		<>
			{(service.Gateways[indexGateways].Tiers.length === 0 || service.Gateways[indexGateways].Tiers === null) && (
				<div className="d-flex flex-row flex-wrap justify-content-end align-items-center p-0 gap-3 mb-3 mt-4 pe-3 w-100">
					<button
						onClick={() => setTierProperty({ add: true, serviceType: serviceType, type: service.ServiceGroup, indexService: indexService, indexGateways: indexGateways })}
						className={styles['btn'] + ' ' + styles['border-blue']}>
						<RiAddFill className={styles['icon20']} />
						ADD TIER
					</button>
				</div>
			)}
			{service.Gateways[indexGateways].Tiers.map((Tiers, indexK) => (
				<div
					key={'Tiers' + service.Gateways[indexGateways].Id + '-' + indexK}
					style={{ width: '100%' }}>
					<div
						style={{
							borderTop: '1px solid #D9D9D9',
							width: '100%',
							minHeight: '100px',
							padding: '20px 0 12px 15px',
							display: 'flex',
							flexDirection: 'column',
							gap: '8px'
						}}>
						<div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-0 gap-3 mb-3 w-100">
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
								style={{ fontWeight: '600', lineHeight: '24px' }}>
								Payment Tier # {(indexK + 1).toString()}
							</label>

							{inputDisabled && (
								<div
									className="d-flex flex-row flex-wrap justify-content-end align-items-center p-0 gap-2"
									style={{ marginRight: '10px' }}>
									{indexK === 0 && (
										<button
											onClick={() => setTierProperty({ add: true, serviceType: serviceType, type: service.ServiceGroup, indexService: indexService, indexGateways: indexGateways })}
											className={styles['btn'] + ' ' + styles['border-blue']}>
											<RiAddFill className={styles['icon20']} />
											ADD TIER
										</button>
									)}
								</div>
							)}

							{!inputDisabled && (
								<div
									className="d-flex flex-row flex-wrap justify-content-end align-items-center p-0 gap-2"
									style={{ marginRight: '10px' }}>
									{service.Gateways[indexGateways].Tiers.length > 1 && (
										<button
											onClick={() => setShowModal({ ...showModal, message: 'This action will delete the selected Tier.', message2: 'Do you want to continue?', showMessage: true, blueBtn: false, btnCaption: 'DELETE TIER', function: () => deleteTiers(indexK) })}
											className={styles['btn'] + ' ' + styles['btn-red-letter'] + ' ' + styles['no-border']}>
											<BsTrash className={styles['icon20']} />
											DELETE TIER
										</button>
									)}
									{service.Gateways[indexGateways].Tiers.length === indexK + 1 && (
										<button
											onClick={() => setTierProperty({ add: true, serviceType: serviceType, type: service.ServiceGroup, indexService: indexService, indexGateways: indexGateways })}
											className={styles['btn'] + ' ' + styles['border-blue']}>
											<RiAddFill className={styles['icon20']} />
											ADD TIER
										</button>
									)}
								</div>
							)}

							<div className="d-flex flex-column w-100">
								<div className={styles['grid-container']}>
									<div className="d-flex flex-column align-items-start p-0">
										<RadioButton
											recurringState={Tiers.mode === 1 || Tiers.mode === 2 ? true : false}
											oneTimeState={Tiers.mode === 0 || Tiers.mode === 2 ? true : false}
											inputDisabled={inputDisabled}
											label="Pay mode Offered"
											changeStateRecurring={() => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'mode', Tiers.mode === 0 ? 2 : Tiers.mode === 1 ? 1 : Tiers.mode === 2 ? 0 : 1, false, false, 0, true, 'Tiers', indexK)}
											changeStateOneTimeState={() => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'mode', Tiers.mode === 1 ? 2 : Tiers.mode === 0 ? 0 : Tiers.mode === 2 ? 1 : 0, false, false, 0, true, 'Tiers', indexK)}
											onlyOneTimeState={serviceType === 'check' || serviceType === 'cash' ? true : false}
										/>

										<div
											className="d-flex flex-wrap flex-row align-items-center w-100"
											style={{ padding: '0 0 12px', gap: '8px' }}>
											<label
												className={styles['label-form'] + ' ' + styles['ft-s-13']}
												style={{ fontWeight: 600, lineHeight: '20px', letterSpacing: '0.0025em', width: '180px', minWidth: '20px' }}>
												Fees
											</label>
											<RowContainer
												inputType="Checkbox"
												divStyle={{ marginRight: '10px' }}
												value={Tiers.payorPrice ? Tiers.payorPrice.absorbDifference : false}
												inputDisabled={inputDisabled}
												name={'absorbDifference' + service.ServiceGroup.toString() + serviceType.toString() + '[' + indexGateways + ' ' + indexK + ']'}
												labelValue="Absorb Fee"
												setValue={(values) => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'absorbDifference', values, false, false, 0, true, 'Tiers', indexK, true)}
											/>
											<RowContainer
												inputType="Checkbox"
												value={Tiers.payorPrice ? Tiers.payorPrice.greaterValueAllowed : false}
												inputDisabled={inputDisabled}
												name={'greaterValueAllowed' + service.ServiceGroup.toString() + serviceType.toString() + '[' + indexGateways + ' ' + indexK + ']'}
												labelValue="Difference Fee"
												setValue={(values) => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'greaterValueAllowed', values, false, false, 0, true, 'Tiers', indexK, true)}
											/>
										</div>

										<div
											className="d-flex flex-wrap flex-row align-items-center w-100"
											style={{ padding: '0 0 12px', gap: '8px' }}>
											<div
												className={styles['label-form'] + ' ' + styles['ft-s-13']}
												style={{ lineHeight: '20px', width: '172px', minWidth: '20px' }}></div>
											<div className="d-flex flex-row flex-wrap align-items-center justify-content-center"></div>
											<SwitchContainer
												onChange={(values) => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'allowOverride', values, false, false, 0, true, 'Tiers', indexK, true)}
												checked={Tiers.payorPrice ? (Tiers.payorPrice?.allowOverride ? Tiers.payorPrice.allowOverride : false) : false}
												onColor="#70AD47"
												onHandleColor="#ffffff"
												inputDisabled={inputDisabled}
												handleDiameter={20}
												height={20}
												width={38}
												labelStyle={{ color: '#212529', padding: '0 30px 0 0' }}
												labelValue="Allow override"
												className="react-switch"
												classNameLabel={styles['label-form'] + ' ' + styles['ft-s-12']}
												TooltipCaption="When enabled, allows transactions to override the paypoint’s service fee settings."
											/>
										</div>
									</div>
									<div className="d-flex flex-column align-items-start p-0">
										<RowContainer
											inputType="number"
											inputDisabled={inputDisabled}
											classNameInput={!inputDisabled && (Tiers.highPayRange === null || !Tiers.highPayRange ? 0 : parseInt(Tiers.highPayRange)) <= (Tiers.lowPayRange === null || !Tiers.lowPayRange ? 0 : parseInt(Tiers.lowPayRange)) ? 'is-invalid' : ''}
											labelValue="Min. Ticket Allowed"
											name={'lowPayRange[' + indexK + ']'}
											placeholder="$0.00"
											value={Tiers.lowPayRange}
											setValue={(values) => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'lowPayRange', values === null || !values ? 0 : values, false, false, 0, true, 'Tiers', indexK)}
											maxLength={18}
										/>

										<RowContainer
											inputType="number"
											inputDisabled={inputDisabled}
											classNameInput={!inputDisabled && (Tiers.highPayRange === null || !Tiers.highPayRange ? 0 : parseInt(Tiers.highPayRange)) <= (Tiers.lowPayRange === null || !Tiers.lowPayRange ? 0 : parseInt(Tiers.lowPayRange)) ? 'is-invalid' : ''}
											labelValue="Max. Ticket Allowed"
											name={'highPayRange[' + indexK + ']'}
											placeholder="$0.00"
											value={Tiers.highPayRange}
											setValue={(values) => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'highPayRange', values === null || !values ? 0 : values, false, false, 0, true, 'Tiers', indexK)}
											maxLength={18}
										/>
									</div>
								</div>
							</div>

							<label
								className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
								style={{ fontWeight: '600', lineHeight: '24px' }}>
								Pricing to payor
							</label>
							<div className="d-flex flex-column w-100">
								<div className={styles['grid-container']}>
									<div className="d-flex flex-column align-items-start p-0">
										<RowContainer
											inputType="number"
											inputDisabled={inputDisabled}
											labelValue="% Fee Charged To Payor"
											name={'priceFloat[' + indexK + ']'}
											placeholder="0.00%"
											prefix=""
											suffix="%"
											value={Tiers.payorPrice ? Tiers.payorPrice.priceFloat : 0}
											setValue={(values) => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'priceFloat', values, false, false, 0, true, 'Tiers', indexK, true)}
											maxLength={18}
											maxValue={100}
										/>

										<RowContainer
											inputType="number"
											inputDisabled={inputDisabled}
											labelValue="$ Fee Charged To Payor"
											name={'priceFix[' + indexK + ']'}
											placeholder="$0.00"
											value={Tiers.payorPrice ? Tiers.payorPrice.priceFix : 0}
											setValue={(values) => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'priceFix', values, false, false, 0, true, 'Tiers', indexK, true)}
											maxLength={18}
										/>
									</div>
									<div className="d-flex flex-column align-items-start p-0">
										<RowContainer
											inputType="number"
											inputDisabled={inputDisabled}
											classNameInput={!inputDisabled && Tiers.payorPrice && (Tiers.payorPrice.priceMaximum === null || !Tiers.payorPrice.priceMaximum ? 0 : parseInt(Tiers.payorPrice.priceMaximum)) < (Tiers.payorPrice.priceMinimum === null || !Tiers.payorPrice.priceMinimum ? 0 : parseInt(Tiers.payorPrice.priceMinimum)) ? 'is-invalid' : ''}
											labelValue="Min. Fee Charged To Payor"
											name={'priceMinimum[' + indexK + ']'}
											placeholder="$0.00"
											value={Tiers.payorPrice ? Tiers.payorPrice.priceMinimum : 0}
											setValue={(values) => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'priceMinimum', values === null || !values ? 0 : values, false, false, 0, true, 'Tiers', indexK, true)}
											maxLength={18}
										/>

										<RowContainer
											inputType="number"
											inputDisabled={inputDisabled}
											classNameInput={!inputDisabled && Tiers.payorPrice && (Tiers.payorPrice.priceMaximum === null || !Tiers.payorPrice.priceMaximum ? 0 : parseInt(Tiers.payorPrice.priceMaximum)) < (Tiers.payorPrice.priceMinimum === null || !Tiers.payorPrice.priceMinimum ? 0 : parseInt(Tiers.payorPrice.priceMinimum)) ? 'is-invalid' : ''}
											labelValue="Max Fee Charged To Payor"
											name={'priceMaximum[' + indexK + ']'}
											placeholder="$0.00"
											value={Tiers.payorPrice ? Tiers.payorPrice.priceMaximum : 0}
											setValue={(values) => handleChangeServices(service.ServiceGroup, serviceType, indexService, service.Gateways[indexGateways].Id, 'priceMaximum', values === null || !values ? 0 : values, false, false, 0, true, 'Tiers', indexK, true)}
											maxLength={18}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</>
	)
}
