import React, { useState, useContext } from 'react'
import { Element } from 'react-scroll'
import MessageList from './MessageList'
import InputContainer from '../inputStyles/InputContainer'
import { FiSend } from 'react-icons/fi'
import applicationContext from '../../views/Boarding/Applications/context/applications_context'
export default function NotesEventTabContaier({ appId, notesEvents, setNotesEvents }) {
	const { loadMessage, saveMessage } = useContext(applicationContext)
	const [scrollHeight, setScrollHeight] = useState(0)
	const [message, setMessage] = useState('')
	const [messageSkip, setMessageSkip] = useState(20)
	const [noMoreNotes, setNoMoreNotes] = useState(false)

	const endMessage = () => {
		const containerMessage = document.getElementById('containerMessage')
		if (containerMessage.offsetHeight + containerMessage.scrollTop >= containerMessage.scrollHeight - 10 && scrollHeight !== containerMessage.scrollHeight) {
			setScrollHeight(containerMessage.scrollHeight)
			loadMoreMessages()
		}
	}
	const loadMoreMessages = async () => {
		const response = await loadMessage(appId, messageSkip, 20)
		if (response.length > 0) {
			const newNotesEvents = notesEvents
			response.forEach((Element) => {
				newNotesEvents.push(Element)
			})
			setMessageSkip(messageSkip + 20)
			setNotesEvents(newNotesEvents)
			setNoMoreNotes(false)
		} else if (response.length <= 0) setNoMoreNotes(true)
	}
	const save = async () => {
		const res = await saveMessage(appId, message)
		if (res === false) return
		const newNotesEvents = notesEvents
		newNotesEvents.unshift({ id: res.id, userName: res.userName, createdAt: res.createdAt, messageType: res.messageType, originalApplicationStatus: 0, currentApplicationStatus: 0, content: res.content })
		setNotesEvents(newNotesEvents)
		setMessage('')
	}

	return (
		<div className="d-flex flex-column flex-nowrap">
			<h5 className="p-4">Notes</h5>
			<div className="ps-4">
				<Element
					onScroll={() => endMessage()}
					name="scrollMessage"
					className="element"
					id="containerMessage"
					style={{
						position: 'relative',
						maxHeight: '700px',
						overflow: 'auto',
						overflowX: 'hidden',
						marginBottom: '20px',
						paddingRight: '15px'
					}}>
					<div
						className={notesEvents.length <= 0 ? 'd-flex justify-content-center align-items-center' : 'd-flex flex-column align-items-center gap-1'}
						style={{ width: '100%', height: '50vh' }}>
						<MessageList
							notesEvents={notesEvents}
							sendEmail={true}
						/>
						{noMoreNotes && (
							<div className="d-flex justify-content-center align-items-center p-4">
								<label style={{ color: '#6c757d' }}>No more notes to show</label>
							</div>
						)}
					</div>
				</Element>
				<div
					className="d-flex flex-row justify-content-center align-items-center"
					style={{ marginBottom: '40px', gap: '16px' }}>
					<InputContainer
						inputType={'textarea'}
						inputDisabled={false}
						classNameInput={'form-control'}
						placeholder={'Add notes here'}
						inputStyle={{ minHeight: '52px' }}
						value={message}
						labelClassName="d-none"
						divClass="w-100"
						setValue={(value) => setMessage(value)}
						// maxLength={maxLength}
					/>
					<div className="d-flex align-items-center justify-content-end">
						<button
							type="button"
							onClick={() => save()}
							disabled={message === '' ? true : false}
							className={`send-email-btn ${message !== '' ? 'btn-send' : 'btn-not-send'}`}
							style={{ width: '52px', height: '52px' }}>
							<FiSend style={{ fontSize: '20px' }} />
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
