import React from 'react';
import { inject, observer } from 'mobx-react';
import { RecordsNotFound } from '../../../components/RecordsNotFound';
import { BiNotepad } from 'react-icons/bi';
import 'react-datepicker/dist/react-datepicker.css';
import { RowActions } from './RowActions';
import { NameAvatar } from '../../../components/NameAvatar';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiSortAlt2 } from 'react-icons/bi';

@inject('store', 'global', 'reports')
@observer
class Table extends React.Component {
  constructor(props) {
    super(props);

    this.viewRecordDetails = this.viewRecordDetails.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.selectAllFilters = this.selectAllFilters.bind(this);
  }

  componentDidMount() {}

  selectAllFilters(containerId){
    let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
    let checkBoxes = menuContainer.getElementsByTagName('input');
    for(var i = 0; i < checkBoxes.length; i++) {
        if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === false) {
            checkBoxes[i].click();
        }
    }
  }
  
  clearFilter(e, filter, filterType){
    e.preventDefault();
    this.setState({ [filterType]: {} });
    this.props.handleFilter(filter,"");
  }

  viewRecordDetails(index, object){
    if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
      return;
    }
    if(object?.target?.id !== "actionsMenuButton"){
        let thisObj = this;
        if(thisObj.props.global.rightPanelOpen && thisObj.props.reports.recordDetails !== index){
            thisObj.props.global.setRightPanelOpen(false);
            thisObj.props.reports.setRecordDetails(index);
            setTimeout(function(){
                thisObj.props.global.setRightPanelOpen(true);
            }, 500)                
        }else{
            thisObj.props.reports.setRecordDetails(index);
            thisObj.props.global.setRightPanelOpen(true);
        }
    }
  }

  render() {
    return (
      <>
        <table className="table table-hover table-striped">
          {/* HEAD and FILTERS */}
          <thead>
            <tr>
              {this.props.reports.getHeaders.map((record, i) =>
                  (Object.entries(this.props.getMappingFields()).flat(1).includes(record[0]) &&
                  record[1].display && 
                  (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.props.sortBy(e, record)}/>
                  </th> ))
                  ||
                  (record[1].display && 
                  (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                  </th> ))                 
              )}
              <th className='text-center sticky-row' scope="col">
                Actions
              </th>
            </tr>
          </thead>

          {/* BODY */}
          <tbody className="report-invoices">
            {this.props.reports.records.map((record, i) => (
              <tr key={i} className="cursorPointer" onClick={(e) => this.viewRecordDetails(i,e)} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} data-qaid={"invoicesMoneyInRow-"+(i)}>
                {this.props.reports.headers.Customer && this.props.reports.headers.Customer.display && (
                  <td className='avatarTd'>
                    {record && record.firstName ? (
                      <>
                      <NameAvatar text={record.firstName + " " + record.lastName}/>
                      {this.props.global.capitalizeFirstLetterOfEachWord(record.firstName + " " + record.lastName)}
                      </>
                    ) : (
                      '-'
                    )}
                  </td>
                )}
                {this.props.reports.headers.Company && this.props.reports.headers.Company.display && (
                  <td>{record && record.company ? this.props.global.capitalizeFirstLetterOfEachWord(record.company) : '-'}</td>
                )}
                { (this.props.reports.headers.ParentOrgName && this.props.reports.headers.ParentOrgName.display) &&
                  <td>{record && record.ParentOrgName ? this.props.global.capitalizeFirstLetterOfEachWord(record.ParentOrgName): '-'}</td>
                }                
                { (this.props.reports.headers.PaypointLegalname && this.props.reports.headers.PaypointLegalname.display) &&
                  <td>{record && record.PaypointLegalname ? this.props.global.capitalizeFirstLetterOfEachWord(record.PaypointLegalname): '-'}</td>
                }
                { (this.props.reports.headers.PaypointDbaname && this.props.reports.headers.PaypointDbaname.display) &&
                  <td>{record && record.PaypointDbaname ? this.props.global.capitalizeFirstLetterOfEachWord(record.PaypointDbaname): '-'}</td>
                }
                { (this.props.reports.headers.ExternalPaypointID && this.props.reports.headers.ExternalPaypointID.display) &&
                    <td>{record && record.externalPaypointID ? record.externalPaypointID : '-'}</td>
                }
                { (this.props.reports.headers.CustomerNumber && this.props.reports.headers.CustomerNumber.display) &&
                  <td>{record.Customer && record.Customer.CustomerNumber ? record.Customer.CustomerNumber.length > 15 ? record.Customer.CustomerNumber.substring(0, 15) + "..." : record.Customer.CustomerNumber : '-'} </td>
                }
                {(this.props.reports.headers.BillingEmail && this.props.reports.headers.BillingEmail.display) &&
                  <td>{record.Customer && record.Customer.BillingEmail ? record.Customer.BillingEmail : '-'} </td>
                }                
                {(this.props.reports.headers.PayorPhone && this.props.reports.headers.PayorPhone.display) &&
                  <td>{record.Customer && record.Customer.BillingPhone ? this.props.global.phoneNumberFormat(record.Customer.BillingPhone): '-'} </td>
                }                
                {(this.props.reports.headers.BillingAddress && this.props.reports.headers.BillingAddress.display) &&
                  <td>{record.Customer && record.Customer.BillingAddress1 ? (record.Customer.BillingAddress1?record.Customer.BillingAddress1: " "+ record.Customer.BillingAddress2?record.Customer.BillingAddress2: " "+ record.Customer.BillingCity?record.Customer.BillingCity: " "+ record.Customer.BillingState?record.Customer.BillingState: " "+ record.Customer.BillingZip?record.Customer.BillingZip: " "+ record.Customer.BillingCountry?record.Customer.BillingCountry:'') : '-'} </td>
                }                
                {(this.props.reports.headers.ShippingAddress && this.props.reports.headers.ShippingAddress.display) &&
                  <td>{record.Customer && record.Customer.ShippingAddress1 ? (record.Customer.ShippingAddress1?record.Customer.ShippingAddress1: " "+ record.Customer.ShippingAddress2?record.Customer.ShippingAddress2: " "+ record.Customer.ShippingCity?record.Customer.ShippingCity: " "+ record.Customer.ShippingState?record.Customer.ShippingState: " "+ record.Customer.ShippingZip?record.Customer.ShippingZip: " "+ record.Customer.ShippingCountry?record.Customer.ShippingCountry:""): '-'} </td>
                }                
                {this.props.reports.headers.InvoiceNumber && this.props.reports.headers.InvoiceNumber.display && (
                  <td>{record && record.invoiceNumber ? record.invoiceNumber : '-'}</td>
                )}
                {this.props.reports.headers.Frequency && this.props.reports.headers.Frequency.display && (
                  <td>{record && record.frequency ? this.props.global.frequencyText(record.frequency) : '-'}</td>
                )}
                {this.props.reports.headers.PaymentTerms && this.props.reports.headers.PaymentTerms.display && (
                  <td>{record && record.paymentTerms ? record.paymentTerms : '-'}</td>
                )}
                {this.props.reports.headers.CreatedDate && this.props.reports.headers.CreatedDate.display && (
                  <td>{this.props.global.stringDateFormatV3(record.createdAt)}</td>
                )}
                {this.props.reports.headers.DueDate && this.props.reports.headers.DueDate.display && (
                  <td>{this.props.global.stringDateFormatV3(record.invoiceDueDate)}</td>
                )}                
                {this.props.reports.headers.LastPaymentDate && this.props.reports.headers.LastPaymentDate.display && (
                  <td>{record.lastPaymentDate ? this.props.global.stringDateFormatV3(record.lastPaymentDate) : "-"}</td>
                )}
                {this.props.reports.headers.Status && this.props.reports.headers.Status.display && (
                  <td>{record && this.props.global.getInvoicesStatus(record.invoiceStatus, record.invoiceDueDate)}</td>
                )}
                {this.props.reports.headers.PaidAmount && this.props.reports.headers.PaidAmount.display && (
                  <td className='text-right'>${this.props.global.numberWithCommas(parseFloat(record.invoicePaidAmount).toFixed(2))}</td>
                )}                
                {this.props.reports.headers.Amount && this.props.reports.headers.Amount.display && (
                  <td className='text-right'>${this.props.global.numberWithCommas(parseFloat(record.invoiceAmount).toFixed(2))}</td>
                )}
                { (this.props.reports.headers.Notes && this.props.reports.headers.Notes.display) && 
                  <td className='text-center'>
                      {record.notes ? 
                          <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{record.notes}</Tooltip>}
                          >
                          <BiNotepad className="label-info-icon in-input"/>
                          </OverlayTrigger>
                      : '-'}
                  </td>
                }
                <td className='text-center sticky-row'>
                  <RowActions
                    status={record.invoiceStatus}
                    toggleModalDelete={this.props.toggleModalDelete}
                    toggleModalMarkPaid={this.props.toggleModalMarkPaid}
                    handleSendInvoice={this.props.handleSendInvoice}
                    record={record}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.props.reports.records.length < 1 && (
          <RecordsNotFound
            message="No invoices yet"
            description={
              <span>
                When you add invoice, they will show up here.
                <br /> you can track their status and drafts here.
              </span>
            }
          />
        )}
      </>
    );
  }
}

export { Table };
