import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { SettingsLinks } from '../../components/SettingsLinks';
import { TopBar } from '../../components/TopBar';

@inject('global')
@observer
class Developers extends React.Component {

    render() {
        return (
            <Layout {...this.props}>
            <div>
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <SettingsLinks selected="developers"/>
                </div>
                </TopBar>
                
                <div className="mt-body4">
                <h5 className="mb-3">Developers</h5>


                </div>
            </div>
            </Layout>
        )
    }
}

export { Developers };