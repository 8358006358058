import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";

@inject('reports', 'filter')
@observer
class ViewPartialTemplates extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="orgParentname" id="orgParentname" className={"form-control"} placeholder="Organization" onChange={(e) => this.props.filter.handleCaptureTextFilter('orgParentname(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('orgParentname(ct)', e)} value={this.props.filter.filterText.orgParentname ? this.props.filter.filterText.orgParentname : ''} />
                            <label htmlFor="orgParentname">Organization</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="title" id="title" className={"form-control"} placeholder="Title" onChange={(e) => this.props.filter.handleCaptureTextFilter('title(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('title(ct)', e)} value={this.props.filter.filterText.title ? this.props.filter.filterText.title : ''} data-qaid="inputTitleFilterTemplates" />
                            <label htmlFor="title">Title</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="description" id="description" className={"form-control"} placeholder="Description" onChange={(e) => this.props.filter.handleCaptureTextFilter('description(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('description(ct)', e)} value={this.props.filter.filterText.description ? this.props.filter.filterText.description : ''} />
                            <label htmlFor="description">Description</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="code" id="code" className={"form-control"} placeholder="Code" onChange={(e) => this.props.filter.handleCaptureTextFilter('code(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('code(ct)', e)} value={this.props.filter.filterText.code ? this.props.filter.filterText.code : ''} />
                            <label htmlFor="code">Code</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating input-number">
                            <input name="usedByLink" id="usedByLink" type='number' className={"form-control"} placeholder="Used by Link" onChange={(e) => this.props.filter.handleCaptureTextFilter('usedByLink(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('usedByLink(ct)', e)} value={this.props.filter.filterText.usedByLink ? this.props.filter.filterText.usedByLink : ''} />
                            <label htmlFor="usedByLink">Used by Link</label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p className='mb-1'><small>Created At</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="createdAtCalendar" 
                                        name="createdAtCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startCreatedAt ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startCreatedAt) + ' → ' : '') + (this.props.filter.stateDate.endCreatedAt ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endCreatedAt) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="createdAtCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startCreatedAt ? this.props.filter.stateDate.startCreatedAt : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startCreatedAt', 'endCreatedAt', 'createdAt')}
                            startDate={this.props.filter.stateDate.startCreatedAt}
                            endDate={this.props.filter.stateDate.endCreatedAt}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endCreatedAt !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p className='mb-1'><small>Is Root?</small></p>
                        <div className="form-group mt-1" style={{fontSize: '20px', display: 'inline-flex'}}>
                            <div className="icheck-primary mr-3">
                                <input type="radio" name="inlineRadioIsRoot" id="inlineRadioIsRoot" value="1" checked={this.props.filter.filterRadioOption.inlineRadioIsRoot ? true : false} onChange={(e) => this.props.filter.handleRadioOptionIsRoot(e, 'isRoot')} />
                                <label htmlFor="inlineRadioIsRoot">Yes</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="radio" name="inlineRadioIsNotRoot" id="inlineRadioIsNotRoot" value="0" checked={this.props.filter.filterRadioOption.inlineRadioIsNotRoot ? true : false} onChange={(e) => this.props.filter.handleRadioOptionIsRoot(e, 'isRoot')} />
                                <label htmlFor="inlineRadioIsNotRoot">No</label>
                            </div>
                        </div>
                    </div>
                </div>
        </>);
    }
}

export { ViewPartialTemplates };