import { action } from 'mobx'

export default function ProcessingFunction({ processing, setProcessing, paypointCurrency, setPaypointCurrency }) {
	const handleChangeProcessing = action((field, value) => {
		setProcessing({ ...processing, [field]: value })
	})
	const handleChangeCurrency = action((value) => {
		setPaypointCurrency({ ...paypointCurrency, value: value })
	})
	const addNewCustomField = action((name, hsColumn, formula) => {
		const customFields = processing.customFields
		customFields.push({
			name: name,
			hsColumn: hsColumn,
			formula: formula
		})
		setProcessing({ ...processing, customFields: customFields })
	})
	const removeCustomField = action((index) => {
		const { customFields } = processing
		customFields.splice(index, 1)
		setProcessing({ ...processing, customFields: customFields })
	})
	const handlerChangeCustomField = action((index, field, value) => {
		const { customFields } = processing
		customFields[index][field] = value
		setProcessing({ ...processing, customFields: customFields })
	})
	return {
		handleChangeProcessing,
		handleChangeCurrency,
		addNewCustomField,
		removeCustomField,
		handlerChangeCustomField
	}
}
