import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

@inject('vTerminal', 'global', 'reports')
@observer
class AutopayForm extends React.Component {

    constructor(props) {
        super(props);
        this.handleAutopayStartDate = this.handleAutopayStartDate.bind(this);
        this.handleAutopayEndDate = this.handleAutopayEndDate.bind(this);
        this.handleAutopayFrequency = this.handleAutopayFrequency.bind(this);
        this.handleAutopayEndDateAction = this.handleAutopayEndDateAction.bind(this);
        this.getOptionsFrequency = this.getOptionsFrequency.bind(this);
        this.getCountDaysByFrequency = this.getCountDaysByFrequency.bind(this);
        this.renderFrequencyOptions = this.renderFrequencyOptions.bind(this);
        this.endDateAutopayCalendar = React.createRef();
    }

    handleAutopayStartDate(date){
        this.props.vTerminal.handleAutopayStartDate(date)
        this.handleAutopayEndDate(null)
    }

    handleAutopayEndDate(date){
        this.props.vTerminal.handleAutopayEndDate(date);
    }

    handleAutopayFrequency(e){
        this.props.vTerminal.handleAutopayFrequency(e.target.value)
        this.handleAutopayEndDate(null)
    }

    handleAutopayEndDateAction(value){
        this.props.vTerminal.handleAutopayEndDateAction(value);
        this.endDateAutopayCalendar.current.setOpen(false)
    }

    getOptionsFrequency(){
        return [
            { key: 'onetime', value: 'onetime', label: 'Onetime' },
            { key: 'weekly', value: 'weekly', label: 'Weekly', days: 7 },
            { key: 'every2Weeks', value: 'every2weeks', label: 'Every 2 weeks', days: 14 },
            { key: 'monthly', value: 'monthly', label: 'Monthly', days: 30 },
            { key: 'every3Months', value: 'every3months', label: 'Every 3 months', days: 90 },
            { key: 'every6Months', value: 'every6months', label: 'Every 6 months', days: 180 },
            { key: 'annually', value: 'annually', label: 'Annually', qaid: 'annuallyAutopayOption', days: 365 },
        ];
    }

    getCountDaysByFrequency(){
        if(this.props.vTerminal.paymentPage.autopay.frequencySelected){
            const arrFrequency = this.getOptionsFrequency().filter(option => option.value === this.props.vTerminal.paymentPage.autopay.frequencySelected);
            if (arrFrequency?.length > 0) {
                return arrFrequency[0].days || 1;
            }
        }
        return 1;
    }

    renderFrequencyOptions() {
        const frequencyData = this.props.vTerminal.paymentPage.autopay.frequency;
        const options = this.getOptionsFrequency();    
        return options.map(option => {
            const { key, value, label, qaid } = option;
            return frequencyData[key] && (
                <option key={value} value={value} data-qaid={qaid}>
                    {label}
                </option>
            );
        });
    }

    render() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        let nextTwoDays = new Date();
        nextTwoDays.setDate(nextTwoDays.getDate() + 2);
        if(this.props.vTerminal.paymentPage.autopay.startDate){
            const days = parseInt(this.getCountDaysByFrequency());
            nextTwoDays = new Date(this.props.vTerminal.paymentPage.autopay.startDate);
            nextTwoDays.setDate(nextTwoDays.getDate() + days);
        }

        const years = [];
        const yearsEndDate = [];
        const months = this.props.global.getMonths();
        let year = new Date().getFullYear();
        for (let i = year; i <= year + 2; i++) {
            years.push(i);
        }
        for (let i = year; i <= year + 20; i++) {
            yearsEndDate.push(i);
        }

        return (
            <div className="row">
                <div className="col-sm mb-3">
                <DatePicker
                        customInput={
                            <div className="form-floating">
                                <input readOnly autoComplete="off" onChange={function () { }} value={this.props.vTerminal.paymentPage.autopay.startDate ? this.props.reports.dateStringFormat(this.props.vTerminal.paymentPage.autopay.startDate) : ''}  name="startDateAutopayCalendar" id="startDateAutopayCalendar"  className={this.props.vTerminal.getPaymentPageErrors.autopayStartDateError ? "form-control input-calendar input-error input-lg" : "form-control input-calendar input-lg" } placeholder="Start Date" data-qaid="startDateAutopayMoneyIn"/>
                                <label htmlFor="startDateAutopayCalendar">{this.props.vTerminal.paymentPage.autopay.frequencySelected !== 'onetime' ? "Start Date" : "Pay Date"}</label>
                            </div>
                        }
                        selected={this.props.vTerminal.paymentPage.autopay.startDate ? this.props.vTerminal.paymentPage.autopay.startDate : ''}
                        onChange={date => this.handleAutopayStartDate(date)}
                        minDate={tomorrow}
                        dayClassName={date => "calendar-day"}
                        popperPlacement="bottom-start"
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    {"<"}
                                </button>
                                <select
                                    value={date.getFullYear()}
                                    onChange={({ target: { value } }) => changeYear(value)}
                                    data-qaid="yearInputAutopayMoneyIn"
                                >
                                    {years.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    value={months[date.getMonth()]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                    data-qaid="monthInputAutopayMoneyIn"
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    {">"}
                                </button>
                            </div>
                        )}
                    />
                </div>
                <div className="col-sm">
                    <div className="form-floating mb-3">
                        <select
                            value={this.props.vTerminal.paymentPage.autopay.frequencySelected || ''}
                            onChange={(e) => this.handleAutopayFrequency(e)}
                            className={this.props.vTerminal.getPaymentPageErrors.autopayFrequencyError ? "form-select form-control input-error" : "form-select form-control"}
                            id="floatingSelectGrid"
                            aria-label="Frequency"
                            data-qaid="frequencyAutopayMoneyIn"
                        >
                            <option defaultValue="select" value="">Select...</option>
                            {this.renderFrequencyOptions()}
                        </select>
                        <label htmlFor="floatingSelectGrid">Frequency</label>
                    </div>
                </div>
                {this.props.vTerminal.paymentPage.autopay.frequencySelected !== 'onetime' &&
                <div className="col-sm">
                <DatePicker
                        customInput={
                            <div className="form-floating">
                                <input readOnly autoComplete="off" onChange={function () { }} 
                                    value={
                                            this.props.vTerminal.paymentPage.autopay.finishSelected && this.props.vTerminal.paymentPage.autopay.finishSelected !== 'untilcancelled' ?
                                            this.props.reports.dateStringFormat(new Date(this.props.vTerminal.paymentPage.autopay.finishSelected)) : 
                                            this.props.vTerminal.paymentPage.autopay.finishSelected === 'untilcancelled' ? "Until Cancelled" : ''}  
                                    name="endDateAutopayCalendar" id="endDateAutopayCalendar" className={this.props.vTerminal.getPaymentPageErrors.autopayFinishError ? "form-control input-calendar input-error input-lg" : "form-control input-calendar input-lg" } placeholder="End Date" data-qaid="endDateAutopayMoneyIn"/>
                                <label htmlFor="endDateAutopayCalendar">End Date</label>
                            </div>
                        }
                        selected={ this.props.vTerminal.paymentPage.autopay.finishSelected && this.props.vTerminal.paymentPage.autopay.finishSelected instanceof Date ? this.props.vTerminal.paymentPage.autopay.finishSelected : ''}
                        onChange={date => this.handleAutopayEndDate(date)}
                        minDate={nextTwoDays}
                        ref={this.endDateAutopayCalendar}
                        dayClassName={date => "calendar-day"}
                        popperPlacement="bottom-end"
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    {"<"}
                                </button>
                                <select
                                    value={date.getFullYear()}
                                    onChange={({ target: { value } }) => changeYear(value)}
                                >
                                    {yearsEndDate.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    value={months[date.getMonth()]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    {">"}
                                </button>
                            </div>
                        )}
                        >
                        <div className="linkCalendar" onClick={(e)=>this.handleAutopayEndDateAction('untilcancelled')} data-qaid="untilCancelledAutopayMoneyIn">Until Cancelled</div>
                        </DatePicker>

                </div>
                }
            </div>
        );
    }
}

export { AutopayForm };