import React, { useContext } from 'react'
import Switch from 'react-switch'
import GroupRow from '../../../../../components/templates/GroupRow'
import templatesContext from '../../context/templates_context'

export default function MgmFees() {
	const { RootTemplate, LoadingState, ServicesTemplate, handleChangeService, handleChangeFees } = useContext(templatesContext)
	return (
		(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.visible) && (
			<>
				<div className="mb-2 mt-4">
					<label className="d-flex flex-row align-items-center">
						<Switch
							onChange={(value) => handleChangeFees('mgm', 'mgmFees', value)}
							checked={!LoadingState && ServicesTemplate.mgm?.mgmFees.visible}
							onColor="#10A0E3"
							onHandleColor="#ffffff"
							handleDiameter={20}
							boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
							activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
							height={20}
							width={38}
							className="react-switch"
							id={'mgmFees'}
						/>
						<span className="ml-2 small">Managed Payables Fees</span>
					</label>
				</div>

				<div className="row">
					{RootTemplate.isRoot ? (
						<>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="returnedMgmFee"
									inputType={{
										title: 'Returned Managed Payables Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="monthlyMgmFee"
									inputType={{
										title: 'Monthly Platform Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="verifyBankMgmFee"
									inputType={{
										title: 'Verify Bank Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="verifyFundMgmFee"
									inputType={{
										title: 'Verify Fund Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="sameDayMgmFee"
									inputType={{
										title: 'Same Day Managed Payables',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="quarterlyPCIMgmFee"
									inputType={{
										title: 'Quarterly PCI',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="chargebackMgmFee"
									inputType={{
										title: 'ChargeBack Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="annualMgmFee"
									inputType={{
										title: 'Annual Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="verifyNegativeMgmFee"
									inputType={{
										title: 'Verify Negative Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="sundayOriginationMgmFee"
									inputType={{
										title: 'Sunday Origination',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="advancedSettlementMgmFee"
									inputType={{
										title: 'Advanced Settlement',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.mgm.mgmFees}
									parentField={RootTemplate.rootTemplate.servicesData.mgm?.mgmFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('mgm', 'mgmFees', property, field, values)}
									field="earlyTerminationMgmFee"
									inputType={{
										title: 'Early Termination Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
						</>
					) : (
						<table className="table">
							<tbody>
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.returnedMgmFee.visible && (
									<tr>
										<td>Returned Managed Payables Fee</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.returnedMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.chargebackMgmFee.visible && (
									<tr>
										<td>ChargeBack Fee</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.chargebackMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.monthlyMgmFee.visible && (
									<tr>
										<td>Monthly Platform Fee</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.monthlyMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.annualMgmFee.visible && (
									<tr>
										<td>Annual Fee</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.annualMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.verifyBankMgmFee.visible && (
									<tr>
										<td>Verify Bank Fee</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.verifyBankMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.verifyNegativeMgmFee.visible && (
									<tr>
										<td>Verify Negative Fee</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.verifyNegativeMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.verifyFundMgmFee.visible && (
									<tr>
										<td>Verify Fund Fee</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.verifyFundMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.sundayOriginationMgmFee.visible && (
									<tr>
										<td>Sunday Origination</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.sundayOriginationMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.sameDayMgmFee.visible && (
									<tr>
										<td>Same Day Managed Payables</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.sameDayMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.advancedSettlementMgmFee.visible && (
									<tr>
										<td>Advanced Settlement</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.advancedSettlementMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.quarterlyPCIMgmFee.visible && (
									<tr>
										<td>Quarterly PCI</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.quarterlyPCIMgmFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.mgm?.mgmFees.earlyTerminationMgmFee.visible && (
									<tr>
										<td>Early Termination Fee</td>
										<td>{!LoadingState && ServicesTemplate.mgm?.mgmFees.earlyTerminationMgmFee.value}</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
				</div>
			</>
		)
	)
}
