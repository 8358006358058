import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('reports', 'filter')
@observer
class RenderFrequency extends React.Component {

    render() {
        return (<>
                <p className='mb-1'><small>Frequency</small></p>
                <div className='row mb-3 section-frequency'>
                    <div className='col-sm-4 col-xs-4'>
                        <div className='form-group'>
                            <div className="icheck-primary">
                                <input type="checkbox" value="onetime" id="frequencyCheckOneTime" checked={this.props.filter.filterCheck.frequencyCheckOneTime} onChange={(e) => this.props.filter.handleCheck(e, 'frequency(in)')} />
                                <label htmlFor="frequencyCheckOneTime">One Time</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="checkbox" value="weekly" id="frequencyCheckWeekly" checked={this.props.filter.filterCheck.frequencyCheckWeekly} onChange={(e) => this.props.filter.handleCheck(e, 'frequency(in)')} />
                                <label htmlFor="frequencyCheckWeekly">Weekly</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="checkbox" value="every2weeks" id="frequencyCheckEvery2Weeks" checked={this.props.filter.filterCheck.frequencyCheckEvery2Weeks} onChange={(e) => this.props.filter.handleCheck(e, 'frequency(in)')} />
                                <label htmlFor="frequencyCheckEvery2Weeks">Every 2 weeks</label>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4 col-xs-4'>
                        <div className='form-group'>
                            <div className="icheck-primary">
                                <input type="checkbox" value="monthly" id="frequencyCheckMonthly" checked={this.props.filter.filterCheck.frequencyCheckMonthly} onChange={(e) => this.props.filter.handleCheck(e, 'frequency(in)')} />
                                <label htmlFor="frequencyCheckMonthly">Monthly</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="checkbox" value="every3months" id="frequencyCheckEvery3Months" checked={this.props.filter.filterCheck.frequencyCheckEvery3Months} onChange={(e) => this.props.filter.handleCheck(e, 'frequency(in)')} />
                                <label htmlFor="frequencyCheckEvery3Months">Every 3 months</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="checkbox" value="every6months" id="frequencyCheckEvery6Months" checked={this.props.filter.filterCheck.frequencyCheckEvery6Months} onChange={(e) => this.props.filter.handleCheck(e, 'frequency(in)')} />
                                <label htmlFor="frequencyCheckEvery6Months">Every 6 months</label>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4 col-xs-4'>
                        <div className='form-group'>
                            <div className="icheck-primary">
                                <input type="checkbox" value="annually" id="frequencyCheckAnnually" checked={this.props.filter.filterCheck.frequencyCheckAnnually} onChange={(e) => this.props.filter.handleCheck(e, 'frequency(in)')} />
                                <label htmlFor="frequencyCheckAnnually" data-qaid="annuallyCheckboxAutopay">Annually</label>
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}

export { RenderFrequency };