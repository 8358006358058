import React from 'react'
import { MdOutlineCallSplit } from 'react-icons/md'
import GlobalStore from '../store/GlobalStore'

export default function QuickViewSplitFundingInformation({ splitFundingInstructions }) {
	return (
		<div className="mb-5">
			<h5 className="header mb-3">Split Funding Information</h5>
			{splitFundingInstructions.map((funding, i) => (
				<div
					className="mb-4"
					key={`splitFundingInstruct ${i}`}>
					<div className="row mb-2">
						<div className="col-12">
							<div className="d-flex align-items-center flex-row flex-nowrap gap-1">
								<MdOutlineCallSplit style={{ color: '#7B61FF', transform: 'rotate(90deg)', width: '16px', height: '16px' }} />
								<span style={{ fontWeight: '400', fontSize: '10px' }}>{`SPLIT ${i + 1}`}</span>
							</div>
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-4">
							<label style={{ fontWeight: '600', fontSize: '13px' }}>Entry Name</label>
						</div>
						<div className="col-8">
							<label style={{ fontWeight: '400', fontSize: '12px' }}>{funding.recipientEntryPoint}</label>
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-4">
							<label style={{ fontWeight: '600', fontSize: '13px' }}>Account ID</label>
						</div>
						<div className="col-8">
							<label style={{ fontWeight: '400', fontSize: '12px' }}>{funding.AccountId}</label>
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-4">
							<label style={{ fontWeight: '600', fontSize: '13px' }}>Amount</label>
						</div>
						<div className="col-8">
							<label style={{ fontWeight: '700', fontSize: '12px' }}>{GlobalStore.numberWithCommasNegative(funding.Amount.toFixed(2))}</label>
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-4">
							<label style={{ fontWeight: '600', fontSize: '13px' }}>Description</label>
						</div>
						<div className="col-8">
							<label style={{ fontWeight: '400', fontSize: '12px' }}>{funding.Description}</label>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}
