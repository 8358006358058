import React from 'react';
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { inject, observer } from 'mobx-react';
import {IMaskInput} from 'react-imask';
import {BiInfoCircle} from '@react-icons/all-files/bi/BiInfoCircle';
import NumberFormat from 'react-number-format';

@inject('boarding','global')
@observer
class InputSimpleLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            validation: 'text', 
            value:'', 
            cname:'', 
            error:false,
            isNewScreen: true,
            ignoreEmpty: false,
            mouseIn: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.getValidationMessage = this.getValidationMessage.bind(this);
        this.focus = this.focus.bind(this);

    }

    mouseIn(){
        this.setState({
            mouseIn : true
        });
    }

    mouseOut(){
        this.setState({
            mouseIn : false
        });
    }

    handleChange(name, value) {
        this.props.boarding.setTemplateData(name, value);
        let validators = this.props.global.validators;
        if(this.props.iMask === 'percentage'){
            if(parseFloat(value, 10) > 100){
                value = value.substr(0, value.split('.')[0].length - 1);
            }
        }
        
        this.setState({
            error: (validators.stringValidator(this.state.validation, value) || (this.props.iRequired && validators.isEmpty(value))) ? true : false, value: value, isNewScreen: false
    }, function() {
            this.props.boarding.setErrorData(this.state.cname, this.state.error);
            this.props.boarding.setTemplateData(name, value);
            if(this.props.iChangeCallback && this.props.iKey !== null && this.props.iKey !== undefined && this.props.iField){
                this.props.iChangeCallback(this.props.iKey,value, this.props.iField);
            }
        });
    }

    // componentWillUnmount() { 
    //     this.props.boarding.setErrorData(this.state.cname, false);
    // }

    componentDidMount() {
        let validators = this.props.global.validators;
        let ignoreEmpty = false;
        if (this.props.ignoreEmpty) {
            ignoreEmpty = this.props.ignoreEmpty;
        }
        this.setState({ validation: this.props.iValidator, value: this.props.iValue, cname: this.props.iName, error: (validators.stringValidator(this.props.iValidator, this.props.iValue) || (this.props.iRequired && validators.isEmpty(this.props.iValue))) ? true : false, ignoreEmpty: ignoreEmpty }, function () {
            this.props.boarding.setTemplateData(this.state.cname, this.state.value);
            this.props.boarding.setErrorData(this.state.cname, this.state.error);
        });
    }

    componentDidUpdate(prevProps, prevState) {
 
        if (this.props.iValue !== prevProps.iValue ) {
            let validators = this.props.global.validators;
            this.setState({ value: this.props.iValue, error: (validators.stringValidator(this.state.validation, this.props.iValue) || (this.props.iRequired && validators.isEmpty(this.props.iValue)))?true:false }, function () {
                this.props.boarding.setTemplateData(this.state.cname, this.state.value);
                this.props.boarding.setErrorData(this.state.cname, this.state.error);
            });
        }
    }

    getValidationMessage(validation){
        console.log(validation);
        switch(validation){
            case "alpha":
                return "Field is incomplete or letters only"
            case "alphanumeric":
                return "Field is incomplete or alphanumeric only"
            case "numbers":
                return "Field is incomplete or numbers only"
            case "text":
                return "Field is incomplete"
            case 'email':
                return "Requires valid email"
            case "phone":
                return "Requires valid phone #"
            case "ein":
                return "Requires valid EIN"
            case "card":
                return "Requires valid Card #"
            case 'routing':
                return "Requires valid Routing #"
            case 'exp':
                return "Requires valid expiration date"
            case 'cvvamex':
                return "Requires 4 digit CVV"
            case 'cvv':
                return "Requires 3 digit CVV"
            case 'zipcode':
                return "Requires 5 digit Zip Code"
            case 'url':
                return "Requires a valid URL"
            case 'ssn':
                return "Requires valid SSN"
            default:
                return "Requires a valid value"
        }
    }

    focus(e){
        e.target.focus();
        e.target.setSelectionRange(0, 1000000000);
    }

    renderField(){
        let nextClicked = this.props.boarding.nextClicked;

        return (
            <>
            <IMaskInput
                mask={this.props.iMask ? this.props.global.maskValidator(this.props.iMask) : String}
                name={this.props.iName}
                value={ this.state.value ? String(this.state.value) : "" }
                autoComplete="off"
                defaultValue="0"
                type= {this.props.iHide ? "password" : "text"}
                unmask={true}
                onAccept={
                    (value, mask) => this.handleChange(this.props.iName, value)
                }
                placeholder={this.props.iTitle}
                className={(this.state.error && !this.state.isNewScreen) ||  (this.state.error && nextClicked) ? "form-control input-error " + this.props.iClass : "form-control "+ this.props.iClass}
                id={this.props.iName}
                onMouseEnter={(e) => this.mouseIn()}
                onMouseLeave={(e) => this.mouseOut()}
            />
            {/* 
                Add Button in the bottom of the input field to execute any action on click it.
                iLinkClick: function to execute on click
                iLinkText: text to display on button
            */}
            {this.props.iLink &&
            ( !this.props.iLinkInvisible ?
                <button 
                className="text-muted"
                style={{
                    backgroundColor: "#fff",
                    cursor: "pointer",
                    fontSize: "10px",
                    textDecoration: "underline",
                    border: "none"
                }} onClick={(e) => this.props.iLinkClick(e)}>{this.props.iLinkText}</button>
                : 
                <div 
                className="hidden"
                style={{
                    backgroundColor: "#fff",
                    height: "21px",
                    border: "none"
                }}/>
            )
            }   
            { (this.state.error && this.state.mouseIn && !this.state.isNewScreen )&& 
                <div className="tooltip-input-error">{this.getValidationMessage(this.state.validation)}</div>
            }
            </>
        )
    }

    renderMoneyField(){
        let nextClicked = this.props.boarding.nextClicked;
        return (
            <>
             <NumberFormat
                onFocus={(e)=>this.focus(e)}
                onMouseEnter={(e) => this.mouseIn()}
                onMouseLeave={(e) => this.mouseOut()}

                thousandsGroupStyle="thousand"
                prefix="$"
                decimalSeparator="."
                displayType="input"
                type= {this.props.iHide ? "password" : "text"}
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}

                autoComplete="off"
                id={this.props.iName}
                name={this.props.iName}
                value={ this.state.value ? String(this.state.value) : "0" }
                placeholder={this.props.iTitle}
                className={(this.state.error && !this.state.isNewScreen) ||  (this.state.error && nextClicked) ? "form-control input-error " + this.props.iClass : "form-control "+ this.props.iClass}
                onValueChange={(values) => this.handleChange(this.props.iName, values.value)}
            />
             { (this.state.error && this.state.mouseIn && !this.state.isNewScreen )&& 
                <div className="tooltip-input-error">{this.getValidationMessage(this.state.validation)}</div>
            }
            </>
        )
    }

    renderPercentageField(){
        let nextClicked = this.props.boarding.nextClicked;
        return (
            <>
             <NumberFormat
                onFocus={(e)=>this.focus(e)}
                onMouseEnter={(e) => this.mouseIn()}
                onMouseLeave={(e) => this.mouseOut()}
                defaultValue="0"
                thousandsGroupStyle="thousand"
                suffix="%"
                decimalSeparator="."
                displayType="input"
                type= "text"
                thousandSeparator={false}
                allowNegative={false}
                decimalScale={this.props.decimalScale || false}
                fixedDecimalScale={this.props.fixedDecimalScale || false}
                
                autoComplete="off"
                id={this.props.iName}
                name={this.props.iName}
                value={ this.state.value ? String(this.state.value) : "0" }
                placeholder={this.props.iTitle}
                className={(this.state.error && !this.state.isNewScreen) ||  (this.state.error && nextClicked) ? "form-control input-error " + this.props.iClass : "form-control "+ this.props.iClass}
                onValueChange={(values) => this.handleChange(this.props.iName, values.value)}
            />
             { (this.state.error && this.state.mouseIn && !this.state.isNewScreen )&& 
                <div className="tooltip-input-error">{this.getValidationMessage(this.state.validation)}</div>
            }
            </>
        )
    }
    

    render() {
        return (
            <Col style={{position: "relative"}} md={this.props.full ? "12" : "6"}>
                <div className="mb-4">
                {
                    this.props.iTooltip ?
                        <>
                        <OverlayTrigger
                            placement="top"
                  
                            overlay={<Tooltip >{this.props.iTooltip}</Tooltip>}
                        >
                           <BiInfoCircle className="info-icon in-input"/>
                        </OverlayTrigger>
                        <div className="form-floating">
                            { 
                                this.props.iMask === "money" ?
                                this.renderMoneyField() : 
                                this.props.iMask === "percentage" ?
                                this.renderPercentageField() :
                                this.renderField()
                            }      
                            <label htmlFor={this.props.iName}>{this.props.iTitle} {[null, true].includes(this.props.iRequired) ?  " *": ""}</label>
                        </div>
                        </>
                        :
                        <>
                        <div className="form-floating">
                            { 
                                this.props.iMask === "money" ?
                                this.renderMoneyField() : 
                                this.props.iMask === "percentage" ?
                                this.renderPercentageField() :
                                this.renderField()
                            }                           
                            
                            <label htmlFor={this.props.iName}>{this.props.iTitle} {[null, true].includes(this.props.iRequired) ?  " *": ""}</label>
                        </div>
                        </>
                }
                </div>
            </Col>
        )
    }
}

export { InputSimpleLink };