import React from 'react';
import { inject, observer } from 'mobx-react';
import {IMaskInput} from 'react-imask';

@inject('vTerminal', 'global')
@observer
class PaymentMethodCheck extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ccType: 'unknown'
        };
        this.handleTextChangeACH = this.handleTextChangeACH.bind(this);
    }

    componentDidMount(){
        const customer = this.props.vTerminal.customerSelected.Firstname ? this.props.vTerminal.customerSelected : false;
        if (customer) {
            this.props.vTerminal.handleTextChangeACH('checkAccountHolderName', (customer.Firstname + ' ' + customer.Lastname));
        }
    }

    handleTextChangeACH(field, value) {
        this.props.vTerminal.handleTextChangeACH(field, value);
    }

    render() {
        const customer = this.props.vTerminal.customerSelected.Firstname ? this.props.vTerminal.customerSelected : false;
        
        return (
            <div className='row'>                
                <div className="col-sm mb-3 form-floating">
                <IMaskInput
                    mask={this.props.global.maskValidator('alpha')}
                    name="checkAccountHolderName"
                    defaultValue={this.props.vTerminal.paymentPage.paymentMethods.checkAccountHolderName ? this.props.vTerminal.paymentPage.paymentMethods.checkAccountHolderName: customer ? (customer.Firstname + ' ' + customer.Lastname) : ''}
                    unmask={true}
                    onAccept={
                        (value, mask) => this.handleTextChangeACH('checkAccountHolderName', value)
                    }
                    placeholder="Account Holder Name"
                    className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsCheckAccountHolderNameError ? "form-control input-error" : "form-control" }
                    id="checkAccountHolderName"
                />
                <label htmlFor="checkAccountHolderName" style={{padding: '9px 30px'}}>Account Holder Name</label>
                </div>
                <div className="col-sm form-floating">
                    <IMaskInput
                        mask={this.props.global.maskValidator('numbers')}
                        name="cardHolderName"
                        defaultValue={this.props.vTerminal.paymentPage.paymentMethods.checkNumber ? this.props.vTerminal.paymentPage.paymentMethods.checkNumber: ''}
                        unmask={true}
                        onAccept={
                            (value, mask) => this.handleTextChangeACH('checkNumber', value)
                        }
                        placeholder="Cardholder Name"
                        className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsCheckNumber ? "form-control input-error" : "form-control" }
                        id="cardHolderName"
                    />
                    <label htmlFor="cardHolderName" style={{padding: '9px 30px'}}>Check #</label>
                </div>
            </div>
        );
    }
}

export { PaymentMethodCheck };