import { observable, makeObservable, action, computed, toJS } from 'mobx';
import axios from 'axios';
import globalStore from './GlobalStore';
import reportsStore from './ReportsStore';
import { PayabliStorageManager } from '../api/localStorageManager';
import parseReportData from '../utils/parseReportData';
import getConfigByReport from '../utils/configByReport';
const FileDownload = require('js-file-download');

class CustomTableStore {
	constructor(globalStore, reportsStore) {
		this.globalStore = globalStore;
		this.reportsStore = reportsStore;
		this.parameters = {
			report: null,
			entry: null,
			orgId: '',
			filters: {}
		};
		makeObservable(this);
	}

	globalStore;

	reportsStore;

	@observable
	typeReport = null;

	@observable
	columns = [];

	@observable
	flagItemAllColumn = true;

	@observable
	flagParamCategoryFilter = null;

	@observable
	actions = [];

	@observable
	data = null;

	@observable
	dataApi = null;

	@observable
	count = 20;

	@observable
	from = 0;

	@observable
	totalRecords = 0;

	@observable
	totalPages = 0;

	@observable
	summary = {};

	@observable
	dataDetail = {};

	@observable
	elementSelected = null;

	@observable
	customFields = [];

	setTypeReport = (typeReport) => {
		this.typeReport = typeReport;
		this.setConfigByReport(typeReport);
	};

	setData = async (data) => {
		this.dataApi = data;
		const customFields = this.customFields;
		let columns = this.columns;
		let value = (await parseReportData(this.typeReport, data)) || [];

		// Add custom fields to columns
		customFields.forEach((field) => {
			const { key } = field;
			const itemColumn = {
				label: key,
				key: key,
				visible: true
			};
			columns.push(itemColumn);
		});

		// Add custom fields to data
		data.forEach((item, index) => {
			const customerAdditionalData = item?.Customer?.AdditionalData || {};
			customFields.forEach((field) => {
				const { key } = field;
				value[index][key] = '-';
				if (customerAdditionalData[key]) {
					value[index][key] = customerAdditionalData[key];
				}
			});
		});

		this.columns = columns;
		this.data = value;
	};

	setSummary = (summary) => {
		this.summary = summary;
	};

	setConfigByReport = (report) => {
		const { columns, actions } = getConfigByReport(report) || { columns: [], actions: [] };
		this.columns = columns;
		this.actions = actions;
	};

	cleanDataAndColumns = () => {
		this.data = null;
		this.columns = [];
		this.actions = [];
		this.flagItemAllColumn = true;
	};

	@computed
	get visibleColumns() {
		return this.columns.filter((col) => col.visible !== false).map((col) => col.key);
	}

	@computed
	get recordDetail() {
		return this.dataDetail;
	}

	@action
	setElementSelected = (element) => {
		this.elementSelected = element;
	};

	@action
	updateParameters = (newParams) => {
		this.parameters = { ...this.parameters, ...newParams };
	};

	@action
	setCount = (count) => {
		this.count = count;
	};

	@action
	setFrom = (from) => {
		this.from = from * this.count;
	};

	@action
	setTotalRecords = (totalRecords) => {
		this.totalRecords = totalRecords;
	};

	@action
	setTotalPages(total) {
		this.totalPages = total;
	}

	@action
	setVisibleColumns = (key) => {
		let tempColumns = this.columns.map((col) => {
			if (col.key === key) {
				col.visible = !col.visible;
			}
			return col;
		});
		this.columns = tempColumns;
		this.flagItemAllColumn = true;
	};

	@action
	setVisibleColumnsBillingFees = () => {
		const listColKey = [
			'billableEvent',
			'frequency',
			'porcentPrice',
			'valuePrice',
			'billableAmount',
			'calculatedBillAmount'
		];
		let tempColumns = this.columns.map((col) => {
			if (listColKey.includes(col.key)) {
				col.visible = true;
			}
			return col;
		});
		this.columns = tempColumns;
		this.flagItemAllColumn = true;
		this.flagParamCategoryFilter = 'billingFees';
	};

	@action
	isEnabledBillingFees = (colKey, report) => {
		const listColKey = [
			'serviceGroup',
			'service',
			'billableEvent',
			'billableEventId',
			'frequency',
			'porcentPrice',
			'valuePrice',
			'billableAmount',
			'calculatedBillAmount',
			'description'
		];
		if (this.typeReport === report && listColKey.includes(colKey)) {
			return true;
		}
		return false;
	};

	@action
	selectAllColumns = (e) => {
		let tempColumns = this.columns.map((col) => {
			if (this.isEnabledBillingFees(col.key, 'batchDetails')) {
				return col;
			}
			col.visible = e.target.checked;
			return col;
		});
		this.columns = tempColumns;
		this.flagItemAllColumn = false;
	};

	@action
	getDataWithFilters(filters) {
		if (filters instanceof Object) {
			this.applyFilters(filters);
		}
		return this.getDataFromAPI();
	}

	applyFilters(filters) {
		const tempFilters = this.parameters.filters || {};
		Object.keys(filters).forEach((key) => {
			if (filters[key]) {
				tempFilters[key] = filters[key];
				this.flagParamCategoryFilter = filters[key];
			} else {
				tempFilters[key] = undefined;
			}
		});
		this.updateParameters({ filters: tempFilters });
	}

	@action
	resetFlagParamCategoryFilter() {
		this.flagParamCategoryFilter = null;
		this.setCount(20);
		this.setFrom(0);
	}

	openQuickView = async (pos) => {
		const row = toJS(this.dataApi[pos]);
		this.dataDetail = row || {};
		globalStore.setRightPanelOpen(true);
	};

	getTransactionDetail = async (transId) => {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
		const { entry, orgId } = this.parameters;
		const tempEntry = entry ? `/${entry}` : '';
		const tempOrg = entry === 'org' ? `/${orgId}` : '';

		return axios
			.get(`${process.env.REACT_APP_URL_API}Query/transactions${tempEntry}${tempOrg}`, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: {
					'transId(eq)': transId
				}
			})
			.then((response) => {
				if (response?.data?.Records?.length > 0) {
					return response.data.Records[0];
				}
			})
			.catch((error) => {
				throw error;
			});
	};

	getCustomFields = async () => {
		const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
		const id = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
		const requestToken = encryptStorage.getItem('pToken');
		const typeRequest = 'Organization';

		return axios
			.get(`${process.env.REACT_APP_URL_API}${typeRequest}/settings/${id}`, {
				headers: {
					requestToken: requestToken
				}
			})
			.then((response) => {
				return response?.data?.customFields || [];
			})
			.catch(() => {
				return [];
			});
	};

	@action
	getDataFromAPI = async () => {
		this.cleanDataAndColumns();
		const { report, entry, orgId, filters } = this.parameters;
		this.setTypeReport(report);
		this.customFields = await this.getCustomFields();
		const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
		const requestToken = encryptStorage.getItem('pToken');
		const tempEntry = entry ? `/${entry}` : '';
		const tempConst = orgId ? `/${orgId}` : '';

		let limits = {};
		limits.limitRecord = this.count;
		limits.fromRecord = this.from;

		let params = Object.assign({}, limits, filters);

		return axios
			.get(`${process.env.REACT_APP_URL_API}Query/${report}${tempEntry}${tempConst}`, {
				headers: {
					requestToken: requestToken
				},
				params: params
			})
			.then((response) => {
				if (response?.data) {
					this.setData(response.data.Records || []);
					this.setSummary(response.data.Summary || {});
					this.setTotalRecords(response.data.Summary.totalRecords);
					this.setTotalPages(response.data.Summary.totalPages);
					return response.data;
				}
			})
			.catch((error) => {
				this.setData([]);
				throw error;
			});
	};

	@action
	exportReportByFormat = async (format) => {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
		const { report, entry, orgId, filters } = this.parameters;
		const tempEntry = entry ? `/${entry}` : '';
		const tempConst = orgId ? `/${orgId}` : '';

		return axios
			.get(`${process.env.REACT_APP_URL_API}Export/${report}/${format}${tempEntry}${tempConst}`, {
				responseType: format.toLowerCase() === 'csv' ? 'text' : 'blob',
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				},
				params: Object.assign({}, filters)
			})
			.then((res) => {
				FileDownload(res.data, report + '.' + format);
			})
			.catch((error) => {
				throw error;
			});
	};
}

const customTableStoreInstance = new CustomTableStore(globalStore, reportsStore);

export default customTableStoreInstance;
