import React from "react";
import { inject, observer } from "mobx-react";
import { PayabliCookieManager, PayabliStorageManager } from "../api/localStorageManager";
import payabliLogo from "../assets/images/payabli.png";

@inject("global")
@observer
class Brand extends React.Component {
  render() {
    const { global, classBrand, imageBrand } = this.props;
    const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    const urlEntry = global.getURLEntry();
    
    const imageUrl = this.props.global.getBrandingImg ? this.props.global.getBrandingImg : imageBrand ? imageBrand : PayabliCookieManager.readCookie(`${PayabliStorageManager.getEntryName()}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`) || payabliLogo;
    return (
      <>
        {encryptStorage && <img alt="" className={classBrand} src={imageUrl.startsWith("data:image/") ? imageUrl : `${imageUrl}?nocache=${new Date().getTime()}`} onLoad={this.props.onImageLoaded} />}
      </>
    );
  }
}

export { Brand };
