import React, { useState, useContext } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import { BsExclamationTriangle } from 'react-icons/bs'
import { Modal } from 'react-bootstrap'
import commandContext from '../context/commandCenter_context'

export default function PopUpNotify({ messageTitle, message, message2 = '', btnCaption, cancelFunction, btnColor, btnFunctionOk = () => null }) {
	const { loadData } = useContext(commandContext)
	const [active, setActive] = useState(true)

	return (
		<Modal
			show={active}
			onHide={() => setActive(false)}
			backdrop="static"
			keyboard={false}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Body
				className={`popover-body ${styles['user-select']}`}
				style={{ paddingTop: '20px' }}
				id="editCatalogModal">
				<div className="d-flex flex-column align-items-center justify-content-center p-4 gap-2">
					<BsExclamationTriangle style={{ fontSize: '50px', color: '#ffa726' }} />
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-14']}
						style={{ fontWeight: '700', lineHeight: '22px', textAlign: 'center', letterSpacing: '0.0015em', padding: '0 20px' }}>
						{messageTitle}
					</label>
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-14']}
						style={{ fontWeight: '400', lineHeight: '18px', textAlign: 'center', letterSpacing: '0.004em', padding: '0 20px' }}>
						{message}
					</label>
					{message2 && (
						<label
							className={styles['label-form'] + ' ' + styles['ft-s-14']}
							style={{ fontWeight: '400', lineHeight: '18px', textAlign: 'center', letterSpacing: '0.004em', padding: '0 20px' }}>
							{message2}
						</label>
					)}
				</div>

				<div className="d-flex flex-row align-items-center justify-content-center p-0 gap-3">
					<button
						onClick={cancelFunction}
						className={styles['btn'] + ' ' + styles['no-border']}
						style={{ fontWeight: '700' }}>
						CANCEL
					</button>
					<button
						onClick={() => btnFunctionOk() | loadData() | cancelFunction()}
						className={styles['btn'] + ' ' + styles[btnColor]}>
						{btnCaption}
					</button>
				</div>
			</Modal.Body>
		</Modal>
	)
}
