import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('reports', 'global', 'filter')
@observer
class ViewPartialOrganizations extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating input-number">
                            <input name="orgId" id="orgId" type='number' className={"form-control"} placeholder="ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('idOrg(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('idOrg(eq)', e)} value={this.props.filter.filterText.orgId ? this.props.filter.filterText.orgId : ''} />
                            <label htmlFor="orgId">ID</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="orgName" id="orgName" className={"form-control"} placeholder="Organization" onChange={(e) => this.props.filter.handleCaptureTextFilter('name(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('name(ct)', e)} value={this.props.filter.filterText.orgName ? this.props.filter.filterText.orgName : ''} data-qaid="inputOrganizationFilterOrganizations"/>
                            <label htmlFor="orgName">Organization</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="orgAddress" id="orgAddress" className={"form-control"} placeholder="Address" onChange={(e) => this.props.filter.handleCaptureTextFilter('address(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('address(ct)', e)} value={this.props.filter.filterText.orgAddress ? this.props.filter.filterText.orgAddress : ''} />
                            <label htmlFor="orgAddress">Address</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating input-number">
                            <input name="orgZip" id="orgZip" type='number' className={"form-control"} placeholder="ZIP Code" onChange={(e) => this.props.filter.handleCaptureTextFilter('zip(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('zip(ct)', e)} value={this.props.filter.filterText.orgZip ? this.props.filter.filterText.orgZip : ''} />
                            <label htmlFor="orgZip">ZIP Code</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <select name="orgState" id="orgState" className={"form-control"} value={this.props.filter.filterOrgState} onChange={(e) => this.props.filter.handleSelectOrgState(e)}>
                                <option value=""> Select... </option>
                                { this.props.global.getUSAStates().map((record, i) => (
                                        <option key={i} value={record.value}>{record.text}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="orgState">State</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="orgCity" id="orgCity" className={"form-control"} placeholder="City" onChange={(e) => this.props.filter.handleCaptureTextFilter('city(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('city(ct)', e)} value={this.props.filter.filterText.orgCity ? this.props.filter.filterText.orgCity : ''} />
                            <label htmlFor="orgCity">City</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating input-number">
                            <input name="hasSubOrgs" id="hasSubOrgs" type='number' className={"form-control"} placeholder="Sub-Organizations" onChange={(e) => this.props.filter.handleCaptureTextFilter('hasSubOrgs(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('hasSubOrgs(eq)', e)} value={this.props.filter.filterText.hasSubOrgs ? this.props.filter.filterText.hasSubOrgs : ''} />
                            <label htmlFor="hasSubOrgs">Sub-Organizations</label>
                        </div>
                    </div>
                </div>
                <div>
                    <form className="form-inline">
                        <div className='row'>
                            <div className='col-6'>
                                <div className="row mb-4">
                                    <p><small>Number of Paypoints</small></p>
                                    <div className="form-group col-6">
                                        <div className="form-floating input-number">
                                            <input name='minHasPaypoints' id='minHasPaypoints' type='number' className="form-control" placeholder="Qty min" onChange={(e) => this.props.filter.handleCaptureTextFilter('hasPaypoints(ge)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('hasPaypoints(ge)', e)} value={this.props.filter.filterText.minHasPaypoints ? this.props.filter.filterText.minHasPaypoints : ''} />
                                            <label htmlFor="minHasPaypoints">Qty min</label>
                                        </div>
                                    </div>
                                    <div className="form-group col-2 p-to-bill-details">
                                        <p><small>TO</small></p>
                                    </div>
                                    <div className="form-group col-6">
                                        <div className="form-floating input-number">
                                            <input name='maxHasPaypoints' id='maxHasPaypoints' type='number' className="form-control" placeholder="Qty max" onChange={(e) => this.props.filter.handleCaptureTextFilter('hasPaypoints(le)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('hasPaypoints(le)', e)} value={this.props.filter.filterText.maxHasPaypoints ? this.props.filter.filterText.maxHasPaypoints : ''} />
                                            <label htmlFor="maxHasPaypoints">Qty max</label>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </form>
                </div>
        </>);
    }
}

export { ViewPartialOrganizations };