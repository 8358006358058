import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie';
import styles from './styles.module.css'
import { SuccessIcon } from '../../components/MarketingPreferences/assets/SuccessIcon'
import { ExceptionIcon } from '../../components/MarketingPreferences/assets/ExceptionIcon'
import { MarketingVector } from '../../components/MarketingPreferences/assets/Vector'
import { MarketingVector2 } from '../../components/MarketingPreferences/assets/Vector2'
import { WarningIcon } from '../../components/MarketingPreferences/assets/WarningIcon'
import LoadingApplication from "../../assets/lotties/LoadingSquaredApplication.json";
import visa from '../../assets/images/visa.svg'
import mastercard from '../../assets/images/mastercard.svg'
import discover from '../../assets/images/discover.svg'
import amex from '../../assets/images/amex.svg'



export function MarketingPreferencesPage(props) {
    const [status, setStatus] = useState();
    const [loading, setLoading] = useState(true);
    const [paypoint, setPaypoint] = useState({});

    const fetchPaypoint = async (entrypoint) => {
        const endpoint = `${process.env.REACT_APP_URL_API}paypoint/basic/${entrypoint}`;
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'requestToken': process.env.REACT_APP_TOKEN
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
        return await response.json();
    }

    useEffect(() => {
        (async () => {
            try {
                const search = window.location.search;
                const params = new URLSearchParams(search);
                const status = params.get('success');
                const entryName = params.get('entrypoint');
                if(entryName){
                    const paypoint = await fetchPaypoint(entryName);
                    paypoint.responseData && setPaypoint(paypoint.responseData);
                }
                setStatus(status === 'true' ? 'success' : status === 'false' ? 'error' : 'notfound');
                setLoading(false);
            } catch (error) {
                console.log(error)
                setStatus('notfound');
            } finally {
                setLoading(false);
            }
        })()
    }, [])


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LoadingApplication,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const statuses = {
        success: <SuccessStatus />,
        error: <ErrorStatus />,
        notfound: <NotfoundStatus />
    }

    const hasAtLestOne = paypoint?.Paypoint?.WebsiteAddress || paypoint?.Paypoint?.Phone;
    const hasAll = paypoint?.Paypoint?.WebsiteAddress && paypoint?.Paypoint?.Phone;
    return (
        <main className={styles.mainSignup}>
            {loading ? (
                <Lottie options={defaultOptions} height={100} width={100} />
                ) : (
                    <section className={styles.sectionSignup}>
                        <MarketingVector className={styles.vectorTop} />
                        <MarketingVector2 className={styles.vectorBottom} />
                        {/* Branding Block */}
                        <div className={styles.signupBranding}>
                            {paypoint?.EntryLogo && (
                                <img alt={paypoint?.EntryName} src={paypoint?.EntryLogo} style={{
                                    width: 'auto',
                                    height: '75px',
                                    objectFit: 'contain',
                                    objectPosition: 'center'
                                }} />
                            )}
                        </div>

                        {/* Content Block */}
                        <div className={styles.signupContent}>
                            {statuses[status] && statuses[status]}
                        </div>

                        {/* Footer Block */}
                        <div className={styles.signupFooter}>
                            {hasAtLestOne && (
                                <div className={styles.signupFooterHavingProblem}>
                                    <p>Having problems? Contact Us</p>
                                    <span><a href={`https://${paypoint?.Paypoint?.WebsiteAddress}`}>{paypoint?.Paypoint?.WebsiteAddress}</a> {hasAll && <span>|</span>} {paypoint?.Paypoint?.Phone}</span>
                                </div>
                            )}
                            <div className={styles.signupFooterLastBlock}>
                                <div>
                                    <img aria-label='Visa logo' alt="Visa Logo" src={visa} height={32} width={32} />
                                    <img aria-label='Mastercard logo' alt="Mastercard Logo" src={mastercard} height={32} width={32} />
                                    <img aria-label='Discover logo' alt="Discover Logo" src={discover} height={32} width={32} />
                                    <img aria-label='American Express logo' alt="American Express Logo" src={amex} height={32} width={32} />

                                </div>
                                <small>
                                    Powered by Payabli
                                </small>
                            </div>
                        </div>
                    </section>
                )}
            </main>
    )

}


function SuccessStatus() {
    return (
        <>
            <SuccessIcon className={styles.icon} />
            <h1 className={styles.successTitle}>Opt in successful!</h1>
        </>
    )
}

function ErrorStatus() {
    return (
        <>
            <WarningIcon className={styles.icon} />
            <p className={styles.errorTitle}>
                There was an error processing your request. Contact our support team for help.
            </p>
        </>
    )
}


function NotfoundStatus() {
    return (
        <>
            <h1 className={styles.notfoundTitle}>Something went wrong</h1>
            <p className={styles.notfoundParagraph}>
                Error <span><ExceptionIcon /> 404</span> Page Not Found
            </p>
        </>
    )
}