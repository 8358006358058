import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportPaginator } from '../../components/ReportPaginator';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { ReportFilters } from '../../components/ReportFilters';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { Layout } from '../../components/Layout'
import { Link } from 'react-router-dom';
import { RightPanel } from '../../components/RightPanel';
import { Modal, Button } from "react-bootstrap";
import { AutopayForm } from '../../components/AutopayForm';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { OrganizationOverviewLinks } from '../../components/OrganizationOverviewLinks';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import {BiFilterAlt} from '@react-icons/all-files/bi/BiFilterAlt';
import {BiPause} from '@react-icons/all-files/bi/BiPause';
import {BiPlay} from '@react-icons/all-files/bi/BiPlay';
import {BiX} from '@react-icons/all-files/bi/BiX';
import {BiNotepad} from '@react-icons/all-files/bi/BiNotepad';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import {IMaskInput} from 'react-imask';
import { MainBar } from '../../components/MainBar';

@inject('reports', 'global', 'vTerminal', 'entry')
@observer
class OrgAutopayReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: "",
            filterBrands: {},
            filterFrequencies: {},
            filtersEnabled: false,
            vTerminalAutopay: 1,
            cancelAutopayModalIsOpen: false,
            pauseAutopayModalIsOpen: false,
            continueAutopayModalIsOpen: false,
            updateAutopayModalIsOpen: false,
            autopayIdToModify: null,
            autopayToUpdate: null,
            autopayToUpdateAmount: 0,
            autopayAmountError: false,
            customFields: [],

            flagItemAllColumn: true,
            currentOrgName: ''
        };
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.handleBrand = this.handleBrand.bind(this);
        this.handleFrequency = this.handleFrequency.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.autopayStatus = this.autopayStatus.bind(this);
        this.openCancelAutopayModal = this.openCancelAutopayModal.bind(this);
        this.closeCancelAutopayModal = this.closeCancelAutopayModal.bind(this);
        this.cancelAutopayAction = this.cancelAutopayAction.bind(this);
        this.openPauseAutopayModal = this.openPauseAutopayModal.bind(this);
        this.closePauseAutopayModal = this.closePauseAutopayModal.bind(this);
        this.pauseAutopay = this.pauseAutopay.bind(this);
        this.pauseAutopayAction = this.pauseAutopayAction.bind(this);
        this.continueAutopayAction = this.continueAutopayAction.bind(this);
        this.openContinueAutopayModal = this.openContinueAutopayModal.bind(this);
        this.closeContinueAutopayModal = this.closeContinueAutopayModal.bind(this);
        this.continueAutopay = this.continueAutopay.bind(this);
        this.closeUpdateAutopayModal = this.closeUpdateAutopayModal.bind(this);
        this.updateAutopay = this.updateAutopay.bind(this);
        this.validateEditAutopayFields = this.validateEditAutopayFields.bind(this);
        this.updateAutopayAction = this.updateAutopayAction.bind(this);
        this.handleAutopayAmount = this.handleAutopayAmount.bind(this);
        this.getCustomFieldsFromApi = this.getCustomFieldsFromApi.bind(this);
        this.focus = this.focus.bind(this);
        this.selectAllFilters = this.selectAllFilters.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
    }
    
    componentDidMount() {
        this.props.global.protect(this.props.history);

        this.setState({ 
            idOrg: this.props.match.params.idOrg ? this.props.match.params.idOrg : null 
        });

        if(this.props.match.params.idOrg){
            this.props.entry.getOrganization(this.props.match.params.idOrg).then(res => {
                this.setState({currentOrgName:res.OrgName});
            })
            .catch(error => {
                toast.error("Something is Wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
        
        this.props.reports.setFrom(0);
        this.props.global.setRightPanelOpen(false);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
        {
            NextDate        : { label:'Next Draft on', class: '', display: true, filter: 'NextDate'}, 
            ParentOrgName : { label:'Organization', class: '', display: false},
            PaypointLegalname : { label:'Paypoint', class: '', display: true, filter: 'PaypointLegalname'},
            
            HolderName      : { label:'Customer', class: '', display: true, filter: 'customerName'}, 
            customerNumber  : { label:'Customer #', class: '', display: false, filter: 'customerNumber'},
            billingEmail  : { label:'Email', class: '', display: false, filter: 'customerEmail'}, 
            payorPhone  : { label:'Phone #', class: '', display: false, filter: 'phoneNumber'},
            billingAddress  : { label:'Billing Address', class: '', display: false},
            shippingAddress  : { label:'Shipping Address', class: '', display: false},
            invoiceNumber  : { label:'Invoice #', class: '', display: true},

            Frequency       : { label:'Frequency', class: '', display: true, filter: 'Frequency'}, 
            TotalAmount     : { label:'Amount', class: 'text-right', display: true, filter: 'totalAmount'}, 
            LeftCycles      : { label:'Remaining', class: '', display: true}, 
            AccountType     : { label:'Pay Method', class: 'text-center', display: true, filter: 'AccountType'}, 
            MaskedAccount   : { label:'Last 4', class: '', display: true, filter: 'payaccountLastfour'}, 
            SubStatus       : { label:'Status', class: '', display: true, filter: 'SubStatus'}, 
            IdSub           : { label:'Autopay ID', class: '', display: true, filter: "IdSub"}, 
            StartDate       : { label:'Start Date', class: '', display: true, filter: 'StartDate'},
            notes    : { label:'Notes', class: 'text-center', display: true}
        }
        )

        this.getCustomFieldsFromApi();
        this.props.vTerminal.resetPaymentPage();
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

    selectAllFilters(containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === false) {
                checkBoxes[i].click();
            }
        }

    }

    getCustomFieldsFromApi(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let idOrg = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
        let reactObj = this;
        this.props.reports.getOrgSettings(idOrg).then(res => {
            if(res.data.customFields){
                let fields = res.data.customFields;
                fields.forEach(function (item, index) {
                    reactObj.props.reports.addHeader(item.key ,{ label:item.key, class: '', display: true, filter: item.key, custom: true});
                });
                this.setState({customFields:fields});
            }
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    cancelAutopay(idAutopay){
        this.setState({ autopayIdToModify: idAutopay });
        this.openCancelAutopayModal();
    }
    
    pauseAutopay(idAutopay){
        this.setState({ autopayIdToModify: idAutopay });
        this.openPauseAutopayModal();
    }
    
    continueAutopay(record){
        this.setState({ autopayToModify: record });
        this.openContinueAutopayModal();
    }

    openCancelAutopayModal(){
        this.setState({ cancelAutopayModalIsOpen: true });
    }
    
    closeCancelAutopayModal(){
        this.setState({ cancelAutopayModalIsOpen: false });
    }
    
    updateAutopay(autopay){
        let nextDate = new Date(autopay.NextDate);
        if(nextDate <= new Date()){
            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.props.vTerminal.handleAutopayStartDate(tomorrow);
        }
        else{
            this.props.vTerminal.handleAutopayStartDate(new Date(autopay.NextDate));
        }
        
        this.props.vTerminal.handleAutopayFrequency(autopay.Frequency);
        //this.props.vTerminal.handleAutopayEndDate(autopay.EndDate)
        this.setState({ autopayToUpdate: autopay, autopayToUpdateAmount: autopay.TotalAmount }, function(){
            this.openUpdateAutopayModal();
        });
    }

    closeUpdateAutopayModal(){
        this.setState({ updateAutopayModalIsOpen: false });
    }
    
    openUpdateAutopayModal(){
        this.setState({ updateAutopayModalIsOpen: true });
    }
    
    openPauseAutopayModal(){
        this.setState({ pauseAutopayModalIsOpen: true });
    }
    
    openContinueAutopayModal(){
        this.setState({ continueAutopayModalIsOpen: true });
    }
    
    closePauseAutopayModal(){
        this.setState({ pauseAutopayModalIsOpen: false });
    }
    
    closeContinueAutopayModal(){
        this.setState({ continueAutopayModalIsOpen: false });
    }

    cancelAutopayAction(){
        let autopayIdToDelete = this.state.autopayIdToModify;
        if(autopayIdToDelete){
            this.props.global.setLoading(true);
            this.props.reports.cancelAutopay(autopayIdToDelete)
            .then(result => {
                this.setState({ cancelAutopayModalIsOpen : false });
                this.props.global.setLoading(false);
                toast.success("Autopay canceled successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }
    
    pauseAutopayAction(){
        let autopayIdToPause = this.state.autopayIdToModify;
        if(autopayIdToPause){
            this.props.global.setLoading(true);
            this.props.reports.pauseAutopay(autopayIdToPause, true)
            .then(result => {
                this.setState({ pauseAutopayModalIsOpen : false });
                this.props.global.setLoading(false);
                toast.success("Autopay paused successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    continueAutopayAction(){
    
        let autopayNextDate = new Date(this.state.autopayToModify.NextDate);
        let autopayEndDate = new Date(this.state.autopayToModify.EndDate);
        let autopayFrequency = this.state.autopayToModify.Frequency;
        let autopayIdToContinue = this.state.autopayToModify.IdSub;
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        if(autopayNextDate && autopayEndDate && autopayFrequency){
            let autopayStartDate = autopayNextDate >= tomorrow ? autopayNextDate: tomorrow;
            this.props.global.setLoading(true);
            this.props.reports.pauseAutopay(autopayIdToContinue, false, autopayStartDate, autopayEndDate, autopayFrequency)
            .then(result => {
                this.setState({ continueAutopayModalIsOpen : false });
                this.props.global.setLoading(false);
                toast.success("Autopay running successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }


    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    exportFile(format) {
        this.props.reports.exportOrgFile('subscriptions', format, this.state.idOrg);
    }

    showHideFilters(){
        this.setState({ filtersEnabled: !this.state.filtersEnabled }, function(){
            if(this.state.filtersEnabled === false){
                this.clearFilters();
            }
        });
    }

    clearFilters(){
        this.setState({ head: "", filterBrands: {},filterFrequencies: {},filterStatus: {}, dateStartDate: null,dateEndtDate: null}, function(){
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    clearFilter(e, filter, filterType){
        e.preventDefault();
        this.setState({ [filterType]: {} });
        this.filter(filter,"");
    }

    getReportFromApi(){
        this.props.global.setLoading(true);
        this.props.reports.getReportFromApiOrgID('subscriptions',this.state.idOrg).then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    handleQuickFilter(value, e){
        this.filter('status(eq)',value)
        this.setState({ head: e.target.id });
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filterOrgId(type, value, 'subscriptions', this.state.idOrg).then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
        });
    }

    handleBrand(e){
        
        let brands = this.state.filterBrands;
        brands[e.target.id] = e.target.checked;

        var arrayValues = [];
        for (let key in brands) {
            if(brands[key]){
                arrayValues.push(key);
            }
        }
        this.setState({ filterBrands: brands });
        this.filter("payaccountType(in)",arrayValues.join("|"));
    }

    handleKeyDown(type, e){
        if (e.key === 'Enter') {
            this.filter(type,e.target.value);
        }
    }
    

    handleFrequency(e){
        
        let frequencies = this.state.filterFrequencies;
        frequencies[e.target.value] = e.target.checked;

        var arrayValues = [];
        for (let key in frequencies) {
            if(frequencies[key]){
                arrayValues.push(key);
            }
        }
        this.setState({ filterFrequencies: frequencies });
        this.filter("frequency(in)",arrayValues.join("|"));
    }

    handleStatus(e){
        
        let status = this.state.filterStatus;
        status[e.target.value] = e.target.checked;

        var arrayValues = [];
        for (let key in status) {
            if(status[key]){
                arrayValues.push(key);
            }
        }
        this.setState({ filterStatus: status });
        this.filter("status(in)",arrayValues.join("|"));
    }

    handleAutopayAmount(value){
        this.setState({ autopayToUpdateAmount: value });
    }

    handleNextDateChangeCalendar(dates) {
        const [start, end] = dates;
        this.setState({dateStartDate: start,dateEndtDate: end}, function(){
            const type = [];
            type['nextDate(ge)'] = this.props.global.stringDateFormatFilters(start)+ "T00:00:00";
            type['nextDate(le)'] = this.props.global.stringDateFormatFilters(end)+ "T23:59:59";
            if(end){
                this.filter(type,null);
            }
        });
        
    };
    handleChangeCalendar(dates) {
        const [start, end] = dates;
        this.setState({dateStartDate: start,dateEndtDate: end}, function(){
            const type = [];
            type['startDate(ge)'] = this.props.global.stringDateFormatFilters(start)+ "T00:00:00";
            type['startDate(le)'] = this.props.global.stringDateFormatFilters(end)+ "T23:59:59";
            if(end){
                this.filter(type,null);
            }
        });
        
    };

    viewRecordDetails(index, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            let thisObj = this;
            if(thisObj.props.global.rightPanelOpen && thisObj.props.reports.recordDetails !== index){
                thisObj.props.global.setRightPanelOpen(false);
                thisObj.props.reports.setRecordDetails(index);
                setTimeout(function(){
                    thisObj.props.global.setRightPanelOpen(true);
                }, 500)                
            }else{
                thisObj.props.reports.setRecordDetails(index);
                thisObj.props.global.setRightPanelOpen(true);
            }
        }
    }
    
    focus(e){
        e.target.focus();
        e.target.setSelectionRange(0, 1000000000);
    }

    autopayStatus(status){
        switch(status){
            case 1:
                return <span className="badge rounded-pill bg-success">Active</span>;
            case 0:
                return <span className="badge rounded-pill bg-warning">Paused</span>;
            case 99:
                return <span className="badge rounded-pill bg-secondary">Completed</span>;
            default:
                return <span className="badge rounded-pill bg-danger">Unknown</span>;

        }
    }
    

    validateEditAutopayFields(){
        let validators = this.props.global.validators;
        let paymentPage = this.props.vTerminal.paymentPage;        

        if(validators.isEmpty(paymentPage.autopay.startDate) || validators.isMaxLength(250, paymentPage.autopay.startDate))
        {
            this.props.vTerminal.setPaymentPageError('autopayStartDateError',true);
        }
        else{
            this.props.vTerminal.setPaymentPageError('autopayStartDateError',false);
        }

        if(validators.isEmpty(paymentPage.autopay.frequencySelected) || validators.isMaxLength(250, paymentPage.autopay.frequencySelected))
        {
            this.props.vTerminal.setPaymentPageError('autopayFrequencyError',true);
        }
        else{
            this.props.vTerminal.setPaymentPageError('autopayFrequencyError',false);
        }

        if(validators.isEmpty(paymentPage.autopay.finishSelected) || validators.isMaxLength(250, paymentPage.autopay.finishSelected))
        {
            this.props.vTerminal.setPaymentPageError('autopayFinishError',true);
        }
        else{
            this.props.vTerminal.setPaymentPageError('autopayFinishError',false);
        } 
        
        if(this.state.autopayToUpdateAmount === 0)
        {
            this.props.vTerminal.setPaymentPageError('autopayAmountError',true);
        }
        else{
            this.props.vTerminal.setPaymentPageError('autopayAmountError',false);
        } 

        
    }

    updateAutopayAction(){
        this.validateEditAutopayFields();
        if(!this.props.vTerminal.hasPaymentPageErrors()){
            this.props.global.setLoading(true);
            this.props.vTerminal.updateAutopay(this.state.autopayToUpdateAmount, this.state.autopayToUpdate)
            .then(result => {
                this.closeUpdateAutopayModal();
                this.props.global.setLoading(false);
                this.getReportFromApi();
                toast.success("Autopay updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }
    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }
    render() {
        const recordDetail = this.props.reports.getRecordDetails;

        return (
            <Layout {...this.props}>
            <RightPanel>
                <h5 className="header mb-3">Autopay Details</h5>
                <div className="small mb-5">
                        <div className="row">
                            <div className="col-3">
                                <label className="header">Frequency</label>
                            </div>
                            <div className="col-4">
                                {recordDetail && recordDetail.Frequency ? this.props.global.frequencyText(recordDetail.Frequency) : '-'}
                            </div>
                            <div className="col-2">
                                <label className="header">Amount</label>
                            </div>
                            <div className="col-3 text-right">
                                {(recordDetail && recordDetail.NetAmount) ? "$" + this.props.global.numberWithCommas(recordDetail.NetAmount.toFixed(2)) : '-'}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-3">
                                <label className="header">Remaining</label>
                            </div>
                            <div className="col-4">
                            {(recordDetail && recordDetail.UntilCancelled === true) ?  "Until Cancelled" : ((recordDetail && recordDetail.LeftCycles) ? recordDetail.LeftCycles : "")}
                            </div>
                            <div className="col-2">
                                <label className="header">Fee</label>
                            </div>
                            <div className="col-3 text-right">
                                {(recordDetail && recordDetail.FeeAmount) ? "$" + this.props.global.numberWithCommas(recordDetail.FeeAmount.toFixed(2)) : '-'}
                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-3">
                                <label className="header">Status</label>
                            </div>
                            <div className="col-4">
                                { this.autopayStatus(recordDetail?.SubStatus) }
                            </div>
                            <div className="col-2">
                                <label className="header">Total</label>
                            </div>
                            <div className="col-3 text-right">
                               <h5>{(recordDetail && recordDetail.TotalAmount) ?  "$" + this.props.global.numberWithCommas(recordDetail.TotalAmount.toFixed(2)) : '-'}</h5>
                            </div>
                            <div className="col-3">
                                <label className="header">Start Date</label>
                            </div>
                            <div className="col-4">
                            { recordDetail && recordDetail.StartDate ? this.props.global.stringDateFormat(recordDetail.StartDate) : '-'}
                            </div>
                            <div className="col-5">
                            </div>
                            <div className="col-3">
                                <label className="header">Next Draft on	</label>
                            </div>
                            <div className="col-4">
                            { recordDetail && recordDetail.NextDate ? this.props.global.stringDateFormat(recordDetail.NextDate) : '-'}
                            </div>
                            <div className="col-5">
                            </div>
                        </div>

                        <h5 className="header mb-3">Payment Information</h5>
                  
                        {(recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.MaskedAccount && recordDetail.PaymentData.MaskedAccount.toLowerCase() === "poi") ?
                            <div className="card-v2 mb-3" style={{padding: "3em"}}>
                                <div className="row">
                                <div className="col-7">
                                    <label className="grey">Source</label>
                                    <h6 style={{fontWeight: 500}}>
                                        POI Device
                                    </h6>
                                </div>
                                <div className="col-5 text-right">
                                    {this.props.global.getGlobalImg('pointofsale', '30px')}
                                </div>
                                </div>
                            </div>
                        :
                        <div className="card-v2 mb-3" style={{padding: "3em"}}>
                            <div className="right-panel-card mb-4">
                                {this.props.global.getGlobalImg((recordDetail?.PaymentData?.AccountType && recordDetail?.PaymentData?.AccountType?.toLowerCase() !== 'unknow' ? recordDetail?.PaymentData?.AccountType?.toLowerCase() : recordDetail?.Method), '45px')}
                            </div>                       
                            <div className="row mb-2">
                                <div className="col-7">
                                    <label className="grey">{recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ? "Card Number" : "Account Number" }</label>
                                    <h5 style={{fontWeight: 500}}>
                                    •••• •••• {(recordDetail && recordDetail.PaymentData) ?  this.props.global.maskedCardNumber(recordDetail.PaymentData.MaskedAccount, 'v5') : '-'}
                                    </h5>
                                </div>
                                {recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ?
                                <div className="col-5 text-center">
                                    <label className="grey">Expires on</label>
                                    <h5 style={{fontWeight: 500}}>
                                        {recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.AccountExp ? this.props.global.expDateFormat(recordDetail.PaymentData.AccountExp) : '-'}
                                    </h5>
                                </div>
                                :
                                <div className="col-5 text-center">
                                    <label className="grey">Account Type</label>
                                    <h5 style={{fontWeight: 500}}>
                                        {recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.AccountType ? recordDetail.PaymentData.AccountType  : '-'}
                                    </h5>
                                </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="grey">{recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ? "Cardholder Name" : "Account Holder Name" } </label>
                                    <h6 style={{fontWeight: 500}}>
                                        {(recordDetail && recordDetail.PaymentData )?  recordDetail.PaymentData.HolderName : '-'}
                                    </h6>
                                </div>
                            </div>
                        </div>                        
                        }
                        <div className="text-center mb-1">
                            &nbsp;
                        </div>
                        <h5 className="header mb-3">Customer Information</h5>
                        <div className="row mb-4">
                            <div className="col-3">
                                <label className="header">Customer</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.Customer )? (recordDetail.Customer.FirstName?recordDetail.Customer.FirstName:"") + ' ' + (recordDetail.Customer.LastName?recordDetail.Customer.LastName:"") : '-'}
                            </div>
                        </div>
                    </div>
            </RightPanel>
            <Modal style={{textAlign: "center"}} show={this.state.cancelAutopayModalIsOpen} onHide={this.closeCancelAutopayModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiX className="icon-modal"/>
                    <h5>Cancel</h5>
                    <p className="small">Are you sure you want to cancel this autopay?</p>
                    <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeCancelAutopayModal()}>
                        Close
                    </Button>
                    &nbsp;&nbsp;
                    <Button className="btn" variant="danger" onClick={(e)=> this.cancelAutopayAction()}>
                        Cancel
                    </Button>
                </Modal.Body>
            </Modal>

            <Modal style={{textAlign: "left"}} show={this.state.updateAutopayModalIsOpen} onHide={this.closeUpdateAutopayModal}  size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="popover-body popover-body-2">
                    <div className="mb-4">
                    <h6 className="mb-4">Update Autopay</h6>
                    <AutopayForm/>
                    <div className="form-floating form-floating-money mb-3">
                         <NumberFormat
                            onFocus={(e)=>this.focus(e)} 
                            thousandsGroupStyle="thousand"
                            decimalSeparator="."
                            displayType="input"
                            type="text"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}

                            value={ String(this.state.autopayToUpdateAmount) }
                            placeholder="Amount"
                            className={ this.props.vTerminal.getPaymentPageErrors.autopayAmountError ? "form-control input-money input-error" : "form-control input-money" }
                            onValueChange={(values) => this.handleAutopayAmount(values.value)}
                            id="amountEditAutopay"
                        />
                         <label htmlFor="amountEditAutopay">Amount</label>
                    </div>
                        { this.state.autopayToUpdate ? this.props.global.getPaymethodImg(this.state.autopayToUpdate.PaymentData.AccountType) : '' }&nbsp;&nbsp;
                        { this.state.autopayToUpdate ? this.props.global.maskedCardNumber(this.state.autopayToUpdate.PaymentData.MaskedAccount, "v3") : '' }
                    </div>
                    <button onClick={(e) => this.updateAutopayAction()} type="button" className="btn btn btn-primary full-w">Update</button>
                </Modal.Body>
            </Modal>
           
            <Modal style={{textAlign: "center"}} show={this.state.pauseAutopayModalIsOpen} onHide={this.closePauseAutopayModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiPause className="icon-modal"/>
                    <h5>Pause</h5>
                    <p className="small">Are you sure you want to pause this autopay?</p>
                    <Button className="btn" variant="primary" onClick={(e)=> this.pauseAutopayAction()}>
                        Pause Autopay
                    </Button>
                </Modal.Body>
            </Modal>

            <Modal style={{textAlign: "center"}} show={this.state.continueAutopayModalIsOpen} onHide={this.closeContinueAutopayModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiPlay className="icon-modal"/>
                    <h5>Continue</h5>
                    <p className="small">Are you sure you want to continue this autopay?</p>
                    <Button className="btn" variant="primary" onClick={(e)=> this.continueAutopayAction()}>
                        Continue Autopay
                    </Button>
                </Modal.Body>
            </Modal>

            <div>

                

                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <OrganizationOverviewLinks goback={true} gobackLink={'/'+this.props.global.getURLEntry() + '/orgdetails/'+this.state.idOrg} gobackText="Org. Overview" idOrg={this.state.idOrg} selected="autopay"/>
                </div>
                </TopBar>
                

                <div className="mt-body4">
                    <MainBar
                        reportName="OrganizationReports"
                        reportTitle={this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : "All Autopays"}
                        dataQAName="OrganizationReports"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        rootTemplate={this.state.rootTemplate}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        searchBar={false}
                        exportFile={this.exportFile}
                        masterName={this.props.match.params.idOrg && `Organization - ${this.state.currentOrgName}`}
                        withTotal={true}
                        totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    /> 

                {this.state.filtersEnabled &&
                <ReportFilters report="subscriptions" clearFilters = {this.clearFilters}/>
                }

                <div className="report-container">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            
                            ( record[1].display &&
                                (this.state.filtersEnabled ?
                                    <th key={i} scope="col" className={record[1].class}>
                                
                                    { (!record[1].filter) ? record[1].label: ''}
    
                                        { record[1].filter &&
                                            <>
                                            
                                        { record[1].custom === true &&
                                        <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                        >
                                            <Dropdown.ItemText className="filter-form">
                                                <label className="header">Filter by {record[1].label}</label>
                                                <input placeholder={record[1].label} onKeyDown={(e) => this.handleKeyDown('additional-'+record[1].label+'(ct)',e)} type="text" className="form-control search-enter"/>
                                            </Dropdown.ItemText>
                                        </DropdownButton>
                                        }

                                        { record[1].filter === 'SubStatus' &&
                                        <DropdownButton
                                            menuAlign="left"
                                            title={<div>{record[1].label} <BiFilterAlt/></div>}
                                            size="sm"
                                            variant=""
                                            id="subStatusFilters"
                                        >
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterStatus && this.state.filterStatus["1"] ? true : false } value="1" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Active</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterStatus && this.state.filterStatus["0"] ? true : false } value="0" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Paused</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterStatus && this.state.filterStatus["99"] ? true : false } value="99" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Completed</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={() => this.selectAllFilters("subStatusFilters")}>Select All</Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={(e) => this.clearFilter(e, "status(in)", "filterStatus") }>Clear All</Dropdown.Item>
                                        </DropdownButton>
                                        }

                                    {record[1].filter === 'payaccountLastfour' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Last 4</label>
                                            <IMaskInput
                                                mask={'0000'}
                                                unmask={true}
                                                onKeyDown={(e) => this.handleKeyDown('payaccountLastfour(ct)',e)}                                                
                                                placeholder="Last 4"
                                                className="form-control search-enter"
                                            />
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }
                                        
                                        { record[1].filter === 'AccountType' &&
                                        <DropdownButton
                                            menuAlign="left"
                                            title={<div>{record[1].label} <BiFilterAlt/></div>}
                                            size="sm"
                                            variant=""
                                            id="accountTypeFilters"
                                        >
                                             <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterBrands && this.state.filterBrands.visa ? true : false } value="visa" id="visa" type="checkbox" onChange={(e) => this.handleBrand(e)}/> Visa</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterBrands && this.state.filterBrands.mastercard ? true : false } value="mastercard" id="mastercard" type="checkbox" onChange={(e) => this.handleBrand(e)}/> MasterCard</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterBrands && this.state.filterBrands.discover ? true : false } value="discover" id="discover" type="checkbox" onChange={(e) => this.handleBrand(e)}/> Discover</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterBrands && this.state.filterBrands.amex ? true : false } value="amex" id="amex" type="checkbox" onChange={(e) => this.handleBrand(e)}/> Amex</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterBrands && this.state.filterBrands.checking ? true : false } value="checking" id="checking" type="checkbox" onChange={(e) => this.handleBrand(e)}/> Checking</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={() => this.selectAllFilters("accountTypeFilters") }>Select All</Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={(e) => this.clearFilter(e, "payaccountType(in)", "filterBrands") }>Clear All</Dropdown.Item>
                                        </DropdownButton>
                                        }

                                        {record[1].filter === 'customerEmail' &&
                                        <DropdownButton
                                            menuAlign="left"
                                            title={<div>{record[1].label} <BiFilterAlt/></div>}
                                            size="sm"
                                            variant=""
                                            className="search"
                                        >
                                            <Dropdown.ItemText className="filter-form">
                                                <label className="header">Filter by Email</label>
                                                <input placeholder="Email" onKeyDown={(e) => this.handleKeyDown('customerEmail(ct)',e)} type="text" className="form-control search-enter"/>
                                            </Dropdown.ItemText>
                                        </DropdownButton>
                                        }
    
                                        { record[1].filter === 'Frequency' &&
                                        <DropdownButton
                                            menuAlign="left"
                                            title={<div>{record[1].label} <BiFilterAlt/></div>}
                                            size="sm"
                                            variant=""
                                            id="frequencyFilters"
                                        >
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterFrequencies && this.state.filterFrequencies["onetime"] ? true : false } value="onetime" type="checkbox" onChange={(e) => this.handleFrequency(e)}/> One Time</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterFrequencies && this.state.filterFrequencies["weekly"] ? true : false } value="weekly" type="checkbox" onChange={(e) => this.handleFrequency(e)}/> Weekly</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterFrequencies && this.state.filterFrequencies["every2weeks"] ? true : false } value="every2weeks" type="checkbox" onChange={(e) => this.handleFrequency(e)}/> Every 2 Weeks</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterFrequencies && this.state.filterFrequencies["monthly"] ? true : false } value="monthly" type="checkbox" onChange={(e) => this.handleFrequency(e)}/> Monthly</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterFrequencies && this.state.filterFrequencies["every3months"] ? true : false } value="every3months" type="checkbox" onChange={(e) => this.handleFrequency(e)}/> Every 3 months</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterFrequencies && this.state.filterFrequencies["every6months"] ? true : false } value="every6months" type="checkbox" onChange={(e) => this.handleFrequency(e)}/> Every 6 Months</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.ItemText>
                                                <label><input checked={ this.state.filterFrequencies && this.state.filterFrequencies["annually"] ? true : false } value="annually" type="checkbox" onChange={(e) => this.handleFrequency(e)}/> Annually</label>
                                            </Dropdown.ItemText>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={() => this.selectAllFilters("frequencyFilters")}>Select All</Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={(e) => this.clearFilter(e, "frequency(in)", "filterFrequencies") }>Clear All</Dropdown.Item>
                                        </DropdownButton>
                                        }

                                    {record[1].filter === 'totalAmount' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Amount</label>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                decimalSeparator="."
                                                displayType="input"
                                                type="text"
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                placeholder="Total Amount"
                                                className="form-control search-enter"
                                                onKeyDown={(e) => this.handleKeyDown('totalAmount(eq)',e)}
                                            />
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }
                          
    
                                        {record[1].filter === 'customerName' &&
                                        <DropdownButton
                                            menuAlign="left"
                                            title={<div>{record[1].label} <BiFilterAlt/></div>}
                                            size="sm"
                                            variant=""
                                            className="search"
                                        >
                                            <Dropdown.ItemText className="filter-form">
                                                <label className="header">Filter by Customer</label>
                                                <input placeholder="Customer" onKeyDown={(e) => this.handleKeyDown('customerName(ct)',e)} type="text" className="form-control search-enter"/>
                                            </Dropdown.ItemText>
                                        </DropdownButton>
                                        }

                                    {record[1].filter === 'customerNumber' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Customer #</label>
                                            <input placeholder="Customer #" onKeyDown={(e) => this.handleKeyDown('customerNumber(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                        {record[1].filter === 'IdSub' &&
                                        <DropdownButton
                                            menuAlign="left"
                                            title={<div>{record[1].label} <BiFilterAlt/></div>}
                                            size="sm"
                                            variant=""
                                            className="search"
                                        >
                                            <Dropdown.ItemText className="filter-form">
                                                <label className="header">Filter by Autopay ID</label>
                                                <input placeholder="Autopay ID" onKeyDown={(e) => this.handleKeyDown('IdSub(ct)',e)} type="text" className="form-control search-enter"/>
                                            </Dropdown.ItemText>
                                        </DropdownButton>
                                        }

                                    {record[1].filter === 'StartDate' &&
                                    <DropdownButton
                                        menuAlign="right"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form-datepicker">
                                        <DatePicker
                                            selected={ this.state.dateStartDate ? this.state.dateStartDate : new Date()}
                                            onChange={date => this.handleChangeCalendar(date)}
                                            startDate={this.state.dateStartDate}
                                            endDate={this.state.dateEndtDate}
                                            selectsRange
                                            inline
                                        />
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'PaypointLegalname' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by PayPoint</label>
                                            <input placeholder="PayPoint" onKeyDown={(e) => this.handleKeyDown('paypointLegal(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'NextDate' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form-datepicker">
                                        <DatePicker
                                            selected={ this.state.dateStartDate ? this.state.dateStartDate : new Date()}
                                            onChange={date => this.handleNextDateChangeCalendar(date)}
                                            startDate={this.state.dateStartDate}
                                            endDate={this.state.dateEndtDate}
                                            selectsRange
                                            inline
                                        />
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }


                                        </>
                                        
                                    }
    
    
                                   
    
                                </th>
                                  : <th key={i} scope="col" className={record[1].class}>{record[1].label}</th> )
                                )
                            )
                           
                        )
                    }
                    <th scope="col" className="text-center sticky-row">Actions</th>
                    </tr>
                </thead>
                <tbody>
                
                { this.props.reports.records.map((record, i) => (
                        <tr key={i} className="cursorPointer" onClick={(e) => this.viewRecordDetails(i,e)} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} >
                             { (this.props.reports.headers.NextDate && this.props.reports.headers.NextDate.display) &&
                            <td>{this.props.global.stringDateFormatV3(record.NextDate)}</td>
                             }

                            { (this.props.reports.headers.ParentOrgName && this.props.reports.headers.ParentOrgName.display) &&
                            <td>{record && record.ParentOrgName ? record.ParentOrgName: '-'}</td>
                            }
                            
                            { (this.props.reports.headers.PaypointLegalname && this.props.reports.headers.PaypointLegalname.display) &&
                            <td>{record && record.PaypointLegalname ? record.PaypointLegalname: '-'}</td>
                            }

                            { (this.props.reports.headers.HolderName && this.props.reports.headers.HolderName.display) &&
                            <td>{record.Customer && record.Customer.FirstName ? record.Customer.FirstName + ' ' + record.Customer.LastName:'-' }</td>
                            }

                            { (this.props.reports.headers.customerNumber && this.props.reports.headers.customerNumber.display) &&
                            <td>{record.Customer && record.Customer.CustomerNumber ? record.Customer.CustomerNumber.length > 15 ? record.Customer.CustomerNumber.substring(0, 15) + "..." : record.Customer.CustomerNumber : '-'} </td>
                            }

                            { (this.props.reports.headers.billingEmail && this.props.reports.headers.billingEmail.display) &&
                            <td>{record.Customer && record.Customer.BillingEmail ? record.Customer.BillingEmail : '-'} </td>
                            }
                            
                            { (this.props.reports.headers.payorPhone && this.props.reports.headers.payorPhone.display) &&
                            <td>{record.Customer && record.Customer.BillingPhone ? this.props.global.phoneNumberFormat(record.Customer.BillingPhone): '-'} </td>
                            }
                           
                            { (this.props.reports.headers.billingAddress && this.props.reports.headers.billingAddress.display) &&
                            <td>{record.Customer && record.Customer.BillingAddress1 ? record.Customer.BillingAddress1 +" "+ record.Customer.BillingAddress2+" "+ record.Customer.BillingCity+" "+ record.Customer.BillingState+" "+ record.Customer.BillingZip+" "+ record.Customer.BillingCountry: '-'} </td>
                            }
                            
                            { (this.props.reports.headers.shippingAddress && this.props.reports.headers.shippingAddress.display) &&
                            <td>{record.Customer && record.Customer.ShippingAddress1 ? record.Customer.ShippingAddress1 +" "+ record.Customer.ShippingAddress2+" "+ record.Customer.ShippingCity+" "+ record.Customer.ShippingState+" "+ record.Customer.ShippingZip+" "+ record.Customer.ShippingCountry: '-'} </td>
                            }

                            { (this.props.reports.headers.invoiceNumber && this.props.reports.headers.invoiceNumber.display) &&
                            <td>{record.invoiceData && record.invoiceData.invoiceNumber ? record.invoiceData.invoiceNumber: '-'}</td>
                            }

                            { (this.props.reports.headers.Frequency && this.props.reports.headers.Frequency.display) &&
                            <td>{this.props.global.frequencyText(record.Frequency)}</td>
                            }
                            
                            { (this.props.reports.headers.TotalAmount && this.props.reports.headers.TotalAmount.display) && 
                            <td className="text-right">${this.props.global.numberWithCommas(record.TotalAmount.toFixed(2))}</td>
                            }

                            { (this.props.reports.headers.LeftCycles && this.props.reports.headers.LeftCycles.display) && 
                            <td>{record.UntilCancelled === true ? "Until Cancelled" :record.LeftCycles}</td>
                            }
                            
                            { (this.props.reports.headers.AccountType && this.props.reports.headers.AccountType.display) && 
                            <td className="text-center">
                                {this.props.global.getPaymethodImgPaymentData(record)}
                            </td>
                            }

                            { (this.props.reports.headers.MaskedAccount && this.props.reports.headers.MaskedAccount.display) && 
                            <td>{ this.props.global.maskedCardNumber(record.PaymentData.MaskedAccount, 'v5')}</td>

                            }

                            { (this.props.reports.headers.SubStatus && this.props.reports.headers.SubStatus.display) && 
                            <td>{this.props.global.autopayStatus(record.SubStatus)}</td>
                            }
                            
                            { (this.props.reports.headers.IdSub && this.props.reports.headers.IdSub.display) && 
                            <td>{record.IdSub}</td>
                            }

                            { (this.props.reports.headers.StartDate && this.props.reports.headers.StartDate.display) &&
                            <td>{this.props.global.stringDateFormatV3(record.StartDate)}</td>
                             }

                            { (this.props.reports.headers.notes && this.props.reports.headers.notes.display) && 
                                <td className="text-center">
                                    {record.PaymentData && record.PaymentData.orderDescription ? 
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{record.PaymentData.orderDescription}</Tooltip>}
                                        >
                                        <BiNotepad className="label-info-icon in-input"/>
                                        </OverlayTrigger>
                                    : '-'}
                                </td>
                            }

                            {
                            this.state.customFields.map((field, i) => (
                            (this.props.reports.headers[field.key] && this.props.reports.headers[field.key].display) && 
                                <td key={i}>{record.Customer && record.Customer.AdditionalData && record.Customer.AdditionalData[field.key] ? record.Customer.AdditionalData[field.key] : '-'}</td>
                            ))
                            }

                            <td className="text-center sticky-row">
                                <DropdownButton
                                    menuAlign="center"
                                    title={<BiDotsVerticalRounded/>}
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                >
                                <Dropdown.Item onClick={(e)=> this.viewRecordDetails(i,e)}><div>Quick View</div></Dropdown.Item>
                                <Dropdown.Item onClick={ (e) => this.updateAutopay(record) }>Update Autopay</Dropdown.Item>
                                
                                {record.SubStatus === 1 &&
                                <Dropdown.Item onClick={(e)=> this.pauseAutopay(record.IdSub)}>Pause Autopay</Dropdown.Item>
                                }
                                
                                {record.SubStatus === 0 &&
                                <Dropdown.Item onClick={(e)=> this.continueAutopay(record)}>Continue Autopay</Dropdown.Item>
                                }

                                <Dropdown.Item style={{ color: "rgb(192, 0, 0)" }} onClick={(e) => this.cancelAutopay(record.IdSub)}>Cancel Autopay</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item as={Link} to={record && record.Customer ? "/customer/"+ record.Customer.customerId : ""}> View Customer</Dropdown.Item>
                                
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }
                
                </tbody>
                </table>
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No scheduled payments yet" description={<span>When your customers schedule payments,<br/> you can track their frequency and draft date here.</span>}/>
                }
                        </div>
                        <ReportPaginator report="subscriptions" mode={this.state.idOrg} />

                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { OrgAutopayReport };