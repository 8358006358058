import React from 'react'
import { PayabliStorageManager } from '../api/localStorageManager'
import { inject, observer } from 'mobx-react'

@inject('global')
@observer
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false, error: '' }
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error: error.message }
	}

	componentDidCatch(error, info) {
		this.setState({ error: error.message })
	}
	resetError = () => {
		this.setState({ hasError: false, error: '' })
	}

	render() {
		const urlDashboard = `${
			process.env.REACT_APP_URL_PARTNER_PAGE
		}${PayabliStorageManager.getEntryName()}/dashboard`
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="error-card">
					<div className="error-content">
						<h2>Something went wrong.</h2>
						<p>
							An unexpected error has occurred. Please contact us.
							We are sorry for the inconvenience caused.
							<br />
							<i>
								<strong>{this.state.error}</strong>
							</i>
						</p>
						<a
							className="btn btn-primary"
							href={urlDashboard}>
							Return
						</a>
					</div>
				</div>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundary
