import React, { useState, useEffect, useContext } from 'react'
import styles from '../../assets/css/styleCommandCenter.module.css'
import {
	BiDetail,
	BiUserCheck,
	BiCog,
	BiAddToQueue,
	BiBookmarkPlus,
	BiLock
} from 'react-icons/bi'
import { RiMoneyDollarBoxLine, RiBankLine } from 'react-icons/ri'
import { TopBar } from '../../components/TopBar'
import StatusBar from '../../components/commandCenter/StatusBar'
import { MainTopBarMenu } from '../../components/MainTopBarMenu'
import BusinessDetails from './form/BusinessDetails'
import OwnerShipContacts from './form/OwnerShipContacts'
import ProcessingInformation from './form/ProcessingInformation'
import Services from './form/Services/Services'
import ValueAddedServices from './form/ValueAddedServices'
import BillingFees from './form/BillingFees'
import BankingDocuments from './form/Bancking/BankingDocuments'
import FraudControl from './form/FraudControl'
import PopUpNotify from './modals/PopUpNotify'
import AddServices from './modals/AddService/AddService'
import AddBankAccount from './modals/addBankAccount'
import MainServices from './Services'
import BillingFeesSummary from './BillingFees'
import { Link } from 'react-scroll'
import commandContext from './context/commandCenter_context'

export default function PayPoint({ isLoading }) {
	const { loadData } = useContext(commandContext)

	const [showPopUp, setShowPopUp] = useState({
		show: false,
		messageTitle: '',
		message: '',
		message2: '',
		btnCaption: '',
		btnColor: 'btn-blue',
		functionOk: ''
	})
	const [showMainServices, setShowMainServices] = useState({
		show: false,
		element: '',
		tab: ''
	})
	const [showMainBilling, setShowMainBilling] = useState({
		show: false,
		element: '',
		tab: ''
	})
	const [showAddService, setShowAddService] = useState({
		show: false,
		section: '',
		serviceType: ''
	})
	const [showAddBank, setShowAddBank] = useState({ show: false, count: 0 })

	const [loaded, SetLoaded] = useState(false)

	useEffect(() => {
		if (!loaded) load()
	})

	const load = async () => {
		SetLoaded(true)
		await loadData()
	}

	return (
		<div style={{ marginTop: 0 }}>
			{/* {!isLoading ? (
				<> */}
			{showMainServices.show ? (
				<MainServices
					addBankAccount={(count) =>
						setShowAddBank({ show: true, count: count })
					}
					element={showMainServices.element}
					tabShow={showMainServices.tab}
					changeTabServices={(element) =>
						setShowMainServices({
							...showMainServices,
							element: element
						})
					}
					closeService={() =>
						setShowMainServices({
							...showMainServices,
							show: false,
							element: '',
							tab: 'moneyIn'
						}) | load()
					}
				/>
			) : showMainBilling.show ? (
				<BillingFeesSummary
					element={showMainBilling.element}
					tabShow={showMainBilling.tab}
					changeTabServices={(element) =>
						setShowMainBilling({
							...showMainBilling,
							element: element
						})
					}
					closeService={() =>
						setShowMainBilling({
							...showMainBilling,
							show: false,
							element: '',
							tab: 'moneyIn'
						}) | load()
					}
				/>
			) : (
				<>
					<TopBar>
						<MainTopBarMenu />
						<StatusBar
							backBtnTitle="PayPoint Report"
							hrefBtn="/payabli/report/paypoints"
							SubTitleStatus="Manage your PayPoint easily within this Command Center"
							LabelStatus="PayPoint Status"
							showPopUpMessage={({
								show,
								messageTitle,
								message,
								message2,
								btnCaption,
								functionOk
							}) =>
								setShowPopUp({
									show: show,
									messageTitle: messageTitle,
									message: message,
									message2: message2,
									btnCaption: btnCaption,
									btnColor: 'btn-blue',
									functionOk: functionOk
								})
							}
						/>
					</TopBar>
					<div className={styles['btn-panels']}>
						<Link
							className={styles['btn-tabs']}
							activeClass={styles['active']}
							to="BusinessDetails"
							spy={true}
							smooth={true}
							exact="true"
							offset={-370}
							duration={100}>
							<BiDetail className={styles['icon']} />
							<span>Business Details</span>
						</Link>
						<Link
							className={styles['btn-tabs']}
							activeClass={styles['active']}
							to="OwnerShipContacts"
							spy={true}
							smooth={true}
							exact="true"
							offset={-270}
							duration={100}>
							<BiUserCheck className={styles['icon']} />
							<span>Ownership and Contacts</span>
						</Link>
						<Link
							className={styles['btn-tabs']}
							activeClass={styles['active']}
							to="Processing"
							spy={true}
							smooth={true}
							exact="true"
							offset={-270}
							duration={100}>
							<BiCog className={styles['icon']} />
							<span>Processing Information</span>
						</Link>
						<Link
							className={styles['btn-tabs']}
							activeClass={styles['active']}
							to="Services"
							spy={true}
							smooth={true}
							exact="true"
							offset={-270}
							duration={100}>
							<BiAddToQueue className={styles['icon']} />
							<span>Services</span>
						</Link>
						<Link
							className={styles['btn-tabs']}
							activeClass={styles['active']}
							to="ValueAdded"
							spy={true}
							smooth={true}
							exact="true"
							offset={-270}
							duration={100}>
							<BiBookmarkPlus className={styles['icon']} />
							<span>Value Added Services</span>
						</Link>
						<Link
							className={styles['btn-tabs']}
							activeClass={styles['active']}
							to="Billing"
							spy={true}
							smooth={true}
							exact="true"
							offset={-270}
							duration={100}>
							<RiMoneyDollarBoxLine className={styles['icon']} />
							<span>Billing & Fees</span>
						</Link>
						<Link
							className={styles['btn-tabs']}
							activeClass={styles['active']}
							to="Banking"
							spy={true}
							smooth={true}
							exact="true"
							offset={-270}
							duration={100}>
							<RiBankLine className={styles['icon']} />
							<span>Banking and Documents</span>
						</Link>
						<Link
							className={styles['btn-tabs']}
							activeClass={styles['active']}
							to="Fraud"
							spy={true}
							smooth={true}
							exact="true"
							offset={-270}
							duration={100}>
							<BiLock className={styles['icon']} />
							<span>Fraud Controls</span>
						</Link>
					</div>

					<div className={styles['cards-container'] + ' mb-5'}>
						<BusinessDetails />
						<OwnerShipContacts />
						<ProcessingInformation />
						<Services
							viewServices={({ element, tab }) =>
								setShowMainServices({
									...showMainServices,
									show: true,
									element: element,
									tab: tab
								})
							}
							showPopUpMessage={({
								show,
								messageTitle,
								message,
								message2,
								btnColor,
								btnCaption,
								functionOk
							}) =>
								setShowPopUp({
									show: show,
									messageTitle: messageTitle,
									message: message,
									message2: message2,
									btnCaption: btnCaption,
									btnColor: btnColor,
									functionOk: functionOk
								})
							}
							showAddService={(section, serviceType) =>
								setShowAddService({
									show: true,
									section: section,
									serviceType: serviceType
								})
							}
						/>
						<ValueAddedServices
							showPopUpMessage={({
								show,
								messageTitle,
								message,
								message2,
								btnCaption,
								functionOk
							}) =>
								setShowPopUp({
									show: show,
									messageTitle: messageTitle,
									message: message,
									message2: message2,
									btnCaption: btnCaption,
									btnColor: 'btn-blue',
									functionOk: functionOk
								})
							}
							showAddService={() =>
								setShowAddService({
									show: true,
									section: '',
									serviceType: ''
								})
							}
							viewBilling={() =>
								setShowMainBilling({
									...showMainBilling,
									show: true,
									element: '',
									tab: 'valueAddedServices'
								})
							}
						/>
						<BillingFees
							viewBilling={({ element, tab }) =>
								setShowMainBilling({
									...showMainBilling,
									show: true,
									element: element,
									tab: tab
								})
							}
						/>
						<BankingDocuments
							addBankAccount={(count) =>
								setShowAddBank({ show: true, count: count })
							}
							showPopUpMessage={({
								show,
								messageTitle,
								message,
								message2,
								btnCaption,
								functionOk
							}) =>
								setShowPopUp({
									show: show,
									messageTitle: messageTitle,
									message: message,
									message2: message2,
									btnCaption: btnCaption,
									btnColor: 'btn-red',
									functionOk: functionOk
								})
							}
						/>
						<FraudControl />
					</div>

					{showPopUp.show && (
						<PopUpNotify
							messageTitle={showPopUp.messageTitle}
							message={showPopUp.message}
							message2={showPopUp.message2}
							btnCaption={showPopUp.btnCaption}
							btnColor={showPopUp.btnColor}
							cancelFunction={() => setShowPopUp({ show: false })}
							btnFunctionOk={() => showPopUp.functionOk()}
						/>
					)}

					{showAddService.show && (
						<AddServices
							addBankAccount={(count) =>
								setShowAddBank({ show: true, count: count })
							}
							showAddService={showAddService.show}
							sectionActive={showAddService.section}
							serviceTypeActive={showAddService.serviceType}
							closeModal={() =>
								setShowAddService({
									show: false,
									section: '',
									serviceType: ''
								}) | load()
							}
						/>
					)}
				</>
			)}
			{showAddBank.show && (
				<AddBankAccount
					showModal={showAddBank.show}
					numberBank={showAddBank.count}
					closeModal={() =>
						setShowAddBank({ show: false, count: 0 }) | load()
					}
				/>
			)}
			{/* 		</>
			) : (
				<div
					id="main-loading-layer"
					className="d-flex justify-content-center align-items-center"></div>
			)} */}
		</div>
	)
}
