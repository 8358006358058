import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiPaint, BiCheckCircle, BiAdjust, BiDollarCircle, BiRotateLeft, BiLogOutCircle } from 'react-icons/bi';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import { RenderCustomerInformation } from '../../components/filters/RenderCustomerInformation';
import { RenderCustomFields } from '../../components/filters/RenderCustomFields';
import { RenderFrequency } from '../../components/filters/RenderFrequency';
import { RenderInvoiceNumber } from '../../components/filters/RenderInvoiceNumber';
import { RenderPopoverPaymentTerms } from '../../components/filters/RenderPopoverPaymentTerms';
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'filter')
@observer
class ViewPartialInvoices extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 mb-4'>
                        <RenderInvoiceNumber />
                    </div>
                    <div className='col-sm-6 col-xs-12 mb-4'>
                        <div className="form-floating">
                            <NumberFormat
                                thousandsGroupStyle="thousand"
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={this.props.filter.filterText.paidAmount ? this.props.filter.filterText.paidAmount : ''}
                                placeholder="Paid Amount"
                                className="form-control"
                                onValueChange={(values) => this.props.filter.handleCaptureTextFilterObject('paidAmount(eq)', values, 'paidAmount')}
                                onKeyDown={(e) => this.props.filter.handleKeyDownObject('paidAmount(eq)', e, 'paidAmount')}
                            />
                            <label>{"Paid Amount"}</label>
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 mb-4'>
                        <p><small>Created Date</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="invoiceDateCalendar" 
                                        name="invoiceDateCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startInvoiceDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startInvoiceDate) + ' → ' : '') + (this.props.filter.stateDate.endInvoiceDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endInvoiceDate) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="invoiceDateCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startInvoiceDate ? this.props.filter.stateDate.startInvoiceDate : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startInvoiceDate', 'endInvoiceDate', 'invoiceDate')}
                            startDate={this.props.filter.stateDate.startInvoiceDate}
                            endDate={this.props.filter.stateDate.endInvoiceDate}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endInvoiceDate !== null ? false : true}
                        />
                    </div>
                    <div className='col-sm-6 col-xs-12 mb-4'>
                        <p><small>Due Date</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="dueDateCalendar" 
                                        name="dueDateCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDueDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDueDate) + ' → ' : '') + (this.props.filter.stateDate.endDueDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDueDate) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="dueDateCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDueDate ? this.props.filter.stateDate.startDueDate : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDueDate', 'endDueDate', 'dueDate')}
                            startDate={this.props.filter.stateDate.startDueDate}
                            endDate={this.props.filter.stateDate.endDueDate}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDueDate !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                    <div className='col-sm-6 col-xs-12 mb-4'>
                        <div className="form-floating">
                            <NumberFormat
                                thousandsGroupStyle="thousand"
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={this.props.filter.filterText.invoiceAmount ? this.props.filter.filterText.invoiceAmount : ''}
                                placeholder="Invoice Amount"
                                className="form-control"
                                onValueChange={(values) => this.props.filter.handleCaptureTextFilterObject('totalAmount(eq)', values, 'invoiceAmount')}
                                onKeyDown={(e) => this.props.filter.handleKeyDownObject('totalAmount(eq)', e, 'invoiceAmount')}
                            />
                            <label>"Invoice Amount"</label>
                        </div>
                    </div>
                </div>
                <div>
                    <p><small>Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-light" : "badge text-light"} onClick={() => this.props.filter.handleStatus('status(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiPaint /> Draft</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))} data-qaid="activeStatusInvoiceMoneyIn"><BiCheckCircle /> Active</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', 2, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]))}><BiAdjust /> Partially Paid</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[4]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 4, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[4]))} data-qaid="paidStatusInvoiceMoneyIn"><BiDollarCircle /> Paid</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[99]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('status(in)', 99, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[99]))} data-qaid="paidStatusInvoiceMoneyIn"><BiLogOutCircle /> Canceled</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus['overdue']) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatusPastDue(!(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus['overdue']))}><BiRotateLeft /> Past Due</span>
                    </div>
                </div>
                <ContainerShowMoreLess>
                    <div>
                        <hr className='mt-1 mb-4'></hr>
                        <RenderFrequency />
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                                <p><small>Last Payment Date</small></p>
                                <DatePicker
                                    customInput={
                                        <div className="form-floating">
                                            <input 
                                                id="lastPaymentDateCalendar" 
                                                name="lastPaymentDateCalendar" 
                                                readOnly 
                                                autoComplete="off" 
                                                className={"form-control input-calendar"} 
                                                value={(this.props.filter.stateDate.startDateLastPayment ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateLastPayment) + ' → ' : '') + (this.props.filter.stateDate.endDateLastPayment ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateLastPayment) : '')} 
                                                placeholder="Start Date to End Date" 
                                            />
                                            <label htmlFor="lastPaymentDateCalendar">Start Date to End Date</label>
                                        </div>
                                    }
                                    selected={ this.props.filter.stateDate.startDateLastPayment ? this.props.filter.stateDate.startDateLastPayment : new Date()}
                                    onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateLastPayment', 'endDateLastPayment', 'invoiceLastPaymentDate')}
                                    startDate={this.props.filter.stateDate.startDateLastPayment}
                                    endDate={this.props.filter.endDateLastPayment}
                                    selectsRange={true}
                                    monthsShown={2}
                                    shouldCloseOnSelect={this.props.filter.stateDate.endDateLastPayment !== null ? false : true}
                                />
                            </div>
                            <div className='col-sm-6 mb-4' style={{ marginTop: '20px' }}>
                                <RenderPopoverPaymentTerms />                                
                            </div>
                            <div className='col-sm-6 mb-4'>
                                <div className="form-floating">
                                    <input name="invoiceNotes" id="invoiceNotes" className={"form-control"} placeholder="Notes" onChange={(e) => this.props.filter.handleCaptureTextFilter('invoiceNotes(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('invoiceNotes(ct)', e)} value={this.props.filter.filterText.invoiceNotes ? this.props.filter.filterText.invoiceNotes : ''} />
                                    <label htmlFor="invoiceNotes">Notes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainerShowMoreLess>
                <RenderCustomerInformation />                   
                { Array.isArray(this.props.filter.customFields) && <RenderCustomFields /> }
            </>);
    }
}

export { ViewPartialInvoices };