import React, { useContext } from 'react'
import Switch from 'react-switch'
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import templatesContext from '../../views/Boarding/Template/context/templates_context'

export default function ToggleContainer({ handleChange, fieldData, field, TooltipToggle = '', checkOptions = { state: { visible: true, readOnly: true, required: true } } }) {
	const { LoadingState } = useContext(templatesContext)

	return (
		<div
			className={checkOptions.state.visible && checkOptions.state.readOnly && checkOptions.state.required ? 'col-xl-6 col-md-12' : (checkOptions.state.visible && !checkOptions.state.readOnly && !checkOptions.state.required) || (!checkOptions.state.visible && checkOptions.state.readOnly && !checkOptions.state.required) || (!checkOptions.state.visible && !checkOptions.state.readOnly && checkOptions.state.required) ? 'col-xl-2 col-md-12' : 'col-xl-4 col-md-12'}
			style={checkOptions.state.visible && checkOptions.state.readOnly && checkOptions.state.required && !LoadingState && fieldData[field].required && fieldData[field].readOnly ? { maxWidth: '270px', border: '1px solid red', borderRadius: '5px', display: 'flex', alignItems: 'center', marginLeft: '13px' } : { maxWidth: 'none' }}>
			<div className="row ps-2">
				{(checkOptions.state.visible || checkOptions.state.readOnly || checkOptions.state.required) && (
					<>
						{checkOptions.state.visible && (
							<div
								className="col-md-4 pt-2"
								style={{ maxWidth: '89px' }}>
								<label className="small-small text-center">{checkOptions.label?.visible ? checkOptions.label?.visible : 'Visible'}</label>
								<Switch
									onChange={(value) => handleChange(field, 'visible', value)}
									checked={!LoadingState && [true, null].includes(fieldData[field].visible) ? true : false}
									onColor="#10A0E3"
									onHandleColor="#ffffff"
									handleDiameter={20}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={20}
									width={38}
									className="react-switch"
									id={field + '_v'}
								/>
							</div>
						)}
						{checkOptions.state.readOnly && (
							<div
								className="col-md-4 pt-2"
								style={{ maxWidth: '89px' }}>
								<label className="small-small text-center">{checkOptions.label?.readOnly ? checkOptions.label?.readOnly : 'Read Only'}</label>
								<Switch
									onChange={(value) => handleChange(field, 'readOnly', value)}
									checked={!LoadingState && [true, null].includes(fieldData[field].readOnly) ? true : false}
									onColor="#10A0E3"
									onHandleColor="#ffffff"
									handleDiameter={20}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={20}
									width={38}
									className="react-switch"
									id={field + '_ro'}
								/>
							</div>
						)}
						{checkOptions.state.required && (
							<div
								className="col-md-4 pt-2"
								style={{ maxWidth: '89px' }}>
								<label className="small-small text-center">{checkOptions.label?.required ? checkOptions.label?.required : 'Required'}</label>
								<Switch
									onChange={(value) => handleChange(field, 'required', value)}
									checked={!LoadingState && [true, null].includes(fieldData[field].required) ? true : false}
									onColor="#10A0E3"
									onHandleColor="#ffffff"
									handleDiameter={20}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={20}
									width={38}
									className="react-switch"
									id={field + '_rq'}
								/>
							</div>
						)}
					</>
				)}
			</div>
			{(TooltipToggle || (checkOptions.state.visible && checkOptions.state.readOnly && checkOptions.state.required && !LoadingState && fieldData[field].required && fieldData[field].readOnly)) && (
				<OverlayTrigger
					key={'top'}
					placement={'top'}
					overlay={
						<Tooltip>
							{!TooltipToggle
								? checkOptions.state.visible &&
								  checkOptions.state.readOnly &&
								  checkOptions.state.required &&
								  !LoadingState &&
								  fieldData[field].required &&
								  fieldData[field].readOnly && (
										<>
											The <strong>Read Only</strong> and <strong>Required</strong> fields cannot be active, since the field is either <strong>Required</strong> or <strong>Read Only</strong>.
										</>
								  )
								: TooltipToggle}
						</Tooltip>
					}>
					<BiInfoCircle style={{ fontSize: '15px', color: '#03a9f4' }} />
				</OverlayTrigger>
			)}
		</div>
	)
}
