import React, { useContext } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import {
	BiBookmarkPlus,
	BiDotsVerticalRounded,
	BiShow,
	BiCheck,
	BiCheckCircle,
	BiMinusCircle
} from 'react-icons/bi'
import { RiSettings3Line, RiAddFill } from 'react-icons/ri'
import FrameGroup from '../../../components/commandCenter/FrameGroup'
import { Element } from 'react-scroll'
import commandContext from '../context/commandCenter_context'

export default function ValueAddedServices({
	showPopUpMessage,
	showAddService,
	viewBilling
}) {
	const { PayPoint } = useContext(commandContext)
	const status = {
		tokenization: false,
		splitPay: true,
		invoicing: true,
		ACH: false,
		level: false,
		automaticCardUpdate: false
	}
	//const [status, setStatus] = useState({ tokenization: false, splitPay: true, invoicing: true, ACH: false, level: false, automaticCardUpdate: false })
	const itemService = (caption, description, statusItem) => {
		return (
			<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
				<div
					style={{
						minHeight: '32px',
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap'
					}}>
					<label
						className={
							styles['label-form'] + ' ' + styles['ft-s-13']
						}
						style={{
							fontWeight: '700',
							lineHeight: '20px',
							minWidth: '180px',
							letterSpacing: '0.0025em'
						}}>
						{caption}
					</label>
					<label
						className={
							styles['label-form'] +
							' ' +
							styles['ft-s-13'] +
							' ' +
							styles['size-auto']
						}
						style={{
							fontWeight: '400',
							lineHeight: '20px',
							width: '280px',
							letterSpacing: '0.0025em'
						}}>
						{description}
					</label>
					<div
						style={{
							fontSize: '14px',
							fontWeight: '600',
							paddingInline: '20px'
						}}>
						<div style={{ minWidth: '80px' }}>
							<span
								className={
									statusItem === true
										? 'badge bg-primary'
										: 'badge bg-light'
								}
								style={{
									fontSize: '12px',
									fontWeight: '500'
								}}>
								{statusItem ? (
									<>
										{' '}
										<BiCheckCircle />
										&nbsp;&nbsp;Active
									</>
								) : (
									<>
										{' '}
										<BiMinusCircle />
										&nbsp;&nbsp;Inactive
									</>
								)}
							</span>
						</div>
					</div>
					<button
						onClick={() =>
							showPopUpMessage({
								show: true,
								messageTitle: 'Notice!',
								message: !statusItem
									? 'This action will activate the selected Service.'
									: 'This action will inactivate the selected Service.',
								message2: 'Do you want to continue?',
								btnCaption: !statusItem
									? 'Activate Service'
									: 'Inactivate Service',
								functionOk: () => updateState()
							})
						}
						className={
							styles['btn'] +
							' ' +
							styles['no-border'] +
							' ' +
							styles['color-blue']
						}
						style={{ minWidth: '138px', fontWeight: '500' }}>
						<BiCheck
							className={styles['icon24']}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								border: '2px solid',
								borderRadius: '50%'
							}}
						/>
						{!statusItem ? 'Activate' : 'Inactivate'}
					</button>

					<button
						onClick={() => alert('VIEW DETAILS')}
						style={{ fontWeight: '500' }}
						className={
							styles['btn'] +
							' ' +
							styles['no-border'] +
							' ' +
							styles[
								PayPoint.idPaypoint <= 0 ||
								PayPoint.entryPoints.length <= 0
									? 'color-disabled'
									: 'color-blue'
							]
						}
						disabled={
							PayPoint.idPaypoint <= 0 ||
							PayPoint.entryPoints.length <= 0
								? true
								: false
						}>
						<BiShow className={styles['icon24']} />
						View Details
					</button>
				</div>
			</div>
		)
	}

	const updateState = () => {
		alert('Value Added Service state update')
	}

	return (
		<Element
			name="ValueAdded"
			className="d-flex justify-content-center w-100">
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={
						<BiBookmarkPlus
							className={
								styles['icon26'] + ' ' + styles['color-blue']
							}
						/>
					}
					icon_btn={
						<BiDotsVerticalRounded
							className={
								styles['icon24'] + ' ' + styles['color-gray']
							}
						/>
					}
					title_label="Value Added Services"
					useBtn={false}
					useOptBtn1={true}
					labelOptBtn1="VIEW & MANAGE SERVICE DETAILS"
					classBtn1={
						styles['btn'] +
						' ' +
						styles['no-border'] +
						' ' +
						styles[
							PayPoint.idPaypoint <= 0 ||
							PayPoint.entryPoints.length <= 0
								? 'color-disabled'
								: 'color-blue'
						]
					}
					iconOptBtn1={
						<RiSettings3Line className={styles['icon24']} />
					}
					functionOpt1={() => viewBilling()}
					disabledOpt1={
						PayPoint.idPaypoint <= 0 ||
						PayPoint.entryPoints.length <= 0
							? true
							: false
					}
					useOptBtn2={true}
					labelOptBtn2="ADD SERVICE"
					iconOptBtn2={<RiAddFill className={styles['icon20']} />}
					classBtn2={
						styles['btn'] +
						' ' +
						styles[
							PayPoint.idPaypoint <= 0 ||
							PayPoint.entryPoints.length <= 0
								? 'border-disabled'
								: 'border-blue'
						]
					}
					disabledOpt2={
						PayPoint.idPaypoint <= 0 ||
						PayPoint.entryPoints.length <= 0
							? true
							: false
					}
					functionOpt2={() => alert('add service')}
					//functionOpt2={() => showAddService()}
				/>
				<div className="d-flex flex-column w-100">
					<div className={styles['grid-container']}>
						<div className="d-flex flex-column align-items-start p-0">
							{itemService(
								'Tokenization',
								'Save payment profiles securely',
								status.tokenization
							)}
						</div>
						<div className="d-flex flex-column align-items-start p-0">
							{itemService(
								'Split Pay',
								'Split fund authorizations to bank accounts',
								status.splitPay
							)}
						</div>
						<div className="d-flex flex-column align-items-start p-0">
							{itemService(
								'Invoicing',
								'Send electronic invoices to customers',
								status.invoicing
							)}
						</div>
						<div className="d-flex flex-column align-items-start p-0">
							{itemService(
								'ACH Bank Verification',
								"Verify bank account #'s in real time",
								status.ACH
							)}
						</div>
						<div className="d-flex flex-column align-items-start p-0">
							{itemService(
								'Level II & III',
								'Optimize interchange costs',
								status.level
							)}
						</div>
						<div className="d-flex flex-column align-items-start p-0">
							{itemService(
								'Automatic Card Updater',
								'Auto-update reissued and expired cards',
								status.automaticCardUpdate
							)}
						</div>
					</div>
				</div>
			</div>
		</Element>
	)
}
