import React, { useContext } from 'react'
import GlobalStore from '../../../../../store/GlobalStore'
import GroupRow from '../../../../../components/templates/GroupRow'
import templatesContext from '../../context/templates_context'

export default function ServiceSetup({ type, typeService, gatewayList, processorList }) {
	const { RootTemplate, LoadingState, PDFTemplates, TiersTable, ServicesTemplate, handleChangeService } = useContext(templatesContext)

	return (
		RootTemplate.isRoot && (
			<>
				<strong>Setup</strong>
				<div className="row">
					<div className="col-md-4">
						<GroupRow
							fieldData={!LoadingState && ServicesTemplate[type]}
							parentField={RootTemplate.rootTemplate.servicesData[type]}
							className="col-xl-10 col-md-12"
							handleChange={(property, field, values) => handleChangeService(type, '', property, field, values)}
							field="gateway"
							inputType={{
								title: 'Gateway',
								type: 'select',
								mask: gatewayList(typeService, type)
							}}
							checkOptions={{ state: { visible: false, readOnly: false, required: false } }}
						/>
						<GroupRow
							fieldData={!LoadingState && ServicesTemplate[type]}
							parentField={RootTemplate.rootTemplate.servicesData[type]}
							className="col-xl-10 col-md-12"
							handleChange={(property, field, values) => handleChangeService(type, '', property, field, values)}
							field="processor"
							inputType={{
								title: 'Processor',
								type: 'select',
								mask: processorList(typeService, type)
							}}
							checkOptions={{ state: { visible: false, readOnly: false, required: false } }}
						/>
						<GroupRow
							fieldData={!LoadingState && ServicesTemplate[type]}
							parentField={RootTemplate.rootTemplate.servicesData[type]}
							className="col-xl-10 col-md-12"
							handleChange={(property, field, values) => handleChangeService(type, '', property, field, values)}
							field="provider"
							inputType={{
								title: 'Provider',
								type: 'select',
								mask: GlobalStore.getOptionsType('provider')
							}}
							checkOptions={{ state: { visible: false, readOnly: false, required: false } }}
						/>
						{
						ServicesTemplate[type]?.processor?.value === 'gp' &&
							<GroupRow
								fieldData={!LoadingState && ServicesTemplate[type]}
								parentField={RootTemplate.rootTemplate.servicesData[type]}
								className="col-xl-10 col-md-12"
								handleChange={(property, field, values) => handleChangeService(type, '', property, field, values)}
								field="tierName"
								linkBottonInput='VIEW TIER DETAILS'
								inputType={{
									title: 'Tier Name',
									type: 'select',
									mask: TiersTable
								}}
								checkOptions={{ state: { visible: false, readOnly: false, required: false } }}
							/>
						}
						<GroupRow
							fieldData={!LoadingState && ServicesTemplate[type]}
							parentField={RootTemplate.rootTemplate.servicesData[type]}
							className="col-xl-10 col-md-12"
							handleChange={(property, field, values) => handleChangeService(type, '', property, field, values)}
							field="pdfTemplateId"
							inputType={{
								title: 'PDF Template ID',
								type: 'select',
								mask: PDFTemplates
							}}
							checkOptions={{ state: { visible: false, readOnly: false, required: false } }}
						/>
					</div>
					<div className="col-md-6">
						<GroupRow
							fieldData={!LoadingState && ServicesTemplate[type]}
							parentField={RootTemplate.rootTemplate.servicesData[type]}
							handleChange={(property, field, values) => handleChangeService(type, '', property, field, values)}
							field="discountFrequency"
							inputType={{
								title: 'Discount Frequency',
								type: 'select',
								mask: GlobalStore.getOptionsType(type !== 'mgm' ? 'discount-freq' : 'discount-freq-mgm')
							}}
							checkOptions={{ state: { visible: false, readOnly: false, required: false } }}
						/>
						{type !== 'mgm' && (
							<GroupRow
								fieldData={!LoadingState && ServicesTemplate[type]}
								parentField={RootTemplate.rootTemplate.servicesData[type]}
								handleChange={(property, field, values) => handleChangeService(type, '', property, field, values)}
								field="fundingRollup"
								inputType={{
									title: 'Funding RollUp',
									type: 'select',
									mask: GlobalStore.getOptionsType('funding-type')
								}}
								checkOptions={{ state: { visible: false, readOnly: false, required: false } }}
							/>
						)}
					</div>
				</div>
			</>
		)
	)
}
