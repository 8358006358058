import { observable,makeObservable , action, computed } from "mobx";
import axios from 'axios';
import { PayabliStorageManager } from '../api/localStorageManager'

class EntryStore {

    constructor() {
        makeObservable(this)
    }

    @observable
    statistics = null;

    @observable
    logo: {
        ftype: "",
        filename: null,
        furl: null,
        fContent: ""
    }

    @observable
    previewLogo = null;

    @action
    setPreviewLogo(base64){
        this.previewLogo = base64;
    }

    getEntryFromApi(entry){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Paypoint/' + entry,{
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            }})
            .then(res => {
                return res.data;
            })
            .catch(error => {
                throw error;
            });
    }

    getEntryFromApiBy(id){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Paypoint/basicById/' + id,{
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            }})
            .then(res => {
                return res.data;
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    getEntryStatistics(idEntry){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Statistic/basic/m12/m/2/' + idEntry,{
            headers: {
              'requestToken': encryptStorage.getItem('pToken'),
            }
            })
            .then(res => {
                this.setStatistics(res.data);
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    setStatistics(data){
        this.statistics = data;
    }

    @computed
    get entryDataChart(){
        let data = [];
        data['labels'] = [];
        data['values'] = [0,0,0,0,0,0,0,0,0,0,0,0];
        data['values_position'] = [];
        

        for (let i = 12; i >= 1; i--) {
            let date = this.createDate(0, i - 12, 0);
            data['values_position'][i] = date.getFullYear() + '-' + (date.getMonth()+1)?.toString()?.padStart(2, '0');
            data['labels'][i] = this.getMonthName(date.getMonth(), 1) + " " + date.getFullYear().toString().slice(-2);
        }

        if(this.statistics){
            this.statistics.forEach(function (item) {
                let index = data['values_position'].indexOf(item.statX);
                if(index > -1){
                    data['values'][index] = item.inTransactionsVolume;
                }
            });
        }
        
        data['lastMonthTotal'] = this.statistics && this.statistics[this.statistics.length -1] ? this.statistics[this.statistics.length -1].inTransactionsVolume : 0;

        return data;
    }

    createDate(days, months, years) {
        var date = new Date(); 
        date.setDate(date.getDate() + days);
        date.setMonth(date.getMonth() + months);
        date.setFullYear(date.getFullYear() + years);
        return date;    
    }

    getMonthName(number, short){
        
        let monthNames = [];
        if(short){
            monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
        }
        else{
            monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
            ];
        }
        
        return monthNames[number];
    }

    getOrganization(id){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Organization/read/' + id,{
            headers: {
                'requestToken': encryptStorage.getItem('pToken')
            }})
            .then(res => {
                return res.data;
            })
            .catch(error => {
                throw error;
            });
    }
    
    getOrganizationSettings(id){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Organization/settings/' + id,{
            headers: {
                'requestToken': encryptStorage.getItem('pToken')
            }})
            .then(res => {
                return res.data;
            })
            .catch(error => {
                throw error;
            });
    }
    

    updateOrgInfo(org,orgId){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.put(process.env.REACT_APP_URL_API+ 'Organization/'+orgId,{
            "orgParentId": org.orgParentId,
            "orgName": org.orgName,
            "orgId": org.orgId,
            "orgWebsite": org.orgWebsite,
            "orgAddress": org.orgAddress,
            "orgCity": org.orgCity,
            "orgState": org.orgState,
            "orgZip": org.orgZip,
            "orgCountry": org.orgCountry,
            "orgType": org.orgType,
            "orgLogo": org.orgLogo,
            "billingInfo": org.billingInfo,
            "orgTimezone": org.orgTimezone,
            "replyToEmail": org.replyToEmail,
        },
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

    newOrgInfo(org) {
        console.log(org);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.post(process.env.REACT_APP_URL_API + 'Organization', {
            "orgParentId": org.orgParentId,
            "orgName": org.orgName,
            "orgId": org.orgId,
            "orgWebsite": org.orgWebsite,
            "orgAddress": org.orgAddress,
            "orgCity": org.orgCity,
            "orgState": org.orgState,
            "orgZip": org.orgZip,
            "orgCountry": org.orgCountry,
            "orgType": org.orgType,
            "orgLogo": org.orgLogo,
            "billingInfo": org.billingInfo,
            "orgTimezone": org.orgTimezone,
            "replyToEmail": org.replyToEmail,
        },
        {
            headers: { 'requestToken': encryptStorage.getItem('pToken') }
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });
            
    }

    parseNotificationByFrequency(notifications = [], frequency){
        return notifications.reduce((acc, notification) => {
            if(notification?.method === 'report-email' || notification?.frequency === frequency){
                acc.push(notification)
            }
            return acc
        }, [])
    }

    getNotificationsOrg(orgId, limit, frequency){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'query/notifications/org/' + orgId + (limit ? ("?limitRecord="+limit):""),{
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            }
            })
            .then(res => {
                const records = res.data.Records || []
                return {
                    Records: this.parseNotificationByFrequency(records, frequency)
                };
            })
            .catch(error => {
                throw error;
            });
    }


    updateOrgSettings(orgId, customFields, notifications, identifiers, forPayOuts, general){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.put(process.env.REACT_APP_URL_API+ 'Organization/settings/'+orgId,{
            "customFields": customFields ? customFields : null,
            "reportsNotifications": notifications ? notifications : null,
            "identifiers": identifiers ? identifiers : null,
            "forPayOuts": forPayOuts ? forPayOuts : null,
            "general": general ? general : null,
        },
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

    updateOrgLogo(org){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.put(process.env.REACT_APP_URL_API+ 'Organization/'+org.orgId,{
            "orgLogo": org.orgLogo,
            "orgName": org.orgName,
        },
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

    @action
    setLogo(base64, ext) {
        
        if(base64 &&  ext){
            this.logo = {
                ftype: ext,
                filename: null,
                furl: null,
                fContent:base64
            }  
        }
        
    }

    deleteOrganization(orgId){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.delete(process.env.REACT_APP_URL_API+ 'Organization/' + orgId,
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

    getPaypointSettings(entry){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Paypoint/settings/' + entry,{
            headers: {
                'requestToken': encryptStorage.getItem('pToken')
            }})
            .then(res => {
                return res.data;
            })
            .catch(error => {
                throw error;
            });
    }


}

const entryStore = new EntryStore();
export default entryStore;