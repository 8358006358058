import React from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import SwitchContainer from '../../inputStyles/switchContainer'

export default function GroupStateService({ labelInfo, status = false, toggelBtn = '', toggelBtnTooltip = '', toggleValue = false, toggleDisabled = false, togglehandleChange = () => null, children }) {
	return (
		<div style={{ width: '100%' }}>
			<div
				className="d-flex flex-row align-items-center justify-content-between w-100 pe-2"
				style={{ paddingBottom: '15px' }}>
				<div className="d-flex flex-row align-items-center justify-content-start">
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{
							paddingLeft: '12px',
							fontWeight: '600',
							lineHeight: '24px'
						}}>
						{labelInfo}
					</label>
					<div
						style={{
							fontSize: '14px',
							fontWeight: '600',
							paddingInline: '20px'
						}}>
						<span
							className={status ? 'badge bg-primary ' + styles['rounded-pill-no-label'] : 'badge bg-light ' + styles['rounded-pill-no-label']}
							style={{
								fontSize: '12px',
								fontWeight: '500',
								minWidth: '73px',
								height: '22px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: '5px'
							}}>
							{status ? 'Active' : 'Inactive'}
						</span>
					</div>
				</div>
				{toggelBtn && (
					<SwitchContainer
						onChange={(values) => togglehandleChange(values)}
						checked={toggleValue}
						onColor="#70AD47"
						onHandleColor="#ffffff"
						inputDisabled={toggleDisabled}
						handleDiameter={20}
						height={20}
						width={38}
						labelStyle={{ color: '#212529', padding: '0' }}
						labelValue={toggelBtn}
						className="react-switch"
						classNameLabel={styles['label-form'] + ' ' + styles['ft-s-12']}
						textAlignEnd={false}
						TooltipCaption={toggelBtnTooltip}
					/>
				)}
			</div>
			{children}
		</div>
	)
}
