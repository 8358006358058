import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {IMaskInput} from 'react-imask';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import { Brand } from '../../components/Brand';
import { PayabliStorageManager } from '../../api/localStorageManager';

@inject('global','user')
@observer
class ForgotPassword extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            email: "",
            entry:null,
            formErrors: {},
        };
    
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.sendEmailToResetPassword = this.sendEmailToResetPassword.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }

    handleKeyDown(e){
        if (e.key === 'Enter') {
            this.sendEmailToResetPassword();
          }
    }
    
    componentDidMount() {
        let entry = PayabliStorageManager.getEntryName();
        this.setState({entry:entry});
    }

    handleTextChange(event) {
        this.setState({ [event.target.name] : event.target.value});
    }

    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }

    sendEmailToResetPassword(){
        let errors = this.validateFields();
        this.setState({ formErrors: errors }, function(){
            if(Object.keys(errors).length === 0){
                
                var user ={
                    email: this.state.email,
                    entry: this.state.entry,
                }
              
                this.props.global.setLoading(true);
                this.props.user.sendEmailToResetPassword(user)
                .then(result => {
                    this.props.global.setLoading(false);
                    toast.success("Reset password link sent to your email", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });

                    setTimeout( function(){
                        window.location = "/"+user.entry+"/login";
                    }, 5000 );
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response.data.responseText ? error.response.data.responseText : "Something went wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
                
            }
        });
        
    }

    validateFields(){
        let validators = this.props.global.validators;
        let errors = {};
       

        if(validators.isEmpty(this.state.email) || validators.isMaxLength(250, this.state.email) || validators.stringValidator('email',this.state.email))
        {
            errors['email']= true;
        }

        return errors;
    }

    render() {
        return (
        <>
        {this.props.global.isLoading &&
                <div id="main-loading-layer" className="d-flex justify-content-center align-items-center">
                   <div className="spinner-border" role="status">
                    </div>
                </div>
                }
            <div className="root-page text-center">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{height: "100%", position: "absolute", width: "100%"}}>
                <div className="card-login mb-3" style={{width: '35em'}}>
                    
                    <Brand brand={this.state} classBrand={'loginBrand'}/>
                    
                    <p className="grey mb-4">Enter your email to reset password.</p>
                        <div className="form-floating mb-4">
                            <IMaskInput
                                mask={this.props.global.maskValidator('email')}
                                name="email"
                                value={ this.state.email }
                                unmask={true}                                
                                onAccept={
                                    (value, mask) => this.handleTextChangeMask('email', value)
                                }
                                placeholder="Email"
                                className={this.state.formErrors.email ? "form-control input-error" : "form-control"}
                                id="email"
                                onKeyDown={(e)=>this.handleKeyDown(e)}
                            />
                            <label htmlFor="email">Email</label>
                        </div>

                       
                        {
                            this.state.error &&
                            (
                                <div className="text-danger mb-3 small">{this.state.error}</div>
                            )
                        }

                       
                       
                        <button onClick={() => this.sendEmailToResetPassword()} className="full-w btn btn-lg btn-success mb-4" >Send Email</button>

                        <p className="mb-2 small">Do you remember password? </p>
                        <Link className="small" to={"/"+this.state.entry+"/login"}>Sign In</Link>
              
                </div>
                <p className="small-small">Powered by  <a href="/" className="small no-underline">Payabli</a></p>
            </div>
            </div>
            <ToastContainer transition={Bounce} />
        </>
        )
    }
}

export { ForgotPassword };