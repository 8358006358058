import React, { useContext } from 'react'
import SwitchStyles from './SwitchStyles'
import InputContainer from '../inputStyles/InputContainer'
import templatesContext from '../../views/Boarding/Template/context/templates_context'

export default function GroupSwitch({ groupFields, handleChange }) {
	const { LoadingState } = useContext(templatesContext)

	return (
		<div className="row m-1">
			{groupFields.map((data, index) => (
				<div
					key={data.property + data.field + index}
					className={!data?.classGroup ? 'col-3' : data?.classGroup}>
					{data.field !== '' ? (
						data.isCheck ? (
							<SwitchStyles
								labelUp={data?.labelUp}
								label={data.title}
								isString={data?.isString}
								styles={data?.styles}
								classLabel={data?.classLabel}
								onChange={(value) => handleChange(data?.subProperty0, data?.subProperty, data.property, data.field, value)}
								checkValue={!LoadingState && [true, 'true', null].includes(data.fieldData[data.property][data.field]) ? true : false}
								id={data.property}
							/>
						) : (
							<InputContainer
								inputType={data.inputType}
								divClass="form-group"
								labelClassName="small"
								classNameInput={`form-control ${data?.classNameInput}`}
								//isLoading={LoadingState}
								inputDisabled={false}
								prefix={data?.prefix}
								suffix={data?.suffix}
								maxValue={data?.maxValue}
								decimalScale={data?.decimalScale}
								allowedNullDate={data?.allowedNullDate}
								labelValue={data.title}
								maxLength={data?.maxLength}
								name={data.field}
								placeholder={data?.placeholder}
								value={!LoadingState && (data.inputType !== 'dateBox' ? (data.inputType === 'number' && ['', null].includes(data.fieldData[data.property][data.field]) ? 0 : data.fieldData[data.property][data.field]) : data?.allowedNullDate && data.fieldData[data.property][data.field] === null ? null : new Date(data.fieldData[data.property][data.field]))}
								setValue={(values) => handleChange(data?.subProperty0, data?.subProperty, data.property, data.field, data.inputType === 'number' && ['', null].includes(values) ? 0 : values)}
								mask={data.mask}
							/>
						)
					) : (
						data.title !== '' && <label>{data.title}</label>
					)}
				</div>
			))}
		</div>
	)
}
