import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('reports', 'filter')
@observer
class RenderSource extends React.Component {

    render() {
        return (<>
                <p className='mb-1'><small>Source</small></p>
                <div className='row mb-3' style={{fontSize: '20px', paddingLeft: '10px'}}>
                    <div className='col-sm-4'>
                        <div className='form-group'>
                            <div className="icheck-primary">
                                <input type="checkbox" value="api" id="sourceCheckApi" checked={this.props.filter.filterSource.sourceCheckApi} onChange={(e) => this.props.filter.handleSource(e)} />
                                <label htmlFor="sourceCheckApi">API</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="checkbox" value="payment page" id="sourceCheckPaymentPage" checked={this.props.filter.filterSource.sourceCheckPaymentPage} onChange={(e) => this.props.filter.handleSource(e)} />
                                <label htmlFor="sourceCheckPaymentPage">Payment Page</label>
                            </div>                                                    
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='form-group'>
                            <div className="icheck-primary">
                                <input type="checkbox" value="virtual terminal device" id="sourceCheckDevice" checked={this.props.filter.filterSource.sourceCheckDevice} onChange={(e) => this.props.filter.handleSource(e)} />
                                <label htmlFor="sourceCheckDevice">Device</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="checkbox" value="autojob" id="sourceCheckAutojob" checked={this.props.filter.filterSource.sourceCheckAutojob} onChange={(e) => this.props.filter.handleSource(e)} />
                                <label htmlFor="sourceCheckAutojob">Autojob</label>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='form-group'>                                                    
                            <div className="icheck-primary">
                                <input type="checkbox" value="virtual terminal" id="sourceCheckVirtualTerminal" checked={this.props.filter.filterSource.sourceCheckVirtualTerminal} onChange={(e) => this.props.filter.handleSource(e)} />
                                <label htmlFor="sourceCheckVirtualTerminal">Virtual Terminal</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="checkbox" value="other" id="sourceCheckOther" checked={this.props.filter.filterSource.sourceCheckOther} onChange={(e) => this.props.filter.handleSource(e)} />
                                <label htmlFor="sourceCheckOther">Other</label>
                            </div>
                            {
                                this.props.filter.filterSource.sourceCheckOther && 
                                (<div className="form-floating">
                                    <input name="sourceCheckOtherText" id="sourceCheckOtherText" className={"form-control"} placeholder="Other" onChange={(e) => this.props.filter.handleCaptureTextFilter('source(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('source(ct)', e)} value={this.props.filter.filterText.sourceCheckOtherText ? this.props.filter.filterText.sourceCheckOtherText : ''} />
                                    <label htmlFor="sourceCheckOtherText">Other</label>
                                </div>)
                            }
                        </div>
                    </div>                                            
                </div>
            </>);
    }
}

export { RenderSource };