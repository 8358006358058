import axios from 'axios'
import { PayabliStorageManager } from '../../../api/localStorageManager'
import GlobalStore from '../../GlobalStore'
let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()

export const templateMasterGET = async (orgId) => {
	const res = await axios.get(
		process.env.REACT_APP_URL_API + 'Templates/master/' + orgId,
		{
			headers: {
				requestToken: encryptStorage.getItem('pToken')
			}
		}
	)
	return res
}
export const templateGET = async (Id) => {
	const res = await axios.get(
		process.env.REACT_APP_URL_API + 'Templates/get/' + Id,
		{
			headers: {
				requestToken: encryptStorage.getItem('pToken')
			}
		}
	)
	return res
}
export const userOneTimePUT = async () => {
	const res = await axios.put(
		process.env.REACT_APP_URL_API + 'User/onetime/1',
		{},
		{
			headers: {
				requestToken: encryptStorage.getItem('pToken')
			}
		}
	)
	return res
}
export const templatesV1GET = async (uniqueUserToken) => {
	const res = await axios.get(
		process.env.REACT_APP_BACKEND_URL + '/v1/templates',
		{
			headers: {
				requestToken: uniqueUserToken,
				'x-api-key': `${process.env.REACT_APP_XAPI_KEY}`,
				Authorization: `Basic ${process.env.REACT_APP_BACKEND_AUTH_TOKEN}`
			}
		}
	)
	return res
}
export const templatesColumnsV1GET = async (uniqueUserToken, templateId) => {
	const res = await axios.get(
		`${process.env.REACT_APP_BACKEND_URL}/v1/template/getTemplate/${templateId}`,
		{
			headers: {
				requestToken: uniqueUserToken,
				'x-api-key': `${process.env.REACT_APP_XAPI_KEY}`,
				Authorization: `Basic ${process.env.REACT_APP_BACKEND_AUTH_TOKEN}`
			}
		}
	)
	return res
}

export const updateTemplateV1PUT = async (
	uniqueUserToken,
	templateId,
	body
) => {
	return await axios.put(
		`${process.env.REACT_APP_BACKEND_URL}/v1/template/updateTemplate/${templateId}`,
		body,
		{
			headers: {
				requestToken: uniqueUserToken,
				Authorization: `Basic ${process.env.REACT_APP_BACKEND_TEMPLATES_TOKEN}`
			}
		}
	)
}
export const helloSignMappingData = async (
	uniqueUserToken,
	templateData,
	serviceData
) => {
	return await axios.post(
		process.env.REACT_APP_BACKEND_URL + '/v1/mappingData',
		{
			templateData: templateData,
			serviceData: serviceData
		},
		{
			headers: {
				requestToken: uniqueUserToken,
				'x-api-key': `${process.env.REACT_APP_XAPI_KEY}`,
				Authorization: `Basic ${process.env.REACT_APP_BACKEND_AUTH_TOKEN}`
			}
		}
	)
}

export const templateFieldsV1GET = async (uniqueUserToken, templateId) => {
	const res = await axios.get(
		`${process.env.REACT_APP_BACKEND_URL}/v1/fields/${templateId}`,
		{
			headers: {
				requestToken: uniqueUserToken,
				'x-api-key': `${process.env.REACT_APP_XAPI_KEY}`,
				Authorization: `Basic ${process.env.REACT_APP_BACKEND_AUTH_TOKEN}`
			}
		}
	)
	return res
}
export const createTemplatePOST = async (orgId, template) => {
	const res = await axios.post(
		process.env.REACT_APP_URL_API + 'Templates/' + orgId,
		template,
		{
			headers: {
				requestToken: encryptStorage.getItem('pToken')
			}
		}
	)
	return res
}
export const getMasterTemplate = async () => {
	let orgId = encryptStorage.getItem(
		GlobalStore.getURLEntry() + '_pEntry'
	).orgId
	return await axios.get(
		`${process.env.REACT_APP_URL_API}Templates/master/${orgId}`,
		{
			headers: {
				requestToken: encryptStorage.getItem('pToken')
			}
		}
	)
}
export const updateTemplatePUT = async (orgId, template) => {
	const res = await axios.put(
		process.env.REACT_APP_URL_API + 'Templates/' + orgId,
		template,
		{
			headers: {
				requestToken: encryptStorage.getItem('pToken')
			}
		}
	)
	return res
}
export const organizationsTreeGET = async (orgId) => {
	const res = await axios.get(
		process.env.REACT_APP_URL_API + 'Query/organizations-tree/' + orgId,
		{
			headers: {
				requestToken: encryptStorage.getItem('pToken')
			}
		}
	)
	return res
}

export const getTiersDetailsGET = async () => {
	let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
	const entryName = PayabliStorageManager.getEntryName();
	const {orgId} = encryptStorage.getItem(`${entryName}_pEntry`)
	return await axios.get(
 		`${process.env.REACT_APP_URL_API}Query/tiers/org/${orgId}`,
 		{
 			headers: {
 				requestToken: encryptStorage.getItem('pToken')
 			}
 		}
 	);
}
