import axios from 'axios'
import { PayabliStorageManager } from '../../../api/localStorageManager'

let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()

export const managementAuthGET = async () => {
	const res = await axios.get(process.env.REACT_APP_URL_API + 'Management/auth', {
		headers: {
			requestToken: encryptStorage.getItem('pToken')
		}
	})
	return res
}
