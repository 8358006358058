import React, { useEffect, useState } from 'react'
import styles from '../../assets/css/styleCommandCenter.module.css'
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle'
import SelectSearch from 'react-select-search'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import LoaderPlaceHolder from '../loaderPlaceHolder'
import Fuse from 'fuse.js'
import GlobalStore from '../../store/GlobalStore'
import { BiLinkExternal } from 'react-icons/bi'

export default function SelectBoxContainer({
	inputDisabled,
	labelValue,
	name,
	required = false,
	refs,
	value = '',
	classNameInput = '',
	inputStyle = {},
	setValue,
	optionList,
	placementValid = 'right',
	placement = 'right',
	TooltipValid = '',
	TooltipValidColorIcon = '',
	TooltipValidStyle = {},
	TooltipCaption = '',
	onBlur = () => null,
	useElement = false,
	listElement = [],
	listBtnElement = [],
	placeholder = '',
	maxHeightScroll = 'none',
	divClass = '',
	divStyle = {},
	linkBottonInput = '',
	labelStyle = {},
	labelClassName = '',
	selectSearch = false,
	isLoading = false,
	placeholderStyle = { height: '39px', width: '100%', borderRadius: '.25rem' }
}) {
	const [showSelect, setShowSelect] = useState(false)
	const [dataListElement, setDataListElement] = useState(listElement)

	useEffect(() => {
		if (dataListElement !== listElement && useElement) {
			setDataListElement(listElement)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listElement])

	const handleChange = (e) => {
		!selectSearch ? setValue(e.target.value) : setValue(e)
	}
	const selectOption = (value) => {
		setValue(value)
		setShowSelect(false)
		const newDataListElement = dataListElement.map((data) => {
			return {
				...data,
				select: data.value === value ? true : false
			}
		})
		setDataListElement(newDataListElement)
	}
	const findSelectOption = (id) => {
		const data = dataListElement.find(({ value }) => value === id)
		if (data) return data.text
		return placeholder
	}
	const fuzzySearch = (options) => {
		const fuse = new Fuse(options, {
			keys: ['name', 'groupName', 'items.name'],
			threshold: 0.3
		})

		return (value) => {
			if (!value.length) {
				return options
			}

			let result = fuse.search(value)
			let resultArray = []
			result.forEach(function (item) {
				resultArray.push(item.item)
			})
			return resultArray
		}
	}

	return (
		<div
			className={divClass}
			key={`select-container-selection-${name}`}
			onMouseLeave={() => setShowSelect(false)}
			style={divStyle}>
			{!TooltipCaption ? (
				<label
					className={labelClassName}
					style={labelStyle}>
					{labelValue}
				</label>
			) : (
				<label
					className={labelClassName}
					style={labelStyle}>
					{labelValue} &nbsp;
					<OverlayTrigger
						key={placement}
						placement={placement}
						overlay={<Tooltip>{TooltipCaption}</Tooltip>}>
						<BiInfoCircle
							className={
								styles['icon15'] + ' ' + styles['color-blue']
							}
						/>
					</OverlayTrigger>
				</label>
			)}
			{isLoading ? (
				<LoaderPlaceHolder extraStyles={placeholderStyle} />
			) : !selectSearch && !useElement ? (
				<div className="d-flex flex-column align-items-end">
					<select
						ref={refs}
						onChange={handleChange}
						onBlur={onBlur}
						style={inputStyle}
						className={classNameInput}
						name={name}
						id={name}
						required={required}
						disabled={inputDisabled}
						value={value ? value : ''}> 
							{optionList}
					</select>
				{linkBottonInput !== '' && (
							<a
								style={{
									textDecoration: 'none',
									cursor: 'pointer',
									color: '#10a0e3 !important'
								}}
								target="_blank"
								href={`/${GlobalStore.getURLEntry()}/report/tiers`}
								rel="noopener noreferrer"
								data-qaid="templateGoToTiersView">
								{linkBottonInput} <BiLinkExternal />
							</a>
						)}
					</div>
			) : selectSearch ? (
				<SelectSearch
					//printOptions={'always' }
					ref={refs}
					options={optionList}
					//className="select-search"
					className={`select-search ${classNameInput}`}
					style={inputStyle}
					autoComplete={'selectSearch'}
					search
					filterOptions={fuzzySearch}
					emptyMessage="Not found"
					placeholder={placeholder}
					id={name || 'selectSearch'}
					value={value}
					onChange={(e) => handleChange(e)}
					disabled={inputDisabled ? inputDisabled : false}
				/>
			) : (
				useElement && (
					<div className={styles['select-input-box-container']}>
						<label
							name={name}
							id={name}
							required={required}
							disabled={inputDisabled}
							onBlur={onBlur}
							onClick={() =>
								!inputDisabled && setShowSelect(!showSelect)
							}
							className={
								!inputDisabled
									? `form-control form-select ${
											styles['select-input-box'] +
											' ' +
											styles['textField'] +
											' ' +
											styles['select-box'] +
											' ' +
											classNameInput
									  }`
									: `${
											styles['textField'] +
											' ' +
											styles['overflow-hidden'] +
											' ' +
											styles['disabled'] +
											' ' +
											styles['select-box'] +
											' ' +
											classNameInput
									  }`
							}
							style={inputStyle}>
							{findSelectOption(value)}
						</label>
						<div
							className={
								showSelect
									? styles['select-input-box-opciones'] +
									  ' ' +
									  styles['show']
									: styles['select-input-box-opciones']
							}>
							{listBtnElement.length > 0 && (
								<div
									className={
										styles[
											'select-input-box-button-container'
										]
									}>
									{listBtnElement.map((data, index) => (
										<div
											key={'select-button-box' + index}
											className={
												data.class
													? styles[data.class]
													: ''
											}
											style={data.style}>
											{data.button}
										</div>
									))}
								</div>
							)}

							<div
								className={
									styles['select-input-box-item-container']
								}
								style={{ maxHeight: maxHeightScroll }}>
								{dataListElement.length > 0 &&
									dataListElement.map((data, index) => (
										<div
											key={'select-input-box' + index}
											className={
												data.select
													? styles[
															'select-input-box-item'
													  ] +
													  ' ' +
													  styles['item-selected']
													: styles[
															'select-input-box-item'
													  ]
											}
											onClick={() =>
												selectOption(data.value)
											}>
											{data.element}
										</div>
									))}
							</div>
						</div>
					</div>
				)
			)}
			{TooltipValid && (
				<OverlayTrigger
					key={placementValid}
					placement={placementValid}
					overlay={<Tooltip>{TooltipValid}</Tooltip>}>
					<div style={TooltipValidStyle}>
						<BiInfoCircle
							className={`${styles['icon15']} ${
								styles[
									!TooltipValidColorIcon
										? 'color-blue'
										: TooltipValidColorIcon
								]
							}`}
						/>
					</div>
				</OverlayTrigger>
			)}
		</div>
	)
}
