import React, { useContext } from 'react'
import { Button, Card, Row } from 'react-bootstrap'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { BiTrash } from '@react-icons/all-files/bi/BiTrash'
import InputContainer from '../../../../components/inputStyles/InputContainer'
import GlobalStore from '../../../../store/GlobalStore'
import templatesContext from '../context/templates_context'

export default function DocumentLink() {
	const { addLink, deleteLink, LoadingState, BankingTemplate, handleChangeBankLink } = useContext(templatesContext)

	const dataLinks = (index) => {
		return (
			<div className="row p-1">
				<div className="col-md-6 mb-3">
					<InputContainer
						inputType="mask"
						divClass="form-group"
						labelClassName="font-weight-bold"
						classNameInput={`form-control ${BankingTemplate.termsAndConditions.visible && (BankingTemplate.termsAndConditions.tcLinks[index].label === null || BankingTemplate.termsAndConditions.tcLinks[index].label.trim().length === 0) ? 'input-error' : ''}`}
						//isLoading={LoadingState}
						inputDisabled={false}
						TooltipValid={BankingTemplate.termsAndConditions.visible && (BankingTemplate.termsAndConditions.tcLinks[index].label === null || BankingTemplate.termsAndConditions.tcLinks[index].label.trim().length === 0) ? 'This field is required' : ''}
						TooltipValidColorIcon="color-red"
						TooltipValidStyle={{ position: 'relative', width: '100%', textAlign: 'right', height: 0, top: '-30px', paddingRight: '7px' }}
						labelValue="T&C Link Description"
						name={'label_' + index}
						placeholder="T&C Link Description"
						value={BankingTemplate.termsAndConditions.tcLinks[index].label}
						setValue={(values) => handleChangeBankLink(index, 'label', values)}
						mask={GlobalStore.maskValidator('text')}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<InputContainer
						inputType="mask"
						divClass="form-group"
						labelClassName="font-weight-bold"
						classNameInput={`form-control ${BankingTemplate.termsAndConditions.visible && (BankingTemplate.termsAndConditions.tcLinks[index].value === null || BankingTemplate.termsAndConditions.tcLinks[index].value.trim().length === 0) ? 'input-error' : ''}`}
						//isLoading={LoadingState}
						inputDisabled={false}
						TooltipValid={BankingTemplate.termsAndConditions.visible && (BankingTemplate.termsAndConditions.tcLinks[index].value === null || BankingTemplate.termsAndConditions.tcLinks[index].value.trim().length === 0) ? 'This field is required' : ''}
						TooltipValidColorIcon="color-red"
						TooltipValidStyle={{ position: 'relative', width: '100%', textAlign: 'right', height: 0, top: '-30px', paddingRight: '7px' }}
						labelValue="Link (ex: https://www.payabli.com)"
						name={'value_' + index}
						placeholder="Link"
						value={BankingTemplate.termsAndConditions.tcLinks[index].value}
						setValue={(values) => handleChangeBankLink(index, 'value', values)}
						mask={GlobalStore.maskValidator('text')}
					/>
				</div>
			</div>
		)
	}

	return (
		<>
			<Card className="card card-in mb-3 card-hover">
				<Card.Header>
					<div className="row">
						<div
							className="col-1"
							style={{
								fontSize: '30px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}>
							{<BsFillExclamationCircleFill color="limegreen" /> || <BsFillExclamationCircleFill color="red" />}
						</div>
						<div className="col-11">
							<p style={{ textAlign: 'justify' }}>
								By clicking below I agree and hereby confirm to have Read, Agreed and Consented to the{' '}
								{!LoadingState &&
									BankingTemplate.termsAndConditions.tcLinks.map((data, index) => (
										<span key={'tcLinks' + index}>
											{index > 0 ? (index === BankingTemplate.termsAndConditions.tcLinks.length - 1 ? ' and ' : ', ') : ''}
											<span className="text-info"> {data['label']}</span>{' '}
											<a
												style={{ textDecoration: 'none' }}
												rel="noreferrer"
												term={index}
												target="_blank"
												href={data['value']}>
												(CLICK HERE)
											</a>
										</span>
									))}
								<span>, provided. Please Review and Save these documents provided in links above.</span>
							</p>
						</div>
					</div>
				</Card.Header>
				<Card.Body>
					<Row style={{ alignItems: 'center' }}>
						<div
							className="col-1"
							style={{
								fontSize: '30px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}>
							<input
								style={{ backgroundColor: '#10A0E3', borderColor: '#10A0E3', minHeight: '22px', marginTop: '6px!important', marginBottom: '6px!important', paddingLeft: 0, width: '22px', height: '22px' }}
								type="checkbox"
								name="acceptance"
								id="acceptance"
							/>
						</div>
						<div className="col-11">
							<label
								style={{ fontSize: '12px' }}
								htmlFor="acceptance">
								I Agree to the above information & I attest that the information provided in this application is correct to the best of my knowledge as an authorized signer for this business.
							</label>
						</div>
					</Row>
				</Card.Body>
			</Card>
			{!LoadingState &&
				BankingTemplate.termsAndConditions.tcLinks.map((data, index) => (
					<div key={'dataLinks' + index}>
						{index > 0 && (
							<div
								className="col-12 text-right"
								style={{ marginBottom: '-23px', position: 'relative' }}>
								<Button
									variant="danger"
									className="mb-1"
									onClick={() => deleteLink(index)}
									style={{ marginRight: '5px' }}>
									<BiTrash style={{ fontSize: '14px' }} />
								</Button>
							</div>
						)}
						{dataLinks(index)}
					</div>
				))}
			<div id="addLink">
				<Button
					className="btn bordered no-margin mb-4"
					onClick={() => addLink()}>
					+
				</Button>
			</div>
		</>
	)
}
