import { EncryptStorage } from 'encrypt-storage';
import md5 from 'crypto-js/md5';

export class PayabliStorageManager {
    static getEntryName = () => {
        let url = new URL(window.location.href);
        let pathname = url.pathname.split('/')[1];
        return pathname;
    }
   
    static #localStorage = new EncryptStorage(md5(navigator.userAgent.toLowerCase()).toString());
    static #KEY = localStorage.getItem(`${PayabliStorageManager.getEntryName()}_dataStorage`) ? 
        this.#localStorage.getItem(`${PayabliStorageManager.getEntryName()}_storageKey`) : 
        md5(process.env.REACT_APP_TOKEN).toString();
    static #getStorageType = (key, migrate) => {
        return key === md5(process.env.REACT_APP_TOKEN).toString()?
            new EncryptStorage(key):
            new PayabliEncryptStorage(key, migrate);
    }
    static getEncryptedLocalStorage = () => {
        try {
            return this.#getStorageType(this.#KEY);
        }
        catch(e) {
            let reloadTimes = window.localStorage.getItem("payabliwebapp_refreshattempt") ? window.localStorage.getItem("payabliwebapp_refreshattempt") : 0 ;
            if(parseInt(reloadTimes) === 0){
                window.localStorage.setItem("payabliwebapp_refreshattempt", 1);
            }else{
                window.localStorage.setItem("payabliwebapp_refreshattempt", parseInt(reloadTimes) + 1);
            }

            if(parseInt(reloadTimes) > 10){
                this.clearStorage();
            }
            else{
                window.location.reload();
            }
        }
    }
    static setEncryptedLocalStorageKey = (key) => {
        var oldStK = this.#KEY;
        let newStK = md5(key).toString();
        if(oldStK && oldStK !== newStK) {
            try{
                PayabliStorageManager.#migrateStorageData(oldStK,newStK,key)
                this.#KEY = newStK;
            }
            catch(e){}
           
        }
        
        PayabliStorageManager.reloading();
        return this.#KEY;
    }
    static #migrateStorageData = (oldStK,newStK,pToken) => {
        let oldEncryptStorage = this.#getStorageType(oldStK);
        let newEncryptStorage = this.#getStorageType(newStK, true);
        let dataStorage = {};
        for (let index = 0; index < oldEncryptStorage.length; index++) {
            let key = oldEncryptStorage.key(index);
            if(key === "pToken"){
                dataStorage[key] = pToken;
            }
           
            if(key === "pUser"){
                dataStorage[key] = oldEncryptStorage.getItem(key);
            }

            if(key.startsWith(`${PayabliStorageManager.getEntryName()}_`)){
                dataStorage[key] = oldEncryptStorage.getItem(key);
            }
            
        }
        newEncryptStorage.setDataStorage(dataStorage);
    
        const bc = new BroadcastChannel(`${PayabliStorageManager.getEntryName()}_payabliLocationReload`);
        bc.postMessage({});
    }
    static reloading = () => {
        this.#localStorage.setItem(`${PayabliStorageManager.getEntryName()}_storageKey`,this.#KEY);
    }
    static clearStorage = (prefix, prefixTimer) => {
        // Clear LocalStorage
        if(prefix){
            let arr = [];
            for (let i = 0; i < localStorage.length; i++){
                if (localStorage.key(i).startsWith(`${prefix}_`)) {
                    arr.push(localStorage.key(i));
                }
            }
            for (let i = 0; i < arr.length; i++) {
                localStorage.removeItem(arr[i]);
            }
        }else{
            localStorage.clear()
            PayabliStorageManager.#KEY = md5(process.env.REACT_APP_TOKEN).toString();
        }
        
        if(prefixTimer){
            localStorage.removeItem(prefixTimer);
        }

    }
}

export class PayabliEncryptStorage {

    encryptStorage;

    get length() {
        return this.encryptStorage ? Object.keys(this.encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_dataStorage`)).length: 0;
    }

    constructor(key, migrate) {
        this.encryptStorage = new EncryptStorage(key);
        if (!migrate && !this.encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_dataStorage`)){
            this.encryptStorage.setItem(`${PayabliStorageManager.getEntryName()}_dataStorage`, JSON.stringify({}));
        }
    }

    getItem(key) {
        try {
            const storageData = this.encryptStorage.getItem(
              `${PayabliStorageManager.getEntryName()}_dataStorage`
            );
            let object = {};
            if (
              typeof storageData === "object" &&
              storageData !== null &&
              key in storageData
            ) {
              object = storageData[key];
            } else {
              if (key && key.includes("_pEntry")) {
                object = {
                  pEntry: "",
                  legalName: "",
                  orgId: -1,
                  isRoot: false,
                };
              }
            }
            return object;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    setItem(key, value) {
        let data = this.encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_dataStorage`);
        data[key] = value;
        this.encryptStorage.setItem(`${PayabliStorageManager.getEntryName()}_dataStorage`, JSON.stringify(data));
    }

    setDataStorage(value) {
        this.encryptStorage.setItem(`${PayabliStorageManager.getEntryName()}_dataStorage`, JSON.stringify(value));
    }

    key(key) {
        let data = this.encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_dataStorage`);
        return Object.keys(data)[key];
    }

    getTokenHash(){
        return md5(this.getItem("pToken")).toString();
    }
}

export class PayabliCookieManager {
    static createCookie(name,value,days, dinamic) {
        var expires = null;
        var domain, domainParts, host;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            expires = "; expires="+date.toGMTString();
        }
        else expires = "";
        host = window.location.host;
        if (host.split('.').length === 1)
        {
            document.cookie = name+"="+value+expires+"; path=/";
        }
        else
        {
            domainParts = host.split('.');
            domainParts.shift();
            domain = '.'+domainParts.join('.');
            document.cookie = name+"="+value+expires+"; path=/; domain="+domain;
            if(dinamic !== true){
                if (this.readCookie(name) === null || this.readCookie(name) !== value)
                {
                    domain = '.'+host;
                    document.cookie = name+"="+value+expires+"; path=/; domain="+domain;
                }
            }
            
        }
    }
    
    static readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    
    static eraseCookie(name) {
        this.createCookie(name,"",-1);
    }
}