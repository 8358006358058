import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import 'rc-slider/assets/index.css';
import { BiLoader, BiDollar, BiDollarCircle, BiErrorCircle, BiDoughnutChart, BiShare } from 'react-icons/bi';
import {ComponentRangeAmount} from './ComponentRangeAmount.js'
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'filter', 'global')
@observer
class ViewPartialTransfer extends React.Component {

    isEnabled = (paramFilter, index) => {
        const filterStatus = this.props.filter[paramFilter];
        return (Object.keys(filterStatus).length > 0 && filterStatus[index]) ? true : false;
    }

    getStatusClassName = (paramFilter, index, interactiveClass) => {
        const isEnabled = this.isEnabled(paramFilter, index);
        return isEnabled ? `bg-${interactiveClass}` : `text-${interactiveClass}`;
    }
    
    elementBadge = (index, icon, text, interactiveClass, getStatusClassName, paramFilter, queryFilter, handleStatus, testData) => {
        const isEnabled = this.isEnabled(paramFilter, index);
        const className = getStatusClassName(paramFilter, index, interactiveClass);
        return <span className={`badge ${className}`} data-qaid={testData} onClick={() => this.props.filter[handleStatus](queryFilter, index, !isEnabled)}>{icon} {text}</span>
    }

    renderStatusBadges = () => {
        const paramFilter = 'filterSettlementStatus';
        const queryFilter = 'transferStatus(in)';
        const handleStatus = 'handleSettlementStatus';
        const icon = this.props.global.getGlobalImg((this.isEnabled(paramFilter, 1) ? 'intransitinfodark' : 'intransitinfo'), '12px');
        const statuses = [
            { index: 0, icon: <BiLoader />, text: 'Pending', interactiveClass: 'warning', testData: '' },
            { index: 1, icon: icon, text: 'In Transit', interactiveClass: 'info', testData: '' },
            { index: 2, icon: <BiDollar />, text: 'Transferred', interactiveClass: 'success', testData: '' },
            { index: 3, icon: <BiDollarCircle />, text: 'Funded', interactiveClass: 'success', testData: '' },
            { index: -1, icon: <BiErrorCircle />, text: 'Exception', interactiveClass: 'danger', testData: '' },
            { index: -2, icon: <BiDoughnutChart />, text: 'Pending Balance', interactiveClass: 'warning', testData: '' },
            { index: -4, icon: <BiShare />, text: 'ACH Return', interactiveClass: 'secondary', testData: '' },
        ]

        return statuses.map((status, index) => {
            return this.elementBadge(status.index, status.icon, status.text, status.interactiveClass, this.getStatusClassName, paramFilter, queryFilter, handleStatus, status.testData)
        })
    }

    render() {
        return (<>
                <hr className='mb-4' />
                <h5 className="title" data-qaid="filters-Batch Details">Transfer Details</h5>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="transferId" id="transferId" className={"form-control"} placeholder="Transfer Id" onChange={(e) => this.props.filter.handleCaptureTextFilter('transferId(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('transferId(eq)', e)} value={this.props.filter.filterText.transferId ? this.props.filter.filterText.transferId : ''} data-qaid="inputTransferIdFilter"/>
                            <label htmlFor="transferId">Transfer ID</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="transferDateCalendar" 
                                        name="transferDateCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startTransferDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startTransferDate) + ' → ' : '') + (this.props.filter.stateDate.endTransferDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endTransferDate) : '')} 
                                        placeholder="Transfer Date" 
                                    />
                                    <label htmlFor="transferDateCalendar">Transfer Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startTransferDate ? this.props.filter.stateDate.startTransferDate : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startTransferDate', 'endTransferDate', 'transferDate')}
                            startDate={this.props.filter.stateDate.startTransferDate}
                            endDate={this.props.filter.stateDate.endTransferDate}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endTransferDate !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                </div>
                <div>
                    <p><small>Transfer Status</small></p>
                    <div className='section-status mb-4'>
                        { this.renderStatusBadges() }
                    </div>
                </div>
                <div className='mb-2'>
                    <ComponentRangeAmount
                        id={'grossAmount'}
                        title={'Gross Amount'}
                        minRange={0}
                        maxRange={1000}
                        minParameter={'minGrossAmount'}
                        maxParameter={'maxGrossAmount'}
                        filterParameter={'grossAmount'}
                    />                 
                </div>
                <div>
                    <ContainerShowMoreLess>
                        <div className='mb-2'>
                            <ComponentRangeAmount
                                id={'chargeBackAmount'}
                                title={'Chargeback'}
                                minRange={0}
                                maxRange={1000}
                                minParameter={'minChargeBackAmount'}
                                maxParameter={'maxChargeBackAmount'}
                                filterParameter={'chargeBackAmount'}
                            />                   
                        </div>
                        <div className='mb-2'>
                            <ComponentRangeAmount
                                id={'returnedAmount'}
                                title={'ACH Returns'}
                                minRange={0}
                                maxRange={1000}
                                minParameter={'minReturnedAmount'}
                                maxParameter={'maxReturnedAmount'}
                                filterParameter={'returnedAmount'}
                            />                   
                        </div>
                        <div className='mb-2'>
                            <ComponentRangeAmount
                                id={'billingFeeAmount'}
                                title={'Billing & Fees'}
                                minRange={0}
                                maxRange={1000}
                                minParameter={'minBillingFeeAmount'}
                                maxParameter={'maxBillingFeeAmount'}
                                filterParameter={'billingFeeAmount'}
                            />                  
                        </div>
                        <div className='mb-2'>
                            <ComponentRangeAmount
                                id={'adjustmentAmount'}
                                title={'Adjustments'}
                                minRange={0}
                                maxRange={1000}
                                minParameter={'minAdjustmentAmount'}
                                maxParameter={'maxAdjustmentAmount'}
                                filterParameter={'adjustmentAmount'}
                            />                    
                        </div>
                        <div className='mb-2'>
                            <ComponentRangeAmount
                                id={'thirdPartyPaidAmount'}
                                title={'Third Party Paid'}
                                minRange={0}
                                maxRange={1000}
                                minParameter={'minThirdPartyPaidAmount'}
                                maxParameter={'maxThirdPartyPaidAmount'}
                                filterParameter={'thirdPartyPaidAmount'}
                            />                   
                        </div>
                    </ContainerShowMoreLess>
                </div>
                <div className='mb-2'>
                    <ComponentRangeAmount
                        id={'netFundedAmount'}
                        title={'Transfer Total'}
                        minRange={0}
                        maxRange={1000}
                        minParameter={'minNetFundedAmount'}
                        maxParameter={'maxNetFundedAmount'}
                        filterParameter={'netFundedAmount'}
                    />                  
                </div>
            </>);
    }
}

export { ViewPartialTransfer };