import React, { useState } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import FrameGroup from '../../../components/commandCenter/FrameGroup'
import ButtonSaveCancel from '../../../components/commandCenter/ButtonSaveCancel'
import RowContainer from '../../../components/commandCenter/RowContainer'
import {
	BiBookmarkPlus,
	BiDotsVerticalRounded,
	BiCheckCircle,
	BiMinusCircle
} from 'react-icons/bi'

export default function ValueAddedServices() {
	const [inputDisabled, setInputDisabled] = useState(true)
	const [inputValues, SetInputValues] = useState({
		tokenization: false,
		TokenizationFee: '0.00',
		splitPay: true,
		splitPayFee: '0.00',
		invoicing: true,
		invoicingFee: '0.00',
		ACH: false,
		ACHBankVerificationFee: '0.00',
		level: false,
		levelFee: '0.00',
		automaticCardUpdate: true,
		automaticCardUpdaterFee: '0.00'
	})

	return (
		<div
			className="d-flex flex-column align-items-center justify-content-center"
			style={{ position: 'relative', top: '150px', gap: '12px' }}>
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={
						<BiBookmarkPlus
							className={
								styles['icon26'] + ' ' + styles['color-blue']
							}
						/>
					}
					icon_btn={
						<BiDotsVerticalRounded
							className={
								styles['icon24'] + ' ' + styles['color-gray']
							}
						/>
					}
					title_label="Value Added Services & Products"
					editClick={() => setInputDisabled(false)}
					useBtn={inputDisabled}
				/>

				<div className="d-flex flex-column w-100">
					<div className={styles['grid-container']}>
						<div
							className="d-flex flex-column align-items-start p-0"
							style={{ paddingBottom: '10px' }}>
							<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
								<div
									className="d-flex align-items-center flex-wrap"
									style={{
										minHeight: inputDisabled
											? '32px'
											: '46px',
										transition: 'all 0.4s ease'
									}}>
									<label
										className={
											styles['label-form'] +
											' ' +
											styles['ft-s-13']
										}
										style={{
											fontWeight: '700',
											lineHeight: '20px',
											minWidth: '180px',
											letterSpacing: '0.0025em'
										}}>
										Tokenization
									</label>

									<div
										style={{
											fontSize: '14px',
											fontWeight: '600',
											paddingInline: '20px'
										}}>
										<span
											className={
												inputValues.tokenization ===
												true
													? 'badge bg-primary'
													: 'badge bg-light'
											}
											style={{
												fontSize: '12px',
												fontWeight: '500',
												minWidth: '73px'
											}}>
											{inputValues.tokenization ? (
												<>
													<BiCheckCircle />
													&nbsp;&nbsp;Active
												</>
											) : (
												<>
													<BiMinusCircle />
													&nbsp;&nbsp;Inactive
												</>
											)}
										</span>
									</div>
								</div>
							</div>

							<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
								<div
									className="d-flex align-items-center flex-wrap"
									style={{
										minHeight: inputDisabled
											? '32px'
											: '46px',
										transition: 'all 0.4s ease'
									}}>
									<label
										className={
											styles['label-form'] +
											' ' +
											styles['ft-s-13']
										}
										style={{
											fontWeight: '700',
											lineHeight: '20px',
											minWidth: '180px',
											letterSpacing: '0.0025em'
										}}>
										Split Pay
									</label>

									<div
										style={{
											fontSize: '14px',
											fontWeight: '600',
											paddingInline: '20px'
										}}>
										<span
											className={
												inputValues.splitPay === true
													? 'badge bg-primary'
													: 'badge bg-light'
											}
											style={{
												fontSize: '12px',
												fontWeight: '500',
												minWidth: '73px'
											}}>
											{inputValues.splitPay ? (
												<>
													<BiCheckCircle />
													&nbsp;&nbsp;Active
												</>
											) : (
												<>
													<BiMinusCircle />
													&nbsp;&nbsp;Inactive
												</>
											)}
										</span>
									</div>
								</div>
							</div>

							<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
								<div
									className="d-flex align-items-center flex-wrap"
									style={{
										minHeight: inputDisabled
											? '32px'
											: '46px',
										transition: 'all 0.4s ease'
									}}>
									<label
										className={
											styles['label-form'] +
											' ' +
											styles['ft-s-13']
										}
										style={{
											fontWeight: '700',
											lineHeight: '20px',
											minWidth: '180px',
											letterSpacing: '0.0025em'
										}}>
										Invoicing
									</label>

									<div
										style={{
											fontSize: '14px',
											fontWeight: '600',
											paddingInline: '20px'
										}}>
										<span
											className={
												inputValues.invoicing === true
													? 'badge bg-primary'
													: 'badge bg-light'
											}
											style={{
												fontSize: '12px',
												fontWeight: '500',
												minWidth: '73px'
											}}>
											{inputValues.invoicing ? (
												<>
													<BiCheckCircle />
													&nbsp;&nbsp;Active
												</>
											) : (
												<>
													<BiMinusCircle />
													&nbsp;&nbsp;Inactive
												</>
											)}
										</span>
									</div>
								</div>
							</div>

							<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
								<div
									className="d-flex align-items-center flex-wrap"
									style={{
										minHeight: inputDisabled
											? '32px'
											: '46px',
										transition: 'all 0.4s ease'
									}}>
									<label
										className={
											styles['label-form'] +
											' ' +
											styles['ft-s-13']
										}
										style={{
											fontWeight: '700',
											lineHeight: '20px',
											minWidth: '180px',
											letterSpacing: '0.0025em'
										}}>
										ACH Bank Verification
									</label>

									<div
										style={{
											fontSize: '14px',
											fontWeight: '600',
											paddingInline: '20px'
										}}>
										<span
											className={
												inputValues.ACH === true
													? 'badge bg-primary'
													: 'badge bg-light'
											}
											style={{
												fontSize: '12px',
												fontWeight: '500',
												minWidth: '73px'
											}}>
											{inputValues.ACH ? (
												<>
													<BiCheckCircle />
													&nbsp;&nbsp;Active
												</>
											) : (
												<>
													<BiMinusCircle />
													&nbsp;&nbsp;Inactive
												</>
											)}
										</span>
									</div>
								</div>
							</div>

							<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
								<div
									className="d-flex align-items-center flex-wrap"
									style={{
										minHeight: inputDisabled
											? '32px'
											: '46px',
										transition: 'all 0.4s ease'
									}}>
									<label
										className={
											styles['label-form'] +
											' ' +
											styles['ft-s-13']
										}
										style={{
											fontWeight: '700',
											lineHeight: '20px',
											minWidth: '180px',
											letterSpacing: '0.0025em'
										}}>
										Level II & III
									</label>

									<div
										style={{
											fontSize: '14px',
											fontWeight: '600',
											paddingInline: '20px'
										}}>
										<span
											className={
												inputValues.level === true
													? 'badge bg-primary'
													: 'badge bg-light'
											}
											style={{
												fontSize: '12px',
												fontWeight: '500',
												minWidth: '73px'
											}}>
											{inputValues.level ? (
												<>
													<BiCheckCircle />
													&nbsp;&nbsp;Active
												</>
											) : (
												<>
													<BiMinusCircle />
													&nbsp;&nbsp;Inactive
												</>
											)}
										</span>
									</div>
								</div>
							</div>

							<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
								<div
									className="d-flex align-items-center flex-wrap"
									style={{
										minHeight: inputDisabled
											? '32px'
											: '46px',
										transition: 'all 0.4s ease'
									}}>
									<label
										className={
											styles['label-form'] +
											' ' +
											styles['ft-s-13']
										}
										style={{
											fontWeight: '700',
											lineHeight: '20px',
											minWidth: '180px',
											letterSpacing: '0.0025em'
										}}>
										Automatic Card Updater
									</label>

									<div
										style={{
											fontSize: '14px',
											fontWeight: '600',
											paddingInline: '20px'
										}}>
										<span
											className={
												inputValues.automaticCardUpdate ===
												true
													? 'badge bg-primary'
													: 'badge bg-light'
											}
											style={{
												fontSize: '12px',
												fontWeight: '500',
												minWidth: '73px'
											}}>
											{inputValues.automaticCardUpdate ? (
												<>
													<BiCheckCircle />
													&nbsp;&nbsp;Active
												</>
											) : (
												<>
													<BiMinusCircle />
													&nbsp;&nbsp;Inactive
												</>
											)}
										</span>
									</div>
								</div>
							</div>
						</div>

						<div
							className="d-flex flex-column align-items-start p-0"
							style={{ paddingBottom: '10px' }}>
							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Tokenization Fee"
								name="TokenizationFee"
								placeholder="$0.00"
								value={inputValues.TokenizationFee}
								setValue={(values) =>
									SetInputValues({
										...inputValues,
										TokenizationFee: values
									})
								}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Split Pay Fee"
								name="splitPayFee"
								placeholder="$0.00"
								value={inputValues.splitPayFee}
								setValue={(values) =>
									SetInputValues({
										...inputValues,
										splitPayFee: values
									})
								}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Invoicing Fee"
								name="invoicingFee"
								placeholder="$0.00"
								value={inputValues.invoicingFee}
								setValue={(values) =>
									SetInputValues({
										...inputValues,
										invoicingFee: values
									})
								}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="ACH Bank Verification Fee"
								name="ACHBankVerificationFee"
								placeholder="$0.00"
								value={inputValues.ACHBankVerificationFee}
								setValue={(values) =>
									SetInputValues({
										...inputValues,
										ACHBankVerificationFee: values
									})
								}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Level II & III Fee"
								name="levelFee"
								placeholder="$0.00"
								value={inputValues.levelFee}
								setValue={(values) =>
									SetInputValues({
										...inputValues,
										levelFee: values
									})
								}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Automatic Card Updater"
								name="automaticCardUpdaterFee"
								placeholder="$0.00"
								value={inputValues.automaticCardUpdaterFee}
								setValue={(values) =>
									SetInputValues({
										...inputValues,
										automaticCardUpdaterFee: values
									})
								}
								maxLength={18}
							/>
						</div>
					</div>
				</div>

				<ButtonSaveCancel
					inputDisabled={inputDisabled}
					funtionCancel={() => setInputDisabled(true)}
				/>
			</div>
		</div>
	)
}
