import React, { useState, useContext, useEffect } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import { BiStore, BiDotsVerticalRounded, BiBeenHere } from 'react-icons/bi'
import FrameGroup from '../../../components/commandCenter/FrameGroup'
import ButtonSaveCancel from '../../../components/commandCenter/ButtonSaveCancel'
import RowContainer from '../../../components/commandCenter/RowContainer'
import GlobalStore from '../../../store/GlobalStore'
import Collapse from 'react-bootstrap/Collapse'
import { Element } from 'react-scroll'
import commandContext from '../context/commandCenter_context'

export default function BusinessDetails() {
	const { PayPoint, loadData, updatePayPoint, Business, handleChangeBusiness, sameAddressBusiness, Owner, Processing, toastMessage, newPypoint } = useContext(commandContext)
	const [inputDisabled, setInputDisabled] = useState(true)

	useEffect(() => {
		newPypoint && setInputDisabled(false)
	}, [newPypoint])
	const sameAddress = (state) => {
		sameAddressBusiness(state)
	}
	const load = async (id = '') => {
		await loadData()
	}
	const updateBusinessDetails = async () => {
		let valid = false
		if (Business.dbaName === null || Business.dbaName.toString().trim().length === 0) valid = true
		if (Business.legalName === null || Business.legalName.toString().trim().length === 0) valid = true
		if (Business.websiteAddress === null || Business.websiteAddress.toString().trim().length === 0 || GlobalStore.validators.stringValidator('url', Business.websiteAddress.toString())) valid = true
		if (Business.address1 === null || Business.address1.toString().trim().length === 0) valid = true
		if (Business.city === null || Business.city.toString().trim().length === 0) valid = true
		if (Business.zip === null || Business.zip.toString().trim().length === 0) valid = true
		if (Business.state === null || Business.state.toString().trim().length === 0) valid = true
		if (Business.country === null || Business.country.toString().trim().length === 0) valid = true
		if (!Business.sameAddress) {
			if (Business.mailAddress1 === null || Business.mailAddress1.toString().trim().length === 0) valid = true
			if (Business.mailCity === null || Business.mailCity.toString().trim().length === 0) valid = true
			if (Business.mailZip === null || Business.mailZip.toString().trim().length === 0) valid = true
			if (Business.mailState === null || Business.mailState.toString().trim().length === 0) valid = true
			if (Business.mailCountry === null || Business.mailCountry.toString().trim().length === 0) valid = true
		}
		if (PayPoint.idPaypoint <= 0) {
			let sumPercent = 0
			Owner.ownership.forEach((ownership) => {
				if (ownership.ownername === null || ownership.ownername.toString().trim().length === 0) valid = true
				if (ownership.ownerphone1 === null || ownership.ownerphone1.toString().trim().length === 0) valid = true
				if (ownership.owneremail === null || ownership.owneremail.toString().trim().length === 0 || !GlobalStore.emailValidation(ownership.owneremail)) valid = true
				if (ownership.oaddress === null || ownership.oaddress.toString().trim().length === 0) valid = true
				if (ownership.ocity === null || ownership.ocity.toString().trim().length === 0) valid = true
				if (ownership.ownerpercent < 25 || [('0', 0, '', null)].includes(ownership.ownerpercent)) valid = true
				sumPercent += parseInt(ownership.ownerpercent)
			})
			Owner.contacts.forEach((contacts) => {
				if (contacts.contactName === null || contacts.contactName.toString().trim().length === 0) valid = true
				if (contacts.contactEmail === null || contacts.contactEmail.toString().trim().length === 0 || !GlobalStore.emailValidation(contacts.contactEmail)) valid = true
				if (contacts.contactPhone === null || contacts.contactPhone.toString().trim().length === 0) valid = true
			})
			if (sumPercent > 100) valid = true

			if (Processing.mcc === null || Processing.mcc.toString().trim().length === 0) valid = true
			if (Processing.summary === null || Processing.summary.toString().trim().length === 0) valid = true
			if (Processing.averageMonthlyVolume === null || Processing.averageMonthlyVolume <= 0) valid = true
			if (Processing.averageTicketAmount === null || Processing.averageTicketAmount <= 0) valid = true
			if (Processing.highTicketAmount === null || Processing.highTicketAmount <= 0) valid = true
			if (parseInt(Processing.binPerson) + parseInt(Processing.binPhone) + parseInt(Processing.binWeb) !== 100) valid = true
		}
		if (valid) {
			toastMessage('You must fill in the required fields', false)
			return
		}

		const res = updatePayPoint(PayPoint.idPaypoint <= 0 ? 'New' : 'Business')
		if (res) setInputDisabled(true)
	}

	return (
		<Element
			name="BusinessDetails"
			className="d-flex justify-content-center w-100">
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={<BiStore className={styles['icon26'] + ' ' + styles['color-blue']} />}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label="Business Details"
					editClick={() => setInputDisabled(false)}
					useBtn={PayPoint.idPaypoint <= 0 ? false : inputDisabled}
					btnDelete={false}
				/>
				<div className="d-flex flex-column w-100">
					<div className={styles['grid-container']}>
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								name="dbaName"
								placeholder="Value"
								//isLoading={isLoading}
								inputDisabled={inputDisabled}
								classNameInput={!inputDisabled && (Business.dbaName === null || Business.dbaName.trim().length === 0) ? 'is-invalid' : ''}
								labelValue="Business DBA Name"
								value={Business.dbaName}
								setValue={(values) => handleChangeBusiness('dbaName', values)}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>

							<RowContainer
								inputType="mask"
								name="legalName"
								placeholder="Value"
								//isLoading={isLoading}
								inputDisabled={inputDisabled}
								classNameInput={!inputDisabled && (Business.legalName === null || Business.legalName.trim().length === 0) ? 'is-invalid' : ''}
								labelValue="Business Legal Name"
								value={Business.legalName}
								setValue={(values) => handleChangeBusiness('legalName', values)}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
							<RowContainer
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="Business Type"
								name="ownType"
								value={Business.ownType}
								setValue={(values) => handleChangeBusiness('ownType', values)}
								optionList={
									<>
										{GlobalStore.getOptionsType('btype').map((data) => (
											<option
												key={'btype' + data.value}
												value={data.value}>
												{data.text}
											</option>
										))}
									</>
								}
							/>
							<RowContainer
								inputType={inputDisabled ? 'password' : 'mask'}
								inputDisabled={inputDisabled}
								labelValue="Business Ein"
								name="ein"
								placeholder="Value"
								//isLoading={isLoading}
								value={Business.ein}
								setValue={(values) => handleChangeBusiness('ein', values)}
								mask={GlobalStore.maskValidator('ein')}
							/>
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								labelValue="Tax Filing Name"
								name="taxfillname"
								placeholder="Value"
								//isLoading={isLoading}
								value={Business.taxfillname}
								setValue={(values) => handleChangeBusiness('taxfillname', values)}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								labelValue="Business License"
								name="license"
								placeholder="Value"
								//isLoading={isLoading}
								value={Business.license}
								setValue={(values) => handleChangeBusiness('license', values)}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
						</div>

						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="License State"
								name="licenseState"
								value={Business.licenseState}
								setValue={(values) => handleChangeBusiness('licenseState', values)}
								optionList={
									<>
										<option value="">select...</option>
										{GlobalStore.getUSAStates().map((data) => (
											<option
												key={'optState' + data.value}
												value={data.value}>
												{data.text}
											</option>
										))}
									</>
								}
							/>
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								classNameInput={!inputDisabled && (Business.websiteAddress === null || Business.websiteAddress.trim().length === 0 || GlobalStore.validators.stringValidator('url', Business.websiteAddress.toString())) ? 'is-invalid' : ''}
								labelValue="Website"
								name="websiteAddress"
								placeholder="Value"
								value={Business.websiteAddress}
								setValue={(values) => handleChangeBusiness('websiteAddress', values)}
								mask={GlobalStore.maskValidator('text')}
								maxLength={200}
							/>
							<RowContainer
								inputType={inputDisabled ? 'text' : 'dateBox'}
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="Business Start Date"
								name="startdate"
								placeholder="Select Date"
								allowedNullDate={true}
								maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
								value={inputDisabled ? GlobalStore.stringDateFormat(Business.startdate) : Business.startdate === null ? null : new Date(Business.startdate)}
								setValue={(values) => handleChangeBusiness('startdate', values)}
							/>
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="Phone Number"
								name="phone"
								placeholder="Value"
								value={Business.phone}
								setValue={(values) => handleChangeBusiness('phone', values)}
								mask={GlobalStore.maskValidator('phone')}
							/>
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="Fax Number"
								name="fax"
								placeholder="Value"
								value={Business.fax}
								setValue={(values) => handleChangeBusiness('fax', values)}
								mask={GlobalStore.maskValidator('phone')}
							/>
							<RowContainer
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="Time Zone"
								name="bTimezone"
								value={Business.bTimezone}
								setValue={(values) => handleChangeBusiness('bTimezone', values)}
								optionList={
									<>
										{GlobalStore.getOptionsType('timezones').map((data) => (
											<option
												key={'timezones' + data.value}
												value={data.value}>
												{data.text}
											</option>
										))}
									</>
								}
							/>
						</div>
					</div>
				</div>

				<FrameGroup
					icon_frame={<BiBeenHere className={styles['icon26'] + ' ' + styles['color-blue']} />}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label="Addresses"
					editClick={() => setInputDisabled(false)}
					useBtn={false}
				/>
				<label
					className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
					style={{ fontWeight: '600', lineHeight: '24px' }}>
					Business Address
				</label>
				<div className="d-flex flex-column w-100">
					<div className={styles['grid-container']}>
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								classNameInput={!inputDisabled && (Business.address1 === null || Business.address1.trim().length === 0) ? 'is-invalid' : ''}
								labelValue="Address #1"
								name="address1"
								placeholder="Value"
								value={Business.address1}
								setValue={(values) => handleChangeBusiness('address1', values)}
								mask={GlobalStore.maskValidator('text')}
								maxLength={200}
							/>
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								labelValue="Address #2"
								name="address2"
								placeholder="Value"
								value={Business.address2}
								setValue={(values) => handleChangeBusiness('address2', values)}
								mask={GlobalStore.maskValidator('text')}
								maxLength={200}
							/>
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								classNameInput={!inputDisabled && (Business.city === null || Business.city.trim().length === 0) ? 'is-invalid' : ''}
								labelValue="City"
								name="city"
								placeholder="Value"
								value={Business.city}
								setValue={(values) => handleChangeBusiness('city', values)}
								mask={GlobalStore.maskValidator('text')}
								maxLength={100}
							/>
						</div>

						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								classNameInput={!inputDisabled && (Business.zip === null || Business.zip.trim().length === 0) ? 'is-invalid' : ''}
								labelValue="Zip"
								name="zip"
								placeholder="Value"
								value={Business.zip}
								setValue={(values) => handleChangeBusiness('zip', values)}
								mask={GlobalStore.maskValidator('zipcode')}
							/>
							<RowContainer
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								classNameInput={!inputDisabled && (Business.country === null || Business.country.trim().length === 0) ? 'is-invalid' : ''}
								labelValue="Country"
								name="country"
								value={Business.country}
								setValue={(values) => handleChangeBusiness('country', values)}
								optionList={
									<>
										<option value="">select...</option>
										{GlobalStore.getAllCountries().map((data) => (
											<option
												key={'optState' + data.value}
												value={data.value}>
												{data.text}
											</option>
										))}
									</>
								}
							/>
							<RowContainer
								inputDisabled={inputDisabled}
								//isLoading={isLoading}
								classNameInput={!inputDisabled && (Business.state === null || Business.state.trim().length === 0) ? 'is-invalid' : ''}
								labelValue="State"
								name="state"
								value={Business.state}
								setValue={(values) => handleChangeBusiness('state', values)}
								optionList={
									<>
										<option value="">select...</option>
										{GlobalStore.getStates(Business.country).map((data) => (
											<option
												key={'optState' + data.value}
												value={data.value}>
												{data.text}
											</option>
										))}
									</>
								}
							/>
						</div>
					</div>
				</div>

				{!inputDisabled ? (
					<div className="d-flex align-items-center justify-content-start flex-wrap flex-row w-100 gap-3">
						<div className="icheck-primary">
							<input
								onChange={() => sameAddress(!Business.sameAddress)}
								type="checkbox"
								defaultChecked={Business.sameAddress}
								disabled={inputDisabled}
								id="sameAddress"
							/>
							<label
								htmlFor="sameAddress"
								className={styles['label-form'] + ' ' + styles['ft-s-12']}
								style={{ fontWeight: '500', lineHeight: '18px', textTransform: 'uppercase', color: '#606060' }}>
								MAILING ADDRESS SAME AS BUSINESS ADDRESS
							</label>
						</div>
					</div>
				) : (
					Business.sameAddress && (
						<div className="d-flex align-items-center justify-content-start flex-wrap flex-row w-100 gap-3">
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-12']}
								style={{ fontWeight: '500', lineHeight: '18px', textTransform: 'uppercase', color: '#606060' }}>
								*MAILING ADDRESS SAME AS BUSINESS ADDRESS
							</label>
						</div>
					)
				)}

				<Collapse in={!Business.sameAddress}>
					<div
						id="collapse-card"
						className="w-100">
						<label
							className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
							style={{ fontWeight: '600', lineHeight: '24px', marginBottom: '20px' }}>
							Mailing Address
						</label>
						<div className="d-flex flex-column w-100">
							<div className={styles['grid-container']}>
								<div className="d-flex flex-column align-items-start p-0">
									<RowContainer
										inputType="mask"
										//isLoading={isLoading}
										inputDisabled={inputDisabled}
										classNameInput={!inputDisabled && (Business.mailAddress1 === null || Business.mailAddress1.trim().length === 0) ? 'is-invalid' : ''}
										labelValue="Address #1"
										name="mailAddress1"
										placeholder="Value"
										value={Business.mailAddress1}
										setValue={(values) => handleChangeBusiness('mailAddress1', values)}
										mask={GlobalStore.maskValidator('text')}
										maxLength={200}
									/>
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Address #2"
										name="mailAddress2"
										placeholder="Value"
										value={Business.mailAddress2}
										setValue={(values) => handleChangeBusiness('mailAddress2', values)}
										mask={GlobalStore.maskValidator('text')}
										maxLength={200}
									/>
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (Business.mailCity === null || Business.mailCity.trim().length === 0) ? 'is-invalid' : ''}
										labelValue="City"
										name="mailCity"
										placeholder="Value"
										value={Business.mailCity}
										setValue={(values) => handleChangeBusiness('mailCity', values)}
										mask={GlobalStore.maskValidator('text')}
										maxLength={100}
									/>
								</div>

								<div className="d-flex flex-column align-items-start p-0">
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (Business.mailZip === null || Business.mailZip.trim().length === 0) ? 'is-invalid' : ''}
										labelValue="Zip"
										name="mailZip"
										placeholder="Value"
										value={Business.mailZip}
										setValue={(values) => handleChangeBusiness('mailZip', values)}
										mask={GlobalStore.maskValidator('zipcode')}
									/>
									<RowContainer
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (Business.mailCountry === null || Business.mailCountry.trim().length === 0) ? 'is-invalid' : ''}
										labelValue="Country"
										name="mailCountry"
										value={Business.mailCountry}
										setValue={(values) => handleChangeBusiness('mailCountry', values)}
										optionList={
											<>
												<option value="">select...</option>
												{GlobalStore.getAllCountries().map((data) => (
													<option
														key={'optState' + data.value}
														value={data.value}>
														{data.text}
													</option>
												))}
											</>
										}
									/>
									<RowContainer
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (Business.mailState === null || Business.mailState.trim().length === 0) ? 'is-invalid' : ''}
										labelValue="State"
										name="mailState"
										value={Business.mailState}
										setValue={(values) => handleChangeBusiness('mailState', values)}
										optionList={
											<>
												<option value="">select...</option>
												{GlobalStore.getStates(Business.mailCountry).map((data) => (
													<option
														key={'optState' + data.value}
														value={data.value}>
														{data.text}
													</option>
												))}
											</>
										}
									/>
								</div>
							</div>
						</div>
					</div>
				</Collapse>

				<ButtonSaveCancel
					inputDisabled={inputDisabled}
					funtionCancel={PayPoint.idPaypoint <= 0 ? null : () => setInputDisabled(true) | load()}
					funtionSave={() => updateBusinessDetails()}
					captionSave={PayPoint.idPaypoint <= 0 ? 'SAVE PAYPOINT' : 'SAVE CHANGES'}
				/>
			</div>
		</Element>
	)
}
