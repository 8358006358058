import React from 'react';
import { inject, observer } from 'mobx-react';
import { RenderPopoverTimeSftpAccount } from '../../components/filters/RenderPopoverTimeSftpAccount';
import { BiAlarm, BiCalendarCheck, BiCalendar, BiCalendarEvent } from 'react-icons/bi'

@inject('reports', 'filter')
@observer
class ViewPartialSftpAccounts extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="filterUsername" id="filterUsername" className={"form-control"} placeholder="Username" onChange={(e) => this.props.filter.handleCaptureTextFilter('UserId(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('UserId(ct)', e)} value={this.props.filter.filterText.filterUsername ? this.props.filter.filterText.filterUsername : ''} />
                            <label htmlFor="filterUsername">Username</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="filterServerUrl" id="filterServerUrl" className={"form-control"} placeholder="Server URL" onChange={(e) => this.props.filter.handleCaptureTextFilter('server(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('server(ct)', e)} value={this.props.filter.filterText.filterServerUrl ? this.props.filter.filterText.filterServerUrl : ''} />
                            <label htmlFor="filterServerUrl">Server URL</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Schedule</small></p>
                        { <RenderPopoverTimeSftpAccount /> }
                    </div>                    
                </div>
                <div className='row'>
                    <div className="col-sm-12 mb-4">
                        <p><small>Mode</small></p>
                        <div className="mt-1 form-group" style={{fontSize: '20px', display: 'inline-flex'}}>
                            <div className="icheck-primary mr-3">
                                <input type="radio" name="inlineRadioDownload" id="inlineRadioDownload" value="0" checked={this.props.filter.filterRadioOption.inlineRadioDownload ? true : false} onChange={(e) => this.props.filter.handleRadioOption(e, 'mode(eq)')} />
                                <label htmlFor="inlineRadioDownload">Download</label>
                            </div>
                            <div className="icheck-primary mr-3">
                                <input type="radio" name="inlineRadioUpload" id="inlineRadioUpload" value="1" checked={this.props.filter.filterRadioOption.inlineRadioUpload ? true : false} onChange={(e) => this.props.filter.handleRadioOption(e, 'mode(eq)')} />
                                <label htmlFor="inlineRadioUpload">Upload</label>
                            </div>
                            <div className="icheck-primary mr-3">
                                <input type="radio" name="inlineRadioBoth" id="inlineRadioBoth" value="2" checked={this.props.filter.filterRadioOption.inlineRadioBoth ? true : false} onChange={(e) => this.props.filter.handleRadioOption(e, 'mode(eq)')} />
                                <label htmlFor="inlineRadioBoth">Both</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p><small>Frequency</small></p>
                    <div className='section-status mb-4'>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus['hourly']) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('frequency(in)', 'hourly', !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus['hourly']))}><BiAlarm /> Hourly</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus['daily']) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleStatus('frequency(in)', 'daily', !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus['daily']))}><BiCalendarCheck /> Daily</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus['weekly']) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('frequency(in)', 'weekly', !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus['weekly']))}><BiCalendar /> Weekly</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus['monthly']) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleStatus('frequency(in)', 'monthly', !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus['monthly']))}><BiCalendarEvent /> Monthly</span>
                    </div>
                </div>                           
            </>);
    }
}

export { ViewPartialSftpAccounts };