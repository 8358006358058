import React from 'react'
import styles from '../../assets/css/styleCommandCenter.module.css'

export default function RadioButton({ recurringState = false, oneTimeState = false, label, inputDisabled = false, changeStateRecurring, changeStateOneTimeState, onlyOneTimeState = false }) {
	return (
		<div
			className="d-flex flex-wrap flex-row align-items-center w-100"
			style={{ padding: '0 0 12px', gap: '8px' }}>
			<label
				className={styles['label-form'] + ' ' + styles['ft-s-13']}
				style={{ fontWeight: 600, lineHeight: '20px', letterSpacing: '0.0025em', width: '180px', minWidth: '20px' }}>
				{label}
			</label>
			{!onlyOneTimeState && (
				<button
					onClick={() => changeStateRecurring()}
					style={{ display: 'flex', padding: '2px 8px', width: '95px', height: '32px', background: recurringState && '#FFA726', borderRadius: '16px', alignItems: 'center', justifyContent: 'center', border: !recurringState ? '1px solid #FFA726' : 'none', color: recurringState ? '#000A12' : '#90A4AE' }}
					disabled={inputDisabled}>
					Recurring
				</button>
			)}

			<button
				onClick={() => changeStateOneTimeState()}
				style={{ display: 'flex', padding: '2px 8px', width: '95px', height: '32px', background: oneTimeState && '#64D8CB', borderRadius: '16px', alignItems: 'center', justifyContent: 'center', border: !oneTimeState ? '1px solid #64D8CB' : 'none', color: oneTimeState ? '#000A12' : '#90A4AE' }}
				disabled={inputDisabled}>
				One Time
			</button>
		</div>
	)
}
