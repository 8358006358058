import React, { useContext } from 'react'
import styles from '../../../../assets/css/styleCommandCenter.module.css'
import RowContainer from '../../../../components/commandCenter/RowContainer'
import Collapse from 'react-bootstrap/Collapse'
import GlobalStore from '../../../../store/GlobalStore'
import commandContext from '../../context/commandCenter_context'

export default function Processor({ showSection, setShowSection, setService, sectionCounter, SetInputValues, inputValues, service, classValidate, setClassValidate, useProvider = true }) {
	const { services } = useContext(commandContext)

	const gateway = () => {
		if (showSection.serviceMoneyIn && !showSection.serviceMoneyOut) {
			if (service.cardIn || service.achIn || service.cloudIn) {
				return [
					{ value: 'payabli', text: 'Payabli' },
					{ value: 'legacy', text: 'Legacy' }
				]
			} else if (service.checkIn || service.cashIn) {
				return [{ value: 'payabli', text: 'Payabli' }]
			} else {
				return []
			}
		} else {
			if (service.managedOut || service.achOut || service.virtualOut) {
				return [{ value: 'payabli', text: 'Payabli' }]
			} else {
				return []
			}
		}
	}
	const processor = (gateway) => {
		if (!gateway) return []
		if (showSection.serviceMoneyIn && !showSection.serviceMoneyOut) {
			if (gateway === 'payabli') {
				if (service.cardIn || service.cloudIn) {
					return [{ value: 'gp', text: 'Global' }]
				} else if (service.achIn) {
					return [
						{ value: 'gp', text: 'Global' },
						{ value: 'checkcommerce', text: 'Check Commerce' }
					]
				} else if (service.checkIn || service.cashIn) {
					return [{ value: 'dummy', text: 'Dummy' }]
				} else if (service.walletIn) {
					return [{ value: '', text: 'to define' }]
				} else {
					return []
				}
			} else if (gateway === 'legacy') {
				if (service.cardIn || service.achIn || service.cloudIn) {
					return [{ value: 'nmi', text: 'NMI' }]
				} else {
					return []
				}
			} else {
				return []
			}
		} else {
			if (gateway === 'payabli') {
				if (service.managedOut || service.virtualOut) {
					return [{ value: 'repay', text: 'Repay' }]
				} else if (service.achOut) {
					return [{ value: 'checkcommerce', text: 'Check Commerce' }]
				} else {
					return []
				}
			} else {
				return []
			}
		}
	}

	return (
		<Collapse in={showSection.processor}>
			<div id="collapse-section">
				<div className="d-flex flex-column gap-3">
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Processor Information
					</label>
					<div className="d-flex flex-column">
						<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
							<div className="d-flex flex-column align-items-start p-0">
								<RowContainer
									inputDisabled={false}
									classNameInput={classValidate.gateway}
									labelValue="Gateway"
									name="gateway"
									value={inputValues.gateway}
									setValue={(values) => SetInputValues({ ...inputValues, gateway: values, processor: '' }) | setShowSection({ ...showSection, credential: false, tiers: false }) | setService({ ...service, sectionCounter: values === '' ? sectionCounter - 1 : service.sectionCounter }) | setClassValidate({ ...classValidate, gateway: values ? '' : 'is-invalid', processor: !values ? 'is-invalid' : !inputValues.processor ? 'is-invalid' : '' })}
									optionList={
										<>
											<option value="">Select a gateway</option>
											{gateway().map((data, index) => (
												<option
													key={'GatewayName' + data.value + index}
													value={data.value}>
													{data.text}
												</option>
											))}
										</>
									}
								/>
								<RowContainer
									inputDisabled={false}
									classNameInput={classValidate.processor}
									labelValue="Processor"
									name="processor"
									value={inputValues.processor}
									setValue={(values) => SetInputValues({ ...inputValues, processor: values }) | setShowSection({ ...showSection, credential: service.cashIn || service.checkIn ? false : true, tiers: !values ? false : true }) | setService({ ...service, sectionCounter: values === '' ? sectionCounter - 1 : sectionCounter }) | setClassValidate({ ...classValidate, processor: values ? '' : 'is-invalid' })}
									optionList={
										<>
											<option value="">Select a processor</option>
											{processor(inputValues.gateway).map((data, index) => (
												<option
													key={'Processor' + data.value + index}
													value={data.value}>
													{data.text}
												</option>
											))}
										</>
									}
								/>
							</div>
							<div className="d-flex flex-column align-items-start p-0">
								{useProvider && (
									<RowContainer
										inputDisabled={false}
										classNameInput={classValidate.provider}
										labelValue="Provider"
										name="provider"
										value={inputValues.provider}
										setValue={(values) => SetInputValues({ ...inputValues, provider: values }) | setClassValidate({ ...classValidate, provider: values ? '' : 'is-invalid' })}
										optionList={
											<>
												<option value="Payabli">Payabli</option>
												{/* {showSection.serviceMoneyIn && <option value="Ironwood">Ironwood</option>} */}
											</>
										}
									/>
								)}
								<RowContainer
									inputType="mask"
									inputDisabled={false}
									classNameInput={inputValues.ServiceGroup !== '' && inputValues.ServiceName !== '' && !useProvider && services[inputValues.ServiceGroup][inputValues.ServiceName][0].Gateways.length >= 1 ? classValidate.accountId : ''}
									labelValue={inputValues.ServiceGroup !== '' && inputValues.ServiceName !== '' && !useProvider && services[inputValues.ServiceGroup][inputValues.ServiceName][0].Gateways.length >= 1 ? 'Identifier' : 'Identifier (Optional)'}
									name="accountId"
									placeholder="Value"
									value={inputValues.accountId}
									onBlur={() => setClassValidate({ ...classValidate, accountId: inputValues.accountId ? '' : 'is-invalid' })}
									setValue={(values) => SetInputValues({ ...inputValues, accountId: values }) | setClassValidate({ ...classValidate, accountId: values ? '' : 'is-invalid' })}
									mask={GlobalStore.maskValidator('text')}
									maxLength={30}
									TooltipCaption={
										inputValues.ServiceGroup !== '' && inputValues.ServiceName !== '' && !useProvider && services[inputValues.ServiceGroup][inputValues.ServiceName][0].Gateways.length >= 1 ? (
											''
										) : (
											<>
												An <strong>Identifier</strong> is a customer identifier for the Gateway to Processor combination. This is very useful when needing to identify various processors enabled for a service within a single Paypoint.
												<br /> <i>Example:</i> <strong>ABC</strong> PayPoint has Processor <strong>A</strong> and Processor <strong>B</strong> for the credit card service.
											</>
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Collapse>
	)
}
