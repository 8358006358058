import React from 'react'

export const WarningIcon = (props) => {
    return (
        <svg
            width={49}
            height={48}
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M24.5 18V26M24.5 34H24.52M43.96 36L27.96 7.99999C27.6111 7.3844 27.1052 6.87237 26.4938 6.51613C25.8825 6.15989 25.1876 5.9722 24.48 5.9722C23.7724 5.9722 23.0775 6.15989 22.4662 6.51613C21.8548 6.87237 21.3489 7.3844 21 7.99999L5 36C4.64736 36.6107 4.46246 37.3038 4.46402 38.009C4.46558 38.7142 4.65356 39.4065 5.00889 40.0156C5.36423 40.6248 5.8743 41.1292 6.48739 41.4776C7.10049 41.8261 7.79481 42.0063 8.5 42H40.5C41.2018 41.9993 41.8911 41.8139 42.4986 41.4626C43.1061 41.1112 43.6104 40.6062 43.961 39.9982C44.3116 39.3903 44.4961 38.7008 44.4959 37.999C44.4957 37.2972 44.3109 36.6078 43.96 36Z"
                stroke="#F59E0B"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    )
}
