import React from 'react'
import styles from '../../../../assets/css/styleCommandCenter.module.css'
import { BiDesktop, BiRepost, BiEditAlt, BiCreditCardFront } from 'react-icons/bi'
import { MdOpenInBrowser } from 'react-icons/md'
import Collapse from 'react-bootstrap/Collapse'

export default function MoneyOut({ showSection, setShowSection, setService, sectionCounter, SetInputValues, inputValues, service }) {
	return (
		<Collapse in={showSection.serviceMoneyOut}>
			<div id="collapse-section">
				<div className="d-flex flex-column gap-3">
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Select a service
					</label>
					<div className="row row-cols-lg-5">
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: true, credential: false, walletView: false, tiers: false }) |
									setService({
										cardIn: false,
										achIn: false,
										checkIn: false,
										cashIn: false,
										cloudIn: false,
										walletIn: false,
										managedOut: true,
										achOut: false,
										checkOut: false,
										virtualOut: false,
										pushOut: false,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyout', ServiceName: 'managed', processor: '', gateway: '' })
								}
								className={service.managedOut ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<MdOpenInBrowser className={styles['icon30'] + ' ' + styles['color-orange']} />
								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>Managed Payables</label>
							</button>
						</div>
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: true, credential: false, walletView: false, tiers: false }) |
									setService({
										cardIn: false,
										achIn: false,
										checkIn: false,
										cashIn: false,
										cloudIn: false,
										walletIn: false,
										managedOut: false,
										achOut: true,
										checkOut: false,
										virtualOut: false,
										pushOut: false,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyout', ServiceName: 'ach', processor: '', gateway: '' })
								}
								className={service.achOut ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<BiRepost className={styles['icon30'] + ' ' + styles['color-red']} />

								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>ACH</label>
							</button>
						</div>
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: true, credential: false, walletView: false, tiers: false }) |
									setService({
										cardIn: false,
										achIn: false,
										checkIn: false,
										cashIn: false,
										cloudIn: false,
										walletIn: false,
										managedOut: false,
										achOut: false,
										checkOut: true,
										virtualOut: false,
										pushOut: false,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyout', ServiceName: 'check', processor: '', gateway: '' })
								}
								className={service.checkOut ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<BiEditAlt className={styles['icon30'] + ' ' + styles['color-teal-light']} />

								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>Check</label>
							</button>
						</div>
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: true, credential: false, walletView: false, tiers: false }) |
									setService({
										cardIn: false,
										achIn: false,
										checkIn: false,
										cashIn: false,
										cloudIn: false,
										walletIn: false,
										managedOut: false,
										achOut: false,
										checkOut: false,
										virtualOut: true,
										pushOut: false,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyout', ServiceName: 'vcard', processor: '', gateway: '' })
								}
								className={service.virtualOut ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<BiDesktop className={styles['icon30'] + ' ' + styles['color-blue']} />

								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>Virtual Card</label>
							</button>
						</div>
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: true, credential: false, walletView: false, tiers: false }) |
									setService({
										cardIn: false,
										achIn: false,
										checkIn: false,
										cashIn: false,
										cloudIn: false,
										walletIn: false,
										managedOut: false,
										achOut: false,
										checkOut: false,
										virtualOut: false,
										pushOut: true,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyout', ServiceName: 'pushToCard', processor: '', gateway: '' })
								}
								className={service.pushOut ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<BiCreditCardFront className={styles['icon30'] + ' ' + styles['color-light-green']} />

								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>Push to Card</label>
							</button>
						</div>
					</div>
				</div>
			</div>
		</Collapse>
	)
}
