import React from 'react';
import { Col } from "react-bootstrap";
//import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { inject, observer } from 'mobx-react';

@inject('boarding', 'global')
@observer
class InputCalendarLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: null, cname: '' };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event }, function(){
            this.props.boarding.setTemplateData(this.state.cname, this.state.value);
        });

        if(this.props.iChangeCallback && this.props.iKey !== null && this.props.iKey !== undefined && this.props.iField){
            this.props.iChangeCallback(this.props.iKey,event, this.props.iField);
        }
    }

    componentDidMount() {
        let value = null;
        if(this.props.iValue === null && this.props.iType === "dob"){
            value = new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay());
        }
        else{
            value = this.props.iValue ? this.props.iValue : new Date();
        }
        
       
        this.setState({ value: value, cname: this.props.iName }, function () {
            this.props.boarding.setTemplateData(this.state.cname, this.state.value);
        });
    }
    componentWillUnmount() { 
        this.props.boarding.setErrorData(this.state.cname, false);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.iValue && prevState.value && this.props.iValue.getTime() !== prevState.value.getTime()){
            this.setState({value: this.props.iValue }, function(){
                this.props.boarding.setTemplateData(this.state.cname, this.props.iValue);
            })
        }
    }

    render() {
      
        const years = [];
        const months = this.props.global.getMonths();
        let year = new Date().getFullYear();
        for (let i = 1900; i <= year; i++) {
            years.push(i);
        }
        return (
            <Col md="6">
                <div className="form-group mb-4">
                    <DatePicker
                        customInput={
                            <div className="form-floating">
                                <input readOnly onChange={function () { }} value={this.props.global.stringDateFormat(this.state.value)} name={this.props.iName} id={this.props.iName} className="form-control input-calendar input-lg" placeholder={this.props.iTitle} />
                                <label htmlFor={this.props.iName}>{this.props.iTitle} {[null, true].includes(this.props.iRequired) ?  " *": ""}</label>
                            </div>
                        }
                        selected={this.state.value}
                        onChange={this.handleChange}
                        maxDate={this.props.iMax}
                        dayClassName={date => "calendar-day"}
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    {"<"}
                                </button>
                                <select
                                    value={date.getFullYear()}
                                    onChange={({ target: { value } }) => changeYear(value)}
                                >
                                    {years.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    value={months[date.getMonth()]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    {">"}
                                </button>
                            </div>
                        )}
                    />
                </div>
            </Col>
        )
    }
}

export { InputCalendarLink };