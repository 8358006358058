import React from 'react'
import LoaderPlaceHolder from '../loaderPlaceHolder'

export default function LabelContainer({ labelClassName, labelStyle = {}, labelValue = '', isLoading = false, placeholderStyle = '', onClick = () => null }) {
	return isLoading ? (
		<LoaderPlaceHolder extraStyles={placeholderStyle} />
	) : (
		<label
			onClick={onClick}
			className={labelClassName}
			style={labelStyle}>
			{labelValue}
		</label>
	)
}
