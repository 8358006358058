import React from 'react'

export const MarketingVector2 = (props) => {
    return (
        <svg
            width={586}
            height={255}
            viewBox="0 0 586 255"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M-1.00639e-06 69.6566C132.087 61.6615 252.118 137.818 306.867 255M435.301 255C393.013 103.79 248.871 1.76378 91.2669 20.5414C58.9535 24.3913 28.3303 33.0507 4.37102e-05 45.7395M0.000119052 159.093C49.3393 75.5353 134.548 15.3852 236.62 3.22393C400.169 -16.262 549.222 94.3412 585 255"
                stroke="#F1F5F9"
                strokeWidth={2}
            />
        </svg>

    )
}
