import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('reports', 'filter')
@observer
class RenderInvoiceNumber extends React.Component {

    render() {
        return (<>
                <div className="form-floating">
                    <input name="invoiceNumber" id="invoiceNumber" className={"form-control"} placeholder="Invoice #" onChange={(e) => this.props.filter.handleCaptureTextFilter('invoiceNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('invoiceNumber(ct)', e)} value={this.props.filter.filterText.invoiceNumber ? this.props.filter.filterText.invoiceNumber : ''} data-qaid="inputInvoiceNumberFilterMoneyIn"/>
                    <label htmlFor="invoiceNumber">Invoice #</label>
                </div>
            </>);
    }
}

export { RenderInvoiceNumber };