import { action } from 'mobx'

export default function ServiceFunctionHandle({ service, setService }) {
	const handleChangeServices = action((serviceType, serviceName, indexService, id, field, value, connector = false, currency = false, idexCurrency, bankTier = false, fielBankTier = '', indexBankTier, payorPrice = false, credentials = false, updatePurpose = false, updateGateways = true, configuration = false) => {
		const newData = service[serviceType][serviceName].map((data, index) => {
			if (index === indexService) {
				if (updateGateways) {
					const newDataGateway = data.Gateways.map((dataGateway) => {
						if (dataGateway.Id === id) {
							if (connector) {
								return {
									...dataGateway,
									Connector: {
										...dataGateway.Connector,
										[field]: value
									}
								}
							} else if (configuration) {
								return {
									...dataGateway,
									Connector: {
										...dataGateway.Connector,
										configuration: {
											...dataGateway.Connector.configuration,
											[field]: value
										}
									}
								}
							} else if (credentials) {
								return {
									...dataGateway,
									Connector: {
										...dataGateway.Connector,
										configuration: {
											...dataGateway.Connector.configuration,
											credentials: {
												...dataGateway.Connector.configuration.credentials,
												[field]: value
											}
										}
									}
								}
							} else if (bankTier) {
								const newBankTier = dataGateway[fielBankTier].map((dataBankTier, indexY) => {
									if (indexY === indexBankTier) {
										if (!payorPrice) {
											if (!updatePurpose) {
												return {
													...dataBankTier,
													[field]: value
												}
											}
											return {
												...dataBankTier,
												[field]: value,
												bankAccountFunction: 3
											}
										}
										return {
											...dataBankTier,
											payorPrice: {
												...dataBankTier.payorPrice,
												[field]: value
											}
										}
									}
									return dataBankTier
								})
								return {
									...dataGateway,
									[fielBankTier]: newBankTier
								}
							} else if (currency) {
								const newCurrency = dataGateway.Currencies.map((dataCurrency, indexK) => {
									if (indexK === idexCurrency) {
										return value
									}
									return dataCurrency
								})
								return {
									...dataGateway,
									Currencies: newCurrency
								}
							} else if (field === 'GatewayName') {
								return {
									...dataGateway,
									GatewayName: value,
									Connector: {
										...dataGateway.Connector,
										Name: ''
									}
								}
							} else {
								return {
									...dataGateway,
									[field]: value
								}
							}
						}
						return dataGateway
					})
					return {
						...data,
						Gateways: newDataGateway
					}
				} else {
					return {
						...data,
						[field]: value
					}
				}
			}
			return data
		})
		setService({
			...service,
			[serviceType]: {
				...service[serviceType],
				[serviceName]: newData
			}
		})
	})
	const handleChangeServicesHS = action((field, value) => {
		setService({ ...service, [field]: value })
	})

	return {
		handleChangeServices,
		handleChangeServicesHS
	}
}
