import React from 'react'
import LoaderPlaceHolder from '../loaderPlaceHolder'

export default function RadioButtonContainer({ name, setValue, value, labelValue, isLoading = false, placeholderStyle = '', errorValid = false, divStyle = {}, divClass = '', classNameInput = '', inputStyle = {}, labelClassName = '', labelStyle = {}, inputDisabled, readonly = '', required = false, onBlur = () => null }) {
	const handleChange = (e) => {
		setValue(e.target.checked)
	}
	return isLoading ? (
		<LoaderPlaceHolder extraStyles={placeholderStyle} />
	) : (
		<div
			className={`icheck-primary ${divClass}`}
			style={{ ...divStyle, border: errorValid && '1px solid #dc3545', padding: errorValid && '5px', borderRadius: errorValid && '.25rem' }}>
			<input
				className={classNameInput}
				style={inputStyle}
				name={name}
				required={required}
				onChange={handleChange}
				onBlur={onBlur}
				disabled={inputDisabled}
				readOnly={readonly}
				checked={value}
				type="radio"
				id={name}
			/>
			<label
				className={labelClassName}
				style={labelStyle}
				htmlFor={name}>
				{labelValue}
			</label>
		</div>
	)
}
