import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { DevelopersLinks } from '../../components/DevelopersLinks';
import { TopBar } from '../../components/TopBar';
import { PayabliCookieManager, PayabliStorageManager } from '../../api/localStorageManager';
import moneyin from '../../assets/images/moneyin.svg';
import moneyout from '../../assets/images/moneyout.svg';
import payabliIcon from '../../assets/images/payabli-icon.png';
import { BiHelpCircle, BiCog, BiBarChartSquare, BiDevices, BiBookContent, BiTestTube} from "react-icons/bi";

@inject('global','developers')
@observer
class ComponentCreator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            componentCreatorPath: "reportDashboard",
            iFrameParam: ''

        }
        this.goToCreator = this.goToCreator.bind(this);
    }
    
    componentDidMount() {
        // PayabliCookieManager.eraseCookie('jwtToken');
        //const jwtToken = PayabliCookieManager.readCookie('jwtToken');
        //PayabliCookieManager.createCookie('jwtToken', jwtToken, 1);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        
        const orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId : null;
        if(orgId){
            PayabliCookieManager.eraseCookie(`creatorContainer_${process.env.REACT_APP_ENVIRONMENT}`);
            PayabliCookieManager.createCookie(`creatorContainer_${process.env.REACT_APP_ENVIRONMENT}`, JSON.stringify({type:'org', id:orgId}), 1);
        }
        
        const encoded = Buffer.from(encryptStorage.getItem('pToken'), 'utf8').toString('base64');
        this.setState({ iFrameParam: encoded });
    }

    handleBackFunction(e){
        if(e){
            e.preventDefault();
        }
        const element = document.getElementById("iframeComponentCreator");
        element.contentWindow.postMessage("componentCreatorGoBack", '*');
    }

    goToCreator(componentCreatorPath, e){
        if(e){
            e.preventDefault();
        }
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        // const encoded = Buffer.from(encryptStorage.getItem('pToken'), 'utf8').toString('base64');
        // let param = encoded;
        // PayabliCookieManager.eraseCookie('jwtToken');
       //const jwtToken = PayabliCookieManager.readCookie('jwtToken');
        //PayabliCookieManager.createCookie('jwtToken', jwtToken, 1);
        let param = '';
        if(componentCreatorPath === 'settings'){
            let userId = (encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').id) ? encryptStorage.getItem('pUser').id : null;
            param = userId;
        }
        this.setState({ iFrameParam: param, componentCreatorPath: componentCreatorPath }, () => {
            document.getElementById('iframeComponentCreator').src = `${process.env.REACT_APP_URL_COMPONENT_CREATOR}/${componentCreatorPath}/${this.state.iFrameParam}`;
            this.props.global.setLoading(true);
        });
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        
        return (
            <Layout fullScreen={true} {...this.props}>

                    <div className='main-left-bar-fullscreen'>
                        <div id="main-branding-icon-fullscreen">
                            <a href={"/"+this.props.global.getURLEntry()+"/dashboard"}><img alt="" src={payabliIcon } /></a>
                        </div>

                        <ul id="main-menu-ul-cc">
                            <li onClick={()=>this.goToCreator('payDashboard')} className={ this.state.componentCreatorPath === "payDashboard" ? 'active' : ""}><img style={{width: "31px", display: "inline-block"}} alt="" src={moneyin}/><br/> Pay In</li>
                            <li onClick={()=>this.goToCreator('payOutDashboard')} className={ this.state.componentCreatorPath === "payOutDashboard" ? 'active' : ""}><img style={{width: "31px", display: "inline-block"}} alt="" src={moneyout}/><br/> Pay Out</li>
                            <li onClick={()=>this.goToCreator('reportDashboard')} className={ this.state.componentCreatorPath === "reportDashboard" ? 'active' : ""}><BiBarChartSquare/> Reports</li>
                            <li onClick={()=>this.goToCreator('boardingDashboard')} className={ this.state.componentCreatorPath === "boardingDashboard" ? 'active' : ""}><BiBookContent/> Boarding</li>
                            <li onClick={()=>this.goToCreator('shopDashboard')} className={ this.state.componentCreatorPath === "shopDashboard" ? 'active' : ""}><BiDevices/> Shop</li>
                            {userPermissions.indexOf("Developers") !== -1 &&
                                <li onClick={()=>this.goToCreator('testEmbeded')} className={ this.state.componentCreatorPath === "testEmbeded" ? 'active' : ""}><BiTestTube/> Test</li>
                            }
                        </ul>

                        <div className="main-menu-bottom">
                        <ul>
       
                            <li className={''}>
                                <a title="Settings" href={"/"} onClick={(e)=>this.goToCreator('settings', e)}><div className="icon"><BiCog/></div>
                                Settings
                                </a>
                            </li>
           
                            <li>
                                <a title="Support" href="mailto:support@payabli.com"><div className="icon"><BiHelpCircle/></div>
                                Support
                                </a>
                            </li>
                        </ul>
                        </div>

                    </div>
                    <div className={"main-body-full-screen"} style={{backgroundColor: this.props.bgcolor ? this.props.bgcolor: '#ffffff'}}>
                   
                    <div>
                    <TopBar style={{paddingLeft: "100px"}}>
                        <MainTopBarMenu/>
                        <div className="top-bar-sub">
                            <DevelopersLinks selected="componentCreator"/>
                        </div>
                    </TopBar>                
                    
                    <iframe id="iframeComponentCreator" allow="clipboard-write" title='Creator' key={'iframe-crator'} className='creatorContainer' src={`${process.env.REACT_APP_URL_COMPONENT_CREATOR}/${this.state.componentCreatorPath}`}></iframe>             
                    </div>


                    </div>


                
                
            </Layout>
        )
    }
}

export { ComponentCreator };