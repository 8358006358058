import React from 'react';
import {ComponentRangeAmount} from './ComponentRangeAmount.js'

class RenderNetAmount extends React.Component {

    render() {
        return (
            <ComponentRangeAmount
                id={'netAmount'}
                title={'Amount'}
                minRange={0}
                maxRange={1000}
                minParameter={'minAmount'}
                maxParameter={'maxAmount'}
                filterParameter={'netAmount'}
            />
        );
    }
}

export { RenderNetAmount };