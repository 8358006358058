import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { VirtualTerminal } from '../../components/VirtualTerminal';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { Nav, Tab} from 'react-bootstrap';
import { Message } from '../../components/Message';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '../../components/Layout'
import { PaypointLinks } from '../../components/PaypointLinks';

@inject('global','vTerminal', 'reports')
@observer
class ViewVirtualTerminal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entry: '',
            payPoint: null
        };
        this.getPaypointCredentials = this.getPaypointCredentials.bind(this);
        this.reloadPage = this.reloadPage.bind(this);
    }
    
    componentDidMount() {
        let entry = this.props.match.params.entry;
        if(entry){
            this.getPaypointCredentials(entry);
            this.setState({entry:entry});
            this.props.vTerminal.setEntryPoint(entry);
            this.props.reports.setPaypoint(entry);
        }

        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        
        this.props.vTerminal.disableAutopay(false);
        let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;
        if(credentialsPermissions.ach.recurring || credentialsPermissions.card.recurring || credentialsPermissions.wallet.recurring || credentialsPermissions.cloud.recurring) {

            if(userPermissions.indexOf("Autopay") !== -1 ){
                this.props.vTerminal.disableAutopay(true);
             }
             else{
                this.props.vTerminal.disableAutopay(false);
             }
        }


        
    }

    reloadPage(){
        window.location.reload();
    }

    getPaypointCredentials(entry){
        this.props.global.setLoading(true);
        this.props.vTerminal.getPaypointCredentials(entry, false).then(res => {
            this.setState({payPoint:res})
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    render() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        
        return (
            <Layout {...this.props}>
            <div>

                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <PaypointLinks idPaypoint={this.state.payPoint ? this.state.payPoint.Paypoint.IdPaypoint : ''} entry={this.state.entry ? this.state.entry : ''} gobackLink={"/"+PayabliStorageManager.getEntryName()+"/paypoints/overview/"+ (this.state.entry ? this.state.entry : '')+"/"+(this.state.payPoint ? this.state.payPoint.Paypoint.IdPaypoint : '')} goback={true}  gobackText="Paypoints Overview" history={this.props.history} selected="virtualterminal" />
                </div>
                </TopBar>
                

                <div className="mt-body4">

                <div className="d-flex flex-column justify-content-center align-items-center">
                <h5 className="mb-0">{this.state.payPoint ? this.state.payPoint.Paypoint.LegalName : ''}</h5>
                <p className="small-grey mb-5">DBA {this.state.payPoint ? this.state.payPoint.Paypoint.DbaName : ''}</p>
                <div id="vterminal-container" className="card" >
                    <div className="card-body main-cont-3 main-card-body">

                    <Tab.Container defaultActiveKey="requestPayment">
                        <Nav fill variant="tabs" defaultActiveKey="requestPayment" className="vterminal-tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="requestPayment">Request a Payment</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="payBill">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pay a Bill&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="requestPayment">
                                {(userPermissions.indexOf("Accept Payments") !== -1 || userPermissions.indexOf("Autopay") !== -1 ) ?
                                    <VirtualTerminal entry={this.props.match.params.entry} autopay={userPermissions.indexOf("Accept Payments") === -1 || userPermissions.indexOf("Autopay") === -1 ? 0 : ''} paymentSuccessFunctionCallBack={this.reloadPage}/>
                                :
                                    <Message message="Sorry. You can't accept payments."  />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="payBill">
                                <div style={{marginTop: "30px"}}>
                                    <Message message="Coming Soon."  />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                      

                    </div>
                </div>
                </div>
           
                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { ViewVirtualTerminal };