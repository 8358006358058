import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import 'rc-slider/assets/index.css';
import {BiArchive, BiBox, BiDollar, BiDollarCircle } from 'react-icons/bi'
import { RenderCustomFields } from '../../components/filters/RenderCustomFields';
import { RenderOrganization } from '../../components/filters/RenderOrganization';
import {ComponentRangeAmount} from './ComponentRangeAmount.js'

@inject('reports', 'global', 'filter')
@observer
class ViewPartialBatchesMo extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <RenderOrganization/>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="batchNumber" id="batchNumber" className={"form-control"} placeholder="Batch Number" onChange={(e) => this.props.filter.handleCaptureTextFilter('batchNumber(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('batchNumber(eq)', e)} value={this.props.filter.filterText.batchNumber ? this.props.filter.filterText.batchNumber : ''} data-qaid="inputBatchOutNumberFilter"/>
                            <label htmlFor="batchNumber">Batch Number</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="batchClosedDateCalendar" 
                                        name="batchClosedDateCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startBatchClosedDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startBatchClosedDate) + ' → ' : '') + (this.props.filter.stateDate.endBatchClosedDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endBatchClosedDate) : '')} 
                                        placeholder="Batch Closed Date" 
                                    />
                                    <label htmlFor="batchClosedDateCalendar">Batch Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startBatchClosedDate ? this.props.filter.stateDate.startBatchClosedDate : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startBatchClosedDate', 'endBatchClosedDate', 'batchDate')}
                            startDate={this.props.filter.stateDate.startBatchClosedDate}
                            endDate={this.props.filter.stateDate.endBatchClosedDate}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endBatchClosedDate !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                </div>
                <div className='mb-2'>
                    <ComponentRangeAmount
                        id={'batchAmount'}
                        title={'Batch Total'}
                        minRange={0}
                        maxRange={1000}
                        minParameter={'minBatchTotal'}
                        maxParameter={'maxBatchTotal'}
                        filterParameter={'batchAmount'}
                    />
                    <div>
                        <p><small>Batch Status</small></p>
                        <div className='section-status mb-4'>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiArchive /> Open</span>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))}><BiBox /> Closed</span>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 2, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]))}><BiDollar /> Funded</span>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleStatus('status(in)', 3, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]))}>{this.props.global.getGlobalImg((Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]) ? 'intransitinfodark' : 'intransitinfo', '12px')} Processed</span>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[4]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 4, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[4]))}><BiDollarCircle /> Paid</span>
                        </div>
                    </div>
                </div>
                { Array.isArray(this.props.filter.customFields) && <RenderCustomFields /> }
            </>);
    }
}

export { ViewPartialBatchesMo };