import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {IMaskInput} from 'react-imask';
import { SettingsLinks } from '../../components/SettingsLinks';
import { PayabliStorageManager } from '../../api/localStorageManager'
import zxcvbn from 'zxcvbn';
import { BiCheckCircle, BiMinusCircle, BiBriefcase, BiLockAlt } from 'react-icons/bi';
import {BiEdit} from '@react-icons/all-files/bi/BiEdit';
import {BiImageAdd} from '@react-icons/all-files/bi/BiImageAdd';
import { BiLowVision } from '@react-icons/all-files/bi/BiLowVision';
import { BiShowAlt } from '@react-icons/all-files/bi/BiShowAlt';
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle';
import PhoneInput from '../../components/PhoneInput';

@inject('user', 'global')
@observer
class Profile extends React.Component {

    legalName = () => {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return encryptStorage.getItem(this.props.global.getURLEntry()+"_pEntry").legalName;
    }

    constructor(props) {
        super(props);
        this.state = {
            user : null,
            permissions : [],
            permissionsEnabled : [],
            countPermissions : [],
            countPermissionsChecked : [],
            editUsernamePswModalIsOpen: false,
            editUserInfoModalIsOpen: false,
            editPermissionsModalIsOpen: false,
            editMFAModalIsOpen: false,
            profileId: null,
            phoneError: false,

            username: '',
            password: '',
            repeatPassword: '',
            score: 0,
            
            name: '',
            phone: '',
            status: '',
            timeZone: 0,
            timeZoneSaved: 0,

            showPassword: false,
            showRepeatPassword: false,
            
            credentialsErrors :{},
            userErrors :{},

            enableMFA: false,
            enableMFASaved: false,
            enableMFAType: '',
            enableMFATypeSaved: '',
            enableMFAError: false,
            enableMFAQRCodeAuth: null,
            enableMFAQRCodeManual: '',
            avatar: {
                ftype: "",
                filename: null,
                furl: null,
                fContent: ""
            }
        };
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.showError = this.showError.bind(this);
        this.closeUsernamePswModal = this.closeUsernamePswModal.bind(this);
        this.openUsernamePswModal = this.openUsernamePswModal.bind(this);
        this.closeUserInfoModal = this.closeUserInfoModal.bind(this);
        this.openUserInfoModal = this.openUserInfoModal.bind(this);
        this.closePermissionsModal = this.closePermissionsModal.bind(this);
        this.openPermissionsModal = this.openPermissionsModal.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.validateCredentialsFields = this.validateCredentialsFields.bind(this);
        this.validateUserFields = this.validateUserFields.bind(this);
        this.hasMFAFieldsErrors = this.hasMFAFieldsErrors.bind(this);
        this.saveUsernamePassword = this.saveUsernamePassword.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.savePermissions = this.savePermissions.bind(this);
        this.handlePermissions = this.handlePermissions.bind(this);
        this.getUserFromApi = this.getUserFromApi.bind(this);
        this.checkRequired = this.checkRequired.bind(this);
        this.showPassword = this.showPassword.bind(this);
        this.showRepeatPassword = this.showRepeatPassword.bind(this);
        this.selectTimezone = this.selectTimezone.bind(this);
        this.openMFAModal = this.openMFAModal.bind(this);
        this.closeMFAModal = this.closeMFAModal.bind(this);
        this.handleEnableMFA = this.handleEnableMFA.bind(this);
        this.handleCheckMFA = this.handleCheckMFA.bind(this);
        this.saveMFA = this.saveMFA.bind(this);
        this.setPhoneError = this.setPhoneError.bind(this);
        this.selectFileLogo = this.selectFileLogo.bind(this);
        this.inputFileLogo = React.createRef();
        this.handleFileRead = this.handleFileRead.bind(this);
    }
    handleFileRead(e){
        let file = e.target.files[0];
        if(file){
            let fileExtension = this.props.global.getFileExtension(file.type);
            if( (fileExtension === "jpeg" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "gif") && file.size <= 1048576){
                this.props.global.readFileBase64(file).then(base64 => {
                    let base64string = base64.split(',');
                    if(base64string[0] &&  base64string[1]){
                        const avatar = {
                            ftype: 'png',
                            filename: file.name,
                            furl: null,
                            fContent: base64string[1]
                        }
                        this.setState({fileError: false, avatar: avatar}, () => {
                            this.updateUserAvatar();
                        });
                    }
                    
                })
                .catch(error => {
                    console.log(error);
                });
            }
            else{
                this.setState({fileError: true});
                toast.error('An error occurred while loading the image for editing. Check the extension or size of the image.', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            }
            
        }
        
    }
    selectFileLogo(){
        this.inputFileLogo.current.click();
    }
    handleTextChange(e){
        this.setState({ [e.target.id]: e.target.value }, function(){
            let testedResult = zxcvbn(this.state.password);
            this.setState({score : testedResult.score});
        });
    }

    handleEnableMFA(e){
        this.setState({enableMFA:e.target.checked});
    }
    
    handleCheckMFA(e){
        this.setState({enableMFAType:e.target.value});
    }

    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }

    openMFAModal(e){
        if(e){
            e.preventDefault();
        }
        this.setState({editMFAModalIsOpen:true, enableMFAQRCodeAuth: null})
    }

    saveMFA(){
        this.setState({enableMFAError : this.hasMFAFieldsErrors()}, function(){
            if(!this.state.enableMFAError){
                this.props.user.updateUserMFA(this.state.user.userId, this.state.enableMFA, this.state.enableMFAType)
                .then(result => {

                    if(result.data.responseData && result.data.responseData.qr){
                        this.setState({enableMFAQRCodeAuth: result.data.responseData.qr,enableMFAQRCodeManual: result.data.responseData.manual});
                        this.getUserFromApi(this.state.profileId);
                    }else{
                        this.closeMFAModal();
                        toast.success("Profile updated successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });
                        this.getUserFromApi(this.state.profileId);
                    }
                    
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
        });
        
    }
    
    closeMFAModal(){
        this.setState({editMFAModalIsOpen:false})
    }

    handlePermissions(obj, i, e, f, parent ){
        let permissions = this.state.permissions;
        let countPermissions = this.state.countPermissions;
        let countPermissionsChecked = this.state.countPermissionsChecked;
        let object = permissions[i];

        if(i!==null && e!==null && f!==null){
            permissions[i].childs[e].childs[f].checked = obj.target.checked;
            object = permissions[i].childs[e].childs[f];
            
        }else if(i!==null && e!==null){
            permissions[i].childs[e].checked = obj.target.checked;
            object = permissions[i].childs[e];
            if (typeof object.requiredBy == 'string') {
                const requieredIndex = permissions[i].childs.findIndex((childPermissions) => {
                    return childPermissions.permission === object.requiredBy
                });
                if (requieredIndex !== -1 && permissions[i].childs[requieredIndex].checked && !obj.target.checked) {
                    permissions[i].childs[requieredIndex].checked = false
                    countPermissionsChecked[permissions[i].permission] -= 1;
                }
            } else {
                const requieredIndex = permissions[i].childs.findIndex((childPermissions) => {
                    return childPermissions.requiredBy === object.permission
                });
                if (requieredIndex !== -1) {
                    permissions[i].childs[requieredIndex].checked = obj.target.checked
                    countPermissionsChecked[permissions[i].permission] = obj.target.checked
                        ? countPermissionsChecked[permissions[i].permission] + 1
                        : countPermissionsChecked[permissions[i].permission] - 1;
                }
            } 
        }else if(i!==null){
            permissions[i].checked = obj.target.checked;
            
            permissions[i] = this.checkChildrens(permissions[i], obj.target.checked);
            if(obj.target.checked){
                countPermissionsChecked[permissions[i].permission] = countPermissions[permissions[i].permission];
            }
            else{
                countPermissionsChecked[permissions[i].permission] = 0;
            }
        }

        //checkboxes dependency
        // if(object.requiredBy){
        //     if(object.checked){
        //         this.checkRequired(object.requiredBy);
        //     }
        // }

        if(parent){
            if(obj.target.checked){
                countPermissionsChecked[parent] = countPermissionsChecked[parent] + 1;
            }
            else{
                countPermissionsChecked[parent] = countPermissionsChecked[parent] - 1;
            }
    
            if(countPermissionsChecked[parent] === 0){
                permissions[i].checked = false;
            }else{
                permissions[i].checked = true;
            }
        }
        
        

        this.setState({ permissions: permissions, countPermissionsChecked: countPermissionsChecked });
    }

    selectTimezone(e){
        if(e){
            this.setState({timeZone:e.target.value})
        }
    }

    checkChildrens(permission,checked){
       if(permission.childs){
            permission.childs.forEach(function (secondLevel) {
                secondLevel.checked = checked;
                if(secondLevel.childs){
                    secondLevel.childs.forEach(function (thirdLevel) {
                        thirdLevel.checked = checked;
                    });
                } 
            });
        }
        return permission;
    }

    checkRequired(required){
        let permissions = this.state.permissions;
        permissions.forEach(function (item) {
            if(item.permission === required){
                item.checked = true;
            }
            if(item.childs){
                item.childs.forEach(function (secondLevel) {
                    if(secondLevel.permission === required){
                        secondLevel.checked = true;
                    }
                    if(secondLevel.childs){
                        secondLevel.childs.forEach(function (thirdLevel) {
                            if(thirdLevel.permission === required){
                                thirdLevel.checked = true;
                            }
                        });
                    } 
                });
            }
            
        });

        this.setState({permissions: permissions});
    }
    
    componentDidMount() {
        this.props.global.setLoading(true);
        let profileId = this.props.match.params.id;
        this.setState({profileId: profileId});
        this.getUserFromApi(profileId);
    }

    getUserFromApi(profileId){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        let entry = this.props.match.params.entryName;
        this.props.user.getUserFromApi(profileId, entry).then(res => {
            this.setState({user: res, username: res.Email, status: res.UsrStatus, name: res.Name, phone: res.Phone, timeZone: res.TimeZone, timeZoneSaved: res.TimeZone, enableMFA: res.UsrMFA, enableMFASaved: res.UsrMFA, enableMFAType: res.UsrMFAMode, enableMFATypeSaved: res.UsrMFAMode }, function(){
                this.props.user.getPermissionsFromApi().then(permissions => {
                    let permissionsDictionary = [];
                    if(res.Access){
                        res.Access.forEach(function (item) {
                            permissionsDictionary[item.roleLabel] = item.roleValue
                        });
                    }
                    
                    let countPermissionsChecked = [];
                    let countPermissions = [];
                    
                    permissions.forEach(function (item) {
                        item.checked = permissionsDictionary[item.permission];
                        
                        countPermissions[item.permission] = 0;
                        countPermissionsChecked[item.permission] = 0;

                        if(item.childs){
                            item.childs.forEach(function (secondLevel) {
                                secondLevel.checked = permissionsDictionary[secondLevel.permission];
                                secondLevel.parent = item.permission;

                                if(userPermissions.indexOf(secondLevel.permission) !== -1)
                                {
                                    countPermissions[item.permission] = countPermissions[item.permission] + 1;
                                    countPermissionsChecked[item.permission] = secondLevel.checked ? countPermissionsChecked[item.permission] + 1 : countPermissionsChecked[item.permission];
                                }

                                if(secondLevel.childs){
                                    secondLevel.childs.forEach(function (thirdLevel) {
                                        thirdLevel.checked = permissionsDictionary[thirdLevel.permission];
                                        thirdLevel.parent = item.permission;
                                        
                                        if(userPermissions.indexOf(thirdLevel.permission) !== -1)
                                        {
                                            countPermissions[item.permission] = countPermissions[item.permission] + 1;
                                            countPermissionsChecked[item.permission] = thirdLevel.checked ? countPermissionsChecked[item.permission] + 1 : countPermissionsChecked[item.permission];
                                        }

                                    });
                                } 
                            });
                        }
                        
                    });
                    let permissionsEnabled = JSON.parse(JSON.stringify(permissions));
                    this.setState({avatar: res?.AdditionalData?.avatar, permissions: permissions,permissionsEnabled: permissionsEnabled,countPermissions:countPermissions,countPermissionsChecked:countPermissionsChecked });
                    this.props.global.setLoading(false);
                }).catch(error => {
                    this.showError(error);
                    this.props.global.setLoading(false);
                });
            });
        })
        .catch(error => {
            this.showError(error);
            this.props.global.setLoading(false);
        });

    }

    closeUsernamePswModal(){
        this.setState({editUsernamePswModalIsOpen:false})
    }
    openUsernamePswModal(e){
        if(e){
            e.preventDefault();
        }
        this.setState({editUsernamePswModalIsOpen:true, score: 0})
    }

    closeUserInfoModal(){
        this.setState({editUserInfoModalIsOpen:false})
    }
    
    
    closePermissionsModal(){
        let permissions = JSON.parse(JSON.stringify(this.state.permissionsEnabled));
        this.setState({editPermissionsModalIsOpen:false, permissions: permissions})
    }

    openPermissionsModal(e){
        if(e){
            e.preventDefault();
        }
        this.setState({editPermissionsModalIsOpen:true})
    }

    openUserInfoModal(e){
        if(e){
            e.preventDefault();
        }
        this.setState({editUserInfoModalIsOpen:true})
    }

    showError(error){
        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
        toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
        });
    }

    getStatus(status){
        switch(status){
            case 1:
                return <span className="badge bg-primary"><BiCheckCircle /> Active</span>;
            case 0:
                return <span className="badge bg-light"><BiMinusCircle /> Inactive</span>;
            case 85:
                return <span className="badge bg-warning"><BiLockAlt /> Locked</span>;
            default:
                return '-';
        }
    }

    savePermissions(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        this.props.global.setLoading(true); 
        let user ={
            id: this.state.user.userId
        };
        this.props.user.updatePermissions(user,this.state.permissions,  
            encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : []
        )
        .then(result => {
            toast.success("Profile updated successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container'
            });
            let permissionsEnabled = JSON.parse(JSON.stringify(this.state.permissions));
            this.setState({permissionsEnabled:permissionsEnabled}, function(){
                this.closePermissionsModal();
                this.props.global.setLoading(false);
            })
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    saveUsernamePassword(){
        let errors = this.validateCredentialsFields();
        this.setState({ credentialsErrors: errors }, function(){
            
            if(Object.keys(errors).length === 0){
                let user ={
                    email: this.state.username,
                    pwd: this.state.password,
                    id: this.state.user.userId
                };
                this.props.global.setLoading(true);   
                this.props.user.updateUserCredentials(user)
                .then(result => {
                    this.closeUsernamePswModal();
                    toast.success("Profile updated successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                    this.getUserFromApi(this.state.profileId);
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
        });
    }
    updateUserAvatar(){
        let user ={
            name: this.state.name,
            email: this.state.username,
            id: this.state.user.userId,
            usrStatus: this.state.status,
            additionalData: {
                avatar: JSON.stringify(this.state.avatar)
            }
        };
        this.props.global.setLoading(true);   
        this.props.user.updateUserInfo(user)
        .then(result => {
            toast.success("Profile updated successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container'
            });
            this.getUserFromApi(this.state.profileId);

            // if you are editing your user profile
            // let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
            // if(encryptStorage && parseInt(this.state.user.userId) === parseInt(encryptStorage.getItem('pUser').id)){
            //     let userSession = encryptStorage.getItem('pUser');
            //     userSession.timeZone = this.state.timeZone;
            //     encryptStorage.setItem('pUser', userSession);
            // }
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
            
    }
    saveUser(){
        
        let errors = this.validateUserFields();
        this.setState({ userErrors: errors }, function(){
            
            if(Object.keys(errors).length === 0){
                let user ={
                    name: this.state.name,
                    phone: this.state.phone,
                    email: this.state.username,
                    id: this.state.user.userId,
                    usrStatus: this.state.status,
                    timeZone: this.state.timeZone,
                    /* additionalData: {
                        avatar: this.state.avatar
                    } */
                };
                this.props.global.setLoading(true);   
                this.props.user.updateUserInfo(user)
                .then(result => {
                    this.closeUserInfoModal();
                    toast.success("Profile updated successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                    this.getUserFromApi(this.state.profileId);

                    // if you are editing your user profile
                    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
                    if(encryptStorage && parseInt(this.state.user.userId) === parseInt(encryptStorage.getItem('pUser').id)){
                        let userSession = encryptStorage.getItem('pUser');
                        userSession.timeZone = this.state.timeZone;
                        encryptStorage.setItem('pUser', userSession);
                    }
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
        });
    }

    hasMFAFieldsErrors(){
        return this.state.enableMFA === true && this.state.enableMFAType === '' ? true : false;
    }

    validateCredentialsFields(){
        let validators = this.props.global.validators;
        let errors = {};
        if(validators.isEmpty(this.state.username) || validators.isMaxLength(250, this.state.username) || validators.stringValidator('email',this.state.username))
        {
            errors['username']= true;
        }

        if(validators.isEmpty(this.state.password) || validators.isMaxLength(250, this.state.password)  || (this.state.score < 4))
        {
            errors['password']= true;
        }

        if(validators.isEmpty(this.state.repeatPassword) || validators.isMaxLength(250, this.state.repeatPassword) || (this.state.password !== this.state.repeatPassword ))
        {
            errors['repeatPassword']= true;
        }
        return errors;
    }
    setPhoneError(error){
        this.setState({phoneError: error});
    }
    validateUserFields(){
        let validators = this.props.global.validators;
        let errors = {};
        if(validators.isEmpty(this.state.name) || validators.isMaxLength(250, this.state.name))
        {
            errors['name']= true;
        }
        
        if(validators.isEmpty(this.state.username) || validators.isMaxLength(250, this.state.username) || validators.stringValidator('email',this.state.username))
        {
            errors['username']= true;
        }

        if(this.state.phoneError)
        {
            errors['phone']= true;
        }

        if(validators.isEmpty(this.state.status))
        {
            errors['status']= true;
        }

        return errors;
    }

    showPassword(){
        this.setState({ showPassword:!this.state.showPassword });
    }
    
    showRepeatPassword(){
        this.setState({ showRepeatPassword:!this.state.showRepeatPassword });
    }
   
    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let reactObject = this;
        let userId = (encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').id) ? encryptStorage.getItem('pUser').id : null;
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];

        return (
            <Layout {...this.props}>
            <div>

                <Modal show={this.state.editUsernamePswModalIsOpen} onHide={this.closeUsernamePswModal}  size="md" centered >
                <Modal.Body className="popover-body">
                        <div className="row mb-3">
                            <div className="col"><h6>Edit Login Information</h6></div>
                        </div>

                        <div className="col-12 mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('email')}
                                name="username"
                                unmask={true}
                                value={this.state.username}                                
                                onAccept={
                                    (value, mask) => this.handleTextChangeMask('username', value)
                                }
                                placeholder="Username"
                                className={this.state.credentialsErrors.username ? "form-control input-error" : "form-control"}
                                id="username"
                            />
                            <label htmlFor="userName">Username</label>
                        </div>
                        </div>

                        <div className="col-12 mb-3">
                        <div className="form-floating">
                            <input type={ this.state.showPassword ? "text" : "password"} className={this.state.credentialsErrors.password ? "form-control input-error" : "form-control"} id="password" onChange={(e) => this.handleTextChange(e)} placeholder="Password" />
                            <label htmlFor="password">Password</label>
                            {
                                this.state.showPassword ? 
                                <BiLowVision className="right-icon-on-input" onClick={(e)=>this.showPassword()}/> 
                                :
                                <BiShowAlt className="right-icon-on-input" onClick={(e)=>this.showPassword()}/>
                            }
                        </div>
                        </div>

                        <div className="col-12 mb-3">
                        <div className="form-floating">
                            <input type={ this.state.showRepeatPassword ? "text" : "password"} className={this.state.credentialsErrors.repeatPassword ? "form-control input-error" : "form-control"} id="repeatPassword" onChange={(e) => this.handleTextChange(e)}  placeholder="Repeat password" />
                            <label htmlFor="repeatPassword">Repeat password</label>
                            {
                                this.state.showRepeatPassword ? 
                                <BiLowVision className="right-icon-on-input" onClick={(e)=>this.showRepeatPassword()}/> 
                                :
                                <BiShowAlt className="right-icon-on-input" onClick={(e)=>this.showRepeatPassword()}/>
                            }
                        </div>
                        </div>

                        <div className="col-12 mb-4 text-center">
                        <p className="small">The password must be <b>Strong</b> to be valid</p>
                        <progress value={this.state.score} max="4" className={"mb-1 password-strength-meter-progress strength-"+this.props.global.createPasswordLabel(this.state.score)}/>
                        <p className="mb-4 small">Password is <b>{this.props.global.createPasswordLabel(this.state.score)}</b></p>
                        </div>

                        <div className="row">
                            <div className="col">
                                <button className="btn full-w btn-light" type="button" onClick={() => this.closeUsernamePswModal() }>Cancel</button>
                            </div>
                            <div className="col">
                            <button className="btn full-w btn-primary" type="button" onClick={() => this.saveUsernamePassword()}>Save</button>   
                            </div>
                        </div>  
                </Modal.Body>
                </Modal>

                 {parseInt(userId) !== parseInt(this.state.profileId) &&
                <Modal show={this.state.editPermissionsModalIsOpen} onHide={this.closePermissionsModal}  size="md" centered >
                <Modal.Body className="popover-body">
                        <div className="row mb-3">
                            <div className="col"><h6>Edit Permissions</h6></div>
                        </div>
                        
                        {
                            <div className="row">
                                {
                                this.state.permissions.map((record, i) => (

                                    userPermissions.indexOf(record.permission) !== -1 &&
                                    <div key={i} className="col-4 mb-3">
                                    <div className="mb-3">
                                    <div className="icheck-success">
                                        <input checked={ record.checked ? true : false } type="checkbox" id={i} onChange={(obj) => reactObject.handlePermissions(obj, i, null, null)} />
                                        <label htmlFor={i}><b className="small-small">{record.permission}</b></label>
                                    </div>
                                    </div>

                                        {
                                            record.childs &&
                                            record.childs.map((child1, e )=> (

                                                userPermissions.indexOf(child1.permission) !== -1 &&
                                                <div key={i+"second"+e}>
                                                <div className="mb-3">
                                                <div className="icheck-success">
                                                    <input readOnly checked={ child1.checked ? true : false } type="checkbox" id={i+"second"+e} onChange={(obj) => reactObject.handlePermissions(obj, i, e, null, child1.parent)}/>
                                                    <label className="small-small" htmlFor={i+"second"+e}>{child1.permission}</label>
                                                </div>
                                                </div>

                                                {
                                                    child1.childs &&
                                                    child1.childs.map((child2, f )=> (

                                                        userPermissions.indexOf(child2.permission) !== -1 &&
                                                        <div key={e+"third"+f} className="mb-3">
                                                        <div className="icheck-success">
                                                            <input readOnly checked={ child2.checked ? true : false } type="checkbox" id={e+"third"+f} onChange={(obj) => reactObject.handlePermissions(obj, i, e, f ,child2.parent)}/>
                                                            <label className="small-small" htmlFor={e+"third"+f}>{child2.permission}</label>
                                                        </div>
                                                        </div>
                                                    ))
                                                }
                                                </div>
                                            ))
                                        }

                                    </div>
                                ))
                                    }
                                </div>
                            }

                            <div className="row">
                                <div className="col">
                                    <button className="btn full-w btn-light" type="button" onClick={() => this.closePermissionsModal() }>Cancel</button>
                                </div>
                                <div className="col">
                                <button className="btn full-w btn-primary" type="button" onClick={() => this.savePermissions()}>Save</button>   
                            </div>
                        </div>  
                          
                </Modal.Body>
                </Modal>
                }
               
               <Modal show={this.state.editUserInfoModalIsOpen} onHide={this.closeUserInfoModal}  size="md" centered >
                <Modal.Body className="popover-body">
                        <div className="row mb-3">
                            <div className="col"><h6>Edit User Information</h6></div>
                        </div>
                        
                        <div className="row mb-4">
                            <div className="col-12 mb-3">
                            <div className="form-floating">
                                <input  value={this.state.name} id="name" onChange={(e) => this.handleTextChange(e)} className={this.state.userErrors.name ? "form-control input-error" : "form-control"} placeholder="Full Name" />
                                <label htmlFor="name">Full Name</label>
                            </div>
                            </div>

                            <div className="col-6 mb-3">
                            <div className="form-floating">    
                                <PhoneInput
                                    required={(this.state.enableMFA && this.state.enableMFATypeSaved === 2) ? true : false}
                                    iValue={ this.state.phone || '' } 
                                    iSetError={this.setPhoneError} 
                                    iCustomClass="inner-addon" 
                                    iTitle="Primary Phone #" 
                                    iMask="phone" 
                                    iOnChange={this.handleTextChangeMask}
                                    iOnChangeForce={this.handleTextChangeMask}
                                    iName="phone"  
                                    full
                                />
                            </div>
                            </div>

                            <div className="col-6  mb-3">
                            <div className="form-floating">                                
                                <IMaskInput
                                    mask={this.props.global.maskValidator('email')}
                                    name="username"
                                    unmask={true}
                                    value={this.state.username}                                    
                                    onAccept={
                                        (value, mask) => this.handleTextChangeMask('username', value)
                                    }
                                    placeholder="Email"
                                    className={this.state.userErrors.username ? "form-control input-error" : "form-control"}
                                    id="username"
                                />
                                <label htmlFor="username">Email</label>
                            </div>
                            </div>

                            <div className="col-6 mb-3">
                                <div className="form-floating">
                                    <select id="language" className="form-select form-control" disabled>
                                        <option value="" defaultValue>English</option>
                                    </select>
                                    <label htmlFor="language">Language</label>
                                </div>
                            </div>

                            <div className="col-6 mb-3">
                                <div className="form-floating">
                                <select id="timezone"  className="form-select form-control" onChange={(e)=> this.selectTimezone(e)} value={this.state.timeZone}>
                                <option value="0">UTC</option>
                                <option value="-4">Atlantic Standard Time (AST) - UTC-04:00</option>
                                <option value="-5">Eastern Standard Time (EST) - UTC-05:00</option>
                                <option value="-6">Central Standard Time (CST) - UTC-06:00</option>
                                <option value="-7">Mountain Standard Time (MST) - UTC-07:00</option>
                                <option value="-8">Pacific Standard Time (PST) - UTC-08:00</option>
                                <option value="-9">Alaska Standard Time (AKST) - UTC-09:00</option>
                                <option value="-10">Hawaii-Aleutian Standard Time (HST) - UTC-10:00</option>
                                </select>
                                <label htmlFor="timezone">Time zone</label>
                                </div>
                            </div>

                            <div className="col-12">
                            <div className="form-floating">
                                <select className={this.state.userErrors.status ? "form-select form-control input-error" : "form-select form-control"} id="status" onChange={(e) => this.handleTextChange(e)} defaultValue={ this.state.user ? this.state.user.UsrStatus :''}>
                                    <option value="">Status</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                    <option value={85}>Locked</option>
                                </select>
                                <label htmlFor="status">Status</label>
                            </div>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col">
                                <button className="btn full-w btn-light" type="button" onClick={() => this.closeUserInfoModal() }>Cancel</button>
                            </div>
                            <div className="col">
                            <button className="btn full-w btn-primary" type="button" onClick={() => this.saveUser()}>Save</button>   
                            </div>
                        </div>  
                </Modal.Body>
                </Modal>

                <Modal show={this.state.editMFAModalIsOpen} onHide={this.closeMFAModal}  size="md" centered>
                <Modal.Body className="popover-body">
                        <div className="row mb-3">
                            <div className="col"><h6>Edit multi-factor authentication</h6></div>
                        </div>
                        
                        <div className="mb-4">
                            <div className="mb-3">
                                <div className="icheck-success">
                                    <input name="mfaActivate" id="mfaActivate" onChange={(e) => this.handleEnableMFA(e)} type="checkbox" checked={this.state.enableMFA}/>
                                    <label htmlFor="mfaActivate"><b>Enable multi-factor authentication (MFA)</b></label>
                                </div>
                            </div>

                            <p className="small-small">
                                To protect your security, we will provide you with a security code to confirm your identity. You will use this code once, to complete your login.
                            </p>
                            <p className="small-small">
                                How would you like to receive this security code?
                            </p>
                            { (this.state.enableMFAError && this.state.enableMFA) &&
                                <p className="small-small text-danger">Please select at least one</p>
                            }

                            <div className="row">
                                <div className="col-sm-7">
                                    <div className="icheck-success">
                                        <input
                                            name="enableMFAType"
                                            type="radio"
                                            id="textMessageMFAModal"
                                            disabled={!this.state.enableMFA && this.state.user && this.state.user.Phone}
                                            value="2"
                                            onChange={(e)=> this.handleCheckMFA(e)}
                                            defaultChecked={this.state.enableMFATypeSaved === 2}
                                        />
                                        <label htmlFor="textMessageMFAModal">
                                            Text Message<br/>
                                            <span className="small-small">Phone #: { this.state.user ? this.state.user.Phone: "Edit your profile to set a phone #" }</span>
                                        </label>
                                    </div>

                                    <div className="icheck-success">
                                    <input
                                        name="enableMFAType"
                                        type="radio"
                                        id="emailMFAModal"
                                        disabled={!this.state.enableMFA}
                                        value="0"
                                        onChange={(e)=> this.handleCheckMFA(e)}
                                        defaultChecked={this.state.enableMFATypeSaved === 0}
                                    />
                                    <label htmlFor="emailMFAModal">
                                        Email<br/>
                                        <span className="small-small">Email Address: { this.state.user ? this.state.user.Email: "-" }</span>
                                    </label>
                                    </div>

                                    <div className="icheck-success">
                                    <input
                                        name="enableMFAType"
                                        type="radio"
                                        id="googleMFAModal"
                                        disabled={!this.state.enableMFA}
                                        value="1"
                                        onChange={(e)=> this.handleCheckMFA(e)}
                                        defaultChecked={this.state.enableMFATypeSaved === 1}
                                    />
                                    <label htmlFor="googleMFAModal">
                                        Google Authenticator
                                    </label>
                                    </div>

                                </div>
                                <div className="col-sm-5 text-center">
                                    { this.state.enableMFAQRCodeAuth &&
                                    <>
                                        <img alt="" style={{width: "100%"}} src={this.state.enableMFAQRCodeAuth} />
                                       
                                    </>
                                    }
                                </div>

                                {this.state.enableMFAQRCodeManual !== '' &&
                                    <div className="col-sm-12" style={{wordWrap: "break-word"}}><p className="small-small">{this.state.enableMFAQRCodeManual}</p></div>
                                }
                            </div>

                   
                        </div>
                        <div className="row">
                            {
                            this.state.enableMFAQRCodeAuth &&
                                <div className="col">
                                <button className="btn full-w btn-light" type="button" onClick={() => this.closeMFAModal() }>Close</button>
                            </div>
                            }
                            {
                            !this.state.enableMFAQRCodeAuth &&
                            <>
                                <div className="col">
                                    <button className="btn full-w btn-light" type="button" onClick={() => this.closeMFAModal() }>Cancel</button>
                                </div>
                                <div className="col">
                                <button className="btn full-w btn-primary" type="button" onClick={() => this.saveMFA()}>Save</button>   
                                </div>
                            </>
                            }   
                        </div>  
                </Modal.Body>
                </Modal>


                <div className="mt-body4">
                <div>
                <TopBar>
                    <MainTopBarMenu />
                    <div className="top-bar-sub">
                        <SettingsLinks selected={parseInt(userId) === parseInt(this.state.profileId) ? "profile" : "manageusers"} goback={parseInt(userId) !== parseInt(this.state.profileId) ? true : null} gobackLink={parseInt(userId) !== parseInt(this.state.profileId) ? ("/" + this.props.global.getURLEntry() + "/report/users") : null} gobackText="List of Users" />
                    </div>
                </TopBar>
                    <div className="row mb-4">
                        <div className="col-12">
                            <h5 className="fl-capitalize" data-qaid="profilePage">Profile - {this.state.user ? this.state.user.Name:''}</h5>
                            <p className="small-grey">View user information and make edits to access and permissions</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <h6 className="sub-header-line mb-1  mb-4">
                                User Information   
                                <a href="/" className="float-end" style={{fontSize: "18px"}} onClick={(e) => this.openUserInfoModal(e)}><BiEdit/></a>
                            </h6>
                            
                            <div className="row small mb-3 dark-grey">
                                <div className="col-4"><b>Profile</b></div>
                                <div className="col-8 fl-capitalize">
                                    <button className="profile-image bg-transparent" style={{width: '150px', height: '140px'}} onClick={()=>this.selectFileLogo()}>
                                        { this.state.avatar && typeof this.state.avatar !== 'object' ?
                                            <img
                                                alt=""
                                                accept="image/png, image/jpeg"
                                                width={100}
                                                height={100}
                                                src={`${this.state.avatar}?timestamp=${new Date().getTime()}`} 
                                            />
                                            : <BiImageAdd className='text-muted' />
                                        }
                                        <input className="hide" ref={this.inputFileLogo} type="file" maxLength="1048576" onChange={(e) => this.handleFileRead(e)} />
                                    </button>
                                </div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-4"><b>Full Name</b></div>
                                <div className="col-8 fl-capitalize">{ this.state.user ? this.state.user.Name: "-" }</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-4"><b>Phone</b></div>
                                <div className="col-8">{ this.state.user ? this.state.user.Phone: "-" }</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-4"><b>Email</b></div>
                                <div className="col-8">{ this.state.user ? this.state.user.Email: "-" }</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-4"><b>Language</b></div>
                                <div className="col-8">{ this.state.user ? "English": "-" }</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-4"><b>Time Zone</b></div>
                                <div className="col-8">{ this.state.user ? this.props.global.getTimeZone('v2',  this.state.timeZoneSaved) : "-" }</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-4"><b>Status</b></div>
                                <div className="col-8">{ this.state.user ? this.getStatus(this.state.user.UsrStatus): "-" }</div>
                            </div>
                            <div className="row small mb-5 dark-grey">
                                <div className="col-4"><b>Date Created</b></div>
                                <div className="col-8">{ this.state.user ? this.props.global.stringDateFormat(this.state.user.CreatedAt)+ ", "+this.props.global.stringTimeFormat(this.state.user.CreatedAt) + " ("+this.props.global.getTimeZone('v1')+")" : "-" }</div>
                            </div>

                            <h6 className="sub-header-line mb-1  mb-4">
                                Login Information   
                                <a href="/" className="float-end" style={{fontSize: "18px"}} onClick={(e) => this.openUsernamePswModal(e)}><BiEdit/></a>
                            </h6>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-4"><b>Username</b></div>
                                <div className="col-8">{ this.state.user ? this.state.user.Email: "-" }</div>
                            </div>
                            <div className="row small mb-5 dark-grey">
                                <div className="col-4"><b>Password</b></div>
                                <div className="col-8">{ this.state.user ? "*****************": "-" }</div>
                            </div>

                            <h6 className="sub-header-line mb-1  mb-4">
                                Multi-factor Authentication 
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Enhance the security on your account by requiring us to send you a one time use security code prior to logging in.</Tooltip>}
                                >
                                <BiInfoCircle className="ml-1 cursor-pointer" style={{fontSize: "20px"}}/>
                                </OverlayTrigger>

                                <a href="/" className="float-end" style={{fontSize: "18px"}} onClick={(e) => this.openMFAModal(e)}><BiEdit/></a>
                            </h6>
                            <div className="row small mb-5 dark-grey">
                                <div className="col-4"><b>Status</b></div>
                                <div className="col-8">
                                    {this.state.enableMFASaved === true ?
                                    <span className="badge bg-primary mb-2"><BiCheckCircle /> Enabled</span>
                                    :
                                    <span className="badge bg-light mb-2"><BiMinusCircle /> Disabled</span>
                                    }
                                   <div className="icheck-success">
                                        <input
                                            type="radio"
                                            checked={this.state.enableMFATypeSaved === 2}
                                            onChange={()=>function(){}}
                                        />
                                        <label>
                                            Text Message
                                        </label>
                                    </div>
                                   
             
                                    <div className="icheck-success">
                                        <input
                                            type="radio"
                                            checked={this.state.enableMFATypeSaved === 0}
                                            onChange={()=>function(){}}
                                        />
                                        <label>
                                            Email
                                        </label>
                                    </div>
     
                                    <div className="icheck-success">
                                        <input
                                            type="radio"
                                            checked={this.state.enableMFATypeSaved === 1}
                                            onChange={()=>function(){}}
                                        />
                                        <label>
                                            Google Authenticator
                                        </label>
                                    </div>

                                   
                                    
                                </div>
                            </div>

 

                            <h6 className="sub-header-line mb-1  mb-4">
                                Access Enabled   
                            </h6>
                           
                            <div className="row small mb-3 dark-grey">
                                <div className="col-12">
                                    <div className="mb-2"><b>Organizations</b></div>
                                    <div className="filter-badges">
                                        <span className="badge bg-primary"><BiBriefcase style={{marginRight: '5px', fontSize: '14px'}} />{this.legalName()}</span>
                                    </div>                                    
                                </div>
                            </div>


                        </div>
                        <div className="col-md-6 offset-md-1 dark-grey small mb-4">
                            <h6 className="sub-header-line mb-1  mb-4">
                                Permissions Enabled
                                {parseInt(userId) !== parseInt(this.state.profileId) &&
                                <a href="/" className="float-end" style={{fontSize: "18px"}} onClick={(e) => this.openPermissionsModal(e)}><BiEdit/></a>
                                }
                            </h6>
                            <div className="row">

                            {
                                this.state.permissionsEnabled.map((record, i) => (
                                
                                    userPermissions.indexOf(record.permission) !== -1 &&
                                    <div key={i} className="col-lg-4 col-sm-6 col-6 mb-3">
                                        <div className="mb-3">
                                        <div className="icheck-success">
                                            <input readOnly type="checkbox" checked={ record.checked ? true : false }/>
                                            <label><b className="small">{record.permission}</b></label>
                                        </div>
                                        </div>

                                        {
                                            record.childs &&
                                            record.childs.map((child1, e )=> (

                                                userPermissions.indexOf(child1.permission) !== -1 &&
                                                <div key={i+"second"+e}>
                                                    <div className="mb-3">
                                                        <div className="icheck-success">
                                                            <input readOnly type="checkbox" checked={ child1.checked ? true : false }/>
                                                            <label className="small" >{child1.permission}</label>
                                                        </div>
                                                    </div>

                                                    {
                                                        child1.childs &&
                                                        child1.childs.map((child2, f )=> (

                                                            userPermissions.indexOf(child2.permission) !== -1 &&
                                                            <div key={e+"third"+f} className="mb-3">
                                                            <div className="icheck-success">
                                                                <input readOnly type="checkbox" checked={ child2.checked ? true : false } />
                                                                <label className="small">{child2.permission}</label>
                                                            </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }

                                    </div>
                                  
                                ))
                            }

                            </div>

                        </div>
                    </div>

                </div>
                </div>

                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { Profile };