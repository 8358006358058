import React from 'react'
import { BiX } from '@react-icons/all-files/bi/BiX'
import { PayabliStorageManager } from '../../../../api/localStorageManager'
import { Modal, Button } from 'react-bootstrap'

export default function ModalClose({ showModal, closeModal }) {
	return (
		<Modal
			style={{ textAlign: 'center' }}
			size="sm"
			show={showModal}
			onHide={() => closeModal()}
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Body style={{ padding: '60px 40px' }}>
				<BiX
					className="icon-modal"
					style={{ cursor: 'pointer', position: 'absolute', top: '15px', right: '15px', fontSize: '35px' }}
					onClick={() => closeModal()}
				/>
				<h5>Abandon Template</h5>
				<p className="small">
					The changes in this template were not saved.
					<br />
					Are you sure you want to abandon this template?
				</p>
				<a href={'/' + PayabliStorageManager.getEntryName() + '/boarding/templates'}>
					<Button
						className="btn"
						variant="danger">
						Abandon Template
					</Button>
				</a>
			</Modal.Body>
		</Modal>
	)
}
