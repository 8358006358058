import React from 'react'
import { inject, observer } from 'mobx-react'
import { Layout } from '../../components/Layout'
import PayPoint from './PayPoint'
import ManagementPaypoint from '../../store/ManagementPaypointStorage/ManagementPaypoint'

@inject('global')
@observer
class CommandCenter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		this.props.global.setLoading(true)
	}

	render() {
		return (
			<Layout {...this.props}>
				<ManagementPaypoint
					setLoading={(state) => this.props.global.setLoading(state)}
					isLoading={this.props.global.isLoading}>
					<PayPoint isLoading={this.props.global.isLoading} />
				</ManagementPaypoint>
			</Layout>
		)
	}
}

export { CommandCenter }
