import React from 'react';
import { inject, observer } from 'mobx-react';
import {Link} from "react-router-dom";
import { PayabliStorageManager } from '../api/localStorageManager'
import {BiGroup} from "@react-icons/all-files/bi/BiGroup";
import { BiShoppingBag, BiWallet } from 'react-icons/bi';

@inject('reports','global')
@observer
class ActionsLinks extends React.Component {

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        
        return (
            <div className="row">
            
            <div className="col-12 text-center">
                <div className="hide-sm">
                    {/*<Link className={this.props.selected === 'vterminal'? 'selected' : ''} to={"/vterminal"}><BiDollarCircle/> Virtual Terminal</Link>*/}
                    {/*<Link className={this.props.selected === 'paymentPages'? 'selected' : ''} to={"/paymentpages"}><BiIdCard/> Payment Pages</Link>*/}
                    {userPermissions.indexOf("Customers") !== -1 &&
                    <Link className={this.props.selected === 'customers'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +"/report/customers"} data-qaid="topBarMenuCustomersLink"><BiGroup/> Customers</Link>
                    }
                    {userPermissions.indexOf("Invoices") !== -1 &&
                    <Link className={this.props.selected === 'invoices'? 'selected' : ''} to={"/"+ this.props.global.getURLEntry() +"/report/invoices"} data-qaid="topBarMenuInvoicesLink"><BiWallet/> Invoices</Link>
                    }
                    {userPermissions.indexOf('Virtual Terminal') !== -1 && (<Link className={this.props.selected === 'catalog' ? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +'/report/catalog'} data-qaid="topBarMenuCatalogLink"><BiShoppingBag /> Catalog</Link>)}
                    {/*<Link className={this.props.selected === 'bills'? 'selected' : ''} to={"/bills"}><BiListCheck/> Bills</Link>*/}
                </div>
                <div className="hide show-sm sm-screen-topbar-menu">
                    {/*<Link className={this.props.selected === 'vterminal'? 'selected' : ''} to={"/vterminal"}><BiDollarCircle/></Link>*/}
                    {/*<Link className={this.props.selected === 'paymentPages'? 'selected' : ''} to={"/paymentpages"}><BiIdCard/></Link>*/}
                    {userPermissions.indexOf("Customers") !== -1 &&
                    <Link className={this.props.selected === 'customers'? 'selected' : ''} to={"/"+ this.props.global.getURLEntry() +"/report/customers"}><BiGroup/></Link>
                    }
                    {userPermissions.indexOf("Invoices") !== -1 &&
                    <Link className={this.props.selected === 'invoices'? 'selected' : ''} to={"/"+ this.props.global.getURLEntry() +"/report/invoices"}><BiWallet/></Link>
                    }
                    {userPermissions.indexOf('Virtual Terminal') !== -1 && (<Link className={this.props.selected === 'catalog' ? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +'/report/catalog'}><BiShoppingBag /></Link>)}
                    {/*<Link className={this.props.selected === 'bills'? 'selected' : ''} to={"/bills"}><BiListCheck/> Bills</Link>*/}
                </div>
            </div>
           
            </div>
        )
    }
}

export { ActionsLinks };