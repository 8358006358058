export default function ServicesFunction({ service, banking }) {
	const structureService = (processor, dataService) => {
		const dataBank = dataService.BankData.map((data) => {
			return {
				bankName: data.bankName,
				routingAccount: data.routingAccount,
				accountNumber: data.accountNumber,
				typeAccount: data.typeAccount,
				bankAccountHolderName: data.bankAccountHolderName,
				bankAccountHolderType: data.bankAccountHolderType,
				bankAccountFunction: data.bankAccountFunction,
				id: data.idSelect
			}
		})

		const dataTiers = dataService.Tiers.map((data) => {
			return {
				mode: data.mode,
				lowPayRange: data.lowPayRange,
				highPayRange: data.highPayRange,
				payorPrice: {
					absorbDifference: data.absorbDifference,
					greaterValueAllowed: data.greaterValueAllowed,
					allowOverride: data.allowOverride,
					priceFix: data.priceFix,
					priceFloat: data.priceFloat,
					priceMinimum: data.priceMinimum,
					priceMaximum: data.priceMaximum
				}
			}
		})

		if (processor === 'gp') {
			return {
				Services: [
					{
						ServiceName: dataService.ServiceName,
						ServiceGroup: dataService.ServiceGroup,
						ServiceProvider: 0,
						Enabled: true,
						Gateways: [
							{
								GatewayName: dataService.gateway,
								AccountId: dataService.accountId,
								FundingRollUp: 0,
								FundingHoldingDays: 1,
								FundingMode: 0,
								FundingBatchPreference: 1,
								Connector: {
									Name: dataService.processor,
									Mode: 2,
									configuration: {
										credentials: {
											merchantId: dataService.merchantId,
											tokenizationAccountName: dataService.tokenizationAccountName,
											tokenizationAccountId: dataService.tokenizationAccountId,
											transactionAccountName: dataService.transactionAccountName,
											transactionAccountId: dataService.transactionAccountId,
											fundingAccountName: dataService.fundingAccountName,
											fundingAccountId: dataService.fundingAccountId
										},
										enableBackConnectorReporting: false,
										backConnector: null
									}
								},
								Tiers: dataTiers,
								Currencies: ['USD'],
								BankData: dataBank,
								AddOnServices: {
									Tokenization: true,
									SplitPay: true,
									Level2andLevel3: true,
									AutomaticCardUpdater: false
								}
							}
						]
					}
				]
			}
		} else if (processor === 'nmi') {
			return {
				Services: [
					{
						ServiceName: dataService.ServiceName,
						ServiceGroup: dataService.ServiceGroup,
						ServiceProvider: 0,
						Enabled: true,
						Gateways: [
							{
								GatewayName: dataService.gateway,
								AccountId: dataService.accountId,
								FundingRollUp: 0,
								FundingHoldingDays: 1,
								FundingMode: 0,
								FundingBatchPreference: 0,
								Connector: {
									Name: dataService.processor,
									Mode: 2,
									configuration: {
										credentials: {
											apiKey: dataService.apiKey,
											gatewayId: dataService.gatewayId,
											serviceFeeKey: dataService.serviceFeeKey,
											webhookKey: dataService.webhookKey
										},
										enableBackConnectorReporting: false,
										backConnector: null
									}
								},
								Tiers: dataTiers,
								Currencies: ['USD'],
								BankData: dataBank,
								AddOnServices: {
									Tokenization: true,
									SplitPay: true,
									Level2andLevel3: true,
									AutomaticCardUpdater: false
								}
							}
						]
					}
				]
			}
		} else if (processor === 'checkcommerce') {
			return {
				Services: [
					{
						ServiceName: dataService.ServiceName,
						ServiceGroup: dataService.ServiceGroup,
						ServiceProvider: 0,
						Enabled: true,
						Gateways: [
							{
								GatewayName: dataService.gateway,
								AccountId: dataService.accountId,
								FundingRollUp: 0,
								FundingHoldingDays: 1,
								FundingMode: 0,
								FundingBatchPreference: 0,
								Connector: {
									Name: dataService.processor,
									Mode: 2,
									configuration: {
										credentials: {
											username: dataService.username,
											password: dataService.password,
											merchantNumber: dataService.merchantNumber,
											serviceFeeUsername: '',
											serviceFeePassword: ''
										},
										enableBackConnectorReporting: false,
										backConnector: null
									}
								},
								Tiers: dataTiers,
								Currencies: ['USD'],
								BankData: dataBank,
								AddOnServices: {
									Tokenization: true,
									SplitPay: false,
									Level2andLevel3: false,
									AutomaticCardUpdater: false
								}
							}
						]
					}
				]
			}
		} else if (processor === 'dummy') {
			return {
				Services: [
					{
						ServiceName: dataService.ServiceName,
						ServiceGroup: dataService.ServiceGroup,
						ServiceProvider: 0,
						Enabled: true,
						Gateways: [
							{
								GatewayName: dataService.gateway,
								AccountId: dataService.accountId,
								FundingRollUp: 0,
								FundingHoldingDays: 1,
								FundingMode: 0,
								FundingBatchPreference: 0,
								Connector: {
									Name: dataService.processor,
									Mode: 2,
									configuration: {
										credentials: null,
										enableBackConnectorReporting: false,
										backConnector: null
									}
								},
								Tiers: dataTiers,
								Currencies: ['USD'],
								BankData: null,
								AddOnServices: {
									Tokenization: false,
									SplitPay: true,
									Level2andLevel3: true,
									AutomaticCardUpdater: false
								}
							}
						]
					}
				]
			}
		} else if (processor === 'repay') {
			return {
				Services: [
					{
						ServiceName: dataService.ServiceName,
						ServiceGroup: dataService.ServiceGroup,
						ServiceProvider: 0,
						Enabled: true,
						Gateways: [
							{
								GatewayName: dataService.gateway,
								AccountId: dataService.accountId,
								FundingRollUp: 0,
								FundingHoldingDays: 1,
								FundingMode: 0,
								FundingBatchPreference: 0,
								Connector: {
									Name: dataService.processor,
									Mode: 2,
									configuration: {
										credentials: {
											clientId: dataService.clientId,
											clientSecretKey: dataService.clientSecretKey,
											customerId: dataService.customerId,
											accountId: dataService.accountIdCredentials
										},
										enableBackConnectorReporting: false,
										backConnector: null
									}
								},
								Tiers: dataTiers,
								Currencies: ['USD'],
								BankData: dataBank,
								AddOnServices: {
									Tokenization: true,
									SplitPay: true,
									Level2andLevel3: true,
									AutomaticCardUpdater: false
								}
							}
						]
					}
				]
			}
		}
	}
	const selectGateway = (gateway) => {
		if (gateway.toUpperCase().includes('LEGACY')) {
			return 'legacy'
		} else if (gateway.toUpperCase().includes('PAYABLI')) {
			return 'payabli'
		}
		return ''
	}
	const selectProcess = (select, serviceType, moneyType, indexService, indexGateways, dataFields, indexTier, state = '') => {
		let newTier = [],
			updateBank = [],
			validationTier = {},
			newConnector = '',
			newDataGateway = []

		service[moneyType][serviceType][indexService].Gateways[indexGateways].Tiers.forEach((data) => {
			validationTier = {
				highPayRange: !data.highPayRange ? 0 : parseFloat(data.highPayRange),
				lowPayRange: !data.lowPayRange ? 0 : parseFloat(data.lowPayRange),
				mode: data.mode,
				payorPrice: {
					absorbDifference: data.payorPrice === null ? false : data.payorPrice.absorbDifference,
					greaterValueAllowed: data.payorPrice === null ? false : data.payorPrice.greaterValueAllowed,
					allowOverride: data.payorPrice === null ? false : data.payorPrice.allowOverride,
					priceFloat: data.payorPrice === null ? 0 : !data.payorPrice.priceFloat ? 0 : parseFloat(data.payorPrice.priceFloat),
					priceFix: data.payorPrice === null ? 0 : !data.payorPrice.priceFix ? 0 : parseFloat(data.payorPrice.priceFix),
					priceMinimum: data.payorPrice === null ? 0 : !data.payorPrice.priceMinimum ? 0 : parseFloat(data.payorPrice.priceMinimum),
					priceMaximum: data.payorPrice === null ? 0 : !data.payorPrice.priceMaximum ? 0 : parseFloat(data.payorPrice.priceMaximum)
				}
			}
			newTier.push(validationTier)
		})

		if (select === 'addTiers') {
			const dataTier = {
				highPayRange: !dataFields.highPayRange ? 0 : parseFloat(dataFields.highPayRange),
				lowPayRange: !dataFields.lowPayRange ? 0 : parseFloat(dataFields.lowPayRange),
				mode: dataFields.mode,
				payorPrice: {
					absorbDifference: dataFields.absorbDifference === null ? false : dataFields.absorbDifference,
					greaterValueAllowed: dataFields.greaterValueAllowed === null ? false : dataFields.greaterValueAllowed,
					allowOverride: dataFields.allowOverride === null ? false : dataFields.allowOverride,
					priceFloat: !dataFields.priceFloat ? 0 : parseFloat(dataFields.priceFloat),
					priceFix: !dataFields.priceFix ? 0 : parseFloat(dataFields.priceFix),
					priceMinimum: !dataFields.priceMinimum ? 0 : parseFloat(dataFields.priceMinimum),
					priceMaximum: !dataFields.priceMaximum ? 0 : parseFloat(dataFields.priceMaximum)
				}
			}
			newTier.push(dataTier)
		} else if (select === 'deleteTier') {
			newTier = newTier.filter((data, index) => index !== indexTier)
		} else if (select === 'updateServiceName') {
			if (service[moneyType][serviceType][indexService].Gateways[indexGateways].BankData !== null) {
				service[moneyType][serviceType][indexService].Gateways[indexGateways].BankData.forEach((data) => {
					const dataBank = banking.bankData.find(({ id }) => id === data.id)
					updateBank.push({
						accountNumber: dataBank.accountNumber,
						bankAccountFunction: data.bankAccountFunction,
						bankAccountHolderName: dataBank.bankAccountHolderName,
						bankAccountHolderType: dataBank.bankAccountHolderType,
						bankName: dataBank.bankName,
						id: dataBank.id,
						routingAccount: dataBank.routingAccount,
						typeAccount: dataBank.typeAccount
					})
				})
			}
		} else if (select === 'updateStateGateway') {
			const newGatewayState = !service[moneyType][serviceType][indexService].Gateways[indexGateways].Connector.Enabled
			newConnector = {
				Name: service[moneyType][serviceType][indexService].Gateways[indexGateways].Connector.Name,
				Descriptor: service[moneyType][serviceType][indexService].Gateways[indexGateways].Connector.Descriptor,
				Mode: service[moneyType][serviceType][indexService].Gateways[indexGateways].Connector.Mode,
				gatewayID: service[moneyType][serviceType][indexService].Gateways[indexGateways].Connector.gatewayID,
				Enabled: newGatewayState,
				TestMode: service[moneyType][serviceType][indexService].Gateways[indexGateways].Connector.TestMode,
				configuration: service[moneyType][serviceType][indexService].Gateways[indexGateways].Connector.configuration
			}

			let stateGateway = false
			service[moneyType][serviceType][indexService].Gateways.forEach((gateways, index) => {
				if (index === indexGateways) {
					if (newGatewayState) stateGateway = true
				} else {
					if (gateways.Connector.Enabled) stateGateway = true
				}
			})
			state = stateGateway
		} else if (select === 'updateService' && state !== '') {
			newDataGateway = service[moneyType][serviceType][indexService].Gateways.map((gateways, index) => {
				return {
					...gateways,
					GatewayName: selectGateway(service[moneyType][serviceType][indexService].Gateways[index].GatewayName),
					Connector: { ...gateways.Connector, Enabled: state }
				}
			})
		}
		return {
			Services: [
				{
					ServiceName: service[moneyType][serviceType][indexService].ServiceName,
					ServiceGroup: service[moneyType][serviceType][indexService].ServiceGroup,
					ServiceProvider: 0, //service[moneyType][serviceType][indexService].ServiceProviderName,
					Enabled: state !== '' ? state : service[moneyType][serviceType][indexService].ServiceEnabled,
					Gateways:
						newDataGateway.length === 0
							? [
									{
										GatewayName: selectGateway(service[moneyType][serviceType][indexService].Gateways[indexGateways].GatewayName),
										AccountId: service[moneyType][serviceType][indexService].Gateways[indexGateways].AccountId,
										FundingRollUp: service[moneyType][serviceType][indexService].Gateways[indexGateways].FundingRollUp,
										FundingHoldingDays: service[moneyType][serviceType][indexService].Gateways[indexGateways].FundingHoldingDays === null ? 1 : service[moneyType][serviceType][indexService].Gateways[indexGateways].FundingHoldingDays,
										FundingMode: service[moneyType][serviceType][indexService].Gateways[indexGateways].FundingMode,
										FundingBatchPreference: service[moneyType][serviceType][indexService].Gateways[indexGateways].FundingBatchPreference,
										Connector: newConnector !== '' ? newConnector : service[moneyType][serviceType][indexService].Gateways[indexGateways].Connector,
										Tiers: newTier,
										Currencies: service[moneyType][serviceType][indexService].Gateways[indexGateways].Currencies,
										BankData: updateBank.length > 0 ? updateBank : service[moneyType][serviceType][indexService].Gateways[indexGateways].BankData,
										Id: service[moneyType][serviceType][indexService].Gateways[indexGateways].Id
									}
							  ]
							: newDataGateway
				}
			]
		}
	}

	return {
		structureService,
		selectGateway,
		selectProcess
	}
}
