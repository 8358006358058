import React from 'react';
import { inject, observer } from 'mobx-react';

import { DropdownButton, Dropdown } from 'react-bootstrap';

@inject('store', 'global', 'reports')
@observer
class QuickFilters extends React.Component {
  componentDidMount() {}

  handleQuickFilter(value, overDueComparator, e) {
    const filters = {
      'status(in)': value,
      'dueDate(lt)': null,
      'dueDate(gt)': null,
    };

    if (overDueComparator) {
      const today =
        this.props.global.stringDateFormatFilters(new Date()) + 'T00:00:00';
      const dueDateFilter = `dueDate(${overDueComparator})`;
      filters[dueDateFilter] = today;
    }

    this.props.handleFilter(filters);
    this.props.setHead(e.target.id);
  }

  render() {
    const CustomHeadToggle = React.forwardRef(({ children, onClick }, ref) => (
      <>{children}</>
    ));

    return (
      <div className="col-5 head-filter">
        <div id="dropdown-invoices" className="mr-3">
          <DropdownButton
            as={CustomHeadToggle}
            menuAlign="left"
            title={
              <h6 data-qaid="allInvoicesMoneyInReportsPage">
                {this.props.head
                  ? this.props.global.capitalizeFirstLetter(this.props.head)
                  : 'All Invoices'}
              </h6>
            }
            size="sm"
            variant=""
          >
            <Dropdown.Item
              id="Drafts"
              onClick={(e) => this.handleQuickFilter('0', null, e)}
            >
              Drafts
            </Dropdown.Item>
            <Dropdown.Item
              id="Open"
              onClick={(e) => this.handleQuickFilter('1', 'gt', e)}
            >
              Open
            </Dropdown.Item>
            <Dropdown.Item
              id="Past Due"
              onClick={(e) => this.handleQuickFilter('1|2', 'lt', e)}
            >
              Past Due
            </Dropdown.Item>
            <Dropdown.Item
              id="PartiallyPaid"
              onClick={(e) => this.handleQuickFilter('2', 'gt', e)}
            >
              Partially Paid
            </Dropdown.Item>
            <Dropdown.Item
              id="Paid"
              onClick={(e) => this.handleQuickFilter('4', null, e)}
            >
              Paid
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => this.handleQuickFilter('', null, e)}>
              All Invoices
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="small-small inline-block">
          Detailed overview of all your customer invoices
        </div>
      </div>
    );
  }
}

export { QuickFilters };
