import React from 'react'
import RowContainer from '../../RowContainer'
import GlobalStore from '../../../../store/GlobalStore'

export default function Nmi({ inputDisabled, service, serviceName, indexService, indexGateways, handleChange, setClassValidate, classValidate }) {
	return (
		<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
			<RowContainer
				inputType="mask"
				classNameInput={classValidate.nmi.apiKey}
				inputDisabled={inputDisabled}
				labelValue="API Key"
				name={'APIKey[' + service.Gateways[indexGateways].Id + ']'}
				placeholder="Value"
				value={service.Gateways[indexGateways].Connector.configuration.credentials.apiKey}
				onBlur={() => setClassValidate({ ...classValidate, nmi: { ...classValidate.nmi, apiKey: service.Gateways[indexGateways].Connector.configuration.credentials.apiKey ? '' : 'is-invalid' } })}
				setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'apiKey', values, false, false, 0, false, '', 0, false, true)}
				mask={GlobalStore.maskValidator('text')}
				maxLength={50}
				oneRow={true}
			/>

			<RowContainer
				inputType="mask"
				classNameInput={classValidate.nmi.gatewayId}
				inputDisabled={inputDisabled}
				labelValue="Gateway ID"
				name={'gatewayID[' + service.Gateways[indexGateways].Id + ']'}
				placeholder="Value"
				value={service.Gateways[indexGateways].Connector.configuration.credentials.gatewayId}
				onBlur={() => setClassValidate({ ...classValidate, nmi: { ...classValidate.nmi, gatewayId: service.Gateways[indexGateways].Connector.configuration.credentials.gatewayId ? '' : 'is-invalid' } })}
				setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'gatewayId', values, false, false, 0, false, '', 0, false, true)}
				mask={GlobalStore.maskValidator('text')}
				maxLength={50}
				oneRow={true}
			/>

			<RowContainer
				inputType="mask"
				inputDisabled={inputDisabled}
				labelValue="Service Fee Key (Opt)"
				name={'serviceFeeKey[' + service.Gateways[indexGateways].Id + ']'}
				placeholder="Value"
				value={service.Gateways[indexGateways].Connector.configuration.credentials.serviceFeeKey}
				setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'serviceFeeKey', values, false, false, 0, false, '', 0, false, true)}
				mask={GlobalStore.maskValidator('text')}
				maxLength={50}
				oneRow={true}
				TooltipCaption={
					<>
						This credential is <strong>optional</strong> as it is used to associate a second service fee credential to enable dual authentication for service fee payments through Legacy gateway.
					</>
				}
			/>
			<RowContainer
				inputType="mask"
				inputDisabled={inputDisabled}
				labelValue="Webhook ID (Opt)"
				name={'webhookKey[' + service.Gateways[indexGateways].Id + ']'}
				placeholder="Value"
				value={service.Gateways[indexGateways].Connector.configuration.credentials.webhookKey}
				setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'webhookKey', values, false, false, 0, false, '', 0, false, true)}
				mask={GlobalStore.maskValidator('text')}
				maxLength={50}
				oneRow={true}
				// TooltipCaption={
				// 	<>
				// 		This credential is <strong>optional</strong> as it is used to associate a second service fee credential to enable dual authentication for service fee payments through Legacy gateway.
				// 	</>
				// }
			/>
		</div>
	)
}
