import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import { inject, observer } from 'mobx-react';
import {  OverlayTrigger, Tooltip } from "react-bootstrap";
import {BiInfoCircle} from '@react-icons/all-files/bi/BiInfoCircle';
import {IMaskInput} from 'react-imask';

@inject('global', 'boarding')
@observer
class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        address: '',
        address2: '',
        isNewScreen: true,
        city: '',
        state: '',
        zipcode: '',
        country: '',
        oindex: 0
     };
     this.handleTextChange = this.handleTextChange.bind(this);
     this.handleChangeAddress = this.handleChangeAddress.bind(this);
     this.handleTextChangeMaskedInput = this.handleTextChangeMaskedInput.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    let validators = this.props.global.validators;
    let values = { oindex: this.props.oindex || 0}
    if (this.props.address !== prevProps.address){
      this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[0], this.props.address);
      values["address"] = this.props.address
      this.setState(values, function(){
        this.props.boarding.setErrorData(this.props.handleTextChangeKeys[0], this.props.iRequired?.includes('address') && validators.isEmpty(this.props.address) ? true : false);
      })
    }
    if (this.props.address2 !== prevProps.address2){
      this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[1], this.props.address2);
      values["address2"] = this.props.address2
      this.setState(values, function(){
        this.props.boarding.setErrorData(this.props.handleTextChangeKeys[1], this.props.iRequired?.includes('address2') && validators.isEmpty(this.props.address2) ? true : false);
      })
    }
    if (this.props.city !== prevProps.city){
      this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[2], this.props.city);
      values["city"] = this.props.city
      this.setState(values, function(){
        this.props.boarding.setErrorData(this.props.handleTextChangeKeys[2], this.props.iRequired?.includes('city') && validators.isEmpty(this.props.city) ? true : false);
      })
    }
    if (this.props.state !== prevProps.state){
      this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[3], this.props.state);
      values["state"] = this.props.state
      this.setState(values, function(){
        this.props.boarding.setErrorData(this.props.handleTextChangeKeys[3], this.props.iRequired?.includes('state') && validators.isEmpty(this.props.state) ? true : false);
      })
    }
    if (this.props.zipcode !== prevProps.zipcode){
      this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[4], this.props.zipcode);
      values["zipcode"] = this.props.zipcode
      this.setState(values, function(){
        this.props.boarding.setErrorData(this.props.handleTextChangeKeys[4], this.props.iRequired?.includes('zipcode') && validators.isEmpty(this.props.zipcode) ? true : false);
      })
    }
    if (this.props.country !== prevProps.country){
      this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[5], this.props.country);
      values["country"] = this.props.country
      this.setState(values, function(){
        this.props.boarding.setErrorData(this.props.handleTextChangeKeys[5], this.props.iRequired?.includes('country') && validators.isEmpty(this.props.country) ? true : false);
      })
    }
}      

  componentDidMount(){
    let validators = this.props.global.validators;
    this.setState({
        address: this.props.address,
        address2: this.props.address2,
        city: this.props.city,
        state: this.props.state,
        zipcode: this.props.zipcode,
        country: this.props.country,
        oindex: this.props.oindex,
    }, function(){
      this.props.boarding.setErrorData(this.props.handleTextChangeKeys[0], this.props.iRequired?.includes('address') && validators.isEmpty(this.props.address) ? true : false);
      this.props.boarding.setErrorData(this.props.handleTextChangeKeys[1], this.props.iRequired?.includes('address2') && validators.isEmpty(this.props.address2) ? true : false);
      this.props.boarding.setErrorData(this.props.handleTextChangeKeys[2], this.props.iRequired?.includes('city') && validators.isEmpty(this.props.city) ? true : false);
      this.props.boarding.setErrorData(this.props.handleTextChangeKeys[3], this.props.iRequired?.includes('state') && validators.isEmpty(this.props.state) ? true : false);
      this.props.boarding.setErrorData(this.props.handleTextChangeKeys[4], this.props.iRequired?.includes('zipcode') && validators.isEmpty(this.props.zipcode) ? true : false);
      this.props.boarding.setErrorData(this.props.handleTextChangeKeys[5], this.props.iRequired?.includes('country') && validators.isEmpty(this.props.country) ? true : false);
    });
    this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[0], this.props.address);
    this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[1], this.props.address2);
    this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[2], this.props.city);
    this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[3], this.props.state);
    this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[4], this.props.zipcode);
    this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[5], this.props.country);
  }

  handleTextChange(e, parentStateValue){
    let validators = this.props.global.validators;
    this.setState({isNewScreen: false, [e.target.id]: e.target.value,[e.target.getAttribute('aria-label')] : e.target.value, [e.target.ariaLabel]: e.target.value}, function(){
      this.props.boarding.setErrorData(e.target.id, this.props.iRequired?.includes(e.target.getAttribute('aria-label')) && validators.isEmpty( e.target.value) ? true : false);
    });
    this.props.boarding.setTemplateData([e.target.id], e.target.value);
    if(this.props.handleTextChangeKeys && this.props.handleTextChange){
      this.props.handleTextChange(parentStateValue,e.target.value );
    }
    if(this.props.iChangeCallback && this.props.iKey !== null && this.props.iKey !== undefined){
      this.props.iChangeCallback(this.props.iKey, e.target.value, e.target.id.split('_')[0]);
    }
  }

  handleTextChangeMaskedInput(id,value,parentStateValue){
    let validators = this.props.global.validators;
    this.setState({isNewScreen: false, [id]: value, error: id }, function(){
      this.props.boarding.setErrorData(parentStateValue, this.props.iRequired?.includes("zipcode") && validators.isEmpty( value) ? true : false);
    });
    if(this.props.handleTextChangeKeys && this.props.handleTextChange){
      this.props.handleTextChange(parentStateValue,value );
    }
    if(this.props.iChangeCallback && this.props.iKey !== null && this.props.iKey !== undefined){
      this.props.iChangeCallback(this.props.iKey, value, parentStateValue.split('_')[0]);
    }
  }
 
  handleChangeAddress = address => {
    let validators = this.props.global.validators;
    this.setState({isNewScreen: false, address }, function(){
      this.props.boarding.setErrorData(this.props.handleTextChangeKeys[0], this.props.iRequired?.includes("address") && validators.isEmpty( address) ? true : false);
    });
    if(this.props.handleTextChangeKeys && this.props.handleTextChange){
      this.props.handleTextChange(this.props.handleTextChangeKeys[0],address );
      this.props.boarding.setTemplateData(this.props.handleTextChangeKeys[0], address);
    }
    if(this.props.iChangeCallback && this.props.iKey !== null && this.props.iKey !== undefined){
      this.props.iChangeCallback(this.props.iKey, address, "oaddress");
    }
  };
 
  handleSelect = address => {
    let validators = this.props.global.validators;
    if(address){
      geocodeByAddress(address)
      .then(results => {
          if(results[0] && results[0].address_components){
              let address_components = results[0].address_components;
              let address2 = '';
              let city = '';
              let state = '';
              let zipcode = '';
              let country = '';

              address_components.forEach(function (item) {
                if(item.types.includes("locality") || item.types.includes("sublocality")){
                  city = item.short_name;
                }
                if(item.types[0] === "administrative_area_level_1"){
                  state = item.short_name;
                }
                if(item.types[0] === "administrative_area_level_2"){
                  //address2 = item.short_name;
                }
                if(item.types[0] === "postal_code"){
                  zipcode = item.short_name;
                }
                if(item.types[0] === "country"){
                  country = item.short_name;
                }
                
              });
              const name_address = results[0].address_components[0].long_name + " " + results[0].address_components[1].long_name
              this.setState({
                address: name_address,
                city: city,
                address2: address2,
                state: state,
                zipcode: zipcode,
                country: country,
                oindex: this.props.oindex || 0,
                isNewScreen: false
              }, function(){
                this.props.boarding.setErrorData(this.props.handleTextChangeKeys[0], this.props.iRequired?.includes('address') && validators.isEmpty(name_address) ? true : false);
                this.props.boarding.setErrorData(this.props.handleTextChangeKeys[1], this.props.iRequired?.includes('address2') && validators.isEmpty(city) ? true : false);
                this.props.boarding.setErrorData(this.props.handleTextChangeKeys[2], this.props.iRequired?.includes('city') && validators.isEmpty(address2) ? true : false);
                this.props.boarding.setErrorData(this.props.handleTextChangeKeys[3], this.props.iRequired?.includes('state') && validators.isEmpty(state) ? true : false);
                this.props.boarding.setErrorData(this.props.handleTextChangeKeys[4], this.props.iRequired?.includes('zipcode') && validators.isEmpty(zipcode) ? true : false);
                this.props.boarding.setErrorData(this.props.handleTextChangeKeys[5], this.props.iRequired?.includes('country') && validators.isEmpty(country) ? true : false);
              });
              
              if(this.props.onChange){
                  this.props.onChange(name_address, address2, city, state, zipcode, country, this.props.oindex);
              }

              if(this.props.iChangeCallback && this.props.iKey !== null && this.props.iKey !== undefined && this.props.handleTextChangeKeysBaseData){
                this.props.iChangeCallback(this.props.iKey, name_address, this.props.handleTextChangeKeysBaseData[0]);
                this.props.iChangeCallback(this.props.iKey, city, this.props.handleTextChangeKeysBaseData[2]);
                this.props.iChangeCallback(this.props.iKey, state, this.props.handleTextChangeKeysBaseData[3]);
                this.props.iChangeCallback(this.props.iKey, zipcode, this.props.handleTextChangeKeysBaseData[4]);
                this.props.iChangeCallback(this.props.iKey, country, this.props.handleTextChangeKeysBaseData[5]);
              }
          }

      })
      .catch(error => console.error('Error', error));
    }
  };

  getAddressInfo(){
    let validators = this.props.global.validators;
    let nextClicked = this.props.nextClicked ? this.props.nextClicked : this.props.boarding.nextClicked;
    return (
      <>  
        {!this.props.iInvisible?.includes('address2') &&
            <div className={this.props.customSize ? this.props.customSize : "col-sm-12 mb-3"}>
                <div className="form-floating">
                {(!this.props.iReadonly?.includes('address2') &&
                  <>
                      <input maxLength={200} name={this.props.handleTextChangeKeys[1]} autoComplete="off" id={this.props.handleTextChangeKeys[1]} onChange={(e) => this.handleTextChange(e, this.props.handleTextChangeKeys[1])} value={this.state.address2} className={(nextClicked && this.props.iRequired?.includes('address2') && validators.isEmpty(this.state.address2)) || (!this.state.isNewScreen && this.props.iRequired?.includes('address2') && validators.isEmpty(this.state.address2)) ? "form-control input-error" : "form-control"} placeholder="Billing Address 2" aria-label='address2'/>
                      <label htmlFor="address2">Address 2 (ex: apt, suite, building, etc) {(this.props.iRequired?.includes('address2')) && !this.props.notRequiredAsterisk ?  " *": ""}</label>
                  </>)
                  ||
                  (
                    <div className="mb-4">
                      <label><b>Address 2</b></label><br/>
                      {this.state.address2 ? this.state.address2 : "-"}
                    </div>
                  )
                }
                </div>
            </div>
        }
        {!this.props.iInvisible?.includes('city') &&
          <div className={this.props.customSize ? this.props.customSize : "col-sm-4 mb-3"}>
              <div className="form-floating">
              {(!this.props.iReadonly?.includes('city') &&
                  <>
                  <input maxLength={50} value={this.state.city} name={this.props.handleTextChangeKeys[2]} onChange={(e)=> this.handleTextChange(e, this.props.handleTextChangeKeys[2])} id={this.props.handleTextChangeKeys[2]} className={(nextClicked && this.props.iRequired?.includes('city') && validators.isEmpty(this.state.city)) || (!this.state.isNewScreen && this.props.iRequired?.includes('city') && validators.isEmpty(this.state.city)) ? "form-control input-error" : "form-control"} placeholder="City" aria-label="city" />
                  <label htmlFor="city">City {(this.props.iRequired?.includes('city')) && !this.props.notRequiredAsterisk ?  " *": ""}</label>
              </>)
              ||
              (
                  <div className="mb-4">
                      <label><b>City</b></label><br/>
                      {this.state.city ? this.state.city : "-"}
                    </div>
              )
              }
              </div>
          </div>
        }
        {this.props.withCountry && !this.props.iInvisible?.includes('country') &&
            <div className={this.props.customSize ? this.props.customSize :"col-sm-4 mb-3"}>
                <div className="form-floating mb-3">
                {(!this.props.iReadonly?.includes('country') &&
                  <>
                    <select name={this.props.handleTextChangeKeys[5]} value={this.state.country}  onChange={(e) => this.handleTextChange(e, this.props.handleTextChangeKeys[5])} className={(nextClicked && this.props.iRequired?.includes('country') && validators.isEmpty(this.state.country)) || (!this.state.isNewScreen && this.props.iRequired?.includes('country') && validators.isEmpty(this.state.country)) ? "form-select form-control input-error" : "form-select form-control"} id={this.props.handleTextChangeKeys[5]} aria-label="country">
                        <option value=""> Select... </option>
                        { this.props.global.getAllCountries().map((record, i) => (
                          <option key={i} value={record.value}>{record.text}</option>
                        ))
                      }
                    </select>
                    <label htmlFor="country">Country {(this.props.iRequired?.includes('country')) && !this.props.notRequiredAsterisk ?  " *": ""}</label>
                    </>
                ) ||
                (
                  <div className="mb-4">
                    <label><b>Country</b></label><br/>
                    {this.state.country ? this.props.global.getAllCountries().filter(rec => rec.value === this.state.country)[0].text : "-"}
                  </div>
                )}
                </div>
            </div>
        }
        {!this.props.iInvisible?.includes('state') &&  
        <div className={this.props.customSize ? this.props.customSize :"col-sm-4 mb-3"}>
            <div className="form-floating mb-3">
              {(!this.props.iReadonly?.includes('state') &&
                <>
                  <select maxLength={50} value={this.state.state} name={this.props.handleTextChangeKeys[3]}  onChange={(e) => this.handleTextChange(e, this.props.handleTextChangeKeys[3])} className={(nextClicked && this.props.iRequired?.includes('state') && validators.isEmpty(this.state.state)) || (!this.state.isNewScreen && this.props.iRequired?.includes('state') && validators.isEmpty(this.state.state)) ? "form-select form-control input-error" : "form-select form-control" }  id={this.props.handleTextChangeKeys[3]}  aria-label="state">
                      <option value=""> Select... </option>
                      { this.props.global.getStates(this.state.country).map((record, i) => (
                        <option key={i} value={record.value}>{record.text}</option>
                      ))
                    }
                  </select>
                  <label htmlFor="state">State {(this.props.iRequired?.includes('state')) && !this.props.notRequiredAsterisk ?  " *": ""}</label>
                  </>)
                  || 
                  (
                    <div className="mb-4">
                    <label><b>State</b></label><br/>
                      {this.state.state ? this.props.global.getUSAStates().filter(rec => rec.value === this.state.state)[0].text : "-"}
                    </div>
                  )
                }
                
                
            </div>
        </div>
        }
        {!this.props.iInvisible?.includes('zipcode') &&  
        <div className={this.props.customSize ? this.props.customSize : "col-sm-4 mb-3"}>
            <div className="form-floating">
            {(!this.props.iReadonly?.includes('zipcode') &&
                  <>
                <IMaskInput
                    mask={this.props.global.maskValidator('zipcode')}
                    id={this.props.handleTextChangeKeys[4]}
                    name={this.props.handleTextChangeKeys[4]}
                    value={this.state.zipcode} 
                    unmask={true}
                    maxLength={10}
                    aria-label="zipcode"                    
                    onAccept={
                        (value, mask) => this.handleTextChangeMaskedInput('zipcode', value, this.props.handleTextChangeKeys[4])
                    }
                    className={(nextClicked && this.props.iRequired?.includes('zipcode') && validators.isEmpty(this.state.zipcode)) || (!this.state.isNewScreen && this.props.iRequired?.includes('zipcode') && validators.isEmpty(this.state.zipcode)) ? "form-control input-error" : "form-control" }
                    placeholder="Zipcode"
                />
                <label htmlFor="zipcode">Zipcode {(this.props.iRequired?.includes('zipcode')) && !this.props.notRequiredAsterisk ?  " *": ""}</label>
                </>
            ) 
            ||
            (
              <div className="mb-4">
                  <label><b>Zipcode</b></label><br/>
                  {this.state.zipcode ? this.state.zipcode : "-"}
                </div>
          )}
            </div>
        </div>}
      </>
    )
  }
 
  render() {
    let validators = this.props.global.validators;
    let nextClicked = this.props.nextClicked ? this.props.nextClicked : this.props.boarding.nextClicked;
    return (
     <>
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChangeAddress}
        onSelect={this.handleSelect}
        autoComplete={"off"}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="form-floating" style={{position: "relative"}}>
            {!this.props.iInvisible?.includes('address') &&  
              ((!this.props.iReadonly?.includes('address') &&
              <div className="form-floating">
                { this.props.iTooltip &&
                <OverlayTrigger
                    placement="top"
          
                    overlay={<Tooltip >{this.props.iTooltip}</Tooltip>}
                >
                    <BiInfoCircle className="info-icon in-input"/>
                </OverlayTrigger>}
              <input
                {...getInputProps({
                  placeholder: this.props.placeholder,
                  className: (nextClicked && this.props.iRequired?.includes('address') && validators.isEmpty(this.state.address)) || (!this.state.isNewScreen && this.props.iRequired?.includes('address') && validators.isEmpty(this.state.address)) ? "form-control mb-3 input-error" : "form-control mb-3" ,
                })}
                autoComplete="off"
                aria-label="address"
                name={this.props.handleTextChangeKeys[0]}
                id={this.props.handleTextChangeKeys[0]}
                maxLength={200}
              />
              <label htmlFor="address">{this.props.placeholder} {(this.props.iRequired?.includes('address')) && !this.props.notRequiredAsterisk ?  " *": ""}</label>
              </div>
              )
              ||
              (
                <div className="mb-4">
                  <label><b>Address</b></label><br/>
                  {this.state.address ? this.state.address : "-"}
                </div>
              ))
            }
            {suggestions.length > 0 &&
            <div className="places-autocomplete-dropdown-container">
              {loading && <div className="suggestion-item">Loading...</div>}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? 'suggestion-item-active'
                  : 'suggestion-item';
                
                return (
                  <div key={i}
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          }
          
          </div>
        )}
        
        </PlacesAutocomplete>
        {this.props.iLink &&
            ( !this.props.iLinkInvisible ?
                <button 
                className="text-muted"
                style={{
                    backgroundColor: "#fff",
                    cursor: "pointer",
                    fontSize: "10px",
                    textDecoration: "underline",
                    border: "none"
                }} onClick={(e) => this.props.iLinkClick(e)}>{this.props.iLinkText}</button>
                : 
                <div 
                className="hidden"
                style={{
                    backgroundColor: "#fff",
                    height: "21px",
                    border: "none"
                }}/>
            )
            }  
        {this.props.withoutRow &&
            <>
              {this.getAddressInfo()}
            </>
        }
        {!this.props.withoutRow &&
            <div className="row">
              {this.getAddressInfo()}
            </div>
        }
      </>
    );
  }
}

export { LocationSearchInput };