import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import {BiMinusCircle, BiCheckCircle} from 'react-icons/bi'

@inject('reports', 'global', 'filter')
@observer
class ViewPartialPaypoints extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="orgParentname" id="orgParentname" className={"form-control"} placeholder="Parent Organization" onChange={(e) => this.props.filter.handleCaptureTextFilter('orgParentname(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('orgParentname(ct)', e)} value={this.props.filter.filterText.orgParentname ? this.props.filter.filterText.orgParentname : ''} />
                            <label htmlFor="orgParentname">Parent Organization</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="legalname" id="legalname" className={"form-control"} placeholder="Organization" onChange={(e) => this.props.filter.handleCaptureTextFilter('legalname(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('legalname(ct)', e)} value={this.props.filter.filterText.legalname ? this.props.filter.filterText.legalname : ''} data-qaid="inputPaypointFilterPaypoints"/>
                            <label htmlFor="legalname">Paypoint</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="externalPaypointID" id="externalPaypointID" className={"form-control"} placeholder="External Paypoint ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('externalPaypointID(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('externalPaypointID(ct)', e)} value={this.props.filter.filterText.externalPaypointID ? this.props.filter.filterText.externalPaypointID : ''}/>
                            <label htmlFor="legalname">External Paypoint ID</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="dbaName" id="dbaName" className={"form-control"} placeholder="DBA Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('dbaname(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('dbaname(ct)', e)} value={this.props.filter.filterText.dbaName ? this.props.filter.filterText.dbaName : ''} />
                            <label htmlFor="dbaName">DBA Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="orgAddress" id="orgAddress" className={"form-control"} placeholder="Address" onChange={(e) => this.props.filter.handleCaptureTextFilter('address(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('address(ct)', e)} value={this.props.filter.filterText.orgAddress ? this.props.filter.filterText.orgAddress : ''} />
                            <label htmlFor="orgAddress">Address</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="contactName" id="contactName" className={"form-control"} placeholder="Contact" onChange={(e) => this.props.filter.handleCaptureTextFilter('contactName(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('contactName(ct)', e)} value={this.props.filter.filterText.contactName ? this.props.filter.filterText.contactName : ''} />
                            <label htmlFor="contactName">Contact</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <select name="orgState" id="orgState" className={"form-control"} value={this.props.filter.filterOrgState} onChange={(e) => this.props.filter.handleSelectOrgState(e)}>
                                <option value=""> Select... </option>
                                { this.props.global.getUSAStates().map((record, i) => (
                                        <option key={i} value={record.value}>{record.text}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="orgState">State</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="orgCity" id="orgCity" className={"form-control"} placeholder="City" onChange={(e) => this.props.filter.handleCaptureTextFilter('city(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('city(ct)', e)} value={this.props.filter.filterText.orgCity ? this.props.filter.filterText.orgCity : ''} />
                            <label htmlFor="orgCity">City</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="entryName" id="entryName" className={"form-control"} placeholder="Entry Point" onChange={(e) => this.props.filter.handleCaptureTextFilter('entryName(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('entryName(ct)', e)} value={this.props.filter.filterText.entryName ? this.props.filter.filterText.entryName : ''} />
                            <label htmlFor="entryName">Entry Point</label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p className='mb-1'><small>Created</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="createdCalendar" 
                                        name="createdCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateCreated ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateCreated) + ' → ' : '') + (this.props.filter.stateDate.endDateCreated ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateCreated) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="createdCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateCreated ? this.props.filter.stateDate.startDateCreated : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateCreated', 'endDateCreated', 'createdAt')}
                            startDate={this.props.filter.stateDate.startDateCreated}
                            endDate={this.props.filter.stateDate.endDateCreated}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateCreated !== null ? false : true}
                        />
                    </div>
                    <div className="col-sm-12 col-xs-12 mb-4">
                        <p><small>Status</small></p>
                        <div className='section-status mt-1 mb-2'>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(eq)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))}><BiCheckCircle /> Active</span>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-light" : "badge text-light"} onClick={() => this.props.filter.handleStatus('status(eq)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiMinusCircle /> Inactive</span>
                        </div>
                    </div>
                </div>
        </>);
    }
}

export { ViewPartialPaypoints };