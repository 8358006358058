import React from 'react'
import styles from '../../../../assets/css/styleCommandCenter.module.css'
import { BiCreditCardAlt, BiRepost, BiEditAlt, BiDollarCircle, BiCloud, BiWallet } from 'react-icons/bi'
import Collapse from 'react-bootstrap/Collapse'

export default function MoneyIn({ showSection, setShowSection, setService, sectionCounter, SetInputValues, inputValues, service }) {
	return (
		<Collapse in={showSection.serviceMoneyIn}>
			<div id="collapse-section">
				<div className="d-flex flex-column gap-3">
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Select a service
					</label>
					<div className="row row-cols-lg-6">
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: true, credential: false, walletView: false, tiers: false }) |
									setService({
										cardIn: true,
										achIn: false,
										checkIn: false,
										cashIn: false,
										cloudIn: false,
										walletIn: false,
										managedOut: false,
										achOut: false,
										checkOut: false,
										virtualOut: false,
										pushOut: false,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyin', ServiceName: 'card', processor: '', gateway: '' })
								}
								className={service.cardIn ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<BiCreditCardAlt className={styles['icon30'] + ' ' + styles['color-orange']} />
								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>Card</label>
							</button>
						</div>
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: true, credential: false, walletView: false, tiers: false }) |
									setService({
										cardIn: false,
										achIn: true,
										checkIn: false,
										cashIn: false,
										cloudIn: false,
										walletIn: false,
										managedOut: false,
										achOut: false,
										checkOut: false,
										virtualOut: false,
										pushOut: false,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyin', ServiceName: 'ach', processor: '', gateway: '' })
								}
								className={service.achIn ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<BiRepost className={styles['icon30'] + ' ' + styles['color-red']} />

								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>ACH</label>
							</button>
						</div>
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: true, credential: false, walletView: false, tiers: false }) |
									setService({
										cardIn: false,
										achIn: false,
										checkIn: true,
										cashIn: false,
										cloudIn: false,
										walletIn: false,
										managedOut: false,
										achOut: false,
										checkOut: false,
										virtualOut: false,
										pushOut: false,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyin', ServiceName: 'check', processor: '', gateway: '' })
								}
								className={service.checkIn ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<BiEditAlt className={styles['icon30'] + ' ' + styles['color-teal-light']} />

								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>Check</label>
							</button>
						</div>
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: true, credential: false, walletView: false, tiers: false }) |
									setService({
										cardIn: false,
										achIn: false,
										checkIn: false,
										cashIn: true,
										cloudIn: false,
										walletIn: false,
										managedOut: false,
										achOut: false,
										checkOut: false,
										virtualOut: false,
										pushOut: false,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyin', ServiceName: 'cash', processor: '', gateway: '' })
								}
								className={service.cashIn ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<BiDollarCircle className={styles['icon30'] + ' ' + styles['color-light-green']} />

								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>Cash</label>
							</button>
						</div>
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: true, credential: false, walletView: false, tiers: false }) |
									setService({
										cardIn: false,
										achIn: false,
										checkIn: false,
										cashIn: false,
										cloudIn: true,
										walletIn: false,
										managedOut: false,
										achOut: false,
										checkOut: false,
										virtualOut: false,
										pushOut: false,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyin', ServiceName: 'cloud', processor: '', gateway: '' })
								}
								className={service.cloudIn ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<BiCloud className={styles['icon30'] + ' ' + styles['color-blue']} />

								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>Cloud Devices</label>
							</button>
						</div>
						<div className="col-4 mb-2">
							<button
								onClick={() =>
									setShowSection({ ...showSection, processor: false, credential: false, walletView: true, tiers: false }) |
									setService({
										cardIn: false,
										achIn: false,
										checkIn: false,
										cashIn: false,
										cloudIn: false,
										walletIn: true,
										managedOut: false,
										achOut: false,
										checkOut: false,
										virtualOut: false,
										pushOut: false,
										sectionCounter: sectionCounter
									}) |
									SetInputValues({ ...inputValues, ServiceGroup: 'moneyin', ServiceName: 'wallet', processor: '', gateway: '' })
								}
								className={service.walletIn ? styles['btn'] + ' ' + styles['border-blue'] + ' ' + styles['box-shodow-md'] : styles['btn']}
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '106px',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000A12',
									textTransform: 'none'
								}}>
								<BiWallet className={styles['icon30'] + ' ' + styles['color-teal-light']} />

								<label className={styles['d-none-750'] + ' ' + styles['label-none-pointer']}>Wallet</label>
							</button>
						</div>
					</div>
				</div>
			</div>
		</Collapse>
	)
}
