import React from 'react'

export default function loaderPlaceHolder(props) {
	const loaderStyle = {
		backgroundColor: '#eee',
		width: '100%',
		overflow: 'hidden',
		position: 'relative',
		...props.extraStyles
	}

	const loaderSwipeStyle = {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		animation: 'loaderSwipeAnimation 1.2s forwards infinite linear',
		background: 'linear-gradient(to right, #eeeeee 8%, #c2c1c1 28%, #eeeeee 33%)',
		backgroundSize: '800px 104px',
		height: '100%'
	}

	return (
		<div style={loaderStyle}>
			<div style={loaderSwipeStyle}></div>
		</div>
	)
}
