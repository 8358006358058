import React, { useContext } from 'react'
import InputContainer from '../../../../components/inputStyles/InputContainer'
import GlobalStore from '../../../../store/GlobalStore'
import Switch from 'react-switch'
import templatesContext from '../context/templates_context'
import { BiInfoCircle } from 'react-icons/bi'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function TabTemplateDetails() {
	const { orgName, DetailsTemplate, handleChangeDetails, LoadingState, handleChangeUnderwriting, UnderwritingTemplate } = useContext(templatesContext)
	return (
		<>
			<h5>Template Details</h5>
			<p className="small mb-4 dark-grey">This section contains information related to the template.</p>
			<div className="row mb-2">
				<div className="form-group">
					<label>Applied to Organization:</label>
					<label>&nbsp;&nbsp;{orgName}</label>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-3 mb-2">
					<InputContainer
						inputType="mask"
						divClass="form-group"
						labelClassName="font-weight-bold"
						classNameInput={`form-control ${!LoadingState && DetailsTemplate.templateName.trim().length === 0 && 'input-error'}`}
						//isLoading={LoadingState}
						inputDisabled={false}
						labelValue={
							<label>
								<span className="text-danger">*</span>Title
								<OverlayTrigger placement="top" overlay={
								<Tooltip>
									An internal name for the template to help identify it.
								</Tooltip>
								}>
								 <BiInfoCircle className='small ml-tiny'/>
								</OverlayTrigger>
							</label>
						}
						maxLength={250}
						name="templateName"
						placeholder="Enter Template Title"
						value={DetailsTemplate.templateName}
						setValue={(values) => handleChangeDetails('templateName', values)}
						mask={GlobalStore.maskValidator('text')}
					/>
				</div>
				<div className="col-sm-3 mb-2">
					<InputContainer
						inputType="mask"
						divClass="form-group"
						labelClassName="font-weight-bold"
						classNameInput={`form-control ${!LoadingState && DetailsTemplate.templateDescription.trim().length === 0 && 'input-error'}`}
						//isLoading={LoadingState}
						inputDisabled={false}
						labelValue={
							<label>
								<span className="text-danger">*</span>Description 
								<OverlayTrigger placement="top" overlay={
								<Tooltip>
									An internal description for the template to help identify it.
								</Tooltip>
								}>
								 <BiInfoCircle className='small ml-tiny'/>
								</OverlayTrigger>
							</label>
						}
						maxLength={250}
						name="templateDescription"
						placeholder="Enter Template Description"
						value={DetailsTemplate.templateDescription}
						setValue={(values) => handleChangeDetails('templateDescription', values)}
						mask={GlobalStore.maskValidator('text')}
					/>
				</div>
				<div className="col-sm-3 mb-2">
					<InputContainer
						inputType="mask"
						divClass="form-group"
						labelClassName="font-weight-bold"
						classNameInput="form-control"
						//isLoading={LoadingState}
						inputDisabled={false}
						labelValue={
							<label>
								Code
								<OverlayTrigger placement="top" overlay={
								<Tooltip>
									An internal code for the template Recipient.
								</Tooltip>
								}>
								 <BiInfoCircle className='small ml-tiny'/>
								</OverlayTrigger>
							</label>
						}
						maxLength={50}
						name="templateCode"
						placeholder="Enter Template Code"
						value={DetailsTemplate.templateCode}
						setValue={(values) => handleChangeDetails('templateCode', values)}
						mask={GlobalStore.maskValidator('text')}
					/>
				</div>
			</div>
			<label className='mt-3'><b>Underwriting</b></label>
			{/* Underwriting data is used to manage risk orchestration in the boarding application lifecycle. */}
			<div className="row">
				<div className="col-sm-3 mb-2">
					<div className="form-group">
						<label>
							<span className="text-danger">*</span>Method
							<OverlayTrigger placement="top" overlay={
							<Tooltip>
								This field controls which method is used to handle risk orchestration..
							</Tooltip>
							}>
								<BiInfoCircle className='small ml-tiny'/>
							</OverlayTrigger>
						</label>
						<select
							onChange={(e) => handleChangeUnderwriting('method', e.target.value)}
							className={`form-control form-select ${!LoadingState && UnderwritingTemplate.method.trim().length === 0 && 'input-error'}`}
							id="method"
							name="method"
							value={UnderwritingTemplate.method}>
							<option value=""> Select Underwriting Method........ </option>
							{['automatic','manual','bypass'].map((record, i) => {
								const [first, ...rest] = record
								return (<option
									key={i}
									value={record}>
									{first.toUpperCase() + rest.join('').toLowerCase()}
								</option>
								)
							})}
						</select>
					</div>
				</div>
				<div className="col-sm-3 mb-2">
					<InputContainer
						inputType="mask"
						divClass="form-group"
						labelClassName="font-weight-bold"
						classNameInput={`form-control ${!LoadingState && UnderwritingTemplate.method === 'automatic' && UnderwritingTemplate.policyId.trim().length === 0 && 'input-error'}`}
						//isLoading={LoadingState}
						inputDisabled={false}
						labelValue={
							<label>
								{ UnderwritingTemplate.method === 'automatic' &&<span className="text-danger">*</span>}Policy
								<OverlayTrigger placement="top" overlay={
								<Tooltip>
									Used to identify the risk workflow used to review this account. Policy IDs must be created before using automatic underwriting, and is required when method is automatic.
								</Tooltip>
								}>
									<BiInfoCircle className='small ml-tiny'/>
								</OverlayTrigger>
							</label>
						}
						maxLength={250}
						name="policyId"
						placeholder="Enter Template Policy"
						value={UnderwritingTemplate.policyId}
						setValue={(values) => handleChangeUnderwriting('policyId', values)}
						mask={GlobalStore.maskValidator('text')}
					/>
				</div>
			</div>
			<label className='mt-3'><b>Options</b></label>
			<div className="row mt-2">
				<div className="col-sm-3 mb-2">
					<label className="d-flex flex-row align-items-center">
						<Switch
							onChange={(value) =>
								handleChangeDetails(
									'recipientEmailNotification',
									value
								)
							}
							checked={
								DetailsTemplate.recipientEmailNotification
							}
							onColor="#10A0E3"
							onHandleColor="#ffffff"
							handleDiameter={20}
							boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
							activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
							height={20}
							width={38}
							className="react-switch"
							id={'recipientEmailNotification'}
						/>
						<span className="ml-2 small">
							Notify merchant of prefilled application <OverlayTrigger placement="top" overlay={
								<Tooltip>
									Toggles whether to send a boarding link to the merchant’s email address.
								</Tooltip>
								}>
								 <BiInfoCircle className='ml-tiny'/>
								</OverlayTrigger>
						</span>
					</label>
				</div>
				<div className="col-sm-3 mb-2">
					<label className="d-flex flex-row align-items-center">
						<Switch
							onChange={(value) =>
								handleChangeDetails(
									'resumable',
									value
								)
							}
							checked={
								DetailsTemplate.resumable
							}
							onColor="#10A0E3"
							onHandleColor="#ffffff"
							handleDiameter={20}
							boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
							activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
							height={20}
							width={38}
							className="react-switch"
							id={'resumable'}
						/>
						<span className="ml-2 small">
							Allow applicant to save and return to application <OverlayTrigger placement="top" overlay={
								<Tooltip>
									Toggles whether the applicant can save their progress and and later resume the application.
								</Tooltip>
								}>
								 <BiInfoCircle className='ml-tiny'/>
								</OverlayTrigger>
						</span>
					</label>
				</div>
			</div>
		</>
	)
}
