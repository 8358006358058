import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '../../components/Layout'
import { PaypointDataChartColumn } from '../../components/PaypointDataChartColumn';
import { PaypointLinks } from '../../components/PaypointLinks';

import {BiDollar} from '@react-icons/all-files/bi/BiDollar';
import {BiInfoCircle} from '@react-icons/all-files/bi/BiInfoCircle';
import {BiMoney} from '@react-icons/all-files/bi/BiMoney';
import {BiPieChart} from '@react-icons/all-files/bi/BiPieChart';
import {BiReceipt} from '@react-icons/all-files/bi/BiReceipt';
import {BiRepost} from '@react-icons/all-files/bi/BiRepost';
import {HiCheckCircle} from '@react-icons/all-files/hi/HiCheckCircle';
import { PayabliStorageManager } from '../../api/localStorageManager';

@inject('global', 'entry')
@observer
class PaypointOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            entryPoint: null
        };
        this.getEntryFromApi = this.getEntryFromApi.bind(this);
        this.goTo = this.goTo.bind(this);
        this.newTabRedirection = this.newTabRedirection.bind(this);
    }

    componentDidMount() {
        let entry = this.props.match.params.entry;
        this.getEntryFromApi(entry);
    }

    goTo(url){
        window.location.href = url;
    }

    newTabRedirection(url){
        alert(url);
    }


    getEntryFromApi(entry){

        this.props.global.setLoading(true);
        this.props.entry.getEntryFromApi(entry).then(res => {
            this.setState({entryPoint: res.responseData, entry: entry}, function(){
                this.props.global.setLoading(false);
            });
            
        })
        .catch(error => {
            this.props.global.setLoading(false);
        });
    }



    render() {


        return (
            <Layout {...this.props}>
            <div>

                <TopBar>
                    <MainTopBarMenu />
                    <div className="top-bar-sub">
                        <PaypointLinks idPaypoint={this.state.entryPoint ? this.state.entryPoint.Paypoint.IdPaypoint : ''} BoardingId={this.state.entryPoint ? this.state.entryPoint.Paypoint.BoardingId : ''} entry={this.state.entryPoint ? this.state.entryPoint.EntryName : ''} gobackLink={"/"+PayabliStorageManager.getEntryName()+"/report/paypoints"} goback={true}  gobackText="Paypoints list" history={this.props.history} selected="overview" />
                    </div>
                </TopBar>


                <div className="mt-body4">
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <h5>{this.state.entryPoint &&  this.state.entryPoint.Paypoint ? this.props.global.capitalizeFirstLetterOfEachWord(this.state.entryPoint.Paypoint.LegalName) : "-"}  
                            </h5>
                            <p className="small-grey" data-qaid="paypointViewDetails">{this.state.entryPoint &&  this.state.entryPoint.Paypoint ? this.state.entryPoint.Paypoint.LegalName : "-"}</p>
                        </div>
                        <div className="col-md-6 col-lg-5 text-right actions-btn-cont">
                            
                            
                        </div>
                    </div>


                    <div className="row">
                       
                        <div className="col-lg-3 mb-5 full-1260 order-1260-2">
                            <PaypointDataChartColumn routeParams={this.props.match.params} entryPoint={this.state.entryPoint}/>
                        </div>
                        <div className="col-lg-8 offset-lg-1 dashboard-items full-1260 order-1260-1 offset-1260-0">
                            <div className="row mb-1">
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle orange">
                                        <BiReceipt/>
                                    </div>
                                    <label>Total Invoices</label><br/>
                                    <b>$0.00</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle green">
                                        <BiDollar/>
                                    </div>
                                    <label>Total $ Volume</label><br/>
                                    <b>${this.state.entryPoint &&  this.state.entryPoint.Paypoint ? this.props.global.numberWithCommas(this.state.entryPoint.Paypoint.summary.amountTx.toFixed(2)) : "0.00"}</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle red">
                                        <BiPieChart/>
                                    </div>
                                    <label>Past Due Invoices</label><br/>
                                    <b>0</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    { (this.state.entryPoint &&  this.state.entryPoint.Paypoint && this.state.entryPoint.Paypoint.PaypointStatus) === 1 ?
                                    <>
                                        <div className="dashboard-color-circle greenoutline">
                                            <HiCheckCircle/>
                                        </div>
                                        <label>Status</label><br/>
                                        <b>Active</b>
                                    </>
                                    :
                                    <>
                                        <div className="dashboard-color-circle yellowoutline">
                                            <BiInfoCircle/>
                                        </div>
                                        <label>Status</label><br/>
                                        <b>Inactive</b>
                                    </>
                                    }
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle blue">
                                        <BiRepost/>
                                    </div>
                                    <label>Active Autopays</label><br/>
                                    <b>{this.state.entryPoint &&  this.state.entryPoint.Paypoint ? this.state.entryPoint.Paypoint.summary.countSubs : "0"} | ${this.state.entryPoint &&  this.state.entryPoint.Paypoint ? this.props.global.numberWithCommas(this.state.entryPoint.Paypoint.summary.amountSubs.toFixed(2)) : "0.00"}</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle purple">
                                        <BiMoney/>
                                    </div>
                                    <label>Balance</label><br/>
                                    <b>${ this.state.entryPoint ? this.props.global.numberWithCommas((0).toFixed(2)) : "0.00"}</b>
                                </div>
                            </div>
                            
                            <h6 className="sub-header-line mb-1 dark-grey">Payment Pages</h6>
                            { this.state.entryPoint && this.state.entryPoint.EntryPages && this.state.entryPoint.EntryPages.length > 0 ?
                                <div className="report-container height-1260-auto">
                                    <table className="table table-hover table-striped mb-5">
                                        <thead>
                                        <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Subdomain</th>
                                        <th scope="col">Created On</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" className="text-right">Total Volume</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.entryPoint.EntryPages.map((ppage, i) => (
                                                <tr key={i}>
                                                    <td>{ppage.PageContent.name ? ppage.PageContent.name : '-'}</td>
                                                    <td><a target="_blank" rel="noreferrer" href={process.env.REACT_APP_URL_PAYMENT_PAGE+this.state.entryPoint.EntryName+"/"+ppage.Subdomain} >{ppage.Subdomain ? ppage.Subdomain : '-'}</a></td>
                                                    <td>{ppage.LastAccess ? this.props.global.stringDateFormat(ppage.LastAccess)+", "+ this.props.global.stringTimeFormat(ppage.LastAccess) : '-'}</td>
                                                    <td><span className="badge rounded-pill bg-success">Active</span></td>
                                                    <td className="text-right">${this.props.global.numberWithCommas(parseFloat(ppage.totalAmount).toFixed(2))}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            :
                            <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Records not found</div> 
                            }   
                              
                        </div>
                    </div>





                </div>

            </div>
            </Layout>
        )
    }
}

export { PaypointOverview };