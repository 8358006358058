import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiHappyAlt} from "react-icons/bi";
import { Button, Modal } from 'react-bootstrap';

@inject('reports')
@observer
class ModalComingSoon extends React.Component {

    render() {

        return (
            <Modal style={{textAlign: "center"}} show={this.props.isOpen} onHide={this.props.closeAction}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <BiHappyAlt className="icon-modal"/>
                <h5>Coming Soon</h5>
                <p className="small">Sorry. We are working on this feature.</p>
                <Button className="btn cancel-btn" variant="default" onClick={this.props.closeAction}>
                    Accept
                </Button>
            </Modal.Body>
        </Modal>
        )
    }
}

export { ModalComingSoon };