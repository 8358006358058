import axios from 'axios'
import { PayabliStorageManager } from '../../../api/localStorageManager'

let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()

export const addBankAccountPOST = async (idPaypoint, dataBank) => {
	const res = await axios.post(process.env.REACT_APP_URL_API + 'Management/bankAccountByPaypoint/' + idPaypoint + '?validateAccount=true', dataBank, {
		headers: {
			requestToken: encryptStorage.getItem('pToken')
		}
	})
	return res
}
export const updateBankAccountPUT = async (idBank, dataBank) => {
	const res = await axios.put(process.env.REACT_APP_URL_API + 'Management/bankAccount/' + idBank + '?validateAccount=true', dataBank, {
		headers: {
			requestToken: encryptStorage.getItem('pToken')
		}
	})
	return res
}
export const updateMerchantBankAccountPUT = async (entry, dataBank) => {
	const res = await axios.put(process.env.REACT_APP_URL_API + 'Management/editMerchantBankAccount/' + entry + '?validateAccount=true', dataBank, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
		}
	})
	return res
}
export const bankAccountDELETE = async (idBank) => {
	const res = await axios.delete(process.env.REACT_APP_URL_API + 'Management/bankAccount/' + idBank, {
		headers: {
			requestToken: encryptStorage.getItem('pToken')
		}
	})
	return res
}
export const attachmentGET = async (paypointId, fileName) => {
	const res = await axios.get(process.env.REACT_APP_URL_API + `Management/paypointAttachment/${paypointId}/${fileName}?returnObject=false`, {
		responseType: 'blob',
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
		}
	})
	return res
}
export const attachmentPOST = async (dataDoc) => {
	const res = await axios.post(process.env.REACT_APP_URL_API + 'Management/paypointAttachment', dataDoc, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
		}
	})
	return res
}
export const attachmentDELETE = async (idPaypoin, fileName) => {
	const res = await axios.delete(process.env.REACT_APP_URL_API + `Management/paypointAttachment/${idPaypoin}/${fileName}`, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
		}
	})
	return res
}
