import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('reports', 'filter')
@observer
class RenderCurrency extends React.Component {

    render() {
        return (<>
                <p className='mb-1'><small>Currency</small></p>
                <div className="form-group" style={{fontSize: '20px', display: 'inline-flex', paddingLeft: '10px'}}>
                    <div className="icheck-primary mr-3">
                        <input type="radio" name="inlineRadioCurrencyUsd" id="inlineRadioCurrencyUsd" value="usd" checked={this.props.filter.filterRadioOption.inlineRadioCurrencyUsd ? true : false} onChange={(e) => this.props.filter.handleRadioOptionCycle(e, 'payaccountCurrency(eq)')} />
                        <label htmlFor="inlineRadioCurrencyUsd">USD</label>
                    </div>
                    <div className="icheck-primary">
                        <input type="radio" name="inlineRadioCurrencyCad" id="inlineRadioCurrencyCad" value="cad" checked={this.props.filter.filterRadioOption.inlineRadioCurrencyCad ? true : false} onChange={(e) => this.props.filter.handleRadioOptionCycle(e, 'payaccountCurrency(eq)')} />
                        <label htmlFor="inlineRadioCurrencyCad">CAD</label>
                    </div>
                </div>
            </>);
    }
}

export { RenderCurrency };