import { action } from 'mobx'

export default function TemplateProcessingFunction({ processingData, setProcessingData, insertValues, handleChange }) {
	const fieldDataProcessing = (data, templateCreator, newTemplate) => {
		let newProcessingData = {}
		let structure = { visible: true, subHeader: '', subFooter: '' }
		for (let element in structure) {
			newProcessingData[element] = insertValues(data[templateCreator], 'processingData', element, structure[element], 'oneElement')
		}

		structure = {
			mcc: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			bsummary: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			binperson: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: false },
			binphone: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: false },
			binweb: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: false },
			avgmonthly: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: false },
			ticketamt: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: false },
			highticketamt: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: false },
			annualRevenue: { posRow: 0, posCol: 0, value: '0', visible: true, readOnly: false, required: false },
			whenCharged: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			whenProvided: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			whenDelivered: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			whenRefunded: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false }
		}
		for (let element in structure) {
			newProcessingData[element] = insertValues(data[templateCreator], 'processingData', element, structure[element])
		}

		if (newTemplate) {
			newProcessingData.avgmonthly.visible = true
			newProcessingData.ticketamt.visible = true
			newProcessingData.highticketamt.visible = true
		}

		setProcessingData(newProcessingData)
	}
	const handleChangeProcessing = action((property, field, value) => {
		handleChange(processingData, setProcessingData, property, field, value)
	})

	return { handleChangeProcessing, fieldDataProcessing }
}
