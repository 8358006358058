import React, { useState } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import RowContainer from '../RowContainer'
import Collapse from 'react-bootstrap/Collapse'
import GlobalStore from '../../../store/GlobalStore'
import GlobalGp from './Credential/Global_Gp'
import Nmi from './Credential/Nmi'
import CheckCommerce from './Credential/CheckCommerce'
import Repay from './Credential/Repay'

export default function Processor({ service, indexGateways, serviceName, inputDisabled, indexService, handleChange, typeService = 'moneyin', classValidate, setClassValidate, showWarningAccountId, setShowWarningAccountId }) {
	const [showCredential, setShowCredential] = useState(false)

	const selectGateway = (gateway) => {
		if (gateway.toUpperCase().includes('LEGACY')) {
			return 'legacy'
		} else if (gateway.toUpperCase().includes('PAYABLI')) {
			return 'payabli'
		}
		return ''
	}
	const gateway = () => {
		if (typeService === 'moneyin') {
			switch (serviceName.toUpperCase()) {
				case 'CARD':
				case 'ACH':
				case 'CLOUD':
					return [
						{ value: 'payabli', text: 'Payabli' },
						{ value: 'legacy', text: 'Legacy' }
					]
				case 'CHECK':
				case 'CASH':
					return [{ value: 'payabli', text: 'Payabli' }]
				default:
					return []
			}
		} else {
			switch (serviceName.toUpperCase()) {
				case 'MANAGED':
				case 'ACH':
				case 'VCARD':
					return [{ value: 'payabli', text: 'Payabli' }]
				default:
					return []
			}
		}
	}
	const processor = (gateway) => {
		if (!gateway) return []
		if (typeService === 'moneyin') {
			if (gateway === 'payabli') {
				switch (serviceName.toUpperCase()) {
					case 'CARD':
					case 'CLOUD':
						return [{ value: 'gp', text: 'Global' }]
					case 'ACH':
						return [
							{ value: 'gp', text: 'Global' },
							{ value: 'checkcommerce', text: 'Check Commerce' }
						]
					case 'CHECK':
					case 'CASH':
						return [{ value: 'dummy', text: 'Dummy' }]
					case 'WALLET':
						return [{ value: '', text: 'to define' }]

					default:
						return []
				}
			} else if (gateway === 'legacy') {
				switch (serviceName.toUpperCase()) {
					case 'CARD':
					case 'ACH':
					case 'CLOUD':
						return [{ value: 'nmi', text: 'NMI' }]
					default:
						return []
				}
			} else {
				return []
			}
		} else {
			if (gateway === 'payabli') {
				switch (serviceName.toUpperCase()) {
					case 'MANAGED':
					case 'VCARD':
						return [{ value: 'repay', text: 'Repay' }]
					case 'ACH':
						return [{ value: 'checkcommerce', text: 'Check Commerce' }]
					default:
						return []
				}
			} else {
				return []
			}
		}
	}

	return (
		<div style={{ width: '100%', minHeight: '100px', paddingLeft: '12px' }}>
			<div className="d-flex flex-column">
				<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
					<div className="d-flex flex-column align-items-start p-0">
						<RowContainer
							inputDisabled={inputDisabled}
							classNameInput={classValidate.gateway}
							labelValue="Gateway"
							name={'GatewayName[' + service.Gateways[indexGateways].Id + ']'}
							value={selectGateway(service.Gateways[indexGateways].GatewayName)}
							setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'GatewayName', values) | setClassValidate({ ...classValidate, gateway: values ? '' : 'is-invalid', processor: !values ? 'is-invalid' : !service.Gateways[indexGateways].Connector.Name ? 'is-invalid' : '' })}
							optionList={
								<>
									<option value="">Select a gateway</option>
									{gateway().map((data, index) => (
										<option
											key={'GatewayName' + data.value + index}
											value={data.value}>
											{data.text}
										</option>
									))}
								</>
							}
						/>

						<RowContainer
							inputDisabled={inputDisabled}
							classNameInput={classValidate.processor}
							labelValue="Processor"
							name={'processor[' + service.Gateways[indexGateways].Id + ']'}
							value={service.Gateways[indexGateways].Connector.Name}
							setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'Name', values, true) | setClassValidate({ ...classValidate, processor: values ? '' : 'is-invalid' })}
							optionList={
								<>
									<option value="">Select a processor</option>
									{processor(selectGateway(service.Gateways[indexGateways].GatewayName)).map((data, index) => (
										<option
											key={'Processor' + data.value + index}
											value={data.value}>
											{data.text}
										</option>
									))}
								</>
							}
						/>
					</div>
					<div className="d-flex flex-column align-items-start p-0">
						<RowContainer
							inputType="mask"
							inputDisabled={inputDisabled}
							classNameInput={showWarningAccountId ? styles['showWarning'] : service.Gateways.length > 1 ? classValidate.accountId : ''}
							labelValue={service.Gateways.length > 1 ? 'Identifier' : 'Identifier (Optional)'}
							name={'accountId[' + service.Gateways[indexGateways].Id + ']'}
							placeholder="Value"
							value={service.Gateways[indexGateways].AccountId}
							setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'AccountId', values) | setClassValidate({ ...classValidate, accountId: values ? '' : 'is-invalid' }) | setShowWarningAccountId(false)}
							mask={GlobalStore.maskValidator('text')}
							TooltipCaption={
								service.Gateways.length > 1 ? (
									''
								) : (
									<>
										An <strong>Identifier</strong> is a customer identifier for the Gateway to Processor combination. This is very useful when needing to identify various processors enabled for a service within a single Paypoint.
										<br /> <i>Example:</i> <strong>ABC</strong> PayPoint has Processor <strong>A</strong> and Processor <strong>B</strong> for the credit card service.
									</>
								)
							}
							maxLength={30}
						/>
					</div>
				</div>
			</div>
			{service.Gateways[indexGateways].Connector.Name !== 'dummy' && (
				<label
					onClick={() => setShowCredential(inputDisabled && !showCredential)}
					className={styles['label-form'] + ' ' + styles['color-blue'] + ' ' + styles['ft-s-10']}
					style={{
						fontWeight: '400',
						lineHeight: '15px',
						width: '99px',
						letterSpacing: ' 0.015em',
						textTransform: 'uppercase',
						paddingBottom: '15px',
						cursor: 'pointer'
					}}>
					SHOW CREDENTIALS
				</label>
			)}
			<Collapse in={!inputDisabled ? true : showCredential}>
				<div id="collapse-card">
					<div className="d-flex flex-column w-100">
						{service.Gateways[indexGateways].GatewayName && (
							<div className="d-flex flex-column">
								{service.Gateways[indexGateways].Connector.Name === 'gp' && (
									<GlobalGp
										inputDisabled={inputDisabled}
										service={service}
										serviceName={serviceName}
										indexService={indexService}
										indexGateways={indexGateways}
										handleChange={handleChange}
										classValidate={classValidate}
										setClassValidate={setClassValidate}
									/>
								)}
								{service.Gateways[indexGateways].Connector.Name === 'nmi' && (
									<Nmi
										inputDisabled={inputDisabled}
										service={service}
										serviceName={serviceName}
										indexService={indexService}
										indexGateways={indexGateways}
										handleChange={handleChange}
										classValidate={classValidate}
										setClassValidate={setClassValidate}
									/>
								)}
								{service.Gateways[indexGateways].Connector.Name === 'checkcommerce' && (
									<CheckCommerce
										inputDisabled={inputDisabled}
										service={service}
										serviceName={serviceName}
										indexService={indexService}
										indexGateways={indexGateways}
										handleChange={handleChange}
										classValidate={classValidate}
										setClassValidate={setClassValidate}
									/>
								)}
								{service.Gateways[indexGateways].Connector.Name === 'repay' && (
									<Repay
										inputDisabled={inputDisabled}
										service={service}
										serviceName={serviceName}
										indexService={indexService}
										indexGateways={indexGateways}
										handleChange={handleChange}
										classValidate={classValidate}
										setClassValidate={setClassValidate}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</Collapse>
		</div>
	)
}
