import React, { useState, useEffect } from 'react'
import styles from '../../assets/css/styleCommandCenter.module.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiInfoCircle, BiCheckCircle, BiLinkExternal } from 'react-icons/bi'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import en from 'date-fns/locale/en-US'
import { IMaskInput } from 'react-imask'
import NumberFormat from 'react-number-format'
import LoaderPlaceHolder from '../loaderPlaceHolder'
import { PayabliStorageManager } from '../../api/localStorageManager'
import * as Sentry from '@sentry/react'
import GlobalStore from '../../store/GlobalStore'

export default function InputContainer({
	inputDisabled,
	labelValue,
	inputType = 'text',
	mask,
	name,
	checkName,
	required = false,
	placeholder,
	readonly = '',
	classNameInput = '',
	classNameInputCheck = '',
	inputStyle = {},
	value = '',
	checkValue = false,
	autocomplete = 'off',
	setValue,
	setCheckValue,
	minDate = false,
	maxLength,
	prefix = '$',
	suffix = '',
	decimalScale = 2,
	allowNegative = false,
	maxValue = 0,
	TooltipCaption = '',
	TooltipLabelStyle={},
	placement = 'right',
	placementValid = 'right',
	TooltipValid = '',
	TooltipValidColorIcon = '',
	TooltipValidStyle = {},
	divStyle = {},
	divStyleICheck = {},
	labelStyle = {},
	labelCheckClassName = '',
	labelCheckStyle = {},
	isLoading = false,
	labelClassName = '',
	allowedNullDate = false,
	divClass = '',
	divInputClass = '',
	divInputStyle = {},
	maxDate = null,
	isPhoneInput = false,
	iCheck = false,
	errorValid = false,
	refs,
	linkBottonInput = '',
	hidden = false,
	placeholderStyle = {
		height: '39px',
		width: '100%',
		borderRadius: '.25rem'
	},
	onBlur = () => null
}) {
	const [phoneState, setPhoneState] = useState({
		errorMsg: '',
		isSuccess: false,
		isLoading: false
	})
	const tomorrow = new Date()
	tomorrow.setDate(tomorrow.getDate() + 1)
	const years = []
	let year = new Date().getFullYear()
	for (let i = year; i <= year + 2; i++) {
		years.push(i)
	}

	useEffect(() => {
		if (isPhoneInput && value?.length === 10) {
			setPhoneState({ errorMsg: '', isSuccess: false, isLoading: true })
			validatePhone(value).then((result) => {
				const responseData = result?.responseData
				setPhoneState({
					isSuccess:
						responseData?.carrierName !== '' &&
						responseData?.type !== '' &&
						responseData?.errors === null
							? true
							: false,
					errorMsg:
						responseData?.status === '400'
							? (responseData?.errors?.length &&
									responseData?.errors[0]) ||
							  'Number must be a valid phone number.'
							: '',
					iconLoading: false
				})
			})
		}
	}, [isPhoneInput, value])

	const handleChange = (e) => {
		setValue(e.target.value)
	}
	const checkHandleChange = (e) => {
		setCheckValue(e.target.checked)
	}
	const handleChangeNumber = (e) => {
		setValue(e.value)
	}
	const handleChangeDate = (date) => {
		if (!date && !allowedNullDate) return
		setValue(date)
	}
	const focusNumbers = (e) => {
		e.target.setSelectionRange(0, 1000000000)
	}
	const validatePhone = async (value) => {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let token =
			encryptStorage.getItem('pToken') || process.env.REACT_APP_TOKEN
		let host = process.env.REACT_APP_URL_API
		var myHeaders = new Headers()
		myHeaders.append('requestToken', token)
		myHeaders.append('Content-Type', 'application/json')

		var raw = JSON.stringify(`+1${value}`)
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		}
		var result = {}
		await fetch(
			`${host}Tools/validatePhone?extendedValidation=true`,
			requestOptions
		)
			.then((response) => response.text())
			.then((res) => {
				result = JSON.parse(res)
			})
			.catch((error) => {
				Sentry.captureException(error)
				result = {
					status: 400,
					data: error.message
				}
			})
		return result
	}

	return (
		<div
			className={divClass}
			style={divStyle}>
			{!TooltipCaption && !iCheck ? (
				<label
					className={labelClassName}
					style={labelStyle}>
					{labelValue}
				</label>
			) : !iCheck ? (
				<label
					className={labelClassName}
					style={labelStyle}>
					{labelValue} &nbsp;
					<OverlayTrigger
						key={placement}
						placement={placement}
						overlay={<Tooltip>{TooltipCaption}</Tooltip>}>
						<BiInfoCircle
							style={TooltipLabelStyle}
							className={
								styles['icon15'] + ' ' + styles['color-blue']
							}
						/>
					</OverlayTrigger>
				</label>
			) : (
				<div
					className="icheck-success"
					style={{
						...divStyleICheck,
						border: errorValid && '1px solid #dc3545',
						padding: errorValid && '5px',
						borderRadius: errorValid && '.25rem'
					}}>
					<input
						ref={refs}
						type="checkbox"
						onChange={checkHandleChange}
						checked={checkValue}
						id={checkName}
						name={checkName}
						readOnly={readonly}
						className={classNameInputCheck}
						disabled={inputDisabled}
						hidden={hidden}
					/>
					{!TooltipCaption && !hidden ? (
						<label
							htmlFor={checkName}
							className={labelCheckClassName}
							style={labelCheckStyle}>
							{labelValue}
						</label>
					) : (
						!hidden && (
							<label
								htmlFor={name}
								className={labelClassName}
								style={{
									...labelStyle,
									display: 'flex',
									alignItems: 'center'
								}}>
								{labelValue} &nbsp;
								<OverlayTrigger
									key={placement}
									placement={placement}
									overlay={
										<Tooltip>{TooltipCaption}</Tooltip>
									}>
									<BiInfoCircle
										className={`${styles['icon15']} ${
											!errorValid
												? styles['color-blue']
												: styles['color-error']
										}`}
									/>
								</OverlayTrigger>
							</label>
						)
					)}
				</div>
			)}

			<div
				className={divInputClass}
				style={divInputStyle}>
				{isLoading ? (
					<LoaderPlaceHolder extraStyles={placeholderStyle} />
				) : inputType === 'text' || inputType === 'password' ? (
					<input
						type={inputType}
						onChange={handleChange}
						onBlur={onBlur}
						name={name}
						id={name}
						required={required}
						className={classNameInput}
						style={inputStyle}
						placeholder={inputDisabled ? '-' : placeholder}
						value={value}
						disabled={inputDisabled}
						readOnly={readonly}
						autoComplete={autocomplete}
						maxLength={maxLength}
					/>
				) : inputType === 'mask' ? (
					<div className="d-flex flex-column align-items-end">
						<IMaskInput
							mask={mask}
							name={name}
							onBlur={onBlur}
							value={value}
							unmask={true}
							required={required}
							onAccept={(value, mask) =>
								setValue(value) | isPhoneInput &&
								setPhoneState({
									errorMsg: '',
									isSuccess: false,
									isLoading: false
								})
							}
							placeholder={inputDisabled ? '-' : placeholder}
							className={`${
								isPhoneInput &&
								!phoneState.isSuccess &&
								value?.length <= 10 &&
								'input-error'
							} ${classNameInput}`}
							style={inputStyle}
							id={name}
							disabled={inputDisabled}
							readOnly={readonly}
							autoComplete={autocomplete}
							maxLength={maxLength}
						/>
						{linkBottonInput !== '' && (
							<a
								style={{
									textDecoration: 'none',
									cursor: 'pointer',
									color: '#10a0e3 !important'
								}}
								target="_blank"
								href={`/${GlobalStore.getURLEntry()}/report/tiers`}
								rel="noopener noreferrer"
								data-qaid="templateGoToTiersView">
								{linkBottonInput} <BiLinkExternal />
							</a>
						)}
					</div>
				) : inputType === 'number' ? (
					<NumberFormat
						thousandsGroupStyle="thousand"
						prefix={!suffix ? prefix : ''}
						suffix={!prefix ? suffix : ''}
						decimalSeparator="."
						displayType="input"
						onBlur={onBlur}
						onFocus={(e) => focusNumbers(e)}
						type="text"
						required={required}
						thousandSeparator={true}
						allowNegative={allowNegative}
						decimalScale={decimalScale}
						fixedDecimalScale={true}
						autoComplete="off"
						name={name}
						id={name}
						value={value}
						className={classNameInput}
						style={inputStyle}
						placeholder={inputDisabled ? '-' : placeholder}
						onValueChange={(e) => handleChangeNumber(e)}
						readOnly={readonly}
						disabled={inputDisabled}
						maxLength={maxLength}
						isAllowed={({ value }) =>
							maxValue > 0 ? value <= maxValue : true
						}
					/>
				) : inputType === 'textarea' ? (
					<textarea
						onChange={handleChange}
						name={name}
						id={name}
						onBlur={onBlur}
						className={`${classNameInput} ${styles['textarea']}`}
						style={inputStyle}
						placeholder={inputDisabled ? '-' : placeholder}
						value={value}
						disabled={inputDisabled}
						readOnly={readonly}
						required={required}
						autoComplete={autocomplete}
						maxLength={maxLength}
					/>
				) : (
					inputType === 'dateBox' && (
						<DatePicker
							name={name}
							id={name}
							closeOnScroll={true}
							onBlur={onBlur}
							selected={value ? value : ''}
							className={`${classNameInput} ${styles['input-calendar']}`}
							style={inputStyle}
							wrapperClassName={styles['wrapper-width']}
							onChange={(date) => handleChangeDate(date)}
							// dateFormat={'yyyy/MM/dd'}
							locale={en}
							//todayButton={'today'}
							required={required}
							disabled={inputDisabled}
							showPopperArrow={false}
							shouldCloseOnSelect={true}
							readOnly={readonly}
							showYearDropdown
							maxDate={maxDate}
							scrollableMonthYearDropdown
							minDate={minDate && tomorrow}
							dayClassName={(date) => 'calendar-day'}
							popperPlacement="bottom-start"
						/>
					)
				)}
				{TooltipValid && (
					<OverlayTrigger
						key={placementValid}
						placement={placementValid}
						overlay={<Tooltip>{TooltipValid}</Tooltip>}>
						<div style={TooltipValidStyle}>
							<BiInfoCircle
								className={`${styles['icon15']} ${
									styles[
										!TooltipValidColorIcon
											? 'color-blue'
											: TooltipValidColorIcon
									]
								}`}
							/>
						</div>
					</OverlayTrigger>
				)}
				{isPhoneInput && !phoneState.isLoading && (
					<div style={TooltipValidStyle}>
						{phoneState.errorMsg && !phoneState.isLoading && (
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip>{phoneState.errorMsg}</Tooltip>
								}>
								<BiInfoCircle color={'red'} />
							</OverlayTrigger>
						)}
						{!phoneState.errorMsg &&
							phoneState.isSuccess &&
							!phoneState.isLoading &&
							value?.length === 10 && (
								<BiCheckCircle color="limegreen" />
							)}
					</div>
				)}
				{isPhoneInput && phoneState.isLoading && (
					<div style={TooltipValidStyle}>
						<div className="lds-ring">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
