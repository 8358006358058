import React, { useContext } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import { RiMoneyDollarBoxLine, RiSettings3Line } from 'react-icons/ri'
import { BiDotsVerticalRounded, BiShow } from 'react-icons/bi'
import FrameGroup from '../../../components/commandCenter/FrameGroup'
import { Element } from 'react-scroll'
import commandContext from '../context/commandCenter_context'

export default function BillingFees({ viewBilling }) {
	const { PayPoint, services } = useContext(commandContext)
	const selectElement = (value, tab) => {
		if (tab === 'moneyIn') {
			switch (value) {
				case 'Card':
					return { element: 'CreditCard', tab: 'moneyIn' }
				case 'ACH':
					return { element: 'ACHIn', tab: 'moneyIn' }
				case 'Check':
					return { element: 'Check', tab: 'moneyIn' }
				case 'Cash':
					return { element: 'Cash', tab: 'moneyIn' }
				case 'Cloud Devices':
					return { element: 'CloudDevices', tab: 'moneyIn' }
				case 'Wallet':
					return { element: 'Wallet', tab: 'moneyIn' }
				default:
					return { element: '', tab: 'moneyIn' }
			}
		} else if (tab === 'moneyOut') {
			switch (value) {
				case 'Managed Payables':
					return { element: 'ManagedPayAbles', tab: 'moneyOut' }
				case 'ACH':
					return { element: 'ACHOut', tab: 'moneyOut' }
				case 'Virtual Card':
					return { element: 'vcard', tab: 'moneyOut' }
				case 'Physical Check':
					return { element: 'PhysicalCheck', tab: 'moneyOut' }
				case 'Push To Card':
					return { element: 'PushToCard', tab: 'moneyOut' }
				default:
					return { element: '', tab: 'moneyOut' }
			}
		} else {
			return { element: '', tab: 'valueAddedServices' }
		}
	}
	const detailColumns = (caption, details, tab) => {
		return (
			<>
				<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-13']}
						style={{ fontWeight: '700', lineHeight: '20px', minWidth: '180px', letterSpacing: '0.0025em' }}>
						{caption}
					</label>
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-13'] + ' ' + styles['size-auto']}
						style={{ fontWeight: '400', lineHeight: '20px', width: '326px', letterSpacing: '0.0025em' }}>
						{details}
					</label>
				</div>
				<button
					onClick={() => viewBilling(selectElement(caption, tab))}
					style={{ fontWeight: '500' }}
					className={styles['btn'] + ' ' + styles['no-border'] + ' ' + styles[PayPoint.idPaypoint <= 0 || PayPoint.entryPoints.length <= 0 ? 'color-disabled' : 'color-blue']}
					disabled={PayPoint.idPaypoint <= 0 || PayPoint.entryPoints.length <= 0 ? true : false}>
					<BiShow className={styles['icon24']} />
					View Details
				</button>
			</>
		)
	}

	return (
		<Element
			name="Billing"
			className="d-flex justify-content-center w-100">
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={<RiMoneyDollarBoxLine className={styles['icon26'] + ' ' + styles['color-blue']} />}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label="Billing & Fees"
					useBtn={false}
					useOptBtn1={true}
					labelOptBtn1="VIEW & MANAGE BILLING DETAILS"
					classBtn1={styles['btn'] + ' ' + styles['no-border'] + ' ' + styles[PayPoint.idPaypoint <= 0 || PayPoint.entryPoints.length <= 0 ? 'color-disabled' : 'color-blue']}
					iconOptBtn1={<RiSettings3Line className={styles['icon24']} />}
					functionOpt1={() => viewBilling({ element: 'CreditCard', tab: 'moneyIn' })}
				/>

				<div
					className="d-flex flex-column align-items-start p-0 w-100 mb-3"
					style={{ gap: '4px' }}>
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Money In
					</label>
					<div className="d-flex flex-column w-100 gap-1">
						{services.moneyin.card.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Card', 'Billing Account|Pricing Type', 'moneyIn')}</div>}
						{services.moneyin.ach.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('ACH', 'Billing Account|Pricing Type', 'moneyIn')}</div>}
						{services.moneyin.wallet.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Wallet', 'Billing Account|Pricing Type', 'moneyIn')}</div>}
						{services.moneyin.check.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Check', 'Billing Account|Pricing Type', 'moneyIn')}</div>}
						{services.moneyin.cash.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Cash', 'Billing Account|Pricing Type', 'moneyIn')}</div>}
						{services.moneyin.cloud.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Cloud Devices', 'Billing Account|Pricing Type', 'moneyIn')}</div>}
					</div>
				</div>

				<div
					className="d-flex flex-column align-items-start p-0 w-100 mb-3"
					style={{ gap: '4px' }}>
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Money Out
					</label>
					<div className="d-flex flex-column w-100 gap-1">
						{services.moneyout.managed.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Managed Payables', 'Billing Account|Pricing Type', 'moneyOut')}</div>}
						{services.moneyout.ach.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('ACH', 'Billing Account|Pricing Type', 'moneyOut')}</div>}
						{services.moneyout.vcard.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Virtual Card', 'Billing Account|Pricing Type', 'moneyOut')}</div>}
						{services.moneyout.check.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Phsyical Check', 'Billing Account|Pricing Type', 'moneyOut')}</div>}
						{services.moneyout.pushToCard.length > 0 && <div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Push To Card', 'Billing Account|Pricing Type', 'moneyOut')}</div>}
					</div>
				</div>

				<div
					className="d-flex flex-column align-items-start p-0 w-100 mb-3"
					style={{ gap: '4px' }}>
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Value Added Services
					</label>
					<div className="d-flex flex-column w-100 gap-1">
						<div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Tokenization', 'Billing Account|Pricing Type', 'valueAddedServices')}</div>
						<div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Split Pay', 'Billing Account|Pricing Type', 'valueAddedServices')}</div>
						<div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Invoicing', 'Billing Account|Pricing Type', 'valueAddedServices')}</div>
						<div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('ACH Bank Verification', 'Billing Account|Pricing Type', 'valueAddedServices')}</div>
						<div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Level II & III', 'Billing Account|Pricing Type', 'valueAddedServices')}</div>
						<div className={styles['grid-container'] + ' justify-content-start align-items-center'}>{detailColumns('Automatic Card Updater', 'Billing Account|Pricing Type', 'valueAddedServices')}</div>
					</div>
				</div>
			</div>
		</Element>
	)
}
