import React from 'react';
import { inject, observer } from 'mobx-react';

import { Accordion, Card } from 'react-bootstrap';

import { SelectPaymentMethodSaved } from '../../../../../components/SelectPaymentMethodSaved';
import { PaymentMethodsAccepted } from '../../../../../components/PaymentMethodsAccepted';
import { PaymentMethodCCForm } from '../../../../../components/PaymentMethodCCForm';
import { PaymentMethodECheck } from '../../../../../components/PaymentMethodECheck';
import { PaymentMethodCheck } from '../../../../../components/PaymentMethodCheck';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

@inject('store', 'global', 'vTerminal', 'device')
@observer
class ChargeCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      responseList: [],
      responseListEmpty: true,
    }

    this.setPaymentMethod = this.setPaymentMethod.bind(this);
    this.handleChangeDeviceSelect = this.handleChangeDeviceSelect.bind(this);
  }

  componentDidMount() {
    this.props.global.setLoading(true);
    this.props.vTerminal
      .getPaymentPageFromApi("invoice")
      .then((res) => {
        this.props.global.setLoading(false);
      })
      .catch((error) => {
        this.props.global.setLoading(false);
        let errorMessage =
          error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!';
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      });

      //devices
      let entryName = null;
      let customerSelected = this.props.vTerminal.getCustomerSelected;
      if(customerSelected && customerSelected !=={}){
        entryName = customerSelected.PaypointEntryname;
      }
      
      if(entryName){
          this.props.device.getDevicesFromApi(entryName).then(res => {
            if(res?.responseList && res?.responseList?.length > 0){
                this.setState({responseList: res.responseList}, function(){
                    this.props.global.setLoading(false);
                })
            }
            else{
              setTimeout(() => {
                this.setState({responseListEmpty: false}, () => {
                    this.props.global.setLoading(false);
                })
            }, 1000);
            }     
          })
          .catch(error => {
              this.props.global.setLoading(false);
          });
      }
      
  }

  componentDidUpdate() {
    let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;

    if (
      this.props.vTerminal.getCustomerSelected.StoredMethods &&
      this.props.vTerminal.getCustomerSelected.StoredMethods.length === 0
    ) {
      if (
        this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' &&
        this.props.vTerminal.hasCards &&
        ((credentialsPermissions.card.onetime && !this.props.vTerminal.isAutopay) ||
          (credentialsPermissions.card.recurring && this.props.vTerminal.isAutopay))
      ) {
        this.props.vTerminal.setPaymentMethod('card');
        this.props.vTerminal.setDefaultPaymentMethodActiveKey('0');
      } else if (
        this.props.vTerminal.getPaymentMethod.toLowerCase() === 'ach' &&
        this.props.vTerminal.hasECheck &&
        ((credentialsPermissions.ach.onetime && !this.props.vTerminal.isAutopay) ||
          (credentialsPermissions.ach.recurring && this.props.vTerminal.isAutopay))
      ) {
        this.props.vTerminal.setPaymentMethod('ach');
        this.props.vTerminal.setDefaultPaymentMethodActiveKey('1');
      }
    }
  }

  setPaymentMethod(method, activeKey) {
    this.props.vTerminal.setPaymentMethod(method);
    if (activeKey !== null) {
      this.props.vTerminal.setDefaultPaymentMethodActiveKey(activeKey);
    }
    this.props.vTerminal.setSavedPaymentMethodSelected('');

    if(method === "device" && this.state.responseList && this.state.responseList.length === 1){
        let defaultValue = this.state.responseList[0];
        if(defaultValue){
            this.setState({deviceDescription:defaultValue.deviceNickName});
            this.props.vTerminal.handleTextChangeACH('device', defaultValue.deviceId);
        }
    }    
  }

  handleChangeDeviceSelect(e){
    let value = e.target.value;
    let valueArray = value.split("|||");
    if(valueArray[0]){
        this.setState({deviceDescription:valueArray[1]});
        this.props.vTerminal.handleTextChangeACH(e.target.name, valueArray[0]);
    }
  }

  render() {
    let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;

    return (
      <div className="charge-payment-options">
        <h6 className="mb-3">Payment Method</h6>
        {
            (!credentialsPermissions.ach.onetime && !credentialsPermissions.ach.recurring && !credentialsPermissions.card.onetime && !credentialsPermissions.card.recurring) && (
                <div style={{margin: '50px 0 50px 0'}}>
                    <div className="alert alert-warning small">There are no services activated for this Paypoint. Contact your administrator to enable it.</div>
                </div>
            )                        
        }
        {this.props.vTerminal.getCustomerSelected.StoredMethods &&
          this.props.vTerminal.getCustomerSelected.StoredMethods.length > 0 && (
            <div className="mb-3">
              <SelectPaymentMethodSaved />
            </div>
          )}

        <Accordion activeKey={this.props.vTerminal.defaultPaymentMethodActiveKey}>
        { 
          (this.state.responseListEmpty && ((credentialsPermissions.cloud.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.cloud.recurring && this.props.vTerminal.isAutopay))) && (this.state.responseList.length < 1 ?
          (<Card className="card card-in mb-3 card-hover">
            <Accordion.Toggle as={Card.Header}  className="card-body">
                <div className="row">
                    <div className="col-2 text-center">
                    <div id="div-loading-layer" className="d-flex justify-content-center align-items-center" style={{width: '44px', marginTop: '3px'}}>
                      <div className="spinner-border" role="status"></div>
                    </div>
                    </div>
                    <div className="col-10">
                        Device / Terminal
                        <p className="small small-grey-m0">
                        Loading devices for selection...
                        </p>
                    </div>
                </div>
            </Accordion.Toggle>
          </Card>) : 
          (<Card className="card card-in mb-3 card-hover">
            <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="3" onClick={(e) => this.setPaymentMethod('device', "3")}>
                <div className="row">
                    <div className="col-2 text-center pt-1">
                        {this.props.global.getGlobalImg('pointofsale', '30px')}
                    </div>
                    <div className="col-10">
                        Device / Terminal
                        <p className="small small-grey-m0">
                        Tap, dip, or swipe a debit or credit card
                        </p>
                    </div>
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
            <Card.Body>
              <div className="form-floating">
                  <select className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsDeviceError ? "form-select form-control input-error" : "form-select form-control" } id="device" name="device" onChange={(e) => this.handleChangeDeviceSelect(e)}>
                      {(this.state.responseList.length !== 1) &&
                      <option value="">Select...</option>
                      }                                     
                      { this.state.responseList.map((record, i) => (
                              <option className={record.deviceNickName} value={record.deviceId+"|||"+record.deviceNickName}>{record.deviceNickName} - {record.make} {record.model}</option>
                          ))
                      }
                  </select>
                  <label htmlFor="device">Device Description & Model</label>
              </div>
            </Card.Body>
            </Accordion.Collapse>
          </Card>))
        }

        {this.props.vTerminal.hasECheck &&
          ((credentialsPermissions.ach.onetime && !this.props.vTerminal.isAutopay) ||
            (credentialsPermissions.ach.recurring && this.props.vTerminal.isAutopay)) && (
            <Card className="card card-in mb-3 card-hover">
              <Accordion.Toggle
                as={Card.Header}
                className="card-body"
                eventKey="1"
                onClick={(e) => this.setPaymentMethod('ach', '1')}
              >
                <span className="badge bg-dark card-info-badge">Most popular method</span>
                <div className="row">
                  <div className="col-2 text-center pt-1">
                    {this.props.global.getGlobalImg('ach', '30px')}
                  </div>
                  <div className="col-10">
                    Bank account / E-Check
                    <p className="small small-grey-m0">Pay from your Bank Account</p>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <PaymentMethodECheck history={this.props.history} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
        )}

          {this.props.vTerminal.hasCards &&
            ((credentialsPermissions.card.onetime && !this.props.vTerminal.isAutopay) ||
              (credentialsPermissions.card.recurring && this.props.vTerminal.isAutopay)) && (
              <Card className="card card-in mb-3 card-hover">
                <Accordion.Toggle
                  as={Card.Header}
                  className="card-body"
                  eventKey="0"
                  onClick={(e) => this.setPaymentMethod('card', '0')}
                >
                  <div className="row">
                    <div className="col-2 text-center pt-1">
                      {this.props.global.getGlobalImg('card', '30px')}
                    </div>
                    <div className="col-10">
                      Credit or Debit Card
                      <p className="small small-grey-m0">Use your Credit or Debit Card</p>
                      <div className="card-brands accordion-right-corner" style={{position: 'absolute'}}>
                        <PaymentMethodsAccepted />
                      </div>
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <PaymentMethodCCForm history={this.props.history} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}

            { (this.props.vTerminal.hasCheck && (credentialsPermissions.check.onetime && !this.props.vTerminal.isAutopay)) &&
              <Card className="card card-in mb-3 card-hover">
                  <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod('check', "4")}>
                      <div className="row">
                          <div className="col-2 text-center pt-1">
                            {this.props.global.getGlobalImg('check', '30px')}
                          </div>
                          <div className="col-10">
                              Check Payment
                              <p className="small small-grey-m0">Record a Physical Check payment</p>
                          </div>
                      </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                      <Card.Body>
                          <PaymentMethodCheck history={this.props.history} />
                      </Card.Body>
                  </Accordion.Collapse>
              </Card>
            }

            { (this.props.vTerminal.hasCash && (credentialsPermissions.cash.onetime && !this.props.vTerminal.isAutopay)) &&
              <Card className="card card-in mb-3 card-hover" style={this.props.vTerminal.paymentMethod && this.props.vTerminal.paymentMethod === 'cash' ? {boxShadow: '0px 0px 2px 0.5px #2196f3'} : {}}>
                  <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod('cash', "5")}>
                      <div className="row">
                          <div className="col-2 text-center pt-1">
                            {this.props.global.getGlobalImg('cash', '30px')}
                          </div>
                          <div className="col-10">
                              Cash
                              <p className="small small-grey-m0">Record a cash payment</p>
                          </div>
                      </div>
                  </Accordion.Toggle>
              </Card>
            }

        </Accordion>

        <div className="review-total mt-5">
          <h6 className="sub-header-line mb-3">Review & Confirm Payment</h6>
          <div className="row">
            <div className="col">Net Amount:</div>
            <div className="col">
              $
              {this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['netAmount']).toFixed(2))}
            </div>
          </div>
          <div className="row">
            <div className="col">Fee:</div>
            <div className="col">
              ${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['fee']).toFixed(2))}
            </div>
          </div>

          <div className="row total-amount">
            <div className="col">Total Amount:</div>
            <div className="col">
              $
              {this.props.global.numberWithCommas(
                parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChargeCustomer;
