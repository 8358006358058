import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportsLinks } from '../../components/ReportsLinks';
import { ReportPaginator } from '../../components/ReportPaginator';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { Layout } from '../../components/Layout'
import { Link } from 'react-router-dom';
import { RightPanel } from '../../components/RightPanel';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { Modal, Button } from "react-bootstrap";
import { AutopayForm } from '../../components/AutopayForm';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { PaypointLinks } from '../../components/PaypointLinks';
import { CustomerLinks } from '../../components/CustomerLinks';
import {BiNotepad} from '@react-icons/all-files/bi/BiNotepad';
import {BiTrash} from '@react-icons/all-files/bi/BiTrash';
import {BiPause} from '@react-icons/all-files/bi/BiPause';
import {BiPlay} from '@react-icons/all-files/bi/BiPlay';
import {BiX} from '@react-icons/all-files/bi/BiX';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { MainBar } from '../../components/MainBar';
import { BiSortAlt2, BiLinkExternal, BiDotsVerticalRounded } from 'react-icons/bi';

@inject('reports', 'global', 'vTerminal', 'entry', 'customer')
@observer
class AutopayReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: "",
            sortDirection: "desc",
            vTerminalAutopay: 1,
            cancelAutopayModalIsOpen: false,
            pauseAutopayModalIsOpen: false,
            continueAutopayModalIsOpen: false,
            updateAutopayModalIsOpen: false,
            customerId: null,
            autopayIdToModify: null,
            autopayToUpdate: null,
            autopayToUpdateAmount: 0,
            autopayCategories: null,
            autopayAmountError: false,
            customFields: [],
            entry: null,
            idPaypoint: null,
            currentPaypointName: '',
            actionAutopayRemoveItem: null,
            flagItemAllColumn: true,
            currentCustomer: null
        };
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.openCancelAutopayModal = this.openCancelAutopayModal.bind(this);
        this.closeCancelAutopayModal = this.closeCancelAutopayModal.bind(this);
        this.cancelAutopayAction = this.cancelAutopayAction.bind(this);
        this.openPauseAutopayModal = this.openPauseAutopayModal.bind(this);
        this.closePauseAutopayModal = this.closePauseAutopayModal.bind(this);
        this.pauseAutopay = this.pauseAutopay.bind(this);
        this.pauseAutopayAction = this.pauseAutopayAction.bind(this);
        this.continueAutopayAction = this.continueAutopayAction.bind(this);
        this.openContinueAutopayModal = this.openContinueAutopayModal.bind(this);
        this.closeContinueAutopayModal = this.closeContinueAutopayModal.bind(this);
        this.continueAutopay = this.continueAutopay.bind(this);
        this.closeUpdateAutopayModal = this.closeUpdateAutopayModal.bind(this);
        this.updateAutopay = this.updateAutopay.bind(this);
        this.validateEditAutopayFields = this.validateEditAutopayFields.bind(this);
        this.updateAutopayAction = this.updateAutopayAction.bind(this);
        this.handleAutopayAmount = this.handleAutopayAmount.bind(this);
        this.getCustomFieldsFromApi = this.getCustomFieldsFromApi.bind(this);
        this.focus = this.focus.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.handleAutopayAmountCategories = this.handleAutopayAmountCategories.bind(this);
        this.handleAutopayQtyCategories = this.handleAutopayQtyCategories.bind(this);
        this.handleAutopayLineMouseOver = this.handleAutopayLineMouseOver.bind(this);
        this.handleAutopayLineMouseLeave = this.handleAutopayLineMouseLeave.bind(this);
        this.handleAutopayLineRemoveCategory = this.handleAutopayLineRemoveCategory.bind(this);
        this.handleAutopayLineMouseOverUp = this.handleAutopayLineMouseOverUp.bind(this);
        this.handleAutopayLineMouseLeaveUp = this.handleAutopayLineMouseLeaveUp.bind(this);
        this.sortBy = this.sortBy.bind(this);
    }
    
    componentDidMount() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let idOrg = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId;
        this.setState({ 
            customerId: this.props.match.params.id ? this.props.match.params.id : null,
            entry: this.props.match.params.entry ? this.props.match.params.entry : null,
            idPaypoint: this.props.match.params.idPaypoint ? this.props.match.params.idPaypoint : null,
            orgId: idOrg
        });

        if(this.props.match.params.id){
            this.props.customer.getCustomerFromApi(this.props.match.params.id).then(res => {
                this.setState({currentCustomer:res.data});
            })
            .catch(error => {
                toast.error("Something is Wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }else{
            this.setState({currentCustomer:null});
        }

        if(this.props.match.params.entry){
            this.props.entry.getEntryFromApi(this.props.match.params.entry).then(res => {
                this.setState({currentPaypointName:res.responseData.Paypoint.LegalName});
            })
            .catch(error => {
                toast.error("Something is Wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }

        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setRightPanelOpen(false);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
        {
            nextDate          : { label:'Next Draft on', class: '', display: true }, 
            startDate         : { label:'Start Date', class: '', display: false }, 
            endDate           : { label:'End Date', class: '', display: false },
            frequency         : { label:'Frequency', class: '', display: true }, 
            leftCycles        : { label:'# Remaining', class: '', display: true}, 
            idSub             : { label:'Autopay ID', class: '', display: false}, 
            parentOrgName     : { label:'Organization', class: '', display: false },
            paypointLegalname : { label:'Paypoint', class: '', display: true },
            paypointDbaname   : { label:'DBA Name', class: '', display: false},
            holderName        : { label:'Customer', class: '', display: true }, 
            companyName       : { label:'Company', class: '', display: true },
            customerNumber    : { label:'Customer #', class: '', display: false },
            billingEmail      : { label:'Email', class: '', display: false }, 
            payorPhone        : { label:'Phone #', class: '', display: false },
            billingAddress    : { label:'Billing Address', class: '', display: false },
            shippingAddress   : { label:'Shipping Address', class: '', display: false },
            accountType       : { label:'Pay Method', class: 'text-center', display: false }, 
            maskedAccount     : { label:'Last 4', class: '', display: true }, 
            subStatus         : { label:'Status', class: '', display: true }, 
            amount            : { label:'Amount', class: 'text-right', display: true }, 
            feeAmount         : { label:'Fee', class: 'text-right', display: false }, 
            totalAmount       : { label:'Total Amount', class: 'text-right', display: false }, 
	        currency          : { label:'Currency', class: 'text-center', display: false },
            notes             : { label:'Notes', class: 'text-center', display: false},
            createdAt         : { label:'Created on', class: '', display: false},
            lastUpdatedOn     : { label:'Last Updated On', class: '', display: false },
        }
        )
        this.getCustomFieldsFromApi();
        this.props.vTerminal.resetPaymentPage();
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    getCustomFieldsFromApi(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let idOrg = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId;
        let reactObj = this;
        this.props.reports.getOrgSettings(idOrg).then(res => {
            if(res.data.customFields){
                let fields = res.data.customFields;
                fields.forEach(function (item, index) {
                    reactObj.props.reports.addHeader(item.key ,{ label:item.key, class: '', display: true, custom: true});
                });
                this.setState({customFields:fields});
            }
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    focus(e){
        e.target.focus();
        e.target.setSelectionRange(0, 1000000000);
    }

    cancelAutopay(idAutopay){
        this.setState({ autopayIdToModify: idAutopay });
        this.openCancelAutopayModal();
    }
    
    pauseAutopay(idAutopay){
        this.setState({ autopayIdToModify: idAutopay });
        this.openPauseAutopayModal();
    }
    
    continueAutopay(record){
        this.setState({ autopayToModify: record });
        this.openContinueAutopayModal();
    }

    openCancelAutopayModal(){
        this.setState({ cancelAutopayModalIsOpen: true });
    }
    
    closeCancelAutopayModal(){
        this.setState({ cancelAutopayModalIsOpen: false });
    }
    
    updateAutopay(autopay){
        let nextDate = new Date(autopay.NextDate);
        if(nextDate <= new Date()){
            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.props.vTerminal.handleAutopayStartDate(tomorrow);
            this.props.vTerminal.handleAutopayEndDate(new Date(tomorrow))
        }
        else{
            this.props.vTerminal.handleAutopayStartDate(new Date(autopay.NextDate));
        }
        this.props.vTerminal.handleAutopayFrequency(autopay.Frequency);
        this.props.vTerminal.handleAutopayEndDate(autopay.EndDate)
        
        this.setState(
            { 
                autopayToUpdate: autopay, 
                autopayToUpdateAmount: autopay.TotalAmount,
                autopayCategories: autopay.PaymentData.paymentDetails.categories,
            }, 
            () => {
                this.openUpdateAutopayModal();
            }
        );
    }

    closeUpdateAutopayModal(){
        this.setState({ updateAutopayModalIsOpen: false });
    }
    
    openUpdateAutopayModal(){
        this.setState({ updateAutopayModalIsOpen: true });
    }
    
    openPauseAutopayModal(){
        this.setState({ pauseAutopayModalIsOpen: true });
    }
    
    openContinueAutopayModal(){
        this.setState({ continueAutopayModalIsOpen: true });
    }
    
    closePauseAutopayModal(){
        this.setState({ pauseAutopayModalIsOpen: false });
    }
    
    closeContinueAutopayModal(){
        this.setState({ continueAutopayModalIsOpen: false });
    }

    cancelAutopayAction(){
        let autopayIdToDelete = this.state.autopayIdToModify;
        if(autopayIdToDelete){
            this.props.global.setLoading(true);
            this.props.reports.cancelAutopay(autopayIdToDelete)
            .then(result => {
                this.setState({ cancelAutopayModalIsOpen : false });
                this.props.global.setLoading(false);
                toast.success("Autopay canceled successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }
    
    pauseAutopayAction(){
        let autopayIdToPause = this.state.autopayIdToModify;
        if(autopayIdToPause){
            this.props.global.setLoading(true);
            this.props.reports.pauseAutopay(autopayIdToPause, true)
            .then(result => {
                this.setState({ pauseAutopayModalIsOpen : false });
                this.props.global.setLoading(false);
                toast.success("Autopay paused successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    continueAutopayAction(){    
        let autopayNextDate = new Date(this.state.autopayToModify.NextDate);
        let autopayEndDate = new Date(this.state.autopayToModify.EndDate);
        let autopayFrequency = this.state.autopayToModify.Frequency;
        let autopayIdToContinue = this.state.autopayToModify.IdSub;
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        if(autopayNextDate && autopayEndDate && autopayFrequency){
            let autopayStartDate = autopayNextDate >= tomorrow ? autopayNextDate: tomorrow;
            this.props.global.setLoading(true);
            this.props.reports.pauseAutopay(autopayIdToContinue, false, autopayStartDate, autopayEndDate, autopayFrequency)
            .then(result => {
                this.setState({ continueAutopayModalIsOpen : false });
                this.props.global.setLoading(false);
                toast.success("Autopay running successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    exportFile(format) {
        this.props.reports.exportOrgFile('subscriptions', format, this.state.orgId);
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    clearFilters(){
        this.setState({ head: "" }, () => {
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    clearFilter(e, filter, filterType){
        e.preventDefault();
        this.setState({ [filterType]: {} });
        this.filter(filter,"");
    }

    getMappingFields(){
        return {
            'startDate': 'startdate',
            'endDate': 'enddate',
            'nextDate': 'nextdate',
            'frequency': 'frequency',
            'accountType': 'method',
            'totalAmount': 'totalamount',
            'amount': 'netamount',
            'feeAmount': 'feeamount',
            'subStatus': 'status',
            'customerNumber': 'customernumber',
            'holderName': 'customerlastname',
            'billingAddress': 'customeraddress',
            'payorPhone': 'customerphone',
            'billingEmail': 'customeremail',
            'shippingAddress': 'customershippingaddress',
            'paypointLegalname': 'paypointlegal',
            'paypointDbaname': 'paypointdba',
            'parentOrgName': 'orgname',
            'idSub': 'idsub',
            'createdAt': 'createdat',
            'lastUpdatedOn': 'lastupdate',
            'companyName': 'companyname',
            'maskedAccount': 'lastfour',
            'leftCycles': 'leftcycles',
        }
    }
    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }
    sortBy(e, record){
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }
    getReportFromApi(){
        let tempFilterValue = {};
        if(this.state.sortBy){
            tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`;
        }
        if(this.state.customerId){
            tempFilterValue['customerId(eq)'] = this.state.customerId;
        }
        if(this.state.idPaypoint){
            tempFilterValue['paypointId(eq)'] = this.state.idPaypoint;
        }
        this.filter(tempFilterValue);
    }

    handleQuickFilter(value, e){
        this.filter('status(eq)',value)
        this.setState({ head: e.target.id });
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'subscriptions').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    handleAutopayAmount(value){
        this.setState({ autopayToUpdateAmount: value });
    }

    viewRecordDetails(index, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            let thisObj = this;
            if(thisObj.props.global.rightPanelOpen && thisObj.props.reports.recordDetails !== index){
                thisObj.props.global.setRightPanelOpen(false);
                thisObj.props.reports.setRecordDetails(index);
                setTimeout(function(){
                    thisObj.props.global.setRightPanelOpen(true);
                }, 500)                
            }else{
                thisObj.props.reports.setRecordDetails(index);
                thisObj.props.global.setRightPanelOpen(true);
            }
        }
    }
    
    validateEditAutopayFields(){
        let validators = this.props.global.validators;
        let paymentPage = this.props.vTerminal.paymentPage;

        if(validators.isEmpty(paymentPage.autopay.startDate) || validators.isMaxLength(250, paymentPage.autopay.startDate))
        {
            this.props.vTerminal.setPaymentPageError('autopayStartDateError',true);
        }
        else{
            this.props.vTerminal.setPaymentPageError('autopayStartDateError',false);
        }

        if(validators.isEmpty(paymentPage.autopay.frequencySelected) || validators.isMaxLength(250, paymentPage.autopay.frequencySelected))
        {
            this.props.vTerminal.setPaymentPageError('autopayFrequencyError',true);
        }
        else{
            this.props.vTerminal.setPaymentPageError('autopayFrequencyError',false);
        }

        if(validators.isEmpty(paymentPage.autopay.finishSelected) || validators.isMaxLength(250, paymentPage.autopay.finishSelected))
        {
            this.props.vTerminal.setPaymentPageError('autopayFinishError',true);
        }
        else{
            this.props.vTerminal.setPaymentPageError('autopayFinishError',false);
        } 
        
        if(this.state.autopayToUpdateAmount === 0)
        {
            this.props.vTerminal.setPaymentPageError('autopayAmountError',true);
        }
        else{
            this.props.vTerminal.setPaymentPageError('autopayAmountError',false);
        }
    }

    updateAutopayAction(){
        this.validateEditAutopayFields();
        if(!this.props.vTerminal.hasPaymentPageErrors()){
            this.props.global.setLoading(true);
            this.props.vTerminal.updateAutopay(this.state.autopayToUpdateAmount, this.state.autopayToUpdate)
            .then(result => {
                this.closeUpdateAutopayModal();
                this.props.global.setLoading(false);
                this.getReportFromApi();
                toast.success("Autopay updated successfully!", {
                    autoClose: 7000,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container data-qaid-autopaySuccessfullyAlert'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    handleAutopayAmountCategories(amount, idx){
        let categories = this.state.autopayCategories;              
        if (amount > 0) {
            categories[idx].amount = amount;
        }else{
            categories[idx].amount = 1;
        }
        let valueAmount = 0;
        categories.map((data) => (
            valueAmount += data.amount * data.qty
        ));
        valueAmount += this.state.autopayToUpdate.FeeAmount;
        this.setState(
            {
                autopayToUpdateAmount: valueAmount
            }
        );
    }

    handleAutopayQtyCategories(qty, idx){
        let categories = this.state.autopayCategories;              
        categories[idx].qty = qty;
        let valueAmount = 0;
        categories.map((data) => (
            valueAmount += data.amount * data.qty
        ));
        valueAmount += this.state.autopayToUpdate.FeeAmount;
        this.setState(
            {
                autopayToUpdateAmount: valueAmount
            }
        );
    }

    handleAutopayLineMouseOver(index){
        this.setState({
            actionAutopayRemoveItem: {
                [index]: true
            }
        });
    }

    handleAutopayLineMouseLeave(index){
        this.setState({
            actionAutopayRemoveItem: {
                [index]: false
            }
        });
    }

    handleAutopayLineMouseOverUp(e){
        e.target.focus();
    }
    
    handleAutopayLineMouseLeaveUp(e){
        e.target.blur();
    }

    handleAutopayLineRemoveCategory(index){
        let categories = this.state.autopayCategories;              
        if (index > -1) {
            categories.splice(index, 1);
        }
        let valueAmount = 0;
        categories.map((data) => (
            valueAmount += data.amount * data.qty
        ));
        valueAmount += this.state.autopayToUpdate.FeeAmount;
        this.setState(
            {
                autopayToUpdateAmount: valueAmount
            }
        );
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        const recordDetail = this.props.reports.getRecordDetails;
        
        return (
            <Layout {...this.props}>
            <RightPanel>
                <h5 className="header mb-3" data-qaid="autopayMoneyViewDetails">Autopay Details</h5>
                <div className="small mb-5">
                        <div className="row">
                            <div className="col-4">
                                <label className="header">Frequency</label>
                            </div>
                            <div className="col-8">
                                {recordDetail && recordDetail.Frequency ? this.props.global.frequencyText(recordDetail.Frequency) : '-'}
                            </div>                            
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="header">Remaining</label>
                            </div>
                            <div className="col-8">
                                {(recordDetail && recordDetail.UntilCancelled === true) ?  "Until Cancelled" : ((recordDetail && recordDetail.LeftCycles) ? recordDetail.LeftCycles : "")}
                            </div>                            
                        </div>


                        <div className="row mb-4">
                            <div className="col-4">
                                <label className="header">Status</label>
                            </div>
                            <div className="col-8">
                                { this.props.global.autopayStatus(recordDetail?.SubStatus) }
                            </div>                            
                            <div className="col-4">
                                <label className="header">Start Date</label>
                            </div>
                            <div className="col-8">
                            { recordDetail && recordDetail.StartDate ? this.props.global.stringDateFormat(recordDetail.StartDate) : '-'}
                            </div>
                            <div className="col-4">
                                <label className="header">Next Draft on	</label>
                            </div>
                            <div className="col-8">
                            { recordDetail && recordDetail.NextDate ? this.props.global.stringDateFormat(recordDetail.NextDate) : '-'}
                            </div>                            
                        </div>

                        {(recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.paymentDetails && recordDetail.PaymentData.paymentDetails.categories) &&
                            <div className="col-12 mb-3">
                                {recordDetail.PaymentData.paymentDetails.categories.map((record, i) => (
                                    <div className="row mb-1" key={i}>
                                        <div className="col-4">{record.label}</div>
                                        <div className="col-3">${this.props.global.numberWithCommas(parseFloat(record.amount).toFixed(2))}</div>
                                        <div className="col-2">x {record.qty ? record.qty: 1}</div>
                                        <div className="col-3 text-right">${this.props.global.numberWithCommas(((record.qty ? record.qty: 1) * record.amount).toFixed(2))}</div>
                                    </div>
                                ))
                                }
                            </div>
                        }

                        <div className="col-12 mb-3">
                            <div className="row mb-1">
                                <div className="col-4">
                                    <b>Amount</b>
                                </div>
                                <div className="col-8 text-right">
                                    {(recordDetail && recordDetail.NetAmount) ? this.props.global.numberWithCommasNegative(recordDetail.NetAmount.toFixed(2)) : '-'}
                                </div>
                            </div>

                            <div className="row mb-1">
                                <div className="col-4">
                                    <b>Fee</b>
                                </div>
                                <div className="col-8 text-right">
                                    {(recordDetail && recordDetail.FeeAmount) ? this.props.global.numberWithCommasNegative(recordDetail.FeeAmount.toFixed(2)) : '$0.00'}
                                </div>
                            </div>

                            <div className="row mb-1">
                                <div className="col-4">
                                    <b>Total</b>
                                </div>
                                <div className="col-8 text-right">
                                <b>{(recordDetail && recordDetail.TotalAmount) ? this.props.global.numberWithCommasNegative(recordDetail.TotalAmount.toFixed(2)) : '-'}</b>
                                </div>
                            </div>
                        </div>

                        <h5 className="header mb-3">Payment Information</h5>
                        {(recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.MaskedAccount && recordDetail.PaymentData.MaskedAccount.toLowerCase() === "poi") ?
                            <div className="card-v2 mb-3" style={{padding: "3em"}}>
                                <div className="row">
                                <div className="col-7">
                                    <label className="grey">Source</label>
                                    <h6 style={{fontWeight: 500}}>
                                        POI Device
                                    </h6>
                                </div>
                                <div className="col-5 text-right">
                                    {this.props.global.getGlobalImg('pointofsale', '30px')}
                                </div>
                                </div>
                            </div>
                        :
                            <div className="card-v2 mb-3" style={{padding: "3em"}}>
                                <div className="right-panel-card mb-4">
                                    {this.props.global.getGlobalImg((recordDetail?.PaymentData?.AccountType && recordDetail?.PaymentData?.AccountType?.toLowerCase() !== 'unknow' ? recordDetail?.PaymentData?.AccountType?.toLowerCase() : recordDetail?.Method), '45px')}
                                </div>
                                <div className="row mb-2">
                                    <div className="col-7">
                                        <label className="grey">{recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ? "Card Number" : "Account Number" }</label>
                                        <h5 style={{fontWeight: 500}}>
                                        •••• •••• {(recordDetail && recordDetail.PaymentData) ?  this.props.global.maskedCardNumber(recordDetail.PaymentData.MaskedAccount, 'v5') : '-'}
                                        </h5>
                                    </div>
                                    {recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ?
                                    <div className="col-5 text-center">
                                        <label className="grey">Expires on</label>
                                        <h5 style={{fontWeight: 500}}>
                                            {recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.AccountExp ? this.props.global.expDateFormat(recordDetail.PaymentData.AccountExp) : '-'}
                                        </h5>
                                    </div>
                                    :
                                    <div className="col-5 text-center">
                                        <label className="grey">Account Type</label>
                                        <h5 style={{fontWeight: 500}}>
                                            {recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.AccountType ? recordDetail.PaymentData.AccountType  : '-'}
                                        </h5>
                                    </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <label className="grey">{recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ? "Cardholder Name" : "Account Holder Name" } </label>
                                        <h6 style={{fontWeight: 500}}>
                                            {(recordDetail && recordDetail.PaymentData )?  recordDetail.PaymentData.HolderName : '-'}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="text-center mb-1">
                            &nbsp;
                        </div>
                        <h5 className="header mb-3">Customer Information
                            <OverlayTrigger placement="top" overlay={<Tooltip>View Customer</Tooltip>}>
                                <Link style={{color: '#333333'}} to={ recordDetail?.Customer ? '/'+this.props.global.getURLEntry() +"/customer/"+ recordDetail.Customer.customerId : ''}><BiLinkExternal/></Link>
                            </OverlayTrigger>
                        </h5>
                        <div className="row mb-4">
                            <div className="col-3">
                                <label className="header">Customer</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.Customer )? this.props.global.capitalizeFirstLetterOfEachWord((recordDetail.Customer.FirstName ? recordDetail.Customer.FirstName : "") + ' ' + (recordDetail.Customer.LastName ? recordDetail.Customer.LastName : "")) : '-'}
                            </div>
                        </div>


                    </div>
                
                
            </RightPanel>
            {userPermissions.indexOf("Autopay") !== -1 && 
            <Modal style={{textAlign: "center"}} show={this.state.cancelAutopayModalIsOpen} onHide={this.closeCancelAutopayModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiX className="icon-modal"/>
                    <h5 data-qaid="cancelAutopayMoneyInPopUp">Cancel</h5>
                    <p className="small">Are you sure you want to cancel this autopay?</p>
                    <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeCancelAutopayModal()} data-qaid="closeAutopayMoneyInButton">
                        Close
                    </Button>
                    &nbsp;&nbsp;
                    <Button className="btn" variant="danger" onClick={(e)=> this.cancelAutopayAction()}>
                        Cancel
                    </Button>
                </Modal.Body>
            </Modal>
            }

            {userPermissions.indexOf("Autopay") !== -1 && 
            <Modal style={{textAlign: "left"}} show={this.state.updateAutopayModalIsOpen} onHide={this.closeUpdateAutopayModal}  size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="popover-body popover-body-2">
                    <div className="mb-4">
                        <h6 className="mb-4" data-qaid="editAutopayMoneyInPopUp">Edit Autopay</h6>
                        <AutopayForm/>
                        { this.state.autopayToUpdate ? this.props.global.getPaymethodImg(this.state.autopayToUpdate.PaymentData.AccountType) : '' }&nbsp;&nbsp;
                        { this.state.autopayToUpdate ? this.props.global.maskedCardNumber(this.state.autopayToUpdate.PaymentData.MaskedAccount, "v3") : '' }
                        { this.state.autopayCategories
                            ? 
                            <div>
                                <div id="item-table">
                                    <div className="header p-right">
                                    <div className="col product" style={{"marginRight": "-7rem"}}>Product/service</div>
                                    <div className="col">Unit Price</div>
                                    <div className="col qty">Qty</div>
                                    <div className="col amount">Amount</div>
                                </div>
                                <div className="body">
                                    {this.state.autopayCategories.map((data, idx) => (
                                        <div 
                                            className="row-item p-right" 
                                            key={`item-${idx}`} 
                                            onMouseOver={()=>this.handleAutopayLineMouseOver(`item-${idx}`)} 
                                            onMouseLeave={()=>this.handleAutopayLineMouseLeave(`item-${idx}`)} 
                                            >
                                            <div className="details">
                                                <div className="col product" style={{"fontEeight": "normal"}}>
                                                {data.label ? data.label : "-"}
                                                </div>
                                                <div 
                                                    className="col unit-price"
                                                    onMouseOver={(e)=>this.handleAutopayLineMouseOverUp(e)} 
                                                    onMouseLeave={(e)=>this.handleAutopayLineMouseLeaveUp(e)} 
                                                >                                        
                                                <NumberFormat
                                                    thousandsGroupStyle="thousand"
                                                    decimalSeparator="."
                                                    displayType="input"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={data.amount}
                                                    className={ "form-control input-money input-amount-updautopay" }
                                                    onValueChange={(value) => this.handleAutopayAmountCategories(value.value, idx)}
                                                />
                                                </div>
                                                <div className="col qty">
                                                <button
                                                    className="btn btn-outline-light btn-sm"
                                                    type="button"
                                                    onClick={ () => {data.qty > 2 ? data.qty-- : data.qty = 1; this.handleAutopayQtyCategories(data.qty, idx)} }
                                                >
                                                    -
                                                </button>
                                                <span className="qty-value">
                                                    {data.qty}
                                                </span>
                                                <button
                                                    className="btn btn-outline-light btn-sm"
                                                    type="button"
                                                    onClick={() => {data.qty++; this.handleAutopayQtyCategories(data.qty, idx)}}
                                                >
                                                    +
                                                </button>
                                                </div>
                                                <div className="col amount" style={{"fontWeight": "normal"}}>
                                                $ {this.props.global.numberWithCommas(parseFloat(data.amount * data.qty).toFixed(2))}
                                                </div> 
                                                {
                                                    this.state.actionAutopayRemoveItem && this.state.actionAutopayRemoveItem[`item-${idx}`] &&
                                                    <div>
                                                        <span onClick={() => this.handleAutopayLineRemoveCategory(idx)}><BiTrash className="icon-trash-modal-autopay" /></span>
                                                    </div>
                                                }                                        
                                            </div>                                    
                                            {data.description && <div className="description">{data.description}</div>}                                        
                                        </div> 
                                    ))}            
                                    <div className="underline"></div>
                                    <div className="row-total p-right" style={{"fontWeight": "inherit"}}>
                                        <span className="text">Fee:</span>
                                        <span className="value">${this.props.global.numberWithCommas(parseFloat(this.state.autopayToUpdate.FeeAmount).toFixed(2))}</span>
                                    </div>                        
                                    <div className="row-total p-right">
                                        <span className="text">Total</span>
                                        <span className="value">${this.props.global.numberWithCommas(parseFloat(this.state.autopayToUpdateAmount).toFixed(2))}</span>
                                    </div>
                                </div>
                            </div>                            
                            </div>
                            : '' 
                        }
                    </div>
                    <button onClick={(e) => this.updateAutopayAction()} type="button" className="btn btn btn-primary full-w" data-qaid="updateAutopayMoneyIn">Update</button>
                </Modal.Body>
            </Modal>
            }
           
            {userPermissions.indexOf("Autopay") !== -1 && 
            <Modal style={{textAlign: "center"}} show={this.state.pauseAutopayModalIsOpen} onHide={this.closePauseAutopayModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiPause className="icon-modal"/>
                    <h5>Pause</h5>
                    <p className="small">Are you sure you want to pause this autopay?</p>
                    <Button className="btn" variant="primary" onClick={(e)=> this.pauseAutopayAction()}>
                        Pause Autopay
                    </Button>
                </Modal.Body>
            </Modal>
            }

            {userPermissions.indexOf("Autopay") !== -1 && 
            <Modal style={{textAlign: "center"}} show={this.state.continueAutopayModalIsOpen} onHide={this.closeContinueAutopayModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiPlay className="icon-modal"/>
                    <h5>Continue</h5>
                    <p className="small">Are you sure you want to continue this autopay?</p>
                    <Button className="btn" variant="primary" onClick={(e)=> this.continueAutopayAction()}>
                        Continue Autopay
                    </Button>
                </Modal.Body>
            </Modal>
            }
            <div>
                <ParentFilterPanel report={'subscriptions'} customFields={this.state.customFields} customerId={this.props.match.params.id} paypointId={this.props.match.params.idPaypoint} />
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    {this.state.entry ?
                    <PaypointLinks idPaypoint={this.state.idPaypoint ? this.state.idPaypoint : ''} entry={this.state.entry ? this.state.entry : ''} gobackLink={"/"+PayabliStorageManager.getEntryName()+"/paypoints/overview/"+ (this.state.entry ? this.state.entry : '')+"/"+(this.state.idPaypoint ? this.state.idPaypoint : '')} goback={true}  gobackText="Paypoints Overview" history={this.props.history} selected="subscriptions" />
                    :
                    this.state.customerId ?
                        <CustomerLinks gobackLink={'/'+this.props.global.getURLEntry()+'/customer/'+this.state.customerId} gobackText="Customer Overview" history={this.props.history} goback={true} selected="autopays" id={this.state.customerId} />
                        :
                        <ReportsLinks id={this.state.customerId} selected="autopays" history={this.props.history} goback={this.state.customerId ? true : false}/>
                    }
                </div>
                </TopBar>                

                <div className="mt-body4">
                <MainBar
                    reportTitle={this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : "All Autopays"}
                    reportName="autopaysReport"
                    dataQAName="AutopaysMoneyIn"
                    showHideFilters={this.showHideFilters}
                    selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                    handleShowColumn={(e) => this.handleShowColumn(e)}
                    totalRecords={this.props.reports.totalRecords}
                    getHeaders={this.props.reports.getHeaders}
                    headers={this.props.reports.headers}
                    refreshView={this.clearFilters}
                    buttonExport={true}
                    searchBar={false}
                    exportFile={this.exportFile}
                    masterName={this.props.match.params.idPaypoint && `Paypoint - ${this.state.currentPaypointName}`}
                    subMasterName={this.state?.currentCustomer && `Customer - ${this.props.global.capitalizeFirstLetterOfEachWord(this.state?.currentCustomer?.Firstname)} ${this.props.global.capitalizeFirstLetterOfEachWord(this.state?.currentCustomer?.Lastname)}`}
                    withTotal={true}
                    totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                    flagItemAllColumn={this.state.flagItemAllColumn}
                />
                
                <div className="report-container">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (                            
                            (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                            record[1].display && 
                            (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                            </th> ))
                            ||
                            (record[1].display && 
                            (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                            </th> ))
                                
                        ))
                    }
                    <th className='text-center sticky-row' scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>                
                { this.props.reports.records.map((record, i) => (
                        <tr key={i} className="cursorPointer" onClick={(e) => this.viewRecordDetails(i,e)} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} data-qaid={"autopayMoneyInRow-"+(i)}>
                            { (this.props.reports.headers.nextDate && this.props.reports.headers.nextDate.display) &&
                                <td>{this.props.global.stringDateFormatV3(record.NextDate)}</td>
                            }
                            { (this.props.reports.headers.startDate && this.props.reports.headers.startDate.display) &&
                                <td>{this.props.global.stringDateFormatV3(record.StartDate)}</td>
                            }
                            { (this.props.reports.headers.endDate && this.props.reports.headers.endDate.display) &&
                                <td>{this.props.global.stringDateFormatV3(record.EndDate)}</td>
                            }
                            { (this.props.reports.headers.frequency && this.props.reports.headers.frequency.display) &&
                                <td>{this.props.global.frequencyText(record.Frequency)}</td>
                            }
                            { (this.props.reports.headers.leftCycles && this.props.reports.headers.leftCycles.display) && 
                                <td>{record.UntilCancelled === true ? "Until Cancelled" :record.LeftCycles}</td>
                            }
                            { (this.props.reports.headers.idSub && this.props.reports.headers.idSub.display) && 
                                <td>{record.IdSub}</td>
                            }
                            { (this.props.reports.headers.parentOrgName && this.props.reports.headers.parentOrgName.display) &&
                                <td>{record && record.ParentOrgName ? this.props.global.capitalizeFirstLetterOfEachWord(record.ParentOrgName): '-'}</td>
                            }                            
                            { (this.props.reports.headers.paypointLegalname && this.props.reports.headers.paypointLegalname.display) &&
                                <td>{record && record.PaypointLegalname ? this.props.global.capitalizeFirstLetterOfEachWord(record.PaypointLegalname): '-'}</td>
                            }
                            { (this.props.reports.headers.paypointDbaname && this.props.reports.headers.paypointDbaname.display) &&
                                <td>{record && record.PaypointDbaname ? this.props.global.capitalizeFirstLetterOfEachWord(record.PaypointDbaname): '-'}</td>
                            }
                            { (this.props.reports.headers.holderName && this.props.reports.headers.holderName.display) &&
                                <td>{record.Customer && record.Customer.FirstName && record.Customer.LastName ? this.props.global.capitalizeFirstLetterOfEachWord(record.Customer.FirstName + ' ' + record.Customer.LastName) : '-' }</td>
                            }
                            { (this.props.reports.headers.companyName && this.props.reports.headers.companyName.display) &&
                                <td>{record.Customer && record.Customer.CompanyName ? this.props.global.capitalizeFirstLetterOfEachWord(record.Customer.CompanyName) : '-'} </td>
                            }
                            { (this.props.reports.headers.customerNumber && this.props.reports.headers.customerNumber.display) &&
                                <td>{record.Customer && record.Customer.CustomerNumber ? record.Customer.CustomerNumber.length > 15 ? record.Customer.CustomerNumber.substring(0, 15) + "..." : record.Customer.CustomerNumber : '-'} </td>
                            }
                            { (this.props.reports.headers.billingEmail && this.props.reports.headers.billingEmail.display) &&
                                <td>{record.Customer && record.Customer.BillingEmail ? record.Customer.BillingEmail : '-'} </td>
                            }                            
                            { (this.props.reports.headers.payorPhone && this.props.reports.headers.payorPhone.display) &&
                                <td>{record.Customer && record.Customer.BillingPhone ? this.props.global.phoneNumberFormat(record.Customer.BillingPhone): '-'} </td>
                            }                           
                            { (this.props.reports.headers.billingAddress && this.props.reports.headers.billingAddress.display) &&
                                <td>{record.Customer && record.Customer.BillingAddress1 ? record.Customer.BillingAddress1 +" "+ record.Customer.BillingAddress2+" "+ record.Customer.BillingCity+" "+ record.Customer.BillingState+" "+ record.Customer.BillingZip+" "+ record.Customer.BillingCountry: '-'} </td>
                            }                            
                            { (this.props.reports.headers.shippingAddress && this.props.reports.headers.shippingAddress.display) &&
                                <td>{record.Customer && record.Customer.ShippingAddress1 ? record.Customer.ShippingAddress1 +" "+ record.Customer.ShippingAddress2+" "+ record.Customer.ShippingCity+" "+ record.Customer.ShippingState+" "+ record.Customer.ShippingZip+" "+ record.Customer.ShippingCountry: '-'} </td>
                            }
                            { (this.props.reports.headers.accountType && this.props.reports.headers.accountType.display) && 
                                <td className='text-center'>
                                     {this.props.global.getPaymethodImgPaymentData(record)}
                                </td>
                            }
                            { (this.props.reports.headers.maskedAccount && this.props.reports.headers.maskedAccount.display) && 
                                <td>{ this.props.global.maskedCardNumber(record.PaymentData.MaskedAccount, 'v5')}</td>
                            }
                            { (this.props.reports.headers.subStatus && this.props.reports.headers.subStatus.display) && 
                                <td>{this.props.global.autopayStatus(record.SubStatus)}</td>
                            }
                            { (this.props.reports.headers.amount && this.props.reports.headers.amount.display) && 
                                <td className='text-right'>${this.props.global.numberWithCommas(record.NetAmount.toFixed(2))}</td>
                            }
                            { (this.props.reports.headers.feeAmount && this.props.reports.headers.feeAmount.display) && 
                                <td className='text-right'>${this.props.global.numberWithCommas(record.FeeAmount.toFixed(2))}</td>
                            }                                                        
                            { (this.props.reports.headers.totalAmount && this.props.reports.headers.totalAmount.display) && 
                                <td className='text-right'>${this.props.global.numberWithCommas(record.TotalAmount.toFixed(2))}</td>
                            }
                            { (this.props.reports.headers.currency && this.props.reports.headers.currency.display) && 
                                <td className='text-center'>{record.PaymentData ? record.PaymentData.paymentDetails.currency ? record.PaymentData.paymentDetails.currency : '-' : '-'}</td>
                            }                            
                            { (this.props.reports.headers.notes && this.props.reports.headers.notes.display) && 
                                <td className='text-center'>
                                    {record.PaymentData && record.PaymentData.orderDescription ? 
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{record.PaymentData.orderDescription}</Tooltip>}
                                        >
                                        <BiNotepad className="label-info-icon in-input"/>
                                        </OverlayTrigger>
                                    : '-'}
                                </td>
                            }
                            {(this.props.reports.headers.createdAt && this.props.reports.headers.createdAt.display) &&
                                <td>{record.Customer && record.Customer.CreatedAt ? this.props.global.stringDateFormatV3(record.Customer.CreatedAt) : '-'} </td>
                            }
                            {(this.props.reports.headers.lastUpdatedOn && this.props.reports.headers.lastUpdatedOn.display) &&
                                <td>{this.props.global.stringDateFormatV3(record.LastUpdated)}</td>
                            }
                            {
                            this.state.customFields.map((field, i) => (
                            (this.props.reports.headers[field.key] && this.props.reports.headers[field.key].display) && 
                                <td key={i}>{record.Customer && record.Customer.AdditionalData && record.Customer.AdditionalData[field.key] ? record.Customer.AdditionalData[field.key] : '-'}</td>
                            ))
                            }
                            <td className='text-center sticky-row'>
                                <DropdownButton
                                    menuAlign="right"
                                    title={<BiDotsVerticalRounded/>}
                                    data-qaid="actionAutopayMoneyIn"
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                >
                                {userPermissions.indexOf("Autopay") !== -1 && 
                                <Dropdown.Item onClick={ (e) => this.updateAutopay(record) }  data-qaid="editAutopayMoneyIn">Edit Autopay</Dropdown.Item>
                                }
                                
                                {userPermissions.indexOf("Autopay") !== -1 && 
                                    <>
                                    {record.SubStatus === 1 &&
                                    <Dropdown.Item onClick={(e)=> this.pauseAutopay(record.IdSub)}>Pause Autopay</Dropdown.Item>
                                    }
                                    </>
                                }
                                
                                {userPermissions.indexOf("Autopay") !== -1 && 
                                <>
                                    {record.SubStatus === 0 &&
                                    <Dropdown.Item onClick={(e)=> this.continueAutopay(record)}>Continue Autopay</Dropdown.Item>
                                    } 
                                </>
                                }
                            
                                {userPermissions.indexOf("Autopay") !== -1 && 
                                <Dropdown.Item style={{color: "rgb(192, 0, 0)"}} onClick={(e)=> this.cancelAutopay(record.IdSub)} data-qaid="cancelAutopayMoneyIn">Cancel Autopay</Dropdown.Item>
                                }
                                <Dropdown.Divider />
                                {userPermissions.indexOf("Customer") !== -1 && 
                                <Dropdown.Item as={Link} to={record && record.Customer ? "/customer/"+ record.Customer.customerId : ""}> View Customer</Dropdown.Item>
                                }
                                {userPermissions.indexOf("Autopay") !== -1 && 
                                <Dropdown.Item onClick={(e)=> this.viewRecordDetails(i)} data-qaid="autopayMoneyViewAutopayLink"><div>View Autopay</div></Dropdown.Item>
                                }
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }                
                </tbody>
                </table>
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No scheduled payments yet" description={<span>When your customers schedule payments,<br/> you can track their frequency and draft date here.</span>}/>
                }
                </div>
                <ReportPaginator report="subscriptions"/>

                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { AutopayReport };
