import React, { useEffect } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import { BiCreditCardFront, BiRepost, BiEditAlt, BiDollarCircle, BiDesktop, BiWallet } from 'react-icons/bi'
import { BsCreditCard, BsCloud } from 'react-icons/bs'
import BillingServiceCard from './BillingServiceCard'
import { Link, scroller } from 'react-scroll'

export default function BillingServiceType({ element, changeTabServices, ServicesType }) {
	const servicesCard = { moneyin: ['card', 'ach', 'check', 'cash', 'cloud', 'wallet'], moneyout: ['managed', 'ach', 'vcard', 'check', 'pushToCard'] }
	const cardProperties = {
		moneyin: {
			card: { title: 'Credit Card', element: 'CreditCard', icon: <BsCreditCard className={styles['icon26'] + ' ' + styles['color-blue']} /> },
			ach: { title: 'ACH', element: 'ACHIn', icon: <BiRepost className={styles['icon26'] + ' ' + styles['color-blue']} /> },
			check: { title: 'Check', element: 'Check', icon: <BiEditAlt className={styles['icon26'] + ' ' + styles['color-blue']} /> },
			cash: { title: 'Cash', element: 'Cash', icon: <BiDollarCircle className={styles['icon26'] + ' ' + styles['color-blue']} /> },
			cloud: { title: 'Cloud Devices', element: 'CloudDevices', icon: <BsCloud className={styles['icon26'] + ' ' + styles['color-blue']} /> },
			wallet: { title: 'Wallet', element: 'Wallet', icon: <BiWallet className={styles['icon26'] + ' ' + styles['color-blue']} /> }
		},
		moneyout: {
			managed: { title: 'Managed Payables', element: 'ManagedPayAbles', icon: <BsCreditCard className={styles['icon26'] + ' ' + styles['color-blue']} /> },
			ach: { title: 'ACH', element: 'ACHOut', icon: <BiRepost className={styles['icon26'] + ' ' + styles['color-blue']} /> },
			vcard: { title: 'Virtual Card', element: 'vcard', icon: <BiDesktop className={styles['icon26'] + ' ' + styles['color-blue']} /> },
			check: { title: 'Physical Check', element: 'PhysicalCheck', icon: <BiEditAlt className={styles['icon26'] + ' ' + styles['color-blue']} /> },
			pushToCard: { title: 'Push To Card', element: 'PushToCard', icon: <BiCreditCardFront className={styles['icon26'] + ' ' + styles['color-blue']} /> }
		}
	}

	useEffect(() => {
		goTo(element)
	})
	const goTo = (element) => {
		if (element) {
			scroller.scrollTo(element, {
				offset: element === 'CreditCard' ? -370 : -350
			})
			changeTabServices('')
		}
	}

	return (
		<>
			<div className={styles['btn-panels'] + ' ' + styles['services-moneyIn']}>
				<Link
					className={styles['btn-tabs']}
					activeClass={styles['active']}
					to={ServicesType === 'moneyin' ? 'CreditCard' : 'ManagedPayAbles'}
					spy={true}
					smooth={true}
					exact="true"
					offset={-370}
					duration={100}>
					<BsCreditCard className={styles['icon']} />
					<span>{ServicesType === 'moneyin' ? 'Credit Card' : 'Managed Payables'}</span>
				</Link>
				<Link
					className={styles['btn-tabs']}
					activeClass={styles['active']}
					to={ServicesType === 'moneyin' ? 'ACHIn' : 'ACHOut'}
					spy={true}
					smooth={true}
					exact="true"
					offset={-350}
					duration={100}>
					<BiRepost className={styles['icon']} />
					<span>ACH</span>
				</Link>
				<Link
					className={styles['btn-tabs']}
					activeClass={styles['active']}
					to={ServicesType === 'moneyin' ? 'Check' : 'vcard'}
					spy={true}
					smooth={true}
					exact="true"
					offset={-350}
					duration={100}>
					{ServicesType === 'moneyin' ? <BiEditAlt className={styles['icon']} /> : <BiDesktop className={styles['icon']} />}
					<span>{ServicesType === 'moneyin' ? 'Check' : 'Virtual Card'}</span>
				</Link>
				<Link
					className={styles['btn-tabs']}
					activeClass={styles['active']}
					to={ServicesType === 'moneyin' ? 'Cash' : 'PhysicalCheck'}
					spy={true}
					smooth={true}
					exact="true"
					offset={-350}
					duration={100}>
					{ServicesType === 'moneyin' ? <BiDollarCircle className={styles['icon']} /> : <BiEditAlt className={styles['icon']} />}
					<span>{ServicesType === 'moneyin' ? 'Cash' : 'Physical Check'}</span>
				</Link>
				<Link
					className={styles['btn-tabs']}
					activeClass={styles['active']}
					to={ServicesType === 'moneyin' ? 'CloudDevices' : 'PushToCard'}
					spy={true}
					smooth={true}
					exact="true"
					offset={-350}
					duration={100}>
					{ServicesType === 'moneyin' ? <BsCloud className={styles['icon']} /> : <BiCreditCardFront className={styles['icon']} />}
					<span>{ServicesType === 'moneyin' ? 'Cloud Devices' : 'Push to Card'}</span>
				</Link>
				{ServicesType === 'moneyin' && (
					<Link
						className={styles['btn-tabs']}
						activeClass={styles['active']}
						to="Wallet"
						spy={true}
						smooth={true}
						exact="true"
						offset={-350}
						duration={100}>
						<BiWallet className={styles['icon']} />
						<span>Wallet</span>
					</Link>
				)}
			</div>
			<div
				className={styles['cards-container'] + ' ' + styles['services-moneyIn']}
				style={{ paddingTop: '100px', paddingBottom: '120px', paddingInline: '20px' }}>
				{servicesCard[ServicesType].map((type) => (
					<BillingServiceCard
						key={ServicesType + type}
						servicesType={ServicesType}
						type={type}
						titleCard={cardProperties[ServicesType][type].title}
						element={cardProperties[ServicesType][type].element}
						iconElement={cardProperties[ServicesType][type].icon}
					/>
				))}
			</div>
		</>
	)
}
