import React, { useContext } from 'react'
import Switch from 'react-switch'
import GroupRow from '../../../../../components/templates/GroupRow'
import templatesContext from '../../context/templates_context'

export default function CardFees() {
	const { RootTemplate, LoadingState, ServicesTemplate, handleChangeService, handleChangeFees } = useContext(templatesContext)
	return (
		(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData.card.cardFees.visible) && (
			<>
				<div className="mb-2 mt-4">
					<label className="d-flex flex-row align-items-center">
						<Switch
							onChange={(value) => handleChangeFees('card', 'cardFees', value)}
							checked={!LoadingState && ServicesTemplate.card?.cardFees.visible}
							onColor="#10A0E3"
							onHandleColor="#ffffff"
							handleDiameter={20}
							boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
							activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
							height={20}
							width={38}
							className="react-switch"
							id={'cardFees'}
						/>
						<span className="ml-2 small">Card Fees</span>
					</label>
				</div>
				<div className="row">
					{RootTemplate.isRoot ? (
						<>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="transactionCardFee"
									inputType={{
										title: 'Transaction Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="achBatchCardFee"
									inputType={{
										title: 'ACH Batch Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="montlyPlatformCardFee"
									inputType={{
										title: 'Monthly Platform Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="minimumProcessingCardFee"
									inputType={{
										title: 'Minimum Processing Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="retrievalCardFee"
									inputType={{
										title: 'Retrieval Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="earlyTerminationCardFee"
									inputType={{
										title: 'Early Termination Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="chargebackCardFee"
									inputType={{
										title: 'ChargeBack Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="avsCardFee"
									inputType={{
										title: 'AVS Card Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="annualCardFee"
									inputType={{
										title: 'Annual Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="ddaRejectsCardFee"
									inputType={{
										title: 'DDA Rejects Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
								<GroupRow
									fieldData={!LoadingState && ServicesTemplate.card.cardFees}
									parentField={RootTemplate.rootTemplate.servicesData.card.cardFees}
									className="col-xl-8 col-md-12"
									handleChange={(property, field, values) => handleChangeService('card', 'cardFees', property, field, values)}
									field="monthlyPCICardFee"
									inputType={{
										title: 'Monthly PCI Fee',
										type: 'number',
										mask: ''
									}}
									checkOptions={{ state: { visible: true, readOnly: false, required: false, label: { visible: 'Active' } } }}
								/>
							</div>
						</>
					) : (
						<table className="table">
							<tbody>
								{RootTemplate.rootTemplate.servicesData.card.cardFees.transactionCardFee.visible && (
									<tr>
										<td>Transaction Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.transactionCardFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.card.cardFees.chargebackCardFee.visible && (
									<tr>
										<td>ChargeBack Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.chargebackCardFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.card.cardFees.achBatchCardFee.visible && (
									<tr>
										<td>ACH Batch Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.achBatchCardFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.card.cardFees.avsCardFee.visible && (
									<tr>
										<td>AVS Card Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.avsCardFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.card.cardFees.montlyPlatformCardFee.visible && (
									<tr>
										<td>Monthly Platform Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.montlyPlatformCardFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.card.cardFees.annualCardFee.visible && (
									<tr>
										<td>Annual Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.annualCardFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.card.cardFees.minimumProcessingCardFee.visible && (
									<tr>
										<td>Minimum Processing Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.minimumProcessingCardFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.card.cardFees.ddaRejectsCardFee.visible && (
									<tr>
										<td>DDA Rejects Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.ddaRejectsCardFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.card.cardFees.retrievalCardFee.visible && (
									<tr>
										<td>Retrieval Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.retrievalCardFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.card.cardFees.monthlyPCICardFee.visible && (
									<tr>
										<td>Monthly PCI Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.monthlyPCICardFee.value}</td>
									</tr>
								)}
								{RootTemplate.rootTemplate.servicesData.card.cardFees.earlyTerminationCardFee.visible && (
									<tr>
										<td>Early Termination Fee</td>
										<td>{!LoadingState && ServicesTemplate.card?.cardFees.earlyTerminationCardFee.value}</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
				</div>
			</>
		)
	)
}
