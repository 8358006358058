import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { OrganizationOverviewLinks } from '../../components/OrganizationOverviewLinks';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import { ReportFilters } from '../../components/ReportFilters';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Modal, Button} from 'react-bootstrap';
import { ReportPaginator } from '../../components/ReportPaginator';
import { Link } from 'react-router-dom';
import { PayabliStorageManager } from '../../api/localStorageManager'
import zxcvbn from 'zxcvbn';
import { BiDotsVerticalRounded, BiCheckCircle, BiMinusCircle, BiLockAlt } from 'react-icons/bi';
import {BiFilterAlt} from '@react-icons/all-files/bi/BiFilterAlt';
import {BiUserCircle} from '@react-icons/all-files/bi/BiUserCircle';
import {BiTrash} from '@react-icons/all-files/bi/BiTrash';
import {BiMailSend} from '@react-icons/all-files/bi/BiMailSend';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { MainBar } from '../../components/MainBar';

@inject('reports', 'global', 'user', 'entry')
@observer
class OrgUserReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterStatus: {},
            filtersEnabled: false,
            resetPasswordEmailModalIsOpen: false,
            idOrg:0,
            name: '',
            phone: '',
            status: '',
            username: '',
            password: '',
            repeatPassword: '',
            timeZone: 0,

            score: 0,

            userErrors :{},
            resetPasswordEmail: '',

            currentOrgName: '',
            orgEntryName: '',
            flagItemAllColumn: true,
        };
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteUserAction = this.deleteUserAction.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.closeResetPasswordEmailModal = this.closeResetPasswordEmailModal.bind(this);
        this.openResetPasswordEmailModal = this.openResetPasswordEmailModal.bind(this);
        this.sendEmailToResetPassword = this.sendEmailToResetPassword.bind(this);
        this.renderStatus = this.renderStatus.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.selectTimezone = this.selectTimezone.bind(this);
        this.goToNewUserForm = this.goToNewUserForm.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
    }
    
    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.setState({
            idOrg: this.props.match.params.idOrg ? this.props.match.params.idOrg : 0
        });

        if(this.props.match.params.idOrg){
            this.props.entry.getOrganization(this.props.match.params.idOrg).then(res => {
                this.setState({currentOrgName:res.OrgName , timeZone : res.OrgTimezone ? res.OrgTimezone:0 , orgEntryName: res.OrgEntryName});
            })
            .catch(error => {
                toast.error("Something is Wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }

        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                Profile        : { label:'Profile', class: 'text-center', display: true}, 
                Name        : { label:'Name', class: '', display: true, filter: "Name"}, 
                Email       : { label:'Email', class: '', display: true, filter: "Email"}, 
                Phone       : { label:'Phone', class: '', display: true}, 
                UsrStatus : { label:'Status', class: '', display: true}, 
                LastAccess : { label:'Last Log in', class: '', display: true}, 
                CreatedAt : { label:'Created on', class: '', display: true, filter: "CreatedAt"}, 
            }
        )
        
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

    viewRecordDetails(url, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            this.props.history.push(url);
        }
    }

    selectTimezone(e){
        if(e){
            this.setState({timeZone:e.target.value})
        }
    }

    renderStatus(status){
        switch(status){
            case 1:
                return <span className="badge bg-primary"><BiCheckCircle /> Active</span>;
            case 0:
                return <span className="badge bg-light"><BiMinusCircle /> Inactive</span>;
            case 85:
                return <span className="badge bg-warning"><BiLockAlt /> Locked</span>;
            default:
                return '-';

        }
    }

    saveUser(){
        let errors = this.validateUserFields();
        this.setState({ userErrors: errors }, function(){
            
            if(Object.keys(errors).length === 0){
                let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
                let orgId = this.state.idOrg;

                let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
                let emptyUserPermissions = [];
                userPermissions.forEach(function (item) {
                    emptyUserPermissions.push({
                        "roleLabel": item,
                        "roleValue": true
                    });
                });

                let user ={
                    name: this.state.name,
                    phone: this.state.phone,
                    email: this.state.username,
                    usrStatus: this.state.status,
                    pwd: this.state.password,
                    timeZone: this.state.timeZone,
                    scope: [{orgType:0, orgId: orgId}],
                    access: emptyUserPermissions
                };
                
                this.props.global.setLoading(true);   
                this.props.user.newUserInfo(user)
                .then(result => {
                    this.closeNewUserInfoModal();
                    this.getReportFromApi();
                    this.props.global.setLoading(false);

                    toast.success("Profile created successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
        });
    }
    
    closeResetPasswordEmailModal(){
        this.setState({resetPasswordEmailModalIsOpen:false})
    }
    
    openResetPasswordEmailModal(email){
        this.setState({resetPasswordEmailModalIsOpen:true, resetPasswordEmail: email})
    }
    
    handleTextChange(e){
        this.setState({ [e.target.id]: e.target.value }, function(){
            let testedResult = zxcvbn(this.state.password);
            this.setState({score : testedResult.score});
        });
    }

    handleKeyDown(type, e){
        if (e.key === 'Enter') {
            this.filter(type,e.target.value);
        }
    }

    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }

    handleChangeCalendar(dates) {
        const [start, end] = dates;
        this.setState({dateStartDate: start,dateEndtDate: end}, function(){
            const type = [];
            type['createdDate(ge)'] = this.props.global.stringDateFormatFilters(start)+ "T00:00:00";
            type['createdDate(le)'] = this.props.global.stringDateFormatFilters(end)+ "T23:59:59";
            if(end){
                this.filter(type,null);
            }
        });
        
    };

    deleteUser(id){
        this.setState({ userToDelete: id });
        this.openDeleteModal();
    }

    clearFilters(){
        this.setState({ head: "",filterStatus: {}, dateStartDate: null,dateEndtDate: null}, function(){
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    openDeleteModal(){
        this.setState({ deleteModalIsOpen: true });
    }
    
    closeDeleteModal(){
        this.setState({ deleteModalIsOpen: false });
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'users').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
        });
    }

    showHideFilters(){
        this.setState({ filtersEnabled: !this.state.filtersEnabled }, function(){
            if(this.state.filtersEnabled === false){
                this.clearFilters();
            }
        });
    }

    getReportFromApi(){
        this.props.global.setLoading(true);
        this.props.reports.getReportFromApiOrgID('users',this.state.idOrg).then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    deleteUserAction(){
        let userToDelete = this.state.userToDelete;
        
        if(userToDelete){
            this.props.global.setLoading(true);
            this.props.user.deleteUser(userToDelete)
            .then(response => {
                this.closeDeleteModal();
                this.getReportFromApi();
                this.setState({ deleteModalIsOpen: false });
                toast.success("Data deleted successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    validateUserFields(){
        let validators = this.props.global.validators;
        let errors = {};
        if(validators.isEmpty(this.state.name) || validators.isMaxLength(250, this.state.name))
        {
            errors['name']= true;
        }
        
        if(validators.isEmpty(this.state.username) || validators.isMaxLength(250, this.state.username) || validators.stringValidator('email',this.state.username))
        {
            errors['username']= true;
        }

        if(validators.isEmpty(this.state.phone) || validators.stringValidator('phone', this.state.phone))
        {
            errors['phone']= true;
        }

        if(validators.isEmpty(this.state.status))
        {
            errors['status']= true;
        }

        if(validators.isEmpty(this.state.password) || validators.isMaxLength(250, this.state.password) || (this.state.score < 4))
        {
            errors['password']= true;
        }

        if(this.state.password !== this.state.repeatPassword || validators.isEmpty(this.state.repeatPassword) || validators.isMaxLength(250, this.state.repeatPassword))
        {
            errors['repeatPassword']= true;
        }

        return errors;
    }

    sendEmailToResetPassword(){
        if(this.state.resetPasswordEmail){
            var user ={
                email: this.state.resetPasswordEmail,
                entry: this.state.orgEntryName,
            }
            
            this.props.global.setLoading(true);
            this.props.user.sendEmailToResetPassword(user)
            .then(result => {
                this.props.global.setLoading(false);
                toast.success("Email sent successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
                this.closeResetPasswordEmailModal();
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    goToNewUserForm(){
        window.location.href = "/"+this.props.global.getURLEntry()+"/users/new/" + this.state.idOrg;
    }
    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }
    
    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        return (
            <Layout {...this.props}>
            {userPermissions.indexOf("Manage Users") !== -1 && 
            <Modal style={{textAlign: "center"}} show={this.state.deleteModalIsOpen} onHide={this.closeDeleteModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiTrash className="icon-modal"/>
                    <h5>Delete</h5>

                    {
                        (encryptStorage && (this.state.userToDelete === encryptStorage.getItem('pUser').id)) ? 
                        <>
                        <p className="small">Sorry! You can not delete yourself.</p>
                        <Button className="btn" variant="primary" onClick={(e) => this.closeDeleteModal()}>
                            Close
                        </Button>
                        </>
                        :
                        <>
                         <p className="small">Are you sure you want to delete this user?</p>
                         <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeDeleteModal()}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="btn" variant="danger" onClick={(e) => this.deleteUserAction()}>
                            Delete
                        </Button>
                        </>
                    }

                   
                </Modal.Body>
            </Modal>
            }
            {userPermissions.indexOf("Manage Users") !== -1 && 
            <Modal dialogClassName="modal-autowidth" style={{textAlign: "center"}} show={this.state.resetPasswordEmailModalIsOpen} onHide={this.closeResetPasswordEmailModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiMailSend className="icon-modal"/>
                    <h5>Reset Password</h5>
                    <p className="small">An email will be sent to change user's password.</p>
                    <p className="small"> <b>Email: {this.state.resetPasswordEmail}</b> </p>
                    
                    <Button className="btn full-w" variant="primary" onClick={(e)=>this.sendEmailToResetPassword()}>
                        Send Email
                    </Button>
                </Modal.Body>
            </Modal>
            }

            <div>
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                            <OrganizationOverviewLinks goback={true} gobackLink={'/'+this.props.global.getURLEntry() + '/orgdetails/' + this.state.idOrg} gobackText="Org. Overview" idOrg={this.state.idOrg} selected="manageusers" />
                </div>
                </TopBar>

                <div className="mt-body4">
                    <MainBar
                        btnNewName="Add User" 
                        newOnClick={this.goToNewUserForm}
                        newButton={userPermissions.indexOf("Manage Users") !== -1}
                        reportTitle={this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : "Users"}
                        reportName="UsersReports"
                        dataQAName="OrgUsersReports"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        rootTemplate={this.state.rootTemplate}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={false}
                        searchBar={false}
                        masterName={this.props.match.params.idOrg && `Organization - ${this.state.currentOrgName}`}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    /> 
                {/* <div className="row mb-4 datatable-actions">
                    <div className="col-6">
                        { this.props.match.params.idOrg &&
                        <h5>Organization - {this.state.currentOrgName}</h5>
                        }
                    <div className="mr-3 inline">
                    <h6 style={{display: "inline-block"}}>Users</h6>
                    </div>

                    <div className="small-small inline-block">
                    Total Count:  {this.props.reports.totalRecords}
                    </div>

                    </div>
                    <div className="col-6 text-right">
                        <div className="d-none show-md">
                            <DropdownButton
                                menuAlign="right"
                                title={<div><BiListUl/> Actions</div>}
                                size="sm"
                                variant="default"
                            >
                            <Dropdown.Item onClick={(e) => this.showHideFilters()}>{this.state.filtersEnabled ? "Hide ": "Show "} Filters</Dropdown.Item>
                            {userPermissions.indexOf("Manage Users") !== -1 && 
                            <Dropdown.Item onClick={(e) => this.goToNewUserForm(e)}>Add User</Dropdown.Item>
                            }
                            </DropdownButton>
                        </div>
                        <div className="hide-md">
                            <button className="btn btn-default bordered" type="button" onClick={(e) => this.showHideFilters()}><BiFilterAlt/> {this.state.filtersEnabled ? "Hide ": "Show "} Filters</button>
                            <DropdownButton
                                    menuAlign="right"
                                    title={<div><BiColumns/> Columns</div>}
                                    size="sm"
                                    variant="default"
                                >
                                {
                                this.props.reports.getHeaders.map((record, i) => (
                                    <Dropdown.ItemText key={"key"+record[0]}>
                                        <label><input type="checkbox" id={record[0]} defaultChecked={ (this.props.reports.headers[record[0]] && this.props.reports.headers[record[0]].display ) ? true : false} onChange={(e) => this.handleShowColumn(e)}/> {record[1].label}</label>
                                    </Dropdown.ItemText>
                                ))
                                }
                            </DropdownButton>
                            {userPermissions.indexOf("Manage Users") !== -1 && 
                            <button onClick={(e) => this.goToNewUserForm(e)} className="btn btn-default btn-bordered-success ml-2">+ Add User</button>
                            }
                        
                        </div>
                    </div>
                </div> */}


                {this.state.filtersEnabled &&
                <ReportFilters report="users" clearFilters = {this.clearFilters}/>
                }
                <div className="report-container">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            
         
                            ( record[1].display &&
                            (this.state.filtersEnabled ?
                            <th key={i} scope="col" className={record[1].class}>
                                
                                { (!record[1].filter) ? record[1].label: ''}

                                { record[1].filter &&
                                    <>
                                    
                                    {record[1].filter === 'Name' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Name</label>
                                            <input placeholder="Name" onKeyDown={(e) => this.handleKeyDown('name(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'Email' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Email</label>
                                            <input placeholder="Email" onKeyDown={(e) => this.handleKeyDown('email(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'CreatedAt' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form-datepicker">
                                        <DatePicker
                                            selected={ this.state.dateStartDate ? this.state.dateStartDate : new Date()}
                                            onChange={date => this.handleChangeCalendar(date)}
                                            startDate={this.state.dateStartDate}
                                            endDate={this.state.dateEndtDate}
                                            selectsRange
                                            inline
                                        />
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }


                                    </>
                                    
                                }


                                

                            </th>
                                : <th key={i} scope="col" className={record[1].class}>{record[1].label}</th> )
                            )
    
                 
                           
                        ))
                    }
                    <th scope="col" className="text-center sticky-row">Actions</th>
                    </tr>
                </thead>
                <tbody>
                
                { this.props.reports.records.map((record, i) => (
                        <tr key={i} className="cursorPointer" onClick={(e) => this.viewRecordDetails("/profile/"+record.userId+"/"+this.state.orgEntryName,e)} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} >
                            { (this.props.reports.headers.Profile && this.props.reports.headers.Profile.display) &&
                            <td className="text-center table-icon"><BiUserCircle/></td>
                             }

                             { (this.props.reports.headers.Name && this.props.reports.headers.Name.display) &&
                            <td>{record.Name}</td>
                             }

                            { (this.props.reports.headers.Email && this.props.reports.headers.Email.display) &&
                            <td>{record.Email}</td>
                            }

                            { (this.props.reports.headers.Phone && this.props.reports.headers.Phone.display) &&
                            <td>{record.Phone ? this.props.global.phoneNumberFormat(record.Phone) : '-'}</td>
                            }
                            
                            { (this.props.reports.headers.UsrStatus && this.props.reports.headers.UsrStatus.display) && 
                            <td>{this.renderStatus(record.UsrStatus)}</td>
                            }
                            
                            { (this.props.reports.headers.LastAccess && this.props.reports.headers.LastAccess.display) && 
                            <td>{this.props.global.stringDateFormatV3(record.LastAccess)}</td>
                            }
                            
                            { (this.props.reports.headers.CreatedAt && this.props.reports.headers.CreatedAt.display) && 
                            <td>{this.props.global.stringDateFormatV3(record.CreatedAt)}</td>
                            }


                            <td className="text-center sticky-row">
                                <DropdownButton
                                    menuAlign="center"
                                    title={<BiDotsVerticalRounded/>}
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                >
                                {userPermissions.indexOf("Manage Users") !== -1 && 
                                <>
                                <Dropdown.Item as={Link} to={'/'+this.props.global.getURLEntry()+"/profile/"+record.userId+"/"+this.state.orgEntryName}>View Details</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => this.openResetPasswordEmailModal(record.Email) }>Reset Password</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item style={{color:"rgb(192, 0, 0)"}} onClick={(e)=>this.deleteUser(record.userId)} >Delete</Dropdown.Item>
                                </>
                                }
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }
                
                </tbody>
                </table>
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound/>
                }
                </div>
                <ReportPaginator report="users" mode={this.state.idOrg} fromId="true" />

                </div>
            </div>         

            <ToastContainer transition={Bounce} />
            </Layout>
        )
    }
}

export { OrgUserReport };