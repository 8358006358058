import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Dropdown, DropdownButton, Button } from 'react-bootstrap';
import { BiListUl } from '@react-icons/all-files/bi/BiListUl';
import { BiColumns } from '@react-icons/all-files/bi/BiColumns';
import { BiSlider } from '@react-icons/all-files/bi/BiSlider';
import { BiRefresh } from '@react-icons/all-files/bi/BiRefresh';
import { BiDownload } from '@react-icons/all-files/bi/BiDownload';
import { SearchBar } from './SearchBar';
import { FaSearch } from 'react-icons/fa';
import { BiUpload } from '@react-icons/all-files/bi/BiUpload';
import { BalanceLine } from './BalanceLine';
import { BiPlus } from 'react-icons/bi';

@observer
class MainBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visibleSearch: false
		};
		this.toggleVisibleSearchBar = this.toggleVisibleSearchBar.bind(this);
	}
	toggleVisibleSearchBar(e) {
		this.setState({ visibleSearch: !this.state.visibleSearch });
	}

	renderStatisticsLabel() {
		let labelCount = this.props.reportOut ? 'Count: ' : 'Total Count: ';
		let labelAmount = this.props.reportOut ? 'Total to Pay: ' : 'Total Amount: ';
		return (
			<div className='inline-block small-small'>
				<span className='mr-2 text-nowrap'>
					{labelCount} {this.props.totalRecords}
				</span>
				<span className='mr-2 text-nowrap'>
					{labelAmount} ${this.props.totalAmount}
				</span>
			</div>
		);
	}

	renderRangeDate() {
		return this.props.rangeDate ? (
			<div className='small-small text-nowrap'>
				<strong>PERIOD </strong>
				{this.props.rangeDate}
			</div>
		) : null;
	}

	render() {
		return (
			<div className='row mb-4 datatable-actions'>
				<div className='col-7 head-filter'>
					{this.props.masterName && <h5>{this.props.masterName}</h5>}
					{this.props.subMasterName && <h5>{this.props.subMasterName}</h5>}
					{this.props.reportNameComponent && this.props.reportNameComponent()}
					{!this.props.reportNameComponent && (
						<div className='mr-3 inline'>
							<h6 data-qaid={`all${this.props.dataQAName}ReportsPage`}>
								{this.props.reportTitle ? this.props.reportTitle : this.props.reportName}
							</h6>
						</div>
					)}
					{!this.props.withTotal && !this.props.skipTotal && (
						<div className='small-small inline-block'>
							{this.props.reportTitle} Count: {this.props.totalRecords}
						</div>
					)}
					{this.props.withTotal && this.renderStatisticsLabel()}
					{this.props.balanceLineEntry && (
						<BalanceLine
							moneIn={true}
							entry={this.props.balanceLineEntry}
						/>
					)}
					{this.renderRangeDate()}
				</div>
				<div className='col-5 text-right report-tools'>
					<div className='d-none show-md'>
						<DropdownButton
							menuAlign='right'
							title={
								<div>
									<BiListUl /> Actions
								</div>
							}
							size='sm'
							variant='default'>
							<Dropdown.Item onClick={(e) => this.props.refreshView(e)}>Update Data</Dropdown.Item>
							{this.props.newLink && (
								<Dropdown.Item>
									<Link
										to={this.props.newLink}
										style={{ textDecoration: 'none', color: '#212529' }}>
										{this.props.btnNewName}
									</Link>
								</Dropdown.Item>
							)}
							{this.props.newButton && (
								<Dropdown.Item onClick={(e) => this.props.newOnClick(e)}>
									{this.props.btnNewName}
								</Dropdown.Item>
							)}
							{this.props?.buttonAddComponent && this.props.buttonAddComponent(true)}
							<Dropdown.Item onClick={(e) => this.props.showHideFilters()}>
								Show Filters
							</Dropdown.Item>
							{this.props.buttonImport && (
								<Dropdown.Item onClick={this.props.importFile}>Import</Dropdown.Item>
							)}
							{this.props.buttonExport && (
								<>
									<Dropdown.Item onClick={(e) => this.props.exportFile('xlsx')}>
										Download xlsx
									</Dropdown.Item>
									<Dropdown.Item onClick={(e) => this.props.exportFile('csv')}>
										Download csv
									</Dropdown.Item>
								</>
							)}
						</DropdownButton>
					</div>
					<div className='hide-md'>
						<div className='main-bar-right-side'>
							<div
								className='zone-refresh'
								onClick={(e) => this.props.refreshView(e)}
								data-qaid='updateDataIcon'>
								<BiRefresh
									className='btn-refresh'
									size={20}
								/>
								<span className='span-refresh'>Update Data</span>
							</div>
							{this.props.searchBar && this.state.visibleSearch && (
								<div>
									<SearchBar
										clickToHide={(e) => this.toggleVisibleSearchBar(e)}
										reports={this.props.reports}
										report={this.props.report}
										status={{
											incomplete: -99,
											pending: 2,
											underwriting: 3,
											submitted: 4,
											manual: 6,
											approved: 7,
											withdraw: 8,
											deaactivated: 9,
											boarding: 10,
											activated: 99,
											live: 100,
											declined: 0,
											exception: -1,
											
										}}
									/>
								</div>
							)}
							{this.props.searchBar && !this.state.visibleSearch && (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: '31px',
										marginBottom: '3px'
									}}>
									<FaSearch
										style={{ fontSize: '18px !important' }}
										onClick={(e) => this.toggleVisibleSearchBar(e)}
									/>
								</div>
							)}
							<button
								className='btn btn-default bordered'
								type='button'
								onClick={(e) => this.props.showHideFilters(e)}
								data-qaid={`showFilters${this.props.dataQAName}Button`}>
								<BiSlider />
								<span className='main-span-button'>Show Filters</span>
							</button>
							<DropdownButton
								menuAlign='left'
								title={
									<div>
										<BiColumns data-qaid={`columns${this.props.dataQAName}Button`} />{' '}
										<span className='main-span-button'>Columns</span>
									</div>
								}
								size='sm'
								variant='default'
								id='columnReport'>
								<Dropdown.ItemText>
									<label>
										<input
											type='checkbox'
											ref={(input) => {
												if (input) {
													input.indeterminate = this.props.flagItemAllColumn;
												}
											}}
											id='itemSelectColumn'
											onChange={(e) => this.props.selectAllColumns(e)}
										/>{' '}
										Select All
									</label>
								</Dropdown.ItemText>
								<Dropdown.Divider />
								{this.props.reportName === 'batches' && (
									<span
										className='small'
										style={{ fontSize: '10px', marginLeft: '15px' }}>
										BATCHES
									</span>
								)}
								{this.props.getHeaders.map((record, i) => (
									<div key={'key' + i + record[0]}>
										<Dropdown.ItemText>
											<label data-qaid={`columns${this.props.dataQAName}-${record[0]}`}>
												<input
													type='checkbox'
													id={record[0]}
													defaultChecked={
														this.props.headers[record[0]] && this.props.headers[record[0]].display
															? true
															: false
													}
													onChange={(e) => this.props.handleShowColumn(e)}
												/>{' '}
												{record[1].label}
											</label>
										</Dropdown.ItemText>
										{this.props.reportName === 'batches' && record[0] === 'BatchAuthAmount' && (
											<Dropdown.Divider />
										)}
										{this.props.reportName === 'batches' && record[0] === 'BatchAuthAmount' && (
											<span
												className='small'
												style={{ fontSize: '10px', marginLeft: '15px' }}>
												TRANSFERS
											</span>
										)}
									</div>
								))}
							</DropdownButton>
							{this.props.buttonImport && (
								<button
									className='btn btn-default bordered ml-2'
									onClick={this.props.importFile}>
									<div>
										<BiUpload data-qaid={`import${this.props.dataQAName}Button`} />{' '}
										<span className='main-span-button'>Import</span>
									</div>
								</button>
							)}
							{this.props.buttonExport && (
								<DropdownButton
									menuAlign='right'
									title={
										<div>
											<BiDownload data-qaid={`export${this.props.dataQAName}Button`} />{' '}
											<span className='main-span-button'>Export</span>
										</div>
									}
									size='sm'
									variant='default'>
									<Dropdown.Item
										onClick={(e) => this.props.exportFile('xlsx')}
										data-qaid={`downloadxlsxExport${this.props.dataQAName}Button`}>
										Download xlsx
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(e) => this.props.exportFile('csv')}
										data-qaid={`downloadcsvExport${this.props.dataQAName}Button`}>
										Download csv
									</Dropdown.Item>
								</DropdownButton>
							)}
							{this.props.newLink && (
								<a
									href={this.props.newLink}
									className='btn btn-default btn-bordered-success ml-2'
									data-qaid={`add${this.props.dataQAName}Button`}>
									<BiPlus /> <span className='main-span-button'>{this.props.btnNewName}</span>
								</a>
							)}
							{this.props.newButton && (
								<Button
									className='btn btn-default btn-bordered-success ml-2'
									onClick={(e) => this.props.newOnClick(e)}
									data-qaid={`add${this.props.dataQAName}Button`}>
									<BiPlus /> <span className='main-span-button'>{this.props.btnNewName}</span>
								</Button>
							)}
							{this.props?.buttonAddComponent && this.props.buttonAddComponent()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export { MainBar };
