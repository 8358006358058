import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { VendorsLinks } from '../../components/VendorsLinks';
import { RightPanel } from '../../components/RightPanel';
import "react-datepicker/dist/react-datepicker.css";
import {HiCheckCircle } from 'react-icons/hi';
import {HiQuestionMarkCircle} from '@react-icons/all-files/hi/HiQuestionMarkCircle';
import { VendorDataChartColumn } from '../../components/VendorDataChartColumn';
import 'react-toastify/dist/ReactToastify.css';
import { BiCheck, BiDollar, BiInfoCircle, BiMoney, BiPaperPlane,BiTimeFive } from 'react-icons/bi';
import { Layout } from '../../components/Layout'
import { Link } from 'react-router-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { EditVendor } from '../../components/EditVendor';
import { PayabliStorageManager } from '../../api/localStorageManager';


@inject('customer','vendor', 'global', 'vTerminal', "reports")
@observer
class ViewVendor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: "",
            vendor: "",
            vendorId: null,
            recentBills: [],
            recentPaidHistory: [],
        };
        this.getVendorFromApi = this.getVendorFromApi.bind(this);
        this.initUI = this.initUI.bind(this);
        this.getBillsNumbers = this.getBillsNumbers.bind(this);
    }

    componentDidMount() {
        this.initUI();
    }

    getBillsNumbers(record){
        let text = [];
        if(record){
            record.forEach(function (item) {
                text.push(<div>{item.invoiceNumber}</div>);
            });
            return text
        }

        return (text);
    }

    initUI(){
        let id = this.props.match.params.id;
        this.getVendorFromApi(id);
        this.setState({ vendorId: id });
    }



    getVendorFromApi(id){
        this.props.global.setLoading(true);
        this.props.vendor.getVendorFromApi(id).then(res => {
            this.setState({vendor: res.data});
            
            if(res.data){
                this.props.vendor.getVendorRecentBills(res.data).then(resBills => {
                    this.setState({recentBills: resBills.data.Records});
                    this.props.global.setLoading(false);
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                });
    
                
                this.props.vendor.getVendorRecentPaidHiscory(res.data).then(resBills => {
                    this.setState({recentPaidHistory: resBills.data.Records});
                    this.props.global.setLoading(false);
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                });
            }
           

        })
        .catch(error => {
            this.props.global.setLoading(false);
        });


    }


    render() {
        return (
            <Layout {...this.props}>
            <div>
                <RightPanel>

                </RightPanel>


                <TopBar>
                    <MainTopBarMenu />
                    <div className="top-bar-sub">
                        <VendorsLinks gobackLink={"/"+PayabliStorageManager.getEntryName()+'/report/vendors'} gobackText="Vendor list" history={this.props.history} goback={true} selected="overview" id={this.state.vendorId} />
                    </div>
                </TopBar>


                <div className="mt-body4">
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <h5>{this.state.vendor ? this.props.global.capitalizeFirstLetterOfEachWord(this.state.vendor.Name1) : ''} {this.state.vendor ? this.props.global.capitalizeFirstLetterOfEachWord(this.state.vendor.Name2): ''} 
                            </h5>
                            <p className="small-grey">{this.state.vendor ? this.state.vendor.Email: ''}</p>
                        </div>
                        <div className="col-md-6 col-lg-6 text-right actions-btn-cont">
                            
                            <DropdownButton
                                menuAlign="right"
                                title={<div className="btn bordered float-end text-normal">+ Actions</div>}
                                size="sm"
                                variant="default"
                            >
                            <Dropdown.Item as={EditVendor} viewAction={"edit"} className={" "} style={{fontSize: "12px"}} vendorToUpdate={this.state.vendor} label={"Edit Vendor"} action={"edit"}></Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>


                    <div className="row">
                       
                        <div className="col-lg-3 mb-5 full-1260 order-1260-2">
                        <VendorDataChartColumn callBackFunction={this.initUI} routeParams={this.props.match.params} vendorObj={this.state.vendor}/>
                        </div>
                        <div className="col-lg-8 offset-lg-1 dashboard-items full-1260 order-1260-1 offset-1260-0">
                            <div className="row mb-1">
                            <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle orange">
                                        <BiCheck/>
                                    </div>
                                    <label>Active Volume</label><br/>
                                    <b>${this.state.vendor && this.state.vendor.Summary ? this.props.global.numberWithCommas(this.state.vendor.Summary.PendingBillsAmount.toFixed(2)) : "0.00"}</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle blue">
                                        <BiPaperPlane/>
                                    </div>
                                    <label>In Transit Volume</label><br/>
                                    <b>${this.state.vendor && this.state.vendor.Summary ? this.props.global.numberWithCommas(this.state.vendor.Summary.InTransitBillsAmount.toFixed(2)) : "0.00"}</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle purple">
                                        <BiMoney/>
                                    </div>
                                    <label>Paid Volume</label><br/>
                                    <b>${this.state.vendor && this.state.vendor.Summary ? this.props.global.numberWithCommas(this.state.vendor.Summary.PaidBillsAmount.toFixed(2)) : "0.00"}</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle red">
                                        <BiTimeFive/>
                                    </div>
                                    <label>Past Due Volume</label><br/>
                                    <b>${this.state.vendor && this.state.vendor.Summary ? this.props.global.numberWithCommas(this.state.vendor.Summary.OverdueBillsAmount.toFixed(2)) : "0.00"}</b>
                                </div>
                                
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle green">
                                        <BiDollar/>
                                    </div>
                                    <label>Total Volume</label><br/>
                                    <b>${this.state.vendor && this.state.vendor.Summary ? this.props.global.numberWithCommas(this.state.vendor.Summary.TotalBillsAmount.toFixed(2)) : "0.00"}</b>
                                </div>


                                <div className="col-md-6 col-lg-4 mb-5">
                                   
                                {
                                    this.state.vendor && this.state.vendor.VendorStatus != null && this.state.vendor.VendorStatus === 1 ?
                                        <div className="dashboard-color-circle greenoutline">
                                            <HiCheckCircle/>
                                        </div>
                                    :
                                        <div className="dashboard-color-circle yellowoutline">
                                            <HiQuestionMarkCircle/>
                                        </div>
                                }
                                   
                                <label>Vendor Status</label><br/>
                                <b>{this.state.vendor && this.state.vendor.VendorStatus != null ? this.props.reports.getVendorStatus(this.state.vendor.VendorStatus, true) : '-'}</b>
                                   
                                </div>
                            </div>
                  
                            <h6 className="sub-header-line mb-1 dark-grey">Recent Paid History<a className="btn bordered btn-sm float-end" href={"/" + this.props.global.getURLEntry() + "/bills/new"}> + </a> <Link to={"/" + this.props.global.getURLEntry() + '/report/bills/'+ this.state.vendorId } className="link-default float-end">See More</Link>  </h6>
                            { (this.state.recentPaidHistory && this.state.recentPaidHistory.length > 0) ?
                            <div className="report-container height-1260-auto">
                            <table className="table table-hover table-striped mb-5">
                                    <tbody>
                                        <tr>
                                            <th>Vendor Name</th>
                                            <th>Bill #</th>
                                            <th>Status</th>
                                            <th>Total Amount</th>
                                            <th className="text-right">Date</th>
                                        </tr>
                                    {
                                    this.state.recentPaidHistory.map((record, i) => (
                                    <tr key={i}>
                                        <td className="text-left">{record.Vendor && record.Vendor.Name1 ? record.Vendor.Name1 : '-'} {record.Vendor && record.Vendor.Name2 ? record.Vendor.Name2 : ''}</td>
                                        <td className="text-left">{record.Bills ? this.getBillsNumbers(record.Bills) : "-"} </td>
                                        <td>{this.props.global.getMoneyOutTransStatus(record.Status)}</td>
                                        <td>${record && record.TotalAmount ? this.props.global.numberWithCommas(record.TotalAmount.toFixed(2)) : '0.00'}</td>
                                        <td className="text-right">{record && record.CreatedAt ? (this.props.global.stringDateFormat(record.CreatedAt)) : '-'}</td>
                                    </tr>
                                    ))
                                    }
                                    </tbody>
                            </table>
                            </div>
                            :
                            <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Records not found</div> 
                            }   

                            <h6 className="sub-header-line mb-1 dark-grey">Recent Bills<a className="btn bordered btn-sm float-end" href={"/" + this.props.global.getURLEntry() + "/bills/new"}> + </a> <Link to={"/" + this.props.global.getURLEntry() + '/report/bills/'+ this.state.vendorId } className="link-default float-end">See More</Link></h6>
                            { (this.state.recentBills && this.state.recentBills.length > 0) ?
                            <div className="report-container height-1260-auto">
                            <table className="table table-hover table-striped mb-4">
                                    <tbody>
                                        <tr>
                                            <th>Vendor Name</th>
                                            <th>Bill #</th>
                                            <th>Status</th>
                                            <th>Total Amount</th>
                                            <th className="text-right">Due Date</th>
                                        </tr>
                                    {
                                    this.state.recentBills.map((record, i) => (
                                    <tr key={i}>
                                        <td className="text-left">{record.Vendor && record.Vendor.Name1 ? record.Vendor.Name1 : '-'} {record.Vendor && record.Vendor.Name2 ? record.Vendor.Name2 : ''}</td>
                                        <td className="text-left">{record.BillNumber} </td>
                                        <td>{this.props.reports.getBillStatus(record.Status)}</td>
                                        <td>${record && record.NetAmount ? this.props.global.numberWithCommas(record.NetAmount.toFixed(2)) : '0.00'}</td>
                                        <td className="text-right">{record && record.DueDate ? (this.props.global.stringDateFormat(record.DueDate)) : '-'}</td>
                                    </tr>
                                    ))
                                    }
                                    </tbody>
                            </table>
                            </div>
                                : <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Records not found</div> }
                                
                        </div>
                    </div>





                </div>

            </div>
            </Layout>
        )
    }
}

export { ViewVendor };