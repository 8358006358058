import React from 'react';
import { BiMessageRoundedError} from 'react-icons/bi';
import { inject, observer } from 'mobx-react';

@inject('global')
@observer
class RecordsNotFound extends React.Component {
    constructor(props) {
        super(props);
        this.reloadPage = this.reloadPage.bind(this);
    }

    reloadPage(e){
        if(e){
            e.preventDefault();
        }
        window.location.reload();
    }

    render() {
        return (
            <div className="records-not-found">
                {!this.props.global.isLoading &&
                <>
                <BiMessageRoundedError className="dark-grey" style={{fontSize: "50px"}}/>
                {this.props.message ? <h6 className="dark-grey mt-4">{this.props.message}</h6> : <h6 className="dark-grey mt-4">No results found</h6>}
                {this.props.description ? <p className="small-small grey">{this.props.description}</p> : <p className="small-small grey">Try adjusting the filters or contact support<br/>if you have any questions</p>}
                {(this.props.btnLink && this.props.btnText )&& 
                    <a href={this.props.btnLink} className="btn btn-primary btn-sm">{this.props.btnText}</a>
                }
                {(this.props.btnFunction && this.props.btnText )&& 
                    <button onClick={this.props.btnFunction} className="btn btn-primary btn-sm">{this.props.btnText}</button>
                }
                </>
                }
                {/*<a className="small-small" href="/" onClick={(e)=>this.reloadPage(e)}>Reload Page</a>*/}
            </div>
        )
    }
}

export { RecordsNotFound };