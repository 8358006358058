import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { TopBar } from '../../components/TopBar';
import { Tab, Nav } from 'react-bootstrap';
import { BiCctv, BiCommentAdd, BiPauseCircle, BiListCheck, BiMask, BiGridAlt, BiListOl } from 'react-icons/bi';
import { ToastContainer, Bounce} from 'react-toastify';
import { RiskControlCenterLinks } from '../../components/RiskControlCenterLinks';
import { ViewPartialFraudAlerts } from './ViewPartialFraudAlerts';

@inject('reports', 'global')
@observer
class ReportsRiskControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = { }
    }

    render() {
        return (
            <Layout {...this.props}>
                <TopBar>
                    <MainTopBarMenu/>
                    <div className="top-bar-sub">
                        <RiskControlCenterLinks selected={'fraudAlerts'} />
                    </div>
                </TopBar>
                <div className="mt-body4">
                    <Tab.Container defaultActiveKey="fraud-alerts">
                        <Nav className="tab-risk-control justify-content-center" style={{marginTop: '-25px'}}>
                            <Nav.Item>
                                <Nav.Link eventKey="reviews" disabled><BiCommentAdd /> Reviews</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fraud-alerts"><BiCctv /> Fraud Alerts</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="hold-reports" disabled><BiPauseCircle /> Hold Reports</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="lists" disabled><BiListOl /> Lists</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="verifications" disabled><BiListCheck /> Verifications</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="unusual-activity" disabled><BiMask /> Unusual Activity</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="sars" disabled><BiGridAlt /> SARs</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="fraud-alerts">
                                <ViewPartialFraudAlerts />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                <ToastContainer transition={Bounce} />
            </Layout>
        )
    }
}

export { ReportsRiskControl };