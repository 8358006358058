import React, { useState } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import { TopBar } from '../../../components/TopBar'
import StatusBar from '../../../components/commandCenter/StatusBar'
import { MainTopBarMenu } from '../../../components/MainTopBarMenu'
import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft'
import BillingServiceType from './BillingServiceType'
import ValueAddedServices from './ValueAddedServices'

export default function MainServices({ closeService, changeTabServices, element, tabShow }) {
	const [showTab, setShowTab] = useState({
		moneyIn: tabShow === 'moneyIn' || tabShow === '' ? true : false,
		moneyOut: tabShow === 'moneyOut' ? true : false,
		valueAddedServices: tabShow === 'valueAddedServices' ? true : false
	})

	return (
		<>
			<TopBar>
				<MainTopBarMenu />
				<StatusBar SubTitleStatus="Manage your PayPoint easy within this command center" />
			</TopBar>
			<div className={styles['panel-Services']}>
				<div
					className={showTab.moneyIn ? styles['container-btn'] + ' ' + styles['margin-left-auto'] + ' ' + styles['margin-auto-screem-sm'] : styles['container-btn'] + ' ' + styles['margin-left-auto'] + ' ' + styles['margin-auto']}
					style={{ marginLeft: '50px' }}>
					<button
						onClick={() => closeService()}
						className={styles['btn']}>
						<BiChevronLeft className={styles['icon20']} />
						<label
							className={showTab.moneyIn ? styles['d-none-750'] + ' ' + styles['d-block-370'] : styles['d-none-750'] + ' ' + styles['d-block-680']}
							style={{ cursor: 'pointer' }}>
							Billing & Fees Summary
						</label>
					</button>
				</div>

				<div className="d-flex flex-row flex-wrap align-items-center justify-content-center m-auto">
					<button
						onClick={() => changeTabServices('CreditCard') | setShowTab({ moneyIn: true, moneyOut: false, valueAddedServices: false })}
						className={showTab.moneyIn ? styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-blue'] : styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-gray']}
						style={{ fontSize: '16px', fontWeight: !showTab.moneyIn && '400' }}>
						Money IN
					</button>
					<button
						onClick={() => changeTabServices('ManagedPayAbles') | setShowTab({ moneyIn: false, moneyOut: true, valueAddedServices: false })}
						className={showTab.moneyOut ? styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-blue'] : styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-gray']}
						style={{ fontSize: '16px', fontWeight: !showTab.moneyOut && '400' }}>
						Money OUT
					</button>
					<button
						onClick={() => setShowTab({ moneyIn: false, moneyOut: false, valueAddedServices: true })}
						className={showTab.valueAddedServices ? styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-blue'] : styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-gray']}
						style={{ fontSize: '16px', fontWeight: !showTab.valueAddedServices && '400' }}>
						Value Added Services
					</button>
				</div>
			</div>
			{!showTab.valueAddedServices ? (
				<BillingServiceType
					element={element}
					changeTabServices={changeTabServices}
					ServicesType={showTab.moneyIn ? 'moneyin' : 'moneyout'}
				/>
			) : (
				<ValueAddedServices />
			)}
		</>
	)
}
