import React from 'react';
import { Col } from "react-bootstrap";
import { inject, observer } from 'mobx-react';
import SelectSearch from 'react-select-search';
import '../assets/css/react-select-search.css';
import Fuse from 'fuse.js';

export default function fuzzySearch(options) {
    const fuse = new Fuse(options, {
        keys: ['name', 'groupName', 'items.name'],
        threshold: 0.3,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }
        let result = fuse.search(value);
        let resultArray = []
        result.forEach(function (item) {
            resultArray.push(item.item);
        });
        return resultArray;
    };
}

@inject('boarding','global')
@observer
class InputSelectSearchable extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            value: '', 
            type: '', 
            cname: '', 
            error: false,
            isNewScreen: true,
            ignoreEmpty: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value, name) {
        let validators = this.props.global.validators;
        this.props.boarding.setTemplateData(name, value);
        this.setState({ 
            value: value, 
            error: validators.isEmpty(value) ? true : false 
        }, function () {
            this.props.boarding.setErrorData(this.state.cname, this.state.error);
        });
   }

    componentDidMount() {
        let validators = this.props.global.validators;
        let ignoreEmpty = false;
        if (this.props.ignoreEmpty) {
            ignoreEmpty = this.props.ignoreEmpty;
        }
        this.setState({ 
            value: this.props.iValue, 
            type: this.props.iType, 
            cname: this.props.iName, 
            error: (!ignoreEmpty && validators.isEmpty(this.props.iValue)) ? true : false, 
            ignoreEmpty: ignoreEmpty 
        }, function () {
            this.props.boarding.setTemplateData(this.state.cname, this.state.value);
            this.props.boarding.setErrorData(this.state.cname, this.state.error);
        });
    }


    render() {
        let nextClicked = this.props.boarding.nextClicked;
        return (
            <Col md={this.props.full ? "12" : "6"}>
                    <div style={{position: "relative"}} className={(this.state.error && !this.state.isNewScreen) ||  (this.state.error && nextClicked) ? "input-error mb-4" : "mb-4"} >
                        <SelectSearch
                            //printOptions={'always' }
                            options={this.props.global.getMccOptions()}
                            autoComplete={"selectSearch"}
                            search
                            filterOptions={fuzzySearch}
                            emptyMessage="Not found"
                            placeholder="Find and Select"
                            id={this.props.iName || "selectSearch"}
                            value={this.state.value}
                            onChange={(e) =>
                                this.handleChange(e,this.props.iName)
                            }
                            disabled={this.props.iReadonly ? this.props.iReadonly : false}
                        />
                        <label className="select-search__input__label">{this.props.iTitle} {[null, true].includes(this.props.iRequired) ?  " *": ""}</label>
                    </div>
            </Col>
        )
    }
}

export { InputSelectSearchable };