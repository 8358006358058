import React, { useContext } from 'react'
import styles from '../../../../../assets/css/styleCommandCenter.module.css'
import { BiStore } from 'react-icons/bi'
import FrameGroup from '../../../../../components/commandCenter/FrameGroup'

import { Element } from 'react-scroll'
import RowContainer from '../../../../../components/commandCenter/RowContainer'
import hellosignContext from '../../context/hellosignContext'
import { Col, Row } from 'react-bootstrap'

export default function ComponentInformation(props) {
	const { inputDisabled, boardingFields } = props
	const ObjectName = useContext(hellosignContext)[props.componentName]
	const handleChange = useContext(hellosignContext)[props.changeName]
	return (
		<Element
			name={`${props.toName}`}
			className="d-flex justify-content-center w-100">
			<div className={styles['cards-details-hs']}>
				<FrameGroup
					icon_frame={
						<BiStore
							className={`${styles['icon26']} ${styles['color-blue']}`}
						/>
					}
					title_label={`${props.componentName} Details`}
					useBtn={false}
					btnDelete={false}
				/>
				<div className="d-flex flex-column w-100">
					<div
						className={styles['grid-container']}
						key={`${props.componentName}Details`}>
						<div className="d-flex flex-column align-items-start p-0">
							<Row key={`row-${props.componentName}Details`}>
								{props.fields
									.filter(
										(item) =>
											item
												.toLowerCase()
												.startsWith(
													props.sectionName
												) ||
											item
												.toLowerCase()
												.startsWith(
													props.sectionNameLetter
												)
									)
									.map((data, index) => (
										<Col
											md={
												props.fields.length > 1
													? '6'
													: '12'
											}
											key={`${props.componentName}Details-row-${index}`}>
											<RowContainer
												inputDisabled={inputDisabled}
												labelValue={data}
												name={data}
												value={ObjectName[data]}
												setValue={(values) =>
													handleChange(data, values)
												}
												labelStyle={{
													fontWeight: 600,
													lineHeight: '20px',
													letterSpacing: '0.0025em',
													width: '265px',
													minWidth: '20px',
													overflow: 'hide'
												}}
												maxLength={30}
												optionList={
													<>
														<option
															key={`${data}-option-default`}>
															Select
														</option>
														{boardingFields?.map(
															(field, pindex) => (
																<option
																	key={`${data}-selection-${pindex}`}
																	value={
																		field.name
																	}>
																	{
																		field.labelValue
																	}
																</option>
															)
														)}
													</>
												}
											/>
										</Col>
									))}
							</Row>
						</div>
					</div>
				</div>
			</div>
		</Element>
	)
}
