import React from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import ItemModal from './ItemModal';
import ItemTable from './ItemTable';
import NumberFormat from 'react-number-format';
import { BiInfoCircle } from 'react-icons/bi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

@inject('store', 'global', 'vTerminal', 'bill')
@observer
class SectionItemsDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedData: false,
      lineItemToSearch: '',
      searchingLineItem: false,
      searchLineItemPanelIsOpen: false,
      newLineItemModalIsOpen: false,
      saveToCatalog: false,
      lineItemToModify: false,
      actionModal: 'add',
    };

    this.searchingLineItem = this.searchingLineItem.bind(this);
    this.closeNewLineItemModal = this.closeNewLineItemModal.bind(this);
    this.closeSearchLineItemPanelDelay = this.closeSearchLineItemPanelDelay.bind(this);
    this.selectLineItem = this.selectLineItem.bind(this);
    this.openNewLineItemModal = this.openNewLineItemModal.bind(this);
    this.closeSearchLineItemPanel = this.closeSearchLineItemPanel.bind(this);
    this.closeNewLineItemModal = this.closeNewLineItemModal.bind(this);
    this.openModalEdit = this.openModalEdit.bind(this);
    this.focus = this.focus.bind(this);
  }

  searchingLineItem(e) {
    this.setState({ lineItemToSearch: e.target.value, searchLineItemPanelIsOpen: true });
    if (e.target.value.length > 2) {
      try{
        this.setState({ searchingLineItem: true });
        this.props.vTerminal
          .searchingLineItems(e.target.value, "bill")
          .then((res) => {
            this.setState({ searchingLineItem: false });
          })
          .catch((error) => {
            this.setState({ searchingLineItem: false });
          });
      }catch(e){
        this.setState({ searchingLineItem: false });
      }
    
    } else {
      this.props.vTerminal.clearLineItems();
    }
  }

  closeSearchLineItemPanelDelay() {
    const _this = this;
    setTimeout(function () {
      _this.setState({ searchLineItemPanelIsOpen: false, lineItemToSearch: '', lineItemToModify: false });
    }, 500);
  }

  selectLineItem(position, e) {
    e.preventDefault();
    const itemSelected = toJS(this.props.vTerminal.lineItems[position]);

    this.props.bill.addItem({
      label: itemSelected.itemProductName,
      value: itemSelected.itemCost,
      quantity: itemSelected.itemQty,
      description: itemSelected.itemDescription,
    });

    this.props.vTerminal.selectLineItem(position);
    this.setState(
      { searchLineItemPanelIsOpen: false, lineItemToSearch: '', lineItemToModify: false, actionModal: 'add' },
      function () {
        this.props.vTerminal.clearLineItems();
      }
    );
  }

  openNewLineItemModal(e, saveToCatalog) {
    if (e) e.preventDefault();

    this.setState({
      newLineItemModalIsOpen: true,
      lineItemToModify: false,
      actionModal: 'add',
      saveToCatalog: saveToCatalog ? saveToCatalog : false,
    });

    this.closeSearchLineItemPanel();
  }

  openModalEdit(lineItemData) {
    this.setState({
      lineItemToModify: lineItemData,
      lineItemToSearch: '',
      newLineItemModalIsOpen: true,
      saveToCatalog: false,
      actionModal: 'edit',
    });
  }

  closeSearchLineItemPanel() {
    this.setState({ searchLineItemPanelIsOpen: false });
  }

  closeNewLineItemModal() {
    this.setState({ newLineItemModalIsOpen: false, lineItemToSearch: '' });
  }

  loadDataBill() {
    const billStatus = this.props.bill.status;
    const billDataLoaded = this.props.billDataLoaded;

    if (billStatus !== 'new' && !this.state.loadedData && billDataLoaded) {
      let billItems = billDataLoaded.BillItems ? billDataLoaded.BillItems : [];
      billItems.forEach((item) => {
        const lineItemTemplate = {
          name: item.itemProductName,
          type: 'quantity',
          label: item.itemProductName,
          value: item.itemCost,
          description: item.itemDescription,
          quantity: item.itemQty,
          showDescription: true,
        };

        this.props.vTerminal.cleanCategories();
        this.props.vTerminal.paymentPage.amount.categories.push(lineItemTemplate);
        this.props.bill.addItem(lineItemTemplate);
      });

      this.setState({ loadedData: true });
    }
  }

  componentDidMount() {
    this.loadDataBill();
  }

  componentDidUpdate() {
    this.loadDataBill();
  }

  focus(e){
    e.target.focus();
    e.target.setSelectionRange(0, 1000000000);
  }

  render() {
    const pay = this.props.bill.pay;
    return (
      <div className="section-form">
        <h6 className="mb-3">Bill Amount</h6>
        <div className="mb-3">
        <div className="form-floating">
          <NumberFormat
              onFocus={(e)=>this.focus(e)}
              thousandsGroupStyle="thousand"
              prefix="$"
              decimalSeparator="."
              displayType="input"
              type= "text"
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              autoComplete="off"
              id="totalAmount"
              name="totalAmount"
              value={ this.props.bill.items.length > 0 ? (parseFloat(pay.subtotal).toFixed(2)) : this.props.bill.billDetails.totalAmount}
              placeholder="Total Amount"
              className={"form-control"}
              onValueChange={(values) => this.props.bill.setBillDetails({totalAmount: values.value})}
              disabled={this.props.isView || this.props.bill.items.length > 0}
          />
          <label htmlFor="totalAmount">Total Amount</label>

          {this.props.bill.items.length > 0 && 
            
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>You have line items. If you want to overwrite the total amount, remove all line items. </Tooltip>}
            >
                <BiInfoCircle className='info-icon in-input cursorPointer' style={{opacity: "0.4"}}/>
            </OverlayTrigger>
          }
        </div>
        </div>

        <div className="form-floating">
          <input
            autoComplete="off"
            onFocus={(e) => this.searchingLineItem(e)}
            onBlur={(e) => this.closeSearchLineItemPanelDelay(e)}
            onChange={(e) => this.searchingLineItem(e)}
            value={this.state.lineItemToSearch}
            id="line-item"
            className={
              !this.state.searchingLineItem ? 'form-control search-input' : 'form-control search-input loading'
            }
            placeholder="Find or add line item…"
            disabled={this.props.isView || (this.props.vTerminal.getVendorSelected.PaypointEntryname ? false : true)}
          />
          <label htmlFor="line-item">Find or add line item…</label>

          <div
            className={this.state.searchLineItemPanelIsOpen ? 'search-dropdown-menu' : 'd-none search-dropdown-menu'}
          >
            <a
              style={{ color: '#10A0E3' }}
              href="/"
              onClick={(e) => this.openNewLineItemModal(e)}
              className="dropdown-item"
            >
              + Add{' '}
              {this.state.lineItemToSearch && this.state.lineItemToSearch !== '' ? (
                <>
                  <span className="text-dark">"{this.state.lineItemToSearch}"</span> as a One Time Item
                </>
              ) : (
                'a One Time Item'
              )}
            </a>
            <a
              style={{ color: '#10A0E3' }}
              href="/"
              onClick={(e) => this.openNewLineItemModal(e, true)}
              className="dropdown-item"
            >
              + Add{' '}
              {this.state.lineItemToSearch && this.state.lineItemToSearch !== '' ? (
                <>
                  <span className="text-dark">"{this.state.lineItemToSearch}"</span> to Items Catalog
                </>
              ) : (
                ' Item to Catalog'
              )}
            </a>
            {this.props.vTerminal.lineItems.map((record, i) => (
              <a key={i} href="/" onClick={(e) => this.selectLineItem(i, e)} className="dropdown-item">
                {record.itemProductName}{' '}
                <span style={{ float: 'right' }}>
                  ${this.props.global.numberWithCommas(parseFloat(record.itemCost).toFixed(2))}
                </span>
              </a>
            ))}
            {this.props.vTerminal.lineItems.length === 0 && this.state.lineItemToSearch.length > 2 && (
              <div className="small-grey ml-2">No results found</div>
            )}
          </div>
        </div>

        <ItemModal
          open={this.state.newLineItemModalIsOpen}
          close={this.closeNewLineItemModal}
          saveToCatalog={this.state.saveToCatalog}
          lineItemToSearch={this.state.lineItemToSearch}
          lineItemToModify={this.state.lineItemToModify}
          action={this.state.actionModal}
        />

        <ItemTable isView={this.props.isView} openModalEdit={this.openModalEdit} />
      </div>
    );
  }
}

export default SectionItemsDetails;
