import React from 'react'
import styles from '../../assets/css/styleCommandCenter.module.css'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { BiCheckCircle, BiMinusCircle } from 'react-icons/bi'

export default function FrameGroup({
	icon_frame,
	icon_btn,
	title_label,
	useBtn = true,
	useAdd = false,
	addClick = () => null,
	activeService = false,
	inactivateService = false,
	activeServiceClick = () => null,
	useEdit = true,
	editClick = () => null,
	useInactivate = false,
	inactivateClick = () => null,
	inactivateState = false,
	useOptBtn1 = false,
	iconOptBtn1,
	labelOptBtn1,
	classBtn1 = styles['btn'] +
		' ' +
		styles['no-border'] +
		' ' +
		styles['color-blue'],
	functionOpt1 = null,
	disabledOpt1 = false,
	useOptBtn2 = false,
	iconOptBtn2,
	labelOptBtn2,
	disabledOpt2 = false,
	classBtn2 = styles['btn'] +
		' ' +
		styles['no-border'] +
		' ' +
		styles['color-blue'],
	functionOpt2 = null,
	borderButton = false,
	showState = false,
	divComponent = '',
	statusState = false,
	btnDelete = true,
	deleteClick = () => null,
	useBtnArray = false,
	btnArray = [],
	deleteServices = false,
	deleteServiceClick = () => null
}) {
	return (
		<div
			className="w-100"
			style={{
				padding: '0 5px 8px 0',
				maxWidth: '924px',
				borderBottom: !borderButton
					? '1px solid #f4f5f7'
					: '1px solid #d9d9d9',
				paddingLeft: borderButton && '12px'
			}}>
			<div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
				<div
					className="d-flex flex-row align-items-center p-0"
					style={{ gap: '8px' }}>
					{icon_frame}
					<label
						className={
							styles['label-form'] + ' ' + styles['ft-s-18']
						}
						style={{ fontWeight: 700, lineHeight: '30px' }}>
						{title_label}
					</label>
					{showState && (
						<div
							style={{
								fontSize: '14px',
								fontWeight: '600',
								paddingInline: '20px'
							}}>
							<span
								className={`badge ${
									statusState ? 'bg-primary' : 'bg-light'
								} d-flex align-items-center justify-content-center ${
									styles['rounded-pill-no-label-frame']
								}`}
								style={{
									fontSize: '12px',
									fontWeight: '500',
									minWidth: '80px',
									height: '22px'
								}}>
								{statusState ? (
									<>
										{' '}
										<BiCheckCircle />
										&nbsp;&nbsp;Active
									</>
								) : (
									<>
										{' '}
										<BiMinusCircle />
										&nbsp;&nbsp;Inactive
									</>
								)}
							</span>
						</div>
					)}
				</div>
				<div className="d-flex flex-row flex-wrap justify-content-end align-items-center p-0 gap-2">
					{useBtn && (
						<DropdownButton
							menuAlign="right"
							title={icon_btn}
							size="sm"
							variant="default"
							className={styles['clear-icon-drop-down']}>
							<div
								className={styles['container-scroll']}
								style={{ maxHeight: '450px', width: '100%' }}>
								{useAdd && (
									<Dropdown.Item
										onClick={() => addClick()}
										style={{ fontSize: '13px' }}>
										Add Gateway
									</Dropdown.Item>
								)}
								{useAdd && !activeService && (
									<Dropdown.Divider />
								)}
								{activeService && (
									<Dropdown.Item
										onClick={() => activeServiceClick()}
										style={{ fontSize: '13px' }}>
										{inactivateService
											? 'Inactivate Service'
											: 'Activate Service'}
									</Dropdown.Item>
								)}
								{activeService && <Dropdown.Divider />}
								{useEdit && (
									<Dropdown.Item
										onClick={() => editClick()}
										style={{ fontSize: '13px' }}>
										Edit
									</Dropdown.Item>
								)}
								{useInactivate && (
									<Dropdown.Item
										onClick={() => inactivateClick()}
										style={{ fontSize: '13px' }}>
										{inactivateState
											? 'Inactivate Gateway'
											: 'Activate Gateway'}
									</Dropdown.Item>
								)}
								{btnDelete && <Dropdown.Divider />}
								{btnDelete && (
									<Dropdown.Item
										onClick={() => deleteClick()}
										style={{
											color: '#ff867c',
											fontSize: '13px'
										}}>
										Delete
									</Dropdown.Item>
								)}
								{useBtnArray &&
									btnArray.map((btn, index) => (
										<div
											key={title_label + index}
											style={{
												margin: '0',
												padding: '0'
											}}>
											{index > 0 && <Dropdown.Divider />}
											{btn.header && (
												<Dropdown.Header
													style={{
														fontSize: '13px'
													}}>
													{btn.header}
												</Dropdown.Header>
											)}
											{btn.useEdit && (
												<Dropdown.Item
													onClick={() =>
														btn.functionEdit()
													}
													style={{
														fontSize: '13px'
													}}>
													Edit
												</Dropdown.Item>
											)}
											{btn.useInactivate && (
												<Dropdown.Item
													onClick={() =>
														btn.functionInactivate()
													}
													style={{
														fontSize: '13px'
													}}>
													{btn.state
														? 'Inactivate Gateway'
														: 'Activate Gateway'}
												</Dropdown.Item>
											)}
											{btn.btnDelete && (
												<Dropdown.Item
													onClick={() =>
														btn.functionDelete()
													}
													style={{
														color: '#ff867c',
														fontSize: '13px'
													}}>
													Delete
												</Dropdown.Item>
											)}
										</div>
									))}

								{deleteServices && <Dropdown.Divider />}
								{deleteServices && (
									<Dropdown.Item
										onClick={() => deleteServiceClick()}
										style={{
											color: '#ff867c',
											fontSize: '13px'
										}}>
										Delete Service
									</Dropdown.Item>
								)}
							</div>
						</DropdownButton>
					)}

					{useOptBtn1 && (
						<button
							onClick={() => functionOpt1()}
							className={classBtn1}
							disabled={disabledOpt1}>
							{iconOptBtn1}
							{labelOptBtn1}
						</button>
					)}
					{useOptBtn2 && (
						<button
							onClick={() => functionOpt2()}
							className={classBtn2}
							disabled={disabledOpt2}>
							{iconOptBtn2}
							{labelOptBtn2}
						</button>
					)}
				</div>
			</div>
			{divComponent && divComponent}
		</div>
	)
}
