import React from 'react'
import { Modal } from 'react-bootstrap'
import MessageList from '../../../components/Applications/MessageList'

export default function NotesModal({ showModal, closeModal, notesEvents }) {
	return (
		<Modal
			style={{ textAlign: 'center' }}
			size="lg"
			show={showModal}
			onHide={() => closeModal()}
			centered
			scrollable={true}>
			<Modal.Header
				closeButton
				className="border-bottom-0 ps-5 pe-4">
				<Modal.Title style={{ fontWeight: '600' }}>RECENT NOTES</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-2">
				<MessageList notesEvents={notesEvents.slice(0, 10)} />
			</Modal.Body>
		</Modal>
	)
}
