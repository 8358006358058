import axios from "axios";
import {
  PayabliCookieManager,
  PayabliStorageManager,
} from "./localStorageManager";
import * as Sentry from "@sentry/react";

export class SecurityManager {
  // RefreshToken should use an actual refresh token instead of the access token
  static refreshToken = async (accessToken) => {
    if (typeof accessToken === 'object') {
      Sentry.captureEvent(new Error(`refreshToken accessToken is ${JSON.stringify(accessToken)}`));
    }

    if (!accessToken) {
      Sentry.captureEvent(new Error(`refreshToken accessToken is empty or: ${accessToken}`));
    }

    return axios
      .post(
        process.env.REACT_APP_URL_API + "user/authrefresh",
        {},
        {
          headers: { requestToken: accessToken },
        }
      )
      .then((res) => {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let pUser = encryptStorage.getItem("pUser");
        let remaining = new Date().getTime() + parseInt(res.data.remaining) * 60000;
        pUser.remaining = remaining;
        encryptStorage.setItem("pUser", pUser);

        PayabliStorageManager.setEncryptedLocalStorageKey(
          res.data.responseData
        );
        
        if(res.data.responseData){
            try{
                PayabliCookieManager.eraseCookie(`creatorToken_${process.env.REACT_APP_ENVIRONMENT}`);
                PayabliCookieManager.createCookie(`creatorToken_${process.env.REACT_APP_ENVIRONMENT}`, res.data.responseData, 1);
            }catch(e){
                Sentry.captureException(e);
                console.log(e);
            }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.log("setEncryptedLocalStorageKey", error);
        const { status } = error.response;
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        const pEntry = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`);
        if (typeof pEntry === 'object' || typeof pEntry === 'undefined') {
          Sentry.captureException(error);
          let value = JSON.stringify(pEntry) || 'undefined';
          Sentry.captureMessage(`pEntry is ${value}`);
        }

        if (status === 403) {
          return SecurityManager.logout(pEntry);
        }

        return Promise.reject(error);
      });
  };

  static logout = (pEntry = {}) => {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
       
        Sentry.setUser(null);

        if (Object.keys(pEntry).length === 0) {
          window.location = `/login`;
          return;
        }

        let accessToken = encryptStorage.getItem("pToken");
        PayabliCookieManager.eraseCookie(`${PayabliStorageManager.getEntryName()}_payabliLastTimeCookie_${process.env.REACT_APP_ENVIRONMENT}`);
        PayabliCookieManager.eraseCookie(`${PayabliStorageManager.getEntryName()}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`);
        PayabliCookieManager.eraseCookie(`creatorToken_${process.env.REACT_APP_ENVIRONMENT}`);
        PayabliCookieManager.eraseCookie(`creatorContainer_${process.env.REACT_APP_ENVIRONMENT}`);

        if (accessToken) {
          axios
            .get(process.env.REACT_APP_URL_API + "user/authlogout", {
              headers: { requestToken: accessToken },
            })
            .catch((error) => {
              window.location = `/${pEntry}/login`;
              Sentry.captureException(error);
            })
            .finally(() => {
              try {
                PayabliStorageManager.clearStorage(PayabliStorageManager.getEntryName());
              } catch (e) {
                Sentry.captureException(e);
                console.log(e);
              }
              if (pEntry) {
                window.location = `/${pEntry}/login`;
              }
            });
        }
        else{
          window.location = `/${pEntry}/login`;
        }
  };
}
