import React, { useContext } from 'react'
import styles from '../../../../assets/css/styleCommandCenter.module.css'
import { BiAddToQueue, BiDotsVerticalRounded } from 'react-icons/bi'
import { RiSettings3Line, RiAddFill } from 'react-icons/ri'
import FrameGroup from '../../../../components/commandCenter/FrameGroup'
import { Element } from 'react-scroll'
import ItemService from './ItemService'
import commandContext from '../../context/commandCenter_context'

export default function Services({ viewServices, showPopUpMessage, showAddService }) {
	const { services, updateService, deleteService, PayPoint } = useContext(commandContext)
	const { itemService } = ItemService({ updateService, deleteService, PayPoint, showAddService, viewServices, showPopUpMessage })

	const selectGateway = (gateway) => {
		switch (gateway) {
			case 'payabli.gp':
			case 'payabli.dummy':
			case 'payabli.repay':
			case 'payabli.checkcommerce':
				return 'Payabli'
			case 'legacy.nmi':
				return 'Legacy'
			default:
				return 'Not found'
		}
	}
	const selectProcessor = (processor) => {
		switch (processor) {
			case 'gp':
				return 'Global'
			case 'checkcommerce':
				return 'Check Commerce'
			case 'dummy':
				return 'Dummy'
			case 'repay':
				return 'REPAY'
			case 'nmi':
				return 'NMI'
			default:
				return 'Not found'
		}
	}

	return (
		<Element
			name="Services"
			className="d-flex justify-content-center w-100">
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={<BiAddToQueue className={styles['icon26'] + ' ' + styles['color-blue']} />}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label="Services"
					useBtn={false}
					useOptBtn1={true}
					labelOptBtn1="VIEW & MANAGE SERVICE DETAILS"
					iconOptBtn1={<RiSettings3Line className={styles['icon24']} />}
					classBtn1={styles['btn'] + ' ' + styles['no-border'] + ' ' + styles[PayPoint.idPaypoint <= 0 || PayPoint.entryPoints.length <= 0 ? 'color-disabled' : 'color-blue']}
					functionOpt1={() => viewServices({ element: 'CreditCard', tab: 'moneyIn' })}
					disabledOpt1={PayPoint.idPaypoint <= 0 || PayPoint.entryPoints.length <= 0 ? true : false}
					useOptBtn2={true}
					labelOptBtn2="ADD SERVICE"
					iconOptBtn2={<RiAddFill className={styles['icon20']} />}
					classBtn2={styles['btn'] + ' ' + styles[PayPoint.idPaypoint <= 0 || PayPoint.entryPoints.length <= 0 ? 'border-disabled' : 'border-blue']}
					functionOpt2={() => showAddService()}
					disabledOpt2={PayPoint.idPaypoint <= 0 || PayPoint.entryPoints.length <= 0 ? true : false}
				/>

				<label
					className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
					style={{ fontWeight: '600', lineHeight: '24px' }}>
					Money In
				</label>
				<div className="d-flex flex-column w-100">
					<div className={styles['grid-container']}>
						<div className={'d-flex flex-row justify-content-between align-items-start flex-wrap p-0 ' + styles['d-none-657']}>
							<div style={{ minHeight: '32px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
								<label
									className={styles['label-form'] + ' ' + styles['ft-s-13'] + ' ' + styles['color-blue']}
									style={{ fontWeight: '600', lineHeight: '24px', minWidth: '180px' }}>
									Services
								</label>
								<label
									className={styles['label-form'] + ' ' + styles['ft-s-13'] + ' ' + styles['color-blue']}
									style={{ fontWeight: '600', lineHeight: '24px' }}>
									Gateway | Processor | Provider
								</label>
							</div>
						</div>

						{services.moneyin.card.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'services_card' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('Card', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyIn', 'card', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('Card', '', 'NA', 'moneyIn', 'card', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyin.card.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('Card', '', 'NA', 'moneyIn', 'card', 0, 0, false)}</div>}
						{services.moneyin.ach.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'services_ACH' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('ACH', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyIn', 'ach', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('ACH', '', 'NA', 'moneyIn', 'ach', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyin.ach.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('ACH', '', 'NA', 'moneyIn', 'ach', 0, 0, false)}</div>}
						{services.moneyin.check.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'services_Check' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('Check', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyIn', 'check', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('Check', '', 'NA', 'moneyIn', 'check', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyin.check.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('Check', '', 'NA', 'moneyIn', 'check', 0, 0, false)}</div>}
						{services.moneyin.cash.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'services_Cash' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('Cash', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyIn', 'cash', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('Cash', '', 'NA', 'moneyIn', 'cash', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyin.cash.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('Cash', '', 'NA', 'moneyIn', 'cash', 0, 0, false)}</div>}
						{services.moneyin.cloud.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'services_CloudDevices' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('Cloud Devices', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyIn', 'cloud', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('Cloud Devices', '', 'NA', 'moneyIn', 'cloud', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyin.cloud.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('Cloud Devices', '', 'NA', 'moneyIn', 'cloud', 0, 0, false)}</div>}
						{services.moneyin.wallet.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'services_Wallet' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('Wallet', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyIn', 'wallet', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('Wallet', '', 'NA', 'moneyIn', 'wallet', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyin.wallet.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('Wallet', '', 'NA', 'moneyIn', 'wallet', 0, 0, false)}</div>}
					</div>
				</div>
				<label
					className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
					style={{ fontWeight: '600', lineHeight: '24px' }}>
					Money Out
				</label>
				<div className="d-flex flex-column w-100">
					<div className={styles['grid-container']}>
						{services.moneyout.managed.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'Managed_Payables' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('Managed Payables', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyOut', 'managed', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('Managed Payables', '', 'NA', 'moneyOut', 'managed', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyout.managed.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('Managed Payables', '', 'NA', 'moneyOut', 'managed', 0, 0, false)}</div>}

						{services.moneyout.ach.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'ACH_Out' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('ACH', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyOut', 'ach', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('ACH', '', 'NA', 'moneyOut', 'ach', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyout.ach.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('ACH', '', 'NA', 'moneyOut', 'ach', 0, 0, false)}</div>}

						{services.moneyout.vcard.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'VirtualCard' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('Virtual Card', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyOut', 'vcard', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('Virtual Card', '', 'NA', 'moneyOut', 'vcard', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyout.vcard.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('Virtual Card', '', 'NA', 'moneyOut', 'vcard', 0, 0, false)}</div>}

						{services.moneyout.check.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'Physical Check' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('Physical Check', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyOut', 'check', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('Physical Check', '', 'NA', 'moneyOut', 'check', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyout.check.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('Physical Check', '', 'NA', 'moneyOut', 'check', 0, 0, false)}</div>}

						{services.moneyout.pushToCard.map((service, indexService) =>
							service.Gateways !== null && service.Gateways.length > 0 ? (
								service.Gateways.map((gateway, indexGateways) => (
									<div
										key={'PushToCard' + indexGateways}
										className="d-flex flex-column align-items-start p-0">
										{itemService('Push To Card', selectGateway(gateway.GatewayName) + ' | ' + selectProcessor(gateway.Connector.Name) + ' | ' + service.ServiceProviderName, gateway.Connector.Enabled, 'moneyOut', 'pushToCard', indexService, indexGateways, false)}
									</div>
								))
							) : (
								<div
									key={'services_CloudDevices' + 0}
									className="d-flex flex-column align-items-start p-0">
									{itemService('Push To Card', '', 'NA', 'moneyOut', 'pushToCard', 0, 0, false, true)}
								</div>
							)
						)}
						{services.moneyout.pushToCard.length <= 0 && <div className="d-flex flex-column align-items-start p-0">{itemService('Push To Card', '', 'NA', 'moneyOut', 'pushToCard', 0, 0, false)}</div>}
					</div>
				</div>
			</div>
		</Element>
	)
}
