import React from 'react'
import { inject, observer } from 'mobx-react'
import { TopBar } from '../../../components/TopBar'
import { MainTopBarMenu } from '../../../components/MainTopBarMenu'
import { BoardingLinks } from '../../../components/BoardingLinks'
import { Layout } from '../../../components/Layout'
import TemplateManagement from './TemplateManagement'
import ManageTemplate from '../../../store/Template/ManageTemplate'

@inject('global')
@observer
class Template extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		this.props.global.protect(this.props.history)
		this.props.global.setLoading(true)
	}

	render() {
		return (
			<Layout {...this.props}>
				<TopBar>
					<MainTopBarMenu />
					<div className="top-bar-sub">
						<BoardingLinks
							selected="templates"
							goback="true"
							gobackHref={'/' + this.props.global.getURLEntry() + '/boarding/templates'}
						/>
					</div>
				</TopBar>
				<ManageTemplate
					setLoading={(state) => this.props.global.setLoading(state)}
					newTemplate={this.props.match.params.id ? false : true}>
					<TemplateManagement
						isLoading={this.props.global.isLoading}
						newTemplate={this.props.match.params.id ? false : true}
					/>
				</ManageTemplate>
			</Layout>
		)
	}
}
export { Template }
