import React from 'react';
import { inject, observer } from 'mobx-react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PayabliStorageManager } from '../api/localStorageManager'

import {BiHelpCircle} from "@react-icons/all-files/bi/BiHelpCircle";
import {BiUserCircle} from "@react-icons/all-files/bi/BiUserCircle";

@inject('store','global')
@observer
class MainTopBarMenu extends React.Component {
    getName(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let pEntry = encryptStorage.getItem(this.props.global.getURLEntry()+"_pEntry");
        if(encryptStorage && pEntry){
            return pEntry.legalName;
        }
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : null;
        return (
            <div className="text-right top-bar-sub">
                <div className="org-name dark-grey">{this.getName()}</div> <span className="grey profile-name" style={{margin:"0"}}>|</span>
                <div className="profile-name" style={{marginLeft: "20px"}}>{encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').name ? encryptStorage.getItem('pUser').name : ""}</div>
                {/*<a href="/" className="icon"><BiMailSend/></a>
                <a href="/" className="icon"><BiBell/></a>*/}
                <a href="mailto:support@payabli.com" className="icon"><BiHelpCircle/></a>
                <DropdownButton 
                menuAlign="right"
                title={<BiUserCircle/>}
                className="icon btn-dropdown-top-menu"
                variant="default"
                data-qaid="topMenuRightUserIcon"
                >
                    {(encryptStorage && userPermissions && userPermissions.indexOf("Profile") !== -1) &&
                    <a className="dropdown-item" href={"/"+this.props.global.getURLEntry()+"/profile/"+ encryptStorage.getItem('pUser').id} data-qaid="topMenuRightUserMyProfile"> My Profile</a>
                    }
                    {encryptStorage &&
                    <Dropdown.Item as={Link} to={"/"+ (encryptStorage.getItem(this.props.global.getURLEntry()+"_pEntry") ? encryptStorage.getItem(this.props.global.getURLEntry()+"_pEntry").pEntry : "") + "/logout"} eventKey="1" data-qaid="topMenuRightUserLogout">Logout</Dropdown.Item>
                    }
                </DropdownButton>
            </div>
        )
    }
}

export { MainTopBarMenu };