import React, { useContext } from 'react'
import Switch from 'react-switch'
import ServiceSetup from './ServiceSetup'
import PricingType from './PricingType/PricingType'
import CardFees from './CardFees'
import AchFees from './AchFees'
import MgmFees from './mgmFees'
import templatesContext from '../../context/templates_context'

export default function TabServicePricing() {
	const {
		RootTemplate,
		LoadingState,
		ServicesTemplate,
		handleChangeService
	} = useContext(templatesContext)

	const gatewayList = (typeService, type) => {
		if (typeService === 'moneyin') {
			switch (type.toUpperCase()) {
				case 'CARD':
				case 'ACH':
					return [
						{ value: '', text: 'Select' },
						{ value: 'payabli', text: 'Payabli' },
						{ value: 'legacy', text: 'Legacy' }
					]
				default:
					return [{ value: '', text: 'Select' }]
			}
		} else {
			switch (type.toUpperCase()) {
				case 'MGM':
					return [
						{ value: '', text: 'Select' },
						{ value: 'payabli', text: 'Payabli' }
					]
				default:
					return [{ value: '', text: 'Select' }]
			}
		}
	}
	const processorList = (typeService, type) => {
		let gateway = ''
		switch (type.toUpperCase()) {
			case 'CARD':
				gateway = !LoadingState
					? ServicesTemplate.card.gateway.value
					: ''
				break
			case 'ACH':
				gateway = !LoadingState
					? ServicesTemplate.ach.gateway.value
					: ''
				break
			case 'MGM':
				gateway = !LoadingState
					? ServicesTemplate.mgm.gateway.value
					: ''
				break
			default:
				gateway = ''
				break
		}
		if (!gateway) return [{ value: '', text: 'Select' }]
		if (typeService === 'moneyin') {
			if (gateway === 'payabli') {
				switch (type.toUpperCase()) {
					case 'CARD':
						return [
							{ value: '', text: 'Select' },
							{ value: 'gp', text: 'Global' }
						]
					case 'ACH':
						return [
							{ value: '', text: 'Select' },
							{ value: 'gp', text: 'Global' },
							{ value: 'checkcommerce', text: 'Check Commerce' }
						]
					default:
						return [{ value: '', text: 'Select' }]
				}
			} else if (gateway === 'legacy') {
				switch (type.toUpperCase()) {
					case 'CARD':
					case 'ACH':
						return [
							{ value: '', text: 'Select' },
							{ value: 'nmi', text: 'NMI' }
						]
					default:
						return [{ value: '', text: 'Select' }]
				}
			} else {
				return [{ value: '', text: 'Select' }]
			}
		} else {
			if (gateway === 'payabli') {
				switch (type.toUpperCase()) {
					case 'MGM':
						return [
							{ value: '', text: 'Select' },
							{ value: 'repay', text: 'Repay' },
							{ value: 'gp', text: 'Global' },
							{ value: 'tsys', text: 'TSYS' }
						]
					case 'ACH':
						return [
							{ value: 'checkcommerce', text: 'Check Commerce' }
						]
					default:
						return [{ value: '', text: 'Select' }]
				}
			} else {
				return [{ value: '', text: 'Select' }]
			}
		}
	}

	return (
		<>
			<h5>Services and Pricing</h5>
			<p className="small mb-4 dark-grey">
				Select the services and pricing configuration that will be
				offered and displayed.
			</p>

			{(RootTemplate.rootTemplate.servicesData.card.visible ||
				RootTemplate.isRoot) && (
				<>
					<div className="row">
						<div className="col-md-4">
							<h6 className="card-title">Credit Card Service</h6>
						</div>
						<div className="col-md-2">
							<label className="d-flex flex-row align-items-center">
								<Switch
									onChange={(value) =>
										handleChangeService(
											'card',
											'',
											'cardAcceptance',
											'visible',
											value
										)
									}
									checked={
										!LoadingState &&
										ServicesTemplate.card?.cardAcceptance
											.visible
									}
									onColor="#10A0E3"
									onHandleColor="#ffffff"
									handleDiameter={20}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={20}
									width={38}
									className="react-switch"
									id={'cardAcceptance'}
								/>
								<span className="ml-2 small">
									Card Acceptance
								</span>
							</label>
						</div>
					</div>
					<ServiceSetup
						type="card"
						typeService="moneyin"
						gatewayList={gatewayList}
						processorList={processorList}
					/>
					<PricingType type="card" />
					<CardFees />
				</>
			)}
			{(RootTemplate.isRoot ||
				RootTemplate.rootTemplate.servicesData.ach.visible) && (
				<>
					<div className="row mt-5 mb-2">
						<div className="col-md-4">
							<h6 className="card-title">ACH Service</h6>
						</div>
						<div className="col-md-2">
							<label className="d-flex flex-row align-items-center">
								<Switch
									onChange={(value) =>
										handleChangeService(
											'ach',
											'',
											'achAcceptance',
											'visible',
											value
										)
									}
									checked={
										!LoadingState &&
										ServicesTemplate.ach?.achAcceptance
											.visible
									}
									onColor="#10A0E3"
									onHandleColor="#ffffff"
									handleDiameter={20}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={20}
									width={38}
									className="react-switch"
									id={'achAcceptance'}
								/>
								<span className="ml-2 small">
									ACH Acceptance
								</span>
							</label>
						</div>
					</div>
					<ServiceSetup
						type="ach"
						typeService="moneyin"
						gatewayList={gatewayList}
						processorList={processorList}
					/>
					<PricingType type="ach" />
					<AchFees />
				</>
			)}
			{(RootTemplate.isRoot ||
				RootTemplate.rootTemplate.servicesData.mgm?.visible) && (
				<>
					<div className="row mt-5 mb-2">
						<div className="col-md-4">
							<h6 className="card-title">Managed Payables</h6>
						</div>
						<div className="col-md-2">
							<label className="d-flex flex-row align-items-center">
								<Switch
									onChange={(value) =>
										handleChangeService(
											'mgm',
											'',
											'mgmAcceptance',
											'visible',
											value
										)
									}
									checked={
										!LoadingState &&
										ServicesTemplate.mgm?.mgmAcceptance
											.visible
									}
									onColor="#10A0E3"
									onHandleColor="#ffffff"
									handleDiameter={20}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={20}
									width={38}
									className="react-switch"
									id={'mgmAcceptance'}
								/>
								<span className="ml-2 small">
									Managed Payables Acceptance
								</span>
							</label>
						</div>
					</div>
					<ServiceSetup
						type="mgm"
						typeService="moneyout"
						gatewayList={gatewayList}
						processorList={processorList}
					/>
					<PricingType type="mgm" />
					<MgmFees />
				</>
			)}
		</>
	)
}
