import React, { useState, useEffect, useRef } from 'react'
import styles from '../../../../../assets/css/styleCommandCenter.module.css'
import { RiAddFill } from 'react-icons/ri'
import { BsTrash } from 'react-icons/bs'
import { AiOutlineBank } from 'react-icons/ai'
import RowContainer from '../../../../../components/commandCenter/RowContainer'
import { OverlayTrigger, Tooltip, Alert, Button } from 'react-bootstrap'
import { RiCloseLine } from 'react-icons/ri'
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle'

export default function BankingInformation({ inputValues, handleAdd, handleRemove, handleChangeBank, Banking, setClassValidate, classValidate, showAlertFees, setShowAlertFees, addBankAccount }) {
	const [removingBank, setRemovingBank] = useState(false)
	const refDeposit = useRef([]),
		//refChargeBacks = useRef([]),
		refWithdrawalsFees = useRef([])

	useEffect(() => {
		if (removingBank) {
			deleteValidate()
			setRemovingBank(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removingBank])

	const unCheckRefBank = (index) => {
		refDeposit.current[index].checked = false
		//refChargeBacks.current[index].checked = false
		refWithdrawalsFees.current[index].checked = false
	}
	const findClassValidate = (id) => {
		let type = ''
		classValidate.BankData.forEach((data) => {
			if (data.id === id) {
				type = data.type
				return
			}
		})
		return type
	}
	const handleClassValidate = (id, value) => {
		const validateBankData = [],
			validateCheck = []

		if (inputValues.BankData.length > 1) {
			for (let i = 0; i < inputValues.BankData.length; i++) {
				if (value.toString().trim() !== '0' && value.toString().trim() === inputValues.BankData[i].idSelect.toString().trim() && inputValues.BankData[i].id !== id) {
					validateBankData.push({ id: inputValues.BankData[i].id, type: 'duplicate' })
					validateBankData.push({ id: id, type: 'duplicate' })
				}
			}
			for (let i = 0; i < inputValues.BankData.length; i++) {
				for (let k = 0; k < inputValues.BankData.length; k++) {
					if (inputValues.BankData[k].idSelect.toString().trim() !== '0' && inputValues.BankData[k].idSelect.toString().trim() === inputValues.BankData[i].idSelect.toString().trim() && inputValues.BankData[k].id !== inputValues.BankData[i].id && inputValues.BankData[k].id !== id && inputValues.BankData[i].id !== id) {
						validateBankData.push({ id: inputValues.BankData[k].id, type: 'duplicate' })
					}
				}
			}
		}

		for (let i = 0; i < classValidate.BankData.length; i++) {
			if (classValidate.BankData[i].type === 'empty' && classValidate.BankData[i].id !== id) {
				validateBankData.push({ id: classValidate.BankData[i].id, type: 'empty' })
			}
		}

		for (let i = 0; i < classValidate.check.length; i++) {
			if (id !== classValidate.check[i].id) {
				validateCheck.push({ id: i, type: classValidate.check[i].type })
			}
		}
		setClassValidate({ ...classValidate, BankData: validateBankData, check: validateCheck })
	}
	const onBlurClassValidate = (id) => {
		const validateBankData = []
		for (let i = 0; i < inputValues.BankData.length; i++) {
			if (inputValues.BankData[i].idSelect.toString().trim() === '0' && inputValues.BankData[i].id === id) {
				validateBankData.push({ id: inputValues.BankData[i].id, type: 'empty' })
			} else {
				for (let k = 0; k < classValidate.BankData.length; k++) {
					if (classValidate.BankData[k].id === inputValues.BankData[i].id) {
						validateBankData.push({ id: classValidate.BankData[k].id, type: classValidate.BankData[k].type })
					}
				}
			}
		}
		setClassValidate({ ...classValidate, BankData: validateBankData })
	}
	const findCheckClassValidate = (id, element) => {
		let type = false
		classValidate.check.forEach((data) => {
			if (data.id === id && data.type === element) {
				type = true
				return
			}
		})

		return type
	}
	const handleCheck = (idBank, type, checked) => {
		const validateCheck = [],
			validatePurpose = []

		if (inputValues.BankData.length > 1) {
			const deposits = { state: false, id: '' },
				//chargeBacks = { state: false, id: '' },
				withdrawalsFees = { state: false, id: '' }

			for (let i = 0; i < inputValues.BankData.length; i++) {
				if (idBank === inputValues.BankData[i].id && type === 'deposits') {
					if (checked && !deposits.state && !deposits.id) {
						deposits.state = true
						deposits.id = inputValues.BankData[i].id
					} else if (checked && deposits.state && deposits.id !== inputValues.BankData[i].id) {
						validateCheck.push({ id: inputValues.BankData[i].id, type: 'deposits' })
					}
				} else {
					if ((inputValues.BankData[i].bankAccountFunction === 0 && !deposits.state && !deposits.id) || (inputValues.BankData[i].bankAccountFunction === 2 && !deposits.state && !deposits.id)) {
						deposits.state = true
						deposits.id = inputValues.BankData[i].id
					} else if ((inputValues.BankData[i].bankAccountFunction === 0 && deposits.state && deposits.id !== inputValues.BankData[i].id) || (inputValues.BankData[i].bankAccountFunction === 2 && deposits.state && deposits.id !== inputValues.BankData[i].id)) {
						validateCheck.push({ id: inputValues.BankData[i].id, type: 'deposits' })
					}
				}

				/* if (idBank === inputValues.BankData[i].id && type === 'chargeBacks') {
					if (checked && !chargeBacks.state && !chargeBacks.id) {
						chargeBacks.state = true
						chargeBacks.id = inputValues.BankData[i].id
					} else if (checked && chargeBacks.state && chargeBacks.id !== inputValues.BankData[i].id) {
						validateCheck.push({ id: inputValues.BankData[i].id, type: 'chargeBacks' })
					}
				} else {
					if (inputValues.BankData[i].chargeBacks && !chargeBacks.state && !chargeBacks.id) {
						chargeBacks.state = true
						chargeBacks.id = inputValues.BankData[i].id
					} else if (inputValues.BankData[i].chargeBacks && chargeBacks.state && chargeBacks.id !== inputValues.BankData[i].id) {
						validateCheck.push({ id: inputValues.BankData[i].id, type: 'chargeBacks' })
					}
				} */

				if (idBank === inputValues.BankData[i].id && type === 'withdrawalsFees') {
					if (checked && !withdrawalsFees.state && !withdrawalsFees.id) {
						withdrawalsFees.state = true
						withdrawalsFees.id = inputValues.BankData[i].id
					} else if (checked && withdrawalsFees.state && withdrawalsFees.id !== inputValues.BankData[i].id) {
						validateCheck.push({ id: inputValues.BankData[i].id, type: 'withdrawalsFees' })
					}
				} else {
					if ((inputValues.BankData[i].bankAccountFunction === 1 && !withdrawalsFees.state && !withdrawalsFees.id) || (inputValues.BankData[i].bankAccountFunction === 2 && !withdrawalsFees.state && !withdrawalsFees.id)) {
						withdrawalsFees.state = true
						withdrawalsFees.id = inputValues.BankData[i].id
					} else if ((inputValues.BankData[i].bankAccountFunction === 1 && withdrawalsFees.state && withdrawalsFees.id !== inputValues.BankData[i].id) || (inputValues.BankData[i].bankAccountFunction === 2 && withdrawalsFees.state && withdrawalsFees.id !== inputValues.BankData[i].id)) {
						validateCheck.push({ id: inputValues.BankData[i].id, type: 'withdrawalsFees' })
					}
				}
			}
		}

		for (let i = 0; i < inputValues.BankData.length; i++) {
			if (idBank === inputValues.BankData[i].id) {
				if (!(type === 'deposits' ? checked : inputValues.BankData[i].bankAccountFunction === 0 || inputValues.BankData[i].bankAccountFunction === 2 ? true : false) && !(type === 'withdrawalsFees' ? checked : inputValues.BankData[i].bankAccountFunction === 1 || inputValues.BankData[i].bankAccountFunction === 2 ? true : false)) {
					validatePurpose.push({ id: inputValues.BankData[i].id, type: 'is-invalid' })
				}
			} else {
				for (let k = 0; k < classValidate.purpose.length; k++) {
					if (classValidate.purpose[k].type && classValidate.purpose[k].id !== idBank) {
						validatePurpose.push({ id: classValidate.purpose[k].id, type: classValidate.purpose[k].type })
					}
				}
			}
		}

		if (inputValues.BankData.length > 1) {
			setClassValidate({ ...classValidate, check: validateCheck, purpose: validatePurpose })
		} else if (inputValues.BankData.length <= 1) {
			setClassValidate({ ...classValidate, purpose: validatePurpose })
		}
	}
	const deleteValidate = () => {
		const validateBankData = [],
			validateCheck = []
		const deposits = { state: false, id: '' },
			//chargeBacks = { state: false, id: '' },
			withdrawalsFees = { state: false, id: '' }

		for (let i = 0; i < classValidate.BankData.length; i++) {
			if (classValidate.BankData[i].type === 'empty') {
				validateBankData.push({ id: classValidate.BankData[i].id, type: 'empty' })
			}
		}

		for (let i = 0; i < inputValues.BankData.length; i++) {
			for (let k = 0; k < inputValues.BankData.length; k++) {
				if (inputValues.BankData[k].idSelect.toString().trim() !== '0' && inputValues.BankData[k].idSelect === inputValues.BankData[i].idSelect && i !== k) {
					validateBankData.push({ id: inputValues.BankData[i].id, type: 'duplicate' })
					validateBankData.push({ id: inputValues.BankData[k].id, type: 'duplicate' })
				}
			}
		}

		if (inputValues.BankData.length > 1) {
			for (let i = 0; i < inputValues.BankData.length; i++) {
				if ((inputValues.BankData[i].bankAccountFunction === 0 && !deposits.state && !deposits.id) || (inputValues.BankData[i].bankAccountFunction === 2 && !deposits.state && !deposits.id)) {
					deposits.state = true
					deposits.id = inputValues.BankData[i].id
				} else if ((inputValues.BankData[i].bankAccountFunction === 0 && deposits.state && deposits.id !== inputValues.BankData[i].id) || (inputValues.BankData[i].bankAccountFunction === 2 && deposits.state && deposits.id !== inputValues.BankData[i].id)) {
					validateCheck.push({ id: inputValues.BankData[i].id, type: 'deposits' })
				}

				/* if (inputValues.BankData[i].chargeBacks && !chargeBacks.state && !chargeBacks.id) {
					chargeBacks.state = true
					chargeBacks.id = inputValues.BankData[i].id
				} else if (inputValues.BankData[i].chargeBacks && chargeBacks.state && chargeBacks.id !== inputValues.BankData[i].id) {
					validateCheck.push({ id: inputValues.BankData[i].id, type: 'chargeBacks' })
				} */

				if ((inputValues.BankData[i].bankAccountFunction === 1 && !withdrawalsFees.state && !withdrawalsFees.id) || (inputValues.BankData[i].bankAccountFunction === 2 && !withdrawalsFees.state && !withdrawalsFees.id)) {
					withdrawalsFees.state = true
					withdrawalsFees.id = inputValues.BankData[i].id
				} else if ((inputValues.BankData[i].bankAccountFunction === 1 && withdrawalsFees.state && withdrawalsFees.id !== inputValues.BankData[i].id) || (inputValues.BankData[i].bankAccountFunction === 2 && withdrawalsFees.state && withdrawalsFees.id !== inputValues.BankData[i].id)) {
					validateCheck.push({ id: inputValues.BankData[i].id, type: 'withdrawalsFees' })
				}
			}
		}
		setClassValidate({ ...classValidate, BankData: validateBankData, check: validateCheck })
		refDeposit.current = []
		//refChargeBacks.current = []
		refWithdrawalsFees.current = []
	}
	const findPurposeClassValidate = (id) => {
		let type = ''
		classValidate.purpose.forEach((data) => {
			if (data.id === id) {
				type = data.type
				return
			}
		})
		return type
	}
	const createElement = (id) => {
		const elements = []
		Banking.bankData.map((data) => elements.push({ value: data.id, element: <div style={{ paddingBottom: '2px' }}>{data.bankName + ' - ' + data.accountNumber}</div>, text: data.bankName + ' - ' + data.accountNumber, select: data.id === id ? true : false }))
		return elements
	}
	const createElementBtn = () => {
		const Buttons = []
		Buttons.push({
			style: { width: '100%', borderBottom: '1px solid #b1b8be' },
			button: (
				<button
					onClick={() => addBankAccount(Banking.bankData.length + 1)}
					className={styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-blue']}
					style={{ padding: '8px 0.75rem', width: '100%' }}>
					<AiOutlineBank className={styles['icon20']} />
					New Bank Account
				</button>
			)
		})
		return Buttons
	}

	return (
		<div className="d-flex flex-column">
			<div className="row justify-content-between align-items-center mb-3">
				<div className="col-8">
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Banking Information
					</label>
				</div>
				<div
					className="col-4 d-flex justify-content-end"
					style={{ paddingRight: '20px' }}></div>
			</div>
			{inputValues.BankData.map((BankData, indexK) => (
				<div
					key={'BankData' + BankData.id}
					style={{ width: '100%' }}>
					<div className="d-flex flex-column">
						<div
							className="d-flex justify-content-end gap-2"
							style={{ marginRight: '10px' }}>
							{inputValues.BankData.length > 1 && (
								<button
									onClick={() => handleRemove(BankData.id) | setRemovingBank(true)}
									className={styles['btn'] + ' ' + styles['btn-red-letter'] + ' ' + styles['no-border']}>
									<BsTrash className={styles['icon20']} />
									DELETE
								</button>
							)}

							{indexK + 1 === inputValues.BankData.length && indexK + 1 < (Banking.bankData.length > 3 ? 3 : Banking.bankData.length) && (
								<button
									onClick={() => handleAdd()}
									className={styles['btn'] + ' ' + styles['color-blue'] + ' ' + styles['border-blue']}>
									<RiAddFill className={styles['icon20']} />
									ADD BANK ACCOUNT
								</button>
							)}
						</div>

						<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0 mb-3">
							<div className="d-flex flex-column align-items-start p-0">
								<RowContainer
									inputDisabled={false}
									classNameInput={findClassValidate(BankData.id) ? 'is-invalid' : ''}
									TooltipValid={findClassValidate(BankData.id) === 'duplicate' ? 'Duplicate bank account' : ''}
									labelValue="Bank Account"
									name={'bank[' + BankData.id + ']'}
									value={BankData.idSelect}
									onBlur={() => onBlurClassValidate(BankData.id)}
									setValue={(values) => handleChangeBank(values, BankData.id) | handleClassValidate(BankData.id, values) | unCheckRefBank(indexK)}
									useElement={true}
									listElement={createElement(BankData.idSelect)}
									listBtnElement={createElementBtn()}
									placeholder="Select a bank"
									maxHeightScroll="235px"
								/>
							</div>
							<div className="d-flex flex-column align-items-start p-0">
								<label
									className={styles['label-form'] + ' ' + styles['ft-s-13']}
									style={{ fontWeight: 600, lineHeight: '20px', letterSpacing: '0.0025em', width: '424px', minWidth: '20px' }}>
									Purpose &nbsp;&nbsp;
									{/* <OverlayTrigger
										key={'right'}
										placement={'right'}
										overlay={<Tooltip>{''}</Tooltip>}>
										<BiInfoCircle className={styles['icon15'] + ' ' + styles['color-blue']} />
									</OverlayTrigger> */}
								</label>

								<div className="d-flex flex-row align-items-center justify-content-center gap-1 p-0">
									<div style={{ border: findPurposeClassValidate(BankData.id) === 'is-invalid' && '1px solid #dc3545', padding: findPurposeClassValidate(BankData.id) === 'is-invalid' && '5px 10px', borderRadius: '.25rem' }}>
										<div
											className={'d-flex flex-wrap flex-row align-items-center w-100 ' + styles['columns-sm']}
											style={{ padding: '0', gap: '25px' }}>
											<RowContainer
												inputType="Checkbox"
												refs={(e) => (e && !refDeposit.current.includes(e) ? (refDeposit.current = [...refDeposit.current, e]) : '')}
												value={BankData.bankAccountFunction === 0 || BankData.bankAccountFunction === 2 ? true : false}
												TooltipCaption={findCheckClassValidate(BankData.id, 'deposits') ? 'You cannot have more than one bank account for deposits' : ''}
												errorValid={findCheckClassValidate(BankData.id, 'deposits') ? true : false}
												inputDisabled={BankData.idSelect !== '0' ? (BankData.bankAccountFunctionResp === 0 || BankData.bankAccountFunctionResp === 2 ? false : true) : true}
												name={'deposits[' + BankData.id + ']'}
												labelValue="Deposits"
												setValue={(checked) =>
													BankData.bankAccountFunction === 0 && checked === false
														? handleChangeBank('', BankData.id, 'bankAccountFunction', 3) | handleCheck(BankData.id, 'deposits', checked)
														: handleChangeBank('', BankData.id, 'bankAccountFunction', BankData.bankAccountFunction === 1 && checked === true ? 2 : BankData.bankAccountFunction === 1 && checked === false ? 1 : BankData.bankAccountFunction === 2 && checked === false ? 1 : BankData.bankAccountFunction === 0 && checked === false ? 0 : 0) | handleCheck(BankData.id, 'deposits', checked)
												}
											/>
											<RowContainer
												inputType="Checkbox"
												refs={(e) => (e && !refWithdrawalsFees.current.includes(e) ? (refWithdrawalsFees.current = [...refWithdrawalsFees.current, e]) : '')}
												value={BankData.bankAccountFunction === 1 || BankData.bankAccountFunction === 2 ? true : false}
												TooltipCaption={findCheckClassValidate(BankData.id, 'withdrawalsFees') ? 'You cannot have more than one bank account for withdrawals/ Fees' : ''}
												errorValid={findCheckClassValidate(BankData.id, 'withdrawalsFees') ? true : false}
												inputDisabled={BankData.idSelect !== '0' ? (BankData.bankAccountFunctionResp === 1 || BankData.bankAccountFunctionResp === 2 ? false : true) : true}
												name={'withdrawalsFees[' + BankData.id + ']'}
												labelValue="Withdrawals/ Fees"
												setValue={(checked) =>
													BankData.bankAccountFunction === 1 && checked === false
														? handleChangeBank('', BankData.id, 'bankAccountFunction', 3) | handleCheck(BankData.id, 'withdrawalsFees', checked)
														: handleChangeBank('', BankData.id, 'bankAccountFunction', BankData.bankAccountFunction === 0 && checked === true ? 2 : BankData.bankAccountFunction === 0 && checked === false ? 0 : BankData.bankAccountFunction === 2 && checked === false ? 0 : BankData.bankAccountFunction === 1 && checked === false ? 1 : 1) | handleCheck(BankData.id, 'withdrawalsFees', checked)
												}
											/>
										</div>
										{/* <RowContainer
												refs={(e) => (e && !refChargeBacks.current.includes(e) ? (refChargeBacks.current = [...refChargeBacks.current, e]) : '')}
												setValue={(checked) => handleChangeBank('', BankData.id, 'chargeBacks', checked) | handleCheck(BankData.id, 'chargeBacks', checked)}
												defaultChecked={BankData.chargeBacks}
												TooltipCaption={findCheckClassValidate(BankData.id, 'chargeBacks') ? 'You cannot have more than one bank account for ACH chargebacks/returns' : ''}
												errorValid={findCheckClassValidate(BankData.id, 'chargeBacks') ? true : false}
												//checkDisabled={BankData.idSelect !== '0' ? false : true}
												checkDisabled={true}
												hidden={true}
												name={'chargeBacks[' + BankData.id + ']'}
												labelValue="Chargebacks/ ACH Returns"
											/> */}
									</div>
									{findPurposeClassValidate(BankData.id) === 'is-invalid' && (
										<OverlayTrigger
											key={'right'}
											placement={'right'}
											overlay={<Tooltip>{'You must select a purpose for the bank account'}</Tooltip>}>
											<BiInfoCircle className={`${styles['icon15']} ${styles['color-error']}`} />
										</OverlayTrigger>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
			{showAlertFees && (
				<Alert
					style={{ marginTop: '20px', width: '100%' }}
					show={showAlertFees}
					variant="danger">
					<div className="d-flex flex-row justify-content-between align-items-center gap-1">
						<p className="m-0">At least one bank account must be assigned as withdrawal / fees purpose.</p>
						<Button
							onClick={() => setShowAlertFees(false)}
							variant="">
							<RiCloseLine className={styles['icon24'] + ' ' + styles['color-error']} />
						</Button>
					</div>
				</Alert>
			)}
		</div>
	)
}
