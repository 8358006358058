import React, { useContext } from 'react'
import styles from '../../../../../assets/css/styleCommandCenter.module.css'
import Collapse from 'react-bootstrap/Collapse'
import commandContext from '../../../context/commandCenter_context'
import GlobalGp from './Global_gp'
import Nmi from './Nmi'
import CheckCommerce from './CheckCommerce'
import Repay from './Repay'
import BankingInformation from './BankingInformation'

export default function Credential({ showSection, SetInputValues, inputValues, setClassValidate, classValidate, showAlertFees, setShowAlertFees, addBankAccount }) {
	const { Banking } = useContext(commandContext)

	const handleChangeBank = (idSelect, idBank, check, value) => {
		const newDataBank = inputValues.BankData.map((dataBank) => {
			if (dataBank.id === idBank) {
				if (!check) {
					const result = Banking.bankData.find(({ id }) => id === parseInt(idSelect))
					if (result) {
						return {
							...dataBank,
							idSelect: result.id,
							accountNumber: result.accountNumber,
							bankAccountHolderName: result.bankAccountHolderName,
							bankAccountHolderType: result.bankAccountHolderType,
							bankName: result.bankName,
							routingAccount: result.routingAccount,
							typeAccount: result.typeAccount,
							bankAccountFunctionResp: result.bankAccountFunction,
							bankAccountFunction: 3
						}
					} else {
						return dataBank
					}
				} else {
					return {
						...dataBank,
						[check]: value
					}
				}
			}
			return dataBank
		})
		SetInputValues({ ...inputValues, BankData: newDataBank })
	}

	const handleAdd = () => {
		const newId = Math.random().toString(36).substring(2, 18)
		const newBank = {
			id: 'B' + newId,
			idSelect: '0',
			accountNumber: '',
			bankAccountHolderName: '',
			bankAccountHolderType: '',
			bankName: '',
			routingAccount: '',
			typeAccount: '',
			bankAccountFunction: 3
		}

		SetInputValues({ ...inputValues, BankData: [...inputValues.BankData, newBank] })
	}

	const handleRemove = (id) => {
		const newDataBank = inputValues.BankData.filter((data) => data.id !== id)
		SetInputValues({ ...inputValues, BankData: newDataBank })
	}

	return (
		<Collapse in={showSection.credential}>
			<div id="collapse-section">
				<div className="d-flex flex-column gap-3">
					{inputValues.processor !== 'dummy' && inputValues.processor && (
						<>
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
								style={{ fontWeight: '600', lineHeight: '24px' }}>
								Credential
							</label>

							<GlobalGp
								inputValues={inputValues}
								SetInputValues={SetInputValues}
								classValidate={classValidate}
								setClassValidate={setClassValidate}
							/>

							<Nmi
								inputValues={inputValues}
								SetInputValues={SetInputValues}
								classValidate={classValidate}
								setClassValidate={setClassValidate}
							/>

							<CheckCommerce
								inputValues={inputValues}
								SetInputValues={SetInputValues}
								classValidate={classValidate}
								setClassValidate={setClassValidate}
							/>

							<Repay
								inputValues={inputValues}
								SetInputValues={SetInputValues}
								classValidate={classValidate}
								setClassValidate={setClassValidate}
							/>

							<BankingInformation
								inputValues={inputValues}
								handleAdd={handleAdd}
								handleRemove={handleRemove}
								handleChangeBank={handleChangeBank}
								Banking={Banking}
								classValidate={classValidate}
								setClassValidate={setClassValidate}
								showAlertFees={showAlertFees}
								setShowAlertFees={setShowAlertFees}
								addBankAccount={addBankAccount}
							/>
						</>
					)}
				</div>
			</div>
		</Collapse>
	)
}
