import React from 'react';
import { inject, observer } from 'mobx-react';
import {Link} from "react-router-dom";
import { BiChevronLeft } from 'react-icons/bi';
import { PayabliStorageManager } from '../api/localStorageManager';

@inject('reports', 'global')
@observer
class VendorsLinks extends React.Component {
     
    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];

        return (
            <div className="row">
            <div className="col-2">
                <div className="hide-sm">
                {this.props.goback && 
                    <a href={this.props.gobackLink} className="btn btn-light text-transform-normal"><BiChevronLeft/> { this.props.gobackText ? this.props.gobackText: "Go back" }</a>
                }
                </div>
            </div>
            <div className="col-8 text-center">
            {userPermissions.indexOf("Customers") !== -1  &&
                <Link className={this.props.selected === 'overview'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/vendor/"+this.props.id}> Overview</Link>
            }
            {userPermissions.indexOf("Transaction") !== -1  &&
                <Link className={this.props.selected === 'payhistory' || this.props.selected ==='transactions' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/report/transactions/out/"+this.props.id}>Pay History</Link>
            }
            {userPermissions.indexOf("Bills") !== -1  &&
                <Link className={this.props.selected === 'bills'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/report/bills/"+this.props.id}>Bills</Link>
            }  
            </div>
            <div className="col-2 text-right">
            </div>
        </div>
        )
    }
}

export { VendorsLinks };