import React from 'react';
import { Col } from "react-bootstrap";
import { inject, observer } from 'mobx-react';
import { PayabliStorageManager } from '../api/localStorageManager';

@inject('boarding','global')
@observer
class ReadOnlySimpleLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value:'', cname:'', error:false, show: true };
        this.showHide = this.showHide.bind(this);
    }

    componentDidMount() {
        if(this.props.iHideShow === true){
            this.setState({show: false});
        }
        this.setState({ value: this.props.iValue, cname: this.props.iName }, function () {
            this.props.boarding.setTemplateData(this.state.cname, this.state.value);
            this.props.boarding.setErrorData(this.state.cname, false);
        });
    }

    showHide(e){
        this.setState({show: !this.state.show})
    }


    render() {

        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        const hasManagement = userPermissions.indexOf("Management") !== -1 ? true: false;
        return (
            <Col md={this.props.full ? "12" : "6"}>
                    <div className="mb-4">
                        <label><b>{this.props.iTitle}</b></label><br/>
                        {(this.props.iMask && this.props.iMask === 'money') &&
                            <>
                            ${this.props.global.numberWithCommas(parseFloat(this.state.value ? this.state.value : 0).toFixed(2))}
                            </>
                        }
                        { !this.props.iMask &&
                            <>
                            {
                                this.state.show === false ? ("***************") : (this.state.value ? this.state.value : "-")
                            }
                            </>
                        }
                        {hasManagement && this.props.iHideShow === true &&
                            <div onClick={(e)=> this.showHide(e)} className="float-right small cursor-pointer blue-text">{this.state.show ? "Hide" : "Show"}</div>
                        }
                    </div>
                </Col>
        )
    }
}

export { ReadOnlySimpleLink };