import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";

@inject('reports', 'filter')
@observer
class ViewPartialLinks extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="orgParentname" id="orgParentname" className={"form-control"} placeholder="Organization" onChange={(e) => this.props.filter.handleCaptureTextFilter('orgParentname(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('orgParentname(ct)', e)} value={this.props.filter.filterText.orgParentname ? this.props.filter.filterText.orgParentname : ''} />
                            <label htmlFor="orgParentname">Organization</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="referenceName" id="referenceName" className={"form-control"} placeholder="Title" onChange={(e) => this.props.filter.handleCaptureTextFilter('referenceName(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('referenceName(ct)', e)} value={this.props.filter.filterText.referenceName ? this.props.filter.filterText.referenceName : ''} />
                            <label htmlFor="referenceName">Reference</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="templateName" id="templateName" className={"form-control"} placeholder="Description" onChange={(e) => this.props.filter.handleCaptureTextFilter('templateName(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('templateName(ct)', e)} value={this.props.filter.filterText.templateName ? this.props.filter.filterText.templateName : ''} data-qaid="inputTemplateBoardingLinks"/>
                            <label htmlFor="templateName">Template</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="templateCode" id="templateCode" className={"form-control"} placeholder="Code" onChange={(e) => this.props.filter.handleCaptureTextFilter('templateCode(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('templateCode(ct)', e)} value={this.props.filter.filterText.templateCode ? this.props.filter.filterText.templateCode : ''} />
                            <label htmlFor="templateCode">Template Code</label>
                        </div>
                    </div>
                </div>
                <div className='row'>                    
                    <div className="col-sm-6 col-xs-12 mb-2">
                        <p className='mb-1'><small>Accept Register?</small></p>
                        <div className="form-group mt-1" style={{fontSize: '20px', display: 'inline-flex'}}>
                            <div className="icheck-primary mr-3">
                                <input type="radio" name="inlineRadioAcceptRegister" id="inlineRadioAcceptRegister" value="0" checked={this.props.filter.filterRadioOption.inlineRadioAcceptRegister ? true : false} onChange={(e) => this.props.filter.handleRadioOptionAcceptRegister(e, 'acceptRegister(ne)')} />
                                <label htmlFor="inlineRadioAcceptRegister">Yes</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="radio" name="inlineRadioNoAcceptRegister" id="inlineRadioNoAcceptRegister" value="0" checked={this.props.filter.filterRadioOption.inlineRadioNoAcceptRegister ? true : false} onChange={(e) => this.props.filter.handleRadioOptionAcceptRegister(e, 'acceptRegister(eq)')} />
                                <label htmlFor="inlineRadioNoAcceptRegister">No</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-2">
                        <p className='mb-1'><small>Accept Authentication?</small></p>
                        <div className="form-group mt-1" style={{fontSize: '20px', display: 'inline-flex'}}>
                            <div className="icheck-primary mr-3">
                                <input type="radio" name="inlineRadioAcceptAuth" id="inlineRadioAcceptAuth" value="0" checked={this.props.filter.filterRadioOptionTwo.inlineRadioAcceptAuth ? true : false} onChange={(e) => this.props.filter.handleRadioOptionAcceptAuth(e, 'acceptAuth(ne)')} />
                                <label htmlFor="inlineRadioAcceptAuth">Yes</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="radio" name="inlineRadioNoAcceptAuth" id="inlineRadioNoAcceptAuth" value="0" checked={this.props.filter.filterRadioOptionTwo.inlineRadioNoAcceptAuth ? true : false} onChange={(e) => this.props.filter.handleRadioOptionAcceptAuth(e, 'acceptAuth(eq)')} />
                                <label htmlFor="inlineRadioNoAcceptAuth">No</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p className='mb-1'><small>Last Updated</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="lastUpdatedCalendar" 
                                        name="lastUpdatedCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startLastUpdated ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startLastUpdated) + ' → ' : '') + (this.props.filter.stateDate.endLastUpdated ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endLastUpdated) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="lastUpdatedCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startLastUpdated ? this.props.filter.stateDate.startLastUpdated : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startLastUpdated', 'endLastUpdated', 'lastUpdated')}
                            startDate={this.props.filter.stateDate.startLastUpdated}
                            endDate={this.props.filter.stateDate.endLastUpdated}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endLastUpdated !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                </div>
        </>);
    }
}

export { ViewPartialLinks };