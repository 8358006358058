import React from 'react'
import { Modal } from 'react-bootstrap'
import { BiEnvelope } from 'react-icons/bi'
import InputContainer from '../../../components/inputStyles/InputContainer'
import GlobalStore from '../../../store/GlobalStore'

export default function SendEmailModal({
	saveEmailModalIsOpen,
	closeSaveEmailModal,
	saveEmailModal,
	appemail,
	handleEmailChange
}) {
	return (
		<Modal
			style={{ textAlign: 'center' }}
			size="sm"
			show={saveEmailModalIsOpen}
			onHide={closeSaveEmailModal}
			centered>
			<Modal.Body>
				<BiEnvelope className="icon-modal" />
				<h5 data-qaid="enterEmailPopUp">Enter an Email</h5>
				<p className="small">
					Please enter an email address to send an access link that
					may be used to finish this application later.
				</p>
				<label className="small mb-2">
					<b>Email address</b>
				</label>
				<InputContainer
					inputType={'mask'}
					inputDisabled={false}
					placeholder={'name@domain.com'}
					value={appemail}
					setValue={(value) => handleEmailChange(value)}
					labelClassName="d-none"
					divClass="mb-2"
					classNameInput={`form-control ${appemail !== null && appemail !== '' && appemail.toString().length > 0 && !GlobalStore.emailValidation(appemail) ? 'is-invalid' : '' }`}
					mask={GlobalStore.maskValidator('email')}
				/>
				<button
					className="btn btn-primary"
					onClick={(e) => saveEmailModal()}
					data-qaid="sendEmailButton">
					Send
				</button>
			</Modal.Body>
		</Modal>
	)
}
