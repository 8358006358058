import React, { useState, useContext, useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import TabTemplateDetails from './Tabs/TabTemplateDetails'
import TabBusinessDetails from './Tabs/TabBusinessDetails'
import TabOwnershipContact from './Tabs/TabOwnershipContact'
import TabProcessingInformation from './Tabs/TabProcessingInformation'
import TabServicePricing from './Tabs/TabServices/TabServicePricing'
import TabBankingDocument from './Tabs/TabBankingDocument'
import templatesContext from './context/templates_context'
import ModalClose from './modal/ModalClose'
import ModalNewTemplate from './modal/ModalNewTemplate'
import { BiDetail, BiUserCheck, BiCog, BiDollarCircle, BiCopy } from 'react-icons/bi'
import { CgTemplate} from 'react-icons/cg'
import { RiBankLine } from 'react-icons/ri'

export default function TemplateManagement({ isLoading, newTemplate }) {
	const { loadData, RootTemplate, validData, UpdateData } = useContext(templatesContext)
	const [loaded, SetLoaded] = useState(false)
	const [showModal, setShowModal] = useState({ close: false, newTemplate: false })
	const [key, setKey] = useState(!newTemplate ? 'main' : 'business')

	useEffect(() => {
		if (!loaded) load()
	})
	const load = async () => {
		SetLoaded(true)
		await loadData()
	}
	const saveTemplate = async () => {
		if (newTemplate) {
			const res = validData(setKey)
			if (res) setShowModal({ close: false, newTemplate: true })
		} else {
			await UpdateData(setKey)
		}
	}

	return (
		<div className="mt-body4">
			<div
				className="application-form view-application-form application-form-6"
				style={{ maxWidth: '100em', margin: 'auto' }}>
				<div
					className="application-tabs"
					style={{ padding: 0 }}>
					<div className="mb-4">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
								<h5
									className="mt-2"
									data-qaid={newTemplate ? 'newBoardingTemplatePage' : 'editBoardingTemplatePage'}>
									{newTemplate ? 'New Boarding Template' : 'Edit Boarding Template'}
								</h5>
								<p className="small-grey">Manage your Boarding Template</p>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left mt-2">
								<div className="d-flex flex-row align-items-center flex-wrap gap-3">
									<button
										className="btn btn-default btn-bordered-success mb-2"
										style={{ cursor: 'pointer' }}
										data-qaid="saveTemplateButton"
										onClick={() => saveTemplate()}>
										Save Template
									</button>
									<button
										className="btn btn-default bordered ml-0 mb-2"
										style={{ cursor: 'pointer', marginLeft: '0px' }}
										onClick={() => setShowModal({ close: true, newTemplate: false })}>
										Cancel
									</button>
								</div>
							</div>
						</div>
					</div>
					{!isLoading && (
						<form
							className="d-flex justify-content-center flex-column"
							style={{ marginBottom: '100px' }}>
							<Tabs
								activeKey={key}
								onSelect={(key) => setKey(key)}
								className="d-flex align-items-center justify-content-center "
								style={{ maxWidth: '60em' }}>
								{!newTemplate && (
									<Tab
										eventKey="main"
										title={
											<>
												<CgTemplate className="icon" />
												<br />
												<span>Template Details</span>
											</>
										}>
										<TabTemplateDetails />
									</Tab>
								)}
								<Tab
									eventKey="business"
									title={
										<>
											<BiDetail className="icon" />
											<br />
											<span>Business Details</span>
										</>
									}>
									{RootTemplate.rootTemplate !== null && (RootTemplate.rootTemplate.businessData.visible || RootTemplate.isRoot) && <TabBusinessDetails />}
								</Tab>
								<Tab
									eventKey="owners"
									title={
										<>
											<BiUserCheck className="icon" />
											<br />
											<span>Ownership and Contacts</span>
										</>
									}>
									{RootTemplate.rootTemplate !== null && (RootTemplate.rootTemplate.ownershipData.visible || RootTemplate.isRoot) && <TabOwnershipContact />}
								</Tab>
								<Tab
									eventKey="processing"
									title={
										<>
											<BiCog className="icon" />
											<br />
											<span>Processing Information</span>
										</>
									}>
									{RootTemplate.rootTemplate !== null && (RootTemplate.rootTemplate.processingData.visible || RootTemplate.isRoot) && <TabProcessingInformation />}
								</Tab>
								<Tab
									eventKey="services"
									title={
										<>
											{' '}
											<BiDollarCircle className="icon" />
											<br />
											<span>Services and Pricing</span>
										</>
									}>
									{RootTemplate.rootTemplate !== null && (RootTemplate.rootTemplate.servicesData.visible || RootTemplate.isRoot) && <TabServicePricing />}
								</Tab>
								<Tab
									eventKey="documents"
									title={
										<>
											<RiBankLine className="icon" />
											<br />
											<span>Banking and Documents</span>
										</>
									}>
									{RootTemplate.rootTemplate !== null && (RootTemplate.rootTemplate.documentsData.visible || RootTemplate.isRoot) && <TabBankingDocument />}
								</Tab>
							</Tabs>
						</form>
					)}
				</div>
			</div>

			{showModal.close && (
				<ModalClose
					showModal={showModal.close}
					closeModal={() => setShowModal({ close: false, newTemplate: false })}
				/>
			)}
			{showModal.newTemplate && (
				<ModalNewTemplate
					showModal={showModal.newTemplate}
					closeModal={() => setShowModal({ close: false, newTemplate: false })}
				/>
			)}
		</div>
	)
}
