import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiPaperPlane } from 'react-icons/bi';
import { GiReceiveMoney } from 'react-icons/gi';

import ChargeCustomer from './ChargeCustomer';

@inject('store', 'global', 'invoice', 'vTerminal')
@observer
class SectionPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentSelected: 'sendInvoice',
    };

    this.handleChangePayment = this.handleChangePayment.bind(this);
  }

  handleChangePayment(e) {
    const { value } = e.target;
    this.setState({ paymentSelected: value });

    if (value === 'sendInvoice') {
      this.props.invoice.updateInvoiceActionSelected('sendInvoice', {});
    }

    if (value === 'chargeCustomer') {
      this.props.invoice.updateInvoiceActionSelected('chargeCustomer', {});
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="section-form">
        <h6 className="mb-3">Payment</h6>

        <div className="payment-option with-separator">
          <div className="radio-selection">
            <div className="icheck-primary">
              <input
                name="payment"
                type="radio"
                id="sendInvoice"
                value="sendInvoice"
                onChange={(e) => this.handleChangePayment(e)}
                defaultChecked={this.state.paymentSelected === 'sendInvoice'}
              />
              <label htmlFor="sendInvoice">&nbsp;</label>
            </div>
          </div>

          <div className="radio-description">
            <div className="icon">
              <BiPaperPlane />
            </div>
            <div className="info">
              <div className="headline">Send Invoice</div>
              <div className="subtitle">
                <span className="text">Email invoice to customer with personalized link to pay</span>
              </div>
            </div>
          </div>
        </div>

        <div className="payment-option">
          <div className="radio-selection">
            <div className="icheck-primary">
              <input
                name="payment"
                type="radio"
                id="chargeCustomer"
                value="chargeCustomer"
                onChange={(e) => this.handleChangePayment(e)}
                defaultChecked={this.state.paymentSelected === 'chargeCustomer'}
                disabled={this.props.vTerminal.getCustomerSelected.PaypointEntryname ? false : true}
              />
              <label htmlFor="chargeCustomer">&nbsp;</label>
            </div>
          </div>

          <div className="radio-description">
            <div className="icon">
              <GiReceiveMoney />
            </div>
            <div className="info">
              <div className="headline">Charge customer now</div>
              <div className="subtitle">
                <span className="text">Enter a payment and send paid invoice via email</span>
              </div>
            </div>
          </div>
        </div>

        {this.state.paymentSelected === 'chargeCustomer' && <ChargeCustomer />}
      </div>
    );
  }
}

export default SectionPayment;
