import React, { useState, useContext } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import FrameGroup from '../../../components/commandCenter/FrameGroup'
import PayMethodAccepted from '../../../components/commandCenter/Services/PayMethodAccepted'
import Tiers from '../../../components/commandCenter/Services/Tiers'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import ShowGroupCard from '../../../components/commandCenter/Services/ShowGroupCard'
import Processor from '../../../components/commandCenter/Services/Processor'
import GroupStateService from '../../../components/commandCenter/Services/GroupStateService'
import BankAccount from '../../../components/commandCenter/Services/BankAccount'
import ButtonSaveCancel from '../../../components/commandCenter/ButtonSaveCancel'
import RowContainer from '../../../components/commandCenter/RowContainer'
import { Element } from 'react-scroll'
import { toast } from 'react-toastify'
import commandContext from '../context/commandCenter_context'
import Record from './hooks/Record'

export default function ServicesCard({ showModal, setShowModal, setTierProperty, setShowAddService, expand, loadService, addBankAccount, servicesType, type, titleCard, element, iconElement }) {
	const { services, handleChangeServices, handleAddBankTier, handleRemoveBankTier } = useContext(commandContext)
	const [inputDisabled, setInputDisabled] = useState('')
	const [showAlertFees, setShowAlertFees] = useState(false)
	const [classValidate, setClassValidate] = useState({
		gateway: '',
		processor: '',
		provider: '',
		accountId: '',
		nmi: {
			apiKey: '',
			gatewayId: ''
		},
		gp: {
			merchantId: '',
			tokenizationAccountName: '',
			tokenizationAccountId: '',
			transactionAccountName: '',
			transactionAccountId: '',
			fundingAccountName: '',
			fundingAccountId: ''
		},
		checkcommerce: {
			username: '',
			password: '',
			merchantNumber: ''
		},
		repay: {
			clientId: '',
			clientSecretKey: '',
			customerId: '',
			accountIdCredentials: ''
		},
		BankData: [],
		check: [],
		purpose: []
	})
	const [showWarningAccountId, setShowWarningAccountId] = useState(false)
	const { createBtn, deleteServ, activeInactiveServ, editService, clearService } = Record({ setInputDisabled, showModal, setShowModal, setShowAlertFees, setClassValidate, loadService, element })

	const validOpenGateway = () => {
		let openNewGateway = true
		services[servicesType][type][0].Gateways.forEach((gateways) => {
			if (!gateways.AccountId || gateways.AccountId === undefined || gateways.AccountId === null) {
				toast.error('To add new gateways in the service with the existing gateways require an identifier for the existing gateways, make the changes and try again to add a new gateway', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
				openNewGateway = false
				setShowWarningAccountId(true)
			}
		})
		if (openNewGateway) {
			setShowAddService({ addService: false, addGateway: true, type: type })
			setShowWarningAccountId(false)
		}
	}

	return (
		<Element
			name={element}
			className="d-flex justify-content-center w-100">
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={iconElement}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label={titleCard}
					useBtn={inputDisabled === '' ? true : false}
					showState={true}
					statusState={services[servicesType][type][0].ServiceEnabled}
					borderButton={true}
					useInactivate={services[servicesType][type].length >= 1 ? (services[servicesType][type][0].Gateways.length > 1 ? false : true) : true}
					inactivateClick={() =>
						services[servicesType][type].length >= 1 &&
						services[servicesType][type][0].Gateways.length <= 1 &&
						setShowModal({
							...showModal,
							message: services[servicesType][type][0].Gateways[0].Connector.Enabled ? 'This action will inactivate the selected Service Gateway.' : 'This action will activate the selected Service Gateway.',
							message2: 'Do you want to continue?',
							showMessage: true,
							blueBtn: true,
							btnCaption: services[servicesType][type][0].Gateways[0].Connector.Enabled ? 'INACTIVATE GATEWAY' : 'ACTIVATE GATEWAY',
							function: () => activeInactiveServ(servicesType, type, 0, 0, services[servicesType][type][0].Gateways[0].Connector.Enabled)
						})
					}
					inactivateState={services[servicesType][type][0].Gateways[0].Connector.Enabled}
					useAdd={true}
					addClick={() => validOpenGateway()}
					activeService={true}
					inactivateService={services[servicesType][type][0].ServiceEnabled}
					activeServiceClick={() =>
						setShowModal({
							...showModal,
							message: services[servicesType][type][0].ServiceEnabled ? 'This action will inactivate the selected Service.' : 'This action will activate the selected Service.',
							message2: 'Do you want to continue?',
							showMessage: true,
							blueBtn: true,
							btnCaption: services[servicesType][type][0].ServiceEnabled ? 'INACTIVATE SERVICE' : 'ACTIVATE SERVICE',
							function: () => activeInactiveServ(servicesType, type, 0, 0, false, services[servicesType][type][0].ServiceEnabled)
						})
					}
					useEdit={services[servicesType][type].length >= 1 ? (services[servicesType][type][0].Gateways.length > 1 ? false : true) : true}
					editClick={() => services[servicesType][type].length >= 1 && services[servicesType][type][0].Gateways.length <= 1 && setInputDisabled(0)}
					btnDelete={services[servicesType][type].length >= 1 ? (services[servicesType][type][0].Gateways.length > 1 ? false : true) : true}
					deleteClick={() => services[servicesType][type].length >= 1 && services[servicesType][type][0].Gateways.length <= 1 && setShowModal({ ...showModal, message: 'This action will delete the selected Service Gateway.', message2: 'Do you want to continue?', showMessage: true, blueBtn: false, btnCaption: 'DELETE SERVICE', function: () => deleteServ(servicesType, type, 0, 0) })}
					useBtnArray={services[servicesType][type].length >= 1 ? (services[servicesType][type][0].Gateways.length > 1 ? true : false) : false}
					btnArray={services[servicesType][type].length >= 1 && services[servicesType][type][0].Gateways.length > 1 && createBtn(servicesType, type)}
					divComponent={
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputDisabled={inputDisabled === '' ? true : false}
								classNameInput={classValidate.provider}
								divStyle={{ padding: inputDisabled === '' ? '0 0 0 35px' : '10px 0 10px 35px', gap: '8px' }}
								labelStyle={{ fontWeight: 600, lineHeight: '20px', letterSpacing: '0.0025em', width: 'auto', minWidth: '20px' }}
								labelValue="PROVIDER:"
								name={'provider[' + servicesType + '][' + type + ']'}
								value={services[servicesType][type][0].ServiceProviderName}
								setValue={(values) => handleChangeServices(servicesType, type, 0, 0, 'ServiceProviderName', values, false, false, 0, false, '', 0, false, false, false, false) | setClassValidate({ ...classValidate, provider: values ? '' : 'is-invalid' })}
								optionList={
									<>
										<option value="Payabli">PAYABLI</option>
										{/* {typeService === 'moneyin' && <option value="Ironwood">Ironwood</option>} */}
									</>
								}
							/>
						</div>
					}
					deleteServices={services[servicesType][type].length >= 1 ? (services[servicesType][type][0].Gateways.length > 1 ? true : false) : false}
					deleteServiceClick={() => setShowModal({ ...showModal, message: 'This action will delete the selected Service.', message2: 'Do you want to continue?', showMessage: true, blueBtn: false, btnCaption: 'DELETE SERVICE', function: () => deleteServ(servicesType, type, 0, 0, true) })}
				/>

				{services[servicesType][type].map((service, indexService) =>
					service.Gateways.map((gateway, indexGateways) => (
						<Element
							name={element + indexGateways}
							key={element + gateway.Id}
							style={{ width: '100%' }}>
							<GroupStateService
								labelInfo={'Processor Information #' + (indexGateways + 1)}
								status={gateway.Connector.Enabled}
								toggelBtn={'Test Mode'}
								toggelBtnTooltip={'When Test Mode is on, the gateway uses sandbox accounts. When Test Mode is off, the gateway uses production accounts.'}
								toggleValue={gateway.Connector.TestMode}
								toggleDisabled={inputDisabled === indexGateways ? false : true}
								togglehandleChange={() => handleChangeServices(servicesType, type, indexService, gateway.Id, 'TestMode', !gateway.Connector.TestMode, true)}>
								<Processor
									service={service}
									indexService={indexService}
									indexGateways={indexGateways}
									serviceName={type}
									typeService={servicesType}
									inputDisabled={inputDisabled === indexGateways ? false : true}
									handleChange={handleChangeServices}
									classValidate={classValidate}
									setClassValidate={setClassValidate}
									showWarningAccountId={showWarningAccountId}
									setShowWarningAccountId={setShowWarningAccountId}
								/>
							</GroupStateService>
							<ShowGroupCard
								expand={expand}
								inputDisabled={inputDisabled === indexGateways ? false : true}>
								{/*--------------------------------- BANK ACCOUNT ----------------------- */}
								{type !== 'cash' && type !== 'check' && (
									<BankAccount
										service={service}
										indexService={indexService}
										indexGateways={indexGateways}
										serviceName={type}
										inputDisabled={inputDisabled === indexGateways ? false : true}
										handleChange={handleChangeServices}
										handleAdd={handleAddBankTier}
										handleRemove={handleRemoveBankTier}
										setClassValidate={setClassValidate}
										classValidate={classValidate}
										showAlertFees={showAlertFees}
										setShowAlertFees={setShowAlertFees}
										addBankAccount={addBankAccount}>
										<PayMethodAccepted
											service={service}
											indexService={indexService}
											indexGateways={indexGateways}
											serviceName={type}
											inputDisabled={inputDisabled === indexGateways ? false : true}
										/>
									</BankAccount>
								)}
								{/*--------------------------------- TIER ----------------------- */}
								<Tiers
									service={service}
									indexService={indexService}
									indexGateways={indexGateways}
									serviceType={type}
									inputDisabled={inputDisabled === indexGateways ? false : true}
									setShowModal={setShowModal}
									showModal={showModal}
									setTierProperty={setTierProperty}
								/>
							</ShowGroupCard>
							<ButtonSaveCancel
								inputDisabled={inputDisabled === indexGateways ? false : true}
								funtionCancel={() => clearService(type) | setShowWarningAccountId(false)}
								funtionSave={() => editService(servicesType, type, indexService, indexGateways)}
							/>
						</Element>
					))
				)}
			</div>
		</Element>
	)
}
