import styles from '../../../../assets/css/styleCommandCenter.module.css'
import LoaderPlaceHolder from '../../../../components/loaderPlaceHolder'
import { BiShow, BiCheck, BiCheckCircle, BiMinusCircle } from 'react-icons/bi'
import { RiAddFill, RiDeleteBin5Line, RiCloseCircleLine } from 'react-icons/ri'

export default function ItemService({
	updateService,
	deleteService,
	PayPoint,
	showAddService,
	viewServices,
	showPopUpMessage
}) {
	const updateState = async (
		moneyType,
		serviceType,
		indexService,
		indexGateways,
		state
	) => {
		await updateService(
			'updateStateGateway',
			serviceType,
			moneyType,
			indexService,
			indexGateways,
			state
				? 'Service Gateway deactivated successfully!'
				: 'Service Gateway activated successfully!',
			'',
			0
		)
	}
	const selectElement = (value, tab, indexGateways) => {
		if (tab === 'moneyIn') {
			switch (value) {
				case 'Card':
					return {
						element: 'CreditCard' + indexGateways,
						tab: 'moneyIn'
					}
				case 'ACH':
					return { element: 'ACHIn' + indexGateways, tab: 'moneyIn' }
				case 'Check':
					return { element: 'Check' + indexGateways, tab: 'moneyIn' }
				case 'Cash':
					return { element: 'Cash' + indexGateways, tab: 'moneyIn' }
				case 'Cloud Devices':
					return {
						element: 'CloudDevices' + indexGateways,
						tab: 'moneyIn'
					}
				case 'Wallet':
					return { element: 'Wallet' + indexGateways, tab: 'moneyIn' }
				default:
					return { element: '', tab: 'moneyIn' }
			}
		} else {
			switch (value) {
				case 'Managed Payables':
					return {
						element: 'ManagedPayAbles' + indexGateways,
						tab: 'moneyOut'
					}
				case 'ACH':
					return {
						element: 'ACHOut' + indexGateways,
						tab: 'moneyOut'
					}
				case 'Virtual Card':
					return { element: 'vcard' + indexGateways, tab: 'moneyOut' }
				case 'Physical Check':
					return {
						element: 'PhysicalCheck' + indexGateways,
						tab: 'moneyOut'
					}
				case 'Push To Card':
					return {
						element: 'PushToCard' + indexGateways,
						tab: 'moneyOut'
					}
				default:
					return { element: '', tab: 'moneyOut' }
			}
		}
	}
	const deleteServ = async (
		moneyType,
		serviceType,
		indexService,
		indexGateways,
		allServices = false
	) => {
		await deleteService(
			serviceType,
			moneyType,
			indexService,
			indexGateways,
			allServices
		)
	}
	const itemService = (
		caption,
		description,
		statusItem,
		tab,
		serviceName,
		indexService,
		indexGateways,
		isLoading,
		error = false
	) => {
		return (
			<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
				<div
					style={{
						minHeight: '32px',
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap'
					}}>
					{isLoading ? (
						<>
							<LoaderPlaceHolder
								extraStyles={{
									minHeight: '22px',
									width: '160px',
									marginRight: '20px'
								}}
							/>
							<LoaderPlaceHolder
								extraStyles={{
									minHeight: '22px',
									width: '280px',
									marginRight: '20px'
								}}
							/>
							<LoaderPlaceHolder
								extraStyles={{
									minHeight: '22px',
									width: '73px',
									marginRight: '20px'
								}}
							/>
							<LoaderPlaceHolder
								extraStyles={{
									minHeight: '22px',
									width: '130px',
									marginRight: '20px'
								}}
							/>
							<LoaderPlaceHolder
								extraStyles={{
									minHeight: '22px',
									width: '140px',
									marginRight: '20px'
								}}
							/>
						</>
					) : (
						<>
							<label
								className={
									styles['label-form'] +
									' ' +
									styles['ft-s-13']
								}
								style={{
									fontWeight: '700',
									lineHeight: '20px',
									minWidth: '180px',
									letterSpacing: '0.0025em',
									color: error
										? 'red'
										: statusItem === 'NA' && '#BABABA'
								}}>
								{indexGateways === 0 ? caption : ''}
							</label>
							<label
								className={
									styles['label-form'] +
									' ' +
									styles['ft-s-13'] +
									' ' +
									styles['size-auto']
								}
								style={{
									fontWeight: '400',
									lineHeight: '20px',
									width: '280px',
									letterSpacing: '0.0025em',
									color: error
										? 'red'
										: statusItem === 'NA' && '#BABABA'
								}}>
								{!description
									? 'Gateway | Processor | Provider'
									: description}
							</label>
							<div
								style={{
									fontSize: '14px',
									fontWeight: '600',
									paddingInline: '20px'
								}}>
								<div style={{ minWidth: '80px' }}>
									<span
										className={
											error
												? 'badge bg-danger'
												: statusItem === 'NA'
												? 'badge bg-danger'
												: statusItem
												? 'badge bg-primary'
												: !statusItem
												? 'badge bg-light'
												: 'badge bg-danger'
										}
										style={{
											fontSize: '12px',
											fontWeight: '500',
											backgroundColor:
												statusItem === 'NA' &&
												'#D9D9D9',
											textAlign: 'left'
										}}>
										{error ? (
											<>
												{' '}
												<RiCloseCircleLine />
												&nbsp;&nbsp;ERROR
											</>
										) : statusItem === 'NA' ? (
											<>
												{' '}
												<RiCloseCircleLine />
												&nbsp;&nbsp;N/A
											</>
										) : statusItem ? (
											<>
												{' '}
												<BiCheckCircle />
												&nbsp;&nbsp;Active
											</>
										) : !statusItem ? (
											<>
												{' '}
												<BiMinusCircle />
												&nbsp;&nbsp;Inactive
											</>
										) : (
											<>
												{' '}
												<RiCloseCircleLine />
												&nbsp;&nbsp;N/A
											</>
										)}
									</span>
								</div>
							</div>
							<button
								onClick={() =>
									(!statusItem || statusItem) &&
									showPopUpMessage({
										show: true,
										btnColor: 'btn-blue',
										messageTitle: 'Notice!',
										message: !statusItem
											? 'This action will activate the selected Service Gateway.'
											: statusItem &&
											  'This action will inactivate the selected Service Gateway.',
										message2: 'Do you want to continue?',
										btnCaption: !statusItem
											? 'Activate Service'
											: 'Inactivate Service',
										functionOk: () =>
											updateState(
												tab.toLowerCase(),
												serviceName,
												indexService,
												indexGateways,
												statusItem
											)
									})
								}
								className={
									styles['btn'] +
									' ' +
									styles['no-border'] +
									' ' +
									styles['color-blue']
								}
								style={{
									minWidth: '138px',
									fontWeight: '500',
									color: statusItem === 'NA' && '#BABABA'
								}}
								disabled={statusItem === 'NA' ? true : false}>
								<BiCheck
									className={styles['icon24']}
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										border: '2px solid',
										borderRadius: '50%'
									}}
								/>
								{!statusItem || statusItem === 'NA'
									? 'Activate'
									: 'Inactivate'}
							</button>
							<button
								onClick={() =>
									error
										? showPopUpMessage({
												show: true,
												messageTitle: 'Notice!',
												message:
													'This action will delete the selected Service.',
												message2:
													'Do you want to continue?',
												btnColor: 'btn-red',
												btnCaption: 'DELETE SERVICE',
												functionOk: () =>
													deleteServ(
														tab.toLowerCase(),
														serviceName,
														indexService,
														indexGateways,
														true
													)
										  })
										: statusItem === 'NA'
										? showAddService(tab, serviceName)
										: viewServices(
												selectElement(
													caption,
													tab,
													indexGateways
												)
										  )
								}
								className={
									styles['btn'] +
									' ' +
									styles['no-border'] +
									' ' +
									styles[
										PayPoint.idPaypoint <= 0 ||
										PayPoint.entryPoints.length <= 0
											? 'color-disabled'
											: 'color-blue'
									]
								}
								style={{
									fontWeight: '500',
									color: error && 'red'
								}}
								disabled={
									PayPoint.idPaypoint <= 0 ||
									PayPoint.entryPoints.length <= 0
										? true
										: false
								}>
								{error ? (
									<>
										<RiDeleteBin5Line
											className={`${styles['icon24']} ${styles['btn-red-letter']}`}
										/>
										Delete Service
									</>
								) : statusItem === 'NA' ? (
									<>
										<RiAddFill
											className={styles['icon24']}
										/>
										Add Service
									</>
								) : (
									<>
										<BiShow className={styles['icon24']} />
										View Details
									</>
								)}
							</button>
						</>
					)}
				</div>
			</div>
		)
	}

	return { itemService }
}
