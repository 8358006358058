import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { DevelopersLinks } from '../../components/DevelopersLinks';
import { TopBar } from '../../components/TopBar';
import { ReportPaginator } from '../../components/ReportPaginator';
import { Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { MainBar } from '../../components/MainBar';
import { BiDotsVerticalRounded } from 'react-icons/bi';

@inject('global', 'reports')
@observer
class Reports extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            flagItemAllColumn: true,
        }
        this.getReportNameComponent = this.getReportNameComponent.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.getProccTime = this.getProccTime.bind(this);
    }

    getReportNameComponent(){
        return (
            <>
                <div className="mr-3 inline">
                    <h6 style={{display: "inline-block"}}>Exchange Files</h6>
                </div>
                <div className="small-small">Manage your Logs</div>
            </>
        )
    }
        
    componentDidMount() {
        this.props.reports.setFrom(0);
        this.props.reports.setHeaders(
            {
                parentOrgName       : { label:'Organization', class: '', display: false },
                legalName           : { label:'Paypoint', class: '', display: true },
                fileName            : { label:'File Name', class: '', display: true },
                shortDescription    : { label:'File Purpose', class: '', display: true },
                status              : { label:'Status', class: '', display: true },
                resultNoRecord      : { label:'# of Records', class: 'text-right', display: true },
                resultNoRowsFailed  : { label:'# of Rows Failed', class: 'text-right', display: false },
                identUnique         : { label:'Lot #', class: '', display: false },
                dtReceived          : { label:'Date & Time', class: '', display: true },
                proccTime           : { label:'Processing Time', class: '', display: true },
            }
        )        
        this.props.reports.setRecords([]);
       this.getReportFromApi();
    }

    getReportFromApi(){
        this.props.global.setLoading(true);
        this.props.reports.getReportExchangeFilesFromApi('xChangeFilesLogs').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = this.props.global.getTextParseMsgError(error);
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    clearFilters(){
       this.props.reports.clearFilters();
       this.getReportFromApi();
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    downloadFile(identUnique, configId, e){
        if (e) {
            e.preventDefault();
        }
        this.props.reports.downloadFileXChange(identUnique, configId).then(res => {
            toast.error('File downloaded successfully!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container',
            });
        })
        .catch(error => {
            let errorMessage = this.props.global.getTextParseMsgError(error);
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    getProccTime(date1, date2){
        if (date1 && date2) {
            let startDate = new Date(date1).getTime(), endDate = new Date(date2).getTime(), result = -1;
            result = (endDate - startDate);
            if (Math.sign(result) < 0) {
                return '-';
            }
            return this.props.global.stringTimeFormatV2(result);
        }
        return '-';
    }

    render() {
        return (
        <Layout {...this.props}>
            <div>
                <TopBar>
                    <MainTopBarMenu/>
                    <div className="top-bar-sub">
                        <DevelopersLinks selected="reports"/>
                    </div>
                </TopBar>
                <ParentFilterPanel report={'xChangeFilesLogs'} />
                <div className="mt-body4">
                    <MainBar
                        reportNameComponent={this.getReportNameComponent}
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={false}
                        searchBar={false}
                        skipTotal={true}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    />
                    <div className="report-container">
                        <table className="table table-hover table-striped">
                            <thead>
                            <tr>
                                { this.props.reports.getHeaders.map((record, i) =>
                                    ( record[1].display && <th key={i} scope="col" className={record[1].class}>{record[1].label}</th> )
                                )}
                                <th className='text-center sticky-row' scope="col">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            { this.props.reports.records.map((record, i) => (
                                <tr key={i}>
                                    { (this.props.reports.headers.parentOrgName && this.props.reports.headers.parentOrgName.display) &&
                                        <td>{record.parentOrgName ? record.parentOrgName : '-'}</td>
                                    }
                                    { (this.props.reports.headers.legalName && this.props.reports.headers.legalName.display) &&
                                        <td>{record.legalName ? record.legalName : '-'}</td>
                                    }
                                    { (this.props.reports.headers.fileName && this.props.reports.headers.fileName.display) &&
                                        <td>{record.fileName ? (record.existsFile && record.identUnique ? <a href='/' onClick={(e) => this.downloadFile(record.identUnique, record.configId, e)}>{record.fileName}</a> : record.fileName) : '-'}</td>
                                    }
                                    { (this.props.reports.headers.shortDescription && this.props.reports.headers.shortDescription.display) &&
                                        <td>{record.shortDescription ? record.shortDescription : '-'}</td>
                                    }
                                    { (this.props.reports.headers.status && this.props.reports.headers.status.display) &&
                                        <td>{this.props.global.getXchangeLogStatus(record.status)}</td>
                                    }
                                    { (this.props.reports.headers.resultNoRecord && this.props.reports.headers.resultNoRecord.display) &&
                                        <td className='text-right'>{record.result ? JSON.parse(record.result).Summary.TotalRecord : '-'}</td>
                                    }
                                    { (this.props.reports.headers.resultNoRowsFailed && this.props.reports.headers.resultNoRowsFailed.display) &&
                                        <td className='text-right'>{record.result ? JSON.parse(record.result).Summary.TotalFailed : '-'}</td>
                                    }
                                    { (this.props.reports.headers.identUnique && this.props.reports.headers.identUnique.display) &&
                                        <td>
                                            {
                                                record.identUnique ? (record.identUnique.length > 20 ?
                                                    <OverlayTrigger
                                                        placement={'bottom'}
                                                        overlay={
                                                            <Tooltip>
                                                                {record.identUnique}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>{record.identUnique.substring(0, 20) + '...'}</span>
                                                    </OverlayTrigger>
                                                : record.identUnique) : '-'
                                            }
                                        </td>
                                    }
                                    { (this.props.reports.headers.dtReceived && this.props.reports.headers.dtReceived.display) &&
                                        <td>{this.props.global.stringDateFormatV3(record.dtReceived)}<br/><small className='text-muted'>{this.props.global.stringTimeFormat(record.dtReceived)}</small></td>
                                    }
                                    { (this.props.reports.headers.proccTime && this.props.reports.headers.proccTime.display) &&
                                        <td>{this.getProccTime(record.dtReceived, record.dtCompleted)}</td>
                                    }
                                    <td  className='text-center sticky-row'>
                                        <DropdownButton
                                            menuAlign="right"
                                            title={<BiDotsVerticalRounded/>}
                                            id="actionsMenuButton"
                                            size="sm"
                                            variant="default"
                                        >
                                            <Dropdown.Item disabled={!(record.existsFile && record.identUnique)} onClick={(e) => this.downloadFile(record.identUnique, record.configId)}>Download</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {this.props.reports.records.length < 1 && 
                            <RecordsNotFound message="No Exchange Files yet" description={<span>Sorry. We don't have any record to show.</span>}/>
                        }
                    </div>
                    <ReportPaginator report="xChangeFilesLogs" option={'xchange'} />
                </div>
                <ToastContainer transition={Bounce} />
            </div>
        </Layout>
        )
    }
}

export { Reports };