import React from 'react';
import { inject } from 'mobx-react';
import {  Modal, Accordion, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { toJS } from "mobx";
import {IMaskInput} from 'react-imask';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PayabliStorageManager } from '../api/localStorageManager'
import {BiChevronDown} from '@react-icons/all-files/bi/BiChevronDown';
import {BiChevronRight} from '@react-icons/all-files/bi/BiChevronRight';
import {BiInfoCircle} from '@react-icons/all-files/bi/BiInfoCircle';
import { LocationSearchInput } from './LocationSearchInput';
import SelectSearch from 'react-select-search';
import '../assets/css/react-select-search.css';
import Fuse from 'fuse.js';
import PhoneInput from './PhoneInput';

export default function fuzzySearch(options) {
    const fuse = new Fuse(options, {
        keys: ['name', 'groupName', 'items.name'],
        threshold: 0.3,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }

        let result = fuse.search(value);
        let resultArray = []
        result.forEach(function (item) {
            resultArray.push(item.item);
        });
        return resultArray;
    };
}

@inject('global','customer', 'vTerminal', 'reports')
class EditCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            phoneError: false,
            id: "",
            customerNumber: "",
            company: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            paypointEntryname: "",
            paypointLegalname: "",
            customFields: [],
            identifiers: [],
            identifiersSelected: [],
            partnerPaypoints:  [],
            customerPaypoint: '',
            
            billingAddressValue: "",
            billingAddress: "",
            billingAddress2: "",
            billingCity: "",
            billingState: "",
            billingZip: "",
            billingCountry: "",

            shippingAddress: "",
            shippingAddress2: "",
            shippingCity: "",
            shippingState: "",
            shippingZip: "",
            shippingCountry: "",

            billingSameShipping: true,

            formErrors: {},

            accordionBillingDetailsOpen : false,
            accordionCustomFieldsOpen : false,
            accordionIdentifiersOpen : false
        };
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.addCustomer = this.addCustomer.bind(this);
        this.customFieldsKeyHandleTextChange = this.customFieldsKeyHandleTextChange.bind(this);
        this.customFieldsValueHandleTextChange = this.customFieldsValueHandleTextChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextChangeSelectSearch = this.handleTextChangeSelectSearch.bind(this);
        this.addCustomField = this.addCustomField.bind(this);
        this.removeCustomField = this.removeCustomField.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.identifierHandleTextChange = this.identifierHandleTextChange.bind(this);
        this.billingSameShipping = this.billingSameShipping.bind(this);
        this.handleGoogleAutocompleteBillingAddress = this.handleGoogleAutocompleteBillingAddress.bind(this);
        this.handleGoogleAutocompleteShippingAddress = this.handleGoogleAutocompleteShippingAddress.bind(this);
        this.handleSelectIdentifier = this.handleSelectIdentifier.bind(this);
        this.collapseAccordion = this.collapseAccordion.bind(this);
        this.getCustomFieldsFromApi = this.getCustomFieldsFromApi.bind(this);
        this.isIdentifier = this.isIdentifier.bind(this);
        this.hasCustomFieldsErrors = this.hasCustomFieldsErrors.bind(this);
        this.getPartnerPaypoints = this.getPartnerPaypoints.bind(this);
        this.setPhoneError = this.setPhoneError.bind(this);
        this.accordionCustomFields = React.createRef();
        this.accordionBillingDetails = React.createRef();
        this.componentPhone = React.createRef();
        this.baseState = this.state;
    }

    resetState = () => {
        this.setState(this.baseState);
        this.getCustomFieldsFromApi(this.state.paypointEntryname ? this.state.paypointEntryname : this.props.reports.paypoint);
    }

    getCustomFieldsFromApi(paypointEntryname){
        if(paypointEntryname){
            this.props.global.setLoading(true);
            this.props.reports.getEntrySettings(paypointEntryname).then(res => {
                if(res.data.customFields){
                    let identifiersSelected = [];
                    if(res.data.identifiers){
                        res.data.identifiers.forEach(function (item) {
                            identifiersSelected.push(item.key);
                        });
                    }
                    
                    this.setState({customFields:res.data.customFields,identifiers: res.data.identifiers, identifiersSelected:identifiersSelected });
                }
                this.props.global.setLoading(false);
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    getPartnerPaypoints(selected){
        this.props.reports.getPartnerPaypoints().then(res => {
            let dataArray = [];
            if(res.Records){
                res.Records.forEach(function (item) {
                    if(item.EntryPoints && item.EntryPoints[0] && item.EntryPoints[0].EntryName){
                        dataArray.push({
                            value: item.EntryPoints[0].EntryName,
                            name: item.LegalName,
                        });
                    }
                });
            }
            this.setState({ partnerPaypoints: dataArray}, function(){
                if(selected){
                    this.setState({customerPaypoint : selected});
                }
            });
        })
        .catch(error => { });
    }

    collapseAccordion(accordion){
        this.setState({[accordion]:!this.state[accordion]});
    }

    handleSelectIdentifier(e, record){
        let identifiersSelected = this.state.identifiersSelected;
        
        if(e.target.checked){
            identifiersSelected.push(record)
        }
        else{
            identifiersSelected.splice(identifiersSelected.indexOf(record), 1); 
        }
        this.setState({identifiersSelected:identifiersSelected});
    }

    handleGoogleAutocompleteBillingAddress(billingAddress, billingAddress2, billingCity, billingState, billingZip, billingCountry){
        this.setState({
            billingAddress : billingAddress ?  billingAddress : '',
            billingAddress2 : billingAddress2 ?  billingAddress2 : '',
            billingCity : billingCity ?  billingCity : '',
            billingState : billingState ?  billingState : '',
            billingZip : billingZip ?  billingZip : '',
            billingCountry : billingCountry ?  billingCountry : ''
         });
    }

    handleGoogleAutocompleteShippingAddress(shippingAddress, shippingAddress2, shippingCity, shippingState, shippingZip, shippingCountry){
        this.setState({
            shippingAddress :shippingAddress ? shippingAddress : '',
            shippingAddress2 :shippingAddress2 ? shippingAddress2 : '',
            shippingCity : shippingCity ? shippingCity : '',
            shippingState : shippingState ? shippingState : '',
            shippingZip : shippingZip ? shippingZip : '',
            shippingCountry : shippingCountry ? shippingCountry : '',
        });      
    }

    addCustomField(e){
        if(e){
            e.preventDefault();
        }
       
        let customFields = this.state.customFields;
        customFields.push({key:"", value: ""});
        this.setState({ customFields: customFields });
    }
    
    billingSameShipping(e){
        this.setState({ billingSameShipping: e.target.checked });
    }

    handleTextChange(e){
        this.setState({ [e.target.id]: e.target.value });
    }

    handleTextChangeSelectSearch(value, name){
        this.setState({ [name]: value }, () => {
            this.getCustomFieldsFromApi(value);
        });
    }
   
    customFieldsKeyHandleTextChange(e, i){
        let customFields = this.state.customFields;
        customFields[i].key = e.target.value;
        this.setState({ customFields: customFields });
    }

    customFieldsValueHandleTextChange(e, i){
        let customFields = this.state.customFields;
        customFields[i].value = e.target.value;
        this.setState({ customFields: customFields });
    }
   
    identifierHandleTextChange(e){
        let identifiers = this.state.identifiers;
        identifiers[e.target.id] = e.target.value;
        this.setState({ identifiers: identifiers });
    }
    
    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }

    closeModal(){
        this.setState({ modalIsOpen: false, accordionBillingDetailsOpen: false });
    }

    openModal(e){
        if(e){
            e.preventDefault();
        }
        let customFields = [];
        let customFieldsApi = this.props.customerToUpdate && this.props.customerToUpdate.AdditionalFields ? toJS(this.props.customerToUpdate.AdditionalFields) : this.state.customFields;
        /*customFieldsApi.forEach(function (item) {
            customFields.push({key:item.key, value: item.value});
        });*/

        if(this.props.customerToUpdate && this.props.customerToUpdate.AdditionalFields){
            for (var [key, value] of Object.entries(customFieldsApi)) {
                customFields.push({key:key, value: value});
            }
        }
        else{
            for (var [key1, value1] of Object.entries(customFieldsApi)) {
                customFields.push(value1);
            }
        }

        let defaultfirstName = this.props.customerToUpdate && this.props.customerToUpdate.Firstname ? this.props.customerToUpdate.Firstname : "";
        let defaultlastName = this.props.customerToUpdate && this.props.customerToUpdate.Lastname ? this.props.customerToUpdate.Lastname : "";

        if(this.props.defaultName){
            let defaultNameParts = this.props.defaultName.split(" ")
            defaultfirstName = defaultNameParts[0] ? defaultNameParts[0] : defaultfirstName;
            defaultlastName = defaultNameParts[1] ? defaultNameParts[1] : defaultlastName;
        }

        let billingSameShipping = true;
        if(this.props.customerToUpdate && (this.props.customerToUpdate.Address !== this.props.customerToUpdate.ShippingAddress || this.props.customerToUpdate.Address1 !== this.props.customerToUpdate.ShippingAddress1)){
            billingSameShipping = false;
        }
       
        this.getPartnerPaypoints(this.props.customerToUpdate && this.props.customerToUpdate.PaypointEntryname ? this.props.customerToUpdate.PaypointEntryname : null);

         //if(this.props.customerToUpdate && 
        this.setState({
            id: this.props.customerToUpdate && this.props.customerToUpdate.customerId ? this.props.customerToUpdate.customerId : "",
            customerNumber: this.props.customerToUpdate && this.props.customerToUpdate.customerNumber ? this.props.customerToUpdate.customerNumber : "",
            paypointEntryname: this.props.customerToUpdate && this.props.customerToUpdate.PaypointEntryname ? this.props.customerToUpdate.PaypointEntryname : "",
            paypointLegalname: this.props.customerToUpdate && this.props.customerToUpdate.PaypointLegalname ? this.props.customerToUpdate.PaypointLegalname : "",
            company: this.props.customerToUpdate && this.props.customerToUpdate.Company ? this.props.customerToUpdate.Company : "",
            firstName: defaultfirstName,
            lastName: defaultlastName,
            email: this.props.customerToUpdate && this.props.customerToUpdate.Email ? this.props.customerToUpdate.Email : "",
            phone: this.props.customerToUpdate && this.props.customerToUpdate.Phone ? this.props.customerToUpdate.Phone : "",
            customFields: customFields,
            //identifiersSelected: this.props.customerToUpdate && this.props.customerToUpdate.IdentifierFields ? toJS(this.props.customerToUpdate.IdentifierFields) : [],

            billingAddress: this.props.customerToUpdate && this.props.customerToUpdate.Address ? this.props.customerToUpdate.Address : "",
            billingAddress2: this.props.customerToUpdate && this.props.customerToUpdate.Address1 ? this.props.customerToUpdate.Address1 : "",
            billingCity: this.props.customerToUpdate && this.props.customerToUpdate.City ? this.props.customerToUpdate.City : "",
            billingState: this.props.customerToUpdate && this.props.customerToUpdate.State ? this.props.customerToUpdate.State : "",
            billingZip: this.props.customerToUpdate && this.props.customerToUpdate.Zip ? this.props.customerToUpdate.Zip : "",
            billingCountry: this.props.customerToUpdate && this.props.customerToUpdate.Country ? this.props.customerToUpdate.Country : "",

            shippingAddress: this.props.customerToUpdate && this.props.customerToUpdate.ShippingAddress ? this.props.customerToUpdate.ShippingAddress : "",
            shippingAddress2: this.props.customerToUpdate && this.props.customerToUpdate.ShippingAddress1 ? this.props.customerToUpdate.ShippingAddress1 : "",
            shippingCity: this.props.customerToUpdate && this.props.customerToUpdate.ShippingCity ? this.props.customerToUpdate.ShippingCity : "",
            shippingState: this.props.customerToUpdate && this.props.customerToUpdate.ShippingState ? this.props.customerToUpdate.ShippingState : "",
            shippingZip: this.props.customerToUpdate && this.props.customerToUpdate.ShippingZip ? this.props.customerToUpdate.ShippingZip : "",
            shippingCountry: this.props.customerToUpdate && this.props.customerToUpdate.ShippingCountry ? this.props.customerToUpdate.ShippingCountry : "",

            billingSameShipping: billingSameShipping,
            modalIsOpen: true
        }, function(){
            if (this.props.action.toLowerCase() !== "new") {
               this.getCustomFieldsFromApi(this.state.paypointEntryname);
            }
        });
    }

    addCustomer(){
        let errors = this.validateFields();
        this.setState({ formErrors: errors }, function(){

            if(!this.state.accordionCustomFieldsOpen && this.hasCustomFieldsErrors()){
                this.accordionCustomFields.current.click();
            }

            if(Object.keys(errors).length === 0){
                let customer ={
                    id: this.state.id,
                    company: this.state.company,
                    customerNumber: this.state.customerNumber,
                    firstname: this.state.firstName,
                    lastname: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                    additionalFields: this.state.customFields,
                    identifierFields: this.state.identifiersSelected,

                    address: this.state.billingAddress,
                    address1: this.state.billingAddress2,
                    city: this.state.billingCity,
                    state: this.state.billingState,
                    zip: this.state.billingZip,
                    country: this.state.billingCountry,

                    shippingAddress: this.state.billingSameShipping ? this.state.billingAddress : this.state.shippingAddress,
                    shippingAddress1: this.state.billingSameShipping ? this.state.billingAddress2 : this.state.shippingAddress2,
                    shippingCity: this.state.billingSameShipping ? this.state.billingCity : this.state.shippingCity,
                    shippingState: this.state.billingSameShipping ? this.state.billingState : this.state.shippingState,
                    shippingZip: this.state.billingSameShipping ? this.state.billingZip : this.state.shippingZip,
                    shippingCountry: this.state.billingSameShipping ? this.state.billingCountry : this.state.shippingCountry
                    
                }


                if((this.state.customerNumber === '' || this.state.customerNumber === null) && this.state.identifiersSelected.length === 0){
                    customer.identifierFields = ["email"];
                }

                
                if(this.props.action.toLowerCase() === "edit"){
                    this.props.global.setLoading(true);
                    this.props.customer.updateCustomer(customer)
                    .then(result => {

                        // calling parent functionCallback after saving
                        if(this.props.functionCallback){
                            this.props.functionCallback();
                        }

                        //this.closeModal();
                        this.props.global.setLoading(false);
                        toast.success("Customer updated successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });

                        this.resetState();
                    })
                    .catch(error => {
                        this.props.global.setLoading(false);
                        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                        toast.error(errorMessage, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    });
                }else if(this.props.action.toLowerCase() === "new"){
                    this.props.global.setLoading(true);
                    let customerPaypoint = this.state.customerPaypoint;
                    this.props.customer.createCustomer(customer, customerPaypoint)
                    .then(result => {
                        if(this.props.rewriteSelectedWithResponse && this.props.rewriteSelectedWithResponse === true){
                            if(result.data && result.data.responseData){
                                this.props.vTerminal.selectCustomerObjectInSearch(result.data.responseData);
                            }                            
                        }else{
                            this.props.vTerminal.selectCustomerObjectInSearch(customer);
                        }

                        if(this.props.vTerminal.getCustomerSelected.StoredMethods === null || this.props.vTerminal.getCustomerSelected.StoredMethods === undefined || this.props.vTerminal.getCustomerSelected.StoredMethods.length === 0){
                            this.props.vTerminal.setPaymentMethod('card');
                            this.props.vTerminal.setDefaultPaymentMethodActiveKey("0");
                        }

                        if(this.props.functionCallbackNew){
                            this.props.functionCallbackNew();
                        }

                        // calling parent functionCallback after saving
                        if(this.props.functionCallback){
                            this.props.functionCallback();
                        }

                        //this.closeModal();
                        this.props.global.setLoading(false);
                        toast.success("Customer created successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });
                        this.resetState();
                    })
                    .catch(error => {
                        console.log(error);
                        this.props.global.setLoading(false);
                        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                        toast.error(errorMessage, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    });
                }

               
                
            }
        });
        
    }

    removeCustomField(i){
        let customFields = this.state.customFields;
        customFields.splice(i, 1);
        this.setState({ customFields: customFields });
    }

    isIdentifier(key){
        let identifiers =  this.state.identifiers;
        if(identifiers){
            for(let i=0; i < identifiers.length; i++){
                if(identifiers[i].key.toLowerCase() === key.toLowerCase()){
                    return true;
                }
            }
        }
        
        return false;
    }

    hasCustomFieldsErrors(){
        let customFields =  this.state.customFields;
       
        for(let i=0; i < customFields.length; i++){
            if(this.state.formErrors['customFieldsValue'+i]){
                return true;
            }
        }
        return false;
    }

    validateFields(){
        let validators = this.props.global.validators;
        let errors = {};
        if(validators.isMaxLength(250, this.state.company) || (this.isIdentifier("company") ? validators.isEmpty(this.state.company) : false))
        {
            errors['company']= true;
        }

        if(validators.isMaxLength(250, this.state.customerNumber) || (this.isIdentifier("customernumber") ? validators.isEmpty(this.state.customerNumber) : false))
        {
            errors['customerNumber']= true;
        }

        if(validators.isEmpty(this.state.firstName) || validators.isMaxLength(250, this.state.firstName))
        {
            errors['firstName']= true;
        }

        if(validators.isEmpty(this.state.lastName) || validators.isMaxLength(250, this.state.lastName))
        {
            errors['lastName']= true;
        }

        if(validators.isEmpty(this.state.email) || validators.isMaxLength(250, this.state.email) || validators.stringValidator('email',this.state.email))
        {
            errors['email']= true;
        }

        if((this.componentPhone.current.hasError()) || (this.isIdentifier("phone") ? validators.isEmpty(this.state.phone) : false))
        {
            errors['phone']= true;
        }
        
        if(validators.isEmpty(this.state.customerPaypoint))
        {
            errors['customerPaypoint']= true;
        }

        /*if(validators.isMaxLength(250, this.state.billingAddress))
        {
            errors['billingAddress']= true;
        }*/

        let reactObj = this;
        if(this.state.identifiers){
            this.state.identifiers.forEach(function (itemI, indexI) {
                reactObj.state.customFields.forEach(function (itemCF, indexCF) {
                    if(itemI.key === itemCF.key){
                        if(validators.isEmpty(itemCF.value) ||  validators.isMaxLength(250, itemCF.value)){
                            errors['customFieldsValue'+indexCF]= true;
                        }
                    }
                });
            
            });
        }

        return errors;

        
    }
    setPhoneError(error){
        this.setState({phoneError: error});
    }
    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        return (
            <>
                <Modal show={this.state.modalIsOpen} onHide={(e) => this.closeModal()}  size="md"  aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="popover-body" id="editCustomerModal">

                <div className="row mb-3">
                    <div className="col" data-qaid="editCustomerModal"><h6>{this.props.title ? this.props.title : "Edit customer"}</h6></div>
                </div>

                <div className="row">
                   <div className="col-12 mb-3">
                       <span className="mb-2" style={{display: "block"}}>Account Information</span> 
                       <div className="form-floating">
                            <input className={this.state.formErrors.company ? "form-control input-error" : "form-control"} onChange={(e) => this.handleTextChange(e)} placeholder="Business Name" id="company" value={ this.state.company } />
                            <label htmlFor="company">Business Name</label>
                        </div>
                   </div>
                   <div className="col-12 mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('alphanumeric')}
                                name="customerNumber"
                                value={ this.state.customerNumber  }
                                unmask={true}                                
                                onAccept={
                                    (value, mask) => this.handleTextChangeMask('customerNumber', value)
                                }
                                placeholder="Customer #" 
                                className={this.state.formErrors.customerNumber ? "form-control input-error" : "form-control"}
                                id="customerNumber"
                            />
                            <label htmlFor="firstName">Customer #</label>
                        </div>
                   </div>
                   <div className="col-6 mb-3">
                        <div className="form-floating">
                            <input className={this.state.formErrors.firstName ? "form-control input-error" : "form-control"} onChange={(e) => this.handleTextChange(e)} placeholder="First Name" id="firstName" value={ this.state.firstName }/>
                            <label htmlFor="firstName">First Name</label>
                        </div>
                   </div>
                   <div className="col-6 mb-3">
                        <div className="form-floating">
                            <input className={this.state.formErrors.lastName ? "form-control input-error" : "form-control"} onChange={(e) => this.handleTextChange(e)} placeholder="Last Name" id="lastName" value={ this.state.lastName } />
                            <label htmlFor="lastName">Last Name</label>
                        </div>
                   </div>
                   <div className="col-6 mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('email')}
                                name="email"
                                value={ this.state.email }
                                unmask={true}                                
                                onAccept={
                                    (value, mask) => this.handleTextChangeMask('email', value)
                                }
                                placeholder="Email"
                                className={this.state.formErrors.email ? "form-control input-error" : "form-control"}
                                id="email"
                            />
                            <label htmlFor="email">Email</label>
                        </div>
                   </div>
                   <div className={`col-6 mb-3 ${this.componentPhone.current?.hasError() ? "" : "clear-input-error"}`}>
                   <PhoneInput 
                        ref={this.componentPhone}
                        iSetError={this.setPhoneError} 
                        iCustomClass="inner-addon" 
                        iTitle="Primary Phone #" 
                        iMask="phone" 
                        iOnChange={this.handleTextChangeMask} 
                        iOnChangeForce={this.handleTextChangeMask}
                        iName="phone" 
                        iValue={ this.state.phone || '' } 
                        iValidator={this.props.global.getValidator().phone}
                        full
                   />
                   </div>
                   <div className="col-12 mb-3">
                        <div className={this.state.formErrors.customerPaypoint ? "input-error form-floating" : "form-floating"}>
                            <SelectSearch
                                id="customerPaypoint"
                                options={this.state.partnerPaypoints}
                                autoComplete={"selectSearch"}
                                search
                                filterOptions={fuzzySearch}
                                emptyMessage="Not found"
                                placeholder="Find and Select"
                                value={this.state.customerPaypoint}
                                onChange={(e) =>
                                    this.handleTextChangeSelectSearch(e,"customerPaypoint")
                                }
                                disabled={this.props.viewAction === "edit"}
                            />
                            <label htmlFor="customerPaypoint" className="forceFloatLabel">Paypoint</label>
                        </div>
                   </div>
                </div>
                <Accordion>
                <Card className="card-no-border mb-2">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2" ref={this.accordionBillingDetails}  onClick={(e)=>this.collapseAccordion("accordionBillingDetailsOpen")}>
                        {this.state.accordionBillingDetailsOpen ? <BiChevronDown/> : <BiChevronRight/>} Billing Details
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                    <div className="col-12 mb-3">
                        <LocationSearchInput 
                            placeholder="Billing Address" 
                            onChange={this.handleGoogleAutocompleteBillingAddress} 
                            handleTextChange={this.handleTextChangeMask} 
                            address={this.state.billingAddress}
                            address2={this.state.billingAddress2}
                            city={this.state.billingCity}
                            state={this.state.billingState}
                            zipcode={this.state.billingZip}
                            country={this.state.billingCountry}
                            handleTextChangeKeys={['billingAddress','billingAddress2', 'billingCity', 'billingState', 'billingZip', 'billingCountry']}
                        />
                    </div>
                    
                    <div className="col-12 mb-3">
                        <div className="icheck-primary">
                        <input defaultChecked={this.state.billingSameShipping} type="checkbox" id="billingAddressSameShipping" onClick={(e) => this.billingSameShipping(e)} />
                        <label htmlFor="billingAddressSameShipping">Shipping address is same as billing</label>
                        </div>
                    </div>
                    {!this.state.billingSameShipping &&
                        <>
                        <div className="col-12 mb-3">
                            <LocationSearchInput  
                                placeholder="Shipping Address"
                                onChange={this.handleGoogleAutocompleteShippingAddress} 
                                handleTextChange={this.handleTextChangeMask} 
                                address={this.state.shippingAddress}
                                address2={this.state.shippingAddress2}
                                city={this.state.shippingCity}
                                state={this.state.shippingState}
                                zipcode={this.state.shippingZip}
                                country={this.state.shippingCountry}
                                handleTextChangeKeys={['shippingAddress','shippingAddress2', 'shippingCity', 'shippingState', 'shippingZip', 'shippingCountry']}
                            />
                        </div>
                        </>
                    }
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

                <Accordion>
                <Card className="card-no-border mb-2">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2" ref={this.accordionCustomFields} onClick={(e)=>this.collapseAccordion("accordionCustomFieldsOpen")}>
                        {this.state.accordionCustomFieldsOpen ? <BiChevronDown/> : <BiChevronRight/>}
                        Custom Fields &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>If you wish to add custom fields, please go to <br/>Settings `{'>'}` Custom Fields</Tooltip>}
                        >
                            <BiInfoCircle/>
                        </OverlayTrigger>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      
                        { this.state.customFields.map((record, i) => (
                        <div key={i} className="row">
                            <div className="col-6 mb-3 hide">
                                <div className="form-floating">
                                    <input className={this.state.formErrors['customFieldsKey'+i] ? "form-control input-error" : "form-control"} onChange={(e) => this.customFieldsKeyHandleTextChange(e, i)} id={"customFieldsKey"+i} placeholder={"Field Name"} value={record.key} />
                                    <label htmlFor={"customFieldsKey"+i}>{"Field Name"}</label>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <input className={this.state.formErrors['customFieldsValue'+i] ? "form-control input-error" : "form-control"} onChange={(e) => this.customFieldsValueHandleTextChange(e, i)} id={"customFieldsValue"+i} placeholder={"Value"} value={record.value} />
                                    <label htmlFor={"customFieldsValue"+i}>{record.key}</label>
                                </div>
                            </div>
                            <div className="col-2 text-center mb-3 hide">
                                <div className="category-icon mt-2">
                                <button className="remove-icon-btn" type="button" onClick={(e) => this.removeCustomField(i)}>&nbsp;</button>
                                </div>
                            </div>
                        </div>
                        ))
                        }

                    {userPermissions.indexOf("Custom Fields") !== -1 &&
                    <div className="row mb-3">
                        {/*<div className="col">
                            <a onClick={(e) => this.addCustomField(e)} href="/">Add custom field</a>
                        </div>*/}
                    </div>
                    }
                        
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
                

                <Accordion>
                <Card className="card-no-border mb-4">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2" onClick={(e)=>this.collapseAccordion("accordionIdentifiersOpen")}>
                        {this.state.accordionIdentifiersOpen ? <BiChevronDown/> : <BiChevronRight/>} Identifiers
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                    {this.state.identifiers &&
                        <div className="row">
                        { this.state.identifiers.map((record, i) => (
                            <div key={i} className="col-4">
                                 <div className="icheck-primary">
                                <input onChange={()=>function(){}} checked type="checkbox" id={"identifier"+i}  />
                                <label className="small-small" htmlFor={"identifier"+i}>{record.key}</label>
                                </div>
                            </div>
                        ))}
                        </div>
                        }                 

                       
                        
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <button className="btn full-w btn-light" type="button" onClick={() => this.closeModal() } data-qaid="cancelButtonEditCustomerModal">Cancel</button>
                    </div>
                    <div className="col-sm-12 col-md-6">
                    <button className="btn full-w btn-primary" type="button" onClick={() => this.addCustomer()}>{this.props.label && !this.props.hasIconLabel ? this.props.label : "Save Changes"}</button>   
                    </div>
                </div>
                </Modal.Body>
                </Modal>
                <a style={ this.props.style ? this.props.style : {}} href="/" className={this.props.className ? this.props.className : "dropdown-item"} onClick={(e) => this.openModal(e)}>{this.props.label ? this.props.label : "Edit Customer"}</a>
            </>
        )
    }
}

export { EditCustomer };