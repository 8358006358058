import React, {
	useEffect,
	forwardRef,
	useImperativeHandle,
	useContext
} from 'react'
import { inject } from 'mobx-react'
import styles from '../../../../assets/css/styleCommandCenter.module.css'
import { Link } from 'react-scroll'

import { BiAddToQueue, BiCog, BiDetail, BiUserCheck } from 'react-icons/bi'
import { RiBankLine } from 'react-icons/ri'
import { FaFileSignature } from 'react-icons/fa'
import hellosignContext from '../context/hellosignContext'
import ComponentInformation from '../Components/ComponentInformation/ComponentInformation'
import boardingFields from './boardingFields'

const HelloSignForm = inject(
	'reports',
	'store',
	'global'
)(
	forwardRef((props, ref) => {
		const {
			loadData,
			getAllData,
			setBusiness,
			setOwner,
			owner,
			setProcessing,
			setBanking,
			service,
			setService,
			setSigner,
			Bussiness,
			handleChangeBusiness
		} = useContext(hellosignContext)
		const { inputDisabled } = props
		useImperativeHandle(ref, () => ({
			getAllData
		}))
		useEffect(() => {
			props.global.setLoading(true)
			const load = async () => {
				const resultData = await loadData(props.template.id)
				setBusiness({ ...resultData.BusinessData })
				setSigner({ ...resultData.SignerData })
				setOwner({ ...resultData.OwnersData })
				setProcessing({ ...resultData.ProcessingData })
				setBanking({ ...resultData.BankData })
				setService({ ...resultData.ServicesData })
				if (!unmounted) {
					props.global.setLoading(false)
				}
			}

			let unmounted = false
			load()
			return () => {
				unmounted = true
			}
		}, [])

		return (
			<>
				<div
					className={styles['btn-panels-125']}
					style={{ paddingTop: '0px !important' }}>
					<Link
						className={styles['btn-tabs']}
						activeClass={styles['active']}
						to="BusinessDetails"
						spy={true}
						smooth={true}
						exact="true"
						offset={-370}
						duration={100}>
						<BiDetail className={styles['icon']} />
						<span>Business Details</span>
					</Link>
					<Link
						className={styles['btn-tabs']}
						activeClass={styles['active']}
						to="OwnersContacts"
						spy={true}
						smooth={true}
						exact="true"
						offset={-270}
						duration={100}>
						<BiUserCheck className={styles['icon']} />
						<span>Ownership and Contacts</span>
					</Link>
					<Link
						className={styles['btn-tabs']}
						activeClass={styles['active']}
						to="ProcessingInformation"
						spy={true}
						smooth={true}
						exact="true"
						offset={-270}
						duration={100}>
						<BiCog className={styles['icon']} />
						<span>Processing Information</span>
					</Link>
					<Link
						className={styles['btn-tabs']}
						activeClass={styles['active']}
						to="Services"
						spy={true}
						smooth={true}
						exact="true"
						offset={-270}
						duration={100}>
						<BiAddToQueue className={styles['icon']} />
						<span>Services</span>
					</Link>
					<Link
						className={styles['btn-tabs']}
						activeClass={styles['active']}
						to="Banking"
						spy={true}
						smooth={true}
						exact="true"
						offset={-270}
						duration={100}>
						<RiBankLine className={styles['icon']} />
						<span>Banking and Documents</span>
					</Link>
					<Link
						className={styles['btn-tabs']}
						activeClass={styles['active']}
						to="Signer"
						spy={true}
						smooth={true}
						exact="true"
						offset={-270}
						duration={100}>
						<FaFileSignature className={styles['icon']} />
						<span>Signer</span>
					</Link>
				</div>
				<div className={styles['cards-container-hellosing'] + ' mb-5'}>
					{[
						[
							'Business',
							'handleChangeBusiness',
							['b_', 'business_'],
							'BusinessDetails'
						],
						[
							'Owner',
							'handleChangeOwnerMapper',
							['o_', 'owner_'],
							'OwnersContacts'
						],
						[
							'Processing',
							'handleChangeProcessing',
							['p_', 'processing_'],
							'ProcessingInformation'
						],
						[
							'Services',
							'handleChangeServicesHS',
							['s_', 'services_'],
							'Services'
						],
						[
							'Banking',
							'handleChangeBankingSimple',
							['bk_', 'bank_'],
							'Banking'
						],
						[
							'Signer',
							'handleChangeSigner',
							['sg_', 'signer_'],
							'Signer'
						]
					].map((item, index) => (
						<ComponentInformation
							componentName={item[0]}
							key={`${item[0]}DetailsComponent`}
							toName={item[3]}
							fields={props.template.fields}
							inputDisabled={inputDisabled}
							sectionNameLetter={item[2][0]}
							sectionName={item[2][1]}
							changeName={item[1]}
							boardingFields={boardingFields[item[0]]}
						/>
					))}
				</div>
			</>
		)
	})
)
export default HelloSignForm
