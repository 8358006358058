import { action } from 'mobx'

export default function TemplateDetailsFunction({ detailsData, setDetailsData, handleChange, insertValues }) {
	const fieldDataDetails = (data, templateCreator, newTemplate) => {
		let newsalesData = {}
		newsalesData.salesCode = insertValues(data[templateCreator], 'salesData', 'salesCode', '', 'oneElement')
		newsalesData.salesCRM = insertValues(data[templateCreator], 'salesData', 'salesCRM', '', 'oneElement')

		let newDetailsData = {
			templateName: data?.title ? data?.title : '',
			templateDescription: data?.description ? data?.description : '',
			templateCode: data?.code ? data?.code : '',
			resumable: data?.resumable !== null ? data?.resumable : true,
			recipientEmailNotification: data?.recipientEmailNotification !== null ? data?.recipientEmailNotification : true,
			templateContent: {
				color: data[templateCreator] ? (data[templateCreator].color ? data[templateCreator].color : '') : '',
				headerText: data[templateCreator] ? (data[templateCreator].headerText ? data[templateCreator].headerText : '') : '',
				subHeaderText: data[templateCreator] ? (data[templateCreator].subHeaderText ? data[templateCreator].subHeaderText : '') : '',
				subHeaderComment: data[templateCreator] ? (data[templateCreator].subHeaderComment ? data[templateCreator].subHeaderComment : '') : '',
				salesData: newsalesData
			}
		}
		if (newTemplate) newDetailsData.orgId = data.towner
		setDetailsData(newDetailsData)
	}
	const handleChangeDetails = action((field, value) => {
		handleChange(detailsData, setDetailsData, '', field, value, true)
	})

	return { handleChangeDetails, fieldDataDetails }
}
