import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class TopBarSingle extends React.Component {

    render() {
        return (
            <div>
                <div className="main-top-bar main-top-bar-single">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export { TopBarSingle };
