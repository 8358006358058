import React from 'react';
import { inject, observer } from 'mobx-react';
import {Link} from "react-router-dom";

import {IoStorefrontOutline} from "@react-icons/all-files/io5/IoStorefrontOutline";
import {RiMoneyDollarBoxLine} from "@react-icons/all-files/ri/RiMoneyDollarBoxLine";
import {BiChevronLeft} from '@react-icons/all-files/bi/BiChevronLeft';
import {BiGridSmall} from '@react-icons/all-files/bi/BiGridSmall';
import { PayabliStorageManager } from '../api/localStorageManager'

@inject('reports','global')
@observer
class OrganizationsLinks extends React.Component {

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        return (
            <div className="row">
            <div className="col-2">
                <div className="hide-sm">
                {this.props.goback && 
                    <a href={this.props.gobackLink} className="btn btn-light text-transform-normal"><BiChevronLeft/> { this.props.gobackText ? this.props.gobackText: "Go back" }</a>
                }
                </div>
            </div>
            <div className="col-8 text-center">
                <div className="hide-sm">
                    {userPermissions.indexOf("Organizations") !== -1 &&
                    <Link className={this.props.selected === 'organizations'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +"/report/organizations"} data-qaid="topBarMenuOrganizationsLink"><BiGridSmall style={{fontSize: "29px"}}/> Organizations</Link>
                    }
                     {userPermissions.indexOf("Paypoints") !== -1 &&
                    <Link className={this.props.selected === 'paypoints'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +"/report/paypoints"} data-qaid="topBarMenuPaypointsLink"><IoStorefrontOutline/> Paypoints</Link>
                    }
                     {userPermissions.indexOf("Residuals") !== -1 &&
                    <Link className={this.props.selected === 'residuals'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +"/report/summaryresiduals"} data-qaid="topBarMenuResidualsLink"><RiMoneyDollarBoxLine/> Residuals</Link>
                    }
                </div>
                <div className="hide show-sm">
                    {userPermissions.indexOf("Organizations") !== -1 &&
                    <Link className={this.props.selected === 'organizations'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +"/report/organizations"} data-qaid="topBarMenuOrganizationsLink"><BiGridSmall style={{fontSize: "29px"}}/></Link>
                    }
                     {userPermissions.indexOf("Paypoints") !== -1 &&
                    <Link className={this.props.selected === 'paypoints'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +"/report/paypoints"} data-qaid="topBarMenuPaypointsLink"><IoStorefrontOutline/></Link>
                    }
                     {userPermissions.indexOf("Residuals") !== -1 &&
                    <Link className={this.props.selected === 'residuals'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +"/report/summaryresiduals"} data-qaid="topBarMenuResidualsLink"><RiMoneyDollarBoxLine/></Link>
                    }
                </div>
            </div>
           
            </div>
        )
    }
}

export { OrganizationsLinks };