import React, { useState, useEffect, useRef } from 'react'
import { inject } from 'mobx-react'
import {
	templatesV1GET,
	updateTemplateV1PUT,
	userOneTimePUT
} from '../../../../store/Template/Connections/connections'
import { Layout } from '../../../../components/Layout'
import { MainTopBarMenu } from '../../../../components/MainTopBarMenu'
import { TopBar } from '../../../../components/TopBar'
import styles from '../../../../assets/css/styleCommandCenter.module.css'
import { BiChevronLeft } from 'react-icons/bi'
import { RecordsNotFound } from '../../../../components/RecordsNotFound'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import HelloSignForm from '../FormView/HelloSignForm'
import HelloSignStore from '../store/HelloSign.store'

const HelloSignTemplate = inject(
	'reports',
	'store',
	'global'
)((props) => {
	const [templates, setTemplates] = useState([])
	const [editMode, setEditMode] = useState(false)
	const [inputDisabled, setInputDisabled] = useState(true)
	const [activeTemplate, setActiveTemplate] = useState(null)
	const formTemplate = useRef(null)
	const openEditMode = (template, e) => {
		setActiveTemplate(template)
		setEditMode(true)
	}
	useEffect(() => {
		try {
			props.global.setLoading(true)
			const load = async () => {
				const uniqueUserToken = await userOneTimePUT()
				let result = []
				if (uniqueUserToken.data.responseData) {
					const options = await templatesV1GET(
						uniqueUserToken.data.responseData
					)
					if (options) {
						options?.data?.forEach((template) => {
							result.push({
								id: template.id,
								name: template.name,
								fields: template.fields
							})
						})
					}
					if (!unmounted) {
						setTemplates(result)
						props.global.setLoading(false)
					}
				}
			}
			let unmounted = false
			load()
			return () => {
				unmounted = true
			}
		} catch (error) {
			console.log('error', error)
		}
	}, [props.global])

	const MapTemplate = async () => {
		props.global.setLoading(true)
		if (formTemplate.current) {
			try {
				const body = {
					columnsSlug:
						formTemplate.current.getAllData(activeTemplate),
					name: activeTemplate.name,
					description: activeTemplate.name,
					type: 'update'
				}
				const cleanBody = Object.keys(body.columnsSlug).reduce(
					(acc, key) => {
						if (body.columnsSlug[key] !== 'Select') {
							acc[key] = body.columnsSlug[key]
						}
						return acc
					},
					{}
				)

				body.columnsSlug = cleanBody
				if (Object.keys(body.columnsSlug).length > 0) {
					const uniqueUserToken = await userOneTimePUT()
					await updateTemplateV1PUT(
						uniqueUserToken.data.responseData,
						activeTemplate.id,
						body
					)
				}
				setInputDisabled(true)
				toast.success('The template mapping was saved successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			} catch (error) {
				toast.error(
					String(error) ||
						'An error occurred while saving the template mapping!',
					{
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					}
				)
			} finally {
				props.global.setLoading(false)
			}
		}
	}
	return (
		<Layout {...props}>
			<div>
				<TopBar>
					<MainTopBarMenu />
					<div
						className="top-bar-sub"
						style={{ padding: '8px 0px 25 40px' }}>
						<div className="row d-flex justify-content-center align-items-center">
							<div
								className="col-2"
								style={{ paddingTop: '1rem' }}>
								{editMode && (
									<button
										onClick={() => {
											setEditMode(false)
											setInputDisabled(true)
										}}
										className="btn btn-light text-transform-normal">
										<BiChevronLeft /> Go back
									</button>
								)}
							</div>
							<div
								className={'col-6 text-center'}
								style={{ paddingTop: '1.4rem' }}>
								<h6>
									{editMode && activeTemplate ? (
										<di>{activeTemplate.name}</di>
									) : (
										'TEMPLATES MAPPER'
									)}
								</h6>
								<small className="text-muted">
									{editMode && activeTemplate?.id}
								</small>
							</div>
							<div
								className={'col-4 text-right'}
								style={{ paddingTop: '1rem' }}>
								<div className={styles['status-btn-group']}>
									{editMode && inputDisabled && (
										<button
											onClick={() =>
												setInputDisabled(false)
											}
											className={`btn btn-primary`}>
											Edit
										</button>
									)}
									{editMode && !inputDisabled && (
										<>
											<button
												onClick={() =>
													setInputDisabled(true)
												}
												className={`btn btn-light`}>
												Cancel
											</button>
											<button
												onClick={() => MapTemplate()}
												className={`btn btn-primary`}>
												SAVE CHANGES
											</button>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</TopBar>
				<div className="mt-body2">
					{!editMode && (
						<>
							<div className="report-container">
								<table className="table table-hover table-striped">
									<thead>
										<tr>
											<th>Template id</th>
											<th>Template Name</th>
										</tr>
									</thead>
									<tbody>
										{templates?.map((record, i) => (
											<React.Fragment key={i}>
												<tr
													className="cursorPointer"
													onClick={(e) =>
														openEditMode(record, e)
													}
													onMouseUp={(e) =>
														props.global.setCoordClickUp(
															e
														)
													}
													onMouseDown={(e) =>
														props.global.setCoordClickDown(
															e
														)
													}>
													<td>
														{record.id
															? record.id
															: '-'}
													</td>
													<td>
														{record.name
															? record.name
															: '-'}
													</td>
												</tr>
											</React.Fragment>
										))}
									</tbody>
								</table>
								{templates.length < 1 && (
									<RecordsNotFound
										message="No Templates yet."
										description={
											<span>
												HelloSign Templates that are
												created will display here.
											</span>
										}
									/>
								)}
							</div>
						</>
					)}
					{editMode && (
						<HelloSignStore>
							<HelloSignForm
								template={activeTemplate}
								ref={formTemplate}
								inputDisabled={inputDisabled}
							/>
						</HelloSignStore>
					)}
				</div>
				<ToastContainer transition={Bounce} />
			</div>
		</Layout>
	)
})
export default HelloSignTemplate
