import React from 'react'
import RowContainer from '../../../../../components/commandCenter/RowContainer'
import GlobalStore from '../../../../../store/GlobalStore'

export default function Global_gp({ inputValues, SetInputValues, setClassValidate, classValidate }) {
	return (
		<>
			{inputValues.processor === 'gp' && (
				<div className="d-flex flex-column">
					<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.gp.merchantId}
								inputDisabled={false}
								labelValue="Merchant ID"
								name="merchantId"
								placeholder="Value"
								value={inputValues.merchantId}
								onBlur={() => setClassValidate({ ...classValidate, gp: { ...classValidate.gp, merchantId: inputValues.merchantId ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, merchantId: values }) | setClassValidate({ ...classValidate, gp: { ...classValidate.gp, merchantId: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>

							<RowContainer
								inputType="mask"
								inputDisabled={false}
								labelValue="Tokenization Account Name"
								name="tokenizationAccountName"
								placeholder="Value"
								value={inputValues.tokenizationAccountName}
								setValue={(values) => SetInputValues({ ...inputValues, tokenizationAccountName: values })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>

							<RowContainer
								inputType="mask"
								inputDisabled={false}
								labelValue="Tokenization Account ID"
								name="tokenizationAccountId"
								placeholder="Value"
								value={inputValues.tokenizationAccountId}
								setValue={(values) => SetInputValues({ ...inputValues, tokenizationAccountId: values })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>

							<RowContainer
								inputType="mask"
								classNameInput={classValidate.gp.transactionAccountName}
								inputDisabled={false}
								labelValue="Transaction Account Name"
								name="transactionAccountName"
								placeholder="Value"
								value={inputValues.transactionAccountName}
								onBlur={() => setClassValidate({ ...classValidate, gp: { ...classValidate.gp, transactionAccountName: inputValues.transactionAccountName ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, transactionAccountName: values }) | setClassValidate({ ...classValidate, gp: { ...classValidate.gp, transactionAccountName: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
						</div>
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.gp.transactionAccountId}
								inputDisabled={false}
								labelValue="Transaction Account ID"
								name="transactionAccountId"
								placeholder="Value"
								value={inputValues.transactionAccountId}
								onBlur={() => setClassValidate({ ...classValidate, gp: { ...classValidate.gp, transactionAccountId: inputValues.transactionAccountId ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, transactionAccountId: values }) | setClassValidate({ ...classValidate, gp: { ...classValidate.gp, transactionAccountId: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>

							<RowContainer
								inputType="mask"
								classNameInput={classValidate.gp.fundingAccountName}
								inputDisabled={false}
								labelValue="Funding Account Name"
								name="fundingAccountName"
								placeholder="Value"
								value={inputValues.fundingAccountName}
								onBlur={() => setClassValidate({ ...classValidate, gp: { ...classValidate.gp, fundingAccountName: inputValues.fundingAccountName ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, fundingAccountName: values }) | setClassValidate({ ...classValidate, gp: { ...classValidate.gp, fundingAccountName: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>

							<RowContainer
								inputType="mask"
								classNameInput={classValidate.gp.fundingAccountId}
								inputDisabled={false}
								labelValue="Funding Account ID"
								name="fundingAccountId"
								placeholder="Value"
								value={inputValues.fundingAccountId}
								onBlur={() => setClassValidate({ ...classValidate, gp: { ...classValidate.gp, fundingAccountId: inputValues.fundingAccountId ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, fundingAccountId: values }) | setClassValidate({ ...classValidate, gp: { ...classValidate.gp, fundingAccountId: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
