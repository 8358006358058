import React from 'react';
import { inject, observer } from 'mobx-react';
import { Col } from "react-bootstrap";

@inject('boarding')
@observer
class CheckLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: true, cname:'' };
        this.handleChangeV = this.handleChangeV.bind(this);
    }

    componentDidMount() {
        this.setState({ value: this.props.iValue, cname: this.props.iName }, function () {
            this.props.boarding.setTemplateData(this.state.cname, this.state.value);
        });
    }

    handleChangeV(checked) {
        this.props.boarding.setTemplateData(this.state.cname, checked.target.checked);
        this.setState({ value:checked.target.checked });
    }

    render() {
        return (
            <Col md="auto">
            <div className={this.props.iInvisible ? "hide" : "icheck-primary" }>
                <input checked={!!this.state.value}  type="checkbox" name={this.props.iName} id={this.props.iName} onChange={this.handleChangeV}  />
                <label htmlFor={this.props.iName}>{ this.props.iTitle }</label>
            </div>

            </Col>
        )
    }
}

export { CheckLink };