import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from "react-router-dom";
import { PayabliStorageManager } from '../api/localStorageManager'
import {BiCustomize} from "@react-icons/all-files/bi/BiCustomize";
import {BiReceipt} from "@react-icons/all-files/bi/BiReceipt";
import {BiStore} from "@react-icons/all-files/bi/BiStore";
import {BiUser} from "@react-icons/all-files/bi/BiUser";
import { BiUserPin } from "@react-icons/all-files/bi/BiUserPin";
import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft';

@inject('reports', 'global')
@observer
class SettingsLinks extends React.Component {

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        return (
            <div className="row">
                {this.props.goback &&
                <div className="col-2">
                    <div className="hide-sm">
                            <a href={this.props.gobackLink} className="btn btn-light text-transform-normal"><BiChevronLeft /> {this.props.gobackText ? this.props.gobackText : "Go back"}</a>
                    </div>
                </div>
                }
                <div className={this.props.goback ? "col-10 text-center" :"col-12 text-center"}>
                <div className="hide-sm">
                    {userPermissions.indexOf("Organizations") !== -1 &&
                    <Link className={this.props.selected === 'orgdetails'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/orgdetails"}><BiStore/> Org Details</Link>
                    }
                    {encryptStorage && userPermissions.indexOf("Profile") !== -1 &&
                    <a className={this.props.selected === 'profile'? 'selected' : ''} href={"/" + this.props.global.getURLEntry() + "/profile/"+encryptStorage.getItem('pUser').id}><BiUser/> My Profile</a>
                    }
                    {userPermissions.indexOf("Custom Fields") !== -1 &&
                    <Link className={this.props.selected === 'fields'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/fields"}><BiCustomize/> Custom Fields</Link>
                    }
                    {userPermissions.indexOf("Notifications") !== -1 &&
                    <Link className={this.props.selected === 'notifications'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/notifications"}><BiReceipt/> Reports & Notifications</Link>
                    }
                    {userPermissions.indexOf("Manage Users") !== -1 &&
                    <Link className={this.props.selected === 'manageusers'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/report/users"}><BiUserPin/> Manage Users</Link>
                    }
                </div>
                <div className="hide show-sm sm-screen-topbar-menu">
                    {userPermissions.indexOf("Organizations") !== -1 &&
                    <Link className={this.props.selected === 'orgdetails'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/orgdetails"}><BiStore/></Link>
                    }
                    {encryptStorage && userPermissions.indexOf("Profile") !== -1 &&
                    <a className={this.props.selected === 'profile'? 'selected' : ''} href={"/" + this.props.global.getURLEntry() + "/profile/"+encryptStorage.getItem('pUser').id}><BiUser/></a>
                    }
                    {userPermissions.indexOf("Custom Fields") !== -1 &&
                    <Link className={this.props.selected === 'fields'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/fields"}><BiCustomize/></Link>
                    }
                    {userPermissions.indexOf("Notifications") !== -1 &&
                    <Link className={this.props.selected === 'notifications'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/notifications"}><BiReceipt/></Link>
                    }
                    {userPermissions.indexOf("Manage Users") !== -1 &&
                    <Link className={this.props.selected === 'manageusers'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/report/users"}><BiUserPin/></Link>
                    }
                </div>
            </div>            
        </div>
        )
    }
}

export { SettingsLinks };
