import React, { useState, useContext } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import { BiShieldQuarter, BiDotsVerticalRounded } from 'react-icons/bi'
import FrameGroup from '../../../components/commandCenter/FrameGroup'
import ButtonSaveCancel from '../../../components/commandCenter/ButtonSaveCancel'
import RowContainer from '../../../components/commandCenter/RowContainer'
import { Element } from 'react-scroll'
import commandContext from '../context/commandCenter_context'

export default function FraudControl() {
	const { PayPoint, fraudControls, handleChangeFraud, loadFraudCtr, updateFraudcontrol, toastMessage } = useContext(commandContext)
	const [inputDisabled, setInputDisabled] = useState(true)

	const updateFraud = async () => {
		let valid = false
		if (fraudControls.maxDeclinedPerCardHourly === null) valid = true
		if (fraudControls.maxDeclinedPerPointHourly === null) valid = true
		if (fraudControls.maxDeclinedPerPayorHourly === null) valid = true
		if (fraudControls.maxTx15Min === null) valid = true
		if (valid) {
			toastMessage('You must fill in the required fields', false)
			return
		}
		const res = await updateFraudcontrol()
		if (res) setInputDisabled(true)
	}

	return (
		<Element
			name="Fraud"
			className="d-flex justify-content-center w-100 mb-5">
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={<BiShieldQuarter className={styles['icon26'] + ' ' + styles['color-blue']} />}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label="Fraud Controls"
					editClick={() => setInputDisabled(false)}
					useBtn={PayPoint.idPaypoint <= 0 ? false : inputDisabled}
					btnDelete={false}
				/>

				<div className="d-flex flex-column align-items-start p-0 pb-2 gap-1 w-100">
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-12']}
						style={{ fontWeight: '700', lineHeight: '18px', textTransform: 'uppercase', color: '#606060' }}>
						MONEY IN
					</label>
					<div className="d-flex flex-column align-items-start p-0 gap-3 w-100">
						<label
							className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
							style={{ fontWeight: '600', lineHeight: '24px' }}>
							Paypoint
						</label>
						<div className="d-flex flex-column w-100">
							<div className={styles['grid-container']}>
								<div className="d-flex flex-column align-items-start p-0">
									{/* <RowContainer
										inputType="number"
										prefix=""
										decimalScale={0}
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && fraudControls.maxAmountPeriod === null ? 'is-invalid' : ''}
										labelValue="Max. Amount Period"
										name="maxAmountPeriod"
										placeholder="Value"
										value={fraudControls.maxAmountPeriod}
										setValue={(values) => handleChangeFraud('maxAmountPeriod', values === '' ? '0' : values)}
										mask=""
									/> */}
									<RowContainer
										inputType="number"
										prefix=""
										decimalScale={0}
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && fraudControls.maxDeclinedPerCardHourly === null ? 'is-invalid' : ''}
										labelValue="Max. Declined Per Card Hourly"
										name="maxDeclinedPerCardHourly"
										placeholder="Value"
										value={fraudControls.maxDeclinedPerCardHourly}
										setValue={(values) => handleChangeFraud('maxDeclinedPerCardHourly', values === '' ? '0' : values)}
										mask=""
									/>
									<RowContainer
										inputType="number"
										prefix=""
										decimalScale={0}
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && fraudControls.maxDeclinedPerPointHourly === null ? 'is-invalid' : ''}
										labelValue="Max. Declined Per Point Hourly"
										name="maxDeclinedPerPointHourly"
										placeholder="Value"
										value={fraudControls.maxDeclinedPerPointHourly}
										setValue={(values) => handleChangeFraud('maxDeclinedPerPointHourly', values === '' ? '0' : values)}
										mask=""
									/>
								</div>

								<div className="d-flex flex-column align-items-start p-0">
									<RowContainer
										inputType="number"
										prefix=""
										decimalScale={0}
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && fraudControls.maxDeclinedPerPayorHourly === null ? 'is-invalid' : ''}
										labelValue="Max. Declined Per Payor Hourly"
										name="maxDeclinedPerPayorHourly"
										placeholder="Value"
										value={fraudControls.maxDeclinedPerPayorHourly}
										setValue={(values) => handleChangeFraud('maxDeclinedPerPayorHourly', values === '' ? '0' : values)}
										mask=""
									/>
									<RowContainer
										inputType="number"
										prefix=""
										decimalScale={0}
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && fraudControls.maxTx15Min === null ? 'is-invalid' : ''}
										labelValue="Max. Transactions In 15 Minutes"
										name="maxTx15Min"
										placeholder="Value"
										value={fraudControls.maxTx15Min}
										setValue={(values) => handleChangeFraud('maxTx15Min', values === '' ? '0' : values)}
										mask=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<ButtonSaveCancel
					inputDisabled={inputDisabled}
					funtionCancel={() => setInputDisabled(true) | loadFraudCtr('', '')}
					funtionSave={() => updateFraud()}
				/>
			</div>
		</Element>
	)
}
