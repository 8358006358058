import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiErrorCircle, BiDollarCircle, BiXCircle, BiLogOutCircle, BiLogInCircle, BiLoader, BiShare, BiPauseCircle, BiDollar, BiUpArrowCircle } from 'react-icons/bi';
import {LuGoal} from 'react-icons/lu';
import DatePicker from "react-datepicker";
import { RenderCurrency } from '../../components/filters/RenderCurrency';
import { RenderCustomerInformation } from '../../components/filters/RenderCustomerInformation';
import { RenderCustomFields } from '../../components/filters/RenderCustomFields';
import { RenderFeeAmount } from '../../components/filters/RenderFeeAmount';
import { RenderInvoiceNumber } from '../../components/filters/RenderInvoiceNumber';
import { RenderNetAmount } from '../../components/filters/RenderNetAmount';
import { RenderPaymentInformation } from '../../components/filters/RenderPaymentInformation';
import { RenderPopoverTime } from '../../components/filters/RenderPopoverTime';
import { RenderSource } from '../../components/filters/RenderSource';
import { RenderTotalAmount } from '../../components/filters/RenderTotalAmount';
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'filter', 'global')
@observer
class ViewPartialTransactions extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Date</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="dateCalendar" 
                                        name="dateCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateDate) + ' → ' : '') + (this.props.filter.stateDate.endDateDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateDate) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="dateCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateDate ? this.props.filter.stateDate.startDateDate : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateDate', 'endDateDate', 'transactionDate')}
                            startDate={this.props.filter.stateDate.startDateDate}
                            endDate={this.props.filter.stateDate.endDateDate}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateDate !== null ? false : true}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Time</small></p>
                        <RenderPopoverTime />
                    </div>
                </div>
                <div>
                    <p><small>Payment Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[11]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 11, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[11]))}><BiLogInCircle /> Authorized</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))} data-qaid="successStatusFilterTransactionsMoneyIn"><BiDollarCircle /> Approved</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('status(in)', '2;3', !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]))}><BiXCircle /> Declined</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]) ? "badge bg-light" : "badge text-light"} onClick={() => this.props.filter.handleStatus('status(in)', 5, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]))}><BiLogOutCircle /> Voided</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('status(in)', '-1;0', !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiErrorCircle /> Failed</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[10]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleStatus('status(in)', 10, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[10]))}><LuGoal /> Initiated</span>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <RenderInvoiceNumber />
                        </div>                        
                    </div>
                </div>
                <div>
                    <RenderNetAmount />
                    <ContainerShowMoreLess>
                        <div>
                            <hr className='mt-1 mb-4'></hr>
                            { <RenderFeeAmount /> }
                            { <RenderTotalAmount /> }
                            <div className='row'>
                                <div className="col-sm-6 col-xs-12 mb-4">
                                    <div className="form-floating">
                                        <input name="orderId" id="orderId" className={"form-control"} placeholder="Order ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('orderId(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('orderId(eq)', e)} value={this.props.filter.filterText.orderId ? this.props.filter.filterText.orderId : ''} />
                                        <label htmlFor="orderId">Order ID</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ContainerShowMoreLess>
                </div>
                <div>
                    <RenderPaymentInformation />
                </div>
                <div>
                    <RenderCustomerInformation />
                </div>
                <div>
                    <h5 className='title'>Processing Information</h5>
                    <div className='row'>  
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="transactionId" id="transactionId" className={"form-control"} placeholder="Transaction ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('gatewayTransId(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('gatewayTransId(ct)', e)} value={this.props.filter.filterText.transactionId ? this.props.filter.filterText.transactionId : ''} />
                                <label htmlFor="transactionId">Transaction ID</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="paymentId" id="paymentId" className={"form-control"} placeholder="Payment ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('transId(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('transId(ct)', e)} value={this.props.filter.filterText.paymentId ? this.props.filter.filterText.paymentId : ''} />
                                <label htmlFor="paymentId">Payment ID</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="authCode" id="authCode" className={"form-control"} placeholder="Auth. Code" onChange={(e) => this.props.filter.handleCaptureTextFilter('authCode(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('authCode(ct)', e)} value={this.props.filter.filterText.authCode ? this.props.filter.filterText.authCode : ''} />
                                <label htmlFor="authCode">Auth. Code</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="batchNumber" id="batchNumber" className={"form-control"} placeholder="Batch Number" onChange={(e) => this.props.filter.handleCaptureTextFilter('batchNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('batchNumber(ct)', e)} value={this.props.filter.filterText.batchNumber ? this.props.filter.filterText.batchNumber : ''} />
                                <label htmlFor="batchNumber">Batch Number</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <RenderSource />
                    <p className='mb-2'><small>Batch Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={ (Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[0]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleSettlementStatus('settlementStatus(in)', 0, !(Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[0]))}><BiLoader /> Pending</span>
                        <span className={ (Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[1]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleSettlementStatus('settlementStatus(in)', 1, !(Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[1]))}>{this.props.global.getGlobalImg((Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[1]) ? 'intransitinfodark' : 'intransitinfo', '12px')} In Transit</span>
                        <span className={ (Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[2]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleSettlementStatus('settlementStatus(in)', 2, !(Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[2]))}><BiDollar /> Transferred</span>
                        <span className={ (Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[3]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleSettlementStatus('settlementStatus(in)', 3, !(Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[3]))}><BiDollarCircle /> Funded</span>
                        <span className={ (Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[-1]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleSettlementStatus('settlementStatus(in)', -1, !(Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[-1]))}><BiErrorCircle /> Exception</span>
                        <span className={ (Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[-2]) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleSettlementStatus('settlementStatus(in)', -2, !(Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[-2]))}><BiShare /> ACH Return/Chargeback</span>
                        <span className={ (Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[-5]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleSettlementStatus('settlementStatus(in)', -5, !(Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[-5]))}><BiPauseCircle /> Held</span>
                        <span className={ (Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[-6]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleSettlementStatus('settlementStatus(in)', -6, !(Object.keys(this.props.filter.filterSettlementStatus).length > 0 && this.props.filter.filterSettlementStatus[-6]))}><BiUpArrowCircle /> Released</span>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <RenderCurrency />
                        </div>
                    </div>                            
                </div> 
                <div>
                    <ContainerShowMoreLess>
                        <hr className='mb-3 mt-2' style={{width: '95%'}}></hr>
                        <div className="col-sm-6 col-xs-12">
                            <p className='mb-1'><small>Cycle</small></p>
                            <div className="form-group mt-1" style={{fontSize: '20px', display: 'inline-flex'}}>
                                <div className="icheck-primary mr-3">
                                    <input type="radio" name="inlineRadioScheduled" id="inlineRadioScheduled" value="0" checked={this.props.filter.filterRadioOption.inlineRadioScheduled ? true : false} onChange={(e) => this.props.filter.handleRadioOptionCycle(e, 'scheduleId(ne)')} />
                                    <label htmlFor="inlineRadioScheduled">Scheduled</label>
                                </div>
                                <div className="icheck-primary">
                                    <input type="radio" name="inlineRadioOneTime" id="inlineRadioOneTime" value="0" checked={this.props.filter.filterRadioOption.inlineRadioOneTime ? true : false} onChange={(e) => this.props.filter.handleRadioOptionCycle(e, 'scheduleId(eq)')} />
                                    <label htmlFor="inlineRadioOneTime">One-Time</label>
                                </div>
                            </div>
                        </div>
                    </ContainerShowMoreLess>
                </div>
                { Array.isArray(this.props.filter.customFields) && <RenderCustomFields /> }
        </>);
    }
}

export { ViewPartialTransactions };