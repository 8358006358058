import React from 'react';
import { inject, observer } from 'mobx-react';
import NumberFormat from 'react-number-format';

@inject('global', 'filter')
@observer
class RenderPaymentInformation extends React.Component {

    render() {
        return (<>
                <h5 className='title'>Pay Method</h5>
                <div className='row section-payment-information'>
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterMethods.card && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethod('card', 'method(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.card))}>{this.props.global.getPaymethodImg('card')}</span>
                        <p>card</p>
                    </div>                        
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterMethods.ach && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethod('ach', 'method(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.ach))}>{this.props.global.getPaymethodImg('ach')}</span>
                        <p>ach</p>
                    </div>
                    <div className="col-2 mb-3">
                    <span className={'disabled'}>{this.props.global.getPaymethodImg('wallet')}</span>
                        <p>wallet</p>
                    </div>
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterMethods.check && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethod('check', 'method(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.check))}>{this.props.global.getPaymethodImg('check')}</span>
                        <p>phys. check</p>
                    </div>
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterMethods.cash && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethod('cash', 'method(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.cash))}>{this.props.global.getPaymethodImg('cash')}</span>
                        <p>cash</p>
                    </div>                         
                </div>
                <p className="mb-2"><small>Brand & Account Type</small></p>
                <div className='row section-payment-information mb-4'>
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterBrand.visa && 'section-payment-information-active'} onClick={() => this.props.filter.handleBrand('visa', 'payaccountType(in)', !(Object.keys(this.props.filter.filterBrand).length > 0 && this.props.filter.filterBrand.visa))}>{this.props.global.getPaymethodImg('visa')}</span>
                        <p>visa</p>
                    </div>
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterBrand.mastercard && 'section-payment-information-active'} onClick={() => this.props.filter.handleBrand('mastercard', 'payaccountType(in)', !(Object.keys(this.props.filter.filterBrand).length > 0 && this.props.filter.filterBrand.mastercard))}>{this.props.global.getPaymethodImg('mastercard')}</span>
                        <p>mastercard</p>
                    </div>
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterBrand.discover && 'section-payment-information-active'} onClick={() => this.props.filter.handleBrand('discover', 'payaccountType(in)', !(Object.keys(this.props.filter.filterBrand).length > 0 && this.props.filter.filterBrand.discover))}>{this.props.global.getPaymethodImg('discover')}</span>
                        <p>discover</p>
                    </div>
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterBrand.amex && 'section-payment-information-active'} onClick={() => this.props.filter.handleBrand('amex', 'payaccountType(in)', !(Object.keys(this.props.filter.filterBrand).length > 0 && this.props.filter.filterBrand.amex))}>{this.props.global.getPaymethodImg('american-express')}</span>
                        <p>amex</p>
                    </div>
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterBrand.jcb && 'section-payment-information-active'} onClick={() => this.props.filter.handleBrand('jcb', 'payaccountType(in)', !(Object.keys(this.props.filter.filterBrand).length > 0 && this.props.filter.filterBrand.jcb))}>{this.props.global.getPaymethodImg('jcb')}</span>
                        <p>jcb</p>
                    </div>                            
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterBrand.savings && 'section-payment-information-active'} onClick={() => this.props.filter.handleBrand('savings', 'payaccountType(in)', !(Object.keys(this.props.filter.filterBrand).length > 0 && this.props.filter.filterBrand.savings))}>{this.props.global.getPaymethodImg('ach')}</span>
                        <p>savings</p>
                    </div>
                    <div className="col-2 mb-3">
                        <span className={this.props.filter.filterBrand.checking && 'section-payment-information-active'} onClick={() => this.props.filter.handleBrand('checking', 'payaccountType(in)', !(Object.keys(this.props.filter.filterBrand).length > 0 && this.props.filter.filterBrand.checking))}>{this.props.global.getPaymethodImg('ach')}</span>
                        <p>checking</p>
                    </div>
                    <div className="col-2 mb-3">
                        <span className={'disabled'}>{this.props.global.getPaymethodImg('applepay')}</span>
                        <p>apple pay</p>
                    </div>                        
                    <div className="col-2 mb-3">
                        <span className={'disabled'}>{this.props.global.getPaymethodImg('googlepay')}</span>
                        <p>google pay</p>
                    </div>
                    <div className="col-2 mb-3">
                        <span className={'disabled'}>{this.props.global.getPaymethodImg('paypal')}</span>
                        <p>paypal</p>
                    </div>
                    <div className="col-2 mb-3">
                        <span className={'disabled'}>{this.props.global.getPaymethodImg('dinersclub')}</span>
                        <p>dinersclub</p>
                    </div>
                </div>
                <div className='row mt-2 mb-4'>
                    <div className='col-sm-6 mt-1'>
                        <div className="form-floating">
                            <NumberFormat
                                format={'####'}
                                thousandsGroupStyle="thousand"
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                allowNegative={false}
                                value={this.props.filter.filterText.lastFour ? this.props.filter.filterText.lastFour : ''}
                                placeholder={"Last 4"}
                                className="form-control"
                                onValueChange={(values) => this.props.filter.handleCaptureTextFilterObject('payaccountLastfour(ct)', values, 'lastFour')}
                                onKeyDown={(e) => this.props.filter.handleKeyDownObject('payaccountLastfour(ct)', e, 'lastFour')}
                            />
                            <label>{"Last 4"}</label>
                        </div>
                    </div>
                    <div className='col-sm-6' style={{marginTop: '-20px'}}>
                        <p className='mb-1'><small>Type</small></p>
                        <div className="form-group" style={{fontSize: '20px', display: 'inline-flex'}}>
                            <div className="icheck-primary mr-3">
                                <input type="radio" name="inlineRadioSale" id="inlineRadioSale" value="sale" checked={this.props.filter.filterRadioOption.inlineRadioSale ? true : false} onChange={(e) => this.props.filter.handleRadioOption(e, 'operation(eq)')} />
                                <label htmlFor="inlineRadioSale">Sale</label>
                            </div>
                            <div className="icheck-primary">
                                <input type="radio" name="inlineRadioRefund" id="inlineRadioRefund" value="refund" checked={this.props.filter.filterRadioOption.inlineRadioRefund ? true : false} onChange={(e) => this.props.filter.handleRadioOption(e, 'operation(eq)')} />
                                <label htmlFor="inlineRadioRefund">Refund</label>
                            </div>
                        </div>
                        <div className="form-group" style={{fontSize: '20px', display: 'inline-flex'}}>
                            <div className="icheck-primary">
                                <input type="radio" name="inlineRadioCredit" id="inlineRadioCredit" value="credit" checked={this.props.filter.filterRadioOption.inlineRadioCredit ? true : false} onChange={(e) => this.props.filter.handleRadioOption(e, 'operation(eq)')} />
                                <label htmlFor="inlineRadioCredit">Credit</label>
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}

export { RenderPaymentInformation };