import { action } from 'mobx'

export default function OwnerFunction({ owner, setOwner }) {
	const handleChangeOwner = action((indexArray, fieldArray, field, value) => {
		const newOwner = owner[fieldArray].map((data, index) => {
			if (index === indexArray) {
				return {
					...data,
					[field]: value
				}
			}
			return data
		})
		setOwner({ ...owner, [fieldArray]: newOwner })
	})
	const handleChangeOwnerMapper = action((field, value) => {
		setOwner({ ...owner, [field]: value })
	})
	const addOwner = action((fieldArray) => {
		const newData =
			fieldArray === 'ownership'
				? {
						ownername: '',
						ownertitle: '',
						ownerpercent: 0,
						ownerssn: '',
						ownerdob: new Date(),
						ownerphone1: '',
						ownerphone2: '',
						owneremail: '',
						ownerdriver: '',
						odriverstate: '',
						oaddress: '',
						ocity: '',
						ozip: '',
						ostate: '',
						ocountry: ''
				  }
				: {
						contactName: '',
						contactTitle: '',
						contactEmail: '',
						contactPhone: ''
				  }
		setOwner({ ...owner, [fieldArray]: [...owner[fieldArray], newData] })
	})
	const removeOwner = action((index, field) => {
		const newData = owner[field]
		newData.splice(index, 1)
		setOwner({ ...owner, [field]: newData })
	})
	return { handleChangeOwner, addOwner, removeOwner, handleChangeOwnerMapper }
}
