import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import zxcvbn from 'zxcvbn';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { BiLowVision } from '@react-icons/all-files/bi/BiLowVision';
import { BiUnlink } from '@react-icons/all-files/bi/BiUnlink';
import { BiShowAlt } from '@react-icons/all-files/bi/BiShowAlt';
import { Brand } from '../../components/Brand';
import * as Sentry from "@sentry/react";

@inject('global','user')
@observer
class ResetPassword extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            password: "",
            repassword: "",
            entry:null,
            token:null,
            formErrors: {},
            score: 0,
            showPassword: false,
            showRepeatPassword: false,
            isInactiveToken: false,
            componentReady: false
        };
    
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.showPassword = this.showPassword.bind(this);
        this.showRepeatPassword = this.showRepeatPassword.bind(this);
    }

    handleKeyDown(e){
        if (e.key === 'Enter') {
           
        }
    }
    
    componentDidMount() {
        let entry = PayabliStorageManager.getEntryName();
        let token = this.props.match.params.token;
        this.setState({entry:entry , token: token});

        this.props.global.setLoading(false);
        this.setState({isInactiveToken: false, componentReady: true});
    }

    handleTextChange(event) {
        this.setState({ [event.target.name] : event.target.value}, function(){
            let testedResult = zxcvbn(this.state.password);
            this.setState({score : testedResult.score});
        });
    }

    resetPassword(){
        let errors = this.validateFields();
        this.setState({ formErrors: errors }, function(){
            if(Object.keys(errors).length === 0){
                
                var user ={
                    psw: this.state.password,
                    entry: this.state.entry
                }
              
                this.props.global.setLoading(true);
                this.props.user.resetPassword(user, this.state.token)
                .then(result => {
                    this.props.global.setLoading(false);
                    toast.success("Password changed successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });

                    setTimeout( function(){
                        window.location = "/"+user.entry+"/login";
                    }, 5000 );
                })
                .catch(error => {
                    
                    if(error.response && error.response.data && error.response.data.pageIdentifier ){
                        let newToken = error.response.data.pageIdentifier;
                        this.setState({token: newToken});
                    }

                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
                
            }
        });
        
    }

    showPassword(){
        this.setState({ showPassword:!this.state.showPassword });
    }

    showRepeatPassword(){
        this.setState({ showRepeatPassword:!this.state.showRepeatPassword });
    }

      validateFields(){
        let validators = this.props.global.validators;
        let errors = {};

        if(validators.isEmpty(this.state.password) || validators.isMaxLength(250, this.state.password)  || (this.state.score < 4))
        {
            errors['password']= true;
        }

        if(validators.isEmpty(this.state.repassword) || validators.isMaxLength(250, this.state.repassword) || (this.state.password !== this.state.repassword ))
        {
            errors['repassword']= true;
        }

        return errors;
    }

    
    render() {
        const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return (
        <>
        
            {this.props.global.isLoading &&
            <div id="main-loading-layer" className="d-flex justify-content-center align-items-center">
                <div className="spinner-border" role="status">
                </div>
            </div>
            }
            <div className="root-page text-center">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{height: "100%", position: "absolute", width: "100%"}}>
                <div className="card-login mb-3" style={{width: '35em'}}>

                {this.state.isInactiveToken ? 
                    <div>
                        <BiUnlink className='icon-modal'/>
                        <h5>Expired Link</h5>
                        <p>Please use the link below to resend the reset password link or contact <a href="mailto:support@payabli.com">support@payabli.com</a>.</p>
                        <Link className="small" to={"/"+this.state.entry+"/forgotpassword"}>Reset Password</Link>
                    </div>
                     :
                    <>
                
                    {this.state.componentReady === true &&
                        <>
                            <Brand brand={this.state} classBrand={'loginBrand'}/>
                            <p className="grey mb-4">Enter your new password</p>
                            <div className="form-floating mb-3">
                                <input autoComplete="off" type={ this.state.showPassword ? "text" : "password"} name="password" className={this.state.formErrors.password ? "form-control input-error" : "form-control"} placeholder="Password"  onChange={(e) => this.handleTextChange(e)}/>
                                <label htmlFor="password">Password</label>
                                {
                                    this.state.showPassword ? 
                                    <BiLowVision className="right-icon-on-input" onClick={(e)=>this.showPassword()}/> 
                                    :
                                    <BiShowAlt className="right-icon-on-input" onClick={(e)=>this.showPassword()}/>
                                }
                            </div>

                            <div className="form-floating mb-4">
                                <input autoComplete="off" type={ this.state.showRepeatPassword ? "text" : "password"} name="repassword" className={this.state.formErrors.repassword ? "form-control input-error" : "form-control"} placeholder="Re-enter Password"  onChange={(e) => this.handleTextChange(e)}/>
                                <label htmlFor="repassword">Re-enter Password</label>
                                {
                                    this.state.showRepeatPassword ? 
                                    <BiLowVision className="right-icon-on-input" onClick={(e)=>this.showRepeatPassword()}/> 
                                    :
                                    <BiShowAlt className="right-icon-on-input" onClick={(e)=>this.showRepeatPassword()}/>
                                }
                            </div>

                            <div className="text-align-center">
                            <p className="small">The password must be <b>Strong</b> to be valid</p>
                            </div>

                            <progress value={this.state.score} max="4" className={"mb-1 password-strength-meter-progress strength-"+this.props.global.createPasswordLabel(this.state.score)}/>
                            <p className="mb-4 small">Password is <b>{this.props.global.createPasswordLabel(this.state.score)}</b></p>
                        
                            {
                                this.state.error &&
                                (
                                    <div className="text-danger mb-3 small">{this.state.error}</div>
                                )
                            }

                        
                        
                            <button onClick={() => this.resetPassword()} className="full-w btn btn-lg btn-success mb-4" >Reset Password</button>

                            <p className="mb-2 small">Didn't get a password reset email? </p>
                            <Link className="small" to={"/"+this.state.entry+"/forgotpassword"}>Send email again</Link>
                        
                        </>
                    }
                    </>
                }
                </div>
                <p className="small-small">Powered by  <a href="/" className="small no-underline">Payabli</a></p>
            </div>
            </div>
            <ToastContainer transition={Bounce} />
        </>
        )
    }
}

export { ResetPassword };