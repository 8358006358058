import React from 'react';
import { BiLogInCircle, BiTrendingDown, BiTrendingUp } from 'react-icons/bi';
import { inject, observer } from 'mobx-react';

@inject('global')
@observer
class ResidualBox extends React.Component {
    constructor(props) {
        super(props);
        this.goToResidualsReport = this.goToResidualsReport.bind(this);
    }
    

    goToResidualsReport(period, e){
        if(e){
            e.preventDefault();
        }
        window.location =  "/" + this.props.global.getURLEntry() + `/report/residuals?rangeFrom=${this.props.reportParametersFrom}&rangeTo=${this.props.reportParametersTo}`;
    }

    render() {
        
        let percent;
        if(this.props.before !== 0){
            percent = (this.props.amount - this.props.before) / this.props.before * 100;
        }
        
        let result = percent ?? 'New'

        return (
            <>
            <div className='border border-1 p-3 rounded-4 mb-4' style={{minHeight: "137px"}}>
                <div className='mb-1'><span className='small-small grey fw-bold'>{this.props.label ? this.props.label : ""}</span></div>
                <h4 className='mb-4'>{this.props.amount ? this.props.global.numberWithCommasNegative(this.props.amount.toFixed(2)) : '$0.00'}</h4>
                
                    <div style={{float: 'left'}} className='text-left small-small grey mb-0'>
                        {result === 'New' ?
                            <>
                                <label className='fw-bold'>New</label>
                            </>
                            :
                            <>
                            {
                                result >= 0 ?
                                <span className="trending-up"><BiTrendingUp className='fs-6'/>{ result > 1000 ? this.props.global.kFormatter(result) : this.props.global.numberWithCommasNegative(result.toFixed(2), true)} % </span>
                                :
                                <span className="trending-down"><BiTrendingDown className='fs-6'/>{ result < -1000 ? this.props.global.kFormatter(result) : this.props.global.numberWithCommasNegative(result.toFixed(2), true)} % </span>
                            }
                            </>
                        }
                    </div>
                    {(this.props.reportParametersFrom && this.props.reportParametersTo) &&
                    <div style={{float: 'right'}} onClick={(e)=>this.goToResidualsReport()} className="small-small blue-text text-underline mb-0 cursor-pointer">
                        View Report
                    </div>}
               
            </div>
            </>
        )
    }
}

export { ResidualBox };
