import React, { useState, useContext } from 'react'
import { Modal, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import InputContainer from '../../../../components/inputStyles/InputContainer'
import GlobalStore from '../../../../store/GlobalStore'
import templatesContext from '../context/templates_context'
import Switch from 'react-switch'
import { BiInfoCircle } from 'react-icons/bi'

export default function ModalNewTemplate({ showModal, closeModal }) {
	const { OrgTree, CreateData } = useContext(templatesContext)
	const [dataNewTemplate, setDataNewTemplate] = useState(
		{ 
			templateName: '', 
			templateDescription: '', 
			templateCode: '', 
			OrgName: '', 
			orgId: 0, 
			recipientEmailNotification: true, 
			method: '',
			policyId: '',
			resumable: true })

	const handleChangeOrg = (currentNode, selectedNodes) => {
		if (selectedNodes && selectedNodes.length > 0) {
			setDataNewTemplate({ ...dataNewTemplate, orgId: selectedNodes[0].value, OrgName: selectedNodes[0].label })
		}
	}
	const saveTemplate = async () => {
		let valid = false
		if (dataNewTemplate.templateName.trim().length === 0 || dataNewTemplate.templateDescription.trim().length === 0 || dataNewTemplate.orgId === 0) valid = true
		if (valid) return false
		await CreateData(dataNewTemplate)
	}

	return (
		<Modal
			show={showModal}
			onHide={() => closeModal()}
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Header closeButton>
				<h5>Save Template</h5>
			</Modal.Header>
			<Modal.Body>
				<div className="row mb-2">
					<div className="col-12 form-group">
						<label>
							<span className="text-danger">*</span>Apply to Organization: <b>{dataNewTemplate.OrgName}</b>
						</label>
						<DropdownTreeSelect
							data={OrgTree.orgTree}
							onChange={handleChangeOrg}
							className="form-tree"
							mode="radioSelect"
						/>
					</div>
				</div>
				<div className="row mb-2">
					<InputContainer
						inputType="mask"
						divClass="form-group"
						labelClassName="font-weight-bold"
						classNameInput={`form-control ${dataNewTemplate.templateName.trim().length === 0 && 'input-error'}`}
						inputDisabled={false}
						labelValue={
							<label>
								<span className="text-danger">*</span>Title
							</label>
						}
						maxLength={250}
						name="templateName"
						placeholder="Enter Template Title"
						value={dataNewTemplate.templateName}
						setValue={(value) => setDataNewTemplate({ ...dataNewTemplate, templateName: value })}
						mask={GlobalStore.maskValidator('text')}
					/>
				</div>
				<div className="row mb-2">
					<InputContainer
						inputType="mask"
						divClass="form-group"
						labelClassName="font-weight-bold"
						classNameInput={`form-control ${dataNewTemplate.templateDescription.trim().length === 0 && 'input-error'}`}
						inputDisabled={false}
						labelValue={
							<label>
								<span className="text-danger">*</span>Description
							</label>
						}
						maxLength={250}
						name="templateDescription"
						placeholder="Enter Template Description"
						value={dataNewTemplate.templateDescription}
						setValue={(value) => setDataNewTemplate({ ...dataNewTemplate, templateDescription: value })}
						mask={GlobalStore.maskValidator('text')}
					/>
				</div>
				<div className="row mb-2">
					<InputContainer
						inputType="mask"
						divClass="form-group"
						labelClassName="font-weight-bold"
						classNameInput="form-control"
						inputDisabled={false}
						labelValue="Code"
						maxLength={50}
						name="templateCode"
						placeholder="Enter Template Code"
						value={dataNewTemplate.templateCode}
						setValue={(value) => setDataNewTemplate({ ...dataNewTemplate, templateCode: value })}
						mask={GlobalStore.maskValidator('text')}
					/>
				</div>
				<div className="row mb-2">
					<div className="form-group">
						<label>
							<span className="text-danger">*</span>Method
							<OverlayTrigger placement="top" overlay={
							<Tooltip>
								This field controls which method is used to handle risk orchestration..
							</Tooltip>
							}>
								<BiInfoCircle className='small ml-tiny'/>
							</OverlayTrigger>
						</label>
						<select
							onChange={(e) => setDataNewTemplate({ ...dataNewTemplate, method: e.target.value })}
							className={`form-control form-select ${dataNewTemplate.method.trim().length === 0 && 'input-error'}`}
							id="method"
							name="method"
							value={dataNewTemplate.method}>
							<option value=""> Select Underwriting Method........ </option>
							{['automatic','manual','bypass'].map((record, i) => {
								const [first, ...rest] = record
								return (<option
									key={i}
									value={record}>
									{first.toUpperCase() + rest.join('').toLowerCase()}
								</option>
								)
							})}
						</select>

					</div>
				</div>
				<div className="row mb-2">
					<InputContainer
						inputType="mask"
						divClass="form-group"
						labelClassName="font-weight-bold"
						classNameInput={`form-control ${dataNewTemplate.method === 'automatic' && dataNewTemplate.policyId.trim().length === 0 && 'input-error'}`}
						//isLoading={LoadingState}
						inputDisabled={false}
						labelValue={
							<label>
								<span className="text-danger">*</span>Policy
								<OverlayTrigger placement="top" overlay={
								<Tooltip>
									Used to identify the risk workflow used to review this account. Policy IDs must be created before using automatic underwriting, and is required when method is automatic.
								</Tooltip>
								}>
									<BiInfoCircle className='small ml-tiny'/>
								</OverlayTrigger>
							</label>
						}
						maxLength={250}
						name="policyId"
						placeholder="Enter Template Policy"
						value={dataNewTemplate.policyId}
						setValue={(value) => setDataNewTemplate({ ...dataNewTemplate, policyId: value })}
						mask={GlobalStore.maskValidator('text')}
					/>
				</div>
				<div className="row mb-2">
					<div className="mb-2">
						<label className="d-flex flex-row align-items-center">
							<Switch
								onChange={(value) => setDataNewTemplate({ ...dataNewTemplate, resumable: value })}
								checked={
									dataNewTemplate.resumable
								}
								onColor="#10A0E3"
								onHandleColor="#ffffff"
								handleDiameter={20}
								boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
								height={20}
								width={38}
								className="react-switch"
								id={'resumable'}
							/>
							<span className="ml-2 small">
							Allow applicant to save and return to application <OverlayTrigger placement="top" overlay={
								<Tooltip>
									Toggles whether the applicant can save their progress and and later resume the application.
								</Tooltip>
								}>
								 <BiInfoCircle className='ml-tiny'/>
								</OverlayTrigger>
						</span>
						</label>
					</div>
				</div>
				<div className="row mb-2">
					<div className="mb-2">
						<label className="d-flex flex-row align-items-center">
							<Switch
								onChange={(value) => setDataNewTemplate({ ...dataNewTemplate, recipientEmailNotification: value })}
								checked={
									dataNewTemplate.recipientEmailNotification
								}
								onColor="#10A0E3"
								onHandleColor="#ffffff"
								handleDiameter={20}
								boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
								height={20}
								width={38}
								className="react-switch"
								id={'recipientEmailNotification'}
							/>
							<span className="ml-2 small">
							Notify merchant of prefilled application <OverlayTrigger placement="top" overlay={
								<Tooltip>
									Toggles whether to send a boarding link to the merchant’s email address.
								</Tooltip>
								}>
								 <BiInfoCircle className='ml-tiny'/>
								</OverlayTrigger>
						</span>
						</label>
					</div>
				</div>
				<div className="row text-center justify-content-md-center">
					<Button
						className="btn col-md-3"
						variant="success"
						onClick={() => saveTemplate()}>
						Save
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	)
}
