import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import { RenderPopoverTimeBeetween } from '../../components/filters/RenderPopoverTimeBeetween';
import { BiXCircle, BiLogInCircle, BiErrorCircle,  BiCheckDouble, BiEnvelope, BiHourglass, BiRocket } from 'react-icons/bi'

@inject('reports', 'filter')
@observer
class ViewPartialXChangeReport extends React.Component {

    render() {
        return (<>
                <div className='row'>                    
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="fileName" id="fileName" className={"form-control"} placeholder="File Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('file_name(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('file_name(ct)', e)} value={this.props.filter.filterText.fileName ? this.props.filter.filterText.fileName : ''} />
                            <label htmlFor="fileName">File Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="lotNumber" id="lotNumber" className={"form-control"} placeholder="Lot #" onChange={(e) => this.props.filter.handleCaptureTextFilter('identUnique(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('identUnique(ct)', e)} value={this.props.filter.filterText.lotNumber ? this.props.filter.filterText.lotNumber : ''} />
                            <label htmlFor="lotNumber">Lot #</label>
                        </div>
                    </div>
                </div>
                <div>
                    <p><small>Status</small></p>
                    <div className='section-status mb-4'>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))}><BiEnvelope /> Received</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleStatus('status(in)', 2, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]))}><BiHourglass /> In Progress</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 3, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]))}><BiLogInCircle /> Imported</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[4]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleStatus('status(in)', 4, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[4]))}><BiCheckDouble /> Completed</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', 5, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]))}><BiErrorCircle /> Errored</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[6]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('status(in)', 6, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[6]))}><BiXCircle /> Cancelled</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[7]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 7, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[7]))}><BiRocket /> Sent</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Date</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="dateCalendar" 
                                        name="dateCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateDate) + ' → ' : '') + (this.props.filter.stateDate.endDateDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateDate) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="dateCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateDate ? this.props.filter.stateDate.startDateDate : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarStateBeetween(date, 'startDateDate', 'endDateDate', 'dtReceived')}
                            startDate={this.props.filter.stateDate.startDateDate}
                            endDate={this.props.filter.stateDate.endDateDate}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateDate !== null ? false : true}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Time (UTC)</small></p>
                        <RenderPopoverTimeBeetween />
                    </div>
                </div>
        </>);
    }
}

export { ViewPartialXChangeReport };