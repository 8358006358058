import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ToastContainer , toast, Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '../../components/Layout';
import { ReportFilters } from '../../components/ReportFilters';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { OrganizationsLinks } from '../../components/OrganizationsLinks';
import { ReportPaginator } from '../../components/ReportPaginator';
import { PaypointLinks } from '../../components/PaypointLinks';
import {BiFilterAlt} from '@react-icons/all-files/bi/BiFilterAlt';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { MainBar } from '../../components/MainBar';

@inject('reports', 'global', 'entry')
@observer
class ResidualsReport extends React.Component {

    constructor(props) {
        super(props);
        this.clearFilters = this.clearFilters.bind(this);
        this.state = {
            filtersEnabled: false,
            entry: null,
            idPaypoint: null,
            period:null,
            rangeFrom:null,
            rangeTo:null,
            orgId:null,
            currentPaypointName: '',
            flagItemAllColumn: true,
        };
        this.showHideFilters = this.showHideFilters.bind(this);
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.renderStatus = this.renderStatus.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
    }
    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }
    componentDidMount() {
        const search = this.props.location.search;
        const period = new URLSearchParams(search).get("period");
        const rangeFrom = new URLSearchParams(search).get("rangeFrom");
        const rangeTo = new URLSearchParams(search).get("rangeTo");
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let idOrg = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
        this.setState({ 
            entry: this.props.match.params.entry ? this.props.match.params.entry : null,
            idPaypoint: this.props.match.params.idPaypoint ? this.props.match.params.idPaypoint : null,
            period: period,
            rangeFrom: rangeFrom,
            rangeTo: rangeTo,
            orgId:idOrg
        });

        if(this.props.match.params.entry){
            this.props.entry.getEntryFromApi(this.props.match.params.entry).then(res => {
                this.setState({currentPaypointName:res.responseData.Paypoint.LegalName});
            })
            .catch(error => {
                toast.error("Something is Wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }


        this.props.global.protect(this.props.history);
        
        this.props.reports.setFrom(0);
        this.props.global.setRightPanelOpen(false);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                Period            : { label:'Processing Period', class: '', display: true},
                OrgParentName     : { label:'Organization', class: '', display: false ,filter: 'OrgParentName'},
                PaypointLegalName : { label:'Paypoint', class: '', display: true, filter: 'PaypointLegalName'},       
                Volume            : { label:'Volume', class: 'text-right', display: true},   
                Count             : { label:'# Transactions', class: 'text-right', display: true},           
                GrossIncome       : { label:'Income', class: 'text-right', display: true},
                GrossExpense      : { label:'Expense', class: 'text-right', display: true},
                PartnerResidual   : { label:'Residual', class: 'text-right', display: true},
            }
        )

        this.props.reports.setRecords([]);
        if (period && period !== null) {
            this.filter('period', period);
        }else if(rangeFrom && rangeFrom != null && rangeTo && rangeTo != null){
           this.filter({'period(ge)': rangeFrom, 'period(le)': rangeTo});
        }
        else {
            this.clearFilters();
        }
    }

    clearFilters(){
        this.setState({ head: "",filterBrands: {},filterTypes: {},filterStatus: {} , dateStartDate: null,dateEndtDate: null}, function(){
            //this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    exportFile(format){
        this.props.reports.exportOrgFile('residuals',format,this.state.orgId);
    }

    handleKeyDown(type, e){
        if (e.key === 'Enter') {
            this.filter(type,e.target.value);
        }
    }

    renderStatus(status){
        switch(status){
            case 1:
                return <span className="badge rounded-pill bg-success">Active</span>;
            case 99:
                return <span className="badge rounded-pill bg-secondary">Inactive</span>;
            default:
                return <span className="badge rounded-pill bg-secondary">Unknown</span>;

        }
    }

    viewRecordDetails(index, object){
        if(object){
            object.preventDefault();
        }
        this.props.reports.setRecordDetails(index);
        this.props.global.setRightPanelOpen(true);
    }


    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filterOrg(type,value, 'residuals').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    getReportFromApi() {
        let tempFilterValue = {};
        if(this.state.idPaypoint){
            tempFilterValue['paypointId(eq)'] = this.state.idPaypoint;
        }
        this.filter(tempFilterValue);
    }

    showHideFilters(){
        this.setState({ filtersEnabled: !this.state.filtersEnabled }, function(){
            if(this.state.filtersEnabled === false){
                this.clearFilters();
            }
        });
    }

    render() {
        return (
            <Layout {...this.props}>
            <div>

                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                {this.state.entry ?
                    <PaypointLinks idPaypoint={this.state.idPaypoint ? this.state.idPaypoint : ''} entry={this.state.entry ? this.state.entry : ''} gobackLink={"/"+PayabliStorageManager.getEntryName()+"/paypoints/overview/"+ (this.state.entry ? this.state.entry : '')+"/"+(this.state.idPaypoint ? this.state.idPaypoint : '')} goback={true}  gobackText="Paypoints Overview" history={this.props.history} selected="residuals" />
                    :
                    
                    (this.state.period ? 
                        <OrganizationsLinks selected="residuals" gobackLink={"/"+PayabliStorageManager.getEntryName()+'/report/summaryresiduals'} history={this.props.history} goback={true} />
                        :
                        <OrganizationsLinks selected="residuals"/>
                     )
                }
                </div>
                </TopBar>
                

                <div className="mt-body4">
                    <MainBar
                        reportTitle={this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : "Residuals"}
                        reportName="Residuals"
                        dataQAName="Residuals"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        searchBar={false}
                        exportFile={this.exportFile}
                        masterName={this.props.match.params.idPaypoint && `Paypoint - ${this.state.currentPaypointName}`}
                        withTotal={true}
                        totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    /> 

                {this.state.filtersEnabled &&
                <ReportFilters report="organizations" clearFilters = {this.clearFilters}/>
                }

               
                                
                <div className="report-container">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (


                            ( record[1].display &&
                            (this.state.filtersEnabled ?
                            <th key={i} scope="col" className={record[1].class}>
                                
                                { (!record[1].filter) ? record[1].label: ''}

                                { record[1].filter &&
                                    <>
          
                                    {record[1].filter === 'OrgParentName' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Organization</label>
                                            <input placeholder="Organization" onKeyDown={(e) => this.handleKeyDown('orgParentname(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }
                                    
                                    {record[1].filter === 'PaypointLegalName' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Organization</label>
                                            <input placeholder="Organization" onKeyDown={(e) => this.handleKeyDown('paypointLegal(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }
                                    
                                
                                   

                                    </>
                                    
                                }


                                

                            </th>
                              : <th key={i} scope="col" className={record[1].class}>{record[1].label}</th> )
                            )
 
                        ))
                    }
                   
                    </tr>
                </thead>
                <tbody>                
                { this.props.reports.records.map((record, i) => (
                    <tr key={i}>                            
                        { (this.props.reports.headers.Period && this.props.reports.headers.Period.display) &&
                            <td>{record && record.Period ? this.props.global.stringDateFormat(record.Period, true) : '-'}</td>
                        }
                        { (this.props.reports.headers.OrgParentName && this.props.reports.headers.OrgParentName.display) &&
                            <td>{record && record.OrgParentName ? record.OrgParentName: '-'}</td>
                        }
                        { (this.props.reports.headers.PaypointLegalName && this.props.reports.headers.PaypointLegalName.display) &&
                            <td>{record && record.PaypointLegalName ? record.PaypointLegalName: '-'}</td>
                        }
                        { (this.props.reports.headers.Volume && this.props.reports.headers.Volume.display) &&
                            <td className="text-right">{record && record.Volume ? "$" + this.props.global.numberWithCommas(parseFloat(record.Volume).toFixed(2)): '$0.00'}</td>
                        }
                        { (this.props.reports.headers.Count && this.props.reports.headers.Count.display) &&
                            <td className="text-right">{record && record.Count ? record.Count: '0'}</td>
                        }
                        { (this.props.reports.headers.GrossIncome && this.props.reports.headers.GrossIncome.display) &&
                            <td className="text-right">{record && record.GrossIncome ? "$" + this.props.global.numberWithCommas(parseFloat(record.GrossIncome).toFixed(2)) : '$0.00'}</td>
                        }                            
                        { (this.props.reports.headers.GrossExpense && this.props.reports.headers.GrossExpense.display) &&
                            <td className="text-right">{record && record.GrossExpense ? this.props.global.numberWithCommasNegative(parseFloat(record.GrossExpense).toFixed(2)) : '$0.00'}</td>
                        }                           
                        { (this.props.reports.headers.PartnerResidual && this.props.reports.headers.PartnerResidual.display) &&
                            <td className="text-right">{record && record.PartnerResidual ? this.props.global.numberWithCommasNegative(parseFloat(record.PartnerResidual).toFixed(2)) : '$0.00'}</td>
                        }                      
                        { (this.props.reports.headers.amountTx && this.props.reports.headers.amountTx.display) &&
                            <td className="text-right">{record.summary  ? "$" + this.props.global.numberWithCommas(parseFloat(record.summary.amountTx).toFixed(2)) : '-'}</td>
                        }
                    </tr>
                ))
                }                
                </tbody>
                </table>                
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No residuals just yet" description={<span>Income is earned on your paypoints as they process transactions.<br/> As soon as income is earned, it will display here.</span>}/>
                }
                </div>
                

                <ReportPaginator mode={this.state.orgId} option={1} report="residuals"/>
                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { ResidualsReport };