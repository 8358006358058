import axios from 'axios'
import { PayabliStorageManager } from '../../../api/localStorageManager'

let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()

export const fraudcontrolGET = async (id) => {
	const res = await axios.get(process.env.REACT_APP_URL_API + 'Management/fraudcontrol/2/' + id, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
		}
	})
	return res
}
export const fraudcontrolPOST = async (id, dataFraud) => {
	const res = await axios.post(process.env.REACT_APP_URL_API + 'Management/fraudcontrol/2/' + id, dataFraud, {
		headers: {
			requestToken: encryptStorage.getItem('pToken'),
		}
	})
	return res
}
