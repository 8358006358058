import React from 'react';
import { inject, observer } from 'mobx-react';
import {Link} from "react-router-dom";
import { PayabliStorageManager } from '../api/localStorageManager'
import {BiChevronLeft} from '@react-icons/all-files/bi/BiChevronLeft';

@inject('reports','global')
@observer
class OrganizationOverviewLinks extends React.Component {

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        return (
            <div className="row">
            <div className="col-2">
                <div className="hide-sm">
                {this.props.goback && 
                    <a href={this.props.gobackLink} className="btn btn-light text-transform-normal"><BiChevronLeft/> { this.props.gobackText ? this.props.gobackText: "Go back" }</a>
                }
                </div>
            </div>
            <div className="col-8 text-center">
                <div>
                    {userPermissions.indexOf("Organizations") !== -1 &&
                    <Link className={this.props.selected === 'overview'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() + "/orgdetails/"+this.props.idOrg}>Overview</Link>
                    }
                    {userPermissions.indexOf("Organizations") !== -1 &&
                    <Link className={this.props.selected === 'organizations'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() + "/report/organizations/"+this.props.idOrg}>Orgs</Link>
                    }
                    {userPermissions.indexOf("Paypoints") !== -1 &&
                    <Link className={this.props.selected === 'paypoints'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() + "/report/paypoints/"+this.props.idOrg}>PayPoints</Link>
                    }
                    {userPermissions.indexOf("Transaction") !== -1 &&
                    <Link className={this.props.selected === 'transactions'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() + "/report/transactions/org/"+this.props.idOrg}>Transactions</Link>
                    }
                    {userPermissions.indexOf("Subscriptions") !== -1 &&
                    <Link className={this.props.selected === 'autopay'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() + "/report/autopays/org/"+this.props.idOrg}>Autopays</Link>
                    }
                    {userPermissions.indexOf("Residuals") !== -1 &&
                    <Link className={this.props.selected === 'residuals'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +"/report/residuals/org/"+this.props.idOrg}>Residuals</Link>
                    }
                    {userPermissions.indexOf("Manage Users") !== -1 &&
                            <Link className={this.props.selected === 'manageusers' ? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +"/report/usersorg/" + this.props.idOrg}> Manage Users</Link>
                    }
                    {userPermissions.indexOf("Customers") !== -1 &&
                        <Link className={this.props.selected === 'customers'? 'selected' : ''} to={'/'+this.props.global.getURLEntry() +"/report/customers/org/"+this.props.idOrg}>Customers</Link>
                    }
                </div>
            </div>
           
            </div>
        )
    }
}

export { OrganizationOverviewLinks };