import React, { useState, useContext, useRef } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle'
import { RiCloseLine } from 'react-icons/ri'
import RowContainer from '../../../components/commandCenter/RowContainer'
import GlobalStore from '../../../store/GlobalStore'
import commandContext from '../context/commandCenter_context'
import { Modal } from 'react-bootstrap'

export default function AddBankAccount({ showModal, closeModal, numberBank }) {
	const { addBank, toastMessage } = useContext(commandContext)
	const [inputValues, setInputValues] = useState({ bankName: '', typeAccount: 'Checking', routingAccount: '', accountNumber: '', bankAccountHolderName: '', bankAccountHolderType: 'Business', bankAccountFunction: 0, country: 'US' })
	const [disabledButton, setDisabledButton] = useState(false)
	const inputCheckDeposit = useRef()
	const inputCheckWithdrawals = useRef()

	const createBank = async (e) => {
		e.preventDefault()
		let valid = false
		if (inputValues.bankName === null || inputValues.bankName.toString().trim().length === 0) valid = true
		if (inputValues.bankAccountHolderName === null || inputValues.bankAccountHolderName.toString().trim().length === 0) valid = true
		if (inputValues.bankAccountHolderType !== 'Business' && inputValues.bankAccountHolderType !== 'Personal') valid = true
		if (inputValues.country === null || inputValues.country.toString().trim().length === 0) valid = true
		if (inputValues.routingAccount === null || inputValues.routingAccount.toString().trim().length === 0) valid = true
		if (inputValues.accountNumber === null || inputValues.accountNumber.toString().trim().length === 0) valid = true
		if (inputValues.typeAccount !== 'Checking' && inputValues.typeAccount !== 'Savings') valid = true
		if (inputValues.bankAccountFunction !== 0 && inputValues.bankAccountFunction !== 1 && inputValues.bankAccountFunction !== 2) valid = true
		if (!inputCheckDeposit.current.checked && !inputCheckWithdrawals.current.checked) valid = true
		if (valid) {
			toastMessage('You must fill in the required fields', false)
			return
		}
		setDisabledButton(true)
		const res = await addBank(inputValues)
		if (res) closeModal()
		setDisabledButton(false)
	}

	return (
		<Modal
			show={showModal}
			onHide={() => !disabledButton && closeModal()}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Header>
				<div className={'d-flex justify-content-between w-100 px-4 ' + styles['user-select']}>
					<Modal.Title
						id="contained-modal-title-vcenter"
						className={'w-100 fs-5 ' + styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Add New Bank Account
					</Modal.Title>
					<RiCloseLine
						onClick={() => closeModal()}
						className={styles['icon24']}
						style={{ cursor: 'pointer' }}
					/>
				</div>
			</Modal.Header>
			<Modal.Body>
				<form
					onSubmit={createBank}
					className={'d-flex flex-column ' + styles['user-select']}>
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' mb-3 ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						{'Bank Account #' + numberBank + ' Details'}
					</label>

					<RowContainer
						inputType="mask"
						inputDisabled={false}
						classNameInput={` w-100  ${(inputValues.bankName === null || inputValues.bankName.trim().length === 0) && 'is-invalid'}`}
						divInputClass={styles['width-70']}
						labelValue="Bank Name"
						name="bankName"
						placeholder="Value"
						required={false}
						value={inputValues.bankName}
						setValue={(values) => setInputValues({ ...inputValues, bankName: values })}
						mask={GlobalStore.maskValidator('text')}
						maxLength={50}
					/>

					<RowContainer
						inputType="mask"
						inputDisabled={false}
						classNameInput={` w-100  ${(inputValues.bankAccountHolderName === null || inputValues.bankAccountHolderName.trim().length === 0) && 'is-invalid'}`}
						divInputClass={styles['width-70']}
						labelValue="Bank Account Holder Name"
						name="bankAccountHolderName"
						placeholder="Value"
						required={false}
						value={inputValues.bankAccountHolderName}
						setValue={(values) => setInputValues({ ...inputValues, bankAccountHolderName: values })}
						mask={GlobalStore.maskValidator('text')}
						maxLength={50}
					/>

					<div className="d-flex flex-row justify-content-start flex-wrap align-items-center w-100 gap-2 p-0 pe-2 pb-3">
						<label
							className={styles['label-form'] + ' ' + styles['ft-s-13']}
							style={{ fontWeight: '600', lineHeight: '40px', minWidth: '180px', letterSpacing: '0.0025em' }}>
							Bank Account Holder Type
						</label>

						<div
							className="d-flex flex-row justify-content-start flex-nowrap align-items-center p-0 gap-5"
							style={{ marginRight: '20px' }}>
							<RowContainer
								inputType="Radio"
								inputDisabled={false}
								labelValue="Business"
								name="bankAccountHolderType-Business"
								value={inputValues.bankAccountHolderType === 'Business' ? true : false}
								setValue={() => setInputValues({ ...inputValues, bankAccountHolderType: 'Business' })}
							/>
							<RowContainer
								inputType="Radio"
								inputDisabled={false}
								labelValue="Personal"
								name="bankAccountHolderType-Personal"
								value={inputValues.bankAccountHolderType === 'Personal' ? true : false}
								setValue={() => setInputValues({ ...inputValues, bankAccountHolderType: 'Personal' })}
							/>
						</div>

						<RowContainer
							inputDisabled={false}
							classNameInput={`${(inputValues.country === null || inputValues.country.trim().length === 0) && 'is-invalid'}`}
							labelValue="Country"
							name="country"
							value={inputValues.country}
							autoWidth={true}
							divClass={`d-flex flex-wrap flex-row align-items-center`}
							divStyle={{ padding: '0px', gap: '8px' }}
							setValue={(values) => setInputValues({ ...inputValues, country: values === null || values === undefined || values.length === 0 ? 'US' : values })}
							inputStyle={{ width: '190px' }}
							optionList={
								<>
									<option value="">select...</option>
									{GlobalStore.getAllCountries().map((data) => (
										<option
											key={'optState' + data.value}
											value={data.value}>
											{data.text}
										</option>
									))}
								</>
							}
						/>
					</div>

					<RowContainer
						inputType="mask"
						inputDisabled={false}
						classNameInput={` w-100  ${(inputValues.routingAccount === null || inputValues.routingAccount.trim().length === 0) && 'is-invalid'}`}
						divInputClass={styles['width-70']}
						labelValue="Routing Number"
						name="routingAccount"
						placeholder="Value"
						required={false}
						value={inputValues.routingAccount}
						setValue={(values) => setInputValues({ ...inputValues, routingAccount: values })}
						mask={GlobalStore.maskValidator('routing')}
					/>

					<RowContainer
						inputType="mask"
						inputDisabled={false}
						classNameInput={` w-100  ${(inputValues.accountNumber === null || inputValues.accountNumber.trim().length === 0) && 'is-invalid'}`}
						divInputClass={styles['width-70']}
						labelValue="Account Number"
						name="accountNumber"
						placeholder="Value"
						required={false}
						value={inputValues.accountNumber}
						setValue={(values) => setInputValues({ ...inputValues, accountNumber: values })}
						mask={GlobalStore.maskValidator('accountnumber')}
						maxLength={17}
					/>

					<div className="d-flex flex-row justify-content-start flex-wrap align-items-center w-100 gap-2 p-0 pe-2 pb-3">
						<label
							className={styles['label-form'] + ' ' + styles['ft-s-13']}
							style={{ fontWeight: '600', lineHeight: '40px', minWidth: '180px', letterSpacing: '0.0025em' }}>
							Account Type
						</label>
						<div className="d-flex flex-row justify-content-start flex-nowrap align-items-center p-0 gap-5">
							<RowContainer
								inputType="Radio"
								inputDisabled={false}
								labelValue="Checking"
								name="typeAccount-Checking"
								value={inputValues.typeAccount === 'Checking' ? true : false}
								setValue={() => setInputValues({ ...inputValues, typeAccount: 'Checking' })}
							/>
							<RowContainer
								inputType="Radio"
								inputDisabled={false}
								labelValue="Savings"
								name="typeAccount-Savings"
								value={inputValues.typeAccount === 'Savings' ? true : false}
								setValue={() => setInputValues({ ...inputValues, typeAccount: 'Savings' })}
							/>
						</div>
					</div>

					<div className="d-flex flex-row justify-content-start flex-wrap align-items-center w-100 gap-2 p-0 pe-2 pb-3">
						<label
							className={styles['label-form'] + ' ' + styles['ft-s-13']}
							style={{ fontWeight: '600', lineHeight: '40px', minWidth: '180px', letterSpacing: '0.0025em' }}>
							Bank Account Function &nbsp;
							<OverlayTrigger
								key={'right'}
								placement={'right'}
								overlay={
									<Tooltip>
										If you wish to use this bank account for deposits and withdrawals/ fees, please select both option. <br />
										<br />
										If you wish to only select one intended purpose, you will be required to add an additional bank account.
									</Tooltip>
								}>
								<BiInfoCircle className={styles['icon15'] + ' ' + styles['color-blue']} />
							</OverlayTrigger>
						</label>

						<div
							className={`d-flex flex-wrap flex-row align-items-center ${styles['columns-sm']} ${inputValues.bankAccountFunction !== 0 && inputValues.bankAccountFunction !== 1 && inputValues.bankAccountFunction !== 2 && 'px-2'}`}
							style={{ padding: '0', gap: '25px', border: inputValues.bankAccountFunction !== 0 && inputValues.bankAccountFunction !== 1 && inputValues.bankAccountFunction !== 2 && '1px solid #dc3545', borderRadius: '.25rem' }}>
							<RowContainer
								inputType="Checkbox"
								refs={inputCheckDeposit}
								value={inputValues.bankAccountFunction === 0 || inputValues.bankAccountFunction === 2 ? true : false}
								inputDisabled={false}
								name={'bankAccountFunction-deposits'}
								labelValue="Deposits"
								setValue={(checked) => (inputValues.bankAccountFunction === 0 && checked === false ? setInputValues({ ...inputValues, bankAccountFunction: 3 }) : setInputValues({ ...inputValues, bankAccountFunction: inputValues.bankAccountFunction === 1 && checked === true ? 2 : inputValues.bankAccountFunction === 1 && checked === false ? 1 : inputValues.bankAccountFunction === 2 && checked === false ? 1 : inputValues.bankAccountFunction === 0 && checked === false ? 0 : 0 }))}
							/>
							<RowContainer
								inputType="Checkbox"
								refs={inputCheckWithdrawals}
								value={inputValues.bankAccountFunction === 1 || inputValues.bankAccountFunction === 2 ? true : false}
								inputDisabled={false}
								name={'bankAccountFunction-withdrawalsFees'}
								labelValue="Withdrawals/ Fees"
								setValue={(checked) => (inputValues.bankAccountFunction === 1 && checked === false ? setInputValues({ ...inputValues, bankAccountFunction: 3 }) : setInputValues({ ...inputValues, bankAccountFunction: inputValues.bankAccountFunction === 0 && checked === true ? 2 : inputValues.bankAccountFunction === 0 && checked === false ? 0 : inputValues.bankAccountFunction === 2 && checked === false ? 0 : inputValues.bankAccountFunction === 1 && checked === false ? 1 : 1 }))}
							/>
						</div>
					</div>

					<div
						className="d-flex justify-content-end align-items-center w-100"
						style={{ marginTop: '20px', marginBottom: '20px' }}>
						<div className="d-flex gap-2 align-items-center">
							<button
								disabled={disabledButton}
								onClick={() => closeModal()}
								className={styles['btn'] + ' ' + styles['color-gray'] + ' ' + styles['border-gray']}>
								CANCEL
							</button>
							<button
								disabled={disabledButton}
								className={styles['btn'] + ' ' + styles['btn-blue']}>
								ADD BANK ACCOUNT
							</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	)
}
