import React from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReportPaginator } from '../../components/ReportPaginator';
import { BoardingLinks } from '../../components/BoardingLinks';
import { Layout } from '../../components/Layout'
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Modal, Button } from "react-bootstrap";
import { RightPanel } from '../../components/RightPanel';
import axios from 'axios';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import {BiX} from '@react-icons/all-files/bi/BiX';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { BiCopy, BiDotsVerticalRounded, BiErrorAlt } from 'react-icons/bi';
import { MainBar } from '../../components/MainBar';

@inject('reports','boarding','store','global')
@observer
class Templates extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: "",
            orgId:0,
            cancelTplModalIsOpen: false,
            newLinkModalIsOpen:false,
            rootTemplate: false,
            tplIdToModify: null,
            linkfields: '',
            currentId:0,
            flagItemAllColumn: true,
            RootTemplateNotFound: false,
            replicateModalIsOpen: false,
            templateToReplicate: null
        };
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.openCancelTplModal = this.openCancelTplModal.bind(this);
        this.closeCancelTplModal = this.closeCancelTplModal.bind(this);
        this.cancelTplAction = this.cancelTplAction.bind(this);
        this.createLinkTpl = this.createLinkTpl.bind(this);
        this.closenewLinkModal = this.closenewLinkModal.bind(this);
        this.getRootTemplate = this.getRootTemplate.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.replicateTemplate = this.replicateTemplate.bind(this);
        this.replicateTemplateAction = this.replicateTemplateAction.bind(this);
        this.openReplicateModal = this.openReplicateModal.bind(this);
        this.closeReplicateModal = this.closeReplicateModal.bind(this);
    }

    componentDidMount() {
        this.props.global.protect(this.props.history);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let orgId = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId;
        this.setState({
            head: "", cancelTplModalIsOpen: false, orgId: orgId });
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                OrgParentName       : { label: 'Organization', class: '', display: true },
                TemplateTitle       : { label: 'Title', class: '', display: true },
                TemplateId          : { label: 'TemplateID', class: '', display: true },
                TemplateDescription : { label: 'Description', class: '', display: true },
                TemplateCode        : { label: 'Code', class: '', display: true },
                UsedBy              : { label: 'Used by Links', class: '', display: true },
                isRoot              : { label: 'Is Root?', class: '', display: false },
                CreatedAt           : { label: 'Created At', class: '', display: true },
            }
        )
        this.props.reports.setRecords([]);
        this.getRootTemplate();
        this.clearFilters();
    }

    replicateTemplateAction(){
        let templatePosition = this.state.templateToReplicate;
        let template = this.props.reports.records[templatePosition];

        if(template){
            let ntemplate = {};
            ntemplate.orgId = template.OrgParentId;
            ntemplate.templateCode = toJS(template.TemplateCode);
            ntemplate.templateContent = toJS(template.TemplateContent);
            ntemplate.templateDescription = toJS(template.TemplateDescription);
            ntemplate.templateName = toJS(template.TemplateTitle);
            this.props.global.setLoading(true);

            this.props.boarding.newTemplate(ntemplate.orgId, ntemplate).then(res => {
                this.closeReplicateModal();
                this.getReportFromApi();
                this.props.global.setLoading(false);
                toast.success("Data saved successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });

        }
        else{
            let errorMessage = "Something is Wrong, cannot identify the payment page.";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }        
    }

    replicateTemplate(position){
        this.setState({ templateToReplicate: position });
        this.openReplicateModal();
    }

    openReplicateModal(){
        this.setState({ replicateModalIsOpen: true });
    }
    
    closeReplicateModal(){
        this.setState({ replicateModalIsOpen: false });
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    viewRecordDetails(idTpl, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            this.editTpl(idTpl);
        }
    }

    handleShowColumn(event) {
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }

    clearFilters() {
        this.setState({ head: "" }, () => {
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    showHideFilters() {
        this.props.global.setFilterRightPanelOpen(true);
    }

    filter(type, value) {
        this.props.global.setLoading(true);
        this.props.reports.filterOrg(type, value, 'templates').then(res => {
            this.props.global.setLoading(false);
        })
            .catch(error => {
                this.props.global.setLoading(false);
            });
    }

    clearFilter(e, filter, filterType) {
        e.preventDefault();
        this.setState({ [filterType]: {} });
        this.filter(filter, "");
    }

    getReportFromApi() {
        this.props.global.setLoading(true);
        this.props.reports.getReportOrgFromApi('templates').then(res => {
            this.props.global.setLoading(false);
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
    }

    getRootTemplate() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let orgId = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId;
        return axios.get(process.env.REACT_APP_URL_API + 'Templates/master/' + orgId, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            }
        })
            .then(res => {
                this.setState({ rootTemplate: true });
            })
            .catch(error => {
                this.setState({ rootTemplate: false, RootTemplateNotFound: true });
            });
    }

    editTpl(idTpl) {
        this.setState({ tplIdToModify: idTpl });
        this.props.history.push("/" + this.props.global.getURLEntry() + '/boarding/edittemplate/'+idTpl);
    }

    cancelTpl(idTpl) {
        this.setState({ tplIdToModify: idTpl });
        this.openCancelTplModal();
    }

    openCancelTplModal() {
        this.setState({ cancelTplModalIsOpen: true });
    }

    closeCancelTplModal() {
        this.setState({ cancelTplModalIsOpen: false });
    }

    closenewLinkModal() {
        this.setState({ newLinkModalIsOpen: false });
    }

    cancelTplAction() {
        let tplIdToDelete = this.state.tplIdToModify;
        if (tplIdToDelete) {
            this.props.global.setLoading(true);
            this.props.reports.deleteTemplate(tplIdToDelete)
                .then(result => {
                    this.setState({ cancelTplModalIsOpen: false });
                    this.props.global.setLoading(false);
                    this.getReportFromApi();
                    toast.success("Template deleted successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
        }
    }

    createLinkTpl(i,o) {
        this.props.global.setLoading(true);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API + 'Templates/getlink/'+i+'/'+o, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            }
        })
            .then(res => {
                this.props.global.setLoading(false);
                if (res.data.responseText === "Success") {
                    toast.success("Boarding link created successfully! - " + res.data.responseData, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                    this.closenewLinkModal();
                    this.clearFilters();
                }
                else {
                    this.setState({ currentId: i, linkfields: res.data.responseData, newLinkModalIsOpen: true });
                }
            })
            .catch(error => {
                this.props.global.setLoading(false);
                throw error;
            });
    }

    showLinks(i, e) {
        if(e){
            e.preventDefault();
        }
        
        this.props.reports.setRecordDetails(i);
        this.props.global.setRightPanelOpen(true);
    }

    render() {
        const currentRecord = this.props.reports.getRecordDetails;
        return (
            <Layout {...this.props}>
                <Modal style={{ textAlign: "center" }} size="sm" show={this.state.cancelTplModalIsOpen} onHide={this.closeCancelTplModal} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <BiX className="icon-modal" onClick={(e) => this.closeCancelTplModal()} />
                        <h5>Delete</h5>
                        <p className="small">Are you sure you want to delete this template?</p>
                        <Button className="btn" variant="danger" onClick={(e) => this.cancelTplAction()}>
                            Delete Template
                    </Button>
                    </Modal.Body>
                </Modal>
                <Modal style={{ textAlign: "center" }} show={this.state.newLinkModalIsOpen} onHide={this.closenewLinkModal} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <h5>Empty fields found!</h5>
                        <p className="small">These fields were marked ReadOnly without value:<br /><b>{ this.state.linkfields }</b></p>
                        <p className="small">Do you want to ignore them?</p>
                        <Button className="btn mr-2" variant="success" onClick={(e) => this.createLinkTpl(this.state.currentId,true)}>
                            Yes
                        </Button>
                        <Button className="btn" variant="outline-dark" onClick={(e) => this.closenewLinkModal()}>
                            No
                        </Button>
                    </Modal.Body>
                </Modal>
                <Modal style={{textAlign: "center"}} show={this.state.replicateModalIsOpen} onHide={this.closeReplicateModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiCopy className="icon-modal"/>
                    <h5>Confirm</h5>
                    <p className="small">Please confirm you would like to create a new Boarding Template by duplicating the attributes of this one?</p>
                    <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeReplicateModal()}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button className="btn" variant="primary" onClick={this.replicateTemplateAction}>
                        Confirm
                    </Button>
                </Modal.Body>
                </Modal>
                <RightPanel >
                    <h6 className="header mb-3 text-center" data-qaid="viewLinksTemplate">Links using the Template<br /> <span className="text-info">{currentRecord && currentRecord.TemplateTitle ? currentRecord.TemplateTitle : ''}</span></h6>
                        {(currentRecord && currentRecord.UsedBy > 0) &&
                            <table className="table">
                            <tbody>
                            {currentRecord.boardingLinks.map((bl, i) => (
                                <tr key={i}><td className="text-center">Click to Create an Application with: <a rel="noreferrer" target="_blank" href={`${process.env.REACT_APP_URL_BOARDING}/boarding/app/${bl.ReferenceName}`}>{bl.ReferenceName}</a></td></tr>
                            ))}
                                </tbody>
                            </table>
                        }
                </RightPanel>
                <div>
                    <ParentFilterPanel report={'templates'} />
                    <TopBar>
                        <MainTopBarMenu />
                        <div className="top-bar-sub">
                            <BoardingLinks selected="templates" />
                        </div>
                    </TopBar>
                    {(this.state.RootTemplateNotFound &&
                        <div className="mt-body4">
                            <div className="d-flex flex-column sjustify-content-center align-items-center">
                                <div className="card" style={{width: '50em'}}>
                                    <div className="card-body text-center" style={{padding: "40px"}}>
                                        <BiErrorAlt className="mb-4 red-text" style={{fontSize: "60px"}}/>
                                        <h5>Warning!</h5>
                                        <p className="small">
                                            You have no root template defined for this organization. Please contact us. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>)
                            ||
                    <div className="mt-body4">
                        <MainBar 
                            btnNewName="New Template" 
                            newLink={"/"+PayabliStorageManager.getEntryName()+"/boarding/newtemplate"}
                            reportName="Templates"
                            dataQAName="Templates"
                            showHideFilters={this.showHideFilters}
                            selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                            handleShowColumn={(e) => this.handleShowColumn(e)}
                            rootTemplate={this.state.rootTemplate}
                            totalRecords={this.props.reports.totalRecords}
                            getHeaders={this.props.reports.getHeaders}
                            headers={this.props.reports.headers}
                            refreshView={this.clearFilters}
                            flagItemAllColumn={this.state.flagItemAllColumn}
                        />                  
                        <div className="report-container">                            
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        {
                                            this.props.reports.getHeaders.map((record, i) => (
                                                (record[1].display && <th key={i} scope="col" className={record[1].class}>{record[1].label}</th>)
                                            ))
                                        }
                                        <th className='text-center sticky-row' scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.reports.records.map((record, i) => (
                                        <tr key={i} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} className="cursorPointer" onClick={(e) => this.viewRecordDetails(record.IdTemplate,e)} data-qaid={"templateRow-"+(i)}>
                                            { (this.props.reports.headers.OrgParentName && this.props.reports.headers.OrgParentName.display) &&
                                                <td>{this.props.global.capitalizeFirstLetterOfEachWord(record.OrgParentName)}</td>
                                            }
                                            { (this.props.reports.headers.TemplateTitle && this.props.reports.headers.TemplateTitle.display) &&
                                                <td>{record.TemplateTitle}</td>
                                            }
                                            { (this.props.reports.headers.TemplateId && this.props.reports.headers.TemplateId.display) &&
                                                <td>{record.IdTemplate}</td>
                                            }
                                            { (this.props.reports.headers.TemplateDescription && this.props.reports.headers.TemplateDescription.display) &&
                                                <td>{record.TemplateDescription}</td>
                                            }
                                            { (this.props.reports.headers.TemplateCode && this.props.reports.headers.TemplateCode.display) &&
                                                <td>{record.TemplateCode ? record.TemplateCode : '-'}</td>
                                            }
                                            { (this.props.reports.headers.UsedBy && this.props.reports.headers.UsedBy.display) &&
                                                <td>
                                                {record.UsedBy > 0 ?
                                                    <a href="/" onClick={(e) => this.showLinks(i, e)}>&nbsp;{record.UsedBy}&nbsp;</a>
                                                    :
                                                    <>{record.UsedBy}</>
                                                    }
                                                </td>
                                            }
                                            { (this.props.reports.headers.isRoot && this.props.reports.headers.isRoot.display) &&
                                                <td>{record.isRoot?"Y":"N"}</td>
                                            }
                                            { (this.props.reports.headers.CreatedAt && this.props.reports.headers.CreatedAt.display) &&
                                                <td>{this.props.global.stringDateFormatV3(record.CreatedAt)}</td>
                                            }
                                            <td className='text-center sticky-row'>
                                                <DropdownButton
                                                    menuAlign="right"
                                                    title={<BiDotsVerticalRounded/>}
                                                    data-qaid={"templatesActions-"+(record && record.TemplateTitle)}
                                                    id="actionsMenuButton"
                                                    size="sm"
                                                    variant="default"
                                                >
                                                    <Dropdown.Item onClick={(e) => this.editTpl(record.IdTemplate)} data-qaid="templateEditLink">Edit</Dropdown.Item>
                                                    <Dropdown.Item href={"/" + this.props.global.getURLEntry() + "/boarding/newapptpl/" + record.IdTemplate+"/"+record.OrgParentId} data-qaid="templateNewApplicationLink">New Application</Dropdown.Item>
                                                    {
                                                        (record.UsedBy > 0) &&
                                                            <Dropdown.Item onClick={(e) => this.showLinks(i)} data-qaid="templateViewLinksLink">View Links</Dropdown.Item>
                                                    }
                                                    {(record && record.boardingLinks && record.boardingLinks.length <= 0) && 
                                                        <Dropdown.Item onClick={(e) => this.createLinkTpl(record.IdTemplate,false)}>Create Boarding Link</Dropdown.Item>
                                                    }
                                                        <Dropdown.Item onClick={(e) => this.replicateTemplate(i)}>Duplicate</Dropdown.Item>
                                                    {
                                                        (record.UsedBy === 0 && !record.isRoot) &&
                                                        <><Dropdown.Divider />
                                                            <Dropdown.Item style={{ color: "rgb(192, 0, 0)" }} onClick={(e) => this.cancelTpl(record.IdTemplate)}>Delete</Dropdown.Item></>
                                                    }
                                                    
                                                </DropdownButton>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>                             
                            {this.props.reports.records.length < 1 &&
                                <RecordsNotFound btnLink={"/"+PayabliStorageManager.getEntryName()+"/boarding/newtemplate"} btnText={"+ New Template"} message="No application templates found" description={<span>As you create application templates to streamline merchant applications, <br/>they will appear here.</span>}/>
                            }
                        </div>
                        <ReportPaginator report="templates" mode={this.state.orgId} option={1} />
                    </div>}
                    <ToastContainer transition={Bounce} />
                </div>
            </Layout>
        )
    }
}

export { Templates };
