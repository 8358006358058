import React, { useState, useContext, useEffect } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import { Alert } from 'react-bootstrap'
import { BiUserCheck, BiDotsVerticalRounded } from 'react-icons/bi'
import { RiContactsBookFill, RiAddFill } from 'react-icons/ri'
import { BsTrash } from 'react-icons/bs'
import FrameGroup from '../../../components/commandCenter/FrameGroup'
import ButtonSaveCancel from '../../../components/commandCenter/ButtonSaveCancel'
import RowContainer from '../../../components/commandCenter/RowContainer'
import GlobalStore from '../../../store/GlobalStore'
import { validName } from '../../../components/inputStyles/validateFunction'
import { Element } from 'react-scroll'
import commandContext from '../context/commandCenter_context'

export default function OwnerShipContacts() {
	const { PayPoint, loadData, updatePayPoint, Owner, handleChangeOwner, addOwner, removeOwner, toastMessage, newPypoint } = useContext(commandContext)
	const [inputDisabled, setInputDisabled] = useState(true)
	const [msgOwnerPercent, setMsgOwnerPercent] = useState(false)

	useEffect(() => {
		newPypoint && setInputDisabled(false)
	}, [newPypoint])
	const load = async () => {
		await loadData()
	}
	const validOwnerPercents = (datIndex, value) => {
		let sumPercent = 0
		Owner.ownership.forEach((ownership, index) => {
			if (index === datIndex) {
				sumPercent += parseInt(value)
			} else {
				sumPercent += parseInt(ownership.ownerpercent)
			}
		})
		sumPercent > 100 ? setMsgOwnerPercent(true) : setMsgOwnerPercent(false)
	}
	const updateOwnerShip = async () => {
		let valid = false
		let sumPercent = 0

		Owner.ownership.forEach((ownership) => {
			if (ownership.ownername === null || ownership.ownername.toString().trim().length === 0 || !validName.test(ownership.ownername.toString())) valid = true
			if (ownership.ownerphone1 === null || ownership.ownerphone1.toString().trim().length === 0) valid = true
			if (ownership.owneremail === null || ownership.owneremail.toString().trim().length === 0 || !GlobalStore.emailValidation(ownership.owneremail)) valid = true
			if (ownership.oaddress === null || ownership.oaddress.toString().trim().length === 0) valid = true
			if (ownership.ocity === null || ownership.ocity.toString().trim().length === 0) valid = true
			if (['', null].includes(ownership.ownerpercent)) valid = true
			sumPercent += parseInt(ownership.ownerpercent)
		})
		Owner.contacts.forEach((contacts) => {
			if (contacts.contactName === null || contacts.contactName.toString().trim().length === 0) valid = true
			if (contacts.contactEmail === null || contacts.contactEmail.toString().trim().length === 0 || !GlobalStore.emailValidation(contacts.contactEmail)) valid = true
			if (contacts.contactPhone === null || contacts.contactPhone.toString().trim().length === 0) valid = true
		})
		if (sumPercent > 100) {
			setMsgOwnerPercent(true)
			valid = true
		} else {
			setMsgOwnerPercent(false)
		}
		if (valid) {
			toastMessage('You must fill in the required fields', false)
			return
		}
		const res = updatePayPoint('Ownership')
		if (res) setInputDisabled(true)
	}

	return (
		<Element
			name="OwnerShipContacts"
			className="d-flex justify-content-center w-100">
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={<BiUserCheck className={styles['icon26'] + ' ' + styles['color-blue']} />}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label="Ownership"
					editClick={() => setInputDisabled(false)}
					useBtn={PayPoint.idPaypoint <= 0 ? false : inputDisabled}
					btnDelete={false}
				/>

				{!inputDisabled && Owner.ownership.length === 0 && (
					<div className="d-flex flex-row flex-wrap justify-content-end align-items-center p-0 gap-3 mb-2 w-100">
						<button
							onClick={() => addOwner('ownership')}
							className={styles['btn'] + ' ' + styles['border-blue']}>
							<RiAddFill className={styles['icon20']} />
							ADD OWNER
						</button>
					</div>
				)}
				{Owner.ownership.map((ownership, index) => (
					<div
						key={'ownership' + index}
						style={{ width: '100%' }}>
						<div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-0 gap-3 mb-3 w-100">
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
								style={{ fontWeight: '600', lineHeight: '24px' }}>
								Owner # {(index + 1).toString()}
							</label>
							{!inputDisabled && (
								<div
									className="d-flex flex-row flex-wrap justify-content-end align-items-start pt-0 pb-0 ps-0 gap-2"
									style={{ paddingRight: '10px' }}>
									<button
										onClick={() => removeOwner(index, 'ownership')}
										className={styles['btn'] + ' ' + styles['btn-red-letter'] + ' ' + styles['no-border']}>
										<BsTrash className={styles['icon20']} />
										DELETE OWNER
									</button>
									{Owner.ownership.length === index + 1 && (
										<button
											onClick={() => addOwner('ownership')}
											className={styles['btn'] + ' ' + styles['border-blue']}>
											<RiAddFill className={styles['icon20']} />
											ADD OWNER
										</button>
									)}
								</div>
							)}
						</div>

						<div className="d-flex flex-column w-100">
							<div className={styles['grid-container']}>
								<div className="d-flex flex-column align-items-start p-0">
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										classNameInput={!inputDisabled && (ownership.ownername === null || ownership.ownername.trim().length === 0 || !validName.test(ownership.ownername)) ? 'is-invalid' : ''}
										TooltipValid={!inputDisabled && !validName.test(ownership.ownername) ? 'The field is for first and last name mandatorily' : ''}
										labelValue="Owner Name"
										name={'ownername[' + index + ']'}
										placeholder="Value"
										value={ownership.ownername}
										//isLoading={isLoading}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ownername', values)}
										mask={GlobalStore.maskValidator('text')}
										maxLength={100}
									/>
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Owner Title"
										name={'ownerTitle[' + index + ']'}
										placeholder="Value"
										value={ownership.ownertitle}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ownertitle', values)}
										mask={GlobalStore.maskValidator('text')}
										maxLength={100}
									/>
									<RowContainer
										inputType="number"
										prefix=""
										suffix="%"
										maxValue={100}
										decimalScale={0}
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (['', null].includes(ownership.ownerpercent)) ? 'input-error' : msgOwnerPercent ? 'input-error' : ''}
										TooltipValid={!inputDisabled && (['', null].includes(ownership.ownerpercent)) ? 'The minimum value is 0%' : ''}
										labelValue="Ownership %"
										name={'ownership[' + index + ']'}
										placeholder="Value"
										value={ownership.ownerpercent}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ownerpercent', values) | validOwnerPercents(index, values)}
										mask=""
									/>
									<RowContainer
										inputType={inputDisabled ? 'password' : 'mask'}
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Owner SSN"
										name={'ownerSsn[' + index + ']'}
										placeholder="Value"
										value={ownership.ownerssn}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ownerssn', values)}
										mask={GlobalStore.maskValidator('ssn')}
									/>
									<RowContainer
										inputType={inputDisabled ? 'text' : 'dateBox'}
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Date Of Birth"
										name={'dateOfBirth[' + index + ']'}
										placeholder="Select Date"
										allowedNullDate={true}
										maxDate={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())}
										value={inputDisabled ? GlobalStore.stringDateFormat(ownership.ownerdob) : ownership.ownerdob === null ? null : new Date(ownership.ownerdob)}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ownerdob', values)}
									/>
									<RowContainer
										inputType="mask"
										classNameInput={!inputDisabled && (ownership.ownerphone1 === null || ownership.ownerphone1.trim().length === 0) ? 'is-invalid' : ''}
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Phone Number"
										name={'ownerPhoneNumber[' + index + ']'}
										placeholder="Value"
										value={ownership.ownerphone1}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ownerphone1', values)}
										mask={GlobalStore.maskValidator('phone')}
									/>
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Additional Phone Number"
										name={'additionalPhoneNumber[' + index + ']'}
										placeholder="Value"
										value={ownership.ownerphone2}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ownerphone2', values)}
										mask={GlobalStore.maskValidator('phone')}
									/>
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (ownership.owneremail === null || ownership.owneremail.trim().length === 0 || !GlobalStore.emailValidation(ownership.owneremail)) ? 'is-invalid' : ''}
										TooltipValid={!inputDisabled && (ownership.owneremail === null || ownership.owneremail.trim().length > 0) && !GlobalStore.emailValidation(ownership.owneremail) ? 'The email is not valid' : ''}
										labelValue="Email Address"
										name={'owneremail[' + index + ']'}
										placeholder="Value"
										value={ownership.owneremail}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'owneremail', values)}
										mask={GlobalStore.maskValidator('email')}
										maxLength={100}
									/>
								</div>

								<div className="d-flex flex-column align-items-start p-0">
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Driver's License Number"
										name={'driverLicenseNumber[' + index + ']'}
										placeholder="Value"
										value={ownership.ownerdriver}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ownerdriver', values)}
										mask={GlobalStore.maskValidator('text')}
										maxLength={30}
									/>
									<RowContainer
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Driver's State License"
										name={'odriverstate[' + index + ']'}
										value={ownership.odriverstate}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'odriverstate', values)}
										optionList={
											<>
												<option value="select">select...</option>
												{GlobalStore.getUSAStates().map((data) => (
													<option
														key={'optState' + data.value}
														value={data.value}>
														{data.text}
													</option>
												))}
											</>
										}
									/>
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (ownership.oaddress === null || ownership.oaddress.trim().length === 0) ? 'is-invalid' : ''}
										labelValue="Owner Address"
										name={'ownerAddress[' + index + ']'}
										placeholder="Value"
										value={ownership.oaddress}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'oaddress', values)}
										mask={GlobalStore.maskValidator('text')}
										maxLength={200}
									/>
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (ownership.ocity === null || ownership.ocity.trim().length === 0) ? 'is-invalid' : ''}
										labelValue="City"
										name={'ownerCity[' + index + ']'}
										placeholder="Value"
										value={ownership.ocity}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ocity', values)}
										mask={GlobalStore.maskValidator('text')}
										maxLength={50}
									/>
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Zip"
										name={'ownerZip[' + index + ']'}
										placeholder="Value"
										value={ownership.ozip}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ozip', values)}
										mask={GlobalStore.maskValidator('zipcode')}
									/>
									<RowContainer
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Country"
										name={'ocountry[' + index + ']'}
										value={ownership.ocountry}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ocountry', values)}
										optionList={
											<>
												<option value="select">select...</option>
												{GlobalStore.getAllCountries().map((data) => (
													<option
														key={'optState' + data.value}
														value={data.value}>
														{data.text}
													</option>
												))}
											</>
										}
									/>
									<RowContainer
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="State"
										name={'ostate[' + index + ']'}
										value={ownership.ostate}
										setValue={(values) => handleChangeOwner(index, 'ownership', 'ostate', values)}
										optionList={
											<>
												<option value="select">select...</option>
												{GlobalStore.getStates(ownership.ocountry).map((data) => (
													<option
														key={'optState' + data.value}
														value={data.value}>
														{data.text}
													</option>
												))}
											</>
										}
									/>
								</div>
							</div>
						</div>
					</div>
				))}

				<Alert
					style={{ marginTop: '20px', width: '100%' }}
					show={msgOwnerPercent}
					variant="danger">
					<div className="d-flex flex-row justify-content-between align-items-center gap-1">
						<p className="m-0">The total combination of % Ownership cannot be greater than 100%</p>
					</div>
				</Alert>

				<FrameGroup
					icon_frame={<RiContactsBookFill className={styles['icon26'] + ' ' + styles['color-blue']} />}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label="Contacts"
					editClick={() => setInputDisabled(false)}
					useBtn={false}
				/>

				{!inputDisabled && Owner.contacts.length === 0 && (
					<div className="d-flex flex-row flex-wrap justify-content-end align-items-center p-0 gap-3 mb-2 w-100">
						<button
							onClick={() => addOwner('contacts')}
							className={styles['btn'] + ' ' + styles['border-blue']}>
							<RiAddFill className={styles['icon20']} />
							ADD CONTACT
						</button>
					</div>
				)}

				{Owner.contacts.map((contacts, index) => (
					<div
						key={'contacts' + index}
						style={{ width: '100%', borderBottom: !inputDisabled && index < Owner.contacts.length - 1 && '1px solid rgb(244, 245, 247)' }}>
						<div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-0 gap-3 mb-3 w-100">
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
								style={{ fontWeight: '600', lineHeight: '24px' }}>
								Contact # {(index + 1).toString()}
							</label>
							{!inputDisabled && (
								<div
									className="d-flex flex-row flex-wrap justify-content-end align-items-center pt-0 pb-0 ps-0 gap-2"
									style={{ paddingRight: '10px' }}>
									<button
										onClick={() => removeOwner(index, 'contacts')}
										className={styles['btn'] + ' ' + styles['btn-red-letter'] + ' ' + styles['no-border']}>
										<BsTrash className={styles['icon20']} />
										DELETE CONTACT
									</button>
									{Owner.contacts.length === index + 1 && (
										<button
											onClick={() => addOwner('contacts')}
											className={styles['btn'] + ' ' + styles['border-blue']}>
											<RiAddFill className={styles['icon20']} />
											ADD CONTACT
										</button>
									)}
								</div>
							)}
						</div>
						<div className="d-flex flex-column w-100">
							<div className={styles['grid-container']}>
								<div className="d-flex flex-column align-items-start p-0">
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (contacts.contactName === null || contacts.contactName.trim().length === 0) ? 'is-invalid' : ''}
										labelValue="Name"
										name={'contactName[' + index + ']'}
										placeholder="Value"
										value={contacts.contactName}
										setValue={(values) => handleChangeOwner(index, 'contacts', 'contactName', values)}
										mask={GlobalStore.maskValidator('text')}
										maxLength={100}
									/>
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Title"
										name={'contactTitle[' + index + ']'}
										placeholder="Value"
										value={contacts.contactTitle}
										setValue={(values) => handleChangeOwner(index, 'contacts', 'contactTitle', values)}
										mask={GlobalStore.maskValidator('text')}
										maxLength={100}
									/>
								</div>

								<div className="d-flex flex-column align-items-start p-0">
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (contacts.contactEmail === null || contacts.contactEmail.trim().length === 0 || !GlobalStore.emailValidation(contacts.contactEmail)) ? 'is-invalid' : ''}
										TooltipValid={!inputDisabled && (contacts.contactEmail === null || (contacts.contactEmail.trim().length > 0 && !GlobalStore.emailValidation(contacts.contactEmail))) ? 'The email is not valid' : ''}
										labelValue="Email"
										name={'contactEmail[' + index + ']'}
										placeholder="Value"
										value={contacts.contactEmail}
										setValue={(values) => handleChangeOwner(index, 'contacts', 'contactEmail', values)}
										mask={GlobalStore.maskValidator('email')}
										maxLength={100}
									/>
									<RowContainer
										inputType="mask"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										classNameInput={!inputDisabled && (contacts.contactPhone === null || contacts.contactPhone.trim().length === 0) ? 'is-invalid' : ''}
										labelValue="Phone"
										name={'contactPhone[' + index + ']'}
										placeholder="Value"
										value={contacts.contactPhone}
										setValue={(values) => handleChangeOwner(index, 'contacts', 'contactPhone', values)}
										mask={GlobalStore.maskValidator('phone')}
									/>
								</div>
							</div>
						</div>
					</div>
				))}

				<ButtonSaveCancel
					inputDisabled={inputDisabled}
					funtionCancel={PayPoint.idPaypoint <= 0 ? null : () => setInputDisabled(true) | load()}
					funtionSave={PayPoint.idPaypoint <= 0 ? null : () => updateOwnerShip()}
				/>
			</div>
		</Element>
	)
}
