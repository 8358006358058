import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { BiFileBlank } from 'react-icons/bi';

@inject('global')
@observer
class FileViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    const { fileData, fileType, global, isQuickView } = this.props;
    const { furl, fContent } = fileData || {};
    const { showModal } = this.state;
    const fileTypesString = fileType ? fileType.toString().toLowerCase() : '';
    const listTypesImage = ['png', 'jpg', 'jpeg', 'bmp', 'svg'];

    let srcFile = '';
    if (furl && typeof furl === 'string') {
      srcFile = furl;
    }else if (fContent && typeof fContent === 'string') {
      if (fileTypesString.includes('pdf')) {
        srcFile = `data:application/${fileType};base64,${fContent}`;
      } else if (listTypesImage.includes(fileTypesString)) {
        srcFile = `data:${fileType};base64,${fContent}`;
      }
    }

    const renderElement = () => {
      if (!srcFile) {
        return (
          <p className='w-100 text-center' style={{height: '200px'}}>File not found</p>
        );
      }
      if (fileTypesString.includes('pdf')) {
        return (
          <iframe
            title="File Viewer"
            src={srcFile}
            width="100%"
            height="500px"
          />
        );
      } else if (listTypesImage.includes(fileTypesString)) {
        return (
          <img src={srcFile} alt="File" width="100%" height="500px" />
        );
      }
      return (
        <p className='w-100 text-center' style={{height: '200px'}}>File type not supported</p>
      )
    }

    return (
      <>
        { isQuickView ?
            <button type="button" className='btn btn-outline-primary' onClick={(e) => this.toggleModal(e)}><BiFileBlank /> View Check</button> :
            <a href='/' variant="link" onClick={(e) => this.toggleModal(e)}>{global.getPaymethodImg('checkblue')}</a>
        }
        <Modal show={showModal} size='lg' onHide={this.toggleModal}>
          <Modal.Body>
            {renderElement()}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export {FileViewer};