import React, { useState, useEffect } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import { RiArrowUpSLine } from 'react-icons/ri'

export default function ShowGroupCard({ children, useShowCard = true, expand, inputDisabled }) {
	const [showCard, setShowCard] = useState(expand)

	useEffect(() => {
		setShowCard(expand)
	}, [expand])

	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<Collapse in={!inputDisabled ? true : showCard}>
				<div id="collapse-card">{children}</div>
			</Collapse>

			{useShowCard && inputDisabled && (
				<div className="d-flex flex-row align-items-center justify-content-center">
					<div style={{ border: '1px solid rgba(33, 33, 33, 0.2)', width: '100%' }}></div>
					<Button
						onClick={() => setShowCard(!showCard)}
						aria-controls="collapse-card"
						aria-expanded={showCard}
						className={showCard ? styles['container-btn-show-cards'] : styles['container-btn-show-cards'] + ' ' + styles['close']}>
						{!showCard ? 'SHOW MORE ' : 'SHOW LESS '} <RiArrowUpSLine className={styles['icon20']} />
					</Button>
					<div style={{ border: '1px solid rgba(33, 33, 33, 0.2)', width: '100%' }}></div>
				</div>
			)}
		</div>
	)
}
