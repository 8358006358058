
import React from 'react';
import { inject, observer } from 'mobx-react';
import {toast} from 'react-toastify';

@inject('boarding','global')
@observer
class ApplicationStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appStatus: ""
        };
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.saveStatus = this.saveStatus.bind(this);
    }

    componentDidMount(){
        if(this.props.idApp){
            this.setState({appStatus: this.props.status});
        }
    }

    handleStatusChange(e){
        this.props.boarding.setTemplateData('boardingStatus', e.target.value);
        this.setState({ [e.target.id]: e.target.value });
    }

    saveStatus(e){
        e.preventDefault();
        this.props.global.setLoading(true);
        this.props.boarding.updateApplicationStatus(this.props.idApp, this.state.appStatus).then(res => {
            this.props.changeStatus(this.state.appStatus);
            toast.success("Status changed successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container',
            });
            this.props.global.setLoading(false);
        }).catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    render() {
       
        return (
            <div className="row popover-body" style={{padding: 0}}>
                <div className="col-sm-7">
                    <div className="form-floating mb-4">
                        <select value={this.state.appStatus} className="form-control form-select" name="appStatus" id="appStatus" onChange={(e) => this.handleStatusChange(e)}>
                            <option value="-99" >Not Submitted</option>
                            {/* <option value="1">In Process</option> */}
                            <option value="2">Pending Signature</option>
                            <option value="3">Underwriting</option>
                            <option value="4">Submitted</option>
                            <option value="6">Manual Review</option>
                            <option value="7">Approved</option>
                            <option value="8">Withdrawn</option>
                            <option value="9">Deactivated</option>
                            <option value="10">Boarding</option>
                            <option value="99">Activated</option>
                            <option value="100">Live</option>
                            <option value="0">Declined</option>
                            <option value="-1">Exception Error</option>
                            <option value="13">Signed</option>
                            {/* <option value="5">Hold</option> */}
                        </select>
                        <label htmlFor="appStatus">Application Status</label>
                    </div>
                </div>
                <div className="col-sm-5">
                    <button onClick={(e) => this.saveStatus(e) } className="btn btn-outline-primary full-w">Update Status</button>
                </div>
            </div>
        )
    }
}

export { ApplicationStatus };