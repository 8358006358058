import React from 'react';
import { inject, observer } from 'mobx-react';
import { SectionVendor } from '../views/Bills/builder/form/vendor/SectionVendor';
import SectionBillDetails from '../views/Bills/builder/form/billDetails/SectionBillDetails';
import { PayBillAdvancedOptions } from './PayBillAdvancedOptions';
import { PayBillPaymentDeliveryMethods } from './PayBillPaymentDeliveryMethods';
import NumberFormat from 'react-number-format';
import { Accordion, Card, Modal } from 'react-bootstrap';
import cardIcon from '../assets/images/card.svg';
import achIcon from '../assets/images/ach.svg';
import paperCheck from '../assets/images/paper-check.svg';
import { BiCheck, BiChevronLeft, BiLayer, BiTimer } from 'react-icons/bi';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SendPaymentLinkModal } from './SendPaymentLinkModal';


@inject('reports','bill','global', 'vTerminal')
@observer
class PayBill extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsOpen: false,
      step: 0,
      method: null,
      paymentDeliveryMethod: 1,
      emails: '',
      showSendPaymentLinkModal: false,
      emailsError: false,
      showSchedulePaymentConfirmModal: false,
    };
    this.handleCategoryTextChangeMask = this.handleCategoryTextChangeMask.bind(this);
    this.goto = this.goto.bind(this);
    this.selectPaymentDeliveryMethod = this.selectPaymentDeliveryMethod.bind(this);
    this.openSendPaymentLinkModal = this.openSendPaymentLinkModal.bind(this);
    this.closeSendPaymentLinkModal = this.closeSendPaymentLinkModal.bind(this);
    this.schedulePayment = this.schedulePayment.bind(this);
    this.statusSchedulePaymentConfirmModal = this.statusSchedulePaymentConfirmModal.bind(this);

    this.paymentMethods = {
      "0" : "Select default payment method",
      "1" : "Virtual Card",
      "2" : "Bank Account",
      "3" : "Paper Check",
      "4" : "Managed",
    }
  }

  closeSendPaymentLinkModal(){
    this.setState({showSendPaymentLinkModal:false});
  }
  
  openSendPaymentLinkModal(){
    this.setState({showSendPaymentLinkModal:true});
  }

  handleCategoryTextChangeMask(field, key ,value) {
    if(!isNaN(value))
    {
        value = value.toString();
    }
   // this.props.vTerminal.handleCategoryTextChangeMask(field, key ,value);
  }

  goto(step){
    this.setState({step: step});
  }

  focus(e){
    e.target.focus();
    e.target.setSelectionRange(0, 1000000000);
  }

  setPaymentMethod(method, activeKey) {
      this.setState({method: activeKey});
      this.props.vTerminal.setPaymentMethod(method);
  }

  selectPaymentDeliveryMethod(method){
    this.setState({paymentDeliveryMethod: method});
  }

  selectPaymentDeliveryMethodAction(){
    this.setState({method: null});
    if(this.state.paymentDeliveryMethod === 1){
      this.setState({emailsError : false, emails: ''})
      this.openSendPaymentLinkModal();
    }
    else{
      this.goto(1);
    }
  }

  async schedulePayment(){
    if(!this.state.method || this.state.method === ""){
      toast.error("Please select a payment method.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
      });
      return;
    }
    let paymentMethodSelected = this.props.bill.getPaymentMethodForBill(this.state.method);
    this.props.global.setLoading(true);
    try {
      const resultPayment = await this.props.bill.schedulePayment(this.props.billToPay, paymentMethodSelected).then(
        res => {
          const isSuccess = res?.data?.isSuccess ?? false;
          const referenceId = res?.data?.responseData?.referenceId ?? null;
          return {isSuccess, referenceId};
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = "Something is Wrong!";
            if(error.response.data.responseData && error.response.data.responseData.todoAction && error.response.data.responseData.explanation){
              errorMessage = error.response.data.responseData.explanation + ". "+ error.response.data.responseData.todoAction;
            }
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
        if(paymentMethodSelected === 'vcard' && resultPayment.isSuccess && resultPayment.referenceId){
          await this.props.reports.executeCaptureAndSendVCardLink(resultPayment.referenceId, 11).then( res => {} ).catch(error => {
            throw error;
          });
        }else if(resultPayment.isSuccess && resultPayment.referenceId){
          await this.props.reports.captureTransactionOut(resultPayment.referenceId).then( res => {} ).catch(error => {
            throw error;
          });
        }

      toast.success("Payment has been sent!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-success-container',
      });
      this.statusSchedulePaymentConfirmModal(false);
      if(this.props.functionCallBack){
        this.props.functionCallBack();
      }
      if(this.props.secondFunctionCallBack){
        this.props.secondFunctionCallBack();
      }
    } catch (error) {
      this.props.global.setLoading(false);
      console.log("Error: ", error);
      toast.error("Something is Wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
      });
    }
  }

  statusSchedulePaymentConfirmModal(status){
    this.setState({showSchedulePaymentConfirmModal: status})
  }

  render() {
    let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;
    return (
      <>

        <Modal style={{textAlign: "center"}} show={this.state.showSchedulePaymentConfirmModal} onHide={()=>this.statusSchedulePaymentConfirmModal(false)}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <BiCheck className="icon-modal"/>
                <h5>Send Payment</h5>
                <p className="small">Send this payment via <br/><b>{this.paymentMethods[this.state.method]}?</b></p>
                <button className="btn cancel-btn" onClick={() => this.statusSchedulePaymentConfirmModal(false)}>
                    Cancel
                </button>
                &nbsp;&nbsp;
                <button className="btn btn-primary" onClick={(e) => this.schedulePayment()}>
                  Send Payment
                </button>
            </Modal.Body>
        </Modal>

      <SendPaymentLinkModal
        vendorName={
          (this.props.billToPay && this.props.billToPay.Vendor) ? 
            (
              (this.props.billToPay.Vendor.Name1 ? this.props.billToPay.Vendor.Name1 : (this.props.billToPay.Vendor.name1 ? this.props.billToPay.Vendor.name1 : '')) + " " + 
              (this.props.billToPay.Vendor.Name2 ? this.props.billToPay.Vendor.Name2 : (this.props.billToPay.Vendor.name2 ? this.props.billToPay.Vendor.name2 : ''))
            ) 
            : 
          null
        }
        amountOf={(this.props.billToPay && this.props.billToPay.NetAmount) ? this.props.billToPay.NetAmount : 0}
        emailOnFile={
            (this.props.billToPay && this.props.billToPay.Vendor && this.props.billToPay.Vendor.Email) ? String(this.props.billToPay.Vendor.Email).toLowerCase() 
            : 
            (this.props.billToPay && this.props.billToPay.Vendor && this.props.billToPay.Vendor.email) ? String(this.props.billToPay.Vendor.email).toLowerCase()
              : 
              ''
        }
        show={this.state.showSendPaymentLinkModal} 
        onHide={this.closeSendPaymentLinkModal}
        billToPay = {this.props.billToPay}
        closeSendPaymentLinkModal={this.closeSendPaymentLinkModal}
        functionCallBack={this.props.functionCallBack}
        secondFunctionCallBack={this.props.secondFunctionCallBack}
      />

      {this.state.step === 0 &&
        <div>
          <div className="text-center full-w mb-3 mt-2">
            <small>Bill amount you wish to pay</small>
          </div>
          
          <NumberFormat
              onFocus={(e)=>this.focus(e)} 
              thousandsGroupStyle="thousand"
              prefix="$"
              decimalSeparator="."
              displayType="input"
              type="text"
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}

              value={(this.props.billToPay && this.props.billToPay.NetAmount) ? this.props.billToPay.NetAmount : 0}
              placeholder={"$"}
              className={"big-amount"}
              onValueChange={(values) => this.handleCategoryTextChangeMask("amount","value", values.value)}
              readOnly={true}
          />

          <div className="mb-3">
            <SectionVendor params={this.props.params}/>
          </div>
          <div className="mb-3">
            <SectionBillDetails isView={this.props.isView} billDataLoaded={this.props.billDataLoaded} noTerms={true}/>
          </div>
          
          <div className="mb-3">
              <h6 className="mb-3">Notes</h6>
              <div className="form-floating">
                <input
                  className="form-control"
                  //onChange={this.handleChangeInput}
                  placeholder="Memo note"
                  id="memoNote"
                  value={(this.props.bill && this.props.bill.advancedOptions.memoNote) ? this.props.bill.advancedOptions.memoNote : ""}
                  disabled={this.props.isView}
                />
                <label htmlFor={"memoNote"}>Memo note</label>
              </div>
            </div>
            <div className="mb-3">
              <PayBillAdvancedOptions/>
            </div>
            <div className="mb-4">
              <PayBillPaymentDeliveryMethods callbackFunction={this.selectPaymentDeliveryMethod} optionSelected={this.state.paymentDeliveryMethod}/>
            </div>
            <div className="mb-3">
              <button className="btn btn-success btn-lg full-w" onClick={()=>this.selectPaymentDeliveryMethodAction()}>{ this.state.paymentDeliveryMethod === 1 ? "Send Payment Link" : "Select Payment Method"}</button>
            </div>
            <button onClick={()=>this.props.functionCallBack()} className="btn btn-default bordered btn-lg panelbutton-secondary">Close</button>
        </div>
        }
        {this.state.step === 1 &&
        <div>
            <h6 className='mt-4 mb-3'>Payment Method</h6>

            <Accordion>

            {credentialsPermissions.managed.moneyout &&
            <Card className={"card card-in mb-3 card-select " + (this.state.method === "4" ? "selected" : "")}>
              <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod("managed","4")}>
                  <div className="row">
                      <div className="col-2 text-center">
                      <BiTimer style={{fontSize: '39px', marginTop: "4px",  opacity: "0.25"}}/>
                      </div>
                      <div className="col-10">
                          {this.paymentMethods["4"]}
                          <p className="small small-grey-m0">
                          The vendor will be contacted to obtain payment
                          </p>
                      </div>
                  </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="00">
              <Card.Body>
                
              </Card.Body>
              </Accordion.Collapse>
            </Card>
            }

            {/**<Card className={"card card-in mb-3 card-select " + (this.state.method === "0" ? "selected" : "")}>
              <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod("0")}>
                  <div className="row">
                      <div className="col-2 text-center">
                      <BiLayer style={{fontSize: '34px', marginLeft: '4px', marginTop: '4px', opacity: "0.2"}} />
                      </div>
                      <div className="col-10">
                          {this.paymentMethods["0"]}
                          <p className="small small-grey-m0">
                          Vendor's preferred way to get paid
                          </p>
                      </div>
                  </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
              <Card.Body>
                
              </Card.Body>
              </Accordion.Collapse>
              </Card>**/}

            {credentialsPermissions.vcard.moneyout &&
            <Card className={"card card-in mb-3 card-select " + (this.state.method === "1" ? "selected" : "")}>
              <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="1" onClick={(e) => this.setPaymentMethod("vcard","1")}>
                  <div className="row">
                      <div className="col-2 text-center">
                      <img alt="" style={{width: '34px', margin: '8px', marginTop: '4px'}} className="grey-icon-v2" src={cardIcon}/>
                      </div>
                      <div className="col-10">
                          {this.paymentMethods["1"]}
                          <p className="small small-grey-m0">
                          Recipient is emailed a digital card
                          </p>
                      </div>
                  </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
              <Card.Body className='pt-1'>
                    <div className="row">
                        <div className="col-2">
                        
                        </div>
                        <div className="col-10" >
                            <div>
                            <div className="form-floating">
                                <input readOnly autoComplete="off" value={this.props.vTerminal.getVendorSelected?.Email} className={"form-control"} placeholder="emailedCheck" />
                                <label htmlFor="emailedCheck">Remittance email on file</label>
                            </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            }


            {credentialsPermissions.ach.moneyout &&
            <Card className={"card card-in mb-3 card-select " + (this.state.method === "2" ? "selected" : "")}>
              <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="2" onClick={(e) => this.setPaymentMethod("ach","2")}>
                  <div className="row">
                      <div className="col-2 text-center">
                      <img alt="" style={{width: '38px', margin: "8px 0 0 4px"}} className="grey-icon-v2" src={achIcon}/>
                      </div>
                      <div className="col-10">
                          {this.paymentMethods["2"]}
                          <p className="small small-grey-m0">
                          Collect recipients ACH information to transfer funds
                          </p>
                      </div>
                  </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="22">
              <Card.Body>
                
              </Card.Body>
              </Accordion.Collapse>
            </Card>
            }

            {credentialsPermissions.check.moneyout &&
            <Card className={"card card-in mb-5 card-select " + (this.state.method === "3" ? "selected" : "")}>
              <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="3" onClick={(e) => this.props.vTerminal.getVendorSelected?.Address1 ? this.setPaymentMethod("check","3") : null}>
                  <div className="row">
                      <div className="col-2 text-center">
                      <img alt="" style={{width: '38px', marginLeft: '4px', marginTop: '4px'}} className="grey-icon-v2" src={paperCheck}/>
                      </div>
                      <div className="col-10">
                          {this.paymentMethods["3"]}
                          <p className="small small-grey-m0">
                          Recipient is mailed a check to their address on file
                          </p>
                          {this.props.vTerminal.getVendorSelected?.Address1 ? "" : <p className="small-small small-grey-m0 text-danger mt-1">The selected vendor does'nt have an address to send a check </p>}
                      </div>
                  </div>
              </Accordion.Toggle>
              {this.props.vTerminal.getVendorSelected?.Address1 &&
              <Accordion.Collapse eventKey="3">
              <Card.Body className='pt-1'>
                    <div className="row">
                        <div className="col-2">
                        
                        </div>
                        <div className="col-10" >
                            <div>
                            <div className="form-floating">
                                <input readOnly autoComplete="off" value={this.props.vTerminal.getVendorSelected?.Address1 + " " + this.props.vTerminal.getVendorSelected?.City + " " + this.props.vTerminal.getVendorSelected?.State + " " + this.props.vTerminal.getVendorSelected?.Zip + " " + this.props.vTerminal.getVendorSelected?.Country} className={"form-control"} placeholder="emailedCheck" />
                                <label htmlFor="emailedCheck">Remittance address on file</label>
                            </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
              </Accordion.Collapse>
              }
            </Card>
            }

            </Accordion>
          
            <div className="review-total">
                <h6 className="sub-header-line mb-3">Review & Send Payment</h6>
                <div className="row total-amount">
                <div className="col">Total Amount:</div>
                <div className="col">${this.props.global.numberWithCommas(parseFloat((this.props.billToPay && this.props.billToPay.NetAmount) ? this.props.billToPay.NetAmount : 0).toFixed(2))}</div>
                </div>
            </div>

            <div className="mb-3">
              <button disabled={this.state.method === null} onClick={()=>this.statusSchedulePaymentConfirmModal(true)} className="btn btn-success btn-lg full-w">Send Payment</button>
            </div>
            <button className="btn btn-default bordered btn-lg panelbutton-secondary" onClick={()=>this.goto(0)}><BiChevronLeft/> Go Back</button>
        </div>
        }
      </>
    );
  }
}

export { PayBill };
