import axios from "axios";
import {
  PayabliStorageManager,
} from "./localStorageManager";
import * as Sentry from "@sentry/react";


export class SessionManager {

  static getNewToken(token) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .post(
        process.env.REACT_APP_URL_API + "User/authdub",
        {},
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        return res && res.data && res.data.responseData
          ? res.data.responseData
          : null;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

}
