import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { withRouter } from 'react-router-dom';

@inject('store', 'global', 'invoice', 'vTerminal')
@observer
class ModalConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: '',
      emailsError : false
    };

    this.handleEmails = this.handleEmails.bind(this);
    this.handleProcessInvoice = this.handleProcessInvoice.bind(this);
    this.isValidAdditionalEmails = this.isValidAdditionalEmails.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps){
    if(this.props.open === true && prevProps.open === false){
        this.setState({
          emails: '',
          emailsError : false
        })
    }
  }

  handleEmails(e) {
    this.setState({ emails: e.target.value });
  }

  redirectToInvoiceReport() {
    // clean stores
    this.props.vTerminal.cleanCategories();
    this.props.invoice.reset();

    this.props.history.push({
      pathname: '/'+this.props.global.getURLEntry() +'/report/invoices',
    });
  }

  isValidAdditionalEmails(){
    return this.props.global.validators.stringValidator('emailSemicolon',this.state.emails);
  }

  async chargeCustomer() {
    try {
      const response = await this.props.vTerminal.makePayment();
      if (response.data.responseData.resultCode && response.data.responseData.resultCode !== 1) {
        let message = '';
        if (response.data.responseData.resultText) {
          message = response.data.responseData.resultText;
        } else {
          message = response.data.responseText
            ? 'Sorry, the payment was ' + response.data.responseText
            : 'Sorry, we have a problem sending your payment.';

          toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
          });

          return false;
        }
      } else {
        if (response.data.responseData.referenceId) {
          this.props.vTerminal.sendReceipt(response.data.responseData.referenceId);
        }
      }

      return true;
    } catch (reason) {
      let message = '';
      if (reason.data) {
        message = reason.data.responseText
          ? reason.data.responseText
          : 'Sorry, we have a problem sending your payment.';
      } else if (reason.request.response) {
        if (typeof reason.request.response === 'string') {
          let messageJson = JSON.parse(reason.request.response);
          if (messageJson.responseText) {
            message = messageJson.responseText;
          } else {
            message = reason.request.response;
          }
        } else {
          message = JSON.parse(reason.request.response).responseText;
        }
      }

      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error-container',
      });

      return false;
    }
  }

  async handleProcessInvoice() {
    this.props.global.setLoading(true);
    let customerSelected = this.props.invoice.customerSelected;

    if((!customerSelected.Email || customerSelected.Email==='') && (!this.state.emails || this.state.emails === '') || 
       (!customerSelected.Email || customerSelected.Email==='') && (this.state.emails || this.state.emails !== '') && (!this.isValidAdditionalEmails())
    ){
      this.props.global.setLoading(false);
      this.setState({emailsError : true})
      return;
    }
    else{
      this.setState({emailsError : false})
    }

    
    if((this.state.emails || this.state.emails !== '') && (!this.isValidAdditionalEmails())){
      this.props.global.setLoading(false);
      this.setState({emailsError : true})
      return;
    }

    try {
      const { action } = this.props.invoice.invoiceActionSelected;

      if (action === 'chargeCustomer') {
        const success = await this.chargeCustomer();

        // do charge, save invoice and send Email
        if (success) {
          // 2: status PAID
          await this.props.invoice.sendInvoiceWithoutPaylink(this.state.emails, 4);
          this.props.global.setLoading(false);
          this.redirectToInvoiceReport();
          return toast.success('Your invoice was charged and sent successfully.', {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-success-container',
          });
        }
      }

      if (action === 'sendInvoice') {
        await this.props.invoice.sendInvoiceWithPaylink(this.state.emails);
        this.redirectToInvoiceReport();
        return toast.success('Your invoice was saved and sent successfully.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success-container',
        });
      }
    } catch (e) {
      this.props.global.setLoading(false);

      if (e.response) {
        return toast.error(e.response.data.responseText, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      }

      toast.error(e.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error-container',
      });
    }

    this.props.global.setLoading(false);
  }

  render() {
    const action = this.props.invoice.invoiceActionSelected.action;
    const subtotal = this.props.invoice.pay.subtotal;
    const totalToPay = `$${this.props.global.numberWithCommas(parseFloat(subtotal).toFixed(2))}`;
    let btnText = null;
    let modalTitle = null;

    const { customerSelected } = this.props.invoice;

    switch (action) {
      case 'sendInvoice':
        modalTitle = `Send invoice with personalized link`;
        btnText = 'Send invoice';
        break;
      case 'chargeCustomer':
        modalTitle = `Please review`;
        btnText = `Charge customer ${totalToPay}`;
        break;
      default:
        break;
    }

    return (
      <Modal show={this.props.open} onHide={this.props.close} size="md" centered>
        <Modal.Body className="popover-body" id="modal-confirmation">
          <div className="row mb-4 underlined">
            <div className="col">
              <h4>{modalTitle}</h4>
            </div>
          </div>

          <p className="description">
            Invoice <b>{customerSelected.Company ? customerSelected.Company+ " - ": ""}{customerSelected.Firstname} {customerSelected.Lastname}</b> for <b>{totalToPay}</b>
          </p>

          <p>Email on file - { (!customerSelected.Email || customerSelected.Email==='') ? this.state.emails : customerSelected.Email}</p>

          <div className="row">
            <div className="col-12">
              <label htmlFor="invoice-emails">Include additional emails</label>
              <input
                id="invoice-emails"
                value={this.state.emails}
                onChange={(e) => this.handleEmails(e)}
                type="text"
                className= {this.state.emailsError === true ? "input-error form-control mb-3" : "form-control mb-3"}
                placeholder="Add emails (optional) / separate by semicolon"
              />
            </div>
          </div>

          <div className="row mt-4">
            <div id="modal-confirmation-buttons" className="col">
              <button className="btn btn-light" type="button" onClick={this.props.close}>
                Back to editing
              </button>
              <button
                className="btn btn-main btn-default btn-bordered-success"
                type="button"
                onClick={this.handleProcessInvoice}
              >
                {btnText}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(ModalConfirmation);
