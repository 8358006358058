import React from 'react'

export const MarketingVector = (props) => {
    return (
        <svg
            width={751}
            height={322}
            viewBox="0 0 751 322"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M751 234.233C581.657 244.337 427.771 148.093 357.581 0.000232654M192.922 0.000141827C247.137 191.096 431.935 320.035 633.991 296.304C675.419 291.439 714.679 280.495 751 264.459M751 121.205C687.745 226.804 578.503 302.82 447.641 318.189C237.963 342.815 46.8697 203.037 1 0"
                stroke="#F1F5F9"
                strokeWidth={2}
            />
        </svg>

    )
}

