import { payPointServiceGET } from '../../Connections/ServicesConnections'
import { toast } from 'react-toastify'
import { action } from 'mobx'

export default function ServiceFunctionLoad({ service, setService, payPoint }) {
	const loadPaypointService = action(async (entryPoints) => {
		try {
			const newService = {
				moneyin: {
					card: [],
					ach: [],
					check: [],
					cloud: [],
					cash: [],
					wallet: []
				},
				moneyout: {
					managed: [],
					ach: [],
					check: [],
					vcard: [],
					pushToCard: []
				}
			}

			if (!entryPoints && payPoint.entryPoints.length <= 0) return
			const res = await payPointServiceGET(!entryPoints ? payPoint.entryPoints[0].entryName : entryPoints, '')
			if (res.data.error) {
				toast.error(res.data.error, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
				return
			} else {
				res.data.Services.forEach((data) => {
					newService[data.ServiceGroup][data.ServiceName].push(data)
				})
			}
			setService(newService)
			//console.log('Service', newService)
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return
		}
	})
	const loadPaypointServiceName = action(async (entryPoints, serviceGroup, serviceName) => {
		try {
			const newServiceName = []
			const res = await payPointServiceGET(!entryPoints ? payPoint.entryPoints[0].entryName : entryPoints, serviceName)
			if (res.data.error) {
				toast.error(res.data.error, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
				return
			} else {
				res.data.Services.forEach((data) => {
					if (serviceGroup === data.ServiceGroup) {
						newServiceName.push(data)
					}
				})
			}
			setService({
				...service,
				[serviceGroup]: {
					...service[serviceGroup],
					[serviceName]: newServiceName
				}
			})
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return
		}
	})

	return {
		loadPaypointService,
		loadPaypointServiceName
	}
}
