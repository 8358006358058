import React, { useContext } from 'react'
import GroupSwitch from '../../../../../../components/templates/GroupSwitch'
import templatesContext from '../../../context/templates_context'

export default function AchPricingType() {
	const { RootTemplate, LoadingState, ServicesTemplate, handleChangeService, handleChangePricingType } = useContext(templatesContext)

	const pricingTypeAchStructure = {
		checksAbsorb: [
			{ property: 'achAbsorb', field: 'visible', title: 'Absorb ACH Price', fieldData: !LoadingState && ServicesTemplate.ach, isCheck: true, classGroup: 'col-4', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-2 text-center', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-2 text-center', labelUp: false },
			{ property: 'achAbsorb_lowPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.ach, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'achAbsorb_highPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.ach, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true }
		],
		absorbACH: [
			{ property: 'blank', field: '', title: 'ACH', fieldData: !LoadingState && '', isCheck: false, classGroup: 'col-4 p-4 d-flex align-items-center', labelUp: false },
			{ property: 'web', field: 'percentxAuth', title: '% x auth', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.ach.achAbsorb.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'achAbsorb', subProperty: ['tiers'] },
			{ property: 'web', field: 'amountxAuth', title: '$ x auth', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.ach.achAbsorb.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'achAbsorb', subProperty: ['tiers'] },
			{ property: 'web', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.ach.achAbsorb.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'achAbsorb', subProperty: ['tiers'] },
			{ property: 'web', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.ach.achAbsorb.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'achAbsorb', subProperty: ['tiers'] }
		],
		checksPassThrough: [
			{ property: 'achPassThrough', field: 'visible', title: 'Pass-Through ACH Price', fieldData: !LoadingState && ServicesTemplate.ach, isCheck: true, classGroup: 'col-2', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-1 text-center', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-2 text-center', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-1 text-center', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-2 text-center', labelUp: false },
			{ property: 'achPass_lowPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.ach, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'achPass_highPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.ach, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true }
		],
		passThroughACH: [
			{ property: 'blank', field: '', title: 'ACH', fieldData: !LoadingState && '', isCheck: false, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false },
			{ property: 'web', field: 'percentFeeOneTime', title: '% OneTime', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.ach.achPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'achPassThrough', subProperty: ['tiers'] },
			{ property: 'web', field: 'amountFeeOneTime', title: '$ OneTime', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.ach.achPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'achPassThrough', subProperty: ['tiers'] },
			{ property: 'web', field: 'percentFeeRecurring', title: '% Recurring', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.ach.achPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'achPassThrough', subProperty: ['tiers'] },
			{ property: 'web', field: 'amountFeeRecurring', title: '$ Recurring', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.ach.achPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'achPassThrough', subProperty: ['tiers'] },
			{ property: 'web', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.ach.achPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'achPassThrough', subProperty: ['tiers'] },
			{ property: 'web', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.ach.achPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'achPassThrough', subProperty: ['tiers'] }
		],
		passThroughMultiTier: [{ property: 'achPassThrough', field: 'multiTier', title: 'Offer MultiTiers', fieldData: !LoadingState && ServicesTemplate.ach, isCheck: true, classGroup: 'col-2', labelUp: false }]
	}

	return (
		<>
			{(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData.ach.achAbsorb.visible) && (ServicesTemplate.ach.pricingType.value === '5' || ServicesTemplate.ach.pricingType.value === '6') && (
				<>
					<GroupSwitch
						groupFields={pricingTypeAchStructure.checksAbsorb}
						handleChange={(subProperty0, subProperty, property, field, values) => handleChangeService('ach', '', property, field, values)}
					/>
					{RootTemplate.isRoot ? (
						<GroupSwitch
							groupFields={pricingTypeAchStructure.absorbACH}
							handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('ach', subProperty0, subProperty, property, field, values)}
						/>
					) : (
						<table className="table">
							<thead>
								<tr>
									<th>ACH Type</th>
									<th>% x Auth</th>
									<th>$ x Auth</th>
									<th>Low Pay Range</th>
									<th>High Pay Range</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>ACH</td>
									<td>{ServicesTemplate.ach.achAbsorb.tiers[0].web.percentxAuth}</td>
									<td>{ServicesTemplate.ach.achAbsorb.tiers[0].web.amountxAuth}</td>
									<td>{ServicesTemplate.ach.achAbsorb.tiers[0].web.lowPayRange}</td>
									<td>{ServicesTemplate.ach.achAbsorb.tiers[0].web.highPayRange}</td>
								</tr>
							</tbody>
						</table>
					)}
				</>
			)}
			{(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData.ach.achPassThrough.visible) && (ServicesTemplate.ach.pricingType.value === '3' || ServicesTemplate.ach.pricingType.value === '6') && (
				<>
					<GroupSwitch
						groupFields={pricingTypeAchStructure.checksPassThrough}
						handleChange={(subProperty0, subProperty, property, field, values) => handleChangeService('ach', '', property, field, values)}
					/>
					{RootTemplate.isRoot ? (
						<GroupSwitch
							groupFields={pricingTypeAchStructure.passThroughACH}
							handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('ach', subProperty0, subProperty, property, field, values)}
						/>
					) : (
						<table className="table">
							<thead>
								<tr>
									<th>ACH Type</th>
									<th>% x Auth</th>
									<th>$ x Auth</th>
									<th>% x Auth Recurring</th>
									<th>$ x Auth Recurring</th>
									<th>Low Pay Range</th>
									<th>High Pay Range</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>ACH</td>
									<td>{ServicesTemplate.ach.achPassThrough.tiers[0].web.percentFeeOneTime}</td>
									<td>{ServicesTemplate.ach.achPassThrough.tiers[0].web.amountFeeOneTime}</td>
									<td>{ServicesTemplate.ach.achPassThrough.tiers[0].web.percentFeeRecurring}</td>
									<td>{ServicesTemplate.ach.achPassThrough.tiers[0].web.amountFeeRecurring}</td>
									<td>{ServicesTemplate.ach.achPassThrough.tiers[0].web.lowPayRange}</td>
									<td>{ServicesTemplate.ach.achPassThrough.tiers[0].web.highPayRange}</td>
								</tr>
							</tbody>
						</table>
					)}

					{(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData.ach.achPassThrough.multiTier) && (
						<GroupSwitch
							groupFields={pricingTypeAchStructure.passThroughMultiTier}
							handleChange={(subProperty0, subProperty, property, field, values) => handleChangeService('ach', '', property, field, values)}
						/>
					)}
				</>
			)}
		</>
	)
}
