import React from 'react'
import styles from '../../assets/css/styleCommandCenter.module.css'
import InputContainer from '../inputStyles/InputContainer'
import RadioButtonContainer from '../inputStyles/RadioButtonContainer'
import CheckBoxContainer from '../inputStyles/ICheckBoxContainer'
import SelectBoxContainer from '../inputStyles/SelectBoxContainer'
import LabelContainer from '../inputStyles/LabelContainer'
import SelectBoxContainerTemplates from '../inputStyles/SelectBoxContainerTemplates'

export default function RowContainer({
	inputType,
	name,
	checkName,
	placeholder,
	inputDisabled,
	classNameInput,
	labelClassName,
	divInputClass = '',
	divStyle = '',
	divClass = '',
	labelValue,
	labelStyle = '',
	inputStyle = {},
	value = '',
	setValue,
	setCheckValue,
	checkValue = false,
	oneRow = false,
	autoWidth = false,
	mask,
	maxLength,
	optionList = '',
	allowedNullDate = false,
	prefix = '$',
	suffix = '',
	decimalScale = 2,
	maxValue = 0,
	TooltipValid = '',
	TooltipValidColorIcon = '',
	TooltipCaption = '',
	isPhoneInput = false,
	isLoading,
	maxDate,
	useElement = false,
	listElement = [],
	listBtnElement = [],
	placeholderStyle = '',
	selectSearch = false,
	errorValid = false,
	refs,
	iCheck = false,
	onClick = () => null,
	onBlur = () => null
}) {
	return !optionList && !useElement ? (
		inputType !== 'Radio' && inputType !== 'Checkbox' && inputType !== 'Label' ? (
			<InputContainer
				inputType={inputType}
				refs={refs}
				inputDisabled={inputDisabled}
				divClass={!oneRow ? (!divClass ? `d-flex flex-wrap flex-row align-items-start w-100` : divClass) : `d-flex flex-row align-items-start w-100 ${styles['one-row']}`}
				divStyle={!divStyle ? { padding: '0 0 12px', gap: '8px' } : divStyle}
				divInputClass={`${divInputClass} ${oneRow && 'w-100 me-2'}`}
				labelClassName={inputType === 'textarea' ? styles['label-form'] + ' ' + styles['ft-s-13'] + ' ' + styles['textarea'] : styles['label-form'] + ' ' + styles['ft-s-13']}
				labelStyle={
					!labelStyle
						? {
								fontWeight: 600,
								lineHeight: '20px',
								letterSpacing: '0.0025em',
								color: '#000a12',
								alignSelf: inputType === 'textarea' && 'flex-start',
								width: !autoWidth ? (!oneRow ? '180px' : '225px') : 'auto',
								minWidth: '20px'
						  }
						: labelStyle
				}
				classNameInput={!inputDisabled ? `form-control ${styles['textField'] + ' ' + classNameInput}` : `${styles['textField'] + ' ' + styles['disabled'] + ' ' + classNameInput}`}
				labelValue={labelValue}
				name={name}
				placeholder={placeholder}
				value={value}
				onBlur={onBlur}
				setValue={setValue}
				setCheckValue={setCheckValue}
				mask={mask}
				maxLength={maxLength}
				allowedNullDate={allowedNullDate}
				prefix={prefix}
				suffix={suffix}
				maxValue={maxValue}
				decimalScale={decimalScale}
				isPhoneInput={isPhoneInput}
				//isLoading={isLoading}
				placeholderStyle={
					!placeholderStyle
						? {
								minHeight: '20px',
								width: '226px',
								borderRadius: '.25rem'
						  }
						: placeholderStyle
				}
				TooltipCaption={TooltipCaption}
				TooltipValid={TooltipValid}
				TooltipValidColorIcon={TooltipValidColorIcon ? TooltipValidColorIcon : 'color-red'}
				TooltipValidStyle={{
					position: 'relative',
					width: '100%',
					textAlign: 'right',
					height: 0,
					top: '-28px',
					right: '20px'
				}}
				maxDate={maxDate}
				iCheck={iCheck ? (inputDisabled ? false : true) : false}
				labelCheckClassName={styles['label-form'] + ' ' + styles['ft-s-12']}
				labelCheckStyle={!labelStyle ? { fontWeight: 600 } : labelStyle}
				divStyleICheck={{ width: '180px' }}
				checkName={checkName}
				checkValue={checkValue}
			/>
		) : inputType === 'Radio' ? (
			<RadioButtonContainer
				name={name}
				refs={refs}
				setValue={setValue}
				value={value}
				labelClassName={styles['label-form'] + ' ' + styles['ft-s-12']}
				inputDisabled={inputDisabled}
				labelValue={labelValue}
				//isLoading={isLoading}
				placeholderStyle={
					!placeholderStyle
						? {
								minHeight: '20px',
								width: '88px',
								borderRadius: '.25rem'
						  }
						: placeholderStyle
				}
				errorValid={errorValid}
				divStyle={divStyle}
				classNameInput={classNameInput}
				labelStyle={!labelStyle ? {} : labelStyle}
			/>
		) : inputType === 'Checkbox' ? (
			<CheckBoxContainer
				name={name}
				refs={refs}
				setValue={setValue}
				labelClassName={styles['label-form'] + ' ' + styles['ft-s-12']}
				inputDisabled={inputDisabled}
				value={value}
				TooltipCaption={TooltipCaption}
				labelValue={labelValue}
				//isLoading={isLoading}
				placeholderStyle={
					!placeholderStyle
						? {
								minHeight: '20px',
								width: '88px',
								borderRadius: '.25rem'
						  }
						: placeholderStyle
				}
				errorValid={errorValid}
				divStyle={divStyle}
				classNameInput={classNameInput}
				labelStyle={!labelStyle ? {} : labelStyle}
			/>
		) : (
			inputType === 'Label' && (
				<LabelContainer
					labelClassName={`${styles['label-form']} ${styles['ft-s-13']} ${labelClassName}`}
					labelStyle={
						!labelStyle
							? {
									lineHeight: '40px',
									minWidth: '180px',
									letterSpacing: '0.0025em'
							  }
							: labelStyle
					}
					onClick={onClick}
					labelValue={labelValue}
					//isLoading={isLoading}
					placeholderStyle={
						!placeholderStyle
							? {
									minHeight: '20px',
									width: '226px',
									borderRadius: '.25rem'
							  }
							: placeholderStyle
					}
				/>
			)
		)
	) : inputType === 'selectTemplate' ? (
		<SelectBoxContainerTemplates
			inputDisabled={inputDisabled}
			refs={refs}
			selectSearch={selectSearch}
			useElement={useElement}
			//isLoading={isLoading}
			placeholderStyle={
				!placeholderStyle
					? {
							minHeight: '20px',
							width: '226px',
							borderRadius: '.25rem'
					  }
					: placeholderStyle
			}
			divClass={!divClass ? 'd-flex flex-wrap flex-row align-items-start w-100' : divClass}
			divStyle={!divStyle ? { padding: '0 0 12px', gap: '8px' } : divStyle}
			labelClassName={styles['label-form'] + ' ' + styles['ft-s-13']}
			labelStyle={
				!labelStyle
					? {
							fontWeight: 600,
							lineHeight: '20px',
							letterSpacing: '0.0025em',
							width: !autoWidth ? '180px' : 'auto',
							minWidth: '20px'
					  }
					: labelStyle
			}
			classNameInput={!inputDisabled ? (selectSearch ? `${styles['textField']} ${'ft-13'} ${classNameInput}` : `form-control form-select ${styles['textField'] + ' ' + styles['select-box'] + ' ' + classNameInput}`) : `${styles['textField'] + ' ' + styles['disabled'] + ' ' + styles['select-box'] + ' ' + classNameInput}`}
			labelValue={labelValue}
			name={name}
			value={value}
			placeholder={placeholder}
			setValue={setValue}
			inputStyle={inputStyle && inputStyle}
			optionList={optionList}
			listElement={listElement}
			listBtnElement={listBtnElement}
		/>
	) : (
		<div key={`row-container-selection-${name}`}>
			<SelectBoxContainer
				inputDisabled={inputDisabled}
				refs={refs}
				selectSearch={selectSearch}
				useElement={useElement}
				//isLoading={isLoading}
				placeholderStyle={
					!placeholderStyle
						? {
								minHeight: '20px',
								width: '226px',
								borderRadius: '.25rem'
						  }
						: placeholderStyle
				}
				divClass={!divClass ? 'd-flex flex-wrap flex-row align-items-start w-100' : divClass}
				divStyle={!divStyle ? { padding: '0 0 12px', gap: '8px' } : divStyle}
				labelClassName={styles['label-form'] + ' ' + styles['ft-s-13']}
				labelStyle={
					!labelStyle
						? {
								fontWeight: 600,
								lineHeight: '20px',
								letterSpacing: '0.0025em',
								width: !autoWidth ? '180px' : 'auto',
								minWidth: '20px'
						  }
						: labelStyle
				}
				classNameInput={!inputDisabled ? (selectSearch ? `${styles['textField']} ${'ft-13'} ${classNameInput}` : `form-control form-select ${styles['textField'] + ' ' + styles['select-box'] + ' ' + classNameInput}`) : `${styles['textField'] + ' ' + styles['disabled'] + ' ' + styles['select-box'] + ' ' + classNameInput}`}
				labelValue={labelValue}
				name={name}
				value={value}
				placeholder={placeholder}
				setValue={setValue}
				inputStyle={inputStyle && inputStyle}
				optionList={optionList}
				listElement={listElement}
				listBtnElement={listBtnElement}
			/>
		</div>
	)
}
