import React, { useContext } from 'react'
import Switch from 'react-switch'
import GroupRow from '../../../../components/templates/GroupRow'
import GlobalStore from '../../../../store/GlobalStore'
import { validName } from '../../../../components/inputStyles/validateFunction'
import templatesContext from '../context/templates_context'

export default function TabOwnershipContact() {
	const { RootTemplate, LoadingState, OwnershipTemplate, handleChangeOwner, messageValid, BusinessTemplate } = useContext(templatesContext)

	return (
		<>
			<h5>Ownership and Contacts</h5>
			<p className="small mb-4 dark-grey">This section contains ownership information. Most of this information is required. Select which fields are visible and read only.</p>
			<h6 className="mb-3">Owners</h6>
			<div className="row p-1">
				<div className="col-md-6">
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ownername"
						inputType={{
							title: 'Owner Legal Name',
							type: 'mask',
							mask: GlobalStore.maskValidator('text'),
							TooltipValid: !LoadingState && !/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) && (OwnershipTemplate.ownername.value.trim().length === 0 && (!OwnershipTemplate.ownername.visible || OwnershipTemplate.ownername.readOnly) ? messageValid : !validName.test(OwnershipTemplate.ownername.value) && (!OwnershipTemplate.ownername.visible || OwnershipTemplate.ownername.readOnly) ? 'The field is for first and last name mandatorily' : ''),
							classNameInput: !LoadingState && !/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) && (OwnershipTemplate.ownername.value.trim().length === 0 || !validName.test(OwnershipTemplate.ownername.value)) && (!OwnershipTemplate.ownername.visible || OwnershipTemplate.ownername.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ownertitle"
						inputType={{ title: 'Title', type: 'mask', mask: GlobalStore.maskValidator('text') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ownerpercent"
						inputType={{
							title: 'Ownership %',
							type: 'number',
							mask: '',
							prefix: '',
							suffix: '%',
							maxValue: 100,
							decimalScale: 0
							//TooltipValid: !LoadingState && !/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) && (OwnershipTemplate.ownerpercent.value < 25 || [('0', 0, '', null)].includes(OwnershipTemplate.ownerpercent.value)) ? 'The minimum value is 25%' : '',
							//classNameInput: !LoadingState && !/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) && (OwnershipTemplate.ownerpercent.value < 25 || [('0', 0, '', null)].includes(OwnershipTemplate.ownerpercent.value)) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ownerssn"
						inputType={{ title: 'SSN', type: 'mask', mask: GlobalStore.maskValidator('ssn') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ownerdob"
						inputType={{ title: 'Date of Birth', type: 'dateBox', allowedNullDate: true, mask: '', maxDate: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay()) }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="oaddress"
						inputType={{
							title: 'Street Address',
							type: 'mask',
							mask: GlobalStore.maskValidator('text'),
							TooltipValid: !LoadingState && !/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) && OwnershipTemplate.oaddress.value.trim().length === 0 && (!OwnershipTemplate.oaddress.visible || OwnershipTemplate.oaddress.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && !/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) && OwnershipTemplate.oaddress.value.trim().length === 0 && (!OwnershipTemplate.oaddress.visible || OwnershipTemplate.oaddress.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ocity"
						inputType={{
							title: 'City',
							type: 'mask',
							mask: GlobalStore.maskValidator('alpha'),
							TooltipValid: !LoadingState && !/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) && OwnershipTemplate.ocity.value.trim().length === 0 && (!OwnershipTemplate.ocity.visible || OwnershipTemplate.ocity.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && !/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) && OwnershipTemplate.ocity.value.trim().length === 0 && (!OwnershipTemplate.ocity.visible || OwnershipTemplate.ocity.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
				<div className="col-md-6">
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ownerphone1"
						inputType={{
							title: 'Phone Number',
							type: 'mask',
							mask: GlobalStore.maskValidator('phone'),
							TooltipValid: !LoadingState && !/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) && OwnershipTemplate.ownerphone1.value.trim().length === 0 && (!OwnershipTemplate.ownerphone1.visible || OwnershipTemplate.ownerphone1.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && !/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) && OwnershipTemplate.ownerphone1.value.trim().length === 0 && (!OwnershipTemplate.ownerphone1.visible || OwnershipTemplate.ownerphone1.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ownerphone2"
						inputType={{ title: 'Additional Phone Number', type: 'mask', mask: GlobalStore.maskValidator('phone') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="owneremail"
						inputType={{
							title: 'Email Address',
							type: 'mask',
							mask: GlobalStore.maskValidator('email'),
							TooltipValid:
								!LoadingState &&
								!/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) &&
								(OwnershipTemplate.owneremail.value.trim().length === 0 && (!OwnershipTemplate.owneremail.visible || OwnershipTemplate.owneremail.readOnly)
									? messageValid
									: !GlobalStore.emailValidation(OwnershipTemplate.owneremail.value) && (!OwnershipTemplate.owneremail.visible || OwnershipTemplate.owneremail.readOnly)
									? 'The email is not valid'
									: OwnershipTemplate.owneremail.value.trim().length !== 0 && !GlobalStore.emailValidation(OwnershipTemplate.owneremail.value)
									? 'The email is not valid'
									: ''),
							classNameInput:
								!LoadingState &&
								!/Non-Profit Org|Government/.test(BusinessTemplate.btype.value) &&
								(OwnershipTemplate.owneremail.value.trim().length === 0 && (!OwnershipTemplate.owneremail.visible || OwnershipTemplate.owneremail.readOnly) ? 'input-error' : !GlobalStore.emailValidation(OwnershipTemplate.owneremail.value) && (!OwnershipTemplate.owneremail.visible || OwnershipTemplate.owneremail.readOnly) ? 'input-error' : OwnershipTemplate.owneremail.value.trim().length !== 0 && !GlobalStore.emailValidation(OwnershipTemplate.owneremail.value) ? 'input-error' : '')
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ownerdriver"
						inputType={{ title: "Driver's License #", type: 'mask', mask: GlobalStore.maskValidator('alphanumeric') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="odriverstate"
						inputType={{ title: "Driver's License State", type: 'select', mask: GlobalStore.getOptionsType('us_states') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ocountry"
						inputType={{ title: 'Country', type: 'select', mask: GlobalStore.getOptionsType('us_countries') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ostate"
						inputType={{ title: 'State', type: 'select', mask: GlobalStore.getStates(OwnershipTemplate.ocountry.value) }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="ozip"
						inputType={{ title: 'Zip', type: 'mask', mask: GlobalStore.maskValidator('zipcode') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
			</div>
			{(RootTemplate.isRoot || RootTemplate.rootTemplate.ownershipData.multipleOwners) && (
				<div className="mb-2">
					<div className="col">
						<label>
							<Switch
								onChange={(value) => handleChangeOwner('', 'multipleOwners', value, true)}
								checked={!LoadingState && OwnershipTemplate.multipleOwners}
								onColor="#10A0E3"
								onHandleColor="#ffffff"
								handleDiameter={20}
								boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
								height={20}
								width={38}
								className="react-switch"
								id={'multipleOwners_v'}
							/>
							<span className="ml-2 small">Offer to add multiple owners</span>
						</label>
					</div>
				</div>
			)}
			<h6 className="mb-3 mt-4">Contacts</h6>
			<div className="row">
				<div className="col-md-6">
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="contactName"
						inputType={{
							title: 'Contact Name',
							type: 'mask',
							mask: GlobalStore.maskValidator('text'),
							TooltipValid: !LoadingState && OwnershipTemplate.contactName.value.trim().length === 0 && (!OwnershipTemplate.contactName.visible || OwnershipTemplate.contactName.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && OwnershipTemplate.contactName.value.trim().length === 0 && (!OwnershipTemplate.contactName.visible || OwnershipTemplate.contactName.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="contactEmail"
						inputType={{
							title: 'Contact Email',
							type: 'mask',
							mask: GlobalStore.maskValidator('email'),
							TooltipValid:
								!LoadingState && OwnershipTemplate.contactEmail.value.trim().length === 0 && (!OwnershipTemplate.contactEmail.visible || OwnershipTemplate.contactEmail.readOnly)
									? messageValid
									: !LoadingState && !GlobalStore.emailValidation(OwnershipTemplate.contactEmail.value) && (!OwnershipTemplate.contactEmail.visible || OwnershipTemplate.contactEmail.readOnly)
									? 'The email is not valid'
									: !LoadingState && OwnershipTemplate.contactEmail.value.trim().length !== 0 && !GlobalStore.emailValidation(OwnershipTemplate.contactEmail.value)
									? 'The email is not valid'
									: '',
							classNameInput:
								!LoadingState && OwnershipTemplate.contactEmail.value.trim().length === 0 && (!OwnershipTemplate.contactEmail.visible || OwnershipTemplate.contactEmail.readOnly)
									? 'input-error'
									: !LoadingState && !GlobalStore.emailValidation(OwnershipTemplate.contactEmail.value) && (!OwnershipTemplate.contactEmail.visible || OwnershipTemplate.contactEmail.readOnly)
									? 'input-error'
									: !LoadingState && OwnershipTemplate.contactEmail.value.trim().length !== 0 && !GlobalStore.emailValidation(OwnershipTemplate.contactEmail.value)
									? 'input-error'
									: ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
				<div className="col-md-6">
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="contactTitle"
						inputType={{ title: 'Contact Title', type: 'mask', mask: GlobalStore.maskValidator('text') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && OwnershipTemplate}
						parentField={RootTemplate.rootTemplate.ownershipData}
						handleChange={(property, field, values) => handleChangeOwner(property, field, values)}
						field="contactPhone"
						inputType={{
							title: 'Contact Phone',
							type: 'mask',
							mask: GlobalStore.maskValidator('phone'),
							TooltipValid: !LoadingState && OwnershipTemplate.contactPhone.value.trim().length === 0 && (!OwnershipTemplate.contactPhone.visible || OwnershipTemplate.contactPhone.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && OwnershipTemplate.contactPhone.value.trim().length === 0 && (!OwnershipTemplate.contactPhone.visible || OwnershipTemplate.contactPhone.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
			</div>
			{(RootTemplate.isRoot || RootTemplate.rootTemplate.ownershipData.multipleContacts) && (
				<div className="mb-2">
					<div className="col">
						<label>
							<Switch
								onChange={(value) => handleChangeOwner('', 'multipleContacts', value, true)}
								checked={!LoadingState && OwnershipTemplate.multipleContacts}
								onColor="#10A0E3"
								onHandleColor="#ffffff"
								handleDiameter={20}
								boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
								height={20}
								width={38}
								className="react-switch"
								id={'multipleContacts_v'}
							/>
							<span className="ml-2 small">Offer to add multiple contacts</span>
						</label>
					</div>
				</div>
			)}
		</>
	)
}
