import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

@inject('store', 'global', 'bill')
@observer
class SendPaymentLinkModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            emails: '',
            emailsError: false,
          };
        this.handlePayBill = this.handlePayBill.bind(this);
        this.isValidAdditionalEmails = this.isValidAdditionalEmails.bind(this);
        this.handleEmails = this.handleEmails.bind(this);
        this.reSendPaymentLink = this.reSendPaymentLink.bind(this);
        this.sendPaymentLink = this.sendPaymentLink.bind(this);
    }

    isValidAdditionalEmails(){
        return this.props.global.validators.stringValidator('emailSemicolon',this.state.emails);
    }

    handleEmails(e) {
        this.setState({ emails: e.target.value });
    }
    

    async handlePayBill(){
        this.props.global.setLoading(true);
        if(this.state.emails !== '' && !this.isValidAdditionalEmails()){
            this.props.global.setLoading(false);
            this.setState({emailsError : true})
            return;
        }else{
            let vendorEmail = '';
            const { billToPay, bill } = this.props;
            if (billToPay?.Vendor?.Email) {
                vendorEmail = String(billToPay.Vendor.Email).toLowerCase();
            } else if (billToPay?.Vendor?.email) {
                vendorEmail = String(billToPay.Vendor.email).toLowerCase();
            } else if (bill?.vendorSelected?.Email) {
                vendorEmail = String(bill.vendorSelected.Email).toLowerCase();
            }
            let emails = (this.state.emails !== '' && this.isValidAdditionalEmails() ? this.state.emails : '');
            let emailsToSend = (vendorEmail + ";" + emails).replace(/\s/g, '');
            if(emailsToSend.at(-1) === ";"){
                emailsToSend = emailsToSend.slice(0, -1)
            }
            if (this.props.billToPay?.paylinkId) {
            if(this.props.handleSaveBill){
                this.props.handleSaveBill().then(res=>{
                    this.reSendPaymentLink(emailsToSend, this.props.billToPay, true);
                })
            }else{
                this.reSendPaymentLink(emailsToSend, this.props.billToPay);
            }            
            } else {
                let savedBillId = null;
                if(this.props.handleSaveBill){
                    savedBillId = await this.props.handleSaveBill(true);
                }
                if(savedBillId !== null){
                    const billData = await this.props.bill.loadBill(savedBillId);
                    this.sendPaymentLink(emailsToSend, billData);
                    if(this.props.functionCallBack){
                        this.props.functionCallBack()
                    }
                }else{                
                    this.sendPaymentLink(emailsToSend, this.props.billToPay);
                    if(this.props.functionCallBack){
                        this.props.functionCallBack()
                    }
                }
            }
        }
    }

      sendPaymentLink(emails,billToPay){
        this.props.bill.sendPaymentLink(billToPay, emails).then(() => {
            this.props.global.setLoading(false);
            this.setState({emailsError : false});
            toast.success("PaymentLink has been sent!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: 'toast-success-container',
            });
            if(this.props.functionCallBack){
              this.props.functionCallBack();
            }
            if(this.props.secondFunctionCallBack){
                this.props.secondFunctionCallBack();
            }
            this.props.closeSendPaymentLinkModal();
          }).catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data?.responseData?.explanation ? error.response.data?.responseData?.explanation : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
          });
      }


      reSendPaymentLink(emails,billToPay, noMessage){
        this.props.bill.reSendPaymentLink(billToPay, emails).then(() => {
            this.props.global.setLoading(false);
            this.setState({emailsError : false});
            if(noMessage !== true){
                toast.success("PaymentLink has been sent!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container',
                  });
            }
           
            if(this.props.functionCallBack){
              this.props.functionCallBack();
            }
            if(this.props.secondFunctionCallBack){
                this.props.secondFunctionCallBack();
            }
            this.props.closeSendPaymentLinkModal();
          }).catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data?.responseData?.explanation ? error.response.data?.responseData?.explanation : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
          });
      }

   

    render() {
        return (
            <>
             <Modal show={this.props.show} onHide={this.props.onHide} size="md" centered>
                <Modal.Body className="popover-body" id="modal-confirmation">
                <div className="row mb-4 underlined">
                    <div className="col">
                    <h4>Send payment link to vendor</h4>
                    </div>
                </div>

                <p className="description">
                Pay 
                {this.props.vendorName ?
                <b> {this.props.vendorName} </b>
                :
                <b>{(this.props.billToPay && this.props.billToPay.Vendor) ? ((this.props.billToPay.Vendor.Name1 ? this.props.billToPay.Vendor.Name1 : "") + " " + (this.props.billToPay.Vendor.Name2 ? this.props.billToPay.Vendor.Name2 : "")) : " Vendor "}</b> 
                }

                amount of
                {this.props.amountOf ?
                <> <b>${this.props.global.numberWithCommas(parseFloat(this.props.amountOf).toFixed(2))}</b></>
                :
                <> <b>${this.props.global.numberWithCommas(parseFloat((this.props.billToPay && this.props.billToPay.NetAmount) ? this.props.billToPay.NetAmount : 0).toFixed(2))}</b></>
                }
                </p>

                <p>
                Email on file
                {this.props.emailOnFile ?
                <>  - {String(this.props.emailOnFile).toLowerCase()} </>
                :
                <>  - {(this.props.billToPay && this.props.billToPay.Vendor && this.props.billToPay.Vendor.Email) ? String(this.props.billToPay.Vendor.Email).toLowerCase() : ""} </>
                }
                </p>

                <div className="row">
                    <div className="col-12">
                    <label htmlFor="invoice-emails">Include additional emails</label>
                    <input
                        id="invoice-emails"
                        value={this.state.emails}
                        onChange={(e) => this.handleEmails(e)}
                        type="text"
                        className= {this.state.emailsError === true ? "input-error form-control mb-3" : "form-control mb-3"}
                        placeholder="Add emails (optional) / separate by semicolon"
                    />
                    </div>
                </div>

                <div className="row mt-4">
                    <div id="modal-confirmation-buttons" className="col">
                    <button className="btn btn-light" type="button" onClick={() => this.props.closeSendPaymentLinkModal()}>
                        Back to editing
                    </button>
                    <button
                        className="btn btn-main btn-default btn-bordered-success"
                        type="button"
                        onClick={() => this.handlePayBill()}>
                        Send payment link
                    </button>
                    </div>
                </div>
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

export { SendPaymentLinkModal };