import React from 'react';
import { inject, observer } from 'mobx-react';
import {Link} from "react-router-dom";
import {BiChevronLeft} from '@react-icons/all-files/bi/BiChevronLeft';
import { PayabliStorageManager } from '../api/localStorageManager'

@inject('reports', 'global')
@observer
class BoardingLinks extends React.Component {

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        return (
            <div className="row">
                <div className="col-2">
                    <div className="hide-sm">
                        {this.props.goback &&
                            <a href={this.props.gobackHref} className="btn btn-light text-transform-normal"><BiChevronLeft /> {this.props.gobackText ? this.props.gobackText : "Go back"}</a>
                        }
                    </div>
                </div>
                <div className="col-8 text-center">
                    <div className="hide-sm">
                        {userPermissions.indexOf("Applications") !== -1 &&
                        <Link className={this.props.selected === 'applications'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/boarding/applications"} data-qaid="topBarMenuApplicationsLink">Applications</Link>
                        }
                        {userPermissions.indexOf("Templates") !== -1 &&
                        <Link className={this.props.selected === 'templates'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/boarding/templates"} data-qaid="topBarMenuTemplatesLink">Templates</Link>
                        }
                        {userPermissions.indexOf("Links") !== -1 &&
                        <Link className={this.props.selected === 'links' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/boarding/links"} data-qaid="topBarMenuBoardingLink">Boarding Links</Link>
                        }
                    </div>
                    <div className="hide show-sm sm-screen-topbar-menu">
                        {userPermissions.indexOf("Applications") !== -1 &&
                        <Link className={this.props.selected === 'applications' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/boarding/applications"}>Applications</Link>
                        }
                        {userPermissions.indexOf("Templates") !== -1 &&
                        <Link className={this.props.selected === 'templates' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/boarding/templates"}>Templates</Link>
                        }
                        {userPermissions.indexOf("Links") !== -1 &&
                        <Link className={this.props.selected === 'links' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/boarding/links"}>Boarding Links</Link>
                        }
                    </div>
                </div>
                <div className="col-2">
                </div>
        </div>
        )
    }
}

export { BoardingLinks };