import React, { useState, useContext } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import Switch from 'react-switch'
import { TopBar } from '../../../components/TopBar'
import StatusBar from '../../../components/commandCenter/StatusBar'
import { MainTopBarMenu } from '../../../components/MainTopBarMenu'
import PopUpNotify from '../modals/PopUpNotify'
import AddServices from '../modals/AddService/AddService'
import AddGateway from '../modals/AddService/AddGateway'
import AddTier from '../modals/AddTier'
import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft'
import ServiceType from './ServicesType'
import commandContext from '../context/commandCenter_context'

export default function MainServices({ closeService, changeTabServices, element, tabShow, addBankAccount }) {
	const { loadPaypointService, loadPaypointServiceName } = useContext(commandContext)
	const [showTab, setShowTab] = useState({
		moneyIn: tabShow === 'moneyIn' || tabShow === '' ? true : false,
		moneyOut: tabShow === 'moneyOut' ? true : false
	})
	const [expand, setExpand] = useState(true)
	const [showAddService, setShowAddService] = useState({ addService: false, addGateway: false, type: '' })
	const [showModal, setShowModal] = useState({ showMessage: false, message: '', message2: '', blueBtn: true, btnCaption: '', function: () => null })
	const [tierProperty, setTierProperty] = useState({ add: false, serviceType: '', type: '', indexService: 0, indexGateways: 0 })

	const loadService = async (serviceGroup = '', serviceName = '') => {
		serviceGroup && serviceName ? await loadPaypointServiceName('', '', serviceGroup, serviceName) : await loadPaypointService()
	}

	return (
		<>
			<TopBar>
				<MainTopBarMenu />
				<StatusBar SubTitleStatus="Manage your PayPoint easily within this Command Center" />
			</TopBar>
			<div className={styles['panel-Services']}>
				<div
					className={showTab.moneyIn ? styles['container-btn'] + ' ' + styles['margin-left-auto'] + ' ' + styles['margin-auto-screem-sm'] : styles['container-btn'] + ' ' + styles['margin-left-auto'] + ' ' + styles['margin-auto']}
					style={{ marginLeft: '50px' }}>
					<button
						onClick={() => closeService()}
						className={styles['btn']}>
						<BiChevronLeft className={styles['icon20']} />
						<label
							className={showTab.moneyIn ? styles['d-none-750'] + ' ' + styles['d-block-370'] : styles['d-none-750'] + ' ' + styles['d-block-680']}
							style={{ cursor: 'pointer' }}>
							Service Summary
						</label>
					</button>
				</div>

				<div className="d-flex flex-row flex-wrap align-items-center justify-content-center m-auto">
					<button
						onClick={() => changeTabServices('CreditCard') | setShowTab({ moneyIn: true, moneyOut: false })}
						className={showTab.moneyIn ? styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-blue'] : styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-gray']}
						style={{ fontSize: '16px', fontWeight: !showTab.moneyIn && '400' }}>
						Money In
					</button>
					<button
						onClick={() => changeTabServices('ManagedPayAbles') | setShowTab({ moneyIn: false, moneyOut: true })}
						className={showTab.moneyOut ? styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-blue'] : styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-gray']}
						style={{ fontSize: '16px', fontWeight: !showTab.moneyOut && '400' }}>
						Money Out
					</button>
				</div>
				<div className="d-flex flex-row flex-wrap align-items-center justify-content-center m-auto">
					<Switch
						checked={expand}
						onChange={(checked) => setExpand(checked)}
						uncheckedIcon={false}
						onColor={'#03a9f4'}
						height={16}
						width={32}
						checkedIcon={false}
						className="react-switch"
					/>
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-12']}
						style={{ color: '#212529', padding: '0 30px 0 10px' }}>
						EXPAND ALL
					</label>
					<button
						onClick={() => setShowAddService({ addService: true, addGateway: false, type: '' })}
						className={styles['btn'] + ' ' + styles['btn-blue']}>
						ADD SERVICE
					</button>
				</div>
			</div>

			<ServiceType
				element={element}
				changeTabServices={changeTabServices}
				showModal={showModal}
				setShowModal={setShowModal}
				setTierProperty={setTierProperty}
				setShowAddService={setShowAddService}
				expand={expand}
				loadService={(serviceName) => loadService(showTab.moneyIn ? 'moneyin' : 'moneyout', serviceName)}
				addBankAccount={addBankAccount}
				ServicesType={showTab.moneyIn ? 'moneyin' : 'moneyout'}
			/>
			{showAddService.addService && (
				<AddServices
					addBankAccount={addBankAccount}
					showAddService={showAddService.addService}
					sectionActive=""
					serviceTypeActive=""
					closeModal={() => setShowAddService({ addService: false, addGateway: false, type: '' }) | loadService()}
				/>
			)}
			{showAddService.addGateway && (
				<AddGateway
					addBankAccount={addBankAccount}
					showAddService={showAddService.addGateway}
					sectionActive={showTab.moneyIn ? 'moneyIn' : 'moneyOut'}
					serviceTypeActive={showAddService.type}
					closeModal={() => setShowAddService({ addService: false, addGateway: false, type: '' }) | loadService()}
				/>
			)}
			{tierProperty.add && (
				<AddTier
					tierProperty={tierProperty}
					closeModal={() => setTierProperty({ add: false, serviceType: '', type: '', indexService: 0, indexGateways: 0 })}
				/>
			)}

			{showModal.showMessage && (
				<PopUpNotify
					messageTitle="Notice!"
					message={showModal.message}
					message2={showModal.message2}
					btnCaption={showModal.btnCaption}
					cancelFunction={() => setShowModal({ showMessage: false, message: '', message2: '', blueBtn: true, btnCaption: '', function: () => null })}
					btnColor={showModal.blueBtn ? 'btn-blue' : 'btn-red'}
					btnFunctionOk={() => showModal.function()}
				/>
			)}
		</>
	)
}
