import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BiTrash } from 'react-icons/bi';

class ModalDelete extends React.Component {
  render() {
    return (
      <>
        <Modal
          style={{ textAlign: 'center' }}
          show={this.props.isOpen}
          onHide={this.props.closeModal}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <BiTrash className="icon-modal" />
            <h5 data-qaid="deleteInvoiceModal">Delete</h5>
            <p className="small">
              Are you sure you want to delete this invoice?
            </p>
            <Button
              className="btn cancel-btn"
              variant="default"
              onClick={this.props.closeModal}
              data-qaid="cancelButtonDeleteInvoiceMoneyIn"
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              className="btn"
              variant="danger"
              onClick={this.props.action}
            >
              Delete
            </Button>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export { ModalDelete };
