import React from 'react';
import { inject, observer } from 'mobx-react';
import { OverlayTrigger, Popover } from "react-bootstrap";

@inject('reports', 'filter')
@observer
class RenderPopoverPaymentTerms extends React.Component {

    render() {
        const popoverPaymentTerms = <Popover id="popover-payment-terms" style={{boxShadow: '0 0 0 0px', minWidth: '0'}}>
                                        <Popover.Content style={{minWidth: '0'}}>
                                            <div className='payment-terms'>                    
                                                {
                                                    (this.props.filter.filterPaymentTerms.paymentTermsCheckCustom) ? 
                                                    (<>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckCustom" id="paymentTermsCheckCustom" value="custom" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckCustom ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckCustom">Custom</label>
                                                        </div>
                                                        <div className="form-floating mt-2">
                                                            <input name="paymentTermsCheckCustomText" id="paymentTermsCheckCustomText" className={"form-control"} placeholder="Other" onChange={(e) => this.props.filter.handleCaptureTextFilter('payTerms(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('payTerms(eq)', e)} value={this.props.filter.filterText.paymentTermsCheckCustomText ? this.props.filter.filterText.paymentTermsCheckCustomText : ''} />
                                                            <label htmlFor="paymentTermsCheckCustomText">Custom</label>
                                                        </div>
                                                    </>)
                                                    :
                                                    (<>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckUr" id="paymentTermsCheckUr" value="ur" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckUr ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckUr">Due on receipt</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckNet10" id="paymentTermsCheckNet10" value="net10" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckNet10 ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckNet10">10 days after invoice date</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckNet20" id="paymentTermsCheckNet20" value="net20" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckNet20 ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckNet20">20 days after invoice date</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckNet30" id="paymentTermsCheckNet30" value="net30" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckNet30 ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckNet30">30 days after invoice date</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckNet45" id="paymentTermsCheckNet45" value="net45" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckNet45 ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckNet45">45 days after invoice date</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckNet60" id="paymentTermsCheckNet60" value="net60" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckNet60 ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckNet60">60 days after invoice date</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckNet90" id="paymentTermsCheckNet90" value="net90" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckNet90 ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckNet90">90 days after invoice date</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckEom" id="paymentTermsCheckEom" value="eom" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckEom ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckEom">Due end of this month</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckMfi" id="paymentTermsCheckMfi" value="mfi" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckMfi ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckMfi">1st of the following the voice date</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheck5mfi" id="paymentTermsCheck5mfi" value="5mfi" checked={this.props.filter.filterPaymentTerms.paymentTermsCheck5mfi ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheck5mfi">5st of the following the voice date</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheck10mfi" id="paymentTermsCheck10mfi" value="10mfi" checked={this.props.filter.filterPaymentTerms.paymentTermsCheck10mfi ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheck10mfi">10st of the following the voice date</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheck20mfi" id="paymentTermsCheck20mfi" value="20mfi" checked={this.props.filter.filterPaymentTerms.paymentTermsCheck20mfi ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheck20mfi">20st of the following the voice date</label>
                                                        </div>
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" name="paymentTermsCheckCustom" id="paymentTermsCheckCustom" value="custom" checked={this.props.filter.filterPaymentTerms.paymentTermsCheckCustom ? true : false} onChange={(e) => this.props.filter.handlePaymentTerms(e)} />
                                                            <label htmlFor="paymentTermsCheckCustom">Custom</label>
                                                        </div>
                                                    </>)
                                                }
                                            </div>
                                        </Popover.Content>
                                    </Popover>

        return (<>
                <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={ popoverPaymentTerms }>
                    <div className="form-floating">
                        <input 
                            id="paymentTerms" 
                            name="paymentTerms" 
                            readOnly 
                            autoComplete="off" 
                            className={"form-control"}
                            value={ this.props.filter.getTextPaymentTerms() }
                            placeholder="Payment Terms"
                            style={{ textOverflow: 'ellipsis' }}
                        />
                        <label htmlFor="paymentTerms">Payment Terms</label>
                    </div>
                </OverlayTrigger>
            </>);
    }
}

export { RenderPopoverPaymentTerms };