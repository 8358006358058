import React, { useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { BiInfoCircle, BiDollarCircle, BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { BiPauseCircle, BiUpArrowCircle, BiReceipt, BiWrench, BiBriefcase } from 'react-icons/bi';
import { TiArrowBackOutline } from 'react-icons/ti';
import { MdCallSplit } from 'react-icons/md';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import RSC from 'react-scrollbars-custom';
import PartialBtnBadgeReport from './PartialBtnBadgeReport';

function PartialViewQuickFilter({ global, reports, ctable }) {
	const { objectBatchRecord } = reports;
	const { BatchAuthAmount = 0, BatchSplitAmount = 0, Transfer } = objectBatchRecord || {};
	const {
		HoldAmount = 0,
		ReleasedAmount = 0,
		ChargeBackAmount = 0,
		ReturnedAmount = 0,
		BillingFeesAmount = 0,
		AdjustmentsAmount = 0,
		ThirdPartyPaidAmount = 0,
		NetFundedAmount = 0
	} = Transfer || {};
	const hasTransfer = Transfer ? true : false;
	const scrollContainerRef = useRef(null);

	const objectElementsFilter = {
		'category(eq)': null,
		'category(in)': null,
		'operation(eq)': null,
		'operation(in)': null,
		'isHold(eq)': null,
		'billingFeeAmount(ne)': null
	};

	const handleScroll = (direction) => {
		const scrollContainer = scrollContainerRef.current;

		if (scrollContainer) {
			const scrollAmount = 50;
			if (direction === 'left') {
				scrollContainer.scrollLeft -= scrollAmount;
			} else {
				scrollContainer.scrollLeft += scrollAmount;
			}
		}
	};

	const handleExcecuteQuickFilter = (typeFilter, params, elementSelected) => {
		const obj = objectElementsFilter;
		obj[typeFilter] = params;
		const filter = Object.assign({}, obj);
		ctable.getDataWithFilters(filter);
		ctable.setElementSelected(elementSelected);
	};

	const resetCategoryFilter = () => {
		const filter = Object.assign({}, objectElementsFilter);
		ctable.resetFlagParamCategoryFilter();
		ctable.getDataWithFilters(filter);
		ctable.setElementSelected(null);
	};

	const setVisibleColumnsBillingFees = () => {
		handleExcecuteQuickFilter('billingFeeAmount(ne)', '0');
		ctable.setVisibleColumnsBillingFees();
	};

	const buttonsData = [
		/* {
			classCircle: 'bg-danger',
			icon: <RiRefund2Line />,
			textTitle: 'Refunds',
			textSubTitle: global.numberWithCommasNegative(RefundsHoldAmount.toFixed(2)),
			isBadge: true,
			isInfoIcon: true,
			infoTextTooltip: 'Refunds deducted from batch.',
			isElementActive: ctable.flagParamCategoryFilter === 'refund',
			fnProps: () => {
				handleExcecuteQuickFilter(hasTransfer ? 'category(eq)' : 'operation(eq)', 'refund');
			}
		}, */
		{
			classCircle: 'bg-secondary',
			icon: <TiArrowBackOutline />,
			textTitle: 'Chargebacks',
			textSubTitle: global.numberWithCommasNegative(ChargeBackAmount?.toFixed(2)),
			isBadge: true,
			isInfoIcon: true,
			infoTextTooltip: 'Chargebacks deducted from batch.',
			isElementActive: ctable.flagParamCategoryFilter === 'chargeback',
			isNotAllowed: !hasTransfer,
			fnProps: () => {
				hasTransfer && handleExcecuteQuickFilter('category(eq)', 'chargeback', 'Chargebacks');
			}
		},
		{
			classCircle: 'bg-secondary',
			icon: <TiArrowBackOutline />,
			textTitle: 'ACH Returns',
			textSubTitle: global.numberWithCommasNegative(ReturnedAmount?.toFixed(2)),
			isBadge: true,
			isInfoIcon: true,
			infoTextTooltip: 'ACH returns deducted from batch.',
			isElementActive: ctable.flagParamCategoryFilter === 'return',
			isNotAllowed: !hasTransfer,
			fnProps: () => {
				hasTransfer && handleExcecuteQuickFilter('category(eq)', 'return', 'ACH Returns');
			}
		},
		{
			classCircle: 'bg-info',
			dataQaid: 'billingFeesBatchTransferMoneyIn',
			icon: <BiReceipt />,
			textTitle: 'Billing & Fees',
			textSubTitle: global.numberWithCommasNegative(BillingFeesAmount?.toFixed(2)),
			isBadge: true,
			isInfoIcon: true,
			infoTextTooltip: 'Charges applied for transactions and services.',
			isElementActive: ctable.flagParamCategoryFilter === 'billingFees',
			isNotAllowed: !hasTransfer,
			fnProps: () => {
				hasTransfer && setVisibleColumnsBillingFees();
			}
		},
		{
			classCircle: 'bg-warning',
			icon: <MdCallSplit style={{ transform: 'rotate(90deg)' }} />,
			textTitle: 'Split Funds',
			textSubTitle: global.numberWithCommasNegative(BatchSplitAmount?.toFixed(2)),
			isBadge: true,
			isInfoIcon: true,
			isNotAllowed: !hasTransfer,
			isElementActive: ctable.flagParamCategoryFilter === 'split',
			infoTextTooltip:
				'Total of split transactions that included split funding instructions at the time of authorization.',
			fnProps: () => {
				hasTransfer && handleExcecuteQuickFilter('category(eq)', 'split', 'Split Funds');
			}
		},
		{
			classCircle: 'bg-danger',
			icon: <BiWrench />,
			textTitle: 'Adjustments',
			textSubTitle: global.numberWithCommasNegative(AdjustmentsAmount?.toFixed(2)),
			isBadge: true,
			isInfoIcon: true,
			infoTextTooltip: 'Corrections applied to Billing & Fees charges.',
			isNotAllowed: !hasTransfer
		},
		{
			classCircle: 'bg-info',
			icon: <BiBriefcase />,
			textTitle: 'Third Party Paid',
			textSubTitle: global.numberWithCommasNegative(ThirdPartyPaidAmount?.toFixed(2)),
			isBadge: true,
			isInfoIcon: true,
			infoTextTooltip:
				'Payments captured in the batch cycle that are deposited separately. For example, checks or cash payments recorded in the batch but not deposited via Payabli, or card brands making a direct transfer in certain situations.',
			isNotAllowed: !hasTransfer
		},
		{
			classCircle: 'bg-warning',
			icon: <BiPauseCircle />,
			textTitle: 'Held Amount',
			textSubTitle: global.numberWithCommasNegative(HoldAmount?.toFixed(2)),
			isBadge: true,
			isInfoIcon: true,
			isNotAllowed: !hasTransfer,
			infoTextTooltip: 'Funds being held for fraud or risk concerns.',
			isElementActive:
				ctable.flagParamCategoryFilter === 'hold' || ctable.flagParamCategoryFilter === '1',
			fnProps: () => {
				handleExcecuteQuickFilter(
					hasTransfer ? 'category(eq)' : 'isHold(eq)',
					hasTransfer ? 'hold' : '1',
					'Held Amount'
				);
			}
		},
		{
			classCircle: 'bg-success',
			icon: <BiUpArrowCircle />,
			textTitle: 'Release Amount',
			textSubTitle: global.numberWithCommasNegative(ReleasedAmount?.toFixed(2)),
			isBadge: true,
			isInfoIcon: true,
			infoTextTooltip: 'Previously held funds that have been released after a risk review.',
			isElementActive: ctable.flagParamCategoryFilter === 'released',
			isNotAllowed: !hasTransfer,
			fnProps: () => {
				hasTransfer && handleExcecuteQuickFilter('category(eq)', 'released', 'Release Amount');
			}
		}
	];

	const listButtons = () => {
		return (
			<div className='d-flex'>
				{buttonsData.map((button, index) => (
					<React.Fragment key={index}>
						<PartialBtnBadgeReport {...button} />
					</React.Fragment>
				))}
			</div>
		);
	};

	return (
		<div className='slide-view-quick-filter mb-2'>
			<div className='d-flex'>
				<p className='mr-3 mb-0'>
					Transfer Details
					<OverlayTrigger
						placement='top'
						overlay={<Tooltip>Important details related to this transfer.</Tooltip>}>
						<BiInfoCircle className='icon-info-text' />
					</OverlayTrigger>
				</p>
				<p className='mb-0'>
					{(Transfer?.TransferStatus || Transfer?.TransferStatus === 0) && (
						<>STATUS {global.getTransferStatus(Transfer.TransferStatus)}</>
					)}
				</p>
			</div>
			<div
				className='d-flex'
				style={{ width: '100%' }}>
				<div className='scroll-buttons'>
					<span
						className='btn-scroll-left'
						onClick={() => handleScroll('left')}>
						<BiChevronLeft />
					</span>
				</div>
				<PartialBtnBadgeReport
					isBadge={true}
					classCircle={'bg-success'}
					icon={<BiDollarCircle />}
					textTitle={'Net Batch'}
					isInfoIcon={true}
					textSubTitle={
						<>
							{BatchAuthAmount >= 0 && '+ '}
							{global.numberWithCommasNegative(BatchAuthAmount.toFixed(2))}
						</>
					}
					infoTextTooltip={'Net batch is the gross batch amount minus service fees.'}
					isElementActive={ctable.flagParamCategoryFilter === 'refund|sale|auth'}
					fnProps={() => {
						handleExcecuteQuickFilter(
							hasTransfer ? 'category(in)' : 'operation(in)',
							'refund|sale|auth',
							'Net Batch'
						);
					}}
				/>
				<RSC
					id='scrollQuickFilter'
					ref={scrollContainerRef}>
					{listButtons()}
				</RSC>
				<span style={{ marginRight: '4px', marginLeft: '4px', marginTop: '20px' }}>=</span>
				<PartialBtnBadgeReport
					isElementActive={!ctable.flagParamCategoryFilter}
					fnProps={resetCategoryFilter}
					isBadge={true}
					textTitle={'Transfer Amount'}
					isInfoIcon={true}
					textSubTitle={global.numberWithCommasNegative(NetFundedAmount.toFixed(2))}
					infoTextTooltip={
						'Payments captured in the batch cycle that are deposited separately. For example, checks or cash payments recorded in the batch but not deposited via Payabli, or card brands making a direct transfer in certain situations.'
					}
				/>
				<div className='scroll-buttons'>
					<span
						className='btn-scroll-right'
						onClick={() => handleScroll('right')}>
						<BiChevronRight />
					</span>
				</div>
			</div>
		</div>
	);
}

export default inject('global', 'reports', 'ctable')(observer(PartialViewQuickFilter));
