import React, { useContext } from 'react'
import GroupRow from '../../../../components/templates/GroupRow'
import GlobalStore from '../../../../store/GlobalStore'
import templatesContext from '../context/templates_context'

export default function TabBusinessDetails() {
	const { RootTemplate, LoadingState, handleChangeBusiness, BusinessTemplate, messageValid } = useContext(templatesContext)
	return (
		<>
			<h5>Business Details</h5>
			<p className="small mb-4 dark-grey">This section contains business information. Most of this information is required. Select which fields are visible and read only. </p>
			<div className="row p-1">
				<div className="col-md-6">
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="dbaname"
						inputType={{ title: 'Business DBA Name', type: 'mask', mask: GlobalStore.maskValidator('text'), TooltipValid: !LoadingState && BusinessTemplate.dbaname.value.trim().length === 0 && (!BusinessTemplate.dbaname.visible || BusinessTemplate.dbaname.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.dbaname.value.trim().length === 0 && (!BusinessTemplate.dbaname.visible || BusinessTemplate.dbaname.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="legalname"
						inputType={{ title: 'Business Legal Name', type: 'mask', mask: GlobalStore.maskValidator('text'), TooltipValid: !LoadingState && BusinessTemplate.legalname.value.trim().length === 0 && (!BusinessTemplate.legalname.visible || BusinessTemplate.legalname.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.legalname.value.trim().length === 0 && (!BusinessTemplate.legalname.visible || BusinessTemplate.legalname.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="btype"
						inputType={{ title: 'Ownership Type', type: 'select', mask: GlobalStore.getOptionsType('btype') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="website"
						inputType={{ title: 'WebSite', type: 'mask', mask: GlobalStore.maskValidator('text'), TooltipValid: !LoadingState && BusinessTemplate.website.value.trim().length === 0 && (!BusinessTemplate.website.visible || BusinessTemplate.website.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.website.value.trim().length === 0 && (!BusinessTemplate.website.visible || BusinessTemplate.website.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="ein"
						inputType={{ title: 'EIN', type: 'mask', mask: GlobalStore.maskValidator('ein') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="taxfillname"
						inputType={{ title: 'Tax Filing Name', type: 'mask', mask: GlobalStore.maskValidator('text') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
				<div className="col-md-6">
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="license"
						inputType={{ title: 'License Number', type: 'mask', mask: GlobalStore.maskValidator('alphanumeric') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="licstate"
						inputType={{ title: 'Biz License State', type: 'select', mask: GlobalStore.getOptionsType('us_states') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="startdate"
						inputType={{ title: 'Business Start Date', type: 'dateBox', allowedNullDate: true, mask: '', maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="phonenumber"
						inputType={{ title: 'Phone Number', type: 'mask', mask: GlobalStore.maskValidator('phone') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="faxnumber"
						inputType={{ title: 'Fax Number', type: 'mask', mask: GlobalStore.maskValidator('phone') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
			</div>
			<hr className="mt-3" />
			<div className="row">
				<div className="col-md-6">
					<h6 className="card-title">Business Address</h6>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="baddress"
						inputType={{ title: 'Street Address', type: 'mask', mask: GlobalStore.maskValidator('text'), TooltipValid: !LoadingState && BusinessTemplate.baddress.value.trim().length === 0 && (!BusinessTemplate.baddress.visible || BusinessTemplate.baddress.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.baddress.value.trim().length === 0 && (!BusinessTemplate.baddress.visible || BusinessTemplate.baddress.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="baddress1"
						inputType={{ title: 'Address 2', type: 'mask', mask: GlobalStore.maskValidator('text') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="bcity"
						inputType={{ title: 'City', type: 'mask', mask: GlobalStore.maskValidator('alpha'), TooltipValid: !LoadingState && BusinessTemplate.bcity.value.trim().length === 0 && (!BusinessTemplate.bcity.visible || BusinessTemplate.bcity.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.bcity.value.trim().length === 0 && (!BusinessTemplate.bcity.visible || BusinessTemplate.bcity.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="bcountry"
						inputType={{ title: 'Country', type: 'select', mask: GlobalStore.getOptionsType('us_countries'), TooltipValid: !LoadingState && BusinessTemplate.bcountry.value.trim().length === 0 && (!BusinessTemplate.bcountry.visible || BusinessTemplate.bcountry.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.bcountry.value.trim().length === 0 && (!BusinessTemplate.bcountry.visible || BusinessTemplate.bcountry.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="bstate"
						inputType={{ title: 'State', type: 'select', mask: GlobalStore.getStates(BusinessTemplate.bcountry.value), TooltipValid: !LoadingState && BusinessTemplate.bstate.value.trim().length === 0 && (!BusinessTemplate.bstate.visible || BusinessTemplate.bstate.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.bstate.value.trim().length === 0 && (!BusinessTemplate.bstate.visible || BusinessTemplate.bstate.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="bzip"
						inputType={{ title: 'Zip', type: 'mask', mask: GlobalStore.maskValidator('zipcode'), TooltipValid: !LoadingState && BusinessTemplate.bzip.value.trim().length === 0 && (!BusinessTemplate.bzip.visible || BusinessTemplate.bzip.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.bzip.value.trim().length === 0 && (!BusinessTemplate.bzip.visible || BusinessTemplate.bzip.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
				<div className="col-md-6">
					<h6 className="card-title">Mail Address</h6>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="maddress"
						inputType={{ title: 'Street Address', type: 'mask', mask: GlobalStore.maskValidator('text'), TooltipValid: !LoadingState && BusinessTemplate.maddress.value.trim().length === 0 && (!BusinessTemplate.maddress.visible || BusinessTemplate.maddress.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.maddress.value.trim().length === 0 && (!BusinessTemplate.maddress.visible || BusinessTemplate.maddress.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="maddress1"
						inputType={{ title: 'Address 2', type: 'mask', mask: GlobalStore.maskValidator('text') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="mcity"
						inputType={{ title: 'City', type: 'mask', mask: GlobalStore.maskValidator('alpha'), TooltipValid: !LoadingState && BusinessTemplate.mcity.value.trim().length === 0 && (!BusinessTemplate.mcity.visible || BusinessTemplate.mcity.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.mcity.value.trim().length === 0 && (!BusinessTemplate.mcity.visible || BusinessTemplate.mcity.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="mcountry"
						inputType={{ title: 'Country', type: 'select', mask: GlobalStore.getOptionsType('us_countries'), TooltipValid: !LoadingState && BusinessTemplate.mcountry.value.trim().length === 0 && (!BusinessTemplate.mcountry.visible || BusinessTemplate.mcountry.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.mcountry.value.trim().length === 0 && (!BusinessTemplate.mcountry.visible || BusinessTemplate.mcountry.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="mstate"
						inputType={{ title: 'State', type: 'select', mask: GlobalStore.getStates(BusinessTemplate.mcountry.value), TooltipValid: !LoadingState && BusinessTemplate.mstate.value.trim().length === 0 && (!BusinessTemplate.mstate.visible || BusinessTemplate.mstate.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.mstate.value.trim().length === 0 && (!BusinessTemplate.mstate.visible || BusinessTemplate.mstate.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && BusinessTemplate}
						parentField={RootTemplate.rootTemplate.businessData}
						handleChange={(property, field, values) => handleChangeBusiness(property, field, values)}
						field="mzip"
						inputType={{ title: 'Zip', type: 'mask', mask: GlobalStore.maskValidator('zipcode'), TooltipValid: !LoadingState && BusinessTemplate.mzip.value.trim().length === 0 && (!BusinessTemplate.mzip.visible || BusinessTemplate.mzip.readOnly) ? messageValid : '', classNameInput: !LoadingState && BusinessTemplate.mzip.value.trim().length === 0 && (!BusinessTemplate.mzip.visible || BusinessTemplate.mzip.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
			</div>
		</>
	)
}
