import React, { useContext } from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import RowContainer from '../RowContainer'
import GlobalStore from '../../../store/GlobalStore'
import commandContext from '../../../views/commandCenter/context/commandCenter_context'

export default function PayMethodAccepted({ service, indexService, indexGateways, serviceName, inputDisabled, payWeb = false }) {
	const { handleChangeServices } = useContext(commandContext)
	return (
		<div className="d-flex flex-column w-100 pt-3">
			{/* quitar pt-3 */}
			<div className={styles['grid-container']}>
				{/* <div className="d-flex flex-column align-items-start p-0">
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-13']}
						style={{
							fontWeight: 600,
							lineHeight: '20px',
							letterSpacing: '0.0025em',
							width: '180px',
							minWidth: '20px',
							paddingBottom: '12px'
						}}>
						Pay Method Accepted
					</label>

					{!payWeb ? (
						<div
							className="d-flex flex-wrap flex-row align-items-center w-100"
							style={{ padding: '0 0 12px', gap: '25px' }}>
							<RowContainer
								//setValue={(checked) => handleChangeServices(service.Gateways[indexGateways].Id, 'payMtVisa', checked)}
								defaultChecked={service.payMtVisa}
								checkDisabled={inputDisabled}
								name={'checkedVisa[' + service.Gateways[indexGateways].Id + serviceName + indexK + '-' + indexService + '-' + indexGateways + ']'}
								labelValue="Visa"
							/>
							<RowContainer
								//setValue={(checked) => handleChangeServices(service.Gateways[indexGateways].Id, 'payMtVisa', checked)}
								defaultChecked={service.payMtMastercard}
								checkDisabled={inputDisabled}
								name={'checkedMastercard[' + service.Gateways[indexGateways].Id + serviceName + indexK + '-' + indexService + '-' + indexGateways + ']'}
								labelValue="Mastercard"
							/>
							<RowContainer
								//setValue={(checked) => handleChangeServices(service.Gateways[indexGateways].Id, 'payMtVisa', checked)}
								defaultChecked={service.payMtDiscover}
								checkDisabled={inputDisabled}
								name={'checkedDiscover[' + service.Gateways[indexGateways].Id + serviceName + indexK + '-' + indexService + '-' + indexGateways + ']'}
								labelValue="Discover"
							/>
							<RowContainer
								//setValue={(checked) => handleChangeServices(service.Gateways[indexGateways].Id, 'payMtVisa', checked)}
								defaultChecked={service.payMtAmex}
								checkDisabled={inputDisabled}
								name={'checkedAmex[' + service.Gateways[indexGateways].Id + serviceName + indexK + '-' + indexService + '-' + indexGateways + ']'}
								labelValue="Amex"
							/>
						</div>
					) : (
						<div
							className="d-flex flex-wrap flex-row align-items-center w-100"
							style={{ padding: '0 0 12px', gap: '25px' }}>
							<RowContainer
								//setValue={(checked) => handleChangeServices(service.Gateways[indexGateways].Id, 'payMtVisa', checked)}
								defaultChecked={service.payMtWeb}
								checkDisabled={inputDisabled}
								name={'checkedWeb[' + service.Gateways[indexGateways].Id + serviceName + indexK + '-' + indexService + '-' + indexGateways + ']'}
								labelValue="WEB"
							/>
							<RowContainer
								//setValue={(checked) => handleChangeServices(service.Gateways[indexGateways].Id, 'payMtVisa', checked)}
								defaultChecked={service.payMtPPD}
								checkDisabled={inputDisabled}
								name={'checkedPPD[' + service.Gateways[indexGateways].Id + serviceName + indexK + '-' + indexService + '-' + indexGateways + ']'}
								labelValue="PPD"
							/>
							<RowContainer
								//setValue={(checked) => handleChangeServices(service.Gateways[indexGateways].Id, 'payMtVisa', checked)}
								defaultChecked={service.payMtCCD}
								checkDisabled={inputDisabled}
								name={'checkedCCD[' + service.Gateways[indexGateways].Id + serviceName + indexK + '-' + indexService + '-' + indexGateways + ']'}
								labelValue="CCD"
							/>
						</div>
					)}
				</div> */}
				<div className={styles['grid-container']}>
					<div className="d-flex flex-column align-items-start p-0">
						<RowContainer
							inputDisabled={inputDisabled}
							labelValue="Funding Rollup"
							name={'FundingRollUp[' + service.Gateways[indexGateways].Id + ']'}
							value={service.Gateways[indexGateways].FundingRollUp}
							setValue={(values) => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'FundingRollUp', values)}
							optionList={
								<>
									<option value="0">Net Fees and Deposits</option>
									<option value="1">Separate Fees and Deposits</option>
								</>
							}
						/>

						<RowContainer
							inputType="mask"
							inputDisabled={inputDisabled}
							labelValue="Payment Descriptor"
							name={'paymentDescriptor[' + service.Gateways[indexGateways].Id + ']'}
							placeholder="Value"
							value={service.Gateways[indexGateways].Connector.Descriptor}
							setValue={(values) => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'Descriptor', values, true)}
							mask={GlobalStore.maskValidator('text')}
							maxLength={30}
						/>

						<div
							className="d-flex flex-row justify-content-start flex-wrap align-items-center w-100"
							style={{ padding: '0 0 12px', gap: '8px' }}>
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-13']}
								style={{
									fontWeight: '600',
									lineHeight: !inputDisabled ? '36px' : '22px',
									minWidth: '180px',
									letterSpacing: '0.0025em'
								}}>
								Currency
							</label>
							{!inputDisabled ? (
								<div className="d-flex flex-row justify-content-start flex-nowrap align-items-center p-0 gap-5">
									<RowContainer
										inputType="Radio"
										inputDisabled={inputDisabled}
										labelValue="USD"
										name="currencyUS"
										value={service.Gateways[indexGateways].Currencies[0] === 'USD' ? true : false}
										setValue={() => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, '0', 'USD', false, true, 0)}
									/>
									<RowContainer
										inputType="Radio"
										inputDisabled={inputDisabled}
										labelValue="CAD"
										name="currencyCAD"
										value={service.Gateways[indexGateways].Currencies[0] === 'CAD' ? true : false}
										setValue={() => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, '0', 'CAD', false, true, 0)}
									/>
								</div>
							) : (
								<RowContainer
									inputType="Label"
									labelStyle={{
										lineHeight: !inputDisabled && '40px',
										minWidth: '180px',
										letterSpacing: '0.0025em'
									}}
									labelValue={service.Gateways[indexGateways].Currencies[0] === 'USD' ? 'United States Dollar (USD)' : 'Canadian Dollar (CAD)'}
								/>
							)}
						</div>

						<RowContainer
							inputType="number"
							iCheck={true}
							inputDisabled={true} //{inputDisabled}
							classNameInput={!inputDisabled && service.Gateways[indexGateways].Connector.configuration !== null && service.Gateways[indexGateways].Connector.configuration.enableCreditCapability && (parseFloat(service.Gateways[indexGateways].Connector.configuration.creditLimit) === 0 || service.Gateways[indexGateways].Connector.configuration.creditLimit === null) ? 'is-invalid' : ''}
							labelValue="Credit (Opt.)"
							checkName={'enableCreditCapability[' + service.Gateways[indexGateways].Id + ']'}
							name={'creditLimit[' + service.Gateways[indexGateways].Id + ']'}
							placeholder="Limit $0.00"
							value={service.Gateways[indexGateways].Connector.configuration.creditLimit}
							checkValue={service.Gateways[indexGateways].Connector.configuration.enableCreditCapability}
							//setValue={(values) => handleChangeServices(service.ServiceGroup,serviceName,indexService,service.Gateways[indexGateways].Id,'creditLimit',values === null || !values ? 0 : values,false,false,0,false,'',0,false,false,false,true,true)}
							//setCheckValue={(values) => handleChangeServices(service.ServiceGroup,serviceName,indexService,service.Gateways[indexGateways].Id,'enableCreditCapability',values,false,false,0,false,'',0,false,false,false,true,true)}
							maxLength={18}
						/>
					</div>
					<div className="d-flex flex-column align-items-start p-0">
						<RowContainer
							inputDisabled={inputDisabled}
							labelValue="Funding Batch Preference"
							name={'FundingBatchPreference[' + service.Gateways[indexGateways].Id + ']'}
							value={service.Gateways[indexGateways].FundingBatchPreference}
							setValue={(values) => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'FundingBatchPreference', values)}
							optionList={
								<>
									<option value="0">Separated</option>
									<option value="1">Combined</option>
								</>
							}
						/>

						<RowContainer
							inputDisabled={inputDisabled}
							labelValue="Funding Hold Days"
							name={'FundingHoldingDays[' + service.Gateways[indexGateways].Id + ']'}
							value={service.Gateways[indexGateways].FundingHoldingDays === null ? 1 : service.Gateways[indexGateways].FundingHoldingDays}
							setValue={(values) => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'FundingHoldingDays', values)}
							optionList={
								<>
									<option value="0">0</option>
									<option
										value="1"
										defaultValue>
										1
									</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
									<option value="7">7</option>
									<option value="8">8</option>
									<option value="9">9</option>
									<option value="10">10</option>
								</>
							}
						/>

						<RowContainer
							inputDisabled={inputDisabled}
							labelValue="Funding Mode"
							name={'FundingMode[' + service.Gateways[indexGateways].Id + ']'}
							value={service.Gateways[indexGateways].FundingMode}
							setValue={(values) => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'FundingMode', values)}
							optionList={
								<>
									<option value="0">Net</option>
									<option value="1">Gross</option>
								</>
							}
						/>

						{service.Gateways[indexGateways].Connector && service.Gateways[indexGateways].Connector.configuration.supportSplitFunding && service.Gateways[indexGateways].Connector.Name.toUpperCase() === 'GP' && (
							<RowContainer
								inputType="Checkbox"
								divStyle={{ marginRight: '10px' }}
								value={service.Gateways[indexGateways].Connector ? service.Gateways[indexGateways].Connector.configuration.enableSplitFunding : false}
								inputDisabled={inputDisabled}
								name={'enableSplitFunding[' + service.Gateways[indexGateways].Id + ']'}
								labelValue="Enable Split Funding"
								setValue={(values) => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'enableSplitFunding', values, false, false, 0, false, '', 0, false, false, false, true, true)}
							/>
						)}

						{service.ServiceGroup === 'moneyin' && serviceName === 'ach' && (
							<RowContainer
								inputType="Checkbox"
								divStyle={{ marginRight: '10px' }}
								inputDisabled={inputDisabled}
								labelStyle={{ fontWeight: 600 }}
								name={'EnableACHValidation[' + service.Gateways[indexGateways].Id + ']'}
								labelValue="Enable ACH verification"
								value={service.Gateways[indexGateways].Connector.EnableACHValidation}
								setValue={(values) => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'EnableACHValidation', values, true)}
							/>
						)}
					</div>
				</div>
			</div>

			{service.Gateways[indexGateways].Connector && (service.Gateways[indexGateways].Connector.configuration.supportUniversalToken || service.Gateways[indexGateways].Connector.configuration.supportNetworkToken) && (
				<div className="d-flex flex-column gap-2 mt-2 mb-4">
					<label
						className={styles['label-form'] + ' ' + styles['ft-s-13']}
						style={{
							fontWeight: 600,
							lineHeight: '20px',
							letterSpacing: '0.0025em',
							width: '180px',
							minWidth: '20px'
						}}>
						Tokens
					</label>

					<div className="d-flex flex-row flex-wrap gap-2">
						{service.Gateways[indexGateways].Connector && service.Gateways[indexGateways].Connector.configuration.supportUniversalToken && (
							<RowContainer
								inputType="Checkbox"
								divStyle={{ marginRight: '30px' }}
								value={service.Gateways[indexGateways].Connector ? service.Gateways[indexGateways].Connector.configuration.enableUniversalToken : false}
								inputDisabled={inputDisabled}
								name={'enableUniversalToken[' + service.Gateways[indexGateways].Id + ']'}
								labelValue="Enable Universal Token"
								setValue={(values) => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'enableUniversalToken', values, false, false, 0, false, '', 0, false, false, false, true, true)}
							/>
						)}
						{service.Gateways[indexGateways].Connector && service.Gateways[indexGateways].Connector.configuration.supportNetworkToken && (
							<RowContainer
								inputType="Checkbox"
								divStyle={{ marginRight: '10px' }}
								value={service.Gateways[indexGateways].Connector ? service.Gateways[indexGateways].Connector.configuration.enableNetworkToken : false}
								inputDisabled={inputDisabled}
								name={'enableNetworkToken[' + service.Gateways[indexGateways].Id + ']'}
								labelValue="Enable Network Token"
								setValue={(values) => handleChangeServices(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'enableNetworkToken', values, false, false, 0, false, '', 0, false, false, false, true, true)}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	)
}
