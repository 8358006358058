import React from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown } from 'react-bootstrap';
import {FaSearch} from 'react-icons/fa'
import {BiX} from 'react-icons/bi'
@inject('reports', 'store', 'global')
@observer
class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            value:'', 
            focusedIndex: 0, 
            filters: [],
            items: []
        }
        this.inputRef = React.createRef()
        this.handleSelect = this.handleSelect.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onSearchKeydown = this.onSearchKeydown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleRemoveClick = this.handleRemoveClick.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);

    }

    componentDidMount() {
        let filters = this.props.global.globalSearchbarFilters
        if(filters) {
            this.setState({items: Object.values(filters)})
        }

    }

    removeItem(index) {
        let items = this.state.items
        if(items[index] && items[index].values){
            this.props.reports.removeFilter(items[index].filter+'(in)')
            this.props.reports.removeFilter(items[index].filter)
            this.props.global.setLoading(true);
            this.props.reports.getReportOrgFromApi(this.props.report).then(res => {
                this.props.global.setLoading(false);
            })
            .catch(error => {
                this.props.global.setLoading(false);
            });
            items.splice(index, 1)
            this.setState({ items: items })
        }
    }
    handleRemoveClick(e, index) {
        this.removeItem(index)
    }
    handleMouseMove(e, index) {
        this.setState({ focusedIndex: index });
        this.inputRef.current.focus();
    }
    handleSelect(e) {
        this.setState({value: e.target.value});
    }

    onSearchKeydown(e) {
        let focusedIndex;
        if(e.key === 'Backspace'){
            let items = this.state.items
            if(items.length > 0 && this.state.value === '')
               this.removeItem(items.length - 1)
        }
        else if(e.key === 'ArrowDown'){
            e.preventDefault()
            let filters = []
            let headers = this.props.reports.getHeaders
            headers.map((header, i) => {
                if (header[1].filter)
                    filters.push(header[1].filter)
                return header
            })
            if (this.state.focusedIndex >= filters.length - 1) {
                focusedIndex = 0;
            } else {
                focusedIndex = this.state.focusedIndex + 1;
            }
            this.setState({focusedIndex: focusedIndex})
        }
        else if(e.key === 'ArrowUp'){
            e.preventDefault()
            let filters = []
            let headers = this.props.reports.getHeaders
            headers.map((header, i) => {
                if (header[1].filter)
                    filters.push(header[1].filter)
                return headers
            })
            if (this.state.focusedIndex === 0 || this.state.focusedIndex > filters.length - 1) {
                focusedIndex = filters.length - 1;
            } else {
                focusedIndex = this.state.focusedIndex - 1;
            }
            this.setState({focusedIndex: focusedIndex})
        }
        else if(e.key === 'Enter'){
            if(this.state.focusedIndex >= 0){
                e.preventDefault()
                this.selectItem()
            }
        }
    }

    handleBlur(e) {
        if(e.relatedTarget && e.relatedTarget.className === 'dropdown-item')
            e.preventDefault()
        else
            this.setState({value: ''});
    }
    trimString(s) {
        var l=0, r=s.length -1;
        while(l < s.length && s[l] === ' ') l++;
        while(r > l && s[r] === ' ') r-=1;
        return s.substring(l, r+1);
      }
      
      compareObjects(o1, o2) {
        var k = '';
        for(k in o1) if(o1[k] !== o2[k]) return false;
        for(k in o2) if(o1[k] !== o2[k]) return false;
        return true;
      }
      
      itemExists(haystack, needle) {
        for(var i=0; i<haystack.length; i++) if(this.compareObjects(haystack[i], needle)) return true;
        return false;
      }
      searchFor(toSearch, objects) {
        var results = [];
        toSearch = this.trimString(toSearch); 
        let elements = Object.keys(objects)
        for(var i=0; i<elements.length; i++) {
          
            if(elements[i].indexOf(toSearch)!==-1) {
              if(!this.itemExists(results, elements[i])) 
                results.push(elements[i]);
            }
          
        }
        return results;
      }
      

    filter(type, value, item) {
        this.props.global.setLoading(true);
        let filter_name = type
        let filter_value = value
        if(type === 'status'){
            let status = this.props.status
            let findStatus = this.searchFor(filter_value, status)
            filter_value = findStatus.length ? findStatus.map(elem => status[elem]).join("|") : '-15'
            filter_name += findStatus.length ? '(in)' : ''
        }
        this.props.reports.filterOrg(filter_name, filter_value, this.props.report).then(res => {
            this.props.global.setLoading(false);
            this.props.global.addGloablSearchbarFilters(type, item)
        })
            .catch(error => {
                this.props.global.setLoading(false);
            });
    }

    selectItem(){
        let items = this.state.items
        let headers = this.props.reports.getHeaders
        let index = this.state.focusedIndex
        let label = headers[index][1].label
        let filter = headers[index][1].filter
        let itemIndex = items.findIndex(item => item.label === label) 
        if(itemIndex >= 0) {
            items[itemIndex].values.push(this.state.value)
        } else{
            items.push({label: label,filter: filter, values: [this.state.value]});
        }
        let item = items.filter(r => r.filter === filter)
        this.filter(filter, this.state.value, item.length > 0 ? item[0] : [])
        this.setState({items: items, value: '', focusedIndex: 0})
    }

    handleClickSearch(e){
        this.selectItem()
    }

    render() {
        const dropDownStyle = { 
            width: "100%", 
            position: 'absolute', 
            top: '100%', 
            left: 'auto', 
            bottom: 'auto', 
            right: 'auto',
            maxHeight: '70vh',
            overflow: 'auto',
            backgroundClip: 'border-box',
            boxShadow: '0 12px 14px -10px rgba(0, 0, 0, 0.25)',
            zIndex: '1000',
            float: 'left',
            minWidth: '10rem',
            padding: '0.5rem 0',
            margin: '0.125rem 0 0',
            fontSize: '0.8333333rem',
            color: '#444B5A',
            textAlign: 'left',
            listStyle: 'none',
            backgroundColor: '#fff',
            border: '1px solid #C9CCD2',
            borderRadius: '0',
        }
        const itemsDropDownStyle = {
            alignItems: 'center',
            display: 'flex',
            paddingLeft: '25px',          
        }
        const searchViewFacet = {
            display: 'flex',
            flex: '0 0 auto',
            margin: '1px 3px 0 0',
            maxWidth: '100%',
            position: 'relative',
        }

        const searchviewFacetLabel = {
            alignItems: 'center',
            color: 'white',
            flex: '0 0 auto',
            padding: '0 3px',
            display: 'flex',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            verticalAlign: 'top',
            fontSize: '80%',
            backgroundColor: '#10A0E3',
            borderRadius: '4px 0 0 4px',
        }

        const facetValues =  {
            direction: 'ltr',
            padding: '0 8px 0 5px',
            border: '1px solid #10A0E3',
            borderLeftWidth: '0',
            borderRadius: '0 4px 4px 0',
            display: 'flex',
            alignItems: 'center'
        }

        const facetLabel =  {
            direction: 'ltr',
            padding: '0 8px 0 5px',
        }

        const searchviewInputContainer = {
            display: 'flex',
            flexFlow: 'row wrap',
            position: 'relative',
            minWidth: '260px',
            inset: '0px 0px auto 29px'
        }
        const searchView = {
            border: '1px solid #C9CCD2',
            borderRadius: '0.25rem',
            paddingBottom: '3px',
            alignItems: 'flex-end',
            padding: '0 30px 1px 0',
            position: 'relative',
        }

        const searchIcon = {
            position: 'absolute',
            top: '8px',
            left: '10px',
            bottom: 'auto',
            right: '0',
        }

        const removeIcon = {
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            flex: '0 0 auto',
            justifyContent: 'center',
            width: '18px',
            position: 'absolute',
            top: '0px',
            left: 'auto',
            bottom: '0',
            right: '0',
        }

        const faceSep = {
            fontStyle: 'italic',
            margin: '0 0.3rem',
        }

        return (
            <div style={{ display: 'flex',  }}>
                {/* <div style={{ width: '50%'}}>
                </div> */}
                <div style={{ width: '100%'}}>
                <div  className="o_cp_searchview" role="search">
                    <div style={searchView} role="search">
                        <FaSearch style={searchIcon} onClick={this.props.clickToHide || null}/>
                        <div style={searchviewInputContainer}>
                            {this.state.items.length > 0 && this.state.items.map((item, index) => (
                                <>
                                    <div style={searchViewFacet}
                                        role="img"
                                        aria-label="search"
                                        tabindex="0"
                                        >
                                        <span style={searchviewFacetLabel}>{item.label}</span>
                                        <div style={facetValues}>
                                            {item.values.length > 0 && item.values.map((val, i) => {
                                                if(i > 0 ){
                                                    return <><span style={faceSep}>or</span><span style={facetLabel}>{val}</span></>
                                                }
                                                return <span style={facetLabel}>{val}</span>
                                                
                                            })}
                                        </div>
                                        <BiX style={removeIcon} onClick={(e) => this.handleRemoveClick(e, index)}/>
                                    </div>
                                </>
                            ))}
                            <input type="text" style={{ flex: '1 0 auto', width: '50px', border: 'none', outline: 'none', height: '31px' }}
                                placeholder="Search..."
                                role="searchbox"
                                ref={this.inputRef}
                                value={this.state.value ? String(this.state.value) : ""}
                                onChange={(e) => this.handleSelect(e)}
                                onKeyDown={(e) => this.onSearchKeydown(e)}
                                onBlur={(e) => this.handleBlur(e)}
                            />
                            {this.state.value &&
                            <ul style={ dropDownStyle } role="menu">
                                {this.props.reports.getHeaders.map((record, i) => {
                                    if(record[1].filter && record[1].filter !== 'createdAt'){
                                        const focusedStyled = {
                                            backgroundColor: this.state.focusedIndex !== i ? '#FFFF' : '#eee'
                                        }
                                        const itemStyled = {...itemsDropDownStyle, ...focusedStyled}

                                        return <Dropdown.Item style={itemStyled} onMouseMove={(e) => this.handleMouseMove(e, i)} onClick={(e) => this.handleClickSearch(e)}>Search {record[1].label}: <span style={{paddingLeft: '4px', fontWeight: 'bold'}}>{this.state.value}</span></Dropdown.Item>
                                    }
                                    return <></>
                                })}
                            </ul>
                            }
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export { SearchBar };