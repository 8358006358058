import React from 'react';
import { inject, observer } from 'mobx-react';
import { ActionsRecord } from '../../../../../components/ActionsRecord';


@inject('store', 'global', 'vTerminal', 'bill')
@observer
class ItemTable extends React.Component {
  constructor(props) {
    super(props);

    this.editItem = this.editItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.updateQty = this.updateQty.bind(this);
  }

  filterDefaultElements(categories) {
    return categories.filter((item) => {
      const [, data] = item;
      if (data.type === 'quantity') return true;
      return false;
    });
  }

  calculateSubtotal(items) {
    return items.reduce((prev, [, data]) => {
      const { quantity, value } = data;
      return prev + value * quantity;
    }, 0);
  }

  editItem(itemData, position) {
    if(!this.props.isView){
      itemData.position = position;
      this.props.openModalEdit(itemData);
    }
  }

  removeItem(position) {
    this.props.vTerminal.removeItemCategory(position);
    this.props.bill.removeItem(position);
  }

  updateQty(action, position) {
    if (action === 'add') {
      this.props.vTerminal.handleItemQtyAdd(position);
    }

    if (action === 'subtract') {
      this.props.vTerminal.handleItemQtySubstract(position);
    }

    this.props.bill.updateQuantity(action, position);
  }

  render() {
    const items = this.props.bill.items;
    const pay = this.props.bill.pay;

    return (
      <>
        {items.length > 0 && (
          <div id="item-table">
            <div className="header p-right">
              <div className="col product">Product/service</div>
              <div className="col">Unit Price</div>
              <div className="col qty">Qty</div>
              <div className="col amount">Amount</div>
            </div>

            <div className="body">
              {
                items.map((data, idx) => (
                <div className="row-item p-right" key={`item-${idx}`}>
                  <div className="details">
                    <div className="col product" onClick={(e) => this.editItem(data, idx)}>
                      {data.label ? data.label : "-"}
                    </div>
                    <div className="col unit-price" onClick={(e) => this.editItem(data, idx)}>
                      ${this.props.global.numberWithCommas(parseFloat(data.value).toFixed(2))}
                    </div>
                    <div className="col qty">
                      {!this.props.isView &&
                      <button
                        className="btn btn-outline-light btn-sm"
                        type="button"
                        onClick={() => this.updateQty('subtract', idx)}
                      >
                        -
                      </button>
                      }
                      <span className="qty-value" onClick={(e) => this.editItem(data, idx)}>
                        {data.quantity}
                      </span>
                      {!this.props.isView &&
                      <button
                        className="btn btn-outline-light btn-sm"
                        type="button"
                        onClick={() => this.updateQty('add', idx)}
                      >
                        +
                      </button>
                      }
                    </div>
                    <div className="col amount">
                      ${this.props.global.numberWithCommas(parseFloat(data.value * data.quantity).toFixed(2))}
                    </div>
                  </div>

                  {data.description && <div className="description">{data.description}</div>}

                  <div className="category-icon">
                    {!this.props.isView &&
                    <ActionsRecord deleteAction={this.removeItem} idx={idx} editAction={this.editItem} editData={data}/>
                    }
                  </div>

                  <div className="underline"></div>
                </div>
              ))}

              <div className="row-subtotal p-right">
                <div className="item">
                  <span className="text">Subtotal</span>
                  <span className="value">${this.props.global.numberWithCommas(parseFloat(pay.subtotal).toFixed(2))}</span>
                </div>

                <div className="underline"></div>
              </div>

              <div className="row-total p-right">
                <span className="text">Total</span>
                <span className="value">${this.props.global.numberWithCommas(parseFloat(pay.subtotal).toFixed(2))}</span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ItemTable;
