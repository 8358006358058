import React from 'react';
import { inject, observer } from 'mobx-react';
import { Col } from "react-bootstrap";

@inject('boarding')
@observer
class ReadOnlyCheckLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: true, cname:'' };
    }

    componentDidMount() {
        this.setState({ value: this.props.iValue, cname: this.props.iName }, function () {
            this.props.boarding.setTemplateData(this.state.cname, this.state.value);
        });
    }

    render() {
        return (
            <Col md="auto">
                <div className={this.props.iInvisible ? "hide" : "icheck-primary" }>
                    <input name={this.props.iName} type="checkbox" id={this.props.iName} className="form-check-input" checked={!!this.state.value} readOnly />
                    <label htmlFor={this.props.iName}>{ this.props.iTitle }</label>
                </div>
            </Col>
        )
    }
}

export { ReadOnlyCheckLink };