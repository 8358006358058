import Switch from 'react-switch'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiInfoCircle } from 'react-icons/bi'
import styles from '../../assets/css/styleCommandCenter.module.css'

const switchContainer = ({ onChange = () => null, checked = false, onColor = '#10A0E3', onHandleColor = '#ffffff', height = 20, width = 38, handleDiameter = 20, labelValue = '', classNameLabel = '', labelStyle = {}, TooltipLabelStyle = {}, placement = 'right', TooltipCaption = '', inputDisabled = false, textAlignEnd = true }) => {
	const RenderLabel = () => {
		return TooltipCaption ? (
			<label
				className={classNameLabel}
				style={labelStyle}>
				{labelValue} &nbsp;
				<OverlayTrigger
					key={placement}
					placement={placement}
					overlay={<Tooltip>{TooltipCaption}</Tooltip>}>
					<BiInfoCircle
						style={TooltipLabelStyle}
						className={styles['icon15'] + ' ' + styles['color-gray']}
					/>
				</OverlayTrigger>
			</label>
		) : (
			<label
				className={classNameLabel}
				style={labelStyle}>
				{labelValue}
			</label>
		)
	}

	return (
		<div className="d-flex flex-row flex-nowrap align-items-center justify-content-center gap-2">
			{!textAlignEnd && <RenderLabel />}
			<Switch
				onChange={(values) => onChange(values)}
				checked={checked}
				onColor={onColor}
				onHandleColor={onHandleColor}
				handleDiameter={handleDiameter}
				boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
				activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
				disabled={inputDisabled}
				height={height}
				width={width}
				className="react-switch"
			/>
			{textAlignEnd && <RenderLabel />}
		</div>
	)
}

export default switchContainer
