import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportPaginator } from '../../components/ReportPaginator';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { ReportFilters } from '../../components/ReportFilters';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '../../components/Layout';
import { Link } from 'react-router-dom';
import { RightPanel } from '../../components/RightPanel';
import { Modal, Button } from 'react-bootstrap';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { OrganizationOverviewLinks } from '../../components/OrganizationOverviewLinks';
import { CustomerDataChartColumn } from '../../components/CustomerDataChartColumn';
import { VirtualTerminal } from '../../components/VirtualTerminal';
import { EditCustomer } from '../../components/EditCustomer';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { BiFilterAlt } from '@react-icons/all-files/bi/BiFilterAlt';
import { BiX } from '@react-icons/all-files/bi/BiX';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { MainBar } from '../../components/MainBar';

@inject('reports', 'global', 'vTerminal', 'customer', 'entry')
@observer
class OrgCustomersReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      head: '',
      filtersEnabled: false,
      filterStatus: {},
      deleteModalIsOpen: false,
      customerIdToDelete: null,
      vterminalRightPanelOpen: false,
      vTerminalAutopay: 1,
      customFields: [],
      paypoint: '',
      currentOrgName: '',
      flagItemAllColumn: true,
    };
    this.getReportFromApiOrgID = this.getReportFromApiOrgID.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.showHideFilters = this.showHideFilters.bind(this);
    this.handleQuickFilter = this.handleQuickFilter.bind(this);
    this.filter = this.filter.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleShowColumn = this.handleShowColumn.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.deleteCustomerAction = this.deleteCustomerAction.bind(this);
    this.viewRecordDetails = this.viewRecordDetails.bind(this);
    this.closeVterminalPanel = this.closeVterminalPanel.bind(this);
    this.openVterminalPanel = this.openVterminalPanel.bind(this);
    this.getCustomFieldsFromApi = this.getCustomFieldsFromApi.bind(this);
    this.selectAllColumns = this.selectAllColumns.bind(this);
  }

  componentDidMount() {
    this.props.global.protect(this.props.history);

    this.setState({
      idOrg: this.props.match.params.idOrg
        ? this.props.match.params.idOrg
        : null,
    });

    if (this.props.match.params.idOrg) {
      this.props.entry
        .getOrganization(this.props.match.params.idOrg)
        .then((res) => {
          this.setState({ currentOrgName: res.OrgName });
        })
        .catch((error) => {
          toast.error('Something is Wrong', {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
          });
        });
    }

    this.props.reports.setFrom(0);
    this.props.global.setRightPanelOpen(false);
    this.props.global.setLoading(true);
    this.props.reports.setHeaders({
      customerId: {
        label: 'Customer ID',
        class: '',
        display: true,
        filter: 'customerId',
      },
      ParentOrgName: { label: 'Organization', class: '', display: false },
      PaypointLegalname: {
        label: 'Paypoint',
        class: '',
        display: true,
        filter: 'PaypointLegalname',
      },
      customer: {
        label: 'Customer',
        class: '',
        display: true,
        filter: 'customer',
      },

      customerNumber: {
        label: 'Customer #',
        class: '',
        display: false,
        filter: 'customerNumber',
      },
      payorPhone: {
        label: 'Phone #',
        class: '',
        display: false,
        filter: 'phoneNumber',
      },
      billingAddress: { label: 'Billing Address', class: '', display: false },
      shippingAddress: { label: 'Shipping Address', class: '', display: false },

      email: { label: 'Email', class: '', display: true, filter: 'email' },
      company: {
        label: 'Company',
        class: '',
        display: true,
        filter: 'company',
      },
      subscriptions: {
        label: 'Active Autopay',
        class: 'text-center',
        display: true,
      },
      customerStatus: {
        label: 'Status',
        class: '',
        display: true,
        filter: 'customerStatus',
      },
      balance: { label: 'Balance', class: 'text-right', display: true },
      created: {
        label: 'Date Created',
        class: '',
        display: true,
        filter: 'created',
      },
    });
    this.getCustomFieldsFromApi();
    this.props.reports.setRecords([]);
    this.clearFilters();
  }

  closeVterminalPanel() {
    this.setState({ vterminalRightPanelOpen: false });
  }

  openVterminalPanel(customer, autopay) {
    if (autopay === 1) {
      this.props.vTerminal.disableAutopay(true);
    } else {
      this.props.vTerminal.disableAutopay(false);
    }

    if (customer) {
      this.props.vTerminal.selectCustomerObject(customer);
    }

    this.props.global.setLoading(true);
    this.props.vTerminal.setEntryPoint(
      customer.PaypointEntryname ? customer.PaypointEntryname : ''
    );
    this.props.vTerminal
      .getPaypointCredentials(
        customer.PaypointEntryname ? customer.PaypointEntryname : ''
      )
      .then((res) => {
        this.props.vTerminal.updateCustomerPaymentsOptions();
        this.props.global.setLoading(false);
      })
      .catch((error) => {
        this.props.global.setLoading(false);
        let errorMessage =
          error.response && error.response.data.responseText
            ? error.response.data.responseText
            : 'Something is Wrong!';
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      });

    this.setState({ vterminalRightPanelOpen: true, vTerminalAutopay: autopay });
  }

  deleteCustomer(customerIdToDelete) {
    this.setState({ customerIdToDelete: customerIdToDelete });
    this.openDeleteModal();
  }

  deleteCustomerAction() {
    let customerIdToDelete = this.state.customerIdToDelete;

    if (customerIdToDelete) {
      this.props.global.setLoading(true);
      this.props.reports
        .deleteCustomer(customerIdToDelete)
        .then((result) => {
          this.setState({ deleteModalIsOpen: false });
          this.props.global.setLoading(false);
          toast.success('Data deleted successfully!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-success-container',
          });
        })
        .catch((error) => {
          this.props.global.setLoading(false);
          let errorMessage =
            error.response && error.response.data.responseText
              ? error.response.data.responseText
              : 'Something is Wrong!';
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
          });
        });
    }
  }

  openDeleteModal() {
    this.setState({ deleteModalIsOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalIsOpen: false });
  }

  handleChangeCalendar(dates) {
    const [start, end] = dates;
    this.setState({ dateStartDate: start, dateEndtDate: end }, function () {
      const type = [];
      type['createdDate(ge)'] =
        this.props.global.stringDateFormatFilters(start) + 'T00:00:00';
      type['createdDate(le)'] =
        this.props.global.stringDateFormatFilters(end) + 'T23:59:59';
      if (end) {
        this.filter(type, null);
      }
    });
  }

  getReportFromApiOrgID() {
    this.props.global.setLoading(true);
    this.props.reports
      .getReportFromApiOrgID('customers', this.props.match.params.idOrg)
      .then((res) => {
        this.props.global.setLoading(false);
      })
      .catch((error) => {
        this.props.global.setLoading(false);
        let errorMessage =
          error.response && error.response.data.responseText
            ? error.response.data.responseText
            : 'Something is Wrong!';
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      });
  }

  clearFilters() {
    this.setState(
      { head: '', filterStatus: {}, dateStartDate: null, dateEndtDate: null },
      function () {
        this.props.reports.clearFilters();
        this.getReportFromApiOrgID();
      }
    );
  }

  showHideFilters() {
    this.setState({ filtersEnabled: !this.state.filtersEnabled }, function () {
      if (this.state.filtersEnabled === false) {
        this.clearFilters();
      }
    });
  }

  getCustomFieldsFromApi() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let idOrg = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
    let reactObj = this;
    this.props.reports
      .getOrgSettings(idOrg)
      .then((res) => {
        if (res.data.customFields) {
          let fields = res.data.customFields;
          fields.forEach(function (item, index) {
            reactObj.props.reports.addHeader(item.key, {
              label: item.key,
              class: '',
              display: true,
              filter: item.key,
              custom: true,
            });
          });
          this.setState({ customFields: fields });
        }
      })
      .catch((error) => {
        let errorMessage =
          error.response && error.response.data.responseText
            ? error.response.data.responseText
            : 'Something is Wrong!';
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      });
  }

  handleShowColumn(event) {
    this.props.reports.handleShowColumn(event);
    if (!this.state.flagItemAllColumn) {
      this.setState({flagItemAllColumn: true});
    }
  }

  exportFile(format) {
    this.props.reports.exportOrgFile('customers', format, this.state.idOrg);
  }

  handleQuickFilter(value, e) {
    this.filter('status(eq)', value);
    this.setState({ head: e.target.id });
  }

  filter(type, value) {
    this.props.global.setLoading(true);
    this.props.reports
      .filterOrgId(type, value, 'customers', this.state.idOrg)
      .then((res) => {
        this.props.global.setLoading(false);
      })
      .catch((error) => {
        this.props.global.setLoading(false);
      });
  }

  handleStatus(e) {
    let status = this.state.filterStatus;
    status[e.target.value] = e.target.checked;

    var arrayValues = [];
    for (let key in status) {
      if (status[key]) {
        arrayValues.push(key);
      }
    }
    this.setState({ filterStatus: status });
    this.filter('status(in)', arrayValues.join('|'));
  }

  handleKeyDown(type, e) {
    if (e.key === 'Enter') {
      this.filter(type, e.target.value);
    }
  }

  viewRecordDetails(id, object) {
    if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
      return;
    }
    let editCustomerModal = document.getElementById("editCustomerModal");
    if(editCustomerModal){
      if(editCustomerModal.contains(object.target) || object.target.className === "fade modal show"){
          return;
      }
    }
    if(object?.target?.id !== "actionsMenuButton"){
      let thisObj = this;
      if (
        thisObj.props.global.rightPanelOpen &&
        this.props.customer.customer !== null &&
        this.props.customer.customer.customerId === id
      ) {
        this.props.global.setRightPanelOpen(false);
        return;
      }
      this.props.global.setRightPanelOpen(false);
      this.props.global.setLoading(true);
      this.props.customer.getStatistics(id);
      this.props.customer
        .getCustomerFromApi(id)
        .then((res) => {
          thisObj.props.global.setLoading(false);
          setTimeout(function () {
            thisObj.props.global.setRightPanelOpen(true);
          }, 500);
        })
        .catch((error) => {
          thisObj.props.global.setLoading(false);
        });
    }
  }
  selectAllColumns(e, containerId){
      let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
      let checkBoxes = menuContainer.getElementsByTagName('input');
      for(var i = 0; i < checkBoxes.length; i++) {
          if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
              checkBoxes[i].click();
          }
      }
      this.setState({flagItemAllColumn: false});
  }
  render() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];

    return (
      <Layout {...this.props}>
        <div>
          <TopBar>
            <MainTopBarMenu />
            <div className="top-bar-sub">
              <OrganizationOverviewLinks
                goback={true}
                gobackLink={'/'+this.props.global.getURLEntry() +'/orgdetails/' + this.state.idOrg}
                gobackText="Org. Overview"
                idOrg={this.state.idOrg}
                selected="customers"
              />
            </div>
          </TopBar>

          {userPermissions.indexOf('Customers') !== -1 && (
            <>
              <Modal
                style={{ textAlign: 'center' }}
                show={this.state.deleteModalIsOpen}
                onHide={this.closeDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <BiTrash className="icon-modal" />
                  <h5>Delete</h5>
                  <p className="small">
                    Are you sure you want to delete this customer?
                  </p>
                  <Button
                    className="btn cancel-btn"
                    variant="default"
                    onClick={(e) => this.closeDeleteModal()}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn"
                    variant="danger"
                    onClick={this.deleteCustomerAction}
                  >
                    Delete
                  </Button>
                </Modal.Body>
              </Modal>

              <RightPanel>
                <CustomerDataChartColumn
                  header={'Customer Quick View'}
                  routeParams={this.props.match.params}
                  customerObj={this.props.customer.customer}
                />
              </RightPanel>
            </>
          )}

          {(userPermissions.indexOf('Accept Payments') !== -1 ||
            userPermissions.indexOf('Autopay') !== -1) && (
            <div
              id="vterminal-rigth-panel"
              className={this.state.vterminalRightPanelOpen ? 'open' : ''}
            >
              <div className="popover-body popover-body-2">
                <BiX
                  id="right-panel-close"
                  onClick={(e) => this.closeVterminalPanel()}
                />
                {this.state.vTerminalAutopay ? (
                  <h5 className="header mb-3">Create Autopay</h5>
                ) : (
                  <h5 className="header mb-3">Create Payment</h5>
                )}
                <VirtualTerminal
                  paypoint={this.state.paypoint}
                  paymentSuccessFunctionCallBack={this.closeVterminalPanel}
                  autopay={this.state.vTerminalAutopay}
                />
              </div>
            </div>
          )}
          {this.state.vterminalRightPanelOpen && (
            <div
              onClick={(e) => this.closeVterminalPanel()}
              id="right-panel-layer"
            ></div>
          )}

          <div className="mt-body4">
            <div className="row mb-4">

              <MainBar
                  reportTitle={this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : "All Customers"}
                  reportName="OrganizationReports"
                  dataQAName="OrganizationReports"
                  showHideFilters={this.showHideFilters}
                  selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                  handleShowColumn={(e) => this.handleShowColumn(e)}
                  rootTemplate={this.state.rootTemplate}
                  totalRecords={this.props.reports.totalRecords}
                  getHeaders={this.props.reports.getHeaders}
                  headers={this.props.reports.headers}
                  refreshView={this.clearFilters}
                  buttonExport={true}
                  searchBar={false}
                  exportFile={this.exportFile}
                  masterName={this.props.match.params.idOrg && `Organization - ${this.state.currentOrgName}`}
                  flagItemAllColumn={this.state.flagItemAllColumn}
              /> 

              {this.state.filtersEnabled && (
                <ReportFilters
                  report="customers"
                  clearFilters={this.clearFilters}
                />
              )}

              <div className="report-container">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      {this.props.reports.getHeaders.map(
                        (record, i) =>
                          record[1].display &&
                          (this.state.filtersEnabled ? (
                            <th key={i} scope="col" className={record[1].class}>
                              {!record[1].filter ? record[1].label : ''}

                              {record[1].filter && (
                                <>
                                  {record[1].custom === true && (
                                    <DropdownButton
                                      menuAlign="left"
                                      title={
                                        <div>
                                          {record[1].label} <BiFilterAlt />
                                        </div>
                                      }
                                      size="sm"
                                      variant=""
                                      className="search"
                                    >
                                      <Dropdown.ItemText className="filter-form">
                                        <label className="header">
                                          Filter by {record[1].label}
                                        </label>
                                        <input
                                          placeholder={record[1].label}
                                          onKeyDown={(e) =>
                                            this.handleKeyDown(
                                              'additional-' +
                                                record[1].label +
                                                '(ct)',
                                              e
                                            )
                                          }
                                          type="text"
                                          className="form-control search-enter"
                                        />
                                      </Dropdown.ItemText>
                                    </DropdownButton>
                                  )}

                                  {record[1].filter === 'customerId' && (
                                    <DropdownButton
                                      menuAlign="left"
                                      title={
                                        <div>
                                          {record[1].label} <BiFilterAlt />
                                        </div>
                                      }
                                      size="sm"
                                      variant=""
                                      className="search"
                                    >
                                      <Dropdown.ItemText className="filter-form">
                                        <label className="header">
                                          Filter by Customer ID
                                        </label>
                                        <input
                                          placeholder="Customer ID"
                                          onKeyDown={(e) =>
                                            this.handleKeyDown(
                                              'customerId(ct)',
                                              e
                                            )
                                          }
                                          type="text"
                                          className="form-control search-enter"
                                        />
                                      </Dropdown.ItemText>
                                    </DropdownButton>
                                  )}

                                  {record[1].filter === 'PaypointLegalname' && (
                                    <DropdownButton
                                      menuAlign="left"
                                      title={
                                        <div>
                                          {record[1].label} <BiFilterAlt />
                                        </div>
                                      }
                                      size="sm"
                                      variant=""
                                      className="search"
                                    >
                                      <Dropdown.ItemText className="filter-form">
                                        <label className="header">
                                          Filter by PayPoint
                                        </label>
                                        <input
                                          placeholder="PayPoint"
                                          onKeyDown={(e) =>
                                            this.handleKeyDown(
                                              'paypointLegal(ct)',
                                              e
                                            )
                                          }
                                          type="text"
                                          className="form-control search-enter"
                                        />
                                      </Dropdown.ItemText>
                                    </DropdownButton>
                                  )}

                                  {record[1].filter === 'customer' && (
                                    <DropdownButton
                                      menuAlign="left"
                                      title={
                                        <div>
                                          {record[1].label} <BiFilterAlt />
                                        </div>
                                      }
                                      size="sm"
                                      variant=""
                                      className="search"
                                    >
                                      <Dropdown.ItemText className="filter-form">
                                        <label className="header">
                                          Filter by Customer
                                        </label>
                                        <input
                                          placeholder="Customer"
                                          onKeyDown={(e) =>
                                            this.handleKeyDown('name(ct)', e)
                                          }
                                          type="text"
                                          className="form-control search-enter"
                                        />
                                      </Dropdown.ItemText>
                                    </DropdownButton>
                                  )}

                                  {record[1].filter === 'email' && (
                                    <DropdownButton
                                      menuAlign="left"
                                      title={
                                        <div>
                                          {record[1].label} <BiFilterAlt />
                                        </div>
                                      }
                                      size="sm"
                                      variant=""
                                      className="search"
                                    >
                                      <Dropdown.ItemText className="filter-form">
                                        <label className="header">
                                          Filter by Email
                                        </label>
                                        <input
                                          placeholder="Email"
                                          onKeyDown={(e) =>
                                            this.handleKeyDown('email(ct)', e)
                                          }
                                          type="text"
                                          className="form-control search-enter"
                                        />
                                      </Dropdown.ItemText>
                                    </DropdownButton>
                                  )}

                                  {record[1].filter === 'company' && (
                                    <DropdownButton
                                      menuAlign="left"
                                      title={
                                        <div>
                                          {record[1].label} <BiFilterAlt />
                                        </div>
                                      }
                                      size="sm"
                                      variant=""
                                      className="search"
                                    >
                                      <Dropdown.ItemText className="filter-form">
                                        <label className="header">
                                          Filter by Company
                                        </label>
                                        <input
                                          placeholder="Company"
                                          onKeyDown={(e) =>
                                            this.handleKeyDown('company(ct)', e)
                                          }
                                          type="text"
                                          className="form-control search-enter"
                                        />
                                      </Dropdown.ItemText>
                                    </DropdownButton>
                                  )}

                                  {record[1].filter === 'customerStatus' && (
                                    <DropdownButton
                                      menuAlign="left"
                                      title={
                                        <div>
                                          {record[1].label} <BiFilterAlt />
                                        </div>
                                      }
                                      size="sm"
                                      variant=""
                                    >
                                      <Dropdown.ItemText>
                                        <label>
                                          <input
                                            value="1"
                                            type="checkbox"
                                            onChange={(e) =>
                                              this.handleStatus(e)
                                            }
                                          />{' '}
                                          Active
                                        </label>
                                      </Dropdown.ItemText>
                                      <Dropdown.ItemText>
                                        <label>
                                          <input
                                            value="58"
                                            type="checkbox"
                                            onChange={(e) =>
                                              this.handleStatus(e)
                                            }
                                          />{' '}
                                          Pending
                                        </label>
                                      </Dropdown.ItemText>
                                      <Dropdown.ItemText>
                                        <label>
                                          <input
                                            value="-1"
                                            type="checkbox"
                                            onChange={(e) =>
                                              this.handleStatus(e)
                                            }
                                          />{' '}
                                          Non-Authorized
                                        </label>
                                      </Dropdown.ItemText>
                                      <Dropdown.ItemText>
                                        <label>
                                          <input
                                            value="0"
                                            type="checkbox"
                                            onChange={(e) =>
                                              this.handleStatus(e)
                                            }
                                          />{' '}
                                          Unregistered
                                        </label>
                                      </Dropdown.ItemText>
                                    </DropdownButton>
                                  )}

                                  {record[1].filter === 'created' && (
                                    <DropdownButton
                                      menuAlign="left"
                                      title={
                                        <div>
                                          {record[1].label} <BiFilterAlt />
                                        </div>
                                      }
                                      size="sm"
                                      variant=""
                                      className="search"
                                    >
                                      <Dropdown.ItemText className="filter-form-datepicker">
                                        <DatePicker
                                          selected={
                                            this.state.dateStartDate
                                              ? this.state.dateStartDate
                                              : new Date()
                                          }
                                          onChange={(date) =>
                                            this.handleChangeCalendar(date)
                                          }
                                          startDate={this.state.dateStartDate}
                                          endDate={this.state.dateEndtDate}
                                          selectsRange
                                          inline
                                        />
                                      </Dropdown.ItemText>
                                    </DropdownButton>
                                  )}

                                  {record[1].filter === 'customerNumber' && (
                                    <DropdownButton
                                      menuAlign="left"
                                      title={
                                        <div>
                                          {record[1].label} <BiFilterAlt />
                                        </div>
                                      }
                                      size="sm"
                                      variant=""
                                      className="search"
                                    >
                                      <Dropdown.ItemText className="filter-form">
                                        <label className="header">
                                          Filter by Customer #
                                        </label>
                                        <input
                                          placeholder="Customer #"
                                          onKeyDown={(e) =>
                                            this.handleKeyDown(
                                              'customernumber(ct)',
                                              e
                                            )
                                          }
                                          type="text"
                                          className="form-control search-enter"
                                        />
                                      </Dropdown.ItemText>
                                    </DropdownButton>
                                  )}

                                  {record[1].filter === 'phoneNumber' && (
                                    <DropdownButton
                                      menuAlign="left"
                                      title={
                                        <div>
                                          {record[1].label} <BiFilterAlt />
                                        </div>
                                      }
                                      size="sm"
                                      variant=""
                                      className="search"
                                    >
                                      <Dropdown.ItemText className="filter-form">
                                        <label className="header">
                                          Filter by Phone #
                                        </label>
                                        <input
                                          placeholder="Phone #"
                                          onKeyDown={(e) =>
                                            this.handleKeyDown('phone(ct)', e)
                                          }
                                          type="text"
                                          className="form-control search-enter"
                                        />
                                      </Dropdown.ItemText>
                                    </DropdownButton>
                                  )}
                                </>
                              )}
                            </th>
                          ) : (
                            <th key={i} scope="col" className={record[1].class}>
                              {record[1].label}
                            </th>
                          ))
                      )}
                      <th scope="col" className="text-right sticky-row">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.reports.records.map((record, i) => (
                      <tr
                        key={i}
                        className="cursorPointer"
                        onClick={(e) =>
                          this.viewRecordDetails(record.customerId, e)
                        }
                        onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} 
                      >
                        {this.props.reports.headers.customerId &&
                          this.props.reports.headers.customerId.display && (
                            <td>{record.customerId}</td>
                          )}

                        {this.props.reports.headers.ParentOrgName &&
                          this.props.reports.headers.ParentOrgName.display && (
                            <td>
                              {record && record.ParentOrgName
                                ? record.ParentOrgName
                                : '-'}
                            </td>
                          )}

                        {this.props.reports.headers.PaypointLegalname &&
                          this.props.reports.headers.PaypointLegalname
                            .display && (
                            <td>
                              {record && record.PaypointLegalname
                                ? record.PaypointLegalname
                                : '-'}
                            </td>
                          )}

                        {this.props.reports.headers.customer &&
                          this.props.reports.headers.customer.display && (
                            <td>
                              {record.Firstname} {record.Lastname}
                            </td>
                          )}

                        {this.props.reports.headers.customerNumber &&
                          this.props.reports.headers.customerNumber.display && (
                            <td>
                              {record.customerNumber
                                ? record.customerNumber.length > 15
                                  ? record.customerNumber.substring(0, 15) +
                                    '...'
                                  : record.customerNumber
                                : '-'}{' '}
                            </td>
                          )}

                        {this.props.reports.headers.payorPhone &&
                          this.props.reports.headers.payorPhone.display && (
                            <td>
                              {record.Phone
                                ? this.props.global.phoneNumberFormat(
                                    record.Phone
                                  )
                                : '-'}{' '}
                            </td>
                          )}

                        {this.props.reports.headers.billingAddress &&
                          this.props.reports.headers.billingAddress.display && (
                            <td>
                              {record.Address
                                ? record.Address +
                                  ' ' +
                                  record.Address1 +
                                  ' ' +
                                  record.City +
                                  ' ' +
                                  record.State +
                                  ' ' +
                                  record.Zip +
                                  ' ' +
                                  record.Country
                                : '-'}{' '}
                            </td>
                          )}

                        {this.props.reports.headers.shippingAddress &&
                          this.props.reports.headers.shippingAddress
                            .display && (
                            <td>
                              {record.ShippingAddress
                                ? record.ShippingAddress +
                                  ' ' +
                                  record.ShippingAddress1 +
                                  ' ' +
                                  record.ShippingCity +
                                  ' ' +
                                  record.ShippingState +
                                  ' ' +
                                  record.ShippingZip +
                                  ' ' +
                                  record.ShippingCountry
                                : '-'}{' '}
                            </td>
                          )}

                        {this.props.reports.headers.email &&
                          this.props.reports.headers.email.display && (
                            <td>{record.Email}</td>
                          )}

                        {this.props.reports.headers.company &&
                          this.props.reports.headers.company.display && (
                            <td>{record.Company ? record.Company : '-'}</td>
                          )}

                        {this.props.reports.headers.subscriptions &&
                          this.props.reports.headers.subscriptions.display && (
                            <td className="text-center">
                              {record.Subscriptions
                                ? record.Subscriptions.length
                                : ''}
                            </td>
                          )}

                        {this.props.reports.headers.customerStatus &&
                          this.props.reports.headers.customerStatus.display && (
                            <td>
                              {this.props.reports.getCustomerStatus(
                                record.customerStatus
                              )}
                            </td>
                          )}

                        {this.props.reports.headers.balance &&
                          this.props.reports.headers.balance.display && (
                            <td className="text-right">
                              $
                              {this.props.global.numberWithCommas(
                                record.Balance.toFixed(2)
                              )}
                            </td>
                          )}

                        {this.props.reports.headers.created &&
                          this.props.reports.headers.created.display && (
                            <td>
                              {this.props.global.stringDateFormat(
                                record.Created
                              )}
                            </td>
                          )}

                        {this.state.customFields.map(
                          (field, i) =>
                            this.props.reports.headers[field.key] &&
                            this.props.reports.headers[field.key].display && (
                              <td key={i}>
                                {record.AdditionalFields &&
                                record.AdditionalFields[field.key]
                                  ? record.AdditionalFields[field.key]
                                  : '-'}
                              </td>
                            )
                        )}

                        <td className="text-center sticky-row">
                          <DropdownButton
                            menuAlign="center"
                            title={<BiDotsVerticalRounded/>}
                            id="actionsMenuButton"
                            size="sm"
                            variant="default"
                          >
                            {userPermissions.indexOf('Accept Payments') !==
                              -1 && (
                              <Dropdown.Item
                                onClick={(e) =>
                                  this.openVterminalPanel(record, 0)
                                }
                              >
                                Create Payment
                              </Dropdown.Item>
                            )}
                            {userPermissions.indexOf('Autopay') !== -1 && (
                              <Dropdown.Item
                                onClick={(e) =>
                                  this.openVterminalPanel(record, 1)
                                }
                              >
                                Create Autopay
                              </Dropdown.Item>
                            )}
                            {/*<Dropdown.Item>Create Invoice</Dropdown.Item>*/}
                            <Dropdown.Divider />
                            {userPermissions.indexOf('Customers') !== -1 && (
                              <>
                                <Dropdown.Item
                                  onClick={(e) =>
                                    this.viewRecordDetails(record.customerId, e)
                                  }
                                >
                                  <div>Quick View</div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Link}
                                  to={'/'+this.props.global.getURLEntry() +'/customer/' + record.customerId}
                                >
                                  {' '}
                                  View Customer
                                </Dropdown.Item>
                                <Dropdown.Item as="div">
                                  <EditCustomer
                                    functionCallback={
                                      this.getReportFromApiOrgID
                                    }
                                    customerToUpdate={record}
                                    action={'edit'}
                                  />
                                </Dropdown.Item>
                                <Dropdown.Item>Change Status</Dropdown.Item>
                                <Dropdown.Item as={Link} to={'/customer/paymethods/'+ record.customerId+"/add"}>Add Payment Method</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  style={{ color: '#c00000' }}
                                  onClick={(e) =>
                                    this.deleteCustomer(record.customerId)
                                  }
                                >
                                  Delete Customer
                                </Dropdown.Item>
                              </>
                            )}
                          </DropdownButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {this.props.reports.records.length < 1 && (
                  <RecordsNotFound
                    message="No customers yet"
                    description={
                      <span>
                        When you add customers, they will show up here.
                        <br /> You can view their payment history, charge them,
                        and more.
                      </span>
                    }
                  />
                )}
              </div>
              <ReportPaginator report="customers" mode={this.state.idOrg} />
            </div>
          </div>
          <ToastContainer transition={Bounce} />
        </div>
      </Layout>
    );
  }
}

export { OrgCustomersReport };
