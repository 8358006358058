import React, { useContext } from 'react'
import GroupSwitch from '../../../../../../components/templates/GroupSwitch'
import templatesContext from '../../../context/templates_context'

export default function CardPricingType() {
	const { RootTemplate, LoadingState, ServicesTemplate, handleChangeService, handleChangePricingType } = useContext(templatesContext)

	const pricingTypeCardStructure = {
		checksCardICP: [
			{ property: 'cardICP', field: 'visible', title: 'IC+ Card Price', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, classGroup: 'col-2', labelUp: false },
			{ property: 'cardICP_percentxAuth', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'cardICP_amountxAuth', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'cardICP_lowPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'cardICP_highPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true }
		],
		cardICP: {
			visa: [
				{ property: 'visa', field: 'visible', title: 'Visa', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'visa', field: 'percentxAuth', title: '% x auth', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'visa', field: 'amountxAuth', title: '$ x auth', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'visa', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'visa', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] }
			],
			masterCard: [
				{ property: 'masterCard', field: 'visible', title: 'MasterCard', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'masterCard', field: 'percentxAuth', title: '% x auth', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'amountxAuth', title: '$ x auth', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] }
			],
			discover: [
				{ property: 'discover', field: 'visible', title: 'Discover', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'discover', field: 'percentxAuth', title: '% x auth', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'discover', field: 'amountxAuth', title: '$ x auth', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'discover', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'discover', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] }
			],
			amex: [
				{ property: 'amex', field: 'visible', title: 'Amex', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'amex', field: 'percentxAuth', title: '% x auth', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'amex', field: 'amountxAuth', title: '$ x auth', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'amex', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] },
				{ property: 'amex', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardICP.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardICP', subProperty: ['tiers'] }
			]
		},
		checkscCardFlat: [
			{ property: 'cardFlat', field: 'visible', title: 'Flat Card Price', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, classGroup: 'col-2', labelUp: false },
			{ property: 'cardFlat_percentxAuth', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'cardFlat_amountxAuth', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'cardFlat_lowPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'cardFlat_highPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true }
		],
		CardFlat: {
			visa: [
				{ property: 'visa', field: 'visible', title: 'Visa', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'visa', field: 'percentxAuth', title: '% x auth', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'visa', field: 'amountxAuth', title: '$ x auth', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'visa', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'visa', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] }
			],
			masterCard: [
				{ property: 'masterCard', field: 'visible', title: 'MasterCard', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'masterCard', field: 'percentxAuth', title: '% x auth', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'amountxAuth', title: '$ x auth', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] }
			],
			discover: [
				{ property: 'discover', field: 'visible', title: 'Discover', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'discover', field: 'percentxAuth', title: '% x auth', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'discover', field: 'amountxAuth', title: '$ x auth', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'discover', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'discover', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] }
			],
			amex: [
				{ property: 'amex', field: 'visible', title: 'Amex', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'amex', field: 'percentxAuth', title: '% x auth', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'amex', field: 'amountxAuth', title: '$ x auth', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'amex', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] },
				{ property: 'amex', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardFlat.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardFlat', subProperty: ['tiers'] }
			]
		},
		checksPassThrough: [
			{ property: 'cardPassThrough', field: 'visible', title: 'Pass-Through Card Price', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, classGroup: 'col-2', labelUp: false },
			{ property: 'cardPassThrough_percentxAuth', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-1 text-center', labelUp: true },
			{ property: 'cardPassThrough_amountxAuth', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'cardPassThrough_percentRecurring', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-1 text-center', labelUp: true },
			{ property: 'cardPassThrough_amountRecurring', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'cardPassThrough_lowPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-1 text-center', labelUp: true },
			{ property: 'cardPassThrough_highPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true }
		],
		passThroughCard: {
			visa: [
				{ property: 'visa', field: 'visible', title: 'Visa', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'visa', field: 'percentFeeOneTime', title: '% OneTime', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'visa', field: 'amountFeeOneTime', title: '$ OneTime', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'visa', field: 'percentFeeRecurring', title: '% Recurring', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'visa', field: 'amountFeeRecurring', title: '$ Recurring', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'visa', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'visa', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] }
			],
			masterCard: [
				{ property: 'masterCard', field: 'visible', title: 'MasterCard', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'masterCard', field: 'percentFeeOneTime', title: '% OneTime', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'amountFeeOneTime', title: '$ OneTime', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'percentFeeRecurring', title: '% Recurring', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'amountFeeRecurring', title: '$ Recurring', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'masterCard', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] }
			],
			discover: [
				{ property: 'discover', field: 'visible', title: 'Discover', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'discover', field: 'percentFeeOneTime', title: '% OneTime', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'discover', field: 'amountFeeOneTime', title: '$ OneTime', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'discover', field: 'percentFeeRecurring', title: '% Recurring', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'discover', field: 'amountFeeRecurring', title: '$ Recurring', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'discover', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'discover', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] }
			],
			amex: [
				{ property: 'amex', field: 'visible', title: 'Amex', fieldData: !LoadingState && ServicesTemplate.card.cardAcceptance.types, isCheck: true, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false, subProperty0: 'cardAcceptance', subProperty: 'types' },
				{ property: 'amex', field: 'percentFeeOneTime', title: '% OneTime', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'amex', field: 'amountFeeOneTime', title: '$ OneTime', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'amex', field: 'percentFeeRecurring', title: '% Recurring', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'amex', field: 'amountFeeRecurring', title: '$ Recurring', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'amex', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] },
				{ property: 'amex', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.card.cardPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'cardPassThrough', subProperty: ['tiers'] }
			]
		},
		passThroughMultiTier: [{ property: 'cardPassThrough', field: 'multiTier', title: 'Offer MultiTiers', fieldData: !LoadingState && ServicesTemplate.card, isCheck: true, classGroup: 'col-2', labelUp: false }]
	}

	return (
		<>
			{(RootTemplate.rootTemplate.servicesData.card.cardICP.visible || RootTemplate.isRoot) && ServicesTemplate.card.pricingType.value === '1' && (
				<>
					<GroupSwitch
						groupFields={pricingTypeCardStructure.checksCardICP}
						handleChange={(subProperty0, subProperty, property, field, values) => handleChangeService('card', '', property, field, values)}
					/>
					{RootTemplate.isRoot ? (
						<>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.cardICP.visa}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.cardICP.masterCard}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.cardICP.discover}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.cardICP.amex}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
						</>
					) : (
						<table className="table">
							<thead>
								<tr>
									<th>Card Type</th>
									<th>% x Auth</th>
									<th>$ x Auth</th>
									<th>Low Pay Range</th>
									<th>High Pay Range</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Visa</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].visa.percentxAuth}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].visa.amountxAuth}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].visa.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].visa.highPayRange}</td>
								</tr>
								<tr>
									<td>MasterCard</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].masterCard.percentxAuth}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].masterCard.amountxAuth}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].masterCard.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].masterCard.highPayRange}</td>
								</tr>
								<tr>
									<td>Discover</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].discover.percentxAuth}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].discover.amountxAuth}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].discover.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].discover.highPayRange}</td>
								</tr>
								<tr>
									<td>Amex</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].amex.percentxAuth}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].amex.amountxAuth}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].amex.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardICP.tiers[0].amex.highPayRange}</td>
								</tr>
							</tbody>
						</table>
					)}
				</>
			)}
			{(RootTemplate.rootTemplate.servicesData.card.cardFlat.visible || RootTemplate.isRoot) && (ServicesTemplate.card.pricingType.value === '2' || ServicesTemplate.card.pricingType.value === '4') && (
				<>
					<GroupSwitch
						groupFields={pricingTypeCardStructure.checkscCardFlat}
						handleChange={(subProperty0, subProperty, property, field, values) => handleChangeService('card', '', property, field, values)}
					/>
					{RootTemplate.isRoot ? (
						<>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.CardFlat.visa}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.CardFlat.masterCard}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.CardFlat.discover}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.CardFlat.amex}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
						</>
					) : (
						<table className="table">
							<thead>
								<tr>
									<th>Card Type</th>
									<th>% x Auth</th>
									<th>$ x Auth</th>
									<th>Low Pay Range</th>
									<th>High Pay Range</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Visa</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].visa.percentxAuth}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].visa.amountxAuth}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].visa.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].visa.highPayRange}</td>
								</tr>
								<tr>
									<td>MasterCard</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].masterCard.percentxAuth}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].masterCard.amountxAuth}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].masterCard.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].masterCard.highPayRange}</td>
								</tr>
								<tr>
									<td>Discover</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].discover.percentxAuth}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].discover.amountxAuth}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].discover.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].discover.highPayRange}</td>
								</tr>
								<tr>
									<td>Amex</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].amex.percentxAuth}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].amex.amountxAuth}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].amex.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardFlat.tiers[0].amex.highPayRange}</td>
								</tr>
							</tbody>
						</table>
					)}
				</>
			)}
			{(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData.card.cardPassThrough.visible) && (ServicesTemplate.card.pricingType.value === '3' || ServicesTemplate.card.pricingType.value === '4') && (
				<>
					<GroupSwitch
						groupFields={pricingTypeCardStructure.checksPassThrough}
						handleChange={(subProperty0, subProperty, property, field, values) => handleChangeService('card', '', property, field, values)}
					/>

					{RootTemplate.isRoot ? (
						<>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.passThroughCard.visa}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.passThroughCard.masterCard}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.passThroughCard.discover}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
							<GroupSwitch
								groupFields={pricingTypeCardStructure.passThroughCard.amex}
								handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('card', subProperty0, subProperty, property, field, values)}
							/>
						</>
					) : (
						<table className="table">
							<thead>
								<tr>
									<th>Card Type</th>
									<th>% x Auth</th>
									<th>$ x Auth</th>
									<th>% x Auth Recurring</th>
									<th>$ x Auth Recurring</th>
									<th>Low Pay Range</th>
									<th>High Pay Range</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Visa</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].visa.percentFeeOneTime}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].visa.amountFeeOneTime}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].visa.percentFeeRecurring}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].visa.amountFeeRecurring}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].visa.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].visa.highPayRange}</td>
								</tr>
								<tr>
									<td>MasterCard</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].masterCard.percentFeeOneTime}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].masterCard.amountFeeOneTime}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].masterCard.percentFeeRecurring}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].masterCard.amountFeeRecurring}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].masterCard.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].masterCard.highPayRange}</td>
								</tr>
								<tr>
									<td>Discover</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].discover.percentFeeOneTime}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].discover.amountFeeOneTime}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].discover.percentFeeRecurring}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].discover.amountFeeRecurring}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].discover.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].discover.highPayRange}</td>
								</tr>
								<tr>
									<td>Amex</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].amex.percentFeeOneTime}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].amex.amountFeeOneTime}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].amex.percentFeeRecurring}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].amex.amountFeeRecurring}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].amex.lowPayRange}</td>
									<td>{ServicesTemplate.card?.cardPassThrough.tiers[0].amex.highPayRange}</td>
								</tr>
							</tbody>
						</table>
					)}

					{(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData.card.cardPassThrough.multiTier) && (
						<GroupSwitch
							groupFields={pricingTypeCardStructure.passThroughMultiTier}
							handleChange={(subProperty0, subProperty, property, field, values) => handleChangeService('card', '', property, field, values)}
						/>
					)}
				</>
			)}
		</>
	)
}
