import React, { useContext } from 'react'
import GroupSwitch from '../../../../../../components/templates/GroupSwitch'
import templatesContext from '../../../context/templates_context'

export default function MgmPricingType() {
	const { RootTemplate, LoadingState, ServicesTemplate, handleChangeService, handleChangePricingType } = useContext(templatesContext)

	const pricingTypeMgmStructure = {
		checksAbsorb: [
			{ property: 'mgmAbsorb', field: 'visible', title: 'Absorb Managed Payables Price', fieldData: !LoadingState && ServicesTemplate.mgm, isCheck: true, classGroup: 'col-4', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-2 text-center', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-2 text-center', labelUp: false },
			{ property: 'mgmAbsorb_lowPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.mgm, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'mgmAbsorb_highPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.mgm, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true }
		],
		absorbMGM: [
			{ property: 'blank', field: '', title: 'Managed Payables', fieldData: !LoadingState && '', isCheck: false, classGroup: 'col-4 p-4 d-flex align-items-center', labelUp: false },
			{ property: 'web', field: 'percentxAuth', title: '% x auth', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.mgm.mgmAbsorb.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'mgmAbsorb', subProperty: ['tiers'] },
			{ property: 'web', field: 'amountxAuth', title: '$ x auth', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.mgm.mgmAbsorb.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'mgmAbsorb', subProperty: ['tiers'] },
			{ property: 'web', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.mgm.mgmAbsorb.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'mgmAbsorb', subProperty: ['tiers'] },
			{ property: 'web', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.mgm.mgmAbsorb.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'mgmAbsorb', subProperty: ['tiers'] }
		],
		checksPassThrough: [
			{ property: 'mgmPassThrough', field: 'visible', title: 'Pass-Through Managed Payables Price', fieldData: !LoadingState && ServicesTemplate.mgm, isCheck: true, classGroup: 'col-2', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-1 text-center', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-2 text-center', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-1 text-center', labelUp: false },
			{ property: 'blank', field: '', title: '', classGroup: 'col-2 text-center', labelUp: false },
			{ property: 'mgmPass_lowPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.mgm, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true },
			{ property: 'mgmPass_highPayRange', field: 'value', title: 'Visible', fieldData: !LoadingState && ServicesTemplate.mgm, isCheck: true, isString: true, classGroup: 'col-2 text-center', labelUp: true }
		],
		passThroughMGM: [
			{ property: 'blank', field: '', title: 'Managed Payables', fieldData: !LoadingState && '', isCheck: false, classGroup: 'col-2 p-4 d-flex align-items-center', labelUp: false },
			{ property: 'web', field: 'percentFeeOneTime', title: '% OneTime', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.mgm.mgmPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'mgmPassThrough', subProperty: ['tiers'] },
			{ property: 'web', field: 'amountFeeOneTime', title: '$ OneTime', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.mgm.mgmPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'mgmPassThrough', subProperty: ['tiers'] },
			{ property: 'web', field: 'percentFeeRecurring', title: '% Recurring', inputType: 'number', prefix: '', suffix: '%', maxValue: 100, mask: '', fieldData: !LoadingState && ServicesTemplate.mgm.mgmPassThrough.tiers[0], isCheck: false, classGroup: 'col-1', labelUp: true, subProperty0: 'mgmPassThrough', subProperty: ['tiers'] },
			{ property: 'web', field: 'amountFeeRecurring', title: '$ Recurring', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.mgm.mgmPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'mgmPassThrough', subProperty: ['tiers'] },
			{ property: 'web', field: 'lowPayRange', title: 'Low pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.mgm.mgmPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'mgmPassThrough', subProperty: ['tiers'] },
			{ property: 'web', field: 'highPayRange', title: 'High pay range', inputType: 'number', mask: '', fieldData: !LoadingState && ServicesTemplate.mgm.mgmPassThrough.tiers[0], isCheck: false, classGroup: 'col-2', labelUp: true, subProperty0: 'mgmPassThrough', subProperty: ['tiers'] }
		],
		passThroughMultiTier: [{ property: 'mgmPassThrough', field: 'multiTier', title: 'Offer MultiTiers', fieldData: !LoadingState && ServicesTemplate.mgm, isCheck: true, classGroup: 'col-2', labelUp: false }]
	}

	return (
		<>
			{(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData.mgm.mgmAbsorb.visible) && (ServicesTemplate.mgm.pricingType.value === '5' || ServicesTemplate.mgm.pricingType.value === '6') && (
				<>
					<GroupSwitch
						groupFields={pricingTypeMgmStructure.checksAbsorb}
						handleChange={(subProperty0, subProperty, property, field, values) => handleChangeService('mgm', '', property, field, values)}
					/>
					{RootTemplate.isRoot ? (
						<GroupSwitch
							groupFields={pricingTypeMgmStructure.absorbMGM}
							handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('mgm', subProperty0, subProperty, property, field, values)}
						/>
					) : (
						<table className="table">
							<thead>
								<tr>
									<th>Managed Payables Type</th>
									<th>% x Auth</th>
									<th>$ x Auth</th>
									<th>Low Pay Range</th>
									<th>High Pay Range</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Managed Payables</td>
									<td>{ServicesTemplate.mgm.mgmAbsorb.tiers[0].web.percentxAuth}</td>
									<td>{ServicesTemplate.mgm.mgmAbsorb.tiers[0].web.amountxAuth}</td>
									<td>{ServicesTemplate.mgm.mgmAbsorb.tiers[0].web.lowPayRange}</td>
									<td>{ServicesTemplate.mgm.mgmAbsorb.tiers[0].web.highPayRange}</td>
								</tr>
							</tbody>
						</table>
					)}
				</>
			)}
			{(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData.mgm.mgmPassThrough.visible) && (ServicesTemplate.mgm.pricingType.value === '3' || ServicesTemplate.mgm.pricingType.value === '6') && (
				<>
					<GroupSwitch
						groupFields={pricingTypeMgmStructure.checksPassThrough}
						handleChange={(subProperty0, subProperty, property, field, values) => handleChangeService('mgm', '', property, field, values)}
					/>
					{RootTemplate.isRoot ? (
						<GroupSwitch
							groupFields={pricingTypeMgmStructure.passThroughMGM}
							handleChange={(subProperty0, subProperty, property, field, values) => handleChangePricingType('mgm', subProperty0, subProperty, property, field, values)}
						/>
					) : (
						<table className="table">
							<thead>
								<tr>
									<th>Managed Payables Type</th>
									<th>% x Auth</th>
									<th>$ x Auth</th>
									<th>% x Auth Recurring</th>
									<th>$ x Auth Recurring</th>
									<th>Low Pay Range</th>
									<th>High Pay Range</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Managed Payables</td>
									<td>{ServicesTemplate.mgm.mgmPassThrough.tiers[0].web.percentFeeOneTime}</td>
									<td>{ServicesTemplate.mgm.mgmPassThrough.tiers[0].web.amountFeeOneTime}</td>
									<td>{ServicesTemplate.mgm.mgmPassThrough.tiers[0].web.percentFeeRecurring}</td>
									<td>{ServicesTemplate.mgm.mgmPassThrough.tiers[0].web.amountFeeRecurring}</td>
									<td>{ServicesTemplate.mgm.mgmPassThrough.tiers[0].web.lowPayRange}</td>
									<td>{ServicesTemplate.mgm.mgmPassThrough.tiers[0].web.highPayRange}</td>
								</tr>
							</tbody>
						</table>
					)}

					{(RootTemplate.isRoot || RootTemplate.rootTemplate.servicesData.mgm.mgmPassThrough.multiTier) && (
						<GroupSwitch
							groupFields={pricingTypeMgmStructure.passThroughMultiTier}
							handleChange={(subProperty0, subProperty, property, field, values) => handleChangeService('mgm', '', property, field, values)}
						/>
					)}
				</>
			)}
		</>
	)
}
