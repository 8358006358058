import React from 'react';
import { BiTrendingUp, BiTrendingDown, BiRightArrowAlt } from 'react-icons/bi';

export const ElementTrending = ({ value }) => {
	let valueClass = 'trending-equal';
	let icon = <BiRightArrowAlt />;

	if (value > 0) {
		valueClass = 'trending-up';
		icon = <BiTrendingUp />;
	}

	if (value < 0) {
		valueClass = 'trending-down';
		icon = <BiTrendingDown />;
	}

	return (
		<span className={valueClass}>
			{icon} {value}%
		</span>
	);
};

export default ElementTrending;
