import { action } from 'mobx'

export default function TemplateBusinessFunction({ businessData, setBusinessData, insertValues, handleChange }) {
	const fieldDataBusiness = (data, templateCreator, newTemplate) => {
		let newBusinessData = {}
		let structure = { visible: true, subHeader: '', subFooter: '' }
		for (let element in structure) {
			newBusinessData[element] = insertValues(data[templateCreator], 'businessData', element, structure[element], 'oneElement')
		}

		structure = {
			dbaname: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			legalname: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			website: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			ein: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			taxfillname: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			license: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			licstate: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			startdate: { posRow: 0, posCol: 0, value: !newTemplate ? 'date' : 'null', visible: true, readOnly: false, required: false },
			phonenumber: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			faxnumber: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			baddress: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			baddress1: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			bcity: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			btype: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			bstate: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			bzip: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			bcountry: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			maddress: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			maddress1: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			mcity: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			mstate: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			mzip: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
			mcountry: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false }
		}
		for (let element in structure) {
			newBusinessData[element] = insertValues(data[templateCreator], 'businessData', element, structure[element])
		}
		setBusinessData(newBusinessData)
	}
	const handleChangeBusiness = action((property, field, value) => {
		handleChange(businessData, setBusinessData, property, field, value)
	})

	return { handleChangeBusiness, fieldDataBusiness }
}
