import React from 'react'
import styles from '../../../../../assets/css/styleCommandCenter.module.css'
import Collapse from 'react-bootstrap/Collapse'
import RowContainer from '../../../../../components/commandCenter/RowContainer'
import RadioButton from '../../../../../components/commandCenter/RadioButton'
import { RiAddFill } from 'react-icons/ri'
import { BsTrash } from 'react-icons/bs'
import SwitchContainer from '../../../../../components/inputStyles/switchContainer'

export default function Tiers({ inputValues, SetInputValues, showSection, service }) {
	const handleChangeTiers = (indexTier, element, value) => {
		const newDataTiers = inputValues.Tiers.map((dataTier, index) => {
			if (index === indexTier) {
				return {
					...dataTier,
					[element]: value
				}
			}
			return dataTier
		})
		SetInputValues({ ...inputValues, Tiers: newDataTiers })
	}
	const deleteTiers = async (indexTier) => {
		const newDataTiers = inputValues.Tiers.filter((data, index) => index !== indexTier)
		SetInputValues({ ...inputValues, Tiers: newDataTiers })
	}
	const addTiers = async () => {
		const newTier = {
			mode: 2,
			highPayRange: 0,
			lowPayRange: 0,
			absorbDifference: false,
			greaterValueAllowed: false,
			allowOverride: false,
			priceFloat: 0,
			priceFix: 0,
			priceMinimum: 0,
			priceMaximum: 0
		}
		SetInputValues({ ...inputValues, Tiers: [...inputValues.Tiers, newTier] })
	}

	return (
		<Collapse in={showSection.tiers}>
			<div id="collapse-section">
				{inputValues.Tiers.map((Tiers, indexK) => (
					<div
						key={'Tiers' + indexK}
						style={{ width: '100%' }}>
						<div
							style={{
								borderTop: '1px solid #D9D9D9',
								width: '100%',
								minHeight: '100px',
								padding: '20px 0 12px 15px',
								display: 'flex',
								flexDirection: 'column',
								gap: '8px'
							}}>
							<div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-0 gap-3 mb-3 w-100">
								<label
									className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
									style={{ fontWeight: '600', lineHeight: '24px' }}>
									Payment Tier # {(indexK + 1).toString()}
								</label>

								<div className="d-flex flex-row flex-wrap justify-content-end align-items-center p-0 gap-2">
									{inputValues.Tiers.length > 1 && (
										<button
											onClick={() => deleteTiers(indexK)}
											className={styles['btn'] + ' ' + styles['btn-red-letter'] + ' ' + styles['no-border']}>
											<BsTrash className={styles['icon20']} />
											DELETE TIER
										</button>
									)}
									{inputValues.Tiers.length === indexK + 1 && (
										<button
											onClick={() => addTiers()}
											className={styles['btn'] + ' ' + styles['border-blue']}>
											<RiAddFill className={styles['icon20']} />
											ADD TIER
										</button>
									)}
								</div>

								<div className="d-flex flex-column w-100">
									<div className={styles['grid-container']}>
										<div className="d-flex flex-column align-items-start p-0">
											<RadioButton
												recurringState={Tiers.mode === 1 || Tiers.mode === 2 ? true : false}
												oneTimeState={Tiers.mode === 0 || Tiers.mode === 2 ? true : false}
												inputDisabled={false}
												label="Pay mode Offered"
												changeStateRecurring={() => handleChangeTiers(indexK, 'mode', Tiers.mode === 0 ? 2 : Tiers.mode === 1 ? 1 : Tiers.mode === 2 ? 0 : 1)}
												changeStateOneTimeState={() => handleChangeTiers(indexK, 'mode', Tiers.mode === 1 ? 2 : Tiers.mode === 0 ? 0 : Tiers.mode === 2 ? 1 : 0)}
												onlyOneTimeState={service.checkIn || service.cashIn ? true : false}
											/>
											<div
												className="d-flex flex-wrap flex-row align-items-center w-100"
												style={{ padding: '0 0 12px', gap: '8px' }}>
												<label
													className={styles['label-form'] + ' ' + styles['ft-s-13']}
													style={{ fontWeight: 600, lineHeight: '20px', letterSpacing: '0.0025em', width: '180px', minWidth: '20px' }}>
													Fees
												</label>
												<RowContainer
													inputType="Checkbox"
													divStyle={{ marginRight: '10px' }}
													value={Tiers.absorbDifference}
													inputDisabled={false}
													name={'absorbDifference[' + indexK + ']'}
													labelValue="Absorb Fee"
													setValue={(values) => handleChangeTiers(indexK, 'absorbDifference', values)}
												/>
												<RowContainer
													inputType="Checkbox"
													value={Tiers.greaterValueAllowed}
													inputDisabled={false}
													name={'greaterValueAllowed[' + indexK + ']'}
													labelValue="Difference Fee"
													setValue={(values) => handleChangeTiers(indexK, 'greaterValueAllowed', values)}
												/>
											</div>
											<div
												className="d-flex flex-wrap flex-row align-items-center w-100"
												style={{ padding: '0 0 12px', gap: '8px' }}>
												<div
													className={styles['label-form'] + ' ' + styles['ft-s-13']}
													style={{ lineHeight: '20px', width: '172px', minWidth: '20px' }}></div>
												<div className="d-flex flex-row flex-wrap align-items-center justify-content-center"></div>
												<SwitchContainer
													onChange={(values) => handleChangeTiers(indexK, 'allowOverride', values)}
													checked={Tiers.allowOverride}
													onColor="#70AD47"
													onHandleColor="#ffffff"
													inputDisabled={false}
													handleDiameter={20}
													height={20}
													width={38}
													labelStyle={{ color: '#212529', padding: '0 30px 0 0' }}
													labelValue="Allow override"
													className="react-switch"
													classNameLabel={styles['label-form'] + ' ' + styles['ft-s-12']}
													TooltipCaption="When enabled, allows transactions to override the paypoint’s service fee settings."
												/>
											</div>
										</div>
										<div className="d-flex flex-column align-items-start p-0">
											<RowContainer
												inputType="number"
												inputDisabled={false}
												classNameInput={(Tiers.highPayRange === null || !Tiers.highPayRange ? 0 : parseInt(Tiers.highPayRange)) <= (Tiers.lowPayRange === null || !Tiers.lowPayRange ? 0 : parseInt(Tiers.lowPayRange)) ? 'is-invalid' : ''}
												labelValue="Min. Ticket Allowed"
												name={'lowPayRange[' + indexK + ']'}
												placeholder="$0.00"
												value={Tiers.lowPayRange}
												setValue={(values) => handleChangeTiers(indexK, 'lowPayRange', values === null || !values ? 0 : values)}
												maxLength={18}
											/>

											<RowContainer
												inputType="number"
												inputDisabled={false}
												classNameInput={(Tiers.highPayRange === null || !Tiers.highPayRange ? 0 : parseInt(Tiers.highPayRange)) <= (Tiers.lowPayRange === null || !Tiers.lowPayRange ? 0 : parseInt(Tiers.lowPayRange)) ? 'is-invalid' : ''}
												labelValue="Max. Ticket Allowed"
												name={'highPayRange[' + indexK + ']'}
												placeholder="$0.00"
												value={Tiers.highPayRange}
												setValue={(values) => handleChangeTiers(indexK, 'highPayRange', values === null || !values ? 0 : values)}
												maxLength={18}
											/>
										</div>
									</div>
								</div>

								<label
									className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
									style={{ fontWeight: '600', lineHeight: '24px' }}>
									Pricing to payor
								</label>
								<div className="d-flex flex-column w-100">
									<div className={styles['grid-container']}>
										<div className="d-flex flex-column align-items-start p-0">
											<RowContainer
												inputType="number"
												inputDisabled={false}
												labelValue="% Fee Charged To Payor"
												name={'priceFloat[' + indexK + ']'}
												placeholder="0.00%"
												prefix=""
												suffix="%"
												value={Tiers.priceFloat}
												setValue={(values) => handleChangeTiers(indexK, 'priceFloat', values)}
												maxLength={18}
												maxValue={100}
											/>

											<RowContainer
												inputType="number"
												inputDisabled={false}
												labelValue="$ Fee Charged To Payor"
												name={'priceFix[' + indexK + ']'}
												placeholder="$0.00"
												value={Tiers.priceFix}
												setValue={(values) => handleChangeTiers(indexK, 'priceFix', values)}
												maxLength={18}
											/>
										</div>
										<div className="d-flex flex-column align-items-start p-0">
											<RowContainer
												inputType="number"
												inputDisabled={false}
												classNameInput={(Tiers.priceMaximum === null || !Tiers.priceMaximum ? 0 : parseInt(Tiers.priceMaximum)) < (Tiers.priceMinimum === null || !Tiers.priceMinimum ? 0 : parseInt(Tiers.priceMinimum)) ? 'is-invalid' : ''}
												labelValue="Min. Fee Charged To Payor"
												name={'priceMinimum[' + indexK + ']'}
												placeholder="$0.00"
												value={Tiers.priceMinimum}
												setValue={(values) => handleChangeTiers(indexK, 'priceMinimum', values === null || !values ? 0 : values)}
												maxLength={18}
											/>

											<RowContainer
												inputType="number"
												inputDisabled={false}
												classNameInput={(Tiers.priceMaximum === null || !Tiers.priceMaximum ? 0 : parseInt(Tiers.priceMaximum)) < (Tiers.priceMinimum === null || !Tiers.priceMinimum ? 0 : parseInt(Tiers.priceMinimum)) ? 'is-invalid' : ''}
												labelValue="Max Fee Charged To Payor"
												name={'priceMaximum[' + indexK + ']'}
												placeholder="$0.00"
												value={Tiers.priceMaximum}
												setValue={(values) => handleChangeTiers(indexK, 'priceMaximum', values === null || !values ? 0 : values)}
												maxLength={18}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</Collapse>
	)
}
