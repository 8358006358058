import { useState } from 'react'
import { observable, action } from 'mobx'

import { ToastContainer, Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import hellosingContext from '../context/hellosignContext'
import { templatesColumnsV1GET, userOneTimePUT } from '../../../../store/Template/Connections/connections'
import BusinessFunction from '../../../../store/ManagementPaypointStorage/recordFunction/BusinessFunction'
import OwnerFunction from '../../../../store/ManagementPaypointStorage/recordFunction/OwnerFunction'
import ProcessingFunction from '../../../../store/ManagementPaypointStorage/recordFunction/ProcessingFunction'
import ServiceFunctionHandle from '../../../../store/ManagementPaypointStorage/recordFunction/ServiceFunction/ServiceFunctionHandle'
import BankFunction from '../../../../store/ManagementPaypointStorage/recordFunction/BankFunction'
import SignerFunction from '../../../../store/ManagementPaypointStorage/recordFunction/SignerFunction'

export default function HelloSignStore({ children }) {
	const [business, setBusiness] = useState({})
	const Business = observable(business)
	const [owner, setOwner] = useState({})
	const Owner = observable(owner)
	const [processing, setProcessing] = useState({})
	const Processing = observable(processing)
	const [banking, setBanking] = useState({})
	const Banking = observable(banking)
	const [signer, setSigner] = useState({})
	const Signer = observable(signer)

	const [service, setService] = useState({
		card: {
			fees: [],
			visa: [],
			master: [],
			discover: [],
			amex: []
		},
		ach: {
			web: [],
			fees: []
		},
		mgm: {
			mgm: [],
			fees: []
		}
	})
	const Services = observable(service)

	const loadData = action(async (templateId) => {
		const uniqueUserToken = await userOneTimePUT()
		const result = await templatesColumnsV1GET(uniqueUserToken.data.responseData, templateId)
		let allData = {
			BusinessData: {},
			OwnersData: {},
			ProcessingData: {},
			ServicesData: {},
			BankData: {},
			SignerData: {}
		}
		result.data
			.filter((el) => el.section)
			.forEach((item) => {
				allData[`${item.section}Data`][item.hsColumn] = item.slug
			})
		return allData
	})
	const { handleChangeBusiness } = BusinessFunction({ business, setBusiness })
	const { handleChangeOwnerMapper } = OwnerFunction({ owner, setOwner })
	const { handleChangeProcessing, addNewCustomField, removeCustomField, handlerChangeCustomField } = ProcessingFunction({
		processing,
		setProcessing
	})
	const { handleChangeServicesHS } = ServiceFunctionHandle({
		service,
		setService
	})
	const { handleChangeSigner } = SignerFunction({ signer, setSigner })
	const { handleChangeBankingSimple } = BankFunction({ banking, setBanking })

	const getAllData = action((activeTemplate) => {
		let allData = business
		allData = {
			...business,
			...processing,
			...signer,
			...service,
			...banking,
			...owner
		}
		let cleanData = {}

		for (let key in allData) {
			if (activeTemplate.fields.includes(key)) {
				cleanData[key] = allData[key]
			}
		}

		return cleanData
	})

	return (
		<hellosingContext.Provider
			value={{
				loadData,
				Business,
				Processing,
				setBusiness,
				Owner,
				setOwner,
				owner,
				setProcessing,
				Banking,
				setService,
				Services,
				service,
				setBanking,
				handleChangeBusiness,
				addNewCustomField,
				removeCustomField,
				handlerChangeCustomField,
				handleChangeOwnerMapper,
				handleChangeProcessing,
				handleChangeBankingSimple,
				handleChangeServicesHS,
				signer,
				setSigner,
				Signer,
				handleChangeSigner,
				getAllData
			}}>
			<>
				{children}
				<ToastContainer transition={Bounce} />
			</>
		</hellosingContext.Provider>
	)
}
