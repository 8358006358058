import { action } from 'mobx'

export default function TemplateUnderwritingFunction({ underWritingData, setUnderWritingData, handleChange }) {
	const fieldDataUnderwriting = (data, templateCreator, newTemplate) => {
		let underwritingData = {
			method: data[templateCreator]?.underwritingData?.method ?? '',
			policyId: data[templateCreator]?.underwritingData?.policyId ?? '',
		}
		setUnderWritingData(underwritingData)
	}
	const handleChangeUnderwriting = action((field, value) => {
		handleChange(underWritingData, setUnderWritingData, '', field, value, true)
	})

	return { handleChangeUnderwriting, fieldDataUnderwriting }
}
