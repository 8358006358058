import { action } from 'mobx'

export default function TemplateBankingFunction({ bankingData, setBankingData, insertValues }) {
	const fieldDataBanking = (data, templateCreator, newTemplate) => {
		let newBankingData = { depositBank: {}, withdrawalBank: {}, termsAndConditions: {} }
		let structure = { visible: true, subHeader: '', subFooter: '', uploadDocuments: true, minimumDocuments: '1' }
		for (let element in structure) {
			newBankingData[element] = insertValues(data[templateCreator], 'documentsData', element, structure[element], 'oneElement')
		}

		structure = {
			depositBank: {
				bankName: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				routingNumber: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				accountNumber: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				accountType: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false }
			},
			withdrawalBank: {
				bankName: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				routingNumber: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				accountNumber: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false },
				accountType: { posRow: 0, posCol: 0, value: '', visible: true, readOnly: false, required: false }
			}
		}
		for (let parentElement in structure) {
			for (let element in structure[parentElement]) {
				newBankingData[parentElement][element] = insertValues(data[templateCreator].documentsData, parentElement, element, structure[parentElement][element])
			}
		}

		newBankingData.depositBank.visible = !data[templateCreator].documentsData || data[templateCreator].documentsData === null || data[templateCreator].documentsData === undefined ? true : insertValues(data[templateCreator].documentsData, 'depositBank', 'visible', true, 'oneElement')

		newBankingData.withdrawalBank.visible = !data[templateCreator].documentsData || data[templateCreator].documentsData === null || data[templateCreator].documentsData === undefined ? true : insertValues(data[templateCreator].documentsData, 'withdrawalBank', 'visible', true, 'oneElement')

		newBankingData.termsAndConditions.visible = !data[templateCreator].documentsData || data[templateCreator].documentsData === null || data[templateCreator].documentsData === undefined ? true : insertValues(data[templateCreator].documentsData, 'termsAndConditions', 'visible', true, 'oneElement')

		newBankingData.termsAndConditions.tcLinks = data[templateCreator]
			? data[templateCreator].documentsData
				? data[templateCreator].documentsData.termsAndConditions
					? data[templateCreator].documentsData.termsAndConditions.tcLinks.length > 0 || data[templateCreator].documentsData.termsAndConditions.tcLinks !== null
						? data[templateCreator].documentsData.termsAndConditions.tcLinks
						: [{ label: '', value: '' }]
					: [{ label: '', value: '' }]
				: [{ label: '', value: '' }]
			: [{ label: '', value: '' }]

		if (newTemplate) newBankingData.termsAndConditions.visible = false
		setBankingData(newBankingData)
	}
	const handleChangeBanking = action((subProperty, property, field, value, onlyField = false) => {
		if (!onlyField) {
			let valuesChecked = ''
			if (field === 'visible' && !value) {
				valuesChecked = { readOnly: false, required: false, visible: false }
			} else if (field === 'readOnly' && value) {
				valuesChecked = { readOnly: value, required: false, visible: true }
			} else if (field === 'required' && value) {
				valuesChecked = { readOnly: false, required: value, visible: true }
			}
			if (subProperty) {
				if (!valuesChecked) {
					setBankingData({ ...bankingData, [subProperty]: { ...bankingData[subProperty], visible: validCheckedBanks(subProperty, property, field, value), [property]: { ...bankingData[subProperty][property], [field]: value } } })
				} else {
					setBankingData({ ...bankingData, [subProperty]: { ...bankingData[subProperty], visible: validCheckedBanks(subProperty, property, field, value), [property]: { ...bankingData[subProperty][property], readOnly: valuesChecked.readOnly, required: valuesChecked.required, visible: valuesChecked.visible } } })
				}
			} else if ((property === 'depositBank' || property === 'withdrawalBank') && field === 'visible') {
				setBankingData({
					...bankingData,
					[property]: { ...bankingData[property], [field]: value, bankName: { ...bankingData[property].bankName, readOnly: false, required: false, visible: value }, routingNumber: { ...bankingData[property].routingNumber, readOnly: false, required: false, visible: value }, accountNumber: { ...bankingData[property].accountNumber, readOnly: false, required: false, visible: value }, accountType: { ...bankingData[property].accountType, readOnly: false, required: false, visible: value } }
				})
			} else {
				setBankingData({ ...bankingData, [property]: { ...bankingData[property], [field]: value } })
			}
		} else {
			setBankingData({ ...bankingData, [field]: value })
		}
	})
	const validCheckedBanks = (subProperty, property, field, value) => {
		if ((value === true && field === 'visible') || field === 'required') return true
		if (value === false && field === 'visible') {
			if (!value && field === 'required') return true
			const elementBank = Object.entries(bankingData[subProperty])
				.map((element) => element[0] !== property && element !== 'visible' && element[1])
				.filter((e) => e.visible)
			return elementBank.length === 0 ? false : true
		} else {
			return bankingData[subProperty].visible
		}
	}
	const addLink = action(() => {
		const newDataTcLinks = []
		bankingData.termsAndConditions.tcLinks.forEach(({ label, value }) => {
			newDataTcLinks.push({ label: label, value: value })
		})
		newDataTcLinks.push({ label: 'Terms and Conditions', value: '' })
		setBankingData({ ...bankingData, termsAndConditions: { ...bankingData.termsAndConditions, tcLinks: newDataTcLinks } })
	})
	const deleteLink = action((indexLink) => {
		const newDataTcLinks = bankingData.termsAndConditions.tcLinks.filter((data, index) => index !== indexLink)
		setBankingData({ ...bankingData, termsAndConditions: { ...bankingData.termsAndConditions, tcLinks: newDataTcLinks } })
	})
	const handleChangeBankLink = action((indexLink, field, value) => {
		const newDataTcLinks = bankingData.termsAndConditions.tcLinks.map((data, index) => {
			if (indexLink === index) {
				return {
					...data,
					[field]: value
				}
			}
			return data
		})
		setBankingData({ ...bankingData, termsAndConditions: { ...bankingData.termsAndConditions, tcLinks: newDataTcLinks } })
	})

	return { handleChangeBanking, handleChangeBankLink, fieldDataBanking, addLink, deleteLink }
}
