import React from 'react';
import { BiPencil } from 'react-icons/bi';

class ActionsRecord extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
        panelIsOpen: false
    };
    this.viewPanel = this.viewPanel.bind(this);
  }

  viewPanel(status){
    this.setState({panelIsOpen: status});
  }

  render() {

    return (
      <>
      <div className={this.state.panelIsOpen === true ? "p-actions open cursor-pointer" : "p-actions cursor-pointer"} onMouseLeave={(e)=> this.viewPanel(false)} onMouseEnter={(e)=> this.viewPanel(true)}>
      {this.state.panelIsOpen === true &&
       <>
          <BiPencil onClick={() => this.props.editAction(this.props.editData,this.props.idx)}  className="mr-1 cursor-pointer" style={{fontSize: "18px"}}/>
          <button onClick={() => this.props.deleteAction(this.props.idx)} className="remove-icon-btn mr-1" type="button">
          &nbsp;
          </button>
       </>
      }
      ...
      </div>
      </>
    );
  }
}

export { ActionsRecord };
