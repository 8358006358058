import React from 'react'
import RowContainer from '../../RowContainer'
import GlobalStore from '../../../../store/GlobalStore'

export default function Global_Gp({ inputDisabled, service, serviceName, indexService, indexGateways, handleChange, setClassValidate, classValidate }) {
	return (
		<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
			<div className="d-flex flex-column align-items-start p-0">
				<RowContainer
					inputType="mask"
					classNameInput={classValidate.gp.merchantId}
					inputDisabled={inputDisabled}
					labelValue="Merchant ID"
					name={'merchantId[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.merchantId}
					onBlur={() => setClassValidate({ ...classValidate, gp: { ...classValidate.gp, merchantId: service.Gateways[indexGateways].Connector.configuration.credentials.merchantId ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'merchantId', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>

				<RowContainer
					inputType="mask"
					inputDisabled={inputDisabled}
					labelValue="Tokenization Account Name"
					name={'tokenizationAccountName[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.tokenizationAccountName}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'tokenizationAccountName', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>

				<RowContainer
					inputType="mask"
					inputDisabled={inputDisabled}
					labelValue="Tokenization Account ID"
					name={'tokenizationAccountId[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.tokenizationAccountId}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'tokenizationAccountId', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>

				<RowContainer
					inputType="mask"
					classNameInput={classValidate.gp.transactionAccountName}
					inputDisabled={inputDisabled}
					labelValue="Transaction Account Name"
					name={'transactionAccountName[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.transactionAccountName}
					onBlur={() => setClassValidate({ ...classValidate, gp: { ...classValidate.gp, transactionAccountName: service.Gateways[indexGateways].Connector.configuration.credentials.transactionAccountName ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'transactionAccountName', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>
			</div>
			<div className="d-flex flex-column align-items-start p-0">
				<RowContainer
					inputType="mask"
					classNameInput={classValidate.gp.transactionAccountId}
					inputDisabled={inputDisabled}
					labelValue="Transaction Account ID"
					name={'transactionAccountId[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.transactionAccountId}
					onBlur={() => setClassValidate({ ...classValidate, gp: { ...classValidate.gp, transactionAccountId: service.Gateways[indexGateways].Connector.configuration.credentials.transactionAccountId ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'transactionAccountId', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>

				<RowContainer
					inputType="mask"
					classNameInput={classValidate.gp.fundingAccountName}
					inputDisabled={inputDisabled}
					labelValue="Funding Account Name"
					name={'fundingAccountName[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.fundingAccountName}
					onBlur={() => setClassValidate({ ...classValidate, gp: { ...classValidate.gp, fundingAccountName: service.Gateways[indexGateways].Connector.configuration.credentials.fundingAccountName ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'fundingAccountName', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>

				<RowContainer
					inputType="mask"
					classNameInput={classValidate.gp.fundingAccountId}
					inputDisabled={inputDisabled}
					labelValue="Funding Account ID"
					name={'fundingAccountId[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.fundingAccountId}
					onBlur={() => setClassValidate({ ...classValidate, gp: { ...classValidate.gp, fundingAccountId: service.Gateways[indexGateways].Connector.configuration.credentials.fundingAccountId ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'fundingAccountId', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>
			</div>
		</div>
	)
}
