import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import { BiChevronUp, BiChevronDown } from 'react-icons/bi'

export default function ContainerShowMoreLess({
	children,
	expand = false,
	textClose = 'SHOW MORE ',
	textOpen = 'SHOW LESS ',
	classCollapse = 'row'
}) {
	const [showCard, setShowCard] = useState(expand)

	useEffect(() => {
		setShowCard(expand)
	}, [expand])

	return (
		<>
			<Collapse in={showCard}>
				<div
					id="collapse-card"
					className={classCollapse}>
					{children}
				</div>
			</Collapse>
			<div className="divider mb-4">
				<span></span>
				<span>
					<Button
						className={`show-more-button ${showCard && 'expand'}`}
						onClick={() => setShowCard(!showCard)}
						data-qaid="showMoreTransactionFilter"
						aria-controls="collapse-card"
						aria-expanded={showCard}>
						{!showCard ? (
							<>
								{textClose}{' '}
								<BiChevronDown style={{ fontSize: '15px' }} />
							</>
						) : (
							<>
								{textOpen}{' '}
								<BiChevronUp style={{ fontSize: '15px' }} />
							</>
						)}
					</Button>
				</span>
				<span></span>
			</div>
		</>
	)
}
