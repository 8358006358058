import React from 'react';
import { Modal, Button } from 'react-bootstrap';

class ModalMarkPaid extends React.Component {
  render() {
    return (
      <>
        <Modal
          style={{ textAlign: 'center' }}
          show={this.props.isOpen}
          onHide={this.props.closeModal}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h5 data-qaid="markAsPaidModalInvoicesMoneyIn">Mark as Paid</h5>
            <p className="small">
              Are you sure you want to mark this invoice as paid?
            </p>
            <Button
              className="btn cancel-btn"
              variant="default"
              onClick={this.props.closeModal}
              data-qaid="cancelButtonMarkAsPaidInvoicesMoneyIn"
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              className="btn"
              variant="success"
              onClick={this.props.action}
              data-qaid="markAsPaidButtonInvoicesMoneyIn"
            >
              Mark as Paid
            </Button>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export { ModalMarkPaid };
