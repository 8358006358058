import axios from 'axios';
import * as Sentry from "@sentry/react";

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);