import React from 'react'
import Switch from 'react-switch'

export default function SwitchStyles({ labelUp = false, label, styles = '', classLabel = '', isString = false, onChange, checkValue, color = '#10A0E3', handleColor = '#ffffff', id }) {
	return (
		<div className="col">
			{labelUp ? (
				<div style={styles === '' ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : styles}>
					<label className={classLabel === '' ? 'small-small text-center' : classLabel}>{label}</label>
					<Switch
						onChange={(value) => onChange(!isString ? value : value.toString())}
						checked={checkValue}
						onColor={color}
						onHandleColor={handleColor}
						handleDiameter={20}
						boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
						activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
						height={20}
						width={38}
						className="react-switch"
						id={id}
					/>
				</div>
			) : (
				<label style={styles === '' ? { display: 'flex', alignItems: 'center' } : styles}>
					<Switch
						onChange={(value) => onChange(!isString ? value : value.toString())}
						checked={checkValue}
						onColor={color}
						onHandleColor={handleColor}
						handleDiameter={20}
						boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
						activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
						height={20}
						width={38}
						className="react-switch"
						id={id}
					/>
					<span className={classLabel === '' ? 'ml-2 small' : classLabel}>{label}</span>
				</label>
			)}
		</div>
	)
}
