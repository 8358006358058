import React from "react";
import { inject, observer } from "mobx-react";
import payabliIcon from "../assets/images/payabli-icon.png";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { PayabliStorageManager } from "../api/localStorageManager";
import { IdleTimeOutModal } from "../components/IdleTimeOutModal";
import RSC from "react-scrollbars-custom";
import { BiHomeAlt } from "@react-icons/all-files/bi/BiHomeAlt";
import { BiGroup } from "@react-icons/all-files/bi/BiGroup";
import { BiHelpCircle } from "@react-icons/all-files/bi/BiHelpCircle";
import { BiCheckSquare } from "@react-icons/all-files/bi/BiCheckSquare";
import { BiIdCard } from "@react-icons/all-files/bi/BiIdCard";
import { BiCodeBlock } from "@react-icons/all-files/bi/BiCodeBlock";
import { BiCog } from "@react-icons/all-files/bi/BiCog";
import { BiFile } from "@react-icons/all-files/bi/BiFile";
import { BiLayout } from "@react-icons/all-files/bi/BiLayout";
import { HiMenuAlt2 } from "@react-icons/all-files/hi/HiMenuAlt2";
import { BiBullseye } from "@react-icons/all-files/bi/BiBullseye";
import moneyin from "../assets/images/moneyin.svg";
import moneyout from "../assets/images/moneyout.svg";
import {
  BiBarChartSquare,
  BiBookContent,
  BiChevronDown,
  BiDevices,
  BiReceipt,
  BiShoppingBag,
} from "react-icons/bi";
import { SecurityManager } from "../api/securityManager";
import { IddleManager } from "../api/iddleManager";
import { Brand } from "./Brand";
import Lottie from "react-lottie";
import LoadingApplication from "../assets/lotties/LoadingSquaredApplication.json";
import md5 from "crypto-js/md5";
import ErrorBoundary from "../utils/ErrorBoundary";
import * as Sentry from "@sentry/react";
import { MdOutlineFeedback } from "react-icons/md";
import { FeedbackModal } from "./FeedbackModal";
import { LuInbox } from "react-icons/lu";

@inject("store", "global", "user")
@observer
class Layout extends React.Component {
  constructor(props) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    super(props);
    this.state = {
      openMenu:
        encryptStorage && encryptStorage.getItem("pMenu") === "1"
          ? false
          : true,
      fullBody:
        encryptStorage && encryptStorage.getItem("pMenu") === "1"
          ? true
          : false,
      selected: "",
      menuPortfolioActiveKey: "closed",
      menuReportsActiveKey: "closed",
      menuReportsMoneyOutActiveKey: "closed",
      menuReportsDevelopersActiveKey: "closed",
      menuRiskControlCenterActiveKey: "closed",
      menuRiskControlCenterReportActiveKey: "closed",
      showModal: false,
      feedbackModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      intervalId: null,
      componentCreatorPath: "reportDashboard",
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleMainMenu = this.handleMainMenu.bind(this);
    this.prevent = this.prevent.bind(this);
    this.handlePortfolioAccordion = this.handlePortfolioAccordion.bind(this);
    this.handleReportsAccordion = this.handleReportsAccordion.bind(this);
    this.handleReportsMoneyOutAccordion =
      this.handleReportsMoneyOutAccordion.bind(this);
    this.idleTimer = null;
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleTimeOutKey = this.handleTimeOutKey.bind(this);
    this.displayIddleModal = this.displayIddleModal.bind(this);
    this.handleReportsDevelopersAccordion =
      this.handleReportsDevelopersAccordion.bind(this);
    this.handleRiskControlCenterAccordion =
      this.handleRiskControlCenterAccordion.bind(this);
    this.handleRiskControlCenterReportAccordion =
      this.handleRiskControlCenterReportAccordion.bind(this);
    this.goToCreator = this.goToCreator.bind(this);
  }

  goToCreator(componentCreatorPath) {
    if (componentCreatorPath === this.state.componentCreatorPath) {
      return;
    }
    this.setState({ componentCreatorPath: componentCreatorPath });
    this.props.global.setComponentCreatorPath(componentCreatorPath);
    this.props.global.setLoading(true);
  }

  handleTimeOutKey() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let remaining = encryptStorage.getItem("pUser").remaining;

    if (new Date().getTime() < remaining) return;
    return SecurityManager.refreshToken(encryptStorage.getItem("pToken"))
    .then(() => this.setState({ showModal: false }))
    .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
    });
  }

  handleClose() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let thisObj = this;

    return SecurityManager.refreshToken(encryptStorage.getItem("pToken"))
    .then(() => thisObj.setState({ showModal: false }))
    .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
    });
  }

  handleLogout() {
    this.setState({ showModal: false });
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    if (encryptStorage) {
      this.props.history.push(
        "/" +
          encryptStorage.getItem(
            `${PayabliStorageManager.getEntryName()}_pEntry`
          ).pEntry +
          "/logout"
      );
    }
  }

  displayIddleModal(status, countDown) {
    this.setState({ showModal: status , countDown : countDown});
  }

  handleMainMenu() {
    if (window.innerWidth < 960) {
      this.setState({
        openMenu: !this.state.openMenu,
      });
    } else {
      let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
      encryptStorage.setItem("pMenu", this.state.openMenu ? 1 : 0);
      this.setState({
        openMenu: !this.state.openMenu,
        fullBody: !this.state.fullBody,
      });
    }
  }

  updateDimensions() {
    if (window.innerWidth < 960) {
      this.setState({
        openMenu: false,
        fullBody: true,
      });
    } else {
      this.setState({
        openMenu: true,
        fullBody: false,
      });
    }
  }

  componentDidMount() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let pUser =
      encryptStorage && encryptStorage.getItem("pUser")
        ? encryptStorage.getItem("pUser")
        : null;
    let orgId =
      encryptStorage &&
      encryptStorage.getItem(this.props.global.getURLEntry() + "_pEntry")
        ? encryptStorage.getItem(this.props.global.getURLEntry() + "_pEntry")
            .orgId
        : null;

    if (!orgId) {
      window.location.href =
        "/" + PayabliStorageManager.getEntryName() + "/logout";
    }

    if (encryptStorage) {
      
      IddleManager.startTimer(this.displayIddleModal);

      const lr = new BroadcastChannel(
        `${PayabliStorageManager.getEntryName()}_payabliLocationReload`
      );
      lr.onmessage = (eventMessage) => {
        window.location.reload();
      };

      // Cloase Organization SSO Tab
      if (pUser && pUser.id) {
        const broadcastOrg = new BroadcastChannel(
          `${md5(pUser.id).toString()}_closeSSOOrganizationTab`
        );
        broadcastOrg.onmessage = (eventMessage) => {
          if (
            eventMessage.data?.entryName ===
            PayabliStorageManager.getEntryName()
          ) {
            window.close();
          }
        };
      }

      window.addEventListener("resize", this.updateDimensions);
      if (encryptStorage.getItem("pMenu") !== "1") {
        this.updateDimensions();
      }

      var intervalId = setInterval(this.handleTimeOutKey, 60000);
      // store intervalId in the state so it can be accessed later:
      this.setState({
        intervalId: intervalId,
      });
      if (this.props.match) {
        let path = this.props.match.path;
        this.setState({ selected: path });
        if (
          path === "/:entryUrl/report/organizations/:idOrg?" ||
          path === "/:entryUrl/report/paypoints" ||
          path === "/:entryUrl/report/paypoints/:idOrg?" ||
          path === "/:entryUrl/report/summaryresiduals" ||
          path === "/:entryUrl/boarding/applications" ||
          path === "/:entryUrl/boarding/templates" ||
          path === "/:entryUrl/boarding/links" ||
          path === "/:entryUrl/orgdetails/:id" ||
          path === "/:entryUrl/paypoints/overview/:entry/:id" ||
          path === "/:entryUrl/boarding/newapptpl/:id/:idOrg?" ||
          path === "/:entryUrl/boarding/newtemplate" ||
          path === "/:entryUrl/boarding/edittemplate/:id" ||
          path === "/:entryUrl/boarding/applicationedit/:id" ||
          path === "/:entryUrl/boarding/viewapplication/:id"
        ) {
          this.setState({ menuPortfolioActiveKey: "0" });
        }

        if (
          path === "/:entryUrl/report/transactions" ||
          path === "/:entryUrl/report/autopays" ||
          path === "/:entryUrl/report/batches" ||
          path === "/:entryUrl/report/chargebacks/:paymentTransId?" ||
          path === "/:entryUrl/report/batches/:idBatch" ||
          path === "/:entryUrl/report/transactions/idtransrefund/:idtransRefund"
        ) {
          this.setState({ menuReportsActiveKey: "0" });
        }

        if (
          path === "/:entryUrl/report/transactions/out/:id?" ||
          path === "/:entryUrl/report/batches/out" ||
          path === "/:entryUrl/report/batches/transactions/out/:idBatch"
        ) {
          this.setState({ menuReportsMoneyOutActiveKey: "0" });
        }

        if (
          path === "/:entryUrl/developers/apitokens" ||
          path === "/:entryUrl/developers/reports" ||
          path === "/:entryUrl/developers/sftpaccounts" ||
          path === "/:entryUrl/developers/creator"
        ) {
          this.setState({ menuReportsDevelopersActiveKey: "0" });
        }

        if (path === "/:entryUrl/riskcontrol/reports") {
          this.setState({
            menuRiskControlCenterActiveKey: "0",
            menuRiskControlCenterReportActiveKey: "0",
          });
        }
      }

      this.checkPermissions();
    }

    window.addEventListener("message", (event) => {
      if (event.data === "componentCreatorShowLoading") {
        this.props.global.setLoading(true);
      }
      if (event.data === "componentCreatorHideLoading") {
        this.props.global.setLoading(false);
      }
    });
  }

  checkPermissions() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let userPermissions =
      encryptStorage &&
      encryptStorage.getItem("pUser") &&
      encryptStorage.getItem("pUser").permissions
        ? encryptStorage.getItem("pUser").permissions
        : [];

    if (!this.props.user.hasAccess(this.props.match.path, userPermissions)) {
      this.props.history.push(
        "/" + this.props.global.getURLEntry() + "/accessdenied"
      );
    }
  }

  prevent(e) {
    if (e.target.getAttribute("data") === "no-prevent") {
      e.preventDefault();
      window.location.href = e.target.getAttribute("href");
    } else {
      e.preventDefault();
    }
  }

  handlePortfolioAccordion(e) {
    this.prevent(e);
    this.setState({
      menuPortfolioActiveKey:
        this.state.menuPortfolioActiveKey === "closed" ? "0" : "closed",
    });
  }

  handleReportsAccordion(e) {
    this.prevent(e);
    this.setState({
      menuReportsActiveKey:
        this.state.menuReportsActiveKey === "closed" ? "0" : "closed",
    });
  }

  handleReportsMoneyOutAccordion(e) {
    this.prevent(e);
    this.setState({
      menuReportsMoneyOutActiveKey:
        this.state.menuReportsMoneyOutActiveKey === "closed" ? "0" : "closed",
    });
  }

  handleReportsDevelopersAccordion(e) {
    this.prevent(e);
    this.setState({
      menuReportsDevelopersActiveKey:
        this.state.menuReportsDevelopersActiveKey === "closed" ? "0" : "closed",
    });
  }

  handleRiskControlCenterAccordion(e) {
    this.prevent(e);
    this.setState({
      menuRiskControlCenterActiveKey:
        this.state.menuRiskControlCenterActiveKey === "closed" ? "0" : "closed",
      menuRiskControlCenterReportActiveKey: "closed",
    });
  }

  handleRiskControlCenterReportAccordion(e) {
    this.prevent(e);
    this.setState({
      menuRiskControlCenterReportActiveKey:
        this.state.menuRiskControlCenterReportActiveKey === "closed"
          ? "0"
          : "closed",
    });
  }
  
  openAtlasCustomerPortal() {
    const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    const user = encryptStorage?.getItem("pUser")
    if(!user.email || !user.atlasHmac) {
      return console.error('User email or atlasHmac not found')
    }
    window.Atlas.call('identify', {
      userHash: user.atlasHmac,
      userId: user.email,
      email: user.email,
      onComplete: () => {
          window.open('https://support-center.payabli.com/customer-portal', '_blank')
      },
    });
  }

  render() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let settingsUrls = [
      "/:entryUrl/orgdetails",
      "/:entryUrl/profile/:id/:entryName?",
      "/:entryUrl/fields",
      "/:entryUrl/notifications",
      "/:entryUrl/report/users",
    ];
    if (encryptStorage && !encryptStorage.getItem("pUser")) {
      if (
        !window.location.href.endsWith("/login") &&
        !window.location.href.endsWith("/logout") &&
        encryptStorage.getItem(this.props.global.getURLEntry() + "_pEntry")
      ) {
        window.location.replace(
          "/" +
            encryptStorage.getItem(this.props.global.getURLEntry() + "_pEntry")
              .pEntry +
            "/login"
        );
      } else {
        window.location.replace("/");
      }
    }

    let userPermissions =
      encryptStorage &&
      encryptStorage.getItem("pUser") &&
      encryptStorage.getItem("pUser").permissions
        ? encryptStorage.getItem("pUser").permissions
        : [];
    let settingsLinkTo = null;

    if (encryptStorage && !userPermissions) {
      window.location.href =
        "/" +
        encryptStorage.getItem(this.props.global.getURLEntry() + "_pEntry")
          .pEntry +
        "/logout";
    }

    if (userPermissions.indexOf("Developers") !== -1) {
      settingsLinkTo = "/" + this.props.global.getURLEntry() + "/developers";
    }
    if (userPermissions.indexOf("Manage Users") !== -1) {
      settingsLinkTo = "/" + this.props.global.getURLEntry() + "/report/users";
    }
    if (userPermissions.indexOf("Notifications") !== -1) {
      settingsLinkTo = "/" + this.props.global.getURLEntry() + "/notifications";
    }
    if (userPermissions.indexOf("Custom Fields") !== -1) {
      settingsLinkTo = "/" + this.props.global.getURLEntry() + "/fields";
    }
    if (encryptStorage && userPermissions.indexOf("Profile") !== -1) {
      settingsLinkTo =
        "/" +
        this.props.global.getURLEntry() +
        "/profile/" +
        encryptStorage.getItem("pUser").id;
    }
    if (userPermissions.indexOf("Organizations") !== -1) {
      settingsLinkTo = "/" + this.props.global.getURLEntry() + "/orgdetails";
    }

    const PortfolioMenu = (
      <Popover
        style={{
          width: "180px",
          marginLeft: "30px",
          boxShadow: "none",
        }}
      >
        <Popover.Content style={{ minWidth: "180px" }}>
          <div className="menu-accordion-body-popover">
            {userPermissions.indexOf("Organizations") !== -1 && (
              <a
                href={
                  "/" +
                  this.props.global.getURLEntry() +
                  "/report/organizations"
                }
                data="no-prevent"
              >
                Organizations
              </a>
            )}
            {userPermissions.indexOf("Paypoints") !== -1 && (
              <a
                href={
                  "/" + this.props.global.getURLEntry() + "/report/paypoints"
                }
                data="no-prevent"
              >
                PayPoints
              </a>
            )}
            {userPermissions.indexOf("Residuals") !== -1 && (
              <a
                href={
                  "/" +
                  this.props.global.getURLEntry() +
                  "/report/summaryresiduals"
                }
                data="no-prevent"
              >
                Residuals
              </a>
            )}
            {userPermissions.indexOf("Applications") !== -1 && (
              <a
                href={
                  "/" +
                  this.props.global.getURLEntry() +
                  "/boarding/applications"
                }
                data="no-prevent"
              >
                Applications
              </a>
            )}
            {userPermissions.indexOf("Templates") !== -1 && (
              <a
                href={
                  "/" + this.props.global.getURLEntry() + "/boarding/templates"
                }
                data="no-prevent"
              >
                Templates
              </a>
            )}
            {userPermissions.indexOf("Links") !== -1 && (
              <a
                href={"/" + this.props.global.getURLEntry() + "/boarding/links"}
                data="no-prevent"
              >
                Boarding Links
              </a>
            )}
          </div>
        </Popover.Content>
      </Popover>
    );

    const ReportsMenu = (
      <Popover
        style={{
          width: "180px",
          marginLeft: "30px",
          boxShadow: "none",
        }}
      >
        <Popover.Content style={{ minWidth: "180px" }}>
          <div className="menu-accordion-body-popover">
            {userPermissions.indexOf("Transaction") !== -1 && (
              <a
                href={
                  "/" + this.props.global.getURLEntry() + "/report/transactions"
                }
                data="no-prevent"
              >
                Transactions
              </a>
            )}
            {userPermissions.indexOf("Subscriptions") !== -1 && (
              <a
                href={
                  "/" + this.props.global.getURLEntry() + "/report/autopays"
                }
                data="no-prevent"
              >
                Autopays
              </a>
            )}
            {userPermissions.indexOf("Batch") !== -1 && (
              <a
                href={"/" + this.props.global.getURLEntry() + "/report/batches"}
                data="no-prevent"
              >
                Batches & Funding
              </a>
            )}
            {userPermissions.indexOf("Chargeback") !== -1 && (
              <a
                href={
                  "/" + this.props.global.getURLEntry() + "/report/chargebacks"
                }
                data="no-prevent"
              >
                ACH Returns & Chargebacks
              </a>
            )}
          </div>
        </Popover.Content>
      </Popover>
    );

    const ReportsMoneyOutMenu = (
      <Popover
        style={{
          width: "180px",
          marginLeft: "30px",
          boxShadow: "none",
        }}
      >
        <Popover.Content style={{ minWidth: "180px" }}>
          <div className="menu-accordion-body-popover">
                {userPermissions.includes("Transaction") && (
                <a
                  href={
                    "/" +
                    this.props.global.getURLEntry() +
                    "/report/transactions/out"
                  }
                  data="no-prevent"
                >
                  Transactions
                </a>
                )}
                {userPermissions.includes("Batch") && (
                <a
                  href={
                    "/" +
                    this.props.global.getURLEntry() +
                    "/report/batches/out"
                  }
                  data="no-prevent"
                >
                  Batches & Funding
                </a>
                )}
          </div>
        </Popover.Content>
      </Popover>
    );
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingApplication,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <>
        <IdleTimeOutModal
          showModal={this.state.showModal}
          countDown={this.state.countDown}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />
         <FeedbackModal
          open={this.state.feedbackModal}
          user={encryptStorage.getItem("pUser")}
          onClose={() => this.setState({ feedbackModal: false })}
        />
        <div>
          {this.props.global.isLoading && (
            <div
              id="main-loading-layer"
              className="d-flex justify-content-center align-items-center"
            >
              {/* <div className="spinner-border" role="status">
                                </div> */}
              <Lottie options={defaultOptions} height={100} width={100} />
            </div>
          )}
          {this.props.fullScreen === true ? (
            <>{this.props.children}</>
          ) : (
            <>
              <div
                className={
                  this.state.openMenu ? "main-left-bar" : "main-left-bar open"
                }
              >
                <HiMenuAlt2
                  className="hamburgerButton"
                  onClick={() => this.handleMainMenu()}
                />
                <div className="main-menu">
                  <div id="main-branding">
                    <a
                      href={
                        "/" + this.props.global.getURLEntry() + "/dashboard"
                      }
                    >
                      <Brand />
                    </a>
                  </div>
                  <div id="main-branding-icon">
                    <a
                      href={
                        "/" + this.props.global.getURLEntry() + "/dashboard"
                      }
                    >
                      <img alt="" src={payabliIcon} />
                    </a>
                  </div>
                  <RSC id="RSC-Example" style={{ height: "71vh" }}>
                    <ul id="main-menu-ul">
                      <li>
                        <div className="header">Menu</div>
                      </li>
                      {userPermissions.indexOf("Dashboard") !== -1 && (
                        <li
                          className={
                            this.state.selected === "/dashboard"
                              ? "selected"
                              : ""
                          }
                        >
                          <a
                            title="Dashboard"
                            href={
                              "/" +
                              this.props.global.getURLEntry() +
                              "/dashboard"
                            }
                            data-qaid="leftMenuDashboardLink"
                          >
                            <div className="icon">
                              <BiHomeAlt />
                            </div>
                            Dashboard
                          </a>
                        </li>
                      )}
                      {userPermissions.indexOf("Portfolio") !== -1 && (
                        <li>
                          <Accordion
                            activeKey={this.state.menuPortfolioActiveKey}
                          >
                            <Accordion.Toggle
                              as={Link}
                              title="Actions"
                              to="#"
                              onClick={(e) => this.handlePortfolioAccordion(e)}
                              eventKey={this.state.openMenu ? "0" : "99"}
                            >
                              <div className="icon">
                                {this.state.openMenu ? (
                                  <BiLayout />
                                ) : (
                                  <OverlayTrigger
                                    rootClose
                                    trigger="click"
                                    placement="right"
                                    overlay={PortfolioMenu}
                                  >
                                    <BiLayout />
                                  </OverlayTrigger>
                                )}
                              </div>{" "}
                              Portfolio{" "}
                              <BiChevronDown
                                className="float-right"
                                style={{
                                  fontSize: "15px",
                                }}
                                data-qaid="leftMenuPortfolioMenu"
                              />
                            </Accordion.Toggle>
                            {this.state.openMenu ? (
                              <Accordion.Collapse eventKey="0">
                                <div className="menu-accordion-body">
                                  {userPermissions.indexOf("Organizations") !==
                                    -1 && (
                                    <a
                                      className={
                                        this.state.selected ===
                                          "/:entryUrl/report/organizations/:idOrg?" ||
                                        this.state.selected ===
                                          "/orgdetails/:id" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/paypoints/:idOrg" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/transactions/org/:idOrg" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/autopays/org/:idOrg" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/residuals/org/:idOrg?" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/usersorg/:idOrg"
                                          ? "selected"
                                          : "" ||
                                            this.state.selected ===
                                              "/:entryUrl/report/customers/org/:idOrg"
                                          ? "selected"
                                          : ""
                                      }
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/report/organizations"
                                      }
                                      data-qaid="leftMenuOrganizationsLink"
                                    >
                                      Organizations
                                    </a>
                                  )}
                                  {userPermissions.indexOf("Paypoints") !==
                                    -1 && (
                                    <a
                                      className={
                                        this.state.selected ===
                                          "/paypoints/overview/:entry/:id" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/transactions/paypoint/:entry/:idPaypoint" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/autopays/paypoint/:entry/:idPaypoint" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/residuals/paypoint/:entry/:idPaypoint" ||
                                        this.state.selected ===
                                          "/vterminal/:entry/:idPaypoint" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/paypoints"
                                          ? "selected"
                                          : "" ||
                                            this.state.selected ===
                                              "/:entryUrl/management/paypoints/:oid?/:id?"
                                          ? "selected"
                                          : ""
                                      }
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/report/paypoints"
                                      }
                                      data-qaid="leftMenuPayPointsLink"
                                    >
                                      PayPoints
                                    </a>
                                  )}
                                  {userPermissions.indexOf("Residuals") !==
                                    -1 && (
                                    <a
                                      className={
                                        this.state.selected ===
                                          "/:entryUrl/report/summaryresiduals" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/residuals"
                                          ? "selected mb-4"
                                          : "mb-4"
                                      }
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/report/summaryresiduals"
                                      }
                                      data-qaid="leftMenuResidualsLink"
                                    >
                                      Residuals
                                    </a>
                                  )}
                                  {userPermissions.indexOf("Applications") !==
                                    -1 && (
                                    <a
                                      className={
                                        this.state.selected ===
                                          "/:entryUrl/boarding/applications" ||
                                        this.state.selected ===
                                          "/:entryUrl/boarding/applicationedit/:id" ||
                                        this.state.selected ===
                                          "/:entryUrl/boarding/newapptpl/:id/:idOrg?" ||
                                        this.state.selected ===
                                          "/:entryUrl/boarding/viewapplication/:id"
                                          ? "selected"
                                          : ""
                                      }
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/boarding/applications"
                                      }
                                      data-qaid="leftMenuApplicationsLink"
                                    >
                                      Applications
                                    </a>
                                  )}
                                  {userPermissions.indexOf("Templates") !==
                                    -1 && (
                                    <a
                                      className={
                                        this.state.selected ===
                                          "/:entryUrl/boarding/templates" ||
                                        this.state.selected ===
                                          "/:entryUrl/boarding/newtemplate" ||
                                        this.state.selected ===
                                          "/:entryUrl/boarding/edittemplate/:id"
                                          ? "selected"
                                          : ""
                                      }
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/boarding/templates"
                                      }
                                      data-qaid="leftMenuTemplatesLink"
                                    >
                                      Templates
                                    </a>
                                  )}
                                  {userPermissions.indexOf("Links") !== -1 && (
                                    <a
                                      className={
                                        this.state.selected ===
                                        "/:entryUrl/boarding/links"
                                          ? "selected"
                                          : ""
                                      }
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/boarding/links"
                                      }
                                      data-qaid="leftMenuBoardingLinksLink"
                                    >
                                      Boarding Links
                                    </a>
                                  )}
                                </div>
                              </Accordion.Collapse>
                            ) : (
                              ""
                            )}
                          </Accordion>
                        </li>
                      )}
                      <li className="mt-4">
                        <div className="header">Money In</div>
                      </li>
                      {userPermissions.includes("Reports") && (
                      <li>
                        <Accordion activeKey={this.state.menuReportsActiveKey}>
                          <Accordion.Toggle
                            as={Link}
                            title="Actions"
                            to="#"
                            onClick={(e) => this.handleReportsAccordion(e)}
                            eventKey={this.state.openMenu ? "0" : "99"}
                          >
                            <div className="icon">
                              {this.state.openMenu ? (
                                <img alt="" src={moneyin} />
                              ) : (
                                <OverlayTrigger
                                  rootClose
                                  trigger="click"
                                  placement="right"
                                  overlay={ReportsMenu}
                                >
                                  <img alt="" src={moneyin} />
                                </OverlayTrigger>
                              )}
                            </div>
                            Reports{" "}
                            <BiChevronDown
                              className="float-right"
                              style={{
                                fontSize: "15px",
                              }}
                              data-qaid="leftMenuReportsMoneyIn"
                            />
                          </Accordion.Toggle>
                          {this.state.openMenu ? (
                            <Accordion.Collapse eventKey="0">
                              <div className="menu-accordion-body">
                                {userPermissions.indexOf("Transaction") !==
                                  -1 && (
                                  <a
                                    className={
                                      this.state.selected ===
                                        "/:entryUrl/report/transactions" ||
                                      this.state.selected ===
                                        "/:entryUrl/report/transactions/idtransrefund/:idtransRefund"
                                        ? "selected"
                                        : ""
                                    }
                                    href={
                                      "/" +
                                      this.props.global.getURLEntry() +
                                      "/report/transactions"
                                    }
                                    data-qaid="leftMenuTransactionsReportsLink"
                                  >
                                    Transactions
                                  </a>
                                )}
                                {userPermissions.indexOf("Subscriptions") !==
                                  -1 && (
                                  <a
                                    className={
                                      this.state.selected ===
                                      "/:entryUrl/report/autopays"
                                        ? "selected"
                                        : ""
                                    }
                                    href={
                                      "/" +
                                      this.props.global.getURLEntry() +
                                      "/report/autopays"
                                    }
                                    data-qaid="leftMenuAutopaysReportsLink"
                                  >
                                    Autopays
                                  </a>
                                )}
                                {userPermissions.indexOf("Batch") !== -1 && (
                                  <a
                                    className={
                                      this.state.selected ===
                                        "/:entryUrl/report/batches" ||
                                      this.state.selected ===
                                        "/:entryUrl/report/batches/:idBatch"
                                        ? "selected"
                                        : ""
                                    }
                                    href={
                                      "/" +
                                      this.props.global.getURLEntry() +
                                      "/report/batches"
                                    }
                                    data-qaid="leftMenuBatchesReportsLink"
                                  >
                                    Batches & Funding
                                  </a>
                                )}
                                {userPermissions.indexOf("Chargeback") !==
                                  -1 && (
                                  <a
                                    className={
                                      this.state.selected ===
                                      "/:entryUrl/report/chargebacks/:paymentTransId?"
                                        ? "selected"
                                        : ""
                                    }
                                    href={
                                      "/" +
                                      this.props.global.getURLEntry() +
                                      "/report/chargebacks"
                                    }
                                    data-qaid="leftMenuReturnsReportsLink"
                                  >
                                    ACH Returns & Chargebacks
                                  </a>
                                )}
                              </div>
                            </Accordion.Collapse>
                          ) : (
                            ""
                          )}
                        </Accordion>
                      </li>
                      )
                      }
                      {userPermissions.indexOf("Customers") !== -1 && (
                        <li
                          className={
                            this.state.selected ===
                              "/:entryUrl/report/customers/:command?" ||
                            this.state.selected === "/:entryUrl/customer/:id" ||
                            this.state.selected ===
                              "/:entryUrl/report/transactions/:id/:gobackData?" ||
                            this.state.selected ===
                              "/:entryUrl/report/autopays/:id?/:command?" ||
                            this.state.selected ===
                              "/:entryUrl/customer/paymethods/:id/:command?"
                              ? "selected"
                              : ""
                          }
                        >
                          <a
                            title="Customers"
                            href={
                              "/" +
                              this.props.global.getURLEntry() +
                              "/report/customers"
                            }
                            data-qaid="leftMenuCustomersReportsLink"
                          >
                            <div className="icon">
                              <BiGroup />
                            </div>
                            Customers
                          </a>
                        </li>
                      )}
                      {userPermissions.indexOf("Invoices") !== -1 && (
                        <li
                          className={
                            this.state.selected ===
                              "/:entryUrl/report/invoices" ||
                            this.state.selected === "/:entryUrl/invoices/:id?"
                              ? "selected"
                              : ""
                          }
                        >
                          <a
                            title="Invoices"
                            href={
                              "/" +
                              this.props.global.getURLEntry() +
                              "/report/invoices"
                            }
                            data-qaid="leftMenuInvoicesReportsLink"
                          >
                            <div className="icon">
                              <BiReceipt />
                            </div>
                            Invoices
                          </a>
                        </li>
                      )}
                      {userPermissions.indexOf("Virtual Terminal") !== -1 && (
                        <li
                          className={
                            this.state.selected === "/:entryUrl/report/catalog"
                              ? "selected"
                              : ""
                          }
                        >
                          <a
                            title="Catalog"
                            href={
                              "/" +
                              this.props.global.getURLEntry() +
                              "/report/catalog"
                            }
                            data-qaid="leftMenuCatalogReportsLink"
                          >
                            <div className="icon">
                              <BiShoppingBag />
                            </div>
                            Catalog
                          </a>
                        </li>
                      )}
                      {(userPermissions.includes("MoneyOut Actions")) && (
                        <div>
                          <li className="mt-4">
                            <div className="header">Money Out</div>
                          </li>
                          {(userPermissions.includes("Reports") && userPermissions.includes("MoneyOut Actions"))
                          && (userPermissions.includes("Transaction") || userPermissions.includes("Batch")) && (
                          <li>
                            <Accordion
                              activeKey={
                                this.state.menuReportsMoneyOutActiveKey
                              }
                            >
                              <Accordion.Toggle
                                as={Link}
                                title="Reports"
                                to="#"
                                onClick={(e) =>
                                  this.handleReportsMoneyOutAccordion(e)
                                }
                                eventKey={this.state.openMenu ? "0" : "99"}
                              >
                                <div className="icon">
                                  {this.state.openMenu ? (
                                    <img alt="" src={moneyout} />
                                  ) : (
                                    <OverlayTrigger
                                      rootClose
                                      trigger="click"
                                      placement="right"
                                      overlay={ReportsMoneyOutMenu}
                                    >
                                      <img alt="" src={moneyout} />
                                    </OverlayTrigger>
                                  )}
                                </div>
                                Reports{" "}
                                <BiChevronDown
                                  className="float-right"
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  data-qaid="leftMenuReportsMoneyOut"
                                />
                              </Accordion.Toggle>
                              {this.state.openMenu ? (
                                <Accordion.Collapse eventKey="0">
                                  <div className="menu-accordion-body">
                                  {userPermissions.includes("Transaction") && (
                                    <a
                                      className={
                                        this.state.selected ===
                                        "/:entryUrl/report/transactions/out/:id?"
                                          ? "selected"
                                          : ""
                                      }
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/report/transactions/out"
                                      }
                                      data-qaid="leftMenuTransactionsOutReportsLink"
                                    >
                                      Transactions
                                    </a>
                                  )}
                                   {userPermissions.includes("Batch") && (
                                    <a
                                      className={
                                        this.state.selected ===
                                          "/:entryUrl/report/batches/out" ||
                                        this.state.selected ===
                                          "/:entryUrl/report/batches/transactions/out/:idBatch"
                                          ? "selected"
                                          : ""
                                      }
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/report/batches/out"
                                      }
                                      data-qaid="leftMenuBatchesOutReportsLink"
                                    >
                                      Batches & Funding
                                    </a>
                                    )}
                                  </div>
                                </Accordion.Collapse>
                              ) : (
                                ""
                              )}
                            </Accordion>
                          </li>
                          )}
                          {userPermissions.includes("Vendors") && (
                            <li
                              className={
                                this.state.selected ===
                                "/:entryUrl/report/vendors"
                                  ? "selected"
                                  : ""
                              }
                            >
                              <a
                                title="Vendors"
                                href={
                                  "/" +
                                  this.props.global.getURLEntry() +
                                  "/report/vendors"
                                }
                                data-qaid="leftMenuVendorsReportsLink"
                              >
                                <div className="icon">
                                  <BiIdCard />
                                </div>
                                Vendors
                              </a>
                            </li>
                          )}
                          {userPermissions.includes("Bills") && (
                            <li
                              className={
                                this.state.selected ===
                                  "/:entryUrl/report/bills/:id?" ||
                                this.state.selected ===
                                  "/:entryUrl/report/bills/vendor/:vendorNumber?" ||
                                this.state.selected ===
                                  "/:entryUrl/report/bills/bill/:billNumber?" ||
                                this.state.selected ===
                                  "/:entryUrl/bills/new/:vendorNumber/:entry" ||
                                this.state.selected ===
                                  "/:entryUrl/bills/new" ||
                                this.state.selected ===
                                  "/:entryUrl/bills/edit/:idBill" ||
                                this.state.selected ===
                                  "/:entryUrl/bills/view/:idBill"
                                  ? "selected"
                                  : ""
                              }
                            >
                              <a
                                title="Bills"
                                href={
                                  "/" +
                                  this.props.global.getURLEntry() +
                                  "/report/bills"
                                }
                                data-qaid="leftMenuBillsReportsLink"
                              >
                                <div className="icon">
                                  <BiFile />
                                </div>
                                Bills
                              </a>
                            </li>
                          )}
                          {userPermissions.includes("Approvals") && (
                            <li
                              className={
                                this.state.selected ===
                                "/:entryUrl/report/approvals"
                                  ? "selected"
                                  : ""
                              }
                            >
                              <a
                                title="Approvals"
                                href={
                                  "/" +
                                  this.props.global.getURLEntry() +
                                  "/report/approvals"
                                }
                                data-qaid="leftMenuApprovalsReportsLink"
                              >
                                <div className="icon">
                                  <BiCheckSquare />
                                </div>
                                Approvals
                              </a>
                            </li>
                          )}
                        </div>
                      )}

                      {(userPermissions.indexOf("Developers") !== -1 ||
                        (userPermissions.indexOf("Management") !== -1 &&
                          userPermissions.indexOf("Fraud") !== -1)) && (
                        <li className="mt-4">
                          <div className="header">More</div>
                        </li>
                      )}
                      {userPermissions.indexOf("Developers") !== -1 && (
                        <div>
                          <li>
                            <Accordion
                              activeKey={
                                this.state.menuReportsDevelopersActiveKey
                              }
                            >
                              <Accordion.Toggle
                                as={Link}
                                title="Reports"
                                to=""
                                data-qaid="leftMenuDevelopersLink"
                                onClick={(e) =>
                                  this.handleReportsDevelopersAccordion(e)
                                }
                                eventKey={this.state.openMenu ? "0" : "99"}
                              >
                                <div className="icon">
                                  <BiCodeBlock />
                                </div>{" "}
                                Developers{" "}
                                <BiChevronDown
                                  className="float-right"
                                  style={{
                                    fontSize: "15px",
                                  }}
                                />
                              </Accordion.Toggle>
                              {this.state.openMenu ? (
                                <Accordion.Collapse eventKey="0">
                                  <div className="menu-accordion-body">
                                    {userPermissions.indexOf("Xchange") !==
                                      -1 && (
                                      <>
                                        <a
                                          className={
                                            this.state.selected ===
                                            "/:entryUrl/developers/sftpaccounts"
                                              ? "selected"
                                              : ""
                                          }
                                          href={
                                            "/" +
                                            this.props.global.getURLEntry() +
                                            "/developers/sftpaccounts"
                                          }
                                        >
                                          SFTP Accounts
                                        </a>
                                        {/*<a className={this.state.selected === "/:entryUrl/developers/logs" ? 'selected' : ''} href={"/" + this.props.global.getURLEntry() + "/developers/logs">Logs</a>*/}
                                        <a
                                          className={
                                            this.state.selected ===
                                            "/:entryUrl/developers/reports"
                                              ? "selected"
                                              : ""
                                          }
                                          href={
                                            "/" +
                                            this.props.global.getURLEntry() +
                                            "/developers/reports"
                                          }
                                        >
                                          Reports
                                        </a>
                                      </>
                                    )}
                                    <a
                                      className={
                                        this.state.selected ===
                                        "/:entryUrl/developers/apitokens"
                                          ? "selected"
                                          : ""
                                      }
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/developers/apitokens"
                                      }
                                    >
                                      API Tokens
                                    </a>
                                    {process.env
                                      .REACT_APP_LINK_COMPONENT_CREATOR ===
                                      "true" && (
                                      <a
                                        className={
                                          this.state.selected ===
                                          "/:entryUrl/developers/creator"
                                            ? "selected"
                                            : ""
                                        }
                                        href={
                                          "/" +
                                          this.props.global.getURLEntry() +
                                          "/developers/creator"
                                        }
                                      >
                                        Creator{" "}
                                        <span
                                          style={{
                                            fontSize: "10px",
                                            marginLeft: "5px",
                                          }}
                                          className={"badge bg-primary"}
                                        >
                                          Beta
                                        </span>
                                      </a>
                                    )}
                                    <a
                                      className={
                                        this.state.selected ===
                                        "/:entryUrl/developers/hellosigntemplate"
                                          ? "selected"
                                          : ""
                                      }
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/developers/hellosigntemplate"
                                      }
                                    >
                                      Templates Mapper
                                    </a>
                                  </div>
                                </Accordion.Collapse>
                              ) : (
                                ""
                              )}
                            </Accordion>
                          </li>
                        </div>
                      )}
                      {userPermissions.indexOf("Management") !== -1 &&
                        userPermissions.indexOf("Fraud") !== -1 && (
                          <div>
                            <li>
                              <Accordion
                                activeKey={
                                  this.state.menuRiskControlCenterActiveKey
                                }
                              >
                                <Accordion.Toggle
                                  as={Link}
                                  title="Reports"
                                  to=""
                                  data-qaid="leftMenuRiskControlCenterActiveLink"
                                  onClick={(e) =>
                                    this.handleRiskControlCenterAccordion(e)
                                  }
                                  eventKey={this.state.openMenu ? "0" : "99"}
                                >
                                  <div className="icon">
                                    <BiBullseye />
                                  </div>{" "}
                                  Risk Control Center{" "}
                                  <BiChevronDown
                                    className="float-right"
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  />
                                </Accordion.Toggle>
                                {this.state.openMenu ? (
                                  <Accordion.Collapse eventKey="0">
                                    <div className="menu-accordion-body">
                                      <Accordion
                                        activeKey={
                                          this.state
                                            .menuRiskControlCenterReportActiveKey
                                        }
                                      >
                                        <Accordion.Toggle
                                          as={Link}
                                          title="Reports"
                                          to=""
                                          data-qaid="leftMenuRiskControlCenterReportActiveKeyLink"
                                          onClick={(e) =>
                                            this.handleRiskControlCenterReportAccordion(
                                              e
                                            )
                                          }
                                          eventKey={
                                            this.state.openMenu ? "0" : "99"
                                          }
                                        >
                                          <span>
                                            Reports{" "}
                                            <BiChevronDown
                                              className="float-right"
                                              style={{
                                                fontSize: "15px",
                                              }}
                                            />
                                          </span>
                                        </Accordion.Toggle>
                                        {this.state.openMenu ? (
                                          <Accordion.Collapse eventKey="0">
                                            <div className="menu-accordion-body">
                                              <a
                                                className={
                                                  this.state.selected ===
                                                  "/:entryUrl/riskcontrol/reports"
                                                    ? "selected sub-menu"
                                                    : "sub-menu"
                                                }
                                                href={
                                                  "/" +
                                                  this.props.global.getURLEntry() +
                                                  "/riskcontrol/reports"
                                                }
                                              >
                                                Fraud Alerts
                                              </a>
                                            </div>
                                          </Accordion.Collapse>
                                        ) : (
                                          ""
                                        )}
                                      </Accordion>
                                    </div>
                                  </Accordion.Collapse>
                                ) : (
                                  ""
                                )}
                              </Accordion>
                            </li>
                          </div>
                        )}
                    </ul>
                  </RSC>
                </div>
                <div className="main-menu-bottom">
                  <ul>
                    {settingsLinkTo && (
                      <li
                        className={
                          settingsUrls.includes(this.state.selected)
                            ? "selected"
                            : ""
                        }
                      >
                        <a
                          title="Settings"
                          href={settingsLinkTo}
                          data-qaid="leftMenuSettingsLink"
                        >
                          <div className="icon">
                            <BiCog />
                          </div>
                          Settings
                        </a>
                      </li>
                    )}
                    <li>
                      <a
                        title="Feedback"
                        onClick={() => this.setState({ feedbackModal: true })}
                        data-qaid="leftMenuFeedbackLink"
                      >
                        <div className="icon">
                          <MdOutlineFeedback />
                        </div>
                        Feedback
                      </a>
                    </li>
                    <li>
                      <a
                        title="Ticket Center"
                        data-qaid="leftMenuTicketCenterLink"
                        onClick={this.openAtlasCustomerPortal}
                      >
                        <div className="icon">
                          <LuInbox />
                        </div>
                        Ticket Center
                      </a>
                    </li>
                    <li>
                      <a
                        title="Support"
                        href="mailto:support@payabli.com"
                        data-qaid="leftMenuSupportLink"
                      >
                        <div className="icon">
                          <BiHelpCircle />
                        </div>
                        Support
                      </a>
                    </li>
                    <li className="versionLabel">
                      {process.env.REACT_APP_VERSION}
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={
                  !this.state.fullBody ? "main-body" : "main-body open-menu"
                }
                style={{
                  backgroundColor: this.props.bgcolor
                    ? this.props.bgcolor
                    : "#ffffff",
                }}
              >
                <ErrorBoundary>{this.props.children}</ErrorBoundary>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export { Layout };
