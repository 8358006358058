import React from 'react';
import { inject, observer } from 'mobx-react';
import { Tabs, Tab } from 'react-bootstrap';

import PreviewPaymentLink from './invoicePaymentLink/PreviewPaymentLink';
import PreviewEmail from './invoiceEmail/PreviewEmail';
import PreviewInvoicePdf from './invoicePdf/PreviewInvoicePdf';

import '../../../../assets/css/invoicePreview.css';

@inject('store', 'global', 'invoice')
@observer
class InvoicePreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewSelected: 'invoice',
    };

    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  handleChangeTab(tabKey) {
    this.setState({ previewSelected: tabKey });
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { action } = this.props.invoice.invoiceActionSelected;
    if (action === 'chargeCustomer' && this.state.previewSelected !== 'invoice') {
      this.setState({ previewSelected: 'invoice' });
    }
  }

  render() {
    const { action } = this.props.invoice.invoiceActionSelected;
    const tabHidden = action === 'chargeCustomer';
    const invoiceStatus = this.props.invoice.status;

    return (
      <div id="preview-container">
        <h5 data-qaid={invoiceStatus === 4 ? "paidInvoiceMoneyInPage" : "previewInvoiceMoneyIn"}>{invoiceStatus === 4 ? "Paid Invoice" : "Preview"}</h5>

        <div id="previews-tabs" className={tabHidden ? 'tabs-hidden' : ''}>
          <Tabs
            defaultActiveKey="invoice"
            activeKey={this.state.previewSelected}
            onSelect={(k) => this.handleChangeTab(k)}
          >
            <Tab eventKey="invoice" title="Invoice PDF">
              <PreviewInvoicePdf/>
            </Tab>
            {action === 'sendInvoice' && (
              <Tab eventKey="email" title="Email">
                <PreviewEmail />
              </Tab>
            )}

            {action === 'sendInvoice' && (
              <Tab eventKey="paylink" title="Payment Link">
                <PreviewPaymentLink />
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default InvoicePreview;
