import axios from "axios";
import { useState } from "react";
import { Button, Form, FormText, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';

export function FeedbackModal({ open, onClose, user }) {
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [error, setError] = useState(false)
    const [characters, setCharacters] = useState(0)
    const handleChange = (e) => {
        setCharacters(e.target.value?.length)
        validation(e.target.value)
        setValue(e.target.value)
    }

    const resetValues = () => {
        setValue('')
        setCharacters(0)
        setError(false)
    }

    const validation = (value) => {
        setError(false)
        if (!value || value.trim() === '') {
            setError(true)
        }
    }

    const handleSubmit = async (e) => {
        try {
            setLoading(true)
            e.preventDefault()
            if (error) return
            await sendFeedback({
                message: value,
                url: window.location.href,
                name: user.name,
                email: user.email
            })
            toast.success("Feedback has been sent!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container',
            });
            resetValues()
            onClose()
        } catch (error) {
            console.log(error)
            toast.error("An error occurred while sending feedback!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        } finally {
            setLoading(false)
        }
    }


    return (
        <Modal show={open} onHide={onClose} size="md" centered>
            <Modal.Body>
                <h5>Send your feedback</h5>
                <Form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <Form.Label label="Message" style={{ width: '100%', position: 'relative' }}>
                        <Form.Control
                            as="textarea"
                            name="message"
                            onChange={handleChange}
                            value={value}
                            placeholder="Message"
                            style={{ height: '100px' }}
                        />
                        <small style={{ fontSize: '12px', color: 'gray', position: 'absolute', right: '5px', bottom: '5px' }}>{characters}/500</small>
                    </Form.Label>
                    {error && <FormText style={{ fontSize: '12px', marginBottom: '0.5rem' }} className="text-danger">Message is required</FormText>}
                    <Button disabled={loading} variant='primary' type="submit" style={{ width: '100%' }}>
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}


const sendFeedback = async ({ message, url, name, email }) => {
    if (!process.env.REACT_APP_FEEDBACK_WEBHOOK) throw new Error('Feedback webhook not set')
    const endpoint = process.env.REACT_APP_FEEDBACK_WEBHOOK
    const data = {
        blocks: [
            {
                type: "section",
                text: {
                    type: "mrkdwn",
                    text: "💬 *New Feedback Received:*"
                }
            },
            {
                type: "section",
                text: {
                    type: "mrkdwn",
                    text: `User: *${name} | ${email}*\nURL active: <${url}| Link>\nMessage: ${message}\n`
                }
            }
        ]
    }
    return await axios.post(endpoint, JSON.stringify(data))
}