import { observable, makeObservable , action, computed } from "mobx";
import axios from 'axios';
import { PayabliStorageManager } from "../api/localStorageManager";

class VendorStore {

    constructor() {
        makeObservable(this)
    }

    @observable
    status = 'new';
    
    @observable
    statistics = null;

    @observable
    vendor = null;

    createVendor(vendor, entryPoint){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let additionalFields = {};
        
        vendor.additionalFields.forEach(function (item, index) {
            additionalFields[item.key] = item.value;
        });

        return axios.post(process.env.REACT_APP_URL_API+ 'Vendor/single/' + entryPoint,{
            "name1": vendor.name1,
            "Contacts": vendor.contacts,
            "customerVendorAccount": vendor.customerVendorAccount,
            "vendorNumber": vendor.vendorNumber,
            "ein": vendor.ein,
            "email": vendor.email,
            "phone": vendor.phone,
            "mcc": vendor.mcc,
            
            "address1": vendor.address1,
            "address2": vendor.address2,
            "city": vendor.city,
            "state":vendor.state,
            "zip": vendor.zip,
            "country": vendor.country,

            "remitAddress1": vendor.remitAddress1,
            "remitAddress2": vendor.remitAddress2,
            "remitCity": vendor.remitCity,
            "remitState":vendor.remitState,
            "remitZip": vendor.remitZip,
            "remitCountry": vendor.remitCountry,
            
            "additionalFields": additionalFields,
            "identifierFields": vendor.identifierFields,

            "customField1": vendor.customField1,
            "customField2": vendor.customField2
        },
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

    updateVendor(vendor){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let additionalFields = {};
        
        vendor.additionalFields.forEach(function (item, index) {
            additionalFields[item.key] = item.value;
        });
        
        return axios.put(process.env.REACT_APP_URL_API+ 'Vendor/' + vendor.id,{
            "name1": vendor.name1,
            "Contacts": vendor.contacts,
            "customerVendorAccount": vendor.customerVendorAccount,
            "vendorNumber": vendor.vendorNumber,
            "ein": vendor.ein,
            "email": vendor.email,
            "phone": vendor.phone,
            "mcc": vendor.mcc,
            
            "address1": vendor.address1,
            "address2": vendor.address2,
            "city": vendor.city,
            "state":vendor.state,
            "zip": vendor.zip,
            "country": vendor.country,

            "remitAddress1": vendor.remitAddress1,
            "remitAddress2": vendor.remitAddress2,
            "remitCity": vendor.remitCity,
            "remitState":vendor.remitState,
            "remitZip": vendor.remitZip,
            "remitCountry": vendor.remitCountry,
            
            "additionalFields": additionalFields,
            "identifierFields": vendor.identifierFields,
            
            "customField1": vendor.customField1,
            "customField2": vendor.customField2
        },
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            
        })
        .catch(error => {
            throw error;
        });
    }

    updateVendorStatus(id, status){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        status = parseInt(status);
        if(id && (status===0 || status === 1)){
            return axios.put(process.env.REACT_APP_URL_API+ 'Vendor/' + id,{
                "vendorStatus": status,
            },
            {
                headers: {'requestToken': encryptStorage.getItem('pToken')}
            })
            .then(res => {
                
            })
            .catch(error => {
                throw error;
            });
        }
    }

    getVendorFromApi(id){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Vendor/' + id,{
            headers: {
              'requestToken': encryptStorage.getItem('pToken'),
            }
            })
            .then(res => {
                this.setVendor(res.data);
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    getVendorRecentBills(vendor){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entryPoint =vendor.PaypointEntryname;
        return axios.get(process.env.REACT_APP_URL_API+ 'Query/bills/'+entryPoint+'?limitRecord=6&fromRecord=0&vendorNumber(eq)='+vendor.VendorNumber,{
            headers: {
              'requestToken': encryptStorage.getItem('pToken'),
            }
            })
            .then(res => {
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    getVendorRecentPaidHiscory(vendor){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entryPoint =vendor.PaypointEntryname;
        return axios.get(process.env.REACT_APP_URL_API+ 'Query/payouts/'+entryPoint+'?limitRecord=6&fromRecord=0&vendorNumber(eq)='+vendor.VendorNumber+"&status(eq)=5",{
            headers: {
              'requestToken': encryptStorage.getItem('pToken'),
            }
            })
            .then(res => {
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    setVendor(data){
        this.vendor = data;
    }

    @action
    getStatistics(idVendor){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Statistic/vendorbasic/m12/m/' + idVendor,{
            headers: {
              'requestToken': encryptStorage.getItem('pToken'),
            }
            })
            .then(res => {
                this.setStatistics(res.data);
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    setStatistics(data){
        this.statistics = data;
    }

    @computed
    get vendorDataChart(){
        let data = [];
        data['labels'] = [];
        data['values'] = [0,0,0,0,0,0,0,0,0,0,0,0,0];
        data['values_position'] = [];
        

        for (let i = 12; i >= 0; i--) {
            let date = this.createDate(0, i - 12, 0);
            
            data['values_position'][i] = date.getFullYear() + '-' + (date.getMonth()+1);
            data['labels'][i] = this.getMonthName(date.getMonth(), 1) + " " + date.getFullYear().toString();
        }
       
        if(this.statistics){
            this.statistics.forEach(function (item) {
                let statX = item.statX;
                statX = statX.replace(/ /g, '').replace('{Year=','').replace(',Month=','-').replace('}','');
                let index = data['values_position'].indexOf(statX);
                if(index > -1){
                    data['values'][index] = item.paidVolume;
                }
            });
        }
                
        let dataValues = data['values'];
        data['lastMonthTotal'] = ((dataValues && dataValues[dataValues.length - 1]) ? dataValues[dataValues.length - 1] : 0);
        return data;
    }

    createDate(days, months, years) {
        var date = new Date(); 
        date.setDate(date.getDate() + days);
        date.setMonth(date.getMonth() + months);
        date.setFullYear(date.getFullYear() + years);
        return date;    
    }

    getMonthName(number, short){
        
        let monthNames = [];
        if(short){
            monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
        }
        else{
            monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
            ];
        }
        
        return monthNames[number];
    }

}

const vendorStore = new VendorStore();
export default vendorStore;