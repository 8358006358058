import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import { RenderOrganization } from '../../components/filters/RenderOrganization';

@inject('reports', 'filter')
@observer
class ViewPartialLineitems extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className='col-sm-6 mb-4'>
                        <div className="form-floating">
                            <input name="itemName" id="itemName" className={"form-control"} placeholder="Item Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('name(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('name(ct)', e)} value={this.props.filter.filterText.itemName ? this.props.filter.filterText.itemName : ''} data-qaid="inputItemNameFilter"/>
                            <label htmlFor="itemName">Item Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 mb-4">
                        <div className="form-floating">
                            <input name="itemCode" id="itemCode" className={"form-control"} placeholder="Item Code" onChange={(e) => this.props.filter.handleCaptureTextFilter('code(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('code(ct)', e)} value={this.props.filter.filterText.itemCode ? this.props.filter.filterText.itemCode : ''} />
                            <label htmlFor="itemCode">Item Code</label>
                        </div>
                    </div>
                    <div className="col-sm-6 mb-4">
                        <div className="form-floating">
                            <NumberFormat
                                thousandsGroupStyle="thousand"
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={this.props.filter.filterText.valueLineItems ? this.props.filter.filterText.valueLineItems : ''}
                                placeholder="Item Cost"
                                className="form-control"
                                onValueChange={(values) => this.props.filter.handleCaptureTextFilterObject('value(eq)', values, 'valueLineItems')}
                                onKeyDown={(e) => this.props.filter.handleKeyDownObject('value(eq)', e, 'valueLineItems')}
                            />
                            <label>"Item Cost"</label>
                        </div>
                    </div>                                        
                    <div className='col-sm-6 mb-4'>
                        <div className="form-floating">
                            <input name="descriptionLineItems" id="descriptionLineItems" className={"form-control"} placeholder="Description" onChange={(e) => this.props.filter.handleCaptureTextFilter('description(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('description(ct)', e)} value={this.props.filter.filterText.descriptionLineItems ? this.props.filter.filterText.descriptionLineItems : ''} />
                            <label htmlFor="descriptionLineItems">Description</label>
                        </div>
                    </div>
                    <div className='col-sm-6 mb-4'>
                        <p><small>Created Date</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="createdDateLineItemsCalendar" 
                                        name="createdDateLineItemsCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateCreatedLineItems ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateCreatedLineItems) + ' → ' : '') + (this.props.filter.stateDate.endDateCreatedLineItems ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateCreatedLineItems) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="createdDateLineItemsCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateCreatedLineItems ? this.props.filter.stateDate.startDateCreatedLineItems : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateCreatedLineItems', 'endDateCreatedLineItems', 'createdDate')}
                            startDate={this.props.filter.stateDate.startDateCreatedLineItems}
                            endDate={this.props.filter.stateDate.endDateCreatedLineItems}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateCreatedLineItems !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                    <div className='col-sm-6 mb-4'>
                        <p><small>Updated Date</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="dateUpdatedLineItemsCalendar" 
                                        name="dateUpdatedLineItemsCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateUpdatedLineItems ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateUpdatedLineItems) + ' → ' : '') + (this.props.filter.stateDate.endDateUpdatedLineItems ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateUpdatedLineItems) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="dateUpdatedLineItemsCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateUpdatedLineItems ? this.props.filter.stateDate.startDateUpdatedLineItems : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateUpdatedLineItems', 'endDateUpdatedLineItems', 'updatedDate')}
                            startDate={this.props.filter.stateDate.startDateUpdatedLineItems}
                            endDate={this.props.filter.stateDate.endDateUpdatedLineItems}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateUpdatedLineItems !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                    <RenderOrganization />
                </div>  
            </>);
    }
}

export { ViewPartialLineitems };