import axios from 'axios'
import { toast } from 'react-toastify'
import { PayabliStorageManager } from '../../../api/localStorageManager'

let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()

export const boardingReadMessageGET = async (appId, messageSkip = 0, messageTake = 10) => {
	try {
		const res = await axios.get(process.env.REACT_APP_URL_API + `Boarding/read/${appId}/${messageSkip}/${messageTake}`, {
			headers: {
				requestToken: encryptStorage.getItem('pToken')
			}
		})
		return res
	} catch (error) {
		let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
		toast.error(errorMessage, {
			position: toast.POSITION.BOTTOM_RIGHT,
			className: 'toast-error-container'
		})
		return { data: [] }
	}
}
export const boardingAppMessagePOST = async (appId, dataMessage) => {
	try {
		const res = await axios.post(process.env.REACT_APP_URL_API + `Boarding/app/${appId}/messages`, dataMessage, {
			headers: {
				requestToken: encryptStorage.getItem('pToken')
			}
		})
		return res
	} catch (error) {
		let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
		toast.error(errorMessage, {
			position: toast.POSITION.BOTTOM_RIGHT,
			className: 'toast-error-container'
		})
		return { data: false }
	}
}
export const sendMessageNoteGET = async (idMessage, emails) => {
	try {
		const res = await axios.get(process.env.REACT_APP_URL_API + `Boarding/app/${idMessage}/sendEmail/${emails}`, {
			headers: {
				requestToken: encryptStorage.getItem('pToken')
			}
		})
		return res
	} catch (error) {
		let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
		toast.error(errorMessage, {
			position: toast.POSITION.BOTTOM_RIGHT,
			className: 'toast-error-container'
		})
		return { data: false }
	}
}