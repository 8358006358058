import React from 'react'
import styles from '../../assets/css/styleCommandCenter.module.css'
import LoaderPlaceHolder from '../loaderPlaceHolder'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle'

export default function CheckBoxContainer({ inputDisabled, labelValue, value, name, readonly = '', labelClassName, labelStyle = {}, errorValid = false, classNameInput = '', setValue, TooltipCaption = '', placement = 'right', isLoading = false, placeholderStyle = '', divStyle = {}, refs, hidden = false }) {
	const handleChange = (e) => {
		setValue(e.target.checked)
	}

	return isLoading ? (
		<LoaderPlaceHolder extraStyles={placeholderStyle} />
	) : (
		<div
			className="icheck-success"
			style={{ ...divStyle, border: errorValid && '1px solid #dc3545', padding: errorValid && '5px', borderRadius: errorValid && '.25rem' }}>
			<input
				ref={refs}
				type="checkbox"
				onChange={handleChange}
				checked={value}
				id={name}
				name={name}
				readOnly={readonly}
				className={classNameInput}
				disabled={inputDisabled}
				hidden={hidden}
			/>
			{!TooltipCaption && !hidden ? (
				<label
					htmlFor={name}
					className={labelClassName}
					style={labelStyle}>
					{labelValue}
				</label>
			) : (
				!hidden && (
					<label
						htmlFor={name}
						className={labelClassName}
						style={{ ...labelStyle, display: 'flex', alignItems: 'center' }}>
						{labelValue} &nbsp;
						<OverlayTrigger
							key={placement}
							placement={placement}
							overlay={<Tooltip>{TooltipCaption}</Tooltip>}>
							<BiInfoCircle className={`${styles['icon15']} ${!errorValid ? styles['color-blue'] : styles['color-error']}`} />
						</OverlayTrigger>
					</label>
				)
			)}
		</div>
	)
}
