import React from 'react';
import { inject, observer } from 'mobx-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdInputCircle } from '../assets/images/MdInputCircle';

@inject('global')
@observer
class InfoConsentCustomer extends React.Component {
	render() {
		const { data, type } = this.props;

		if (!data) return null;

		const { status, updatedAt } = data;

		if (!status) return null;

		const color = status === 1 ? '#9CCC65' : '#FFA726';
		const title = `Invoice by ${`${type === 0 ? 'Email' : 'Phone'}`} ${
			status === -1 ? '' : ' Accepted'
		}`;
		const subTitle =
			status === -1
				? 'Waiting for response'
				: `Since ${this.props.global.stringDateFormatV3(updatedAt)}`;

		return (
			<OverlayTrigger
				placement='top'
				overlay={
					<Tooltip>
						<div className='d-flex flex-column'>
							<b className='text-nowrap'>{title}</b>
							<span>{subTitle}</span>
						</div>
					</Tooltip>
				}>
				<MdInputCircle style={{ color: color, marginLeft: '5px' }} />
			</OverlayTrigger>
		);
	}
}

export { InfoConsentCustomer };
