import React, { useState, useContext } from 'react'
import styles from '../../../../assets/css/styleCommandCenter.module.css'
import { RiBankLine, RiAddFill, RiSettings3Line, RiUpload2Fill } from 'react-icons/ri'
import { BiDotsVerticalRounded, BiDetail } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'
import FrameGroup from '../../../../components/commandCenter/FrameGroup'
import ButtonSaveCancel from '../../../../components/commandCenter/ButtonSaveCancel'
import BankInformation from './BankInformation'
import ModalFileLimit from '../../../../components/commandCenter/ModalFileLimit'
import RowContainer from '../../../../components/commandCenter/RowContainer'
import { Element } from 'react-scroll'
import commandContext from '../../context/commandCenter_context'

export default function BankingDocuments({ addBankAccount, showPopUpMessage }) {
	const { loadData, Banking, DocumentsRef, handleCheckUpdate, PayPoint, readAttachments, downLoadDocument, upLoadDocument, deleteDocument, isLoading } = useContext(commandContext)
	const [inputDisabled, setInputDisabled] = useState(true)
	const [editing, setEditing] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [removingFile, setRemovingFile] = useState(false)
	const UploadFile = React.createRef()

	const load = async () => {
		await loadData()
	}
	const fileSelectHandler = (event) => {
		if (event.target.files.length <= 0) return
		let newDocumentsFiles = {}
		let sizeDoc = 0
		let fileLength = 0
		fileLength = event.target.files.length

		for (let i = 0; i < fileLength; i++) {
			sizeDoc += event.target.files[i].size
			newDocumentsFiles = { ...newDocumentsFiles, ['doc_' + i]: { value: event.target.files[i] } }
		}
		if (sizeDoc > 31457280) {
			setShowModal(true)
			return
		}
		event.target.value = ''
		readAttachments(newDocumentsFiles).then((dataDoc) => {
			uploadFiles(dataDoc)
		})
	}
	const uploadFiles = async (dataDoc) => {
		const res = await upLoadDocument(dataDoc)
		if (!res) return
		load()
	}
	const deleteAttachments = () => {
		let timeOut = 0,
			inProcess = false
		DocumentsRef.documents.filelist.forEach((file) => {
			if (file.check === true) {
				setTimeout(function () {
					deleteDocument(file.zipName)
					load()
				}, timeOut)
				timeOut += 2000
				inProcess = true
			}
		})
		if (inProcess) {
			setRemovingFile(true)
			setTimeout(function () {
				setRemovingFile(false)
				setInputDisabled(true)
				setEditing(false)
			}, timeOut)
		}
	}
	const activeDeleteBtn = () => {
		let valid = false
		DocumentsRef.documents.filelist.forEach((file) => {
			if (file.check === true) {
				valid = true
				return
			}
		})
		return valid
	}

	return (
		<Element
			name="Banking"
			className="d-flex justify-content-center w-100">
			<div className={styles['cards-details']}>
				<FrameGroup
					icon_frame={<RiBankLine className={styles['icon26'] + ' ' + styles['color-blue']} />}
					icon_btn={<BiDotsVerticalRounded className={styles['icon24'] + ' ' + styles['color-gray']} />}
					title_label="Banking"
					useBtn={false}
					useOptBtn2={!editing}
					iconOptBtn2={<RiAddFill className={styles['icon20']} />}
					labelOptBtn2="ADD BANK ACCOUNT"
					classBtn2={styles['btn'] + ' ' + styles[PayPoint.idPaypoint <= 0 ? 'border-disabled' : 'border-blue']}
					disabledOpt2={PayPoint.idPaypoint <= 0 ? true : false}
					functionOpt2={() => addBankAccount(Banking.bankData.length + 1)}
				/>

				{Banking.bankData.map((account, index) => (
					<div
						key={'bankData' + index}
						className="w-100">
						<BankInformation
							account={account}
							index={index}
							load={() => load()}
							editing={editing}
							setEditing={setEditing}
							showPopUpMessage={showPopUpMessage}
						/>
					</div>
				))}

				<div className="d-flex flex-column align-items-start p-0 gap-2 w-100">
					<div
						className="d-flex flex-row flex-wrap justify-content-between align-items-center p-0 pb-2 w-100"
						style={{ borderBottom: '1px solid #f4f5f7' }}>
						<label
							className={styles['label-form'] + ' d-flex align-items-center ' + styles['ft-s-20']}
							style={{ fontWeight: '700', lineHeight: '30px', gap: '8px' }}>
							<BiDetail className={styles['icon26'] + ' ' + styles['color-blue']} />
							Documents
						</label>

						{inputDisabled && !editing && (
							<div className="d-flex flex-row flex-wrap align-items-center p-0 gap-2">
								<button
									onClick={() => setInputDisabled(false) | setEditing(true)}
									className={styles['btn'] + ' ' + styles['no-border'] + ' ' + styles[PayPoint.idPaypoint <= 0 ? 'color-disabled' : 'color-blue']}
									disabled={PayPoint.idPaypoint <= 0 ? true : false}>
									<RiSettings3Line className={styles['icon24']} />
									MANAGE DOCUMENTS
								</button>
							</div>
						)}
						{!inputDisabled && (
							<div className="d-flex flex-row flex-wrap align-items-center p-0 gap-2">
								{activeDeleteBtn() && (
									<button
										className={styles['btn'] + ' ' + styles[removingFile ? 'btn-letter-disabled' : 'btn-red-letter'] + ' ' + styles['no-border']}
										onClick={() => deleteAttachments()}
										disabled={removingFile}>
										<BsTrash className={styles['icon20']} />
										DELETE DOCUMENT
									</button>
								)}
								<button
									className={styles['btn'] + ' ' + styles[removingFile ? 'border-disabled' : 'border-blue']}
									onClick={() => UploadFile.current.click()}
									disabled={removingFile}>
									<RiUpload2Fill className={styles['icon24']} />
									UPLOAD DOCUMENT
								</button>
							</div>
						)}
					</div>

					<div className="row w-100">
						<div className="col-md-2">
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-13'] + ' ' + styles['d-none-1214']}
								style={{ fontWeight: '600', lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}>
								File Type
							</label>
						</div>
						<div className="col-md-4">
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-13'] + ' ' + styles['d-none-1214']}
								style={{ fontWeight: '600', lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}>
								Document Name
							</label>
						</div>
						<div className="col-md-2">
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-13'] + ' ' + styles['d-none-1214']}
								style={{ fontWeight: '600', lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}>
								Date Added
							</label>
						</div>
						<div className="col-md-4">
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-13'] + ' ' + styles['d-none-1214']}
								style={{ fontWeight: '600', lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}>
								Attachment
							</label>
						</div>
					</div>

					{isLoading || DocumentsRef.documents.filelist.length === 0 ? (
						<div className="row w-100">
							<div className="col-md-2">
								<RowContainer
									inputType="Label"
									labelClassName={styles['d-none-1214']}
									placeholderStyle={{ minHeight: '20px', width: '100px', marginRight: '20px', borderRadius: '.25rem' }}
									labelStyle={{ lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}
									//isLoading={isLoading}
									labelValue="N/A"
								/>
							</div>
							<div className="col-md-4">
								<RowContainer
									inputType="Label"
									labelClassName={styles['d-none-1214']}
									placeholderStyle={{ minHeight: '20px', width: '260px', marginRight: '20px', borderRadius: '.25rem' }}
									labelStyle={{ lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}
									//isLoading={isLoading}
									labelValue="N/A"
								/>
							</div>
							<div className="col-md-2">
								<RowContainer
									inputType="Label"
									labelClassName={styles['d-none-1214']}
									placeholderStyle={{ minHeight: '20px', width: '100px', marginRight: '20px', borderRadius: '.25rem' }}
									labelStyle={{ lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}
									//isLoading={isLoading}
									labelValue="N/A"
								/>
							</div>
							<div className="col-md-4">
								<RowContainer
									inputType="Label"
									labelClassName={styles['d-none-1214']}
									placeholderStyle={{ minHeight: '20px', width: '260px', marginRight: '20px', borderRadius: '.25rem' }}
									labelStyle={{ lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}
									//isLoading={isLoading}
									labelValue="N/A"
								/>
							</div>
						</div>
					) : (
						!isLoading &&
						DocumentsRef.documents.filelist.map((doc, index) => (
							<div
								key={'documentsRef' + index}
								className="row w-100 align-items-center">
								<div className="col-md-2">
									{!inputDisabled ? (
										<RowContainer
											inputType="Checkbox"
											isLoading={removingFile}
											value={doc?.check}
											inputDisabled={inputDisabled}
											name={'check' + index}
											placeholderStyle={{ minHeight: '20px', width: '100px', marginRight: '20px', borderRadius: '.25rem' }}
											labelStyle={{ fontWeight: '700', lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}
											labelValue={doc.zipName.slice(((doc.zipName.lastIndexOf('.') - 1) >>> 0) + 2)}
											setValue={(checked) => handleCheckUpdate(index, checked)}
										/>
									) : (
										<RowContainer
											inputType="Label"
											//isLoading={removingFile}
											labelValue={doc.zipName.slice(((doc.zipName.lastIndexOf('.') - 1) >>> 0) + 2)}
											placeholderStyle={{ minHeight: '20px', width: '100px', marginRight: '20px', borderRadius: '.25rem' }}
											labelStyle={{ fontWeight: '700', lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}
										/>
									)}
								</div>
								<div className="col-md-4">
									<RowContainer
										inputType="Label"
										//isLoading={removingFile}
										labelValue={doc.originalName}
										placeholderStyle={{ minHeight: '20px', width: '260px', marginRight: '20px', borderRadius: '.25rem' }}
										labelStyle={{ fontWeight: !doc.check ? '400' : '700', lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}
									/>
								</div>
								<div className="col-md-2">
									<RowContainer
										inputType="Label"
										//isLoading={removingFile}
										labelValue={doc.uploadDate}
										placeholderStyle={{ minHeight: '20px', width: '100px', marginRight: '20px', borderRadius: '.25rem' }}
										labelStyle={{ fontWeight: !doc.check ? '400' : '700', lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}
									/>
								</div>
								<div className="col-md-4">
									<RowContainer
										inputType="Label"
										onClick={() => downLoadDocument(doc.zipName, DocumentsRef.documents.zipfile, doc.zipName.slice(((doc.zipName.lastIndexOf('.') - 1) >>> 0) + 2))}
										//isLoading={removingFile}
										labelValue={'/' + doc.zipName}
										placeholderStyle={{ minHeight: '20px', width: '260px', marginRight: '20px', borderRadius: '.25rem' }}
										labelStyle={{ fontWeight: !doc.check ? '400' : '700', lineHeight: '20px', width: '100%', letterSpacing: '0.0025em' }}
										labelClassName={styles['label-links']}
									/>
								</div>
							</div>
						))
					)}
				</div>
				<input
					ref={UploadFile}
					name="file"
					onChange={fileSelectHandler}
					type="file"
					multiple="multiple"
					accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain"
					hidden={true}
				/>

				<ButtonSaveCancel
					inputDisabled={removingFile ? true : inputDisabled}
					funtionCancel={() => setInputDisabled(true) | setEditing(false) | load()}
					funtionSave=""
				/>
			</div>
			<ModalFileLimit
				show={showModal}
				close={() => setShowModal(false)}
				title='File limit exceeded!'
				textLine1 = 'It seems that you have exceeded the maximum file size allowed. The maximum upload is 30MB of any combination of .pdf .jpg. or .png files. We apologize for any inconvenience.'
				textLine2 = 'If you need to upload more files try with fewer files.'
			/>
		</Element>
	)
}
