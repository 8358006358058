import React, { useState, useContext, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import GroupRow from '../../../../components/templates/GroupRow'
import GlobalStore from '../../../../store/GlobalStore'
import templatesContext from '../context/templates_context'

export default function TabProcessingInformation() {
	const { RootTemplate, LoadingState, handleChangeProcessing, ProcessingTemplate, messageValid } = useContext(templatesContext)
	const [classValidation, setClassValidation] = useState({ binperson: '', binphone: '', binweb: '' })

	useEffect(() => {
		if (ProcessingTemplate && ProcessingTemplate.binperson !== undefined && ProcessingTemplate.binphone !== undefined && ProcessingTemplate.binweb !== undefined) {
			if (
				(!ProcessingTemplate.binperson.visible && !ProcessingTemplate.binphone.visible && !ProcessingTemplate.binweb.visible) ||
				(ProcessingTemplate.binperson.visible && ProcessingTemplate.binphone.visible && ProcessingTemplate.binweb.visible && parseFloat(ProcessingTemplate.binperson.value !== '' ? ProcessingTemplate.binperson.value : 0) > 0 && parseFloat(ProcessingTemplate.binphone.value !== '' ? ProcessingTemplate.binphone.value : 0) > 0 && parseFloat(ProcessingTemplate.binweb.value !== '' ? ProcessingTemplate.binweb.value : 0) > 0) ||
				(ProcessingTemplate.binperson.readOnly && ProcessingTemplate.binphone.readOnly && ProcessingTemplate.binweb.readOnly) ||
				(ProcessingTemplate.binperson.readOnly && !ProcessingTemplate.binphone.visible && !ProcessingTemplate.binweb.visible) ||
				(!ProcessingTemplate.binperson.visible && ProcessingTemplate.binphone.readOnly && !ProcessingTemplate.binweb.visible) ||
				(!ProcessingTemplate.binperson.visible && !ProcessingTemplate.binphone.visible && ProcessingTemplate.binweb.readOnly) ||
				(ProcessingTemplate.binperson.readOnly && ProcessingTemplate.binphone.readOnly && !ProcessingTemplate.binweb.visible) ||
				(ProcessingTemplate.binperson.readOnly && !ProcessingTemplate.binphone.visible && ProcessingTemplate.binweb.readOnly) ||
				(!ProcessingTemplate.binperson.visible && ProcessingTemplate.binphone.readOnly && ProcessingTemplate.binweb.readOnly)
			) {
				setClassValidation(parseFloat(ProcessingTemplate.binperson.value !== '' ? ProcessingTemplate.binperson.value : 0) + parseFloat(ProcessingTemplate.binphone.value !== '' ? ProcessingTemplate.binphone.value : 0) + parseFloat(ProcessingTemplate.binweb.value !== '' ? ProcessingTemplate.binweb.value : 0) !== 100 ? { binperson: 'input-error', binphone: 'input-error', binweb: 'input-error' } : { binperson: '', binphone: '', binweb: '' })
			} else if (ProcessingTemplate.binperson.visible || ProcessingTemplate.binphone.visible || ProcessingTemplate.binweb.visible) {
				setClassValidation(
					parseFloat(ProcessingTemplate.binperson.value !== '' ? ProcessingTemplate.binperson.value : 0) + parseFloat(ProcessingTemplate.binphone.value !== '' ? ProcessingTemplate.binphone.value : 0) + parseFloat(ProcessingTemplate.binweb.value !== '' ? ProcessingTemplate.binweb.value : 0) > 100
						? { binperson: parseFloat(ProcessingTemplate.binperson.value !== '' ? ProcessingTemplate.binperson.value : 0) > 0 ? 'input-error' : '', binphone: parseFloat(ProcessingTemplate.binphone.value !== '' ? ProcessingTemplate.binphone.value : 0) > 0 ? 'input-error' : '', binweb: parseFloat(ProcessingTemplate.binweb.value !== '' ? ProcessingTemplate.binweb.value : 0) > 0 ? 'input-error' : '' }
						: { binperson: '', binphone: '', binweb: '' }
				)
			} else {
				setClassValidation({ binperson: '', binphone: '', binweb: '' })
			}
		} else {
			setClassValidation({ binperson: 'input-error', binphone: 'input-error', binweb: 'input-error' })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ProcessingTemplate.binperson, ProcessingTemplate.binphone, ProcessingTemplate.binweb])

	return (
		<>
			<h5>Processing Information</h5>
			<p className="small mb-4 dark-grey">This section contains industry code (MCC) and transactional questions so we know what to expect from your business.</p>
			<h6 className="mb-3">Tell Us How You Process Transactions</h6>
			<div className="row">
				<div className="col-md-6">
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="mcc"
						inputType={{ title: 'Industry (Merchant Category Code)', type: 'selectSearch', mask: GlobalStore.getMccOptions(), TooltipValid: !LoadingState && ProcessingTemplate.mcc.value.trim().length === 0 && (!ProcessingTemplate.mcc.visible || ProcessingTemplate.mcc.readOnly) ? messageValid : '', classNameInput: !LoadingState && ProcessingTemplate.mcc.value.trim().length === 0 && (!ProcessingTemplate.mcc.visible || ProcessingTemplate.mcc.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="bsummary"
						inputType={{
							title: 'Tell us about your Business',
							type: 'mask',
							mask: GlobalStore.maskValidator('text'),
							TooltipValid: !LoadingState && ProcessingTemplate.bsummary.value.trim().length === 0 && (!ProcessingTemplate.bsummary.visible || ProcessingTemplate.bsummary.readOnly) ? messageValid : '',
							classNameInput: !LoadingState && ProcessingTemplate.bsummary.value.trim().length === 0 && (!ProcessingTemplate.bsummary.visible || ProcessingTemplate.bsummary.readOnly) ? 'input-error' : ''
						}}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="binperson"
						inputType={{ title: 'Percent of payments In Person', type: 'number', prefix: '', suffix: '%', maxValue: 100, decimalScale: 0, mask: '', classNameInput: classValidation.binperson }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="binphone"
						inputType={{ title: 'Percent of payments by Phone', type: 'number', prefix: '', suffix: '%', maxValue: 100, decimalScale: 0, mask: '', classNameInput: classValidation.binphone }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="binweb"
						inputType={{ title: 'Percent of payments online', type: 'number', prefix: '', suffix: '%', maxValue: 100, decimalScale: 0, mask: '', classNameInput: classValidation.binweb }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<Alert
						style={{ marginTop: '20px', width: '100%' }}
						show={JSON.stringify(classValidation).includes('input-error') ? true : false}
						variant="danger">
						<div className="d-flex flex-row justify-content-between align-items-center gap-1">
							<p className="m-0">Combined total payments percent must be equal to 100%!</p>
						</div>
					</Alert>
				</div>
				<div className="col-md-6">
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="avgmonthly"
						inputType={{ title: 'Average Monthly Volume', type: 'number', mask: '', TooltipValid: !LoadingState && ProcessingTemplate.avgmonthly.value <= 0 && (!ProcessingTemplate.avgmonthly.visible || ProcessingTemplate.avgmonthly.readOnly) ? messageValid : '', classNameInput: !LoadingState && ProcessingTemplate.avgmonthly.value <= 0 && (!ProcessingTemplate.avgmonthly.visible || ProcessingTemplate.avgmonthly.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="ticketamt"
						inputType={{ title: 'Average Ticket Amount', type: 'number', mask: '', TooltipValid: !LoadingState && ProcessingTemplate.ticketamt.value <= 0 && (!ProcessingTemplate.ticketamt.visible || ProcessingTemplate.ticketamt.readOnly) ? messageValid : '', classNameInput: !LoadingState && ProcessingTemplate.ticketamt.value <= 0 && (!ProcessingTemplate.ticketamt.visible || ProcessingTemplate.ticketamt.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="highticketamt"
						inputType={{ title: 'High Ticket Amount', type: 'number', mask: '', TooltipValid: !LoadingState && ProcessingTemplate.highticketamt.value <= 0 && (!ProcessingTemplate.highticketamt.visible || ProcessingTemplate.highticketamt.readOnly) ? messageValid : '', classNameInput: !LoadingState && ProcessingTemplate.highticketamt.value <= 0 && (!ProcessingTemplate.highticketamt.visible || ProcessingTemplate.highticketamt.readOnly) ? 'input-error' : '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="annualRevenue"
						inputType={{ title: 'Annual Revenue Volume', type: 'number', mask: '' }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="whenCharged"
						inputType={{ title: 'When is Payment Charged?', type: 'select', mask: GlobalStore.getOptionsType('whencharged') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="whenProvided"
						inputType={{ title: 'Service Generally Provided In:', type: 'select', mask: GlobalStore.getOptionsType('whenprovided') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="whenDelivered"
						inputType={{ title: 'Products/Services Delivered In:', type: 'select', mask: GlobalStore.getOptionsType('whendelivered') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
					<GroupRow
						fieldData={!LoadingState && ProcessingTemplate}
						parentField={RootTemplate.rootTemplate.processingData}
						handleChange={(property, field, values) => handleChangeProcessing(property, field, values)}
						field="whenRefunded"
						inputType={{ title: 'Refund Policy', type: 'select', mask: GlobalStore.getOptionsType('whenrefunded') }}
						checkOptions={{ state: { visible: true, readOnly: true, required: true } }}
					/>
				</div>
			</div>
		</>
	)
}
