import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BsCheck, BsX } from 'react-icons/bs';
import { BiCheckCircle, BiXCircle, BiUpload } from 'react-icons/bi';
import { PayabliStorageManager } from '../../api/localStorageManager'
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone'
import BillSample from '../../assets/samples-import/ImportBill.csv';
import ChargeBackSample from '../../assets/samples-import/ImportChargeback.csv';
import ResidualSample from '../../assets/samples-import/ImportResidual.csv';
import CustomerSample from '../../assets/samples-import/ImportCustomer.csv';
import VendorSample from '../../assets/samples-import/ImportVendor.csv';

@inject('boarding', 'store', 'global')
@observer
class ModalImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedFiles: [],
      correctFiles: false,
      statusUpload: 'draft',
    }
    this.handleFileEvent = this.handleFileEvent.bind(this);
    this.handleUploadFiles = this.handleUploadFiles.bind(this);
    this.handlerRemove = this.handlerRemove.bind(this);
    this.handlerReadFiles = this.handlerReadFiles.bind(this);
    this.handlerCloseModal = this.handlerCloseModal.bind(this);
  }
  async readAttachment(file){
    try {
      const result = await this.getBase64(file);
      return result
    } catch (error) {
      return;
    }
  }
  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (res) => {
        resolve(res.target.result);
      };
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });
  }
  async handleUploadFiles(file){
    const self = this;
    let correctFiles = false;
    let statusUpload = this.state.statusUpload;
    if(file.size <= 2097152){
      this.readAttachment(file).then((result)=> {
        const fileStore = {
          filename: file.name,
          ftype: file.type.split('/').at(-1),
          fsize: file.size,
          fContent: result.split(',').at(-1),
        }
        const input = file;
        const reader = new FileReader();
        reader.onload = function (e) {
          let textFull = e.target.result;
          let text_n = textFull.split('\n');
          const text = text_n ? text_n[0] : ''
          if(text.split(',').includes('BillNumber')){
            fileStore.type = 'Bill';
          }
          else if(text.split(',').includes('period')){
            fileStore.type = 'Residual';
          }
          else if(text.split(',').includes('customerNumber')){
            fileStore.type = 'Customer';
          }
          else if(text.split(',').includes('VendorNumber')){
            fileStore.type = 'Vendor';
          }
          else if(text.split(',').includes('Rcvd_Date')){
            fileStore.type = 'ChargeBack';
          }
          else{
            fileStore.type = 'Error';
            statusUpload = 'failure';
          }
          if (fileStore.type === 'Residual' && !text.split(',').includes('period') && !text.split(',').includes('parent_org_Id') && !text.split(',').includes('paypoint_id') ){
            fileStore.type = 'Error';
            statusUpload = 'failure';
          }
          else if(fileStore.type !== 'Residual' && !text.split(',').includes('Paypoint_Id') && !text.split(',').includes('externalPaypointId') && !text.split(',').includes('entryName')){
            fileStore.type = 'Error';
            statusUpload = 'failure';
          }
          if(self.props.modalType === fileStore.type && fileStore.type !== 'Error'){
            correctFiles = true;
            statusUpload = 'success';
          }
          else{
            statusUpload = 'failure';
          }
          self.setState({uploadedFiles: [fileStore], statusUpload: statusUpload, correctFiles: fileStore.type !== 'Error' && correctFiles});
        };
        reader.readAsText(input);
        
      });
    }
    else{
      toast.error("Support for a single or bulk upload. Maximum file size 2MB..", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error-container',
    });
    }
  }
  handlerCloseModal(e){
    let self = this;
    this.setState({uploadedFiles: [], statusUpload: 'draft',
      correctFiles: false,}, () => {
        self.props.closeModal();
      });
    ;
  }
  handleFileEvent(files){
      this.handleUploadFiles(files[0]);
  }

  handlerRemove(e){
    const updaled = this.state.uploadedFiles
    if(updaled.length > 0){
      this.setState({uploadedFiles: [], statusUpload: 'draft'})
    }
  }
  handlerReadFiles(e){
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let tk = encryptStorage.getItem('pToken') || process.env.REACT_APP_TOKEN;
    let orgId = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId;
    const file = this.state.uploadedFiles.length > 0 ? this.state.uploadedFiles[0] : null
    if(file && file.type === 'Error'){
      toast.error("There are files with errors so the information cannot be imported.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error-container',
      });
    }
    const listOfEndpoints = {
      Vendor: 'Import/vendorsJson/org/'+orgId+'?replaceExisting=0',
      Customer: 'Import/customersJson/org/'+orgId+'?replaceExisting=0',
      Bill: 'Import/billsJson/org/'+orgId+'?replaceExisting=0',
      ChargeBack: 'Import/chargeBackJsonV1/org/'+orgId+'?replaceExisting=0',
      Residual: 'Import/residualJson/org/'+orgId+'?replaceExisting=0',
    }
    let params = {
      "fContent": file.fContent,
      "fileDescriptor": file.filename,
      "filename": file.filename,
    }
    this.props.global.setLoading(true);
    axios.post(process.env.REACT_APP_URL_API + listOfEndpoints[this.props.modalType],params,
    {
        headers: { 'requestToken': tk },
        
    })
    .then(res => {
      const added = res?.data?.responseData?.added;
      const rejected = res?.data?.responseData?.rejected;
      const errors = res?.data?.responseData?.errors;
      let errorInfo = ''
      this.props.global.setLoading(false);
      this.setState({uploadedFiles: [], statusUpload: 'draft',
      correctFiles: false,});
      this.props.closeModal();
      if(rejected){
        errors.map(err => {
          errorInfo += `Error in line ${err.line}: ${err.error}`;
          errorInfo += '\n';
        })
      }
      if(added){
        errorInfo += `Added ${added} lines \n`;
      }
      if(rejected){
        toast.error(errorInfo, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      }else{
        toast.success('Data saved successfully!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-success-container'
        });
      }
    })
    .catch(error => {
        this.props.global.setLoading(false);
        this.setState({uploadedFiles: [],
          correctFiles: false,});
        this.props.closeModal();
    });
    
  }
  getSampleFileFromType(type) {
    if(type === 'Vendor'){
      return VendorSample;
    }
    if(type === 'Bill'){
      return BillSample;
    }
    if(type === 'ChargeBack'){
      return ChargeBackSample;
    }
    if(type === 'Residual'){
      return ResidualSample;
    }
    return CustomerSample;
  }

  
  render() {
    const file = this.state.uploadedFiles.length > 0 ? this.state.uploadedFiles[0] : ''
    const fileName = file ? (
      <div className='mb-3 mt-3'>
          <div className='row'>
            <div className='col-5 text-left'>{this.state.correctFiles ? <BsCheck color='green' size={20}/> : <BsX color='red' size={20}/>}{file?.filename}</div>
            <div className='col-5'/>
            <div className='col-2'><span className='cursorPointer' onClick={(e) => this.handlerRemove(e)}>DELETE</span></div>
          </div>
      </div>
    ) : ''
    return (
      <>
        <Modal
          style={{ textAlign: 'center' }}
          show={this.props.isOpen}
          onHide={this.props.closeModal}
          dialogClassName="modal-import-width"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body style={{padding: '36px 36px 0 36px'}}>
            <div >
              <p style={{fontSize: '22px'}} data-qaid="importModal">{this.props.textHeader || 'Import data for Applications'}</p>
              <p className="small">
                
              </p>
              <Dropzone onDrop={this.handleFileEvent} accept={{'text/csv': ['.csv']}}>
              {({getRootProps, getInputProps}) => (
                <>
                {this.state.statusUpload === 'draft' && <section className="container" style={{padding: "48px 0 48px 0", border: '1px solid rgb(235 235 235 / 48%)', cursor: 'pointer'}}>
                  <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} data-qaid="uploadFilesModal"/>
                    <BiUpload color='#10A0E3' size={48}/>
                    <p>Click or drag file to this area to upload<br/>
                    <p style={{fontSize: '0.7rem', color:'#6C757D'}}  className='small text-mute'>Support for a single upload. Maximum file size 2MB.</p>
                    </p>
                    
                  </div>
                </section>}
                {this.state.statusUpload === 'failure' && <section className="container" style={{borderRadius: '4px',border: '2px solid rgb(251 134 124 / 100%)'}}>
                  <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
                    <BiXCircle color='#FB867C' size={48}/>
                  </div>
                    <p style={{marginBottom: '1rem'}}>Upload Not Complete! <br/>
                    <p style={{fontSize: '0.7rem', color:'#6C757D'}} className='small text-mute'>Please check your document and re-upload it.</p>
                    </p>
                </section>}
                {this.state.statusUpload === 'success' && 
                  <section className="container" style={{border: '2px solid rgb(156 204 101 / 100%)'}}>
                    <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
                      <BiCheckCircle color='#9CCC65' size={48}/>
                    </div>
                    <p style={{marginBottom: '1rem'}} data-qaid="uploadCompleteImport">Upload Complete! <br/>
                    <p style={{fontSize: '0.7rem', color:'#6C757D'}} className='small text-mute'>Document upload successfully</p>
                    </p>
                    
                </section>}
                <div>{fileName}</div></>
              )}
            </Dropzone>
          </div>
            
          </Modal.Body>
          <Modal.Footer style={{borderTop: '0'}}>
            <div style={{paddingRight: '10rem'}}>
              <p className='small mt-3'>
              <a href={this.getSampleFileFromType(this.props.modalType)} download={this.props.modalType + 'Sample.csv'}  data-qaid="downloadSampleFilesLinkModal">Download Sample Files (*.csv)</a>
              </p>
            </div>
            <Button
              className="btn cancel-btn"
              variant="light"
              onClick={(e) => this.handlerCloseModal(e)}
              data-qaid="cancelButtonModal"
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button  disabled={this.state.statusUpload !== 'success'}
              className="btn"
              variant={this.state.statusUpload !== 'success'? "secondary" : "primary"}
              onClick={(e)=> this.handlerReadFiles(e)}
              data-qaid="saveUploadlButtonModal"
            >
              SAVE UPLOAD
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export { ModalImport };
