import React from 'react';
import { inject, observer } from 'mobx-react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

@inject('global', 'filter')
@observer
class RenderPaymentInformationMo extends React.Component {

    render() {
        return (<>
                <h5 className='title'>Payment Information</h5>
                <p className='mb-1'><small>Type</small></p>
                <div className='row section-payment-information'>
                    <div className="col-2 mb-4">
                        <span className={this.props.filter.filterMethods.ach && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethodMo('ach', 'paymentMethod(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.ach))}>{this.props.global.getPaymethodImg('ach')}</span>
                        <p>ach</p>
                    </div>
                    <div className="col-2 mb-4">
                    <span className={this.props.filter.filterMethods.push2card && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethodMo('push2card', 'paymentMethod(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.push2card))}>{this.props.global.getPaymethodImg('pushtocard')}</span>
                        <p>push to card</p>
                    </div>
                    <div className="col-2 mb-4">
                        <span className={this.props.filter.filterMethods.check && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethodMo('check', 'paymentMethod(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.check))}>{this.props.global.getPaymethodImg('check')}</span>
                        <p>check</p>
                    </div>
                    <div className="col-2 mb-4">
                        <span className={this.props.filter.filterMethods.vcard && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethodMo('vcard', 'paymentMethod(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.vcard))}>{this.props.global.getPaymethodImg('vcard')}</span>
                        <p>vcard</p>
                    </div>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{'Pending Pay Type'}</Tooltip>}
                    >                            
                        <div className="col-2 mb-4">
                            <span className={this.props.filter.filterMethods.managed && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethodMo('managed', 'paymentMethod(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.managed))}>{this.props.global.getPaymethodImg('managed')}</span>
                            <p>managed</p>
                        </div>
                    </OverlayTrigger>
                </div>
            </>);
    }
}

export { RenderPaymentInformationMo };