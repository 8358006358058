import axios from 'axios';
import { PayabliStorageManager } from "../api/localStorageManager";
import { makeObservable , action, observable } from "mobx";
import moment from 'moment';
import * as Sentry from "@sentry/react";

class StatisticStore {
    

    constructor() {
		makeObservable(this)
	}

    @observable
    statistics = null;

    @observable
    sectionActive = 'all';

    async getBasicStatistic(mode, frequency, orgId, queryParams){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return await axios.get(process.env.REACT_APP_URL_API+ 'Statistic/basic/'+(queryParams ? 'custom' : mode)+'/'+frequency+'/0/' + orgId + (queryParams ? queryParams : '') ,{
        headers: {
            'requestToken': encryptStorage.getItem('pToken'),
        }})
        .then(res => {
            return res.data;
        })
        .catch(error => {
            Sentry.captureException(error);
            throw error;
        });
    }
    
    async getResidualsStatistic(interval, orgId, year){
        let data = []
        data['maxSumInOutTransactionsVolume'] = 0;
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return await axios.get(process.env.REACT_APP_URL_API+ 'Statistic/residuals/'+interval+'/0/' + orgId + (year ? '?Year='+year : '' ),{
        headers: {
            'requestToken': encryptStorage.getItem('pToken'),
        }})
        .then(res => {
            return res.data;
        })
        .catch(error => {
            Sentry.captureException(error);
            throw error;
        });
    }
    
    async getResidualsStatisticRange(interval, orgId, from, to){
        let data = []
        data['maxSumInOutTransactionsVolume'] = 0;
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return await axios.get(process.env.REACT_APP_URL_API+ `Statistic/residuals/${interval}/0/${orgId}?startDate=${from}&endDate=${to}`,{
        headers: {
            'requestToken': encryptStorage.getItem('pToken'),
        }})
        .then(res => {
            return res.data;
        })
        .catch(error => {
            Sentry.captureException(error);
            throw error;
        });
    }

    getDataTransactionsVolume(dataArray, bars, monthly) {
		let data = []
		data['labels'] = []
		 
        data['valuesInOutTransactionsVolume'] = Array(bars).fill(0);
        data['valuesMoneyInTransactionsVolume'] = Array(bars).fill(0);
        data['valuesMoneyOutTransactionsVolume'] = Array(bars).fill(0);
        data['valuesInTransactionsVolume'] = Array(bars).fill(0);
		data['valuesOutTransactionsVolume'] = Array(bars).fill(0);
		data['valuesAllShadowVolume'] = Array(bars).fill(0);
		data['valuesInShadowVolume'] = Array(bars).fill(0);
		data['valuesOutShadowVolume'] = Array(bars).fill(0);


        data['valuesInOutACHVolume'] = Array(bars).fill(0);
        data['valuesInACHVolume'] = Array(bars).fill(0);
        data['valuesOutACHVolume'] = Array(bars).fill(0);

        data['valuesInOutCardVolume'] = Array(bars).fill(0);
        data['valuesInCardVolume'] = Array(bars).fill(0);
        data['valuesOutCardVolume'] = Array(bars).fill(0);

        data['valuesInOutCheckVolume'] = Array(bars).fill(0);
        data['valuesInCheckVolume'] = Array(bars).fill(0);
        data['valuesOutCheckVolume'] = Array(bars).fill(0);

        data['valuesInOutCashVolume'] = Array(bars).fill(0);
        data['valuesInCashVolume'] = Array(bars).fill(0);
        data['valuesOutCashVolume'] = Array(bars).fill(0);
        
        data['valuesInOutVCardVolume'] = Array(bars).fill(0);
        data['valuesOutVCardVolume'] = Array(bars).fill(0);
        
        data['maxSumInOutTransactionsVolume'] = 0;
        data['maxSumInTransactionsVolume'] = 0;
        data['maxSumOutTransactionsVolume'] = 0;
       
        data['maxSumInOutTransactions'] = 0;
        data['maxSumInTransactions'] = 0;
        data['maxSumOutTransactions'] = 0;
        
        data['valuesInTransactions'] = Array(bars).fill(0);
        data['valuesOutTransactions'] = Array(bars).fill(0);
        data['valuesInOutTransactions'] = Array(bars).fill(0);
        	
        data['values_position'] = []

		for (let i = 0; i < dataArray.length ; i++) {
            if(dataArray[i]){
                let periodPosition = monthly ? this.getDayByPeriod(dataArray[i].statX) - 1 : this.getMonthByPeriod(dataArray[i].statX);
               
                data['valuesInTransactionsVolume'][periodPosition] = dataArray[i].inTransactionsVolume;
                data['valuesOutTransactionsVolume'][periodPosition] = dataArray[i].outTransactionsVolume;
                data['valuesInOutTransactionsVolume'][periodPosition] = dataArray[i].outTransactionsVolume + dataArray[i].inTransactionsVolume;
                data['valuesMoneyInTransactionsVolume'][periodPosition] = dataArray[i].inCardVolume + dataArray[i].inACHVolume + dataArray[i].inCheckVolume + dataArray[i].inCashVolume;
                data['valuesMoneyOutTransactionsVolume'][periodPosition] = dataArray[i].outACHVolume + dataArray[i].outCheckVolume + dataArray[i].outVCardVolume + dataArray[i].outCardVolume;
                     
                data['valuesInACHVolume'][periodPosition] = dataArray[i].inACHVolume;
                data['valuesOutACHVolume'][periodPosition] = dataArray[i].outACHVolume;
                data['valuesInOutACHVolume'][periodPosition] = dataArray[i].outACHVolume + dataArray[i].inACHVolume;
                
                data['valuesInCardVolume'][periodPosition] = dataArray[i].inCardVolume;
                data['valuesOutCardVolume'][periodPosition] = dataArray[i].outCardVolume;
                data['valuesInOutCardVolume'][periodPosition] = dataArray[i].outCardVolume + dataArray[i].inCardVolume;
                
                data['valuesInCheckVolume'][periodPosition] = dataArray[i].inCheckVolume;
                data['valuesOutCheckVolume'][periodPosition] = dataArray[i].outCheckVolume;
                data['valuesInOutCheckVolume'][periodPosition] = dataArray[i].outCheckVolume + dataArray[i].inCheckVolume;
                
                data['valuesInCashVolume'][periodPosition] = dataArray[i].inCashVolume;
                data['valuesOutCashVolume'][periodPosition] = 0;
                data['valuesInOutCashVolume'][periodPosition] = dataArray[i].inCashVolume;

                data['valuesOutVCardVolume'][periodPosition] = dataArray[i].outVCardVolume;
                data['valuesInOutVCardVolume'][periodPosition] = dataArray[i].outVCardVolume;

                data['valuesInTransactions'][periodPosition] = dataArray[i].inTransactions;
                data['valuesOutTransactions'][periodPosition] = dataArray[i].outTransactions;
                data['valuesInOutTransactions'][periodPosition] = dataArray[i].outTransactions + dataArray[i].inTransactions;

                if(dataArray[i].inTransactionsVolume + dataArray[i].outTransactionsVolume > data['maxSumInOutTransactionsVolume']){
                    data['maxSumInOutTransactionsVolume'] = dataArray[i].inTransactionsVolume + dataArray[i].outTransactionsVolume;
                }
                
                if(dataArray[i].inACHVolume + dataArray[i].inCardVolume + dataArray[i].inCheckVolume + dataArray[i].inCashVolume > data['maxSumInTransactionsVolume']){
                    data['maxSumInTransactionsVolume'] = dataArray[i].inACHVolume + dataArray[i].inCardVolume + dataArray[i].inCheckVolume + dataArray[i].inCashVolume;
                }
                
                if(dataArray[i].outACHVolume + dataArray[i].outCardVolume + dataArray[i].outCheckVolume + dataArray[i].outVCardVolume > data['maxSumOutTransactionsVolume']){
                    data['maxSumOutTransactionsVolume'] = dataArray[i].outACHVolume + dataArray[i].outCardVolume + dataArray[i].outCheckVolume + dataArray[i].outVCardVolume;
                }
                
                if(dataArray[i].inTransactions > data['maxSumInTransactions']){
                    data['maxSumInTransactions'] = dataArray[i].inTransactions;
                }
                
                if(dataArray[i].outTransactions > data['maxSumOutTransactions']){
                    data['maxSumOutTransactions'] = dataArray[i].outTransactions;
                }
                
                if(dataArray[i].outTransactions + dataArray[i].inTransactions > data['maxSumInOutTransactions']){
                    data['maxSumInOutTransactions'] = dataArray[i].outTransactions + dataArray[i].inTransactions;
                }
            }
		}

        for (let i = 0; i < bars ; i++) {
            data['valuesAllShadowVolume'][i] = data['maxSumInOutTransactionsVolume'] - (data['valuesInTransactionsVolume'][i] + data['valuesOutTransactionsVolume'][i]);
            data['valuesInShadowVolume'][i] = data['maxSumInTransactionsVolume'] - (data['valuesInACHVolume'][i] + data['valuesInCardVolume'][i] + data['valuesInCheckVolume'][i] + data['valuesInCashVolume'][i]);
            data['valuesOutShadowVolume'][i] = data['maxSumOutTransactionsVolume'] - (data['valuesOutACHVolume'][i] + data['valuesOutCardVolume'][i] + data['valuesOutCheckVolume'][i] + data['valuesOutVCardVolume'][i]);
        }
        
		return data
	}

    getDataResiduals(dataArray){
        let data = [];
        data['valuesPartnerResidual'] = Array(12).fill(0);
        data['valuesPartnerResidualIn'] = Array(12).fill(0);
        data['valuesPartnerResidualOut'] = Array(12).fill(0);
        data['valuesPartnerResidualCompare'] = Array(12).fill(0);
        data['maxPartnerResidualIn'] = 0;
        data['maxPartnerResidualOut'] = 0;
        data['maxPartnerResidualInOut'] = 0;

        for (let i = 0 ; i <= dataArray.length - 1 ; i++) {
            let periodPosition = this.getMonthByPeriod(dataArray[i].period);
            data['valuesPartnerResidual'][periodPosition] = data['valuesPartnerResidual'][periodPosition] + parseFloat(dataArray[i].partnerResidual);

            if(dataArray[i].serviceGroup === 'moneyin'){
                data['valuesPartnerResidualIn'][periodPosition] = data['valuesPartnerResidualIn'][periodPosition] + parseFloat(dataArray[i].partnerResidual);
                
                if(data['maxPartnerResidualIn'] < dataArray[i].partnerResidual){
                    data['maxPartnerResidualIn'] = dataArray[i].partnerResidual;
                }
            }
            if(dataArray[i].serviceGroup === 'moneyout'){
                data['valuesPartnerResidualOut'][periodPosition] = data['valuesPartnerResidualOut'][periodPosition] + parseFloat(dataArray[i].partnerResidual);

                if(data['maxPartnerResidualOut'] < dataArray[i].partnerResidual){
                    data['maxPartnerResidualOut'] = dataArray[i].partnerResidual;
                }
            }
		}

        data['maxPartnerResidualInOut'] = data['maxPartnerResidualIn'] + data['maxPartnerResidualOut'];

        return data;
    }

    getDataResidualsMonthsStats(dataArray){
        let dataMoneyIn = Array(24).fill({
            count : 0,
            grossExpense : 0,
            grossIncome : 0,
            partnerAdjustments : 0,
            partnerResidual : 0,
            partnerResidualVSVolume : 0,
            payabliAdjustments : 0,
            payabliIncome : 0,
            payabliNetIncome : 0,
            payabliNetIncomeVSVolume : 0,
            period : 0,
            processorIncome : 0,
            serviceGroup : 'moneyin',
            volume : 0,
        });
        let dataMoneyOut = Array(24).fill({
            count : 0,
            grossExpense : 0,
            grossIncome : 0,
            partnerAdjustments : 0,
            partnerResidual : 0,
            partnerResidualVSVolume : 0,
            payabliAdjustments : 0,
            payabliIncome : 0,
            payabliNetIncome : 0,
            payabliNetIncomeVSVolume : 0,
            period : 0,
            processorIncome : 0,
            serviceGroup : 'moneyout',
            volume : 0,
        });
        let dataReturn ={
            valueLastTwelveMonth: 0,
            valueLastSixMonth: 0,
            valueLastThreeMonth: 0,
            valueLastTwelveMonthCompare: 0,
            valueLastSixMonthCompare: 0,
            valueLastThreeMonthCompare: 0,
            valueLastMonth: 0,
            valueLastMonthVolume: 0,
            valueLastMonthNumberTrans: 0,
            valueLastMonthIncome: 0,
            valueLastMonthExpenses: 0,
            valueLastMonthBPS: 0,
        };
        
        let getLast24Months = this.getLast24Months();

        getLast24Months.forEach((month, i) => {
            dataArray.forEach((data) => {
                if(data.period === month && data.serviceGroup === 'moneyin'){
                    dataMoneyIn[i] = data;
                }else if(data.period === month && data.serviceGroup === 'moneyout'){
                    dataMoneyOut[i] = data;
                }
            });
        });

        dataMoneyIn.forEach((item, i) => {
            // 24 months (from 0 to 23) 

            //Residuals last 3 months
            if(i >= 21 ){
                dataReturn.valueLastThreeMonth = dataReturn.valueLastThreeMonth + item.partnerResidual + dataMoneyOut[i].partnerResidual;
            }
            if(i >= 18 &&  i <= 20){
                dataReturn.valueLastThreeMonthCompare = dataReturn.valueLastThreeMonthCompare + item.partnerResidual + dataMoneyOut[i].partnerResidual;
            }

            //Residuals last 6 months
            if(i >= 18 ){
                dataReturn.valueLastSixMonth = dataReturn.valueLastSixMonth + item.partnerResidual + dataMoneyOut[i].partnerResidual;
            }
            if(i >= 12 &&  i <= 17){
                dataReturn.valueLastSixMonthCompare = dataReturn.valueLastSixMonthCompare + item.partnerResidual + dataMoneyOut[i].partnerResidual;
            }

            //Residuals last 12 months
            if(i >= 12 ){
                dataReturn.valueLastTwelveMonth = dataReturn.valueLastTwelveMonth + item.partnerResidual + dataMoneyOut[i].partnerResidual;
            }
            if(i >= 0 &&  i <= 11){
                dataReturn.valueLastTwelveMonthCompare = dataReturn.valueLastTwelveMonthCompare + item.partnerResidual + dataMoneyOut[i].partnerResidual;
            }
            
            //Residuals last months
            if(i == 23 ){
                dataReturn.valueLastMonth = parseFloat(item.partnerResidual + dataMoneyOut[i].partnerResidual).toFixed(2);
                dataReturn.valueLastMonthVolume = parseFloat(item.volume + dataMoneyOut[i].volume).toFixed(2);
                dataReturn.valueLastMonthNumberTrans = item.count + dataMoneyOut[i].count;
                dataReturn.valueLastMonthIncome = parseFloat(item.grossIncome + dataMoneyOut[i].grossIncome).toFixed(2);
                dataReturn.valueLastMonthExpenses = parseFloat(item.grossExpense + dataMoneyOut[i].grossExpense).toFixed(2);
            }
        });

        return dataReturn;
    }

    getMonthByPeriod(period){
        if(period){
            let valuesArray = period.split('-');
            return valuesArray[1] ? parseInt(valuesArray[1]) - 1 : null;
        }
        return null;
    }

    getLast24Months(){
        let startDate = moment().subtract(24,'months').startOf('month');
        let endDate = moment().subtract(1,'months').endOf('month');
        let months = [];  // this should be an object
        let month = startDate;

        while (month < endDate) {
            months.push(month.format('YYYY-M'));
            month = month.clone().add(1, 'months');
        }
        return months;
    }
    
    getDayByPeriod(period){
        if(period){
            let day =  moment(period).format('D');
            return day;
        }
        return null;
    }

    normalizeReportData(data){
        let dataArray = [];
        for (let i = 0; i < data.length ; i++) {
            if(data[i].serviceGroup === this.sectionActive || this.sectionActive === 'all'){
                if(dataArray[data[i].period]){
                    dataArray[data[i].period] = {
                        period: data[i].period,
                        volume: data[i].volume + dataArray[data[i].period].volume,
                        count: data[i].count + dataArray[data[i].period].count,
                        grossIncome: data[i].grossIncome + dataArray[data[i].period].grossIncome,
                        grossExpense: data[i].grossExpense + dataArray[data[i].period].grossExpense,
                        processorIncome: data[i].processorIncome + dataArray[data[i].period].processorIncome,
                        payabliIncome: data[i].payabliIncome + dataArray[data[i].period].payabliIncome,
                        partnerAdjustments: data[i].partnerAdjustments + dataArray[data[i].period].partnerAdjustments,
                        payabliAdjustments: data[i].payabliAdjustments + dataArray[data[i].period].payabliAdjustments,
                        partnerResidual: data[i].partnerResidual + dataArray[data[i].period].partnerResidual,
                        payabliNetIncome: data[i].payabliNetIncome + dataArray[data[i].period].payabliNetIncome,
                        partnerResidualVSVolume: data[i].partnerResidualVSVolume + dataArray[data[i].period].partnerResidualVSVolume,
                        payabliNetIncomeVSVolume: data[i].payabliNetIncomeVSVolume + dataArray[data[i].period].payabliNetIncomeVSVolume,
                        serviceGroup: 'moneyinmoneyout'
                    };
                }
                else{
                    dataArray[data[i].period] = {
                        period: data[i].period,
                        volume: data[i].volume,
                        count: data[i].count,
                        grossIncome: data[i].grossIncome,
                        grossExpense: data[i].grossExpense,
                        processorIncome: data[i].processorIncome,
                        payabliIncome: data[i].payabliIncome,
                        partnerAdjustments: data[i].partnerAdjustments,
                        payabliAdjustments: data[i].payabliAdjustments,
                        partnerResidual: data[i].partnerResidual,
                        payabliNetIncome: data[i].payabliNetIncome,
                        partnerResidualVSVolume: data[i].partnerResidualVSVolume,
                        payabliNetIncomeVSVolume: data[i].payabliNetIncomeVSVolume,
                        serviceGroup: data[i].serviceGroup
                    };
                }
            }
            
        }

        let orderedDataArray = Array(12).fill(null);
        let position = null;
        for (let [key, value] of Object.entries(dataArray)) {
            position = key.split("-")[1]
            orderedDataArray[position-1]=value;
        }

        orderedDataArray = orderedDataArray.filter(function (el) { return el != null;});
        return orderedDataArray;
    }


    @action
    setSectionActive(value){
        this.sectionActive = value;
    }


}

const statisticStore = new StatisticStore();
export default statisticStore;