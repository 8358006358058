import React from 'react'

export const ExceptionIcon = (props) => {
    return (
        <svg
            width={14}
            height={14}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7 1.16675C3.78 1.16675 1.16667 3.78008 1.16667 7.00008C1.16667 10.2201 3.78 12.8334 7 12.8334C10.22 12.8334 12.8333 10.2201 12.8333 7.00008C12.8333 3.78008 10.22 1.16675 7 1.16675ZM7 7.58342C6.67917 7.58342 6.41667 7.32092 6.41667 7.00008V4.66675C6.41667 4.34591 6.67917 4.08342 7 4.08342C7.32084 4.08342 7.58333 4.34591 7.58333 4.66675V7.00008C7.58333 7.32092 7.32084 7.58342 7 7.58342ZM7.58333 9.91675H6.41667V8.75008H7.58333V9.91675Z"
                fill="#7F1D1D"
            />
        </svg>

    )
}
