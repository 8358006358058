import React, {useState, useContext} from 'react'
import { Modal } from 'react-bootstrap'
import { MdOutlineEmail } from 'react-icons/md'
import InputContainer from '../inputStyles/InputContainer'
import GlobalStore from '../../store/GlobalStore'
import applicationContext from '../../views/Boarding/Applications/context/applications_context'

export default function NoteSendMail({ showModal, closeModal, idMessage = '' }) {
    const { sendMail } = useContext(applicationContext)
    const [email, setEmail] = useState('')

    const send = async() =>{
        const res = await sendMail(idMessage, email, GlobalStore.multiple_emailsValidation(email))
        if(res?.success) closeModal()
    }
    
	return (
		<Modal
			style={{ textAlign: 'center' }}
			size="md"
			show={showModal}
			onHide={() => closeModal()}
			centered
			scrollable={false}>
			<Modal.Header 
                style={{padding:'24px 36px 16px 36px'}}
				closeButton = {false}
				className="border-bottom-0">
				<Modal.Title className='w-100'>
                    <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                        <MdOutlineEmail style={{width:'30px', height:'30px', color:'#03A9F4'}}/>
                        <label style={{ fontWeight: '700', fontSize: '16px' }}>Send note by Email</label>
                    </div>
                </Modal.Title>
			</Modal.Header>
			<Modal.Body style={{padding:'0px 36px 16px 36px'}}>
                <InputContainer
                    inputType={'text'}
                    inputDisabled={false}
                    labelValue={'Recipient e-mail address'}
                    placeholder={'example@domain.com'}
                    value={email}
                    setValue={(value) => setEmail(value)}
                    TooltipCaption={'We’ll send an email to the recipient that includes the note.'}
                    TooltipLabelStyle={{color: '#4A4A4A'}}
                    labelClassName='w-100 text-start'
                    classNameInput={`form-control ${email.toString().length > 0 && !GlobalStore.multiple_emailsValidation(email) ? 'is-invalid' : ''}`}
                    //mask={GlobalStore.maskValidator('email')}
                    //maxLength={maxLength}
                />
			</Modal.Body>
            <Modal.Footer className="border-top-0 d-flex flex-row flex-nowrap justify-content-center align-items-center" style={{padding:'0px 36px 24px 36px'}}>
                <button type="button" onClick={() => closeModal()} className="send-email-btn w-50">CANCEL</button>
                <button type="button" onClick={()=> send()} className="send-email-btn btn-send w-50">SEND EMAIL</button>
            </Modal.Footer>
		</Modal>
	)
}