import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ToastContainer , toast, Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '../../components/Layout';
import axios from 'axios';

import { Bar } from 'react-chartjs-2';
import {Link} from "react-router-dom";
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {BiDownload} from '@react-icons/all-files/bi/BiDownload';
import { PayabliStorageManager } from '../../api/localStorageManager'
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { BiDollarCircle, BiDoughnutChart, BiHome, BiRefresh, BiUpload } from 'react-icons/bi';
import { ResidualBox } from '../../components/ResidualBox';
import moneyin from '../../assets/images/moneyin.svg';
import moneyout from '../../assets/images/moneyout.svg';
import moment from 'moment/moment';
import ReactDOMServer from 'react-dom/server'
import { ModalImport } from '../Common/ModalImport';


const FileDownload = require('js-file-download');
const sections = {
    moneyIn: 'moneyin',
    moneyOut: 'moneyout',
    all: 'all',
}
const ranges = {
    year: 'year',
    month: 'month'
}

@inject('reports', 'global', 'statistic')
@observer
class SummaryResidualsReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: null,
            idPaypoint: null,
            transactionsVolume :{},
            residualsVolume :{},
            residualsVolumeMonthsStats :{},
            range: ranges.year,
            sectionActive: sections.all,
            moneyInOutYearActive: '',
            moneyInOutMonthActive: '',
            tooltipData: {
                valuesOutTransactionsVolume: 0,
                valuesInTransactionsVolume: 0,
                valuesPartnerResidual: 0,
                
                valuesInCardVolume: 0,
                valuesInACHVolume: 0,
                valuesInCheckVolume: 0,
                valuesInCashVolume: 0,
                
                valuesOutCardVolume: 0,
                valuesOutVCardVolume: 0,
                valuesOutCheckVolume: 0,
                valuesOutACHVolume: 0,

                valuesInTransactions: 0,
                valuesOutTransactions: 0,
                valuesInOutTransactions: 0
            },
            modalImportIsOpen: false
        };
        this.filter = this.filter.bind(this);
        this.getResidualsStatistics = this.getResidualsStatistics.bind(this);
        this.getResidualsStatisticsByYear = this.getResidualsStatisticsByYear.bind(this);
        this.getResidualsStatisticsByMonth = this.getResidualsStatisticsByMonth.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.initData = this.initData.bind(this);
        this.getBasicStatistic = this.getBasicStatistic.bind(this);
        this.goToResidualsReport = this.goToResidualsReport.bind(this);
        this.getResidualReport = this.getResidualReport.bind(this);
        this.getDataMonthBySection = this.getDataMonthBySection.bind(this);
        this.getGradientValues = this.getGradientValues.bind(this);

        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let orgId = encryptStorage && encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry') ? encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId : null;
        this.orgId = orgId;

        this.barsBorderRadius = [
            { topLeft: 200, topRight: 200, bottomLeft: 200, bottomRight: 200 },
        ];
    }
    
    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);

        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                period : { label:'Processing Period', class: '', display: true},
                volume : { label:'$ Volume', class: 'text-right', display: true},   
                count : { label:'# Transactions', class: 'text-right', display: true},           
                grossIncome : { label:'Income', class: 'text-right', display: true},
                grossExpense : { label:'Expense', class: 'text-right', display: true},
                partnerResidual : { label:'Residual', class: 'text-right', display: true},
            }
        )
        this.props.reports.setRecords([]);
        this.clearFilters();

        this.initData();
       
    }

    async getResidualsStatisticsByYear(year){
        this.props.global.setLoading(true);
        this.setState({
            moneyInOutYearActive : year
        });

        let basic = await this.getBasicStatistic("ytd", "m", `?startDate=${year}-01-01&endDate=${year}-12-31`);
        if(basic){
            this.setState({
                transactionsVolume : this.props.statistic.getDataTransactionsVolume(basic, 12)
            })
        }
        this.getResidualsStatistics("m", year);
    }
    
    getResidualsStatisticsByMonth(year, month){
        this.props.global.setLoading(true);
        this.setState({
            moneyInOutMonthActive : month
        }, function(){
            let daysInMonth = this.getDaysInMonth();
            let monthNumber = moment().month(month).format("MM");

            this.getBasicStatistic("ytd", "d", `?startDate=${year}-${monthNumber}-01&endDate=${year}-${monthNumber}-${daysInMonth}`)
            .then(res => {
                this.setState({
                    transactionsVolume : this.props.statistic.getDataTransactionsVolume(res, daysInMonth, true)
                })
                this.props.global.setLoading(false);
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }); 
    }

    clearFilters(){
        this.setState({ head: "",filterBrands: {},filterTypes: {},filterStatus: {} , dateStartDate: null,dateEndtDate: null}, function(){
            this.getReportFromApi();
        });
    }

    getReportFromApi() {
        let tempFilterValue = {};
        if(this.state.idPaypoint){
            tempFilterValue['paypointId(eq)'] = this.state.idPaypoint;
        }
        this.filter(tempFilterValue);
    }

    initData(){
        this.getTransactionsStatistics("ytd", "m");
        this.getResidualsStatistics("m", moment().year(), true);

        this.setState({
            moneyInOutYearActive: moment().year(),
            moneyInOutMonthActive: moment().format('MMM'),
            range: ranges.year,
            sectionActive: sections.all
        })
    }

    refreshData(){
        this.initData();
    }

    handleState(key,value,e){
        if(e){
            e.preventDefault();
        }
        this.setState({[key]:value});
    }

    setRangeToYear(e){
        if(e){
            e.preventDefault();
        }
        this.setState({range: ranges.year}, function(){
            this.getResidualsStatisticsByYear(this.state.moneyInOutYearActive);
        });
    }

    setRangeToMonth(e){
        if(e){
            e.preventDefault();
        }
        this.setState({range: ranges.month}, function(){
            this.getResidualsStatisticsByMonth(this.state.moneyInOutYearActive, this.state.moneyInOutMonthActive);
        });
    }

    setSectionActive(value, e){
        if(e){
            e.preventDefault();
        }
        this.setState({
            sectionActive : value
        }, function(){
            this.props.statistic.setSectionActive(value)
            
            if(this.state.range === ranges.month){
                this.getResidualsStatisticsByMonth(this.state.moneyInOutYearActive, this.state.moneyInOutMonthActive);
                this.getResidualsStatistics("m", this.state.moneyInOutYearActive);
            }else{
                this.getResidualsStatisticsByYear(this.state.moneyInOutYearActive);
            }
            
        })
    }

    async getTransactionsStatistics(mode, freq, queryParams){
        this.props.global.setLoading(true);
        
        let basic = await this.getBasicStatistic(mode,freq,queryParams);
        if(basic){
            this.setState({
                transactionsVolume : this.props.statistic.getDataTransactionsVolume(basic, 12)
            })
        }
        this.props.global.setLoading(false);
        
    }

    async getBasicStatistic(mode,freq,queryParams){
        return this.props.statistic.getBasicStatistic(mode,freq,this.orgId, queryParams);
    }

    async getLastTwelveResiduals(){
        let range = {
            'period(ge)': moment().subtract(12, 'months').format('YYYY-MM-DD'), 
            'period(le)': moment().format('YYYY-MM-DD')
        };

        this.props.reports.setCount(-1);
        this.props.reports.filterOrg(range,null, 'residuals').then(res => {
            
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }
   
    async getResidualsStatistics(interval, year, updateStatisticLastMonths){
        this.props.global.setLoading(true);
        let orgId = this.orgId;
        let residuals = await this.props.statistic.getResidualsStatistic(interval,orgId, year);

        let lastDayPrevMonth = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
        let firstDayPrevTwelveMonths = moment().subtract(24,'months').startOf('month').format('YYYY-MM-DD');

        let residualsLastTwelve = await this.props.statistic.getResidualsStatisticRange(interval,orgId, firstDayPrevTwelveMonths, lastDayPrevMonth);

        if(residuals){
            let residualsVolume = this.props.statistic.getDataResiduals(residuals.records);

            this.props.reports.setRecords(this.props.statistic.normalizeReportData(residuals.records));
            this.setState({
                residualsVolume : residualsVolume
            })
        }

        if(residualsLastTwelve){
            let residualsVolumeMonthsStats = this.props.statistic.getDataResidualsMonthsStats(residualsLastTwelve.records);
            this.setState({
                residualsVolumeMonthsStats : residualsVolumeMonthsStats
            })
        }
        
        this.props.global.setLoading(false);
    }

    getDataSetBars(){
        if(this.state.sectionActive === sections.all){
            return this.getDatasetAllBars();
        }
        if(this.state.sectionActive === sections.moneyIn){
            return this.getDatasetMoneyIn();
        }
        if(this.state.sectionActive === sections.moneyOut){
            return this.getDatasetMoneyOut();
        }
    }

    getDatasetAllBars(){
        var stateObj = this.state;
        let moneyInOut = {
            label: 'Money In Out',
            data: this.state.transactionsVolume.valuesInOutTransactionsVolume,
            backgroundColor: function(context){
                const chart = context.chart;
                const {ctx} = chart;
                let height = chart.chartArea?.height;
                let backgroundGradient = [];
                if(stateObj.transactionsVolume.valuesInOutTransactionsVolume){
                    for (let i = 0; i < stateObj.transactionsVolume.valuesInOutTransactionsVolume.length; i++) {
                        let item = stateObj.transactionsVolume.valuesInOutTransactionsVolume[i];
                        let barSize = item * height / (stateObj.transactionsVolume.maxSumInOutTransactionsVolume === 0 ? 1 : stateObj.transactionsVolume.maxSumInOutTransactionsVolume);
                        let ShadowSize = height - barSize;

                        // Money In 0
                        if(stateObj.transactionsVolume.valuesInTransactionsVolume[i] === 0){
                            const gradient = ctx.createLinearGradient(0, ShadowSize, 0, ShadowSize + barSize);
                            gradient.addColorStop(0, "#03A9F4");
                            gradient.addColorStop(1, "#2A47AD");
                            backgroundGradient.push(gradient)
                        }

                        // Money Out 0
                        if(stateObj.transactionsVolume.valuesOutTransactionsVolume[i] === 0){
                            const gradient = ctx.createLinearGradient(0, ShadowSize, 0, ShadowSize + barSize);
                            gradient.addColorStop(0, "#71BE19");
                            gradient.addColorStop(1, "#95C43A");
                            backgroundGradient.push(gradient)
                        }

                        // Money in/Out != 0
                        if(stateObj.transactionsVolume.valuesOutTransactionsVolume[i] !== 0 && stateObj.transactionsVolume.valuesInTransactionsVolume[i] !== 0){
                            let division = stateObj.transactionsVolume.valuesInTransactionsVolume[i] / stateObj.transactionsVolume.valuesInOutTransactionsVolume[i];
                            division = isNaN(division) ? 0 : division;

                            division = division < 0 ? 0 : division;
                            division = division > 1 ? 1 : division;

                            const gradient = ctx.createLinearGradient(0, ShadowSize, 0, ShadowSize + barSize);
                            gradient.addColorStop(0, "#71BE19");
                            gradient.addColorStop(division, "#95C43A");
                            gradient.addColorStop(division, "#03A9F4");
                            gradient.addColorStop(1, "#2A47AD");
                            backgroundGradient.push(gradient)
                        }

                      
                    }
                }
                return backgroundGradient;
            },
            borderRadius: this.barsBorderRadius,
            barPercentage: 0.35,
            borderSkipped: false,
            yAxisID: 'y'
        };

        let shadow = {
            label: "shadow",
            data: this.state.transactionsVolume.valuesAllShadowVolume,
            backgroundColor: function(context){
                const chart = context.chart;
                const {ctx} = chart;
                let height = chart.chartArea?.height;
                let backgroundGradient = [];

                if(stateObj.transactionsVolume.valuesInOutTransactionsVolume){
                    for (let i = 0; i < stateObj.transactionsVolume.valuesInOutTransactionsVolume.length; i++) {
                        let item = stateObj.transactionsVolume.valuesInOutTransactionsVolume[i];
                        let barSize = item * height / (stateObj.transactionsVolume.maxSumInOutTransactionsVolume === 0 ? 1 : stateObj.transactionsVolume.maxSumInOutTransactionsVolume);
                        let ShadowSize = height - barSize;

                        const gradient = ctx.createLinearGradient(0, 0, 0, ShadowSize+30);
                        gradient.addColorStop(0, "#F2F7FF");
                        gradient.addColorStop(0.9, "#F2F7FF");
                        gradient.addColorStop(1, '#ffffff');
                        backgroundGradient.push(gradient)
                    }
                }

                return backgroundGradient;
            },
            borderRadius: this.barsBorderRadius,
            barPercentage: 0.35,
            yAxisID: 'y'
        };

        return [this.getDataSetResidualTransactions(),moneyInOut,shadow];
    }
    
    getDatasetMoneyOut(){
        var stateObj = this.state;
        var thisObj = this;
        return [ this.getDataSetResidualTransactions(),
        {
            label: 'Money Out',
            data: this.state.transactionsVolume.valuesMoneyOutTransactionsVolume,
            backgroundColor: function(context){
                const chart = context.chart;
                const {ctx} = chart;
                let height = chart.chartArea?.height;
                let backgroundGradient = [];
            
                if(stateObj.transactionsVolume.valuesMoneyOutTransactionsVolume){
                    for (let i = 0; i < stateObj.transactionsVolume.valuesMoneyOutTransactionsVolume.length; i++) {
                        let item = stateObj.transactionsVolume.valuesMoneyOutTransactionsVolume[i];
                        let barSize = item * height / (stateObj.transactionsVolume.maxSumOutTransactionsVolume === 0 ? 1 : stateObj.transactionsVolume.maxSumOutTransactionsVolume);
                        let ShadowSize = height - barSize;
                        let valuesArray = [
                            { value : stateObj.transactionsVolume.valuesOutACHVolume[i], color :'#7CC041'},
                            { value : stateObj.transactionsVolume.valuesOutCheckVolume[i], color :'#00B8E8'},
                            { value : stateObj.transactionsVolume.valuesOutVCardVolume[i], color :'#2F82BC'},
                            { value : stateObj.transactionsVolume.valuesOutCardVolume[i], color :'#002A66'},
                        ]

                        let filter = thisObj.getGradientValues(valuesArray, stateObj.transactionsVolume.valuesMoneyOutTransactionsVolume[i]);

                        const gradient = ctx.createLinearGradient(0, ShadowSize, 0, ShadowSize + barSize);
                        filter.forEach((itemGradient)=>{
                            gradient.addColorStop(itemGradient.from, itemGradient.color);
                            gradient.addColorStop(itemGradient.to, itemGradient.color);
                        });
                        backgroundGradient.push(gradient)
                        
                    }
                }
                return backgroundGradient;
            },
            borderRadius: this.barsBorderRadius,
            borderSkipped: false,
            barPercentage: 0.35
        },
        {
            label: "shadow",
            data: this.state.transactionsVolume.valuesOutShadowVolume,
            backgroundColor: function(context){
                return thisObj.getGradientShadow(context, stateObj.transactionsVolume.valuesMoneyOutTransactionsVolume, stateObj.transactionsVolume.maxSumOutTransactionsVolume);
            },
            borderRadius: this.barsBorderRadius,
            borderSkipped: false,
            barPercentage: 0.35,
        }];
    }

    getDatasetMoneyIn(){
        var stateObj = this.state;
        var thisObj = this;
        return [
            this.getDataSetResidualTransactions(),
        {
            label: 'Money In',
            data: this.state.transactionsVolume.valuesMoneyInTransactionsVolume,
            backgroundColor: function(context){
                const chart = context.chart;
                const {ctx} = chart;
                let height = chart.chartArea?.height;
                let backgroundGradient = [];
                if(stateObj.transactionsVolume.valuesMoneyInTransactionsVolume){
                    for (let i = 0; i < stateObj.transactionsVolume.valuesMoneyInTransactionsVolume.length; i++) {
                        let item = stateObj.transactionsVolume.valuesMoneyInTransactionsVolume[i];
                        let barSize = item * height / (stateObj.transactionsVolume.maxSumInTransactionsVolume === 0 ? 1 : stateObj.transactionsVolume.maxSumInTransactionsVolume);
                        let ShadowSize = height - barSize;

                        let valuesArray = [
                            { value : stateObj.transactionsVolume.valuesInCardVolume[i], color :'#7CC041'},
                            { value : stateObj.transactionsVolume.valuesInACHVolume[i], color :'#00C0D6'},
                            { value : stateObj.transactionsVolume.valuesInCheckVolume[i], color :'#00A8E2'},
                            { value : stateObj.transactionsVolume.valuesInCashVolume[i], color :'#0587D1'},
                        ]

                        let filter = thisObj.getGradientValues(valuesArray, stateObj.transactionsVolume.valuesMoneyInTransactionsVolume[i]);

                        const gradient = ctx.createLinearGradient(0, ShadowSize, 0, ShadowSize + barSize);
                        filter.forEach((itemGradient)=>{
                            gradient.addColorStop(itemGradient.from, itemGradient.color);
                            gradient.addColorStop(itemGradient.to, itemGradient.color);
                        });
                        backgroundGradient.push(gradient)
                        
                    }
                }
                return backgroundGradient;
            },
            borderRadius: this.barsBorderRadius,
            borderSkipped: false,
            barPercentage: 0.35
        },
        {
            label: "shadow",
            data: this.state.transactionsVolume.valuesInShadowVolume,
            backgroundColor: function(context){
                return thisObj.getGradientShadow(context, stateObj.transactionsVolume.valuesMoneyInTransactionsVolume, stateObj.transactionsVolume.maxSumInTransactionsVolume);
            },
            borderRadius: this.barsBorderRadius,
            borderSkipped: false,
            barPercentage: 0.35,
        }];
    }

    getGradientShadow(context, valuesTransactionsVolume, maxSumTransactionsVolume){
        const chart = context.chart;
        const {ctx} = chart;
        let height = chart.chartArea?.height;
        let backgroundGradient = [];

        if(valuesTransactionsVolume){
            for (let i = 0; i < valuesTransactionsVolume.length; i++) {
                let item = valuesTransactionsVolume[i];
                let barSize = item * height / (maxSumTransactionsVolume === 0 ? 1 : maxSumTransactionsVolume);
                let ShadowSize = height - barSize;

                const gradient = ctx.createLinearGradient(0, 30, 0, ShadowSize + 20);
                gradient.addColorStop(0, "#F2F7FF");
                gradient.addColorStop(0.8, "#F2F7FF");
                gradient.addColorStop(1, '#ffffff');
                backgroundGradient.push(gradient)
            }
        }
        return backgroundGradient;
    }

    getGradientValues(valuesArray, maxSum){
        let filter = [];
        let from = 0;
        valuesArray.forEach((item)=> {
            if(item.value > 0){
                let percent = item.value / maxSum;
                if(from !== 0){
                    item.from = from > 1 ? 1 : from;
                    item.to = (from + percent) > 1 ? 1 : from + percent ;
                    from = item.to;
                }
                else{
                    item.to = percent > 1 ? 1 : percent;
                    item.from = from > 1 ? 1 : from;
                    from = percent;
                }
                
                
                filter.push(item)
            }
        });
        return filter;
    }

    getDataMonthBySection(){
        if(this.state.sectionActive === sections.all){
            return this.state.transactionsVolume.valuesInOutTransactions;
        }
        if(this.state.sectionActive === sections.moneyIn){
            return this.state.transactionsVolume.valuesInTransactions;
        }
        if(this.state.sectionActive === sections.moneyOut){
            return this.state.transactionsVolume.valuesOutTransactions;
        }
    }

    getValuesPartnerResidual(){
        if(this.state.sectionActive === sections.moneyIn){
            return this.state.residualsVolume.valuesPartnerResidualIn;
        }else if(this.state.sectionActive === sections.moneyOut){
            return this.state.residualsVolume.valuesPartnerResidualOut;
        }else{
            return this.state.residualsVolume.valuesPartnerResidual;
        }
    }

    getDataSetResidualTransactions(){
        return {
            type: 'line',
            label: 'data line',
            data: this.state.range === ranges.year ? this.getValuesPartnerResidual() : this.getDataMonthBySection(),
            backgroundColor: '#FFA726',
            borderColor: '#FFA726',
            borderWidth: 4,
            pointBorderColor: '#ffffff',
            pointRadius: 6,
            pointBorderWidth: 2,
            yAxisID: 'y1',
            tension: 0.3,
        };
    }

    exportFile(format) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
        return axios.get(process.env.REACT_APP_URL_API + 'Statistic/residuals/m/0/' + orgId+'?export='+format+'&Year='+this.state.moneyInOutYearActive, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            }
        })
            .then(res => {
                FileDownload(res.data, 'summary_residuals.' + format);
            })
            .catch(error => {
                throw error;
            });
    }

    exportFileMonth(period, format){
        this.props.reports.setFilters({period:period});
        this.props.reports.exportOrgFile('residuals',format,this.orgId);
        this.props.reports.clearFilters();
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filterStatOrg(type,value, 'residuals').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    getTooltipAmount(pValue){
        let value = this.props.global.numberWithCommasNegative(pValue ? pValue.toFixed(2) : '0.00');
        if(typeof value === 'object'){
            value = ReactDOMServer.renderToString(value);
        }
        return value;
    }

    getHtmlTooltipBody(){
        const htmlElement = document.createElement("div");

        if(this.state.sectionActive === sections.all){
            htmlElement.innerHTML = `
            <div class="text-left residual-tooltip-body">
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:rgb(255, 167, 38);">&nbsp;</div> 
                    <div>${this.state.range === ranges.year ? 'Total Residual' : '# Transactions'}</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.state.range === ranges.year ? `${this.getTooltipAmount(this.state.tooltipData.valuesPartnerResidual)}` : `${this.state.tooltipData.valuesInOutTransactions}`}</div>
                </div>
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:rgb(113, 190, 25);">&nbsp;</div> 
                    <div>Money In</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.getTooltipAmount(this.state.tooltipData.valuesInTransactionsVolume)}</div>
                </div>
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:rgb(42,71,173);">&nbsp;</div> 
                    <div>Money Out</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.getTooltipAmount(this.state.tooltipData.valuesOutTransactionsVolume)}</div>
                </div>
            </div>`;
        }
        if(this.state.sectionActive === sections.moneyIn){
            htmlElement.innerHTML = `
            <div class="text-left residual-tooltip-body">
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:rgb(255, 167, 38);">&nbsp;</div> 
                    <div>${this.state.range === ranges.year ? 'Total Residual' : '# Transactions'}</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.state.range === ranges.year ? `${this.getTooltipAmount(this.state.tooltipData.valuesPartnerResidual)}` : `${this.state.tooltipData.valuesInTransactions}`}</div>
                </div>
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:#71BE19;">&nbsp;</div> 
                    <div>Card</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.getTooltipAmount(this.state.tooltipData.valuesInCardVolume)}</div>
                </div>
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:#00C0D6;">&nbsp;</div> 
                    <div>ACH</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.getTooltipAmount(this.state.tooltipData.valuesInACHVolume)}</div>
                </div>
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:#00A8E2;">&nbsp;</div> 
                    <div>Check</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.getTooltipAmount(this.state.tooltipData.valuesInCheckVolume)}</div>
                </div>
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:#0587D1;">&nbsp;</div> 
                    <div>Cash</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.getTooltipAmount(this.state.tooltipData.valuesInCashVolume)}</div>
                </div>
            </div>
            `;
        }
        if(this.state.sectionActive === sections.moneyOut){
            htmlElement.innerHTML = `
            <div class="text-left residual-tooltip-body">
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:rgb(255, 167, 38);">&nbsp;</div> 
                    <div>${this.state.range === ranges.year ? 'Total Residual' : '# Transactions'}</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.state.range === ranges.year ? `${this.getTooltipAmount(this.state.tooltipData.valuesPartnerResidual)}` : `${this.state.tooltipData.valuesOutTransactions}`}</div>
                </div>
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:#7CC041;">&nbsp;</div> 
                    <div>ACH</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.getTooltipAmount(this.state.tooltipData.valuesOutACHVolume)}</div>
                </div>
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:#00B8E8;">&nbsp;</div> 
                    <div>Check</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.getTooltipAmount(this.state.tooltipData.valuesOutCheckVolume)}</div>
                </div>
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:#2F82BC;">&nbsp;</div> 
                    <div>Virtual Card</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.getTooltipAmount(this.state.tooltipData.valuesOutVCardVolume)}</div>
                </div>
                <div class="residual-tooltip-line-item">
                    <div class="residuals-color-legend" style="background-color:#002A66;">&nbsp;</div> 
                    <div>Push to Card</div>
                    <div style="float: right; line-height:25px; margin-left: 10px;">${this.getTooltipAmount(this.state.tooltipData.valuesOutCardVolume)}</div>
                </div>
            </div>
            `;
        }

        
        return htmlElement.firstElementChild;
    }

    getDaysInMonth(){
        return moment(`${this.state.moneyInOutYearActive}-${this.state.moneyInOutMonthActive}`, "YYYY-MMM").daysInMonth();
    }

    goToResidualsReport(period, e){
        if(e){
            e.preventDefault();
        }
        window.location =  "/" + this.props.global.getURLEntry() + '/report/residuals?period=' + period;
    }

    getMaxScale(type){
       
        if(this.state.sectionActive === sections.all){
            return this.state.transactionsVolume.maxSumInOutTransactionsVolume;
        }
        if(this.state.sectionActive === sections.moneyIn){
            return this.state.transactionsVolume.maxSumInTransactionsVolume;
        }
        if(this.state.sectionActive === sections.moneyOut){
            return this.state.transactionsVolume.maxSumOutTransactionsVolume;
        }

    }

    getScaleStep(type){
        if(type === 'transactions'){
            return this.state.transactionsVolume.maxSumInOutTransactions ?  parseInt(this.state.transactionsVolume.maxSumInOutTransactions / 6) : 1
        }

        if(this.state.sectionActive === sections.all){
            if(type === 'residuals'){
                return this.state.residualsVolume.maxPartnerResidualInOut ?  parseInt(this.state.residualsVolume.maxPartnerResidualInOut / 6) : 1
            }

            return this.state.transactionsVolume.maxSumInOutTransactionsVolume ?  parseInt(this.state.transactionsVolume.maxSumInOutTransactionsVolume / 6) : 1;
        }
        if(this.state.sectionActive === sections.moneyIn){
            if(type === 'residuals'){
                return this.state.residualsVolume.maxPartnerResidualIn ?  parseInt(this.state.residualsVolume.maxPartnerResidualIn / 6) : 1
            }

            return this.state.transactionsVolume.maxSumInTransactionsVolume ?  parseInt(this.state.transactionsVolume.maxSumInTransactionsVolume / 6) : 1;
        }
        if(this.state.sectionActive === sections.moneyOut){
            if(type === 'residuals'){
                return this.state.residualsVolume.maxPartnerResidualOut ?  parseInt(this.state.residualsVolume.maxPartnerResidualOut / 6) : 1
            }

            return this.state.transactionsVolume.maxSumOutTransactionsVolume ?  parseInt(this.state.transactionsVolume.maxSumOutTransactionsVolume / 6) : 1;
        }
    }

    getResidualReport(){
        return this.props.reports.records.slice(0).reverse();
    }


    render() {

        let currentYear = moment().year();
        let daysInMonth = this.getDaysInMonth();
        let daysInMonthArray = !isNaN(daysInMonth) ? Array.from({length: daysInMonth}, (_, i) => i + 1) : [];
        let objThis = this;

        const dataBarsLines = {
            labels: ['JAN','FEB', 'MAR', "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
            datasets: this.getDataSetBars()
        };
        
        const dataBarsLinesMonth = {
            labels: daysInMonthArray,
            datasets: this.getDataSetBars()
        };

        const getOrCreateTooltip = (chart) => {
            let tooltipEl = chart.canvas.parentNode.querySelector('div');
          
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.style.background = 'rgba(255, 255, 255, 1)';
              tooltipEl.style.borderRadius = '6px';
              tooltipEl.style.color = '#000000';
              tooltipEl.style.opacity = 1;
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.boxShadow = "0 0 16px rgba(0,0,0,0.2)";
              //tooltipEl.style.transform = 'translate(-50%, 0)';
              tooltipEl.style.transition = 'all .5s ease';
              tooltipEl.style.fontSize='12px';
              const div = document.createElement('div');
              tooltipEl.appendChild(div);
              chart.canvas.parentNode.appendChild(tooltipEl);
            }
            
            return tooltipEl;
          };

        const externalTooltipHandler = (context) => {
            // Tooltip Element
            const {chart, tooltip} = context;
            const tooltipEl = getOrCreateTooltip(chart);

            if(tooltip.dataPoints?.length === 0){
                tooltipEl.style.opacity = 0;
                return false
            }

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }
          
            // Set Text
            if (tooltip.body) {
              const titleLines = tooltip.title || [];
              const bodyLines = tooltip.body.map(b => b.lines);

              const divHead = document.createElement('div');
              divHead.style.fontWeight = 'bold';
              divHead.style.margin = '10px 15px 10px 15px';

              titleLines.forEach(title => {
                const text = document.createTextNode((this.state.range === ranges.year ? moment(title, 'MMM').format('MMMM') : `${moment(this.state.moneyInOutMonthActive, 'MMM').format('MMMM')} ${title}`) + ", "+ this.state.moneyInOutYearActive);
                divHead.appendChild(text);
              });
          
              const divBody = document.createElement('div');
              bodyLines.forEach((body, i) => {
                divBody.appendChild(this.getHtmlTooltipBody());
                divBody.style.margin = '5px 15px 10px 15px';
              });
          
              const divRoot = tooltipEl.querySelector('div');
              divRoot.style.minWidth = "20px";
              divRoot.style.textAlign = "left";
              
              // Remove old children
              while (divRoot.firstChild) {
                divRoot.firstChild.remove();
              }

              // Add new children
              divRoot.appendChild(divHead);
              divRoot.appendChild(divBody);
            }
          
            const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
            let ttleft = tooltip.caretX;
            
            if(ttleft > chart.width - 230){
                ttleft = chart.width - 300;
            }

            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + ttleft + 'px';
            tooltipEl.style.top = positionY + tooltip.caretY + 'px';
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
          };

       

        const optionsBarsLines = {
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                filter: function (tooltipItem) {
                    return tooltipItem.dataset.label !== "shadow";
                },
                enabled: false,
                external: externalTooltipHandler
              }
            },

            onHover: (event, item) => {
                if(item[0] && item[0].index !== undefined){
                   
                    if(this.state.sectionActive === sections.all){
                        let tooltipData = {
                            valuesOutTransactionsVolume: this.state.transactionsVolume.valuesOutTransactionsVolume[item[0].index],
                            valuesInTransactionsVolume: this.state.transactionsVolume.valuesInTransactionsVolume[item[0].index],
                            valuesPartnerResidual: this.state.residualsVolume.valuesPartnerResidual[item[0].index],
                            valuesInOutTransactions: this.state.transactionsVolume.valuesInOutTransactions[item[0].index],
                        }

                        if(this.state.tooltipData.valuesOutTransactionsVolume !== tooltipData.valuesOutTransactionsVolume || 
                            this.state.tooltipData.valuesInTransactionsVolume !== tooltipData.valuesInTransactionsVolume ||
                            this.state.tooltipData.valuesPartnerResidual !== tooltipData.valuesPartnerResidual ||
                            this.state.tooltipData.valuesInOutTransactions !== tooltipData.valuesInOutTransactions){
                                 this.setState({
                                     tooltipData: tooltipData
                                 })
                         }
                    }

                    if(this.state.sectionActive === sections.moneyIn){
                        let tooltipData = {
                            valuesInCardVolume: this.state.transactionsVolume.valuesInCardVolume[item[0].index],
                            valuesInACHVolume: this.state.transactionsVolume.valuesInACHVolume[item[0].index], 
                            valuesInCheckVolume: this.state.transactionsVolume.valuesInCheckVolume[item[0].index],
                            valuesInCashVolume: this.state.transactionsVolume.valuesInCashVolume[item[0].index],
                            valuesPartnerResidual: this.state.residualsVolume.valuesPartnerResidualIn[item[0].index],
                            valuesInTransactions: this.state.transactionsVolume.valuesInTransactions[item[0].index],
                        }

                        if(this.state.tooltipData.valuesInCardVolume !== tooltipData.valuesInCardVolume || 
                            this.state.tooltipData.valuesInACHVolume !== tooltipData.valuesInACHVolume ||
                            this.state.tooltipData.valuesInCheckVolume !== tooltipData.valuesInCheckVolume ||
                            this.state.tooltipData.valuesInCashVolume !== tooltipData.valuesInCashVolume ||
                            this.state.tooltipData.valuesPartnerResidual !== tooltipData.valuesPartnerResidual ||
                            this.state.tooltipData.valuesInTransactions !== tooltipData.valuesInTransactions){
                                 this.setState({
                                     tooltipData: tooltipData
                                 })
                         }
                    }

                    if(this.state.sectionActive === sections.moneyOut){
                        let tooltipData = {
                            valuesOutCardVolume: this.state.transactionsVolume.valuesOutCardVolume[item[0].index],
                            valuesOutVCardVolume: this.state.transactionsVolume.valuesOutVCardVolume[item[0].index],
                            valuesOutCheckVolume: this.state.transactionsVolume.valuesOutCheckVolume[item[0].index],
                            valuesOutACHVolume: this.state.transactionsVolume.valuesOutACHVolume[item[0].index],
                            valuesPartnerResidual: this.state.residualsVolume.valuesPartnerResidualOut[item[0].index],
                            valuesOutTransactions: this.state.transactionsVolume.valuesOutTransactions[item[0].index],
                        }

                        if(this.state.tooltipData.valuesOutCardVolume !== tooltipData.valuesOutCardVolume || 
                            this.state.tooltipData.valuesOutVCardVolume !== tooltipData.valuesOutVCardVolume ||
                            this.state.tooltipData.valuesOutCheckVolume !== tooltipData.valuesOutCheckVolume ||
                            this.state.tooltipData.valuesOutACHVolume !== tooltipData.valuesOutACHVolume ||
                            this.state.tooltipData.valuesPartnerResidual !== tooltipData.valuesPartnerResidual ||
                            this.state.tooltipData.valuesOutTransactions !== tooltipData.valuesOutTransactions){
                                 this.setState({
                                     tooltipData: tooltipData
                                 })
                         }
                    }

                    
                }
            },
            
            scales: {
                x: {
                  stacked: true,
                  grid: {
                    display: false,
                    drawBorder: false,
                  }
                },
                y: {
                    stacked: true,
                    grid: {
                        display: true,
                        drawBorder: false,
                    },
                    ticks: {
                        // calculated
                        stepSize: this.getScaleStep(),
                        callback: function(value, index,values) {
                            return objThis.props.global.kFormatter(value);
                        }
                    },
                    //max: this.getMaxScale()
                },
                y1:{
                    position: 'right',
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        // calculated
                        stepSize: this.state.range === ranges.month ? this.getScaleStep('transactions') : this.getScaleStep('residuals'),
                        callback: function(value) {
                            return objThis.props.global.kFormatter(value);
                        }
                    }
                }
            },
            
          };

        
        return (
            <Layout {...this.props}>
            <div>
                <ModalImport
                    isOpen={this.state.modalImportIsOpen}
                    closeModal={()=> this.setState({modalImportIsOpen: false})}
                    action={this.handleImportAction}
                    modalType='Residual'
                    textHeader="Import Residual Data"
                    initialState={{uploadedFiles: [],correctFiles: []}}
                />
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <div className='row'>
                        <div className='col-2'></div>
                        <div className='col-8 text-center'>
                            <Link className={'selected'} to={"/"+this.props.global.getURLEntry()+"/report/summaryresiduals"}><BiHome/> Overview</Link>
                            <Link to="#"><BiDoughnutChart/> Analytics</Link>
                        </div>
                        <div className='col-2'></div>
                    </div>
                </div>
                </TopBar>
                <div className="mt-body4">


                    <div className="row mb-4 datatable-actions">
                        <div className="col-12 col-sm-8 col-md-6 col-lg-4 order-sm-1 order-md-1 order-lg-1 order-1">
                            <div className="mr-3 inline">
                                <h5 style={{display: "inline-block"}}>Residuals</h5>
                                <p className="small">Detailed overview of all Residual Payouts across Organizations</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-4 order-sm-3 order-md-3 order-lg-2 order-3 text-center a-tabs-style" >
                            <a style={{textTransform: "none"}} href='/' onClick={(e)=>this.setSectionActive("all",e)} className={this.state.sectionActive === sections.all ? 'active no-border' : ''}><BiDollarCircle className='icon'/>  All</a>
                            <a style={{textTransform: "none"}} href='/' onClick={(e)=>this.setSectionActive("moneyin",e)} className={this.state.sectionActive === sections.moneyIn ? 'active no-border' : ''}><img className='icon' alt="" src={moneyin}/>  Money In</a>
                            <a style={{textTransform: "none"}} href='/' onClick={(e)=>this.setSectionActive("moneyout",e)} className={this.state.sectionActive === sections.moneyOut ? 'active no-border' : ''}><img className='icon' alt="" src={moneyout}/> Money Out</a>
                        </div>
                        <div className="col-12 col-sm-4 col-md-6 col-lg-4 order-2 order-sm-2 order-md-2 order-lg-3 text-right text-center-sm">
                            <button onClick={()=>this.refreshData()} style={{padding: "4px 6px"}} className='btn btn-default bordered'><BiRefresh style={{fontSize: "21px"}}/></button>
                            
                            <button className="btn btn-default bordered ml-2" onClick={() => this.setState({modalImportIsOpen: true})}>
                                <div>
                                    <BiUpload data-qaid={`importSummaryResidualsButton`}/> <span className="main-span-button">Import</span>
                                </div>
                            </button>
                            
                            <DropdownButton
                                menuAlign="right"
                                title={<div><BiDownload/> <span className="main-span-button">Export Residuals, {this.state.moneyInOutYearActive}</span></div>}
                                size="sm"
                                variant="default"
                            >
                                <Dropdown.Item onClick={(e) => this.exportFile('xlsx')}>Download xlsx</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => this.exportFile('csv')}>Download csv</Dropdown.Item>
                            </DropdownButton>
                            
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <ResidualBox reportParametersFrom={moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD')} reportParametersTo={moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')} label={"Total Last 12 Months"} before={this.state.residualsVolumeMonthsStats.valueLastTwelveMonthCompare} amount={this.state.residualsVolumeMonthsStats.valueLastTwelveMonth}/>
                        </div>
                        <div className='col'>
                            <ResidualBox reportParametersFrom={moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD')} reportParametersTo={moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')} label={"Total Last 6 Months"} before={this.state.residualsVolumeMonthsStats.valueLastSixMonthCompare} amount={this.state.residualsVolumeMonthsStats.valueLastSixMonth}/>
                        </div>
                        <div className='col'>
                            <ResidualBox reportParametersFrom={moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD')} reportParametersTo={moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')} label={"Total Last 3 Months"} before={this.state.residualsVolumeMonthsStats.valueLastThreeMonthCompare} amount={this.state.residualsVolumeMonthsStats.valueLastThreeMonth}/>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-5 full-1300 mb-4'>
                            <div className='border border-1 p-3 rounded-4' style={{minHeight: "135px"}}>
                                <div className='mb-1 float-end text-right'>
                                    <span className='small-small grey fw-bold'>Last Month</span>
                                    <br/>
                                    <p className="blue-text text-underline mb-0 fw-bold cursor-pointer" onClick={(e)=>this.goToResidualsReport(`${moment().subtract(1, 'months').year()}-${moment().subtract(1, 'months').format('MM')}`,e)}>{moment().subtract(1, 'months').format('MMMM, YYYY')}{}</p>
                                </div>

                                <div className='mb-1'><span className='small-small grey fw-bold'>Residuals</span></div>
                                <h4 className='mb-2'>{this.state.residualsVolumeMonthsStats.valueLastMonth ? this.props.global.numberWithCommasNegative(this.state.residualsVolumeMonthsStats.valueLastMonth) : '$0.00'}</h4>
                                <div className='row'>
                                    <div className='col text-left'>
                                        <span className="small-small grey mb-0 fw-bold">Volume</span><br/>
                                        <span className="small-small mb-0">{this.props.global.numberWithCommasNegative(this.state.residualsVolumeMonthsStats.valueLastMonthVolume ? this.state.residualsVolumeMonthsStats.valueLastMonthVolume : 0)}</span>
                                    </div>
                                    <div className='col text-left'>
                                        <span className="small-small grey mb-0 fw-bold"># Trans.</span><br/>
                                        <span className="small-small mb-0">{this.state.residualsVolumeMonthsStats.valueLastMonthNumberTrans ? this.state.residualsVolumeMonthsStats.valueLastMonthNumberTrans : 0}</span>
                                    </div>
                                    <div className='col text-left'>
                                        <span className="small-small grey mb-0 fw-bold">Income</span><br/>
                                        <span className="small-small mb-0">{this.props.global.numberWithCommasNegative(this.state.residualsVolumeMonthsStats.valueLastMonthIncome ? this.state.residualsVolumeMonthsStats.valueLastMonthIncome : 0)}</span>
                                    </div>
                                    <div className='col text-left'>
                                        <span className="small-small grey mb-0 fw-bold">Expense</span><br/>
                                        <span className="small-small mb-0">{this.props.global.numberWithCommasNegative(this.state.residualsVolumeMonthsStats.valueLastMonthExpenses ? this.state.residualsVolumeMonthsStats.valueLastMonthExpenses : 0)}</span>
                                    </div>
                                    <div className='col text-left'>
                                        <span className="small-small grey mb-0 fw-bold">BPS</span><br/>
                                        <span className="small-small mb-0">{this.state.residualsVolumeMonthsStats.valueLastMonthBPS ? this.state.residualsVolumeMonthsStats.valueLastMonthBPS : '0.00'}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className='border border-1 p-4 rounded-4 residual-chart-container mt-2'>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-lg-3'>Residuals & Volume</div>
                                    <div className='col-12 col-md-12 col-lg-6 badges-collection small text-center'>
                                    {this.state.range === ranges.year ? 
                                        <>
                                        <span onClick={()=>this.getResidualsStatisticsByYear(currentYear - 3)} className={this.state.moneyInOutYearActive === (currentYear - 3) ? 'active': ''}>{currentYear - 3}</span>
                                        <span onClick={()=>this.getResidualsStatisticsByYear(currentYear - 2)} className={this.state.moneyInOutYearActive === (currentYear - 2) ? 'active': ''}>{currentYear - 2}</span>
                                        <span onClick={()=>this.getResidualsStatisticsByYear(currentYear - 1)} className={this.state.moneyInOutYearActive === (currentYear - 1) ? 'active': ''}>{currentYear - 1}</span>
                                        <span onClick={()=>this.getResidualsStatisticsByYear(currentYear)} className={this.state.moneyInOutYearActive === currentYear ? 'active' :'' }>{currentYear}</span>
                                        </>
                                        :
                                        <>
                                        {
                                            moment.monthsShort().map((record, i) => (
                                                <span key={i} onClick={()=>this.getResidualsStatisticsByMonth(this.state.moneyInOutYearActive, record)} className={this.state.moneyInOutMonthActive === record ? "active":""}>{record}</span>
                                            ))
                                        }
                                        
                                        </>
                                        }
                                    </div>
                                    <div className='col-12 col-md-12 col-lg-3 text-right a-tabs-style fw-bold' style={{fontSize: "10px"}}>
                                        <a href='/' onClick={(e)=>this.setRangeToMonth(e)} className={ (this.state.range === ranges.month ? 'active ' : '') + 'pt-0' }>MONTH</a>
                                        <a href='/' onClick={(e)=>this.setRangeToYear(e)} className={ (this.state.range === ranges.year ? 'active ' : '') + 'pt-0' }>YEAR {this.state.range === ranges.month ? `, ${this.state.moneyInOutYearActive}` : ''}</a>
                                    </div>
                                </div>
                                <hr className='mt-3' style={{borderTop: "solid 1px #dee2e6", opacity: "1", margin: "0 -23px"}}/>
                                {this.state.range === ranges.year ? 
                                <>
                                <div className='row mt-3 mb-3'>
                                    <div className='col-2 text-left small-small fw-bold'><span>$ Volume</span></div>
                                    <div className='col-8 badges-collection small text-center pe-none'>
                                        {this.state.sectionActive === sections.all &&
                                        <>
                                        <span><div className='small-circle' style={{background: "#FFA726"}}></div> RESIDUAL</span>
                                        <span><div className='small-circle' style={{background: "#71BE19"}}></div> MONEY IN</span>
                                        <span><div className='small-circle small-circle-gradient'></div> MONEY OUT</span>
                                        </>
                                        }
                                        {this.state.sectionActive === sections.moneyIn &&
                                        <>
                                        <span><div className='small-circle' style={{background: "#FFA726"}}></div> RESIDUAL</span>
                                        <span><div className='small-circle' style={{background: "#71BE19"}}></div> CARD</span>
                                        <span><div className='small-circle' style={{background: "#00C0D6"}}></div> ACH</span>
                                        <span><div className='small-circle' style={{background: "#00A8E2"}}></div> CHECK</span>
                                        <span><div className='small-circle' style={{background: "#0587D1"}}></div> CASH</span>
                                        </>
                                        }
                                        {this.state.sectionActive === sections.moneyOut &&
                                        <>
                                        <span><div className='small-circle' style={{background: "#FFA726"}}></div> RESIDUAL</span>
                                        <span><div className='small-circle' style={{background: "#7CC041"}}></div> ACH</span>
                                        <span><div className='small-circle' style={{background: "#00B8E8"}}></div> CHECK</span>
                                        <span><div className='small-circle' style={{background: "#2F82BC"}}></div> VIRTUAL CARD</span>
                                        <span><div className='small-circle' style={{background: "#002A66"}}></div> PUSH TO CARD</span>
                                        </>
                                        }
                                    </div>
                                    <div className='col-2 text-right small-small fw-bold'><span>$ Residual</span></div>
                                </div>
                                <div style={{width: "100%",position: 'relative'}}>
                                    <div className='text-center' style={{ width: "100%"}}>
                                        <Bar options={optionsBarsLines} data={dataBarsLines} height="80vh"/>
                                        <label className='mt-3 small'><b>{this.state.range === ranges.year ? "Months" : "Days"}</b></label>
                                    </div>
                                </div>
                                </>
                                :
                                <>
                                <div className='row mt-3 mb-3'>
                                    <div className='col-2 text-left small-small fw-bold'><span>$ Volume</span></div>
                                    <div className='col-8 badges-collection small text-center pe-none'>
                                        {this.state.sectionActive === sections.all &&
                                        <>
                                        <span><div className='small-circle' style={{background: "#FFA726"}}></div> TRANSACTIONS</span>
                                        <span><div className='small-circle' style={{background: "#71BE19"}}></div> MONEY IN</span>
                                        <span><div className='small-circle small-circle-gradient'></div> MONEY OUT</span>
                                        </>
                                        }
                                        {this.state.sectionActive === sections.moneyIn &&
                                        <>
                                        <span><div className='small-circle' style={{background: "#FFA726"}}></div> TRANSACTIONS</span>
                                        <span><div className='small-circle' style={{background: "#71BE19"}}></div> CARD</span>
                                        <span><div className='small-circle' style={{background: "#6CA738"}}></div> ACH</span>
                                        <span><div className='small-circle' style={{background: "#5D9031"}}></div> CHECK</span>
                                        <span><div className='small-circle' style={{background: "#4E7A28"}}></div> CASH</span>
                                        </>
                                        }
                                        {this.state.sectionActive === sections.moneyOut &&
                                        <>
                                        <span><div className='small-circle' style={{background: "#FFA726"}}></div> TRANSACTIONS</span>
                                        <span><div className='small-circle' style={{background: "#03A9F4"}}></div> ACH</span>
                                        <span><div className='small-circle' style={{background: "#0097DD"}}></div> CHECK</span>
                                        <span><div className='small-circle' style={{background: "#0078B0"}}></div> VIRTUAL CARD</span>
                                        <span><div className='small-circle' style={{background: "#2A47AD"}}></div> PUSH TO CARD</span>
                                        </>
                                        }
                                    </div>
                                    <div className='col-2 text-right small-small fw-bold'><span># Transactions</span></div>
                                </div>
                                <div style={{width: "100%",position: 'relative'}}>
                                    <div className='text-center' style={{ width: "100%"}}>
                                        <Bar options={optionsBarsLines} data={dataBarsLinesMonth} height="80vh"/>
                                        <label className='mt-3 small'><b>{this.state.range === ranges.year ? "Months" : "Days"}</b></label>
                                    </div>
                                </div>
                                </>
                                }

                            </div>
                            
                        </div>
                    
                    </div>
                
                    <div className="report-container mb-5 mt-5">
                        <label className='fs-5 mb-3 mr-2'>Residual Payouts</label> 
                        {(this.state.sectionActive === sections.all || this.state.sectionActive === sections.moneyIn) &&
                        <><span className="badge bg-success custom-badge-1" style={{border: "solid 1px #6fa233"}}><img className='icon' alt="" src={moneyin} style={{display: 'inline-block', marginTop: '-3px'}}/> Money In</span> &nbsp;</>
                        }
                        
                        {(this.state.sectionActive === sections.all || this.state.sectionActive === sections.moneyOut) &&
                        <span className="badge bg-primary custom-badge-1" style={{border: "solid 1px #5592af"}}><img className='icon' alt="" src={moneyout} style={{display: 'inline-block', marginTop: '-3px'}}/> Money Out</span>
                        }
                        <table className="table table-hover table-striped">
                            <thead>
                            <tr>
                            {
                                
                                this.props.reports.getHeaders.map((record, i) => (
                                    ( record[1].display &&
                                    <th key={i} scope="col" className={record[1].class}>{record[1].label}</th>                            
                                )))

                               
                                
                            }
                             <th className='text-center' scope="col">Actions</th>      
                            </tr>
                            
                        </thead>
                        <tbody>                
                        { this.getResidualReport().map((record, i) => (
                            <tr key={i}>                            
                            {(this.props.reports.headers.period && this.props.reports.headers.period.display) &&
                                <td>{record && record.period ? <a href="/" onClick={(e)=>this.goToResidualsReport(this.props.global.stringDateFormatFilters(record.period+'-1').replace("-01",""),e)}>{this.props.global.stringDateFormat(record.period+'-1', true)}</a> : '-'}</td>
                            }
                            { (this.props.reports.headers.volume && this.props.reports.headers.volume.display) &&
                                <td className="text-right">{record && record.volume ? this.props.global.numberWithCommasNegative(parseFloat(record.volume).toFixed(2)): '$0.00'}</td>
                            }
                            { (this.props.reports.headers.count && this.props.reports.headers.count.display) &&
                                <td className="text-right">{record && record.count ? record.count: '0'}</td>
                            }
                            { (this.props.reports.headers.grossIncome && this.props.reports.headers.grossIncome.display) &&
                                <td className="text-right">{record && record.grossIncome ? this.props.global.numberWithCommasNegative(parseFloat(record.grossIncome).toFixed(2)) : '$0.00'}</td>
                            }                            
                            { (this.props.reports.headers.grossExpense && this.props.reports.headers.grossExpense.display) &&
                                <td className="text-right">{record && record.grossExpense ? this.props.global.numberWithCommasNegative(parseFloat(record.grossExpense).toFixed(2)) : '$0.00'}</td>
                            }                           
                            { (this.props.reports.headers.partnerResidual && this.props.reports.headers.partnerResidual.display) &&
                                <td className="text-right">
                                    <span className='border border-primary rounded-pill text-right px-3 py-1 fw-bold'>{record && record.partnerResidual ? this.props.global.numberWithCommasNegative(parseFloat(record.partnerResidual).toFixed(2)) : '$0.00'}</span>
                                </td>
                            }
                            <td className='text-center'>                           
                                <DropdownButton
                                    menuAlign="right"
                                    title="..."
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                    >
                                        <Dropdown.Item><div onClick={(e)=>this.goToResidualsReport(this.props.global.stringDateFormatFilters(record.period+'-1').replace("-01",""),e)}>View Details</div></Dropdown.Item>                              
                                        <Dropdown.Item><div onClick={(e)=>this.exportFileMonth(this.props.global.stringDateFormatFilters(record.period+'-1').replace("-01",""), 'xlsx')}>Download xlsx</div></Dropdown.Item>                              
                                        <Dropdown.Item><div onClick={(e)=>this.exportFileMonth(this.props.global.stringDateFormatFilters(record.period+'-1').replace("-01",""), 'csv')}>Download csv</div></Dropdown.Item>                              
                                    </DropdownButton>
                            </td>                       
                            </tr>
                            
                        ))
                        }                
                        </tbody>
                        </table>
                        {this.props.reports.records.length < 1 &&
                            <RecordsNotFound message="No residuals just yet" description={<span>Income is earned on your paypoints as they process transactions.<br/> As soon as income is earned, it will display here.</span>}/>
                        }
                    </div>
                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { SummaryResidualsReport };