import React, { useEffect, useState } from 'react'
import { getTiersDetailsGET } from '../../../store/Template/Connections/connections'
import { TopBar } from '../../../components/TopBar'
import GlobalStore from '../../../store/GlobalStore'

const TiersDetails = (props) => {
	const [tiers, setTiers] = useState([])
	const [isError, setIsError] = useState(false)
	const [error, setError] = useState({
		message: 'Something is Wrong!',
		todoAction: '',
		code: 400
	})
	useEffect(() => {
		try {
			const load = async () => {
				try {
					const tiersResult = await getTiersDetailsGET()
					if (!unmounted) {
						setTiers(tiersResult?.data?.Records)
					}
				} catch (error) {
					setError({
						message:
							error?.response?.data?.responseData?.explanation ||
							error?.message,
						todoAction:
							error?.response?.data?.responseData?.todoAction,
						code: error?.response?.data?.responseCode || '400'
					})
					setIsError(true)
				}
			}
			let unmounted = false
			load()
			return () => {
				unmounted = true
			}
		} catch (error) {
			console.log('error', error)
		}
	}, [])
	const convertFieldToCorrectType = (field, value) => {
		const excludesNumbers = ['CcdefaultMcc', 'TierId']
		const dateFields = ['CreatedAt', 'LastModified']
		if (value === '') {
			return '-'
		}
		if (dateFields.includes(field)) {
			return GlobalStore.stringDateFormatV3(value)
		}
		if (!excludesNumbers.includes(field) && !isNaN(value)) {
			return parseFloat(value).toFixed(2)
		}
		return value
	}
	const tableTiers = () => {
		return (
			<div>
				<TopBar style={{ padding: '0' }}>
					<div
						className="top-bar-sub"
						style={{ padding: '8px 0px 25 40px' }}>
						<div className="row d-flex justify-content-center align-items-center">
							<h6>Tier Details</h6>
						</div>
					</div>
				</TopBar>
				<div style={{ marginTop: '4rem' }}>
					<div className="report-container">
						<table className="table table-hover table-striped">
							<thead>
								{columnsHeaders.map((column, index) => (
									<th key={'row-header'}>
										<td key={`header-column-${index}`}>
											{column}
										</td>
									</th>
								))}
							</thead>
							<tbody>
								{tiers.map((tier, index) => {
									return (
										<tr key={`row-tier-${index}`}>
											{columnsHeaders.map(
												(column, cIndex) => (
													<td
														key={`tier-${index}-column-${cIndex}`}
														className={
															isNaN(tier[column])
																? 'text-center'
																: 'text-right'
														}>
														{convertFieldToCorrectType(
															column,
															tier[column]
														)}
													</td>
												)
											)}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}
	const errorInformation = (error) => {
		return (
			<section style={{ backgroundImage: 'none', minHeight: '100vh' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '50vh'
					}}>
					<h1
						className="mb-3"
						style={{ fontSize: '50px' }}>
						{error.code}
					</h1>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '50vh',
						backgroundColor: '#03a9f4',
						color: '#fff'
					}}>
					<h4 style={{ color: '#fff' }}>{error.message}</h4>
					<p style={{ color: '#fff' }}>{error.todoAction}</p>
				</div>
			</section>
		)
	}
	const columnsHeaders = (tiers.length > 0 && Object.keys(tiers[0])) || []
	return <>{isError ? errorInformation(error) : tableTiers()}</>
}

export default TiersDetails
