import React from 'react';
import { inject, observer } from 'mobx-react';
import PartialViewBatchDetailReport from './PartialViewBatchDetailReport';
import { BiX } from 'react-icons/bi';

function ParentView({ global }) {
	const { slideParentViewOpen } = global;

	const handleCloseSlide = () => {
		global.setSlideParentViewOpen(false);
	};

	const getPartialView = () => {
		return <PartialViewBatchDetailReport />;
	};

	return (
		<>
			<div
				className={slideParentViewOpen ? 'open' : ''}
				id='parentViewSlide'>
				<span onClick={() => handleCloseSlide()}>
					<BiX id='right-panel-close' />
				</span>
				{getPartialView()}
			</div>
			{slideParentViewOpen && (
				<div
					onClick={(e) => handleCloseSlide()}
					id='right-panel-layer'></div>
			)}
		</>
	);
}

export default inject('global')(observer(ParentView));
