import React from 'react'
import RowContainer from '../../RowContainer'
import GlobalStore from '../../../../store/GlobalStore'

export default function CheckCommerce({ inputDisabled, service, serviceName, indexService, indexGateways, handleChange, setClassValidate, classValidate }) {
	return (
		<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
			<div className="d-flex flex-column align-items-start p-0">
				<RowContainer
					inputType="mask"
					classNameInput={classValidate.checkcommerce.username}
					inputDisabled={inputDisabled}
					labelValue="User Name"
					name={'username[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.username}
					onBlur={() => setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, username: service.Gateways[indexGateways].Connector.configuration.credentials.username ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'username', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>

				<RowContainer
					inputType="mask"
					classNameInput={classValidate.checkcommerce.password}
					inputDisabled={inputDisabled}
					labelValue="Password"
					name={'password[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.password}
					onBlur={() => setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, password: service.Gateways[indexGateways].Connector.configuration.credentials.password ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'password', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>
			</div>
			<div className="d-flex flex-column align-items-start p-0">
				<RowContainer
					inputType="mask"
					classNameInput={classValidate.checkcommerce.merchantNumber}
					inputDisabled={inputDisabled}
					labelValue="Merchant Number"
					name={'merchantNumber[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.merchantNumber}
					onBlur={() => setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, merchantNumber: service.Gateways[indexGateways].Connector.configuration.credentials.merchantNumber ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'merchantNumber', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>
			</div>
		</div>
	)
}
