import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportPaginator } from '../../components/ReportPaginator';
import { RightPanel } from '../../components/RightPanel';
import { ReportFilters } from '../../components/ReportFilters';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {IMaskInput} from 'react-imask';
import { VirtualTerminal } from '../../components/VirtualTerminal';
import { toJS } from "mobx";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {Link} from "react-router-dom";
import { PayabliStorageManager } from '../../api/localStorageManager'

import { Layout } from '../../components/Layout'
import { OrganizationOverviewLinks } from '../../components/OrganizationOverviewLinks';
import { CgUnavailable } from '@react-icons/all-files/cg/CgUnavailable';
import {BiFilterAlt} from '@react-icons/all-files/bi/BiFilterAlt';
import {BiMailSend} from '@react-icons/all-files/bi/BiMailSend';
import {BiShare} from '@react-icons/all-files/bi/BiShare';
import {BiX} from '@react-icons/all-files/bi/BiX';
import {BiXCircle} from '@react-icons/all-files/bi/BiXCircle';
import {BiNotepad} from '@react-icons/all-files/bi/BiNotepad';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { MainBar } from '../../components/MainBar';

@inject('reports', 'global', 'vTerminal', 'entry', 'customer')
@observer
class OrgTransactionsReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: true,
            head: "",
            filterBrands: {},
            filterTypes: {},
            filterStatus: {},
            filterSettlementStatus: {},
            filtersEnabled: false,
            endDate: null,

            flagItemAllColumn: true,
            voidModalIsOpen: true,
            transIdToVoid: null,

            refundModalIsOpen: true,
            transIdToRefund: null,
            refundAmount: "0.00",
            refundAmountError: false,
            refundAmountMax: 0,

            transEmailToSendReceipt: null,
            transIdToSendReceipt: null,
            sendReceiptModalIsOpen: true,
            sendReceiptAddress: "",
            sendReceiptError: true,

            vterminalRightPanelOpen: false,
            vTerminalAutopay: 1,

            entry: null,
            idOrg: null,
            customFields: [],

            currentOrgName: ''
        };
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.filter = this.filter.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleBrand = this.handleBrand.bind(this);
        this.handleType = this.handleType.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
        this.handleSettlementStatus = this.handleSettlementStatus.bind(this);
        this.handleQuickFilter = this.handleQuickFilter.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        
        this.voidTransaction = this.voidTransaction.bind(this);
        this.openVoidModal = this.openVoidModal.bind(this);
        this.voidTransactionAction = this.voidTransactionAction.bind(this);
        this.closeVoidModal = this.closeVoidModal.bind(this);
        
        this.closeRefundModal = this.closeRefundModal.bind(this);
        this.refundTransactionAction = this.refundTransactionAction.bind(this);
        this.handleRefundAmount = this.handleRefundAmount.bind(this);
        this.refundTransaction = this.refundTransaction.bind(this);
        
        this.openSendReceiptModal = this.openSendReceiptModal.bind(this);
        this.sendTransactionReceipt = this.sendTransactionReceipt.bind(this);
        this.closeSendReceiptModal = this.closeSendReceiptModal.bind(this);
        this.sendReceiptTransactionAction = this.sendReceiptTransactionAction.bind(this);

        this.closeVterminalPanel = this.closeVterminalPanel.bind(this);
        this.openVterminalPanel = this.openVterminalPanel.bind(this);
        this.chargeAgain = this.chargeAgain.bind(this);
        this.getCustomFieldsFromApi = this.getCustomFieldsFromApi.bind(this);
        this.focus = this.focus.bind(this);
        this.selectAllFilters = this.selectAllFilters.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
    }
    
    componentDidMount() {
        this.setState({ 
            idOrg: this.props.match.params.idOrg ? this.props.match.params.idOrg : null 
        });

        if(this.props.match.params.idOrg){
            this.props.entry.getOrganization(this.props.match.params.idOrg).then(res => {
                this.setState({currentOrgName:res.OrgName});
            })
            .catch(error => {
                toast.error("Something is Wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }

        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setRightPanelOpen(false);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                paymentId : { label:'Payment ID', class: '', display: true, filter: 'paymentId'},
                ParentOrgName : { label:'Organization', class: '', display: false},
                PaypointLegalname : { label:'Paypoint', class: '', display: true, filter: 'PaypointLegalname'},
                gatewayTransId  : { label:'Transaction ID', class: '', display: true, filter: 'gatewayTransId'}, 
                company  : { label:'Company', class: '', display: false, filter: 'company'},
                customer  : { label:'Customer', class: '', display: true, filter: 'customer'}, 
                customerNumber  : { label:'Customer #', class: '', display: false, filter: 'customerNumber'},
                billingEmail  : { label:'Email', class: '', display: false, filter: 'customerEmail'}, 
                payorPhone  : { label:'Phone #', class: '', display: false, filter: 'phoneNumber'},
                billingAddress  : { label:'Billing Address', class: '', display: false},
                shippingAddress  : { label:'Shipping Address', class: '', display: false},
                invoiceNumber  : { label:'Invoice #', class: '', display: true},
                date      : { label:'Date', class: '', display: true, filter: 'date'}, 
                time      : { label:'Time', class: '', display: true}, 
                brand     : { label:'Brand', class: 'text-center', display: true, filter: 'brand'}, 
                last4     : { label:'Last 4', class: '', display: true, filter: "payaccountLastfour"}, 
                type      : { label:'Type', class: '', display: true, filter: 'type'}, 
                status    : { label:'Status', class: '', display: true, filter: 'status'}, 
                settlementStatus    : { label:'Batch Status', class: '', display: true,  filter: 'settlementStatus'}, 
                netAmount    : { label:'Amount', class: 'text-center', display: true, filter: "totalAmount"}, 
                feeAmount    : { label:'Fee', class: 'text-center', display: false}, 
                totalAmount    : { label:'Total Amount', class: 'text-center', display: false}, 
                authCode    : { label:'Auth. Code', class: 'text-center', display: false}, 
                source    : { label:'Source', class: '', display: false},
                notes    : { label:'Notes', class: 'text-center', display: true}
            }
        )
        this.getCustomFieldsFromApi();
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

    closeVterminalPanel(){
        this.setState({ vterminalRightPanelOpen: false});
    }

    chargeAgain(customer, categories, autopay, record, capture){
        if(capture && capture === true){
            this.setState({actionCapture: true});
            this.props.vTerminal.setOperation("authorize");
        }
        else{
            this.setState({actionCapture: false});
            this.props.vTerminal.setOperation("sale");
        }

        this.props.vTerminal.setPaymentMethod('');
        this.props.vTerminal.setDefaultPaymentMethodActiveKey('2');
        this.props.vTerminal.setPaymentMethodSavedPosition('2');
        this.props.vTerminal.setPaymentMethodSaved(null);
        
        let categoriesJS = toJS(categories);
        if (categoriesJS !== null) {
            categoriesJS.forEach(function (item, index) {
                if (typeof item.value === 'undefined' && typeof item.amount !== 'undefined') {
                    item.value = String(item.amount);
                    delete item.amount;
                }
                else {
                    item.value = String(item.value);
                }
                if (typeof item.description === 'undefined') {
                    item.description = item.label;
                }
                if (typeof item.quantity === 'undefined' && typeof item.qty !== 'undefined') {
                    item.quantity = item.qty;
                    delete item.qty;
                }
                if (typeof item.showDescription === 'undefined') {
                    item.showDescription = false;
                }
                if (typeof item.type === 'undefined') {
                    item.type = "quantity";
                }
                if (typeof item.name === 'undefined') {
                    item.name = String(Date.now());
                }
            });

        }
        else {
            categoriesJS = [{
                name: "amountDefaultPayabliApp",
                type: 'customer',
                label: 'Amount you wish to charge',
                value: String(record.NetAmount),
                description: '',
                quantity: 1,
                showDescription: true
            }];
        }
        

        this.props.global.setLoading(true);
        this.props.customer.getCustomerFromApi(customer.customerId).then(res => {
            if(res.data){
                let customer = res.data;
                this.props.vTerminal.selectCustomerObject(customer);
                let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;
                
                if(this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods.length === 0){
                    if(this.props.vTerminal.hasCards && ((credentialsPermissions.card.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.card.recurring && this.props.vTerminal.isAutopay))){
                        this.props.vTerminal.setPaymentMethod('card');
                        this.props.vTerminal.setDefaultPaymentMethodActiveKey("0");
                    }else if(this.props.vTerminal.hasECheck && ((credentialsPermissions.ach.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.ach.recurring && this.props.vTerminal.isAutopay))){
                        this.props.vTerminal.setPaymentMethod('ach');
                        this.props.vTerminal.setDefaultPaymentMethodActiveKey("1");
                    }
                    
                }
                
                this.props.vTerminal.setAmountCategories(categoriesJS);
                this.openVterminalPanel(autopay, record);
                
            }
            else{
                this.props.global.setLoading(false);
                let errorMessage =  "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            }
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    openVterminalPanel(autopay,record){
        if(autopay === 1){
            this.props.vTerminal.disableAutopay(true);
        }
        else{
            this.props.vTerminal.disableAutopay(false);
        }

        this.props.global.setLoading(true);
        this.props.vTerminal.setEntryPoint(record.PaypointEntryname ? record.PaypointEntryname : '');
        this.props.vTerminal.getPaypointCredentials(record.PaypointEntryname ? record.PaypointEntryname : '').then(res => {
            this.props.vTerminal.updateCustomerPaymentsOptions();
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });


        this.setState({ vterminalRightPanelOpen: true, vTerminalAutopay: autopay});
    }

    handleRefundAmount(value){
        this.setState({ refundAmount: value });
        if(value < 0.01){
            this.setState({ refundAmountError: true });
        }
        else{
            if(value > this.state.refundAmountMax){
                this.setState({ refundAmountError: true });
            }else{
                this.setState({ refundAmountError: false });
            }
        }
    }
    
    handleSendReceiptAddress(value){
        this.setState({ sendReceiptAddress: value });
        if(!this.props.global.validators.isEmpty(value) && !this.props.global.validators.stringValidator('email',value)){
            this.setState({ sendReceiptError: false });
        }
        else{
            this.setState({ sendReceiptError: true });
        }
    }

    voidTransaction(transaction){
        this.setState({ transIdToVoid: transaction });
        this.openVoidModal();
    }

    sendTransactionReceipt(transaction, email){
        this.setState({ sendReceiptAddress: email, transIdToSendReceipt: transaction  , sendReceiptError: email ? false : true });
        this.openSendReceiptModal();
    }

    refundTransaction(transaction, totalAmount){
        this.setState({ 
            transIdToRefund: transaction, 
            refundAmount: String(totalAmount),
            refundAmountMax: totalAmount,
            refundAmountError: false
        });
        this.openRefundModal();
    }
    
    voidTransactionAction(){
        let transIdToVoid = this.state.transIdToVoid;
        if(transIdToVoid){
            this.props.global.setLoading(true);
            this.props.reports.voidTransaction(transIdToVoid)
            .then(result => {
                this.setState({ voidModalIsOpen: true });
                this.props.global.setLoading(false);
                if (result.data.responseData.resultCode && result.data.responseData.resultCode !== 1) {
                    let message = result.data.responseText ? "Sorry, the operation was " + result.data.responseText : 'Sorry, we have a problem voiding the payment.';
                    toast.error(message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                }
                else {
                    let records = this.props.reports.records;
                    records.forEach(function (item, index) {
                        if (item.PaymentTransId === transIdToVoid) {
                            records[index].TransStatus = 5;
                        }
                    });
                    toast.success("Voided successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                }
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }
    
    refundTransactionAction(){
        if(this.state.refundAmountError){
            return;
        }

        let transIdToRefund = this.state.transIdToRefund;
        
        if(transIdToRefund){
            this.props.global.setLoading(true);
            this.props.reports.refundTransaction(transIdToRefund, this.state.refundAmount)
            .then(result => {
                this.setState({ refundModalIsOpen: true });
                this.props.global.setLoading(false);
                if (result.data.responseData.resultCode && result.data.responseData.resultCode !== 1) {
                    let message = result.data.responseText ? "Sorry, the payment was " + result.data.responseText : 'Sorry, we have a problem refunding your payment.';
                    toast.error(message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                }
                else {
                    toast.success("Refund successful!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                }
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    sendReceiptTransactionAction(){
        if(this.state.sendReceiptError){
            return;
        }

        let transIdToSendReceipt = this.state.transIdToSendReceipt;
        let sendReceiptAddress = this.state.sendReceiptAddress;
        
        if(transIdToSendReceipt && sendReceiptAddress){
            this.props.global.setLoading(true);
            this.props.reports.sendReceiptTransaction(transIdToSendReceipt, sendReceiptAddress)
            .then(result => {
                this.setState({ sendReceiptModalIsOpen: true });
                this.props.global.setLoading(false);
                toast.success("Transaction Receipt Emailed Successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    openRefundModal(){
        this.setState({ refundModalIsOpen: false });
    }
    
    closeRefundModal(){
        this.setState({ refundModalIsOpen: true });
    }

    openVoidModal(){
        this.setState({ voidModalIsOpen: false });
    }
    
    openSendReceiptModal(){
        this.setState({ sendReceiptModalIsOpen: false });
    }
    
    closeSendReceiptModal(){
        this.setState({ sendReceiptModalIsOpen: true });
    }
    
    closeVoidModal(){
        this.setState({ voidModalIsOpen: true });
    }

    getCustomFieldsFromApi(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let idOrg = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
        let reactObj = this;
        this.props.reports.getOrgSettings(idOrg).then(res => {
            if(res.data.customFields){
                let fields = res.data.customFields;
                fields.forEach(function (item, index) {
                    reactObj.props.reports.addHeader(item.key ,{ label:item.key, class: '', display: true, filter: item.key, custom: true});
                });
                this.setState({customFields:fields});
            }
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }


    getReportFromApi(){
        
        this.props.global.setLoading(true);
        this.props.reports.getReportFromApiOrgID('transactions', this.state.idOrg).then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    clearFilters(){
        this.setState({ head: "",filterBrands: {},filterTypes: {},filterStatus: {}, filterSettlementStatus: {} , dateStartDate: null,dateEndtDate: null}, function(){
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    selectAllFilters(containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === false) {
                checkBoxes[i].click();
            }
        }

    }

    showHideFilters(){
        this.setState({ filtersEnabled: !this.state.filtersEnabled }, function(){
            if(this.state.filtersEnabled === false){
                this.clearFilters();
            }
        });
    }

    

    viewRecordDetails(index, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            let thisObj = this;
            if(thisObj.props.global.rightPanelOpen && thisObj.props.reports.recordDetails !== index){
                thisObj.props.global.setRightPanelOpen(false);
                thisObj.props.reports.setRecordDetails(index);
                setTimeout(function(){
                    thisObj.props.global.setRightPanelOpen(true);
                }, 500)                
            }else{
                thisObj.props.reports.setRecordDetails(index);
                thisObj.props.global.setRightPanelOpen(true);
            }
        }
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    exportFile(format){
        this.props.reports.exportOrgFile('transactions', format, this.state.idOrg);
    }

    handleQuickFilter(value){
        this.filter('operation(eq)',value)
        this.setState({ head: value });
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filterOrg(type, value, 'transactions/org', this.state.idOrg).then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
        });
    }

    handleKeyDown(type, e){
        if (e.key === 'Enter') {
            this.filter(type,e.target.value);
        }
    }

    clearFilter(e, filter, filterType){
        e.preventDefault();
        this.setState({ [filterType]: {} });
        this.filter(filter,"");
    }

    focus(e){
        e.target.focus();
        e.target.setSelectionRange(0, 1000000000);
    }
    

    handleBrand(e){
        
        let brands = this.state.filterBrands;
        brands[e.target.id] = e.target.checked;

        var arrayValues = [];
        for (let key in brands) {
            if(brands[key]){
                arrayValues.push(key);
            }
        }
        this.setState({ filterBrands: brands });
        this.filter("payaccountType(in)",arrayValues.join("|"));
    }

    handleType(e){
        
        let types = this.state.filterTypes;
        types[e.target.id] = e.target.checked;

        var arrayValues = [];
        for (let key in types) {
            if(types[key]){
                arrayValues.push(key);
            }
        }
        this.setState({ filterTypes: types });
        this.filter("operation(in)",arrayValues.join("|"));
    }

    handleStatus(e){
        
        let status = this.state.filterStatus;
        status[e.target.value] = e.target.checked;

        var arrayValues = [];
        for (let key in status) {
            if(status[key]){
                arrayValues.push(key);
            }
        }
        this.setState({ filterStatus: status });
        this.filter("status(in)",arrayValues.join("|"));
    }

    handleSettlementStatus(e){
        
        let status = this.state.filterSettlementStatus;
        status[e.target.value] = e.target.checked;

        var arrayValues = [];
        for (let key in status) {
            if(status[key]){
                arrayValues.push(key);
            }
        }
        this.setState({ filterSettlementStatus: status });
        this.filter("settlementStatus(in)",arrayValues.join("|"));
    }
    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }
    handleChangeCalendar(dates) {
        const [start, end] = dates;
        this.setState({dateStartDate: start,dateEndtDate: end}, function(){
            const type = [];
            type['transactionDate(ge)'] = this.props.global.stringDateFormatFilters(start)+ "T00:00:00";
            type['transactionDate(le)'] = this.props.global.stringDateFormatFilters(end)+ "T23:59:59";
            if(end){
                this.filter(type,null);
            }
        });
        
    };

    render() {
        const recordDetail = this.props.reports.getRecordDetails;
        return (
            <Layout {...this.props}>

                <div id="vterminal-rigth-panel" className={ this.state.vterminalRightPanelOpen ? 'open' : ''}>
                    <div className="popover-body popover-body-2">
                        <BiX id="right-panel-close" onClick={(e) => this.closeVterminalPanel()}/>
                        <h5 className="header mb-3">{this.state.actionCapture === true ? "Capture": "Charge Again"}</h5>
                        <VirtualTerminal paymentSuccessFunctionCallBack={this.closeVterminalPanel} autopay={0}/>
                    </div>
                </div>
                {this.state.vterminalRightPanelOpen  &&
                <div onClick={(e) => this.closeVterminalPanel()} id="right-panel-layer"></div>
                }

            <div>
                <Modal style={{textAlign: "center"}} show={!this.state.voidModalIsOpen} onHide={this.closeVoidModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiXCircle className="icon-modal"/>
                    <h5>Void</h5>
                    <p className="small">Are you sure you want to void this transaction?</p>
                    <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeVoidModal()}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button className="btn" variant="primary" onClick={this.voidTransactionAction}>
                        Void
                    </Button>
                </Modal.Body>
                </Modal>

                <Modal style={{textAlign: "center"}} show={!this.state.refundModalIsOpen} onHide={this.closeRefundModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiShare className="icon-modal"/>
                    <h5>Refund Payment</h5>
                    <p className="small">Transaction Id:</p>
                    <p className="small-grey">{this.state.transIdToRefund}</p>

                    <NumberFormat
                        onFocus={(e)=>this.focus(e)} 
                        thousandsGroupStyle="thousand"
                        decimalSeparator="."
                        displayType="input"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}

                        value={this.state.refundAmount}
                        placeholder= {"Max amount $" + this.props.global.numberWithCommas(this.state.refundAmountMax.toFixed(2))}
                        className={this.state.refundAmountError ? "form-control input-money-v2 mb-3 input-error" : "form-control input-money-v2 mb-3" }
                        onValueChange={(values) => this.handleRefundAmount(values.value)}
                    />

                    <Button className="btn full-w" variant="primary" onClick={this.refundTransactionAction}>
                        Refund
                    </Button>
                </Modal.Body>
                </Modal>

                <Modal style={{textAlign: "center"}} show={!this.state.sendReceiptModalIsOpen} onHide={this.closeSendReceiptModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiMailSend className="icon-modal"/>
                    <h5>Send Receipt</h5>
                    <p className="small">Transaction Id:</p>
                    <p className="small-grey">{this.state.transIdToSendReceipt}</p>

                    <IMaskInput
                        mask={this.props.global.maskValidator('email')}
                        value={this.state.sendReceiptAddress}
                        unmask={true}
                        placeholder= "Email"
                        className={this.state.sendReceiptError ? "form-control mb-3 input-error" : "form-control mb-3" }                        
                        onAccept={
                            (value, mask) => this.handleSendReceiptAddress(value)
                        }
                    />
                    <div className="row">
                        <div className="col">
                            <Button className="btn full-w  cancel-btn" variant="default" onClick={this.closeSendReceiptModal}>
                                Cancel
                            </Button>
                        </div>
                        <div className="col">
                            <Button className="btn full-w" variant="primary" onClick={this.sendReceiptTransactionAction}>
                                Send
                            </Button>
                        </div>
                    </div>
                   
                </Modal.Body>
                </Modal>

                <RightPanel>
                    <h5 className="header mb-3">Transaction Details</h5>
               
                    <div className="small mb-5">
                    <div className="row">
                            <div className="col-4">
                                <label className="header">Date & Time {"("+this.props.global.getTimeZone('v1')+")"}</label>
                            </div>
                            <div className="col-8">
                                {recordDetail ? this.props.global.stringDateFormat(recordDetail.TransactionTime) : '-'}
                                -
                                {recordDetail ? this.props.global.stringTimeFormat(recordDetail.TransactionTime) : '-'}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="header">Type</label>
                            </div>
                            <div className="col-8">
                                {recordDetail ? recordDetail.Operation : '-'}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="header">Status</label>
                            </div>
                            <div className="col-8">
                                {this.props.global.getTransStatusText(recordDetail?.TransStatus)}
                            </div>
                            <div className="col-4">
                                <label className="header">Batch Status</label>
                            </div>
                            <div className="col-8">
                                {(recordDetail && recordDetail.TransStatus !== -1 && recordDetail.TransStatus !== 2 && recordDetail.TransStatus !== 3) ? 
                                (recordDetail && recordDetail.SettlementStatus !== null) ?
                                    <>
                                    {
                                        recordDetail.SettlementStatus < 0 ?
                                            <a href={"/report/chargebacks/"+recordDetail.PaymentTransId} >{this.props.global.getSettlementTransStatus(recordDetail.SettlementStatus, true)}</a>
                                            :
                                            this.props.global.getSettlementTransStatus(recordDetail.SettlementStatus, true)
                                    }
                                    </>
                                    : 
                                    <span className="badge bg-light"><CgUnavailable /> N/A</span>
                                :
                                <span className="badge bg-light"><CgUnavailable /> N/A</span>
                                }       
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="header">Response</label>
                            </div>
                            <div className="col-8">
                                {(recordDetail && recordDetail.ResponseData) ? this.props.global.capitalizeFirstLetter(recordDetail.ResponseData.responsetext): '-'}
                                {(recordDetail && recordDetail.ResponseData) ? this.props.global.getIconTransactionResponse(recordDetail.ResponseData.response): ''}
                            </div>
                        </div>
                        
                        <div className="row mb-4">
                            <div className="col-4">
                               
                            </div>
                            <div className="col-8">
                                {(recordDetail && recordDetail.ResponseData) ? this.props.global.capitalizeFirstLetter(recordDetail.ResponseData.response_code_text): ''}
                            </div>
                        </div>

                        <div className="row mb-4">
						{(recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.orderDescription) &&
                            <div className="col-12">
                            <label className="header">Notes:</label>
                            <p>
                                {recordDetail.PaymentData.orderDescription}
                            </p>
                            </div>
                        }
                            {(recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.paymentDetails && recordDetail.PaymentData.paymentDetails.categories) &&
                            <div className="col-12">
                                {recordDetail.PaymentData.paymentDetails.categories.map((record, i) => (
                                    <div className="row mb-1" key={i}>
                                        <div className="col-4">{record.label}</div>
                                        <div className="col-3">${this.props.global.numberWithCommas((record.amount).toFixed(2))}</div>
                                        <div className="col-2">x {record.qty ? record.qty : 1}</div>
                                        <div className="col-3 text-right">${this.props.global.numberWithCommas(((record.qty ? record.qty : 1) * record.amount).toFixed(2))}</div>
                                    </div>
                                ))
                                }
                            </div>
                            }

                            <div className="col-12">
                                <div className="row mb-1">
                                    <div className="col-4">
                                        <b>Amount</b>
                                    </div>
                                    <div className="col-8 text-right">
                                        {(recordDetail && recordDetail.NetAmount) ? this.props.global.numberWithCommasNegative(recordDetail.NetAmount.toFixed(2)) : '-'}
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-4">
                                        <b>Fee</b>
                                    </div>
                                    <div className="col-8 text-right">
                                        {(recordDetail && recordDetail.FeeAmount) ? this.props.global.numberWithCommasNegative(recordDetail.FeeAmount.toFixed(2)) : '$0.00'}
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-4">
                                        <b>Total</b>
                                    </div>
                                    <div className="col-8 text-right">
                                    <b>{(recordDetail && recordDetail.TotalAmount) ? this.props.global.numberWithCommasNegative(recordDetail.TotalAmount.toFixed(2)) : '$0.00'}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 className="header mb-3">Payment Information</h5>
                        {(recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.MaskedAccount && recordDetail.PaymentData.MaskedAccount.toLowerCase() === "poi") ?
                            <div className="card-v2 mb-3" style={{padding: "3em"}}>
                                <div className="row">
                                <div className="col-7">
                                    <label className="grey">Source</label>
                                    <h6 style={{fontWeight: 500}}>
                                        POI Device
                                    </h6>
                                </div>
                                <div className="col-5 text-right">
                                    {this.props.global.getGlobalImg('pointofsale', '30px')}
                                </div>
                                </div>
                            </div>
                        :                  
                        <div className="card-v2 mb-3" style={{padding: "3em"}}>
                            <div className="right-panel-card mb-4">
                                {this.props.global.getGlobalImg((recordDetail?.PaymentData?.AccountType && recordDetail?.PaymentData?.AccountType?.toLowerCase() !== 'unknow' ? recordDetail?.PaymentData?.AccountType?.toLowerCase() : recordDetail?.Method), '45px')}
                            </div>                       
                            <div className="row mb-2">
                                <div className="col-7">
                                    <label className="grey">{recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ? "Card Number" : "Account Number" }</label>
                                    <h5 style={{fontWeight: 500}}>
                                    •••• •••• {(recordDetail && recordDetail.PaymentData) ?  this.props.global.maskedCardNumber(recordDetail.PaymentData.MaskedAccount, 'v5') : '-'}
                                    </h5>
                                </div>
                                {recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ?
                                <div className="col-5 text-center">
                                    <label className="grey">Expires on</label>
                                    <h5 style={{fontWeight: 500}}>
                                        {recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.AccountExp ? this.props.global.expDateFormat(recordDetail.PaymentData.AccountExp) : '-'}
                                    </h5>
                                </div>
                                :
                                <div className="col-5 text-center">
                                    <label className="grey">Account Type</label>
                                    <h5 style={{fontWeight: 500}}>
                                        {recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.AccountType ? recordDetail.PaymentData.AccountType  : '-'}
                                    </h5>
                                </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="grey">{recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ? "Cardholder Name" : "Account Holder Name" } </label>
                                    <h6 style={{fontWeight: 500}}>
                                        {(recordDetail && recordDetail.PaymentData )?  recordDetail.PaymentData.HolderName : '-'}
                                    </h6>
                                </div>
                            </div>
							
                            {/*<hr className="hr-m1" />
                            <div className="blue-text">0 active scheduled payments</div>*/}
                        </div>
                     
                        }
                        <div className="text-center mb-4">
                        <div className="btn-group" role="group">
                        {(recordDetail && (recordDetail.SettlementStatus === 0 && (recordDetail.TransStatus === 1 || recordDetail.TransStatus === 11) )) &&
                        <button onClick={() => this.voidTransaction(recordDetail.PaymentTransId) } type="button" className="btn btn-outline-primary"><BiXCircle/> Void</button>
                        }
                        {(recordDetail && recordDetail.SettlementStatus === 1) &&
                        <button onClick={() => this.refundTransaction(recordDetail.PaymentTransId, recordDetail.NetAmount)} type="button" className="btn btn-outline-primary"><BiShare/> Refund</button>
                        }
                        <button onClick={() => this.sendTransactionReceipt(recordDetail.PaymentTransId, recordDetail.Customer.BillingEmail)} type="button" className="btn btn-outline-primary"><BiMailSend/> Send Receipt</button>
                        </div>
                        </div>


                        <h5 className="header mb-3">Customer Information</h5>
                        <div className="row mb-4">
                            <div className="col-3">
                                <label className="header">Customer</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.Customer )? (recordDetail.Customer.FirstName?recordDetail.Customer.FirstName:"") + ' ' + (recordDetail.Customer.LastName?recordDetail.Customer.LastName:"") : '-'}
                            </div>
                        </div>

                        <h5 className="header mb-3">Processing Information</h5>
                        <div className="row mb-4">
                            <div className="col-3">
                                <label className="header">Transaction #</label>
                            </div>
                            <div className="col-9">
                            {(recordDetail && recordDetail.ResponseData ) ? recordDetail.ResponseData.transactionid : '-'}
                            </div>

                            <div className="col-3">
                                <label className="header">Payment ID</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.paymentId !== null) ?  recordDetail.PaymentTransId : '-'}
                            </div>

                            <div className="col-3">
                                <label className="header">CVV Response</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.cvvresponse_text) ?  recordDetail.ResponseData.cvvresponse_text : '-'}
                            </div>

                            <div className="col-3">
                                <label className="header">AVS Response</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.avsresponse_text) ?  recordDetail.ResponseData.avsresponse_text : '-'}
                            </div>
                            <div className="col-3">
                                <label className="header">Auth Code</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.authcode) ?  recordDetail.ResponseData.authcode : '-'}
                            </div>
                            



                            <div className="col-3">
                                <label className="header">Source</label>
                            </div>
                            <div className="col-9">
                            {(recordDetail && recordDetail.Source ) ? recordDetail.Source : '-'}
                            </div>
                        </div>

                        {(recordDetail && recordDetail.TransactionEvents && recordDetail.TransactionEvents.length > 0) &&
                        <>
                        <h5 className="header mb-3">Transaction Event History</h5>
                        <div className='timeLineRecordContainer'>
                        {recordDetail.TransactionEvents.map((record, i) => (
                            <>
                            <div className='timeLineRecordItem'>
                                <div className="timeLineRecord">&nbsp;</div>
                                <div className="timeLineRecordText"><b>{record.TransEvent}</b><br/>{this.props.global.stringDateFormat(record.EventTime)} <span className="grey">{this.props.global.stringTimeFormat(record.EventTime)} {"("+this.props.global.getTimeZone('v1')+")"}</span></div>
                                <br/>
                            </div>
                            </>
                        ))}
                        </div>
                        </>
                        }

                    </div>
                
                
                </RightPanel>

                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <OrganizationOverviewLinks goback={true} gobackLink={'/'+this.props.global.getURLEntry() + '/orgdetails/'+this.state.idOrg} gobackText="Org. Overview" idOrg={this.state.idOrg} selected="transactions"/>
                </div>
                </TopBar>
                

                <div className="mt-body4">
                    <MainBar
                        reportName="OrgTransactions"
                        reportTitle={this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : "All Transactions"}
                        dataQAName="OrgTransactionsReports"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        rootTemplate={this.state.rootTemplate}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        searchBar={false}
                        exportFile={this.exportFile}
                        masterName={this.props.match.params.idOrg && `Organization - ${this.state.currentOrgName}`}
                        withTotal={true}
                        totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    /> 
                
                {this.state.filtersEnabled &&
                <ReportFilters report="transactions" clearFilters = {this.clearFilters}/>
                }
                <div className="report-container">  
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            ( record[1].display &&
                            (this.state.filtersEnabled ?
                                <th key={i} scope="col" className={record[1].class}>
                                { record[1].filter ?
                                    <>
                                    { record[1].custom === true &&
                                        <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                        >
                                            <Dropdown.ItemText className="filter-form">
                                                <label className="header">Filter by {record[1].label}</label>
                                                <input placeholder={record[1].label} onKeyDown={(e) => this.handleKeyDown('additional-'+record[1].label+'(ct)',e)} type="text" className="form-control search-enter"/>
                                            </Dropdown.ItemText>
                                        </DropdownButton>
                                    }

                                    {record[1].filter === 'gatewayTransId' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Transaction ID</label>
                                            <input placeholder="Transaction ID" onKeyDown={(e) => this.handleKeyDown('gatewayTransId(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    { record[1].filter === 'brand' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        id="brandFilters"
                                    >
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterBrands && this.state.filterBrands.visa ? true : false } value="visa" id="visa" type="checkbox" onChange={(e) => this.handleBrand(e)}/> Visa</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterBrands && this.state.filterBrands.mastercard ? true : false } value="mastercard" id="mastercard" type="checkbox" onChange={(e) => this.handleBrand(e)}/> MasterCard</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterBrands && this.state.filterBrands.discover ? true : false } value="discover" id="discover" type="checkbox" onChange={(e) => this.handleBrand(e)}/> Discover</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterBrands && this.state.filterBrands.amex ? true : false } value="amex" id="amex" type="checkbox" onChange={(e) => this.handleBrand(e)}/> Amex</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterBrands && this.state.filterBrands.checking ? true : false } value="checking" id="checking" type="checkbox" onChange={(e) => this.handleBrand(e)}/> Checking</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => this.selectAllFilters('brandFilters')}>Select All</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={(e) => this.clearFilter(e, "payaccountType(in)", "filterBrands") }>Clear All</Dropdown.Item>
                                    </DropdownButton>
                                    }

                                    { record[1].filter === 'type' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        id="typeFilters"
                                    >
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterTypes && this.state.filterTypes.sale ? true : false }  value="sale" id="sale" type="checkbox" onChange={(e) => this.handleType(e)}/> sale</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterTypes && this.state.filterTypes.refund ? true : false }  value="refund" id="refund" type="checkbox" onChange={(e) => this.handleType(e)}/> Refund</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => this.selectAllFilters('typeFilters')}>Select All</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={(e) => this.clearFilter(e, "operation(in)", "filterTypes")}>Clear All</Dropdown.Item>
                                    </DropdownButton>
                                    }

                                    { record[1].filter === 'status' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        id="statusFilters"
                                    >
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterStatus && this.state.filterStatus[1] ? true : false } value="1" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Success</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterStatus && this.state.filterStatus[15] ? true : false } value="15" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Refunded</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterStatus && this.state.filterStatus[5] ? true : false } value="5" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Voided</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterStatus && this.state.filterStatus[12] ? true : false } value="12" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Returned</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterStatus && this.state.filterStatus[11] ? true : false } value="11" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Uncaptured</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterStatus && this.state.filterStatus[3] ? true : false } value="3" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Failed</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterStatus && this.state.filterStatus[2] ? true : false } value="2" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Declined</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterStatus && this.state.filterStatus[-1] ? true : false } value="-1" type="checkbox" onChange={(e) => this.handleStatus(e)}/> Error</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => this.selectAllFilters('statusFilters')}>Select All</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={(e) => this.clearFilter(e, "status(in)", "filterStatus")}>Clear All</Dropdown.Item>
                                    </DropdownButton>
                                    }

                                    { record[1].filter === 'settlementStatus' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="filterPanelV2"
                                        id="settlementStatusFilters"
                                    >
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterSettlementStatus && this.state.filterSettlementStatus[-2] ? true : false } value="-2" type="checkbox" onChange={(e) => this.handleSettlementStatus(e)}/> Returned/Chargeback</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterSettlementStatus && this.state.filterSettlementStatus[-1] ? true : false } value="-1" type="checkbox" onChange={(e) => this.handleSettlementStatus(e)}/> Exception</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterSettlementStatus && this.state.filterSettlementStatus[0] ? true : false } value="0" type="checkbox" onChange={(e) => this.handleSettlementStatus(e)}/> Pending Settlement</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterSettlementStatus && this.state.filterSettlementStatus[1] ? true : false } value="1" type="checkbox" onChange={(e) => this.handleSettlementStatus(e)}/> In Transit</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText>
                                            <label><input checked={ this.state.filterSettlementStatus && this.state.filterSettlementStatus[2] ? true : false } value="2" type="checkbox" onChange={(e) => this.handleSettlementStatus(e)}/> Funded</label>
                                        </Dropdown.ItemText>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => this.selectAllFilters('settlementStatusFilters') }>Select All</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={(e) => this.clearFilter(e, "settlementStatus(in)", "filterSettlementStatus") }>Clear All</Dropdown.Item>
                                    </DropdownButton>
                                    }
                                
                                    {record[1].filter === 'paymentId' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Payment ID</label>
                                            <input placeholder="Payment ID" onKeyDown={(e) => this.handleKeyDown('transId(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'company' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Company</label>
                                            <input placeholder="Company" onKeyDown={(e) => this.handleKeyDown('customerCompanyname(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'PaypointLegalname' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by PayPoint</label>
                                            <input placeholder="PayPoint" onKeyDown={(e) => this.handleKeyDown('paypointLegal(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'date' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form-datepicker">
                                        <DatePicker
                                            selected={ this.state.dateStartDate ? this.state.dateStartDate : new Date()}
                                            onChange={date => this.handleChangeCalendar(date)}
                                            startDate={this.state.dateStartDate}
                                            endDate={this.state.dateEndtDate}
                                            selectsRange
                                            inline
                                        />
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'customerEmail' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Email</label>
                                            <input placeholder="Email" onKeyDown={(e) => this.handleKeyDown('customerEmail(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'customer' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Customer</label>
                                            <input placeholder="Customer" onKeyDown={(e) => this.handleKeyDown('customerName(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'customerNumber' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Customer #</label>
                                            <input placeholder="Customer #" onKeyDown={(e) => this.handleKeyDown('customerNumber(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'payaccountLastfour' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Last 4</label>
                                            <IMaskInput
                                                mask={'0000'}
                                                unmask={true}
                                                onKeyDown={(e) => this.handleKeyDown('payaccountLastfour(ct)',e)}                                                
                                                placeholder="Last 4"
                                                className="form-control search-enter"
                                            />
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }
                                    

                                    {record[1].filter === 'totalAmount' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Amount</label>
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                decimalSeparator="."
                                                displayType="input"
                                                type="text"
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                placeholder="Total Amount"
                                                className="form-control search-enter"
                                                onKeyDown={(e) => this.handleKeyDown('netAmount(eq)',e)}
                                            />
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }


                                    </>
                                    :
                                    (record[1].label)
                                }
                                </th>
                              : <th key={i} scope="col" className={record[1].class}>{record[1].label}</th> )
                            )
 
                        ))
                    }
                    <th scope="col" className="text-right sticky-row">Actions</th>
                    </tr>
                </thead>
                <tbody>
                
                { this.props.reports.records.map((record, i) => (
                        <tr key={i} className="cursorPointer" onClick={(e) => this.viewRecordDetails(i,e)} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} >
                             { (this.props.reports.headers.paymentId && this.props.reports.headers.paymentId.display) &&
                            <td>
                                {record.PaymentTransId ? record.PaymentTransId.substring(0, 10) : ''}...
                            </td>
                             }

                            { (this.props.reports.headers.ParentOrgName && this.props.reports.headers.ParentOrgName.display) &&
                            <td>{record && record.ParentOrgName ? record.ParentOrgName: '-'}</td>
                            }
                            
                            { (this.props.reports.headers.PaypointLegalname && this.props.reports.headers.PaypointLegalname.display) &&
                            <td>{record && record.PaypointLegalname ? record.PaypointLegalname: '-'}</td>
                            }

                            { (this.props.reports.headers.gatewayTransId && this.props.reports.headers.gatewayTransId.display) &&
                            <td>{record && record.GatewayTransId ? record.GatewayTransId: '-'}</td>
                            }

                            { (this.props.reports.headers.company && this.props.reports.headers.company.display) &&
                            <td>{record.Customer && record.Customer.CompanyName ? record.Customer.CompanyName: '-'}</td>
                            }

                            { (this.props.reports.headers.customer && this.props.reports.headers.customer.display) &&
                            <td>{record.Customer && record.Customer.FirstName ? record.Customer.FirstName: '-'} { record.Customer && record.Customer.LastName ? record.Customer.LastName : ''}</td>
                            }
                            
                            
                            { (this.props.reports.headers.customerNumber && this.props.reports.headers.customerNumber.display) &&
                            <td>{record.Customer && record.Customer.CustomerNumber ? record.Customer.CustomerNumber.length > 15 ? record.Customer.CustomerNumber.substring(0, 15) + "..." : record.Customer.CustomerNumber : '-'} </td>
                            }

                            { (this.props.reports.headers.billingEmail && this.props.reports.headers.billingEmail.display) &&
                            <td>{record.Customer && record.Customer.BillingEmail ? record.Customer.BillingEmail : '-'} </td>
                            }
                            
                            { (this.props.reports.headers.payorPhone && this.props.reports.headers.payorPhone.display) &&
                            <td>{record.Customer && record.Customer.BillingPhone ? this.props.global.phoneNumberFormat(record.Customer.BillingPhone): '-'} </td>
                            }
                           
                            { (this.props.reports.headers.billingAddress && this.props.reports.headers.billingAddress.display) &&
                            <td>{record.Customer && record.Customer.BillingAddress1 ? record.Customer.BillingAddress1 +" "+ record.Customer.BillingAddress2+" "+ record.Customer.BillingCity+" "+ record.Customer.BillingState+" "+ record.Customer.BillingZip+" "+ record.Customer.BillingCountry: '-'} </td>
                            }
                            
                            { (this.props.reports.headers.shippingAddress && this.props.reports.headers.shippingAddress.display) &&
                            <td>{record.Customer && record.Customer.ShippingAddress1 ? record.Customer.ShippingAddress1 +" "+ record.Customer.ShippingAddress2+" "+ record.Customer.ShippingCity+" "+ record.Customer.ShippingState+" "+ record.Customer.ShippingZip+" "+ record.Customer.ShippingCountry: '-'} </td>
                            }

                            { (this.props.reports.headers.invoiceNumber && this.props.reports.headers.invoiceNumber.display) &&
                            <td>{record.invoiceData && record.invoiceData.invoiceNumber ? record.invoiceData.invoiceNumber: '-'}</td>
                            }
                            
                            { (this.props.reports.headers.date && this.props.reports.headers.date.display) &&
                            <td>{this.props.global.stringDateFormatV3(record.TransactionTime)}</td>
                            }

                            { (this.props.reports.headers.time && this.props.reports.headers.time.display) && 
                            <td>{this.props.global.stringTimeFormat(record.TransactionTime)}</td>
                            }   
                            
                            { (this.props.reports.headers.brand && this.props.reports.headers.brand.display) && 
                            <td className="text-center">
                                {this.props.global.getPaymethodImgPaymentData(record)}
                            </td>
                            }

                            { (this.props.reports.headers.last4 && this.props.reports.headers.last4.display) && 
                            <td>{ (record && record.PaymentData && record.PaymentData.MaskedAccount )? this.props.global.maskedCardNumber(record.PaymentData.MaskedAccount, "v5") : '-'}</td>
                            }

                            { (this.props.reports.headers.type && this.props.reports.headers.type.display) && 
                            <td>{record.Operation}</td>
                            }

                            { (this.props.reports.headers.status && this.props.reports.headers.status.display) && 
                            <td>{this.props.global.getTransStatus(record.TransStatus)}</td>
                            }

                            { (this.props.reports.headers.settlementStatus && this.props.reports.headers.settlementStatus.display) && 
                            <td>{ this.props.global.getSettlementTransStatus(record.SettlementStatus) }</td>
                            }       

                            { (this.props.reports.headers.netAmount && this.props.reports.headers.netAmount.display) && 
                            <td className="text-center">{this.props.global.numberWithCommasNegative(record.NetAmount.toFixed(2))}</td>
                            }

                            { (this.props.reports.headers.feeAmount && this.props.reports.headers.feeAmount.display) && 
                            <td className="text-center">${this.props.global.numberWithCommas(record.FeeAmount.toFixed(2))}</td>
                            }
                          
                            { (this.props.reports.headers.totalAmount && this.props.reports.headers.totalAmount.display) && 
                            <td className="text-center">${this.props.global.numberWithCommas(record.TotalAmount.toFixed(2))}</td>
                            }
                            
                            { (this.props.reports.headers.authCode && this.props.reports.headers.authCode.display) && 
                            <td className="text-center">{record.ResponseData.authcode ? record.ResponseData.authcode : '-'}</td>
                            }
                            
                            { (this.props.reports.headers.source && this.props.reports.headers.source.display) && 
                            <td>{record.Source ? this.props.global.capitalizeFirstLetter(record.Source) : '-'}</td>
                            }

                            { (this.props.reports.headers.notes && this.props.reports.headers.notes.display) && 
                                <td className="text-center">
                                    {record.PaymentData && record.PaymentData.orderDescription ? 
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{record.PaymentData.orderDescription}</Tooltip>}
                                        >
                                        <BiNotepad className="label-info-icon in-input"/>
                                        </OverlayTrigger>
                                    : '-'}
                                </td>
                            }

                            {
                                 this.state.customFields.map((field, i) => (
                                    (this.props.reports.headers[field.key] && this.props.reports.headers[field.key].display) && 
                                     <td key={i}>{record.Customer && record.Customer.AdditionalData && record.Customer.AdditionalData[field.key] ? record.Customer.AdditionalData[field.key] : '-'}</td>
                                 ))
                            }
                            

                            <td className="text-center sticky-row">
                                <DropdownButton
                                    menuAlign="center"
                                    title={<BiDotsVerticalRounded/>}
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                >
                                <Dropdown.Item onClick={(e) => this.viewRecordDetails(i)}><div>Quick View</div></Dropdown.Item>
                                <Dropdown.Item onClick={() => this.sendTransactionReceipt(record.PaymentTransId, record.Customer?record.Customer.BillingEmail:'')}>Send Receipt</Dropdown.Item>
                                {(record.PayorId > 0) &&
                                    <>
                                    <Dropdown.Item as={Link} to={ record.Customer ? '/'+this.props.global.getURLEntry() +"/customer/"+ record.Customer.customerId : ''}>View Customer</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => this.chargeAgain(record.Customer, record.PaymentData.paymentDetails.categories, 0, record)}>Charge Again</Dropdown.Item>
                                    </>
                                }
                                {record.TransStatus === 11 &&
                                <Dropdown.Item onClick={(e) => this.chargeAgain(record.Customer ,record.PaymentData.paymentDetails.categories, 0, record, true)}>Capture</Dropdown.Item>
                                }
                                {(record && record.SettlementStatus >= 1) &&
                                <Dropdown.Item onClick={() => this.refundTransaction(record.PaymentTransId, record.NetAmount) }>Refund</Dropdown.Item>
                                }
                                {(record && (record.SettlementStatus === 0 && (record.TransStatus === 1 || record.TransStatus === 11) )) &&
                                <Dropdown.Item onClick={() => this.voidTransaction(record.PaymentTransId) }>Void</Dropdown.Item>
                                }
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }
                
                </tbody>
                </table>
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No transactions yet" description={<span>When your customers make payments in real time,<br/> you can track transaction details here.</span>}/>
                }
                
                </div>
                <ReportPaginator report="transactions" mode={this.state.idOrg} />

                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
       
    }
}

export { OrgTransactionsReport };