import React from 'react'
import RowContainer from '../../RowContainer'
import GlobalStore from '../../../../store/GlobalStore'

export default function Repay({ inputDisabled, service, serviceName, indexService, indexGateways, handleChange, setClassValidate, classValidate }) {
	return (
		<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
			<div className="d-flex flex-column align-items-start p-0">
				<RowContainer
					inputType="mask"
					classNameInput={classValidate.repay.clientId}
					inputDisabled={inputDisabled}
					labelValue="Client ID"
					name={'clientId[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.clientId}
					onBlur={() => setClassValidate({ ...classValidate, repay: { ...classValidate.repay, clientId: service.Gateways[indexGateways].Connector.configuration.credentials.clientId ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'clientId', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>

				<RowContainer
					inputType="mask"
					classNameInput={classValidate.repay.clientSecretKey}
					inputDisabled={inputDisabled}
					labelValue="Client Secret Key"
					name={'clientSecretKey[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.clientSecretKey}
					onBlur={() => setClassValidate({ ...classValidate, repay: { ...classValidate.repay, clientSecretKey: service.Gateways[indexGateways].Connector.configuration.credentials.clientSecretKey ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'clientSecretKey', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>
			</div>
			<div className="d-flex flex-column align-items-start p-0">
				<RowContainer
					inputType="mask"
					classNameInput={classValidate.repay.customerId}
					inputDisabled={inputDisabled}
					labelValue="Customer ID"
					name={'customerId[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.customerId}
					onBlur={() => setClassValidate({ ...classValidate, repay: { ...classValidate.repay, customerId: service.Gateways[indexGateways].Connector.configuration.credentials.customerId ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'customerId', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>

				<RowContainer
					inputType="mask"
					classNameInput={classValidate.repay.accountId}
					inputDisabled={inputDisabled}
					labelValue="Account ID"
					name={'accountIdCredentials[' + service.Gateways[indexGateways].Id + ']'}
					placeholder="Value"
					value={service.Gateways[indexGateways].Connector.configuration.credentials.accountId}
					onBlur={() => setClassValidate({ ...classValidate, repay: { ...classValidate.repay, accountId: service.Gateways[indexGateways].Connector.configuration.credentials.accountId ? '' : 'is-invalid' } })}
					setValue={(values) => handleChange(service.ServiceGroup, serviceName, indexService, service.Gateways[indexGateways].Id, 'accountId', values, false, false, 0, false, '', 0, false, true)}
					mask={GlobalStore.maskValidator('text')}
					maxLength={50}
				/>
			</div>
		</div>
	)
}
