import React from 'react';
import { inject, observer } from 'mobx-react';
import { PayabliStorageManager } from '../api/localStorageManager';
import ReactPaginate from 'react-paginate';
import {BiChevronLeft} from '@react-icons/all-files/bi/BiChevronLeft';
import {BiChevronRight} from '@react-icons/all-files/bi/BiChevronRight';
import { ToastContainer, toast , Bounce} from 'react-toastify';

@inject('developers', 'global')
@observer
class ReportPaginatorApiKeys extends React.Component {

    constructor(props) {
        super(props);
        this.goToPage = this.goToPage.bind(this);
        this.showByNumberRows = this.showByNumberRows.bind(this);
    }

    async goToPage(data){
        let selected = data.selected;
        this.props.developers.setFrom(selected);
        
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry;
        this.props.global.setLoading(true);
        try{
                this.props.developers.getAPIKeysFromApi(entry, 2).then(res => {
                    this.props.global.setLoading(false);
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data ? error.response.data : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
        }catch(error){
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
    }

    async showByNumberRows(e, number){
        if (e) {
            e.preventDefault();            
        }

        let selected = Number(number)
        this.props.developers.setCount(selected);
        
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry;
        this.props.global.setLoading(true);
        try{
            this.props.developers.getAPIKeysFromApi(entry, 2).then(res => {
                this.props.global.setLoading(false);
            })
            .catch(error => {});
        }catch(error){}
    }

    render() {
        return (
            <>
                <div className='report-paginator row'>
                    <div className='col pagination-section-1'>
                        {( this.props.developers.totalPages > 1 ) ?
                            (
                                <ul className="pagination justify-content-left pagination-rows-page">
                                    <p className='mt-1 mr-2'>Rows per page</p>
                                    <li className={this.props.developers.count === 20 ? 'page-item active' : 'page-item'}><a href='/' role="button" className="page-link" onClick={(e) => this.showByNumberRows(e, 20)}>20</a></li>
                                    <li className={this.props.developers.count === 50 ? 'page-item active' : 'page-item'}><a href='/' role="button" className="page-link" onClick={(e) => this.showByNumberRows(e, 50)} data-qaid="rowsPerPage50">50</a></li>
                                    <li className={this.props.developers.count === 100 ? 'page-item active' : 'page-item'}><a href='/' role="button" className="page-link" onClick={(e) => this.showByNumberRows(e, 100)} data-qaid="rowsPerPage100">100</a></li>
                                </ul>
                            ) :
                            (
                                <ul className="pagination justify-content-left pagination-rows-page" style={{opacity: '0.4'}}>
                                    <p className='mt-1 mr-2'>Rows per page</p>
                                    <li className={'page-item'}><a href='/' role="button" className="btn page-link disabled" onClick={(e) => {e && e.preventDefault()}} aria-disabled="true">20</a></li>
                                    <li className={'page-item'}><a href='/' role="button" className="btn page-link disabled" onClick={(e) => {e && e.preventDefault()}} aria-disabled="true">50</a></li>
                                    <li className={'page-item'}><a href='/' role="button" className="btn page-link disabled" onClick={(e) => {e && e.preventDefault()}} aria-disabled="true">100</a></li>
                                </ul>
                            )
                        }
                    </div>
                    <div className='col pagination-section-2'>
                        <nav aria-label="Page navigation">
                            <ReactPaginate
                                previousLabel={<BiChevronLeft/>}
                                nextLabel={<BiChevronRight/>}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.props.developers.totalPages || 1}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={8}
                                onPageChange={this.goToPage}
                                containerClassName={'pagination justify-content-center pagination-rows-page'}
                                activeClassName={'active'}
                                pageClassName={'page-item'}
                                nextClassName={'page-item'}
                                previousClassName={'page-item'}
                                previousLinkClassName= {'page-link'}
                                nextLinkClassName= {'page-link'}
                                pageLinkClassName={'page-link'}
                            />
                        </nav>
                    </div>
                    <div className='col pagination-section-3'>
                        {( this.props.developers.records.length > 0 ) &&
                            (
                                <p className='float-end mt-1'>Viewing <strong>{this.props.developers.from + 1}-{(this.props.developers.count + this.props.developers.from) > this.props.developers.totalRecords ? this.props.developers.totalRecords : (this.props.developers.count + this.props.developers.from)}</strong> of <strong>{this.props.developers.totalRecords}</strong> results</p>
                            )
                        }
                    </div>

                <ToastContainer transition={Bounce} />
                </div>
            </>
        );
    }
}

export { ReportPaginatorApiKeys };