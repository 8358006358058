import React from 'react';
import { inject, observer } from 'mobx-react';
import ReactPaginate from 'react-paginate';
import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft';
import { BiChevronRight } from '@react-icons/all-files/bi/BiChevronRight';

const CustomTableReportPaginator = inject('ctable')(
	observer((props) => {
		const goToPage = (selected) => {
			props.ctable.setFrom(selected.selected);
			fetchData();
		};

		const showByNumberRows = (number) => {
			props.ctable.setCount(number);
			props.ctable.setFrom(0);
			fetchData();
		};

		const fetchData = () => {
			props.ctable.getDataFromAPI();
		};

		const renderRowsPerPageButton = (number) => {
			const isActive = props.ctable.count === number;

			return (
				<li
					key={number}
					className={`page-item ${isActive ? 'active' : ''}`}>
					<a
						href='/'
						role='button'
						className='page-link'
						onClick={(e) => {
							e.preventDefault();
							showByNumberRows(number);
						}}>
						{number}
					</a>
				</li>
			);
		};

		const { totalPages, totalRecords, from, count, data } = props.ctable;

		return (
			<>
				<div className='report-paginator row'>
					<div className='col pagination-section-1'>
						{totalRecords > 20 ? (
							<ul className='pagination justify-content-left pagination-rows-page'>
								<p className='mt-1 mr-2'>Rows per page</p>
								{[20, 50, 100].map((number) => renderRowsPerPageButton(number))}
							</ul>
						) : (
							<ul
								className='pagination justify-content-left pagination-rows-page'
								style={{ opacity: '0.4' }}>
								<p className='mt-1 mr-2'>Rows per page</p>
								{[20, 50, 100].map((number) => (
									<li
										key={number}
										className={'page-item'}>
										<a
											href='/'
											role='button'
											className='btn page-link disabled'
											onClick={(e) => e.preventDefault()}
											aria-disabled='true'>
											{number}
										</a>
									</li>
								))}
							</ul>
						)}
					</div>
					<div className='col pagination-section-2'>
						<nav aria-label='Page navigation'>
							<ReactPaginate
								previousLabel={<BiChevronLeft />}
								nextLabel={<BiChevronRight />}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={totalPages}
								marginPagesDisplayed={1}
								pageRangeDisplayed={8}
								onPageChange={goToPage}
								containerClassName={'pagination justify-content-center pagination-rows-page'}
								activeClassName={'active'}
								pageClassName={'page-item'}
								nextClassName={'page-item'}
								previousClassName={'page-item'}
								previousLinkClassName={'page-link'}
								nextLinkClassName={'page-link'}
								pageLinkClassName={'page-link'}
								forcePage={from / count}
							/>
						</nav>
					</div>
					<div className='col pagination-section-3'>
						{data.length > 0 && (
							<p className='float-end mt-1'>
								Viewing{' '}
								<strong>
									{from + 1}-{Math.min(count + from, totalRecords)}
								</strong>{' '}
								of <strong>{totalRecords}</strong> results
							</p>
						)}
					</div>
				</div>
			</>
		);
	})
);

export { CustomTableReportPaginator };
