import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { CustomerLinks } from '../../components/CustomerLinks';
import { RightPanel } from '../../components/RightPanel';
import "react-datepicker/dist/react-datepicker.css";
import { CustomerDataChartColumn } from '../../components/CustomerDataChartColumn';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '../../components/Layout'
import { Link } from 'react-router-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { EditCustomer } from '../../components/EditCustomer';
import { VirtualTerminal } from '../../components/VirtualTerminal';

import {BiDollar} from '@react-icons/all-files/bi/BiDollar';
import {BiInfoCircle} from "@react-icons/all-files/bi/BiInfoCircle";
import {BiMoney} from '@react-icons/all-files/bi/BiMoney';
import {BiPieChart} from '@react-icons/all-files/bi/BiPieChart';
import {BiReceipt} from '@react-icons/all-files/bi/BiReceipt';
import {BiRepost} from '@react-icons/all-files/bi/BiRepost';
import {BiX} from '@react-icons/all-files/bi/BiX';
import {HiCheckCircle} from "@react-icons/all-files/hi/HiCheckCircle";

import { PayabliStorageManager } from '../../api/localStorageManager'

@inject('customer', 'global', 'vTerminal')
@observer
class ViewCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: "",
            customerId: null,
            vterminalRightPanelOpen: false
        };
        this.getCustomerFromApi = this.getCustomerFromApi.bind(this);
        this.addAutopay = this.addAutopay.bind(this);
        this.closeVterminalPanel = this.closeVterminalPanel.bind(this);
        this.renderSubHeaderLine = this.renderSubHeaderLine.bind(this);
    }

    componentDidMount() {
        this.props.global.setRightPanelOpen(false);
        let id = this.props.match.params.id;
        this.getCustomerFromApi(id);
        this.setState({ customerId: id });
    }

    closeVterminalPanel(){
        this.setState({ vterminalRightPanelOpen: false});
    }

    addAutopay(e, route){
        e.preventDefault();
        this.props.vTerminal.selectCustomerObject(this.props.customer.customer);
        this.props.history.push(route);
    }

    openVterminalPanel(customer, autopay){
        this.props.vTerminal.setPaymentMethod('');
        this.props.vTerminal.setDefaultPaymentMethodActiveKey('2');
        this.props.vTerminal.setPaymentMethodSavedPosition('2');
        this.props.vTerminal.setPaymentMethodSaved(null);

        if(autopay === 1){
            this.props.vTerminal.disableAutopay(true);
        }
        else{
            this.props.vTerminal.disableAutopay(false);
        }
        if(customer){
            this.props.vTerminal.selectCustomerObject(customer);
        }

        this.props.global.setLoading(true);
        this.props.vTerminal.setEntryPoint(customer.PaypointEntryname ? customer.PaypointEntryname : '');
        this.props.vTerminal.getPaypointCredentials(customer.PaypointEntryname ? customer.PaypointEntryname : '').then(res => {
            this.props.vTerminal.updateCustomerPaymentsOptions();
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });

        this.setState({ vterminalRightPanelOpen: true, vTerminalAutopay: autopay});
    }

    getCustomerFromApi(id){
        this.props.global.setLoading(true);
        this.props.customer.getCustomerFromApi(id).then(res => {
            this.props.global.setLoading(false);
            if(res.data.PaypointEntryname){
                this.props.customer.setEntryPoint(res.data.PaypointEntryname);
            }
            this.props.customer.getCustomerLastPayments(id, 5);
        })
        .catch(error => {
            this.props.global.setLoading(false);
        });
    }

    renderSubHeaderLine( entryName, linkTo, customerId, titleHeader, customer, numberVterminal ){
        const urlLink = `/${entryName}/${linkTo}/${customerId}`;
        let btnAction = '';
        if (!isNaN(numberVterminal)) {
            btnAction = <button className="btn bordered btn-sm float-end" onClick={(e) => this.openVterminalPanel(customer, numberVterminal)}>+</button>;
        }else{
            btnAction = <Link className="btn bordered btn-sm float-end" to={`${urlLink}/add`}>+</Link>;
        }
        return (
            <h6 className="sub-header-line mb-1 dark-grey">
                {titleHeader}
                {btnAction}
                <Link to={ urlLink } className="link-default float-end">See More</Link>
            </h6>
        );
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        const entryName = PayabliStorageManager.getEntryName();
        const customer = this.props.customer.customer;
        const customerId = this.state.customerId;
        return (
            <Layout {...this.props}>
            <div>
                <RightPanel>

                </RightPanel>

                <div id="vterminal-rigth-panel" className={ this.state.vterminalRightPanelOpen ? 'open' : ''}>
                    <div className="popover-body popover-body-2">
                        <BiX id="right-panel-close" onClick={(e) => this.closeVterminalPanel()}/>
                        {this.state.vTerminalAutopay ? 
                        <h5 className="header mb-3">Create Autopay</h5>
                        :
                        <h5 className="header mb-3">Create Payment</h5>
                        }
                        <VirtualTerminal paymentSuccessFunctionCallBack={this.closeVterminalPanel} autopay={this.state.vTerminalAutopay}/>
                    </div>
                </div>
                {this.state.vterminalRightPanelOpen  &&
                <div onClick={(e) => this.closeVterminalPanel()} id="right-panel-layer"></div>
                }

                <TopBar>
                    <MainTopBarMenu />
                    <div className="top-bar-sub">
                        <CustomerLinks gobackLink={'/'+this.props.global.getURLEntry() +'/report/customers'} gobackText="Customer list" history={this.props.history} goback={true} selected="overview" id={this.state.customerId} />
                    </div>
                </TopBar>


                <div className="mt-body4">
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <h5>{this.props.customer.customer ? this.props.global.capitalizeFirstLetterOfEachWord(this.props.customer.customer.Firstname) : ''} {this.props.customer.customer ? this.props.global.capitalizeFirstLetterOfEachWord(this.props.customer.customer.Lastname) : ''}  
                            </h5>
                            <p className="small-grey">{this.props.customer.customer ? this.props.customer.customer.Email: ''}</p>
                        </div>
                        <div className="col-md-6 col-lg-6 text-right actions-btn-cont">
                            
                            <DropdownButton
                                menuAlign="right"
                                title={<div className="btn bordered float-end text-normal">+ Actions</div>}
                                size="sm"
                                variant="default"
                            >
                            {userPermissions.indexOf("Accept Payments") !== -1 &&
                                <Dropdown.Item onClick={(e) => this.openVterminalPanel(this.props.customer.customer, 0)}>Charge Payment</Dropdown.Item>
                            }
                            {userPermissions.indexOf("Autopay") !== -1 &&
                                <Dropdown.Item onClick={(e) => this.openVterminalPanel(this.props.customer.customer, 1)}>Create Autopay</Dropdown.Item>
                            }
                            {(userPermissions.indexOf("Autopay") !== -1 || userPermissions.indexOf("Accept Payments") !== -1) &&
                                <Dropdown.Item as={Link} to={'/'+this.props.global.getURLEntry() +'/customer/paymethods/'+this.state.customerId + "/add"}>Add Payment Method</Dropdown.Item>
                            }
                            <Dropdown.Item as={EditCustomer} className={" "} style={{fontSize: "12px"}} customerToUpdate={this.props.customer.customer} action={"edit"}></Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>


                    <div className="row">
                       
                        <div className="col-lg-3 mb-5 full-1260 order-1260-2">
                        <CustomerDataChartColumn routeParams={this.props.match.params} customerObj={this.props.customer.customer}/>
                        </div>
                        <div className="col-lg-8 offset-lg-1 dashboard-items full-1260 order-1260-1 offset-1260-0">
                            <div className="row mb-1">
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle orange">
                                        <BiReceipt/>
                                    </div>
                                    <label>Total Invoices</label><br/>
                                    <b>$0.00</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle green">
                                        <BiDollar/>
                                    </div>
                                    <label>Payments YTD</label><br/>
                                    <b>${this.props.customer && this.props.customer.customerPaymentYDT ? this.props.global.numberWithCommas(this.props.customer.customerPaymentYDT.toFixed(2)) : "0.00"}</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle red">
                                        <BiPieChart/>
                                    </div>
                                    <label>Past Due Invoices</label><br/>
                                    <b>0</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    { this.props.customer.customer && this.props.customer.customer.customerStatus === 1 ?
                                    <>
                                        <div className="dashboard-color-circle greenoutline">
                                            <HiCheckCircle/>
                                        </div>
                                        <label>Status</label><br/>
                                        <b>Active</b>
                                    </>
                                    :
                                    <>
                                        <div className="dashboard-color-circle yellowoutline">
                                            <BiInfoCircle/>
                                        </div>
                                        <label>Status</label><br/>
                                        <b>{ this.props.customer.customerStatusText }</b>
                                    </>
                                    }
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle blue">
                                        <BiRepost/>
                                    </div>
                                    <label>Active Autopays</label><br/>
                                    <b>{ this.props.customer.customerAutopayCount } | ${this.props.global.numberWithCommas(this.props.customer.customerAutopayAmount.toFixed(2)) }</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle purple">
                                        <BiMoney/>
                                    </div>
                                    <label>Balance</label><br/>
                                    <b>${ this.props.customer.customer && this.props.customer.customer.Balance ? this.props.global.numberWithCommas(this.props.customer.customer.Balance.toFixed(2)) : "0.00"}</b>
                                </div>
                            </div>
                            { this.renderSubHeaderLine(entryName, 'report/transactions', customerId, 'Last Payments', customer, 0) }
                            { this.props.customer.customer && this.props.customer.customerLastPayments && this.props.customer.customerLastPayments.length > 0 ?
                                <div className="report-container height-1260-auto">
                                    <table className="table table-hover table-striped mb-5">
                                        <thead>
                                            <tr>
                                                <th >Date</th>
                                                <th >Status</th>
                                                <th className="text-center">Pay Method</th>
                                                <th className="text-center">Last 4</th>
                                                <th className="text-center">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.customer.customerLastPayments.map((record, i) => (
                                            <tr key={i}>
                                                <td>{this.props.global.stringDateFormat(record.TransactionTime)}</td>
                                                <td>{this.props.global.getTransStatus(record.TransStatus)}</td>
                                                <td className="text-center">{this.props.global.getPaymethodImg(record.PaymentData.AccountType)}</td>
                                                <td className="text-center">{this.props.global.maskedCardNumber(record.PaymentData.MaskedAccount, "v3")}</td>
                                                <td className="text-center"><b>${this.props.global.numberWithCommas(record.TotalAmount.toFixed(2))}</b></td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            :
                            <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Records not found</div> 
                            }
                            { this.renderSubHeaderLine(entryName, 'report/autopays', customerId, 'Autopay', customer, 1) }
                            {(this.props.customer.customer && this.props.customer.customer.Subscriptions  && this.props.customer.customer.Subscriptions.length) ?
                                <div className="report-container height-1260-auto">
                                    <table className="table table-hover table-striped mb-5">
                                        <thead>
                                            <tr>
                                                <th >Next Draft on</th>
                                                <th >Frequency</th>
                                                <th className="text-center">Amount</th>
                                                <th className="text-center">Type</th>
                                                <th className="text-center">Remaining</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                            this.props.customer.customer.Subscriptions.map((record, i) => (
                                            <tr key={i}>
                                                <td>{this.props.global.stringDateFormat(record.NextDate)}</td>
                                                <td>{this.props.global.frequencyText(record.Frequency)}</td>
                                                <td  className="text-center">${this.props.global.numberWithCommas(record.PaymentData.paymentDetails.totalAmount.toFixed(2))}</td>
                                                <td className="text-center">-</td>
                                                <td className="text-center">
                                                    {(record && record.UntilCancelled === true) ?  "Until Cancelled" : ((record && record.LeftCycles) ? record.LeftCycles : "")}
                                                </td>
                                            </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            :  <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Records not found</div>
                            }
                            { this.renderSubHeaderLine(entryName, 'customer/paymethods', customerId, 'Pay Methods', customer) }
                            { (this.props.customer.customer && this.props.customer.customer.StoredMethods && this.props.customer.customer.StoredMethods.length) ?
                                <div className="report-container height-1260-auto">
                                    <table className="table table-hover table-striped mb-4">
                                            <tbody>
                                            {
                                            this.props.customer.customer.StoredMethods.map((record, i) => (
                                            <tr key={i}>
                                                <td> {this.props.global.maskedCardNumber(record.MaskedAccount, "v3")} { /*i === 0 ? <span className="badge rounded-pill bg-secondary">Default</span>: '' */}</td>
                                                <td className="text-center">{this.props.global.getPaymethodImg(record.Descriptor)} </td>
                                                <td><span className="grey">Last Updated:</span> {this.props.global.stringDateFormat(record.LastUpdated)}</td>
                                                <td><span className="grey">Method:</span> {record.Method}</td>
                                            </tr>
                                            ))
                                            }
                                            </tbody>
                                    </table>
                                </div>
                                : <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Records not found</div> }
                                
                        </div>
                    </div>
                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { ViewCustomer };