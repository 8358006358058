import React from 'react'
import ToggleContainer from './ToggleContainer'

export default function ContainerFields({ handleChange, fieldData, field, TooltipToggle = '', className = '', checkOptions = { state: { visible: true, readOnly: true, required: true } }, children }) {
	return (
		<>
			<div className={className ? className : 'col-xl-5 col-md-12'}>{children}</div>
			<ToggleContainer
				TooltipToggle={TooltipToggle}
				handleChange={handleChange}
				fieldData={fieldData}
				field={field}
				checkOptions={checkOptions}
			/>
		</>
	)
}
