import React from 'react';

class Message extends React.Component {
    render() {
        return (
            <div className="alert alert-warning small">{ this.props.message }</div>
        );
    }
}

export { Message };