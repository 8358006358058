import React, { useState } from 'react'
import styles from '../../../../assets/css/styleCommandCenter.module.css'
import googlePay from '../../../../assets/images/google-pay.svg'
import applePay from '../../../../assets/images/apple-pay.svg'
import Collapse from 'react-bootstrap/Collapse'

export default function WalletView({ showSection }) {
	const [walletPayment, setWalletPayment] = useState({ googlePay: true, applePay: false })
	return (
		<Collapse in={showSection.walletView}>
			<div id="collapse-section">
				<div className="d-flex flex-column align-items-center justify-content-center">
					<label
						className={styles['label-form'] + ' my-5 fs-3 ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Comming Soon
					</label>
				</div>
				<div className="d-flex align-items-center justify-content-center flex-wrap gap-5 mb-5">
					<div className="d-flex align-items-center justify-content-center">
						<input
							name="walletPayment.googlePay"
							onChange={() => setWalletPayment({ googlePay: true, applePay: false })}
							checked={walletPayment.googlePay}
							type="radio"
							id="walletPayment.googlePay"
							style={{ height: '30px', width: '30px', marginRight: '10px' }}
						/>
						<label
							htmlFor="walletPayment.googlePay"
							style={{ fontSize: '15px' }}>
							<img
								alt=""
								className="googlePay"
								width={105}
								height={56}
								src={googlePay}
							/>
							&nbsp;&nbsp;&nbsp;Google Pay
						</label>
					</div>

					<div className="d-flex align-items-center justify-content-center">
						<input
							name="walletPayment.applePay"
							onChange={() => setWalletPayment({ googlePay: false, applePay: true })}
							checked={walletPayment.applePay}
							type="radio"
							id="walletPayment.applePay"
							style={{ height: '30px', width: '30px', marginRight: '10px' }}
						/>
						<label
							htmlFor="walletPayment.applePay"
							style={{ fontSize: '15px' }}>
							<img
								alt=""
								className="applePay"
								width={105}
								height={56}
								src={applePay}
							/>
							&nbsp;&nbsp;&nbsp;Apple Pay
						</label>
					</div>
				</div>
			</div>
		</Collapse>
	)
}
