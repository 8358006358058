import { action } from 'mobx'
import { boardingReadMessageGET, boardingAppMessagePOST, sendMessageNoteGET } from './Connections/ApplicationConnections'
import applicationContext from '../../views/Boarding/Applications/context/applications_context'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PayabliStorageManager } from '../../api/localStorageManager'

export default function ManagementApplication({ children }) {
	const loadMessage = action(async (appId, messageSkip = 0, messageTake = 10) => {
		try {
			const res = await boardingReadMessageGET(appId, messageSkip, messageTake)
			return res.data
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return []
		}
	})
	const saveMessage = action(async (appId, message) => {
		try {
			if (message.toString().length === 0) {
				toast.error('Enter the message you want to save', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
				return false
			}

			let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
			const user = encryptStorage.getItem('pUser')
			const dataMessage = { userId: user.id, content: message }
			const res = await boardingAppMessagePOST(appId, dataMessage)
			if (res.data?.content) {
				toast.success('Note added successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
				return res.data
			}
			return false
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})
	const sendMail = action(async (idMessage, emails, emailValid) => {
		try {
			if (emails.toString().length === 0 || !emailValid) {
				toast.error(emails.toString().length === 0 ? 'You must enter the email or emails': 'invalid email or emails', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
				return
			}

			const res = await sendMessageNoteGET(idMessage, emails.replace(/\s+/g, ''))
			if (res.data?.isSuccess) {
				toast.success('Email sent successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
				return {success: true}
			}
			return
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return
		}
	})

	return (
		<applicationContext.Provider
			value={{
				loadMessage,
				saveMessage,
				sendMail
			}}>
			<>{children}</>
		</applicationContext.Provider>
	)
}
