const boardingFields = {
	Business: [
		{
			name: 'legalname',
			labelValue: 'Business Legal Name'
		},
		{
			name: 'dbaname',
			labelValue: 'Business DBA Name'
		},
		{
			name: 'btype',
			labelValue: 'Business Type'
		},
		{
			name: 'ein',
			labelValue: 'Business Ein'
		},
		{
			labelValue: 'Tax Filing Name',
			name: 'taxfillname'
		},
		{
			labelValue: 'Business License',
			name: 'license'
		},
		{
			labelValue: 'Business LOT months',
			name: 'businessLOT_months'
		},
		{
			labelValue: 'Business Corp. Type',
			name: 'btype_corp'
		},
		{
			labelValue: 'Business LLC Type',
			name: 'btype_llc'
		},
		{
			labelValue: 'Business Government Type',
			name: 'btype_government'
		},
		{
			labelValue: 'License State',
			name: 'licstate'
		},
		{
			labelValue: 'Website',
			name: 'website'
		},
		{
			labelValue: 'Business Start Date',
			name: 'startdate'
		},
		{
			labelValue: 'Phone Number',
			name: 'phonenumber'
		},
		{
			labelValue: 'Fax Number',
			name: 'faxnumber'
		},
		{
			labelValue: 'Business Start Year',
			name: 'businessStart_year'
		},
		{
			labelValue: 'Business LOT years',
			name: 'businessLOT_years'
		},
		{
			labelValue: 'Business Partnership Type',
			name: 'btype_partnership'
		},
		{
			labelValue: 'Business State Type',
			name: 'btype_state'
		},
		{
			labelValue: 'Business Tax Exempt Type',
			name: 'btype_taxExempt'
		},
		{
			labelValue: 'Address #1',
			name: 'baddress'
		},
		{
			labelValue: 'Address #2',
			name: 'baddress1'
		},
		{
			labelValue: 'City',
			name: 'bcity'
		},
		{
			labelValue: 'City State Zip',
			name: 'bCityStateZip'
		},
		{
			labelValue: 'Zip',
			name: 'bzip'
		},
		{
			labelValue: 'State',
			name: 'bstate'
		},
		{
			labelValue: 'Country',
			name: 'bcountry'
		},
		{
			labelValue: 'Address #1',
			name: 'maddress'
		},
		{
			labelValue: 'Address #2',
			name: 'maddress1'
		},
		{
			labelValue: 'City',
			name: 'mcity'
		},
		{
			labelValue: 'City State Zip',
			name: 'mCityStateZip'
		},
		{
			labelValue: 'Zip',
			name: 'mzip'
		},
		{
			labelValue: 'State',
			name: 'mstate'
		},
		{
			labelValue: 'Country',
			name: 'mcountry'
		}
	],
	Owner: [1, 2, 3, 4]
		.map((ownership, index) => [
			{
				labelValue: `Owner Name # ${index + 1}`,
				name: `ownership_${index}_ownername`
			},
			{
				labelValue: `Owner Title # ${index + 1}`,
				name: `ownership_${index}_ownertitle`
			},
			{
				labelValue: `Ownership % # ${index + 1}`,
				name: `ownership_${index}_ownerpercent`
			},
			{
				labelValue: `Owner SSN # ${index + 1}`,
				name: `ownership_${index}_ownerssn`
			},
			{
				labelValue: `Date Of Birth # ${index + 1}`,
				name: `ownership_${index}_ownerdob`
			},
			{
				labelValue: `Phone Number # ${index + 1}`,
				name: `ownership_${index}_ownerphone1`
			},
			{
				labelValue: `Additional Phone Number # ${index + 1}`,
				name: `ownership_${index}_ownerphone2`
			},
			{
				labelValue: `Email Address # ${index + 1}`,
				name: `ownership_${index}_owneremail`
			},
			{
				labelValue: `Driver's License Number # ${index + 1}`,
				name: `ownership_${index}_ownerdriver`
			},
			{
				labelValue: `Driver's State License # ${index + 1}`,
				name: `ownership_${index}_odriverstate`
			},
			{
				labelValue: `Owner Address # ${index + 1}`,
				name: `ownership_${index}_oaddress`
			},
			{
				labelValue: `City # ${index + 1}`,
				name: `ownership_${index}_ocity`
			},
			{
				labelValue: `Zip # ${index + 1}`,
				name: `ownership_${index}_ozip`
			},
			{
				labelValue: `State # ${index + 1}`,
				name: `ownership_${index}_ostate`
			},
			{
				labelValue: `Country # ${index + 1}`,
				name: `ownership_${index}_ocountry`
			},
			{
				labelValue: `City/State/Zip # ${index + 1}`,
				name: `ownership_${index}_ocityStateZip`
			}
		])
		.reduce((acc, curr) => acc.concat(curr), []),
	Processing: [
		{
			labelValue: 'Merchant Category Code (MCC)',
			name: 'mcc'
		},
		{
			labelValue: 'Business Description',
			name: 'business_description'
		},
		{
			labelValue: '% of Payments In Person',
			name: 'binperson'
		},
		{
			labelValue: '% of Payments By Phone',
			name: 'binphone'
		},
		{
			labelValue: '% of Payments Online',
			name: 'binweb'
		},
		{
			labelValue: 'Tell Us About Your Business',
			name: 'bsummary'
		},
		{
			labelValue: 'High Daily Dollar Amount',
			name: 'high_daily_dollar_ammount'
		},
		{
			labelValue: 'High Number Daily Transactions',
			name: 'high_number_daily_transaction'
		},
		{
			labelValue: 'High Number Monthly transaction',
			name: 'high_number_monthly_transaction'
		},
		{
			labelValue: 'Average Monthly Volume',
			name: 'avgmonthly'
		},
		{
			labelValue: 'Average Ticket Amount',
			name: 'ticketamt'
		},
		{
			labelValue: 'Annual Ticket Volume',
			name: 'ticketannualvolume'
		},
		{
			labelValue: 'High Ticket Amount',
			name: 'highticketamt'
		},
		{
			labelValue: 'Annual Revenue',
			name: 'annualRevenue'
		},
		{
			labelValue: 'When Is Payment Charged',
			name: 'whenCharged'
		},
		{
			labelValue: 'Products/Services Delivered In',
			name: 'whenDelivered'
		},
		{
			labelValue: 'When Generally Provided In',
			name: 'whenProvided'
		},
		{
			labelValue: 'Refund Policy',
			name: 'whenRefunded'
		},
		{
			labelValue: 'High Monthly Dollar Amount',
			name: 'high_monthly_dollar_amount'
		}
	],
	Services: [
		{ labelValue: '% x auth', name: 'visaPercentageAuth' },
		{ labelValue: '$ x auth', name: 'visaAmountAuth' },
		{ labelValue: 'Low Per Range', name: 'visaLowPerRange' },
		{ labelValue: 'High Per Range', name: 'visaHighPerRange' },
		{ labelValue: '% Recurring', name: 'visaPercentRecurring' },
		{ labelValue: '$ Recurring', name: 'visaAmountRecurring' },
		{ labelValue: '% x auth', name: 'masterPercentageAuth' },
		{ labelValue: '$ x auth', name: 'masterAmountAuth' },
		{ labelValue: 'Low Per Range', name: 'masterLowPerRange' },
		{ labelValue: 'High Per Range', name: 'masterHighPerRange' },
		{ labelValue: '% Recurring', name: 'masterPercentRecurring' },
		{ labelValue: '$ Recurring', name: 'masterAmountRecurring' },
		{ labelValue: '% x auth', name: 'discoverPercentageAuth' },
		{ labelValue: '$ x auth', name: 'discoverAmountAuth' },
		{ labelValue: 'Low Per Range', name: 'discoverLowPerRange' },
		{ labelValue: 'High Per Range', name: 'discoverHighPerRange' },
		{ labelValue: '% Recurring', name: 'discoverPercentRecurring' },
		{ labelValue: '$ Recurring', name: 'discoverAmountRecurring' },
		{ labelValue: '% x auth', name: 'amexPercentageAuth' },
		{ labelValue: '$ x auth', name: 'amexAmountAuth' },
		{ labelValue: 'Low Per Range', name: 'amexLowPerRange' },
		{ labelValue: 'High Per Range', name: 'amexHighPerRange' },
		{ labelValue: '% Recurring', name: 'amexPercentRecurring' },
		{ labelValue: '$ Recurring', name: 'amexAmountRecurring' },
		{ labelValue: 'Transaction Fee', name: 'transactionCardFee' },
		{ labelValue: 'ChargeBack Fee', name: 'chargebackCardFee' },
		{ labelValue: 'ACH Batch Fee', name: 'achBatchCardFee' },
		{ labelValue: 'AVS Card Fee', name: 'avsCardFee' },
		{ labelValue: 'Monthly Platform Fee', name: 'montlyPlatformCardFee' },
		{ labelValue: 'Annual Fee', name: 'annualCardFee' },
		{
			labelValue: 'Minimum Processing Fee',
			name: 'minimumProcessingCardFee'
		},
		{ labelValue: 'DDA Rejects Fee', name: 'ddaRejectsCardFee' },
		{ labelValue: 'Retrieval Fee', name: 'retrievalCardFee' },
		{ labelValue: 'Monthly PCI Fee', name: 'monthlyPCICardFee' },
		{
			labelValue: 'Early Termination Fee',
			name: 'earlyTerminationCardFee'
		},
		{ labelValue: '% x auth', name: 'achPercentageAuth' },
		{ labelValue: '$ x auth', name: 'achAmountAuth' },
		{ labelValue: 'Low Per Range', name: 'achLowPerRange' },
		{ labelValue: 'High Per Range', name: 'achHighPerRange' },
		{ labelValue: '% One Time', name: 'achOneTimePercentage' },
		{ labelValue: '$ One Time', name: 'achOneTimeAmount' },
		{ labelValue: '% Recurring', name: 'achPercentRecurring' },
		{ labelValue: '$ Recurring', name: 'achAmountRecurring' },
		{ labelValue: 'Returned ACH Fee', name: 'returnedAchFee' },
		{ labelValue: 'ChargeBack Fee', name: 'chargebackAchFee' },
		{ labelValue: 'Verify Bank Fee', name: 'verifyBankAchFee' },
		{ labelValue: 'Verify Negative Fee', name: 'verifyNegativeAchFee' },
		{ labelValue: 'Verify Fund Fee', name: 'verifyFundAchFee' },
		{ labelValue: 'Monthly Platform Fee', name: 'monthlyAchFee' },
		{ labelValue: 'Annual Fee', name: 'annualAchFee' },
		{ labelValue: 'Sunday Origination', name: 'sundayOriginationAchFee' },
		{ labelValue: 'Same Day ACH', name: 'sameDayAchFee' },
		{ labelValue: 'Advanced Settlement', name: 'advancedSettlementAchFee' },
		{ labelValue: 'Quarterly PCI', name: 'quarterlyPCIAchFee' },
		{ labelValue: 'Early Termination Fee', name: 'earlyTerminationAchFee' },
		{ labelValue: '% x auth', name: 'mgmPercentageAuth' },
		{ labelValue: '$ x auth', name: 'mgmAmountAuth' },
		{ labelValue: 'Low Per Range', name: 'mgmLowPerRange' },
		{ labelValue: 'High Per Range', name: 'mgmHighPerRange' },
		{ labelValue: '% One Time', name: 'mgmOneTimePercentage' },
		{ labelValue: '$ One Time', name: 'mgmOneTimeAmount' },
		{ labelValue: '% Recurring', name: 'mgmPercentRecurring' },
		{ labelValue: '$ Recurring', name: 'mgmAmountRecurring' },
		{ labelValue: 'Returned Managed Payables Fee', name: 'returnedMgmFee' },
		{ labelValue: 'Monthly Platform Fee', name: 'monthlyMgmFee' },
		{ labelValue: 'Verify Bank Fee', name: 'verifyBankMgmFee' },
		{ labelValue: 'Verify Fund Fee', name: 'verifyFundMgmFee' },
		{ labelValue: 'Same Day Managed Payables', name: 'sameDayMgmFee' },
		{ labelValue: 'Quarterly PCI', name: 'quarterlyPCIMgmFee' },
		{ labelValue: 'ChargeBack Fee', name: 'chargebackMgmFee' },
		{ labelValue: 'Annual Fee', name: 'annualMgmFee' },
		{ labelValue: 'Verify Negative Fee', name: 'verifyNegativeMgmFee' },
		{ labelValue: 'Sunday Origination', name: 'sundayOriginationMgmFee' },
		{ labelValue: 'Advanced Settlement', name: 'advancedSettlementMgmFee' },
		{ labelValue: 'Early Termination Fee', name: 'earlyTerminationMgmFee' }
	],
	Banking: [
		{
			name: 'depositAccount_bankName',
			labelValue: 'Deposit Bank Name'
		},
		{
			name: 'depositAccount_routingAccount',
			labelValue: 'Deposit Routing Number'
		},
		{
			name: 'depositAccount_accountNumber',
			labelValue: 'Deposit Account Number'
		},
		{
			name: 'depositAccount_typeAccount',
			labelValue: 'Deposit Account Type'
		},
		{
			name: 'withdrawalAccount_bankName',
			labelValue: 'Withdrawal Bank Name'
		},
		{
			name: 'withdrawalAccount_routingAccount',
			labelValue: 'Withdrawal Routing Number'
		},
		{
			name: 'withdrawalAccount_accountNumber',
			labelValue: 'Withdrawal Account Number'
		},
		{
			name: 'withdrawalAccount_typeAccount',
			labelValue: 'Withdrawal Account Type'
		},
		{
			name: 'withdrawalAccount_typeAccount_checking',
			labelValue: 'Withdrawal Account Type Checking'
		},
		{
			name: 'withdrawalAccount_typeAccount_saving',
			labelValue: 'Withdrawal Account Type Saving'
		}
	],
	Signer: [
		{
			labelValue: 'Signer Name',
			name: 'signer_name'
		},
		{
			labelValue: 'Signer Title',
			name: 'signer_title'
		},
		{
			labelValue: 'Signer %',
			name: 'signer_ownerpercent'
		},
		{
			labelValue: 'Signer SSN',
			name: 'signer_ssn'
		},
		{
			labelValue: 'Date Of Birth',
			name: 'signer_dob'
		},
		{
			labelValue: 'Phone Number',
			name: 'signer_phone'
		},
		{
			labelValue: 'Email Address',
			name: 'signer_email'
		},
		{
			labelValue: 'Name/Title',
			name: 'signer_name_title'
		},
		{
			labelValue: 'Signer Address',
			name: 'signer_address'
		},
		{
			labelValue: 'Signer Additional Address',
			name: 'signer_address1'
		},
		{
			labelValue: 'City',
			name: 'signer_city'
		},
		{
			labelValue: 'Zip',
			name: 'signer_zip'
		},
		{
			labelValue: 'State',
			name: 'signer_state'
		},
		{
			labelValue: 'Country',
			name: 'signer_country'
		},
		{
			labelValue: 'City/State/Zip',
			name: 'signer_cityStateZip'
		}
	]
}
export default boardingFields
