import React, { useState, useContext, useRef } from 'react'
import styles from '../../../../assets/css/styleCommandCenter.module.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { RiSettings3Line } from 'react-icons/ri'
import { BiCheck, BiInfoCircle } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'
import RowContainer from '../../../../components/commandCenter/RowContainer'
import GlobalStore from '../../../../store/GlobalStore'
import commandContext from '../../context/commandCenter_context'

export default function BankInformation({ account, index, load, editing, setEditing, showPopUpMessage }) {
	const { updateBankAccount, handleChangeBanking, removeBank, validaBankRemove, toastMessage } = useContext(commandContext)
	const [inputDisabled, setInputDisabled] = useState(true)
	const inputCheckDeposit = useRef()
	const inputCheckWithdrawals = useRef()

	const updteBank = async () => {
		let valid = false
		if (account.bankName === null || account.bankName.toString().trim().length === 0) valid = true
		if (account.bankAccountHolderName === null || account.bankAccountHolderName.toString().trim().length === 0) valid = true
		if (account.bankAccountHolderType !== 'Business' && account.bankAccountHolderType !== 'Personal') valid = true
		if (account.routingAccount === null || account.routingAccount.toString().trim().length === 0) valid = true
		if (account.accountNumber === null || account.accountNumber.toString().trim().length === 0) valid = true
		if (account.typeAccount !== 'Checking' && account.typeAccount !== 'Savings') valid = true
		if (account.bankAccountFunction !== 0 && account.bankAccountFunction !== 1 && account.bankAccountFunction !== 2) valid = true
		if (!inputCheckDeposit.current.checked && !inputCheckWithdrawals.current.checked) valid = true
		if (valid) {
			toastMessage('You must fill in the required fields', false)
			return
		}
		const res = await updateBankAccount(account.id)
		if (res) cancel()
	}
	const deleteBank = async (idBank) => {
		if (!validaBankRemove(idBank)) return
		const res = await removeBank(idBank)
		if (!res) return
		await load()
		cancel()
	}
	const cancel = () => {
		setInputDisabled(true)
		setEditing(false)
		load()
	}

	return (
		<div
			className={`d-flex flex-column align-items-start ${!inputDisabled && index > 0 ? 'pb-2 ps-0 pe-0 pt-4' : !inputDisabled ? 'pb-2 ps-0 pe-0 pt-0' : 'p-0'} gap-3 w-100 ${styles['show-on-hover-begin']}`}
			style={{ borderBottom: !inputDisabled && '1px solid rgb(244, 245, 247)', borderTop: !inputDisabled && index > 0 && '1px solid rgb(244, 245, 247)' }}>
			<div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-0 gap-3 mb-0 w-100">
				<label
					className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
					style={{ fontWeight: '600', lineHeight: '24px' }}>
					{'Bank Account #' + (index + 1)}
				</label>
				{inputDisabled && !editing && (
					<div className={'d-flex flex-row flex-wrap justify-content-end align-items-start p-0 gap-3 ' + styles['show-on-hover']}>
						<button
							onClick={() => setInputDisabled(false) | setEditing(true)}
							className={styles['btn'] + ' ' + styles['no-border'] + ' ' + styles['color-blue']}>
							<RiSettings3Line className={styles['icon24']} />
							MANAGE BANKING DETAILS
						</button>
					</div>
				)}
				{!inputDisabled && (
					<div
						className="d-flex flex-row flex-wrap justify-content-end align-items-start pb-0 pt-0 ps-0 gap-3"
						style={{ paddingRight: '10px' }}>
						<button
							onClick={() => showPopUpMessage({ show: true, messageTitle: 'Notice!', message: 'This action will delete the Bank Account #' + (index + 1) + '.', message2: 'Do you want to continue?', btnCaption: 'DELETE ACCOUNT', functionOk: () => deleteBank(account.id) })}
							className={styles['btn'] + ' ' + styles['btn-red-letter'] + ' ' + styles['no-border']}>
							<BsTrash className={styles['icon20']} />
							DELETE ACCOUNT
						</button>

						<button
							onClick={() => updteBank()}
							className={styles['btn'] + ' ' + styles['border-blue']}>
							<BiCheck className={styles['icon24']} />
							SAVE CHANGES
						</button>
					</div>
				)}
			</div>

			<div className="d-flex flex-column w-100">
				<div className={styles['grid-container']}>
					<div className="d-flex flex-column align-items-start p-0">
						<RowContainer
							inputType="mask"
							classNameInput={!inputDisabled && (account.bankName === null || account.bankName.trim().length === 0) ? 'is-invalid' : ''}
							inputDisabled={inputDisabled}
							//isLoading={isLoading}
							labelValue="Bank Name"
							name={'bankName[' + index + ']'}
							placeholder="Value"
							value={account.bankName}
							setValue={(values) => handleChangeBanking(index, 'bankName', values)}
							mask={GlobalStore.maskValidator('text')}
							maxLength={50}
						/>

						<RowContainer
							inputType="mask"
							classNameInput={!inputDisabled && (account.bankAccountHolderName === null || account.bankAccountHolderName.trim().length === 0) ? 'is-invalid' : ''}
							inputDisabled={inputDisabled}
							//isLoading={isLoading}
							labelValue="Bank Account Holder Name"
							name={'bankAccountHolderName[' + index + ']'}
							placeholder="Value"
							value={account.bankAccountHolderName}
							setValue={(values) => handleChangeBanking(index, 'bankAccountHolderName', values)}
							mask={GlobalStore.maskValidator('text')}
							maxLength={50}
						/>

						<div
							className="d-flex flex-row justify-content-start flex-wrap align-items-center w-100 gap-2"
							style={{ padding: '0px 0px 12px' }}>
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-13']}
								style={{ fontWeight: '600', lineHeight: '40px', minWidth: '180px', letterSpacing: '0.0025em' }}>
								Bank Account Holder Type
							</label>
							{!inputDisabled ? (
								<div
									className={`d-flex flex-row justify-content-start flex-nowrap align-items-center p-0 gap-5 ${!inputDisabled && account.bankAccountHolderType !== 'Business' && account.bankAccountHolderType !== 'Personal' && 'px-2'}`}
									style={{ border: !inputDisabled && account.bankAccountHolderType !== 'Business' && account.bankAccountHolderType !== 'Personal' && '1px solid #dc3545', borderRadius: !inputDisabled && !inputDisabled && account.bankAccountHolderType !== 'Business' && account.bankAccountHolderType !== 'Personal' && '.25rem' }}>
									<RowContainer
										inputType="Radio"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Business"
										name={'bankAccountHolderType[' + index + ']-Business'}
										value={account.bankAccountHolderType === 'Business' ? true : false}
										setValue={() => handleChangeBanking(index, 'bankAccountHolderType', 'Business')}
									/>
									<RowContainer
										inputType="Radio"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Personal"
										name={'bankAccountHolderType[' + index + ']-Personal'}
										value={account.bankAccountHolderType === 'Personal' ? true : false}
										setValue={() => handleChangeBanking(index, 'bankAccountHolderType', 'Personal')}
									/>
								</div>
							) : (
								<RowContainer
									inputType="Label"
									//isLoading={isLoading}
									labelValue={account.bankAccountHolderType === 'Personal' ? 'Personal' : account.bankAccountHolderType === 'Business' ? 'Business' : '-'}
								/>
							)}
						</div>

						<RowContainer
							inputDisabled={inputDisabled}
							classNameInput={!inputDisabled && (account.country === null || account.country?.trim().length === 0) ? 'is-invalid' : ''}
							labelValue="Country"
							name="country"
							value={account.country}
							setValue={(values) => handleChangeBanking(index, 'country', values)}
							optionList={
								<>
									<option value="">select...</option>
									{GlobalStore.getAllCountries().map((data) => (
										<option
											key={'optState' + data.value}
											value={data.value}>
											{data.text}
										</option>
									))}
								</>
							}
						/>

						<RowContainer
							inputType="mask"
							classNameInput={!inputDisabled && (account.routingAccount === null || account.routingAccount.trim().length === 0) ? 'is-invalid' : ''}
							inputDisabled={inputDisabled}
							//isLoading={isLoading}
							labelValue="Routing Number"
							name={'routingAccount[' + index + ']'}
							placeholder="Value"
							value={account.routingAccount}
							setValue={(values) => handleChangeBanking(index, 'routingAccount', values)}
							mask={GlobalStore.maskValidator('routing')}
						/>
					</div>

					<div className="d-flex flex-column align-items-start p-0">
						<RowContainer
							inputType={inputDisabled ? 'text' : 'mask'}
							classNameInput={!inputDisabled && (account.accountNumber === null || account.accountNumber.trim().length === 0) ? 'is-invalid' : ''}
							inputDisabled={inputDisabled}
							//isLoading={isLoading}
							labelValue="Account Number"
							name={'accountNumber[' + index + ']'}
							placeholder="Value"
							value={inputDisabled ? account.accountNumber.replace('XXXXXX', '••••••') : account.accountNumber}
							setValue={(values) => handleChangeBanking(index, 'accountNumber', values)}
							mask={!inputDisabled && GlobalStore.maskValidator('accountnumber')}
							maxLength={17}
						/>

						<div
							className="d-flex flex-row justify-content-start flex-wrap align-items-center w-100 gap-2"
							style={{ padding: '0px 0px 12px' }}>
							<label
								className={styles['label-form'] + ' ' + styles['ft-s-13']}
								style={{ fontWeight: '600', lineHeight: '40px', minWidth: '180px', letterSpacing: '0.0025em' }}>
								Account Type
							</label>
							{!inputDisabled ? (
								<div
									className={`d-flex flex-row justify-content-start flex-nowrap align-items-center p-0 gap-5 ${!inputDisabled && account.typeAccount !== 'Checking' && account.typeAccount !== 'Savings' && 'px-2'}`}
									style={{ border: !inputDisabled && account.typeAccount !== 'Checking' && account.typeAccount !== 'Savings' && '1px solid #dc3545', borderRadius: !inputDisabled && account.typeAccount !== 'Checking' && account.typeAccount !== 'Savings' && '.25rem' }}>
									<RowContainer
										inputType="Radio"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Checking"
										name={'typeAccount[' + index + ']-Checking'}
										value={account.typeAccount === 'Checking' ? true : false}
										setValue={() => handleChangeBanking(index, 'typeAccount', 'Checking')}
									/>
									<RowContainer
										inputType="Radio"
										inputDisabled={inputDisabled}
										//isLoading={isLoading}
										labelValue="Savings"
										name={'typeAccount[' + index + ']-Savings'}
										value={account.typeAccount === 'Savings' ? true : false}
										setValue={() => handleChangeBanking(index, 'typeAccount', 'Savings')}
									/>
								</div>
							) : (
								<RowContainer
									inputType="Label"
									//isLoading={isLoading}
									labelValue={account.typeAccount === 'Savings' ? 'Savings' : account.typeAccount === 'Checking' ? 'Checking' : '-'}
								/>
							)}
						</div>

						{!inputDisabled ? (
							<>
								<label
									className={styles['label-form'] + ' ' + styles['ft-s-13']}
									style={{ fontWeight: '600', lineHeight: '40px', minWidth: '180px', letterSpacing: '0.0025em' }}>
									Bank Account Function &nbsp;
									<OverlayTrigger
										key={'right'}
										placement={'right'}
										overlay={
											<Tooltip>
												If you wish to use this bank account for deposits and withdrawals/ fees, please select both option. <br />
												<br />
												If you wish to only select one intended purpose, you will be required to add an additional bank account.
											</Tooltip>
										}>
										<BiInfoCircle className={styles['icon15'] + ' ' + styles['color-blue']} />
									</OverlayTrigger>
								</label>
								<div
									className={`d-flex flex-wrap flex-row align-items-center ${styles['columns-sm']} ${!inputDisabled && account.bankAccountFunction !== 0 && account.bankAccountFunction !== 1 && account.bankAccountFunction !== 2 && 'px-2'}`}
									style={{ padding: '0', gap: '25px', border: !inputDisabled && account.bankAccountFunction !== 0 && account.bankAccountFunction !== 1 && account.bankAccountFunction !== 2 && '1px solid #dc3545', borderRadius: '.25rem' }}>
									<RowContainer
										inputType="Checkbox"
										refs={inputCheckDeposit}
										value={account.bankAccountFunction === 0 || account.bankAccountFunction === 2 ? true : false}
										inputDisabled={inputDisabled}
										name={'bankAccountFunction[' + index + ']-deposits'}
										labelValue="Deposits"
										setValue={(checked) => (account.bankAccountFunction === 0 && checked === false ? handleChangeBanking(index, 'bankAccountFunction', 3) : handleChangeBanking(index, 'bankAccountFunction', account.bankAccountFunction === 1 && checked === true ? 2 : account.bankAccountFunction === 1 && checked === false ? 1 : account.bankAccountFunction === 2 && checked === false ? 1 : account.bankAccountFunction === 0 && checked === false ? 0 : 0))}
									/>
									<RowContainer
										inputType="Checkbox"
										refs={inputCheckWithdrawals}
										value={account.bankAccountFunction === 1 || account.bankAccountFunction === 2 ? true : false}
										inputDisabled={inputDisabled}
										name={'bankAccountFunction[' + index + ']-withdrawalsFees'}
										labelValue="Withdrawals/ Fees"
										setValue={(checked) => (account.bankAccountFunction === 1 && checked === false ? handleChangeBanking(index, 'bankAccountFunction', 3) : handleChangeBanking(index, 'bankAccountFunction', account.bankAccountFunction === 0 && checked === true ? 2 : account.bankAccountFunction === 0 && checked === false ? 0 : account.bankAccountFunction === 2 && checked === false ? 0 : account.bankAccountFunction === 1 && checked === false ? 1 : 1))}
									/>
								</div>
							</>
						) : (
							<div
								className="d-flex flex-row justify-content-start flex-wrap align-items-center w-100 gap-2"
								style={{ padding: '0px 0px 12px' }}>
								<label
									className={styles['label-form'] + ' ' + styles['ft-s-13']}
									style={{ fontWeight: '600', lineHeight: '40px', minWidth: '180px', letterSpacing: '0.0025em' }}>
									Bank Account Function
								</label>
								<RowContainer
									inputType="Label"
									//isLoading={isLoading}
									labelValue={account.bankAccountFunction === 0 ? 'Deposit' : account.bankAccountFunction === 1 ? 'Withdrawals/Fees' : 'Both'}
								/>
							</div>
						)}
					</div>

					{!inputDisabled && (
						<div className="d-flex align-items-center justify-content-end flex-wrap flex-row w-100">
							<button
								onClick={() => cancel()}
								className={styles['btn'] + ' ' + styles['btn-red-letter'] + ' ' + styles['no-border']}>
								CANCEL
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
