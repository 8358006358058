import React from 'react';
import { inject, observer } from 'mobx-react';
import {Link} from "react-router-dom";
import { PayabliStorageManager } from '../api/localStorageManager'
import { BiBarChartSquare } from 'react-icons/bi';

@inject('reports', 'global')
@observer
class RiskControlCenterLinks extends React.Component {
    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        return (
            <div className="row">
                <div className={"col-12 text-center"}>
                    <div className="hide-sm"> 
                        {true &&
                            <>
                                <Link className={this.props.selected === 'fraudAlerts' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/riskcontrol/reports"}><BiBarChartSquare/> Reports</Link>
                            </>
                        }                    
                    </div>
                <div className="hide show-sm sm-screen-topbar-menu">
                    {true &&
                        <>
                            <Link className={this.props.selected === 'fraudAlerts' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/riskcontrol/reports"}><BiBarChartSquare/></Link>
                        </>
                    }
                </div>
            </div>            
        </div>
        )
    }
}

export { RiskControlCenterLinks };