import React from 'react';
import { inject, observer } from 'mobx-react';
import { SecurityManager } from '../../api/securityManager';

@inject('global')
@observer
class Logout extends React.Component {

    
    componentDidMount() {
        let entry = this.props.match.params.entryUrl;
        SecurityManager.logout(entry);
    }

    render() {
        return (
        <>
            <div className="mt-body">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="card text-center" style={{width: '40em', padding: "40px"}}>
                   Closing session ...
                </div>
            </div>
            </div>
        </>
        )
    }
}

export { Logout };