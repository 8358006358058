import React from 'react'
import RowContainer from '../../../../../components/commandCenter/RowContainer'
import GlobalStore from '../../../../../store/GlobalStore'

export default function CheckCommerce({ inputValues, SetInputValues, setClassValidate, classValidate }) {
	return (
		<>
			{inputValues.processor === 'checkcommerce' && (
				<div className="d-flex flex-column">
					<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.checkcommerce.username}
								inputDisabled={false}
								labelValue="User Name"
								name="username"
								placeholder="Value"
								value={inputValues.username}
								onBlur={() => setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, username: inputValues.username ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, username: values }) | setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, username: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.checkcommerce.password}
								inputDisabled={false}
								labelValue="Password"
								name="password"
								placeholder="Value"
								value={inputValues.password}
								onBlur={() => setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, password: inputValues.password ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, password: values }) | setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, password: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
						</div>
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.checkcommerce.merchantNumber}
								inputDisabled={false}
								labelValue="Merchant Number"
								name="merchantNumber"
								placeholder="Value"
								value={inputValues.merchantNumber}
								onBlur={() => setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, merchantNumber: inputValues.merchantNumber ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, merchantNumber: values }) | setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, merchantNumber: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
