import React from 'react';
import { inject, observer } from 'mobx-react';
import {IMaskInput} from 'react-imask';

@inject('vTerminal', 'global')
@observer
class PaymentMethodECheck extends React.Component {

    constructor(props) {
        super(props);
        this.handleTextChangeACH = this.handleTextChangeACH.bind(this);
        this.handleTextChangeACHSelect = this.handleTextChangeACHSelect.bind(this);
    }


    handleTextChangeACH(field, value) {
        this.props.vTerminal.handleTextChangeACH(field, value);
    }
    
    handleTextChangeACHSelect(e) {
        this.props.vTerminal.handleTextChangeACH(e.target.name, e.target.value);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('alphanumericspaces')}
                                data-qaid="accountHolderNameInput"
                                name="achAccountHolderName"
                                defaultValue={this.props.vTerminal.paymentPage.paymentMethods.achAccountHolderName ? this.props.vTerminal.paymentPage.paymentMethods.achAccountHolderName: ''}
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeACH('achAccountHolderName', value)
                                }
                                placeholder="Account Holder Name"
                                className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsAchAccountHolderNameError ? "form-control input-error" : "form-control" }
                                id="achAccountHolderName"
                            />
                            <label htmlFor="achAccountHolderName">Account Holder Name</label>
                        </div>
                    </div>
                    <div className="col-sm mb-3">
                        <div className="form-floating">
                            <select className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsAchAccountTypeError ? "form-select form-control input-error" : "form-select form-control" } id="achAccountType" name="achAccountType" onChange={(e) => this.handleTextChangeACHSelect(e)} defaultValue={''} data-qaid="accountTypeInput">
                                <option value="">Select...</option>
                                <option value="Checking" data-qaid="selectCheckingAccount">Checking</option>
                                <option value="Savings" data-qaid="selectSavingAccount">Savings</option>
                            </select>
                            <label htmlFor="achAccountType">Account Type</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('routing')}
                                data-qaid="achRoutingNumber"
                                name="achRouting"
                                defaultValue={this.props.vTerminal.paymentPage.paymentMethods.achRouting ? this.props.vTerminal.paymentPage.paymentMethods.achRouting: ''}
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeACH('achRouting', value)
                                }
                                placeholder="Routing #"
                                className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsAchRoutingError ? "form-control input-error" : "form-control" }
                                id="achRouting"
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                            <label htmlFor="achRouting">Routing #</label>
                        </div>
                    </div>
                    <div className="col-sm mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('accountnumber')}
                                data-qaid="achAccountNumber"
                                name="achAccount"
                                defaultValue={this.props.vTerminal.paymentPage.paymentMethods.achAccount ? this.props.vTerminal.paymentPage.paymentMethods.achAccount: ''}
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeACH('achAccount', value)
                                }
                                placeholder="Account #"
                                className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsAchAccountError ? "form-control input-error" : "form-control" }
                                id="achAccount"
                                maxLength={17}
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                            <label htmlFor="achAccount">Account #</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { PaymentMethodECheck };
