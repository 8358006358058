import React from 'react';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';

@inject('store', 'global', 'invoice')
@observer
class PreviewInvoicePdf extends React.Component {
  componentDidMount() {}

  getPriceFormatted(value) {
    return `$${this.props.global.numberWithCommas(parseFloat(value).toFixed(2))}`;
  }

  getPriceFormattedDynamic(value) {
    return `$${this.props.global.numberWithCommas(parseFloat(value).toFixed(this.props.global.formatDecimalDynamics(value)))}`;
  }

  getPriceFormattedDynamicAmount(value){
    if (value.toFixed(2) > 0) {
      return `$${this.props.global.numberWithCommas(parseFloat(value).toFixed(2))}`;
    } else {
      return `$${this.props.global.numberWithCommas(parseFloat(value).toFixed(this.props.global.formatDecimalDynamics(value)))}`;
    }
  }


  render() {
    const { paypointData, defaultSettings, customerSelected, invoiceDetails, pay, items, advancedOptions } =
      this.props.invoice;
    const { memoNote } = advancedOptions;

    const paypointInformation = {
      logo: paypointData.logo || this.props.invoice.getInvoiceLogo,
      name: paypointData.LegalName,
      address1: paypointData.Address1,
      address2: paypointData.Address2,
      phone: defaultSettings.contactUsPhone,
      email: defaultSettings.contactUsEmail,
      city: paypointData.City,
      state: paypointData.State,
      zip: paypointData.Zip,
    };

    const { brandColor, footerNote } = defaultSettings;

    const billInformation = {
      show: customerSelected && customerSelected.Firstname,
      name: `${customerSelected.Firstname} ${customerSelected.Lastname}`,
      company: customerSelected.Company ? customerSelected.Company : null,
      address: customerSelected.Address ? customerSelected.Address : null,
      address1: customerSelected.Address1 ? customerSelected.Address1 : null,
      email: customerSelected.Email ? customerSelected.Email : null,
      city: customerSelected.City ? customerSelected.City : null,
      state: customerSelected.State ? customerSelected.State : null,
      zip: customerSelected.Zip ? customerSelected.Zip : null,
    };

    const shipInformation = {
      show: customerSelected && customerSelected.ShippingAddress,
      address: `${customerSelected.ShippingAddress}`,
      address1: `${customerSelected.ShippingAddress1}`,
    };

    const invoiceDate = invoiceDetails.scheduleCheck === true ? dayjs(invoiceDetails.startDate).format('MMM D, YYYY') : dayjs(invoiceDetails.invoiceDate).format('MMM D, YYYY');
    const dueDate = dayjs(invoiceDetails.dueDate).format('MMM D, YYYY');

    const invoiceStatus = this.props.invoice.status;

    return (
      <div id="preview-invoice" className="preview-card not-rounded">
        {invoiceStatus === 4 && <div id="paid-tag" data-qaid="paidInvoicesMoneyIn">PAID</div>}
        {invoiceStatus === 2 && <div id="partially-paid-tag" data-qaid="partiallyInvoicesMoneyIn">Partially Paid</div>}

        <section className="header">
          <div className="wrapper row">
            <div className="logo col-sm-4">
              { paypointInformation.logo &&
              <img src={paypointInformation.logo} alt="logo" />
              }
            </div>
            <div className="welcome col-sm-8 text-right" style={{ color: brandColor }}>
              Hello, this is your invoice.
            </div>
          </div>
        </section>

        <section className="information">
          <span className="number">
            <span className="title">INVOICE</span>
            <span className="value">{invoiceDetails.invoiceNumber}</span>
          </span>
          <div className="data">
            <div className="date">
              <span className="title">INVOICE DATE</span>
              <span className="value">{invoiceDate}</span>
            </div>
            <div className="due">
              <span className="title">DUE DATE</span>
              <span className="value">{dueDate}</span>
            </div>
            <div className="pay" style={{ backgroundColor: brandColor }}>
              <span className="title">PLEASE PAY</span>
              <span className="value">{this.getPriceFormatted(pay.subtotal)}</span>
            </div>
          </div>
        </section>

        <section
          className="billing-info pb-0"
          style={{ backgroundColor: this.props.invoice.getBrightnessColor(brandColor || '#fff', 90) }}
        >
          <div className="row full-w">
            <div className="col-sm-4 column-from mb-4">
              <span className="title">From</span>
              {paypointInformation.name && <span>{paypointInformation.name}</span>}
              {paypointInformation.address1 && <span>{paypointInformation.address1}</span>}
              {paypointInformation.address2 && <span>{paypointInformation.address2}</span>}
              {[paypointInformation.city, paypointInformation.state, paypointInformation.zip].filter(Boolean).join(', ')}
              {paypointInformation.phone && <span>{paypointInformation.phone}</span>}
              {paypointInformation.email && <span>{paypointInformation.email}</span>}
            </div>

            {billInformation.show && (
              <div className="col-sm-4 column-bill mb-4">
                <span className="title">Bill to</span>
                {billInformation.company && <span>{billInformation.company}</span>}
                {billInformation.name && <span>{billInformation.name}</span>}
                {billInformation.address && <span>{billInformation.address}</span>}
                {billInformation.address1 && <span>{billInformation.address1}</span>}
                {[billInformation.city, billInformation.state, billInformation.zip].filter(Boolean).join(', ')}
                {billInformation.email && <span>{billInformation.email}</span>}
              </div>
            )}

            {shipInformation.show && (
              <div className="col-sm-4 column-ship mb-4">
                <span className="title">Ship to</span>
                {shipInformation.address && <span>{shipInformation.address}</span>}
                {shipInformation.address1 && <span>{shipInformation.address1}</span>}
              </div>
            )}
          </div>
        </section>

        <section className="items">
          <div className="invoice-details">
              <div className="responsive-1330-container">
                <table className="table" style={{minWidth: "400px"}}>
                  <tbody>
                  <tr style={{ color: brandColor }}>
                    <th>Product/service</th>
                    <th>Unit Price</th>
                    <th>Qty</th>
                    <th className="text-right">Amount</th>
                  </tr>
                  
                  {items.length === 0 && (
                  <tr className="skeleton">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  )}
                  
                  {items.length > 0 &&
                  items.map((item, idx) => (
                  <tr key={idx}>
                    <td className="product">
                      {item.label ? item.label : '-'}
                      <br/>
                      {item.description && <div className="description">{item.description}</div>}
                    </td>
                    <td>{this.getPriceFormattedDynamic(item.value)}</td>
                    <td>{item.quantity}</td>
                    <td className='text-right'>{this.getPriceFormattedDynamicAmount(item.value * item.quantity)}</td>
                  </tr>
                  ))}
                  </tbody>
                </table>
              </div>

              <div className='items-body'>
                <div className="row-item state">
                  <span className="text">Subtotal</span>
                  <span className="value">{this.getPriceFormatted(pay.subtotal)}</span>
                </div>

                <div className="row-item state" style={{ color: brandColor }}>
                  <span className="text">Total</span>
                  <span className="value">{this.getPriceFormatted(pay.subtotal)}</span>
                </div>
              </div>
          </div>
        </section>

        <section className="memo-note">{memoNote}</section>

        <section className="footer">
          <span className="text">{advancedOptions.footerNote || footerNote}</span>
        </section>
      </div>
    );
  }
}

export default PreviewInvoicePdf;
