import React from 'react'
import styles from '../../../assets/css/styleCommandCenter.module.css'
import RowContainer from '../RowContainer'
import GlobalStore from '../../../store/GlobalStore'

export default function BillingDetails({ inputDisabled, inputValues, SetInputValues }) {
	return (
		<>
			<label
				className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
				style={{ fontWeight: '600', lineHeight: '24px', paddingLeft: '12px' }}>
				Billing to PayPoint (Merchant)
			</label>
			<div style={{ borderBottom: '1px solid #D9D9D9', width: '100%', minHeight: '100px', paddingLeft: '12px' }}>
				<div className="d-flex flex-column w-100">
					<div className={styles['grid-container']}>
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								labelValue="Billing Account"
								name="billingAccount"
								placeholder="Value"
								value={inputValues.billingAccount}
								setValue={(values) => SetInputValues({ ...inputValues, billingAccount: values })}
								mask={GlobalStore.maskValidator('numbers')}
								maxLength={30}
							/>
							<RowContainer
								inputType="mask"
								inputDisabled={inputDisabled}
								labelValue="Pricing Type"
								name="pricingType"
								placeholder="Value"
								value={inputValues.pricingType}
								setValue={(values) => SetInputValues({ ...inputValues, pricingType: values })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={30}
							/>
						</div>

						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="$ Per Authorization"
								name="perAuthorizationMD"
								placeholder="$0.00"
								value={inputValues.perAuthorizationMD}
								setValue={(values) => SetInputValues({ ...inputValues, perAuthorizationMD: values })}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="% Per Authorization"
								name="perAuthorizationPorcent"
								prefix=""
								suffix="%"
								placeholder="0.00"
								value={inputValues.perAuthorizationPorcent}
								setValue={(values) => SetInputValues({ ...inputValues, perAuthorizationPorcent: values <= 100 ? values : inputValues.perAuthorizationPorcent })}
								maxLength={7}
							/>
						</div>
					</div>
				</div>
			</div>

			<label
				className={styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
				style={{ fontWeight: '600', lineHeight: '24px', paddingLeft: '12px' }}>
				Ocurrence Fees & Billing
			</label>
			<div style={{ width: '100%', minHeight: '100px', paddingLeft: '12px' }}>
				<div className="d-flex flex-column w-100">
					<div className={styles['grid-container']}>
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputDisabled={inputDisabled}
								labelValue="Billing Frequency"
								name="billingFrequency"
								value={inputValues.billingFrequency}
								setValue={(values) => SetInputValues({ ...inputValues, billingFrequency: values })}
								optionList={
									<>
										<option value="select">select...</option>
										<option value="Daily">Daily</option>
									</>
								}
							/>
							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Transaction Fee"
								name="transactionFee"
								placeholder="$0.00"
								value={inputValues.transactionFee}
								setValue={(values) => SetInputValues({ ...inputValues, transactionFee: values })}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="ACH Batch Free"
								name="ACHBatchFree"
								placeholder="$0.00"
								value={inputValues.ACHBatchFree}
								setValue={(values) => SetInputValues({ ...inputValues, ACHBatchFree: values })}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Monthly Platform Fee"
								name="monthlyPlatformFee"
								placeholder="$0.00"
								value={inputValues.monthlyPlatformFee}
								setValue={(values) => SetInputValues({ ...inputValues, monthlyPlatformFee: values })}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Min. Processing Fee"
								name="minProcessingFee"
								placeholder="$0.00"
								value={inputValues.minProcessingFee}
								setValue={(values) => SetInputValues({ ...inputValues, minProcessingFee: values })}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Retrieval Fee"
								name="retrievalFee"
								placeholder="$0.00"
								value={inputValues.retrievalFee}
								setValue={(values) => SetInputValues({ ...inputValues, retrievalFee: values })}
								maxLength={18}
							/>
						</div>

						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Changeback Fee"
								name="changeBackFee"
								placeholder="$0.00"
								value={inputValues.changeBackFee}
								setValue={(values) => SetInputValues({ ...inputValues, changeBackFee: values })}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Early Termination Fee"
								name="earlyTerminationFee"
								placeholder="$0.00"
								value={inputValues.earlyTerminationFee}
								setValue={(values) => SetInputValues({ ...inputValues, earlyTerminationFee: values })}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="AVS Card Fee"
								name="AVSCardFee"
								placeholder="$0.00"
								value={inputValues.AVSCardFee}
								setValue={(values) => SetInputValues({ ...inputValues, AVSCardFee: values })}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Annual Fee"
								name="annualFee"
								placeholder="$0.00"
								value={inputValues.annualFee}
								setValue={(values) => SetInputValues({ ...inputValues, annualFee: values })}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="DDA Reject Fee"
								name="DDARejectFee"
								placeholder="$0.00"
								value={inputValues.DDARejectFee}
								setValue={(values) => SetInputValues({ ...inputValues, DDARejectFee: values })}
								maxLength={18}
							/>

							<RowContainer
								inputType="number"
								inputDisabled={inputDisabled}
								labelValue="Monthly PCI Fee"
								name="monthlyPCIFee"
								placeholder="$0.00"
								value={inputValues.monthlyPCIFee}
								setValue={(values) => SetInputValues({ ...inputValues, monthlyPCIFee: values })}
								maxLength={18}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
