import axios from 'axios';
import { PayabliStorageManager } from '../api/localStorageManager'

axios.interceptors.response.use(
  (response) => {
    let url = new URL(response.config?.url);
    if (response.config.headers.requestToken && response.config.headers.requestToken !== process.env.REACT_APP_TOKEN && url === process.env.REACT_APP_URL_BRAND) {
      PayabliStorageManager.setEncryptedLocalStorageKey(response.config.headers.requestToken);
    }
    return response;
  }
);
