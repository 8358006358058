import React from 'react';

class NameAvatar extends React.Component {
  
  getColor(){
      let colors =['rgb(158,215,174)','rgb(191,153,221)', 'rgb(225,154,155)', 'rgb(158,174,188)', 'rgb(91,141,184)', 'rgb(249,196,108)'];
      let rand = (Math.floor(Math.random() * (6 - 0) + 0));
      return colors[rand];
  }

  stringToHslColor(str, s, l) {
    let text = str ? str : 'a';
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
  }

  getText(){
    let name = this.props.text;
    let first ='';
    let second ='';

    if(name){
      name = name.replaceAll('-', '')
      name = name.replaceAll('  ', ' ')
      let arrayName = name.split(' ');
      
      if(arrayName[0] && arrayName[0][0]){
        first = arrayName[0][0];
      }
  
      if(arrayName[1] && arrayName[1][0]){
        second = arrayName[1][0];
      }
    }

    return (first + "" + second).toUpperCase();
  }

  render() {
    return (
      <>
      <div className="avatar-container" style={{backgroundColor: this.stringToHslColor(this.getText(), 30, 76)}}>
        <>&nbsp;</>{this.getText()}
      </div>
      </>
    );
  }
}

export { NameAvatar };

